import customerGroupLayout from "@/views/cashiers/customerGroups/CustomerGroupLayout"
import customerGroupList from "@/views/customers/customerGroups/V2/List"
import customerGroupCreate from "@/views/cashiers/customerGroups/Create"
import customerGroupShow from "@/views/cashiers/customerGroups/Show"
import customerGroupEdit from "@/views/cashiers/customerGroups/Edit"

export default {
  path: "customer-group",
  name: "customer-group",
  component: customerGroupLayout,
  redirect: "/customer/customer-group",
  children: [
    {
      path: "",
      name: "customer-group-list-v2",
      component: customerGroupList
    },
    // for old version
    {
      path: "create",
      name: "create-customer-group",
      component: customerGroupCreate
    },
    {
      path: "edit-customer-group/:uuid",
      name: "edit-customer-group",
      component: customerGroupEdit
    },
    {
      path: "show-customer-group/:uuid",
      name: "show-customer-group",
      component: customerGroupShow
    }
  ]
}
