<template>
  <li v-if="shop">
    <a
      :href="`presentation://pos.figos.app/second-customer-display?token=${token}&shop_id=${shop.id}`"
      class="user-select-none"
    >
      <i class="fas fa-desktop mr-2" /> {{ $t("label.displayCustomOrdered") }} (Android)
    </a>
  </li>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "SecondDisplayDeepLink",
  data () {
    return {
      token: null
    }
  },
  computed: {
    ...mapGetters("shop", {
      shop: "shop"
    })
  },
  mounted () {
    const token = localStorage.getItem("token")
    if (token) {
      this.token = token.replace(/Bearer /g, "")
    }
  }
}
</script>

<style scoped>

</style>
