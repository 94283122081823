import List from "@/views/inventories/units/List"
import Create from "@/views/inventories/units/Create"
import Edit from "@/views/inventories/units/Edit"
import Show from "@/views/inventories/units/Show"
import Index from "@/views/inventories/units/Index"

export const unitRouters = {
  path: "unit",
  component: Index,
  name: "unit",
  redirect: "/admin/inventories/unit/",
  children: [
    {
      path: "",
      name: "list-product-unit",
      component: List
    },
    {
      path: "create",
      name: "create-product-unit",
      component: Create
    },
    {
      path: ":uuid/show",
      name: "show-product-unit",
      component: Show
    },
    {
      path: ":uuid/edit",
      name: "edit-product-unit",
      component: Edit
    }
  ]
}
