<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("strings.product.tag.listingTags") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'create-product-tag'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("food.button.newProductTag") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <table
              class="table table-bordered table-hover"
              id="product-tag-table"
            >
              <thead>
                <tr>
                  <th>{{ $t("label.nameEn") }}</th>
                  <th>{{ $t("label.nameKm") }}</th>
                  <th>{{ $t("food.table.createdAt") }}</th>
                  <th>{{ $t("food.table.action") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <TagDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>
import TagDataTableSm from "./_components/TagDataTableSm"

export default {
  name: "ListProductTag",
  components: { TagDataTableSm },
  metaInfo () {
    return {
      title: this.$t("strings.product.tag.listingTags"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null,
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    windowWidth: function () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    oTable: function () {
      this.reloadButtonAction()
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    deleteTag (params) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        this.$axios.post(this.$config.base_url + "/api/product-tag/delete", {
          ...params
        }).then(() => {
          self.$aswaldelete(self.$t("model.tag"))
          self.oTable.draw(true)
        }).catch((error) => {
          self.onResponseError(error)
        })
      })
    },
    dataTable () {
      const self = this
      return $("#product-tag-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: self.config.base_url + "/api/product-tag/data",
          type: "POST",
          data: {
            shop_id: self.get_shop.id,
            labelEdit: self.labelEdit,
            labelDelete: self.labelDelete,
            labelShow: self.labelShowTrans,
            labelViewStock: self.labelShowStock
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "name_en",
            name: "product_tags.name_en",
            title: self.$t("label.nameEn")
          },
          {
            data: "name_km",
            name: "product_tags.name_km",
            title: self.$t("label.nameKm")
          },
          {
            data: "created_at",
            name: "product_tags.created_at",
            title: self.$t("food.table.createdAt"),
            searchable: false,
            orderable: true
          },
          {
            data: "actions",
            name: "actions",
            title: self.$t("food.table.action"),
            searchable: false,
            orderable: false
          }
        ],
        order: [
          [2, "desc"]
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    reloadButtonAction () {
      const self = this
      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-edit-product-tag")
      $(document).off("click", ".btn-delete-product-tag")

      $(document).on("click", ".btn-show", function () {
        self.$router.push({
          name: "show-product-tag",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-edit-product-tag", function () {
        self.$router.push({
          name: "edit-product-tag",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-delete-product-tag", function () {
        self.deleteTag({
          shop_id: self.get_shop.id,
          uuid: $(this).attr("data-uuid")
        })
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
