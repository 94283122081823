import Index from "@/views/settings/banner-images/Index"
import List from "@/views/settings/banner-images/List"

export const bannerImageRouters = {
  path: "banner-image",
  component: Index,
  name: "banner-image",
  redirect: "banner-image/list",
  children: [
    {
      path: "list",
      name: "list-banner-image",
      component: List
    }
  ]
}
