<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("string.offlineInventoryTransaction") }}
          </h3>
        </div>
        <div class="box-body">
          <table
            id="stock-alert-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("string.image") }}</th>
                <th>{{ $t("string.productName") }}</th>
                <th>{{ $t("string.user") }}</th>
                <th>{{ $t("string.order") }}</th>
                <th>{{ $t("string.amountStock") }}</th>
                <th>{{ $t("string.amountRequest") }}</th>
                <th>{{ $t("string.amountOwe") }}</th>
                <th>{{ $t("string.createdAt") }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  metaInfo () {
    return {
      title: this.$t("string.offlineInventoryTransaction"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      oTable: null,
      config: this.$config
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $("#stock-alert-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        pageLength: 100,
        scrollX: true,
        ajax: {
          url: this.$config.base_url + "/api/offline/inventory-transaction",
          type: "POST",
          data: {
            shop_uuid: this.$config.shop_uuid
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "product",
            name: "product",
            title: self.$t("string.image"),
            searchable: false,
            orderable: false,
            render: (data) => {
              if (data && data.hasOwnProperty("images") && Array.isArray(data.images) && data.images.length > 0) {
                const image = `${this.$config.base_url}/${data.images[0].src}`
                return `<img src="${image}" width="80px" alt="Product image">`
              }
              return "<img src=\"/img/default_product.png\" width=\"80px\" alt=\"Product image\">"
            }
          },
          {
            data: "product.name",
            name: "product.name",
            orderable: false,
            title: self.$t("string.productName")
          },
          {
            data: "user.first_name",
            name: "user.first_name",
            orderable: false,
            title: self.$t("string.user"),
            render: (data, type, row) => {
              return `${row.user.first_name} ${row.user.last_name}`
            }
          },
          {
            data: "order.order_number",
            name: "order.order_number",
            orderable: false,
            title: self.$t("string.orderNumber")
          },
          {
            data: "stock_amount",
            name: "stock_amount",
            title: self.$t("string.amountStock")
          },
          {
            data: "request_amount",
            name: "request_amount",
            title: self.$t("string.amountRequest")
          },
          {
            data: "owe_amount",
            name: "owe_amount",
            title: self.$t("string.amountOwe")
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("string.createdAt"),
            render: (data) => {
              return this.$moment(data).format("DD/MM/YYYY hh:mm:ss A")
            }
          }
        ],
        order: [
          [7, "desc"]
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
  },
  beforeDestroy () {
    this.oTable.clear()
    this.oTable.destroy()
  }
}
</script>

<style scoped>

</style>
