export const googleSdkMixin = {
  methods: {
    async loginWithGoogle () {
      try {
        const googleUser = await this.$gAuth.signIn()
        if (!googleUser) {
          return null
        }
        const access_token = googleUser.getAuthResponse().access_token

        this.$isLoading(true)
        const self = this
        this.$axios
          .post(this.$config.base_url + "/api/social-account/login-with-gb", {
            access_token
          })
          .then((response) => {
            const result = response.data.data
            const token = result.token_type + " " + result.access_token
            localStorage.setItem("token", token)

            this.$axios.defaults.headers.common.Accept = "application/json"
            this.$axios.defaults.headers.common.Authorization = token

            const user = result.user
            localStorage.setItem("user", JSON.stringify(user))
            self.$store.dispatch("user/updateUser", user)

            if (self.$bill_active === "true") {
              const plan = result.plan
              localStorage.setItem("plan", JSON.stringify(plan))
              self.$store.dispatch("shop/setPlan", plan)
              if (result.hasOwnProperty("expired")) {
                this.$store.dispatch("shop/setExpired", result.expired)
              }
            }

            const shop = result.shop
            $.ajaxSetup({
              headers: {
                Accept: "application/json",
                Authorization: localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*"
              }
            })
            if (shop !== null) {
              self.setShopProperty(shop)
              self.checkTokenDeviceForSell()
              self.$router.push({ name: "dashboard" })
            } else {
              self.$router.replace({ name: "create-shop" })
            }
          })
          .catch((error) => {
            self.getResponseError(error)
          })
          .finally(() => {
            self.$isLoading(false)
          })
      } catch (error) {
        return null
      }
    }
  }
}
