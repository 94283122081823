<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("strings.listingExchanges") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'create-exchange'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("button.newExchange") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-2 col-sm-3 col-xs-4 margin-bottom">
                <multiselect
                  v-model="selectedActiveOption"
                  :allow-empty="false"
                  :option-height="104"
                  :options="activeOptions"
                  :placeholder="$t('string.pleaseSelect')"
                  :show-labels="false"
                  label="label"
                  track-by="value"
                  @input="getExchange"
                />
              </div>
            </div>
            <table
              id="exchange-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("table.exchange.valueRiel") }}</th>
                  <th>{{ $t("table.exchange.status") }}</th>
                  <th>{{ $t("table.exchange.createdAt") }}</th>
                  <th>{{ $t("table.exchange.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <ExchangeDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>
import ExchangeDataTableSm from "@/views/settings/exchange/_components/ExchangeDataTableSm"

export default {
  name: "ExchangeList",
  components: { ExchangeDataTableSm },
  metaInfo () {
    return {
      title: this.$t("strings.listingExchanges"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null,
      selectedActiveOption: {
        label: this.$t("report.label.all"),
        value: ""
      },
      windowWidth: null
    }
  },
  computed: {
    activeOptions () {
      return [
        {
          label: this.$t("report.label.all"),
          value: ""
        },
        {
          label: this.$t("label.active"),
          value: true
        },
        {
          label: this.$t("label.inactive"),
          value: false
        }
      ]
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
      this.selectedActiveOption = this.activeOptions.find(item => item.value === this.selectedActiveOption.value)
    },
    windowWidth: function () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    oTable () {
      this.reloadButtonAction()
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    deleteExchange (uuid) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/exchange/delete", {
          shop_id: self.get_shop.id,
          uuid: uuid
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.exchangeRate"))
            self.oTable.draw(true)
          }
        })
      })
    },
    getExchange () {
      this.oTable.draw(true)
    },
    dataTable () {
      const self = this
      return $("#exchange-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: self.config.base_url + "/api/exchange/data",
          type: "POST",
          data: (d) => {
            d.shop_id = self.get_shop.id
            d.labelEdit = self.labelEdit
            d.labelDelete = self.labelDelete
            d.labelShow = self.labelShowTrans
            d.labelInactive = self.labelInactive
            d.labelActive = self.labelActive
            d.active = self.selectedActiveOption.value
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "value",
            name: "value",
            title: self.$t("table.exchange.valueRiel")
          },
          {
            data: "active",
            name: "active",
            searchable: false,
            orderable: false,
            title: self.$t("table.exchange.status")
          },
          {
            data: "created_at",
            name: "created_at",
            searchable: false,
            orderable: true,
            title: self.$t("table.exchange.createdAt")
          },
          {
            data: "actions",
            name: "actions",
            searchable: false,
            orderable: false,
            title: self.$t("table.exchange.actions")
          }
        ],
        order: [[2, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    reloadButtonAction () {
      const self = this
      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-edit")
      $(document).off("click", ".btn-delete")

      $(document).on("click", ".btn-show", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "show-exchange",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-edit", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "edit-exchange",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-delete", function (e) {
        e.preventDefault()
        self.deleteExchange($(this).attr("data-uuid"))
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
