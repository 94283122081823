<template>
  <div class="register-container">
    <div class="register-form-content">
      <div class="register-form-content-wrapper text-center shadow">
        <div class="section_icon d-block">
          <i class="far fa-envelope" />
        </div>
        <div class="section_title d-block">
          {{ $t("label.send_mail_title") }}
        </div>
        <div class="section_content">
          <p>{{ $t("label.send_mail_label_1") }} <span class="mail">{{ mail }}</span> ,</p>
          <p>{{ $t("label.send_mail_label_2") }}</p>
        </div>
        <div>
          <router-link
            :to="{name: 'Login'}"
            class="btn btn-xl btn-secondary uppercase"
          >
            <i class="fas fa-home mr-2" />
            {{ $t("button.goto_login") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SendMail",
  metaInfo () {
    return {
      title: this.$t("label.send_mail_title"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      mail: null
    }
  },
  mounted () {
    if (localStorage.hasOwnProperty("send_mail")) {
      this.mail = localStorage.getItem("send_mail")
    }
  }
}
</script>

<style scoped>
.send-mail-container {
  background-color: var(--gray-dark-secondary);
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  padding: 40px;
}

.section_icon {
  font-size: 65px;
  margin: 20px 0;
}

.section_title {
  font-size: 28px;
  margin-bottom: 64px;
}

.section_content {
  font-size: 18px;
  margin-bottom: 64px;
}

.mail {
  text-decoration: underline;
  font-weight: bold;
}

.btn-group-xl > .btn, .btn-xl {
  padding: 14px 40px;
  font-size: 22px;
  line-height: 1.3333333;
  border-radius: 6px;
}
</style>
