<template>
  <div class="row">
    <div :class="columns">
      <div
        :class="{'has-error' : message_validation !== null && message_validation.hasOwnProperty('name_en') }"
        class="form-group"
      >
        <label class="required">{{ $t("label.nameEn") }}</label>
        <input
          v-model="tax.name_en"
          :placeholder="$t('placeholder.enterNameAsEn')"
          class="form-control"
          type="text"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('name_en')">
          <span class="help-block">{{ message_validation["name_en"][0] }}</span>
        </template>
      </div>
      <div
        :class="{'has-error' : message_validation !== null && message_validation.hasOwnProperty('name_km') }"
        class="form-group"
      >
        <label class="required">{{ $t("label.nameKm") }}</label>
        <input
          v-model="tax.name_km"
          :placeholder="$t('placeholder.enterNameAsKm')"
          class="form-control"
          type="text"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('name_km')">
          <span class="help-block">{{ message_validation["name_km"][0] }}</span>
        </template>
      </div>
      <div
        :class="(message_validation !== null && message_validation.hasOwnProperty('amount_percentage') ? 'has-error' : '')"
        class="form-group"
      >
        <label class="required">{{ $t("fields.label.amountPercentage") }}</label>
        <input
          v-model="tax.amount_percentage"
          :placeholder="$t('fields.placeholder.amountPercentage')"
          class="form-control"
          type="number"
        >
        <template
          v-if="message_validation !== null && message_validation.hasOwnProperty('amount_percentage')"
        >
          <span class="help-block">{{ message_validation["amount_percentage"][0] }}</span>
        </template>
      </div>
      <div class="form-group">
        <label>
          <input
            v-model="tax.active"
            name="active"
            type="checkbox"
          > {{ $t("fields.label.active") }}
        </label>
      </div>
      <div class="form-group text-right">
        <template v-if="this.$route.name !== 'show-tax'">
          <button
            :class="`btn-${this.$route.name === 'edit-tax' ? 'info' : 'primary'}`"
            class="btn"
            @click="storeTax"
          >
            <i class="fas fa-check-circle" />
            <template v-if="this.$route.name === 'edit-tax'">
              {{ $t("button.update") }}
            </template>
            <template v-else>
              {{ $t("button.save") }}
            </template>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaxForm",
  props: {
    columns: {
      type: String,
      default: "col-md-12"
    }
  },
  data () {
    return {
      tax: {
        shop_id: null,
        name_en: "",
        name_km: "",
        amount_percentage: "",
        active: false
      },
      config: this.$config,
      message_validation: null
    }
  },
  methods: {
    getTax () {
      this.$axios.post(this.$config.base_url + "/api/tax/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.tax = response.data.data
        }
        if (response.data.code === 0) {
          this.$router.push({ name: "list-tax" })
          this.$toastr("error", response.data.message, "Get Tax")
        }
      }).catch((error) => {
        this.$toastr("error", error, "Get Tax")
      })
    },
    storeTax () {
      this.tax.shop_id = this.get_shop.id
      this.$axios.post(this.$config.base_url + "/api/tax/store", this.tax)
        .then((response) => {
          if (response.data.code === 1) {
            this.$router.push({ name: "list-tax" })
            if (this.tax.hasOwnProperty("id") && this.tax.id !== null) {
              this.$anotify(this.$t("model.tax"), "update")
            } else {
              this.$anotify(this.$t("model.tax"))
            }
          } else {
            this.message_validation = response.data.message
          }
        })
        .catch((error) => {
          this.$toastr("error", error, "New Tax")
        })
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getTax()
    }
    if (this.$route.name === "show-tax") {
      $("input").attr("disabled", true)
    }
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}
</style>
