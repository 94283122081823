<template>
  <div class="row">
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("user.showUser") }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <div
            v-if="user"
            class="row"
          >
            <div class="col-md-7">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>{{ $t("label.name") }}</td>
                    <td>
                      <strong>{{ user.full_name }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("fields.placeholder.username") }}</td>
                    <td>
                      <strong>{{ user.username }}</strong>
                    </td>
                  </tr>
                  <tr v-if="user.gender">
                    <td>{{ $t("label.gender") }}</td>
                    <td>
                      <strong>{{ user.gender["name_" + $i18n.locale] }}</strong>
                    </td>
                  </tr>
                  <tr v-if="user.email">
                    <td>{{ $t("label.email") }}</td>
                    <td>
                      <strong>{{ user.email }}</strong>
                    </td>
                  </tr>
                  <tr v-if="user.roles">
                    <td>{{ $t("label.roles") }}</td>
                    <td>
                      <ul
                        class="margin-bottom-none"
                        style="padding-left: 16px"
                      >
                        <template v-for="(item, key) in user.roles">
                          <li :key="key">
                            {{ item["display_name_" + $i18n.locale] }}
                          </li>
                        </template>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.status") }}</td>
                    <td>
                      <template v-if="user.active">
                        <span class="label label-success">{{ $t("label.active") }}</span>
                      </template>
                      <template v-else>
                        <span class="label label-danger">{{ $t("label.inactive") }}</span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("user.table.createdAt") }}</td>
                    <td>
                      <strong>{{ $moment(user.created_at).format("DD/MM/YYYY HH:mm:ss A") }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.updatedAt") }}</td>
                    <td>
                      <strong>{{ $moment(user.updated_at).format("DD/MM/YYYY HH:mm:ss A") }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-5">
              <div class="w-50 mx-auto">
                <template v-if="user.avatar_location">
                  <img
                    :src="getSrc(user.avatar_location)"
                    alt="avatar"
                    class="img-thumbnail w-100"
                  >
                </template>
                <template v-else>
                  <img
                    :src="defaultAvatar"
                    alt="avatar"
                    class="img-thumbnail w-100"
                  >
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserShow",
  metaInfo () {
    return {
      title: this.$t("user.showUser"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      user: null
    }
  },
  mounted () {
    this.getUser()
  },
  methods: {
    getUser () {
      this.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/user/show", {
          uuid: this.$route.params.uuid
        })
        .then((response) => {
          if (response.data.code === 1 && response.data.data !== null) {
            this.user = response.data.data
          }
          if (response.data.code === 0) {
            this.$toastr("error", response.data.message, "User information")
            this.$router.push({ name: "list-user" })
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
