<template>
  <div class="row">
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="info-box tw-bg-gray-300 tw-border tw-shadow-sm">
        <span class="info-box-icon bg-green">
          <i class="fas fa-gift" />
        </span>

        <div class="info-box-content tw-text-capitalize tw-space-y-3">
          <span class="info-box-text tw-font-bold tw-text-3xl">{{ $t("string.total_sold_count") }}</span>
          <span class="info-box-number">{{ total_sold_count }} {{ $t("string.items") }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-9 col-sm-6 col-xs-12">
      <div class="row">
        <template v-if="Array.isArray(total_sold_count_by_categories) && total_sold_count_by_categories.length >0">
          <div
            v-for="(category, key) of total_sold_count_by_categories"
            :key="key"
            class="col-md-3 col-sm-4 col-xs-12"
          >
            <div class="info-box tw-bg-gray-300 tw-border tw-shadow-sm">
              <span class="info-box-icon tw-bg-gray-100 tw-text-white">
                <template v-if="category.image">
                  <img
                    :src="getSrc(category.image)"
                    alt="Image category"
                    class="tw-w-24"
                  >
                </template>
                <template v-else>
                  <img
                    alt="Image category"
                    class="tw-w-24"
                    src="/img/pngwing.png"
                  >
                </template>
              </span>

              <div class="info-box-content tw-text-capitalize tw-space-y-3">
                <span class="info-box-text tw-font-bold tw-text-3xl">{{ category["name_" + $i18n.locale] }}</span>
                <span class="info-box-number">{{ category.total_sold_count }} {{ $t("string.items") }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "TotalSoldCount",
  data () {
    return {
      total_sold_count: 0,
      total_sold_count_by_categories: []
    }
  },
  computed: {
    ...mapState({
      shop: state => state.shop.shop
    }),
    ...mapState("totalSoldCount", {
      dateRange: "dateRange"
    })
  },
  watch: {
    dateRange: {
      deep: true,
      handler () {
        this.getTotalSoldCount()
      }
    }
  },
  methods: {
    getTotalSoldCount () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/report/total-sold-count", {
        uuid: this.shop.uuid,
        start_date: this.dateRange && this.dateRange[0] ? this.$moment(this.dateRange[0]).format("YYYY-MM-DD") : null,
        end_date: this.dateRange && this.dateRange[1] ? this.$moment(this.dateRange[1]).format("YYYY-MM-DD") : null
      }).then(({ data }) => {
        if (data.data) {
          this.total_sold_count = data.data.total_sold_count
          this.total_sold_count_by_categories = data.data.total_sold_count_by_categories
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
