<template>
  <div
    id="categoryModal"
    aria-labelledby="categoryModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-scroll modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header-custom">
          <h4
            id="categoryModalLabel"
            class="modal-title"
          >
            {{ $t("string.filterProductByCategory") }}
          </h4>
          <button
            ref="close"
            :class="class_btn_modal"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
          >
            <i
              aria-hidden="true"
              class="fas fa-times"
            />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div
                v-if="categories.length > 0"
                class="list-category h-auto no-padding"
              >
                <div
                  v-for="(category, key) in categories"
                  :key="key"
                  :class="`${category.active ? 'active' : ''}`"
                  class="popup-category-item shadow rounded"
                  @click="onClickCategory(category)"
                >
                  <span class="check-icon"><i class="fas fa-check-circle" /></span>
                  <div class="position-relative d-flex align-items-center justify-content-center flex-column h-100">
                    <div class="popup-category-item-content__image rounded">
                      <img
                        v-if="category.image"
                        :src="`${$config.base_url}${replaceImgUrl(category.image, 'small')}`"
                        class="w-100"
                        alt="Category image"
                      >
                      <img
                        v-else-if="category.id === -1"
                        :src="categoryAllImg"
                        class="w-100"
                        alt="Category image"
                      >
                      <img
                        v-else
                        :src="`${defaultImg}`"
                        class="w-100"
                        alt="Category image"
                      >
                    </div>
                    <div class="position-relative text-center popup-category-item-content__label">
                      <label
                        class="list-category-label no-margin font-s-15 text-ellipsis-2-line"
                        style="padding:0 10px;"
                      >{{ category["name_" + $i18n.locale] }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default btn-lg"
            @click="onClickReset"
          >
            <i class="fas fa-history" /> {{ $t("button.reset") }}
          </button>
          <button
            class="btn btn-success btn-lg"
            @click="getProductsByCategories"
          >
            <i class="fas fa-check-circle" /> {{ $t("button.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "CategoryModal",
  computed: {
    ...mapState({
      categories: state => state.order.categories
    })
  },
  methods: {
    onClickCategory (category) {
      this.$store.dispatch("order/onChangeCategories", category)
    },
    onClickReset () {
      const all = {
        name_en: "All",
        name_km: "ទាំងអស់",
        id: -1,
        active: true
      }
      this.$store.dispatch("order/onChangeCategories", all)
      this.$store.dispatch("order/setFilterProductCategoryIds", [all.id])
      this.$emit("refreshProducts")
      this.$refs.close.click()
    },
    getProductsByCategories () {
      const categories = []
      this.categories.forEach((category) => {
        if (category.active === true) {
          categories.push(category.id)
        }
      })
      this.$store.dispatch("order/setFilterProductCategoryIds", categories)
      this.$emit("refreshProducts")
      this.$refs.close.click()
    }
  }
}
</script>

<style scoped>

</style>
