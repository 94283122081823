<template>
  <div
    id="chefOrderModal"
    aria-labelledby="chefOrderModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header-custom">
          <h4
            id="chefOrderModalLabel"
            class="modal-title"
          >
            {{ $t("label.order") }}
          </h4>
          <button
            ref="close"
            :class="class_btn_modal"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
          >
            <i
              aria-hidden="true"
              class="fas fa-times"
            />
          </button>
        </div>
        <div class="modal-body">
          <template v-if="isLoading">
            <asora-text-loading />
          </template>
          <template v-else>
            <template v-if="products !== null">
              <div class="col-xs-12 table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>{{ $t("recent_sell.table.no") }}</th>
                      <th>{{ $t("recent_sell.table.productName") }}</th>
                      <th>{{ $t("recent_sell.table.servedQuantity") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="products && Array.isArray(products) && products.length > 0">
                      <tr
                        v-for="(product, key) in products"
                        :key="key"
                      >
                        <td>{{ key + 1 }}</td>
                        <td>{{ product.name }}</td>
                        <td>{{ product.pivot.served_quantity }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-else>
              <no-any-records :height="50" />
            </template>
          </template>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import AsoraTextLoading from "./AsoraTextLoading"
import NoAnyRecords from "./NoAnyRecords"

export default {
  name: "AsoraChefViewOrderModal",
  components: {
    NoAnyRecords,
    AsoraTextLoading
  },
  data () {
    return {
      products: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      order_uuid: state => state.chef.order_uuid
    })
  },
  watch: {
    order_uuid () {
      this.getOrderDetail()
    }
  },
  methods: {
    getOrderDetail () {
      this.isLoading = true
      this.$axios.post(this.$config.base_url + "/api/chef/order/get-order-detail", {
        shop_id: this.get_shop.id,
        order_uuid: this.order_uuid
      }).then((response) => {
        this.isLoading = false
        if (response.data.code === 1) {
          this.products = response.data.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
