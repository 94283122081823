<template>
  <IndexLayout
    :btn-title="btn_title"
    :hidden-button="true"
    :is-not-route-list="isNotRouteList"
    :route-name-create="route_name_create"
    :title="title"
  />
</template>

<script>
import IndexLayout from "@/components/layout/IndexLayout"

export default {
  name: "Index",
  components: { IndexLayout },
  computed: {
    isNotRouteList () {
      return this.$route.name !== "list-stock-alert-control"
    }
  },
  data () {
    return {
      route_name_create: "create-product",
      title: "menu.inventories.products.stockAlertControl",
      btn_title: ""
    }
  }
}
</script>

<style scoped>

</style>
