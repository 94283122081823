<template>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.product.unit.showUnit") }}
          </h3>
          <div class="box-tools hidden-xs">
            <router-link
              class="btn btn-primary btn-sm mr-2"
              :to="{name: 'edit-product-unit', params: {uuid: $route.params.uuid}}"
            >
              <i class="fas fa-edit" /> {{ $t("button.edit") }}
            </router-link>
            <button
              @click="$router.back()"
              class="btn btn-default btn-sm"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </button>
          </div>
        </div>
        <div class="box-body p-xs">
          <table
            class="table table-striped"
            v-if="unit"
          >
            <tbody>
              <tr>
                <td>{{ $t("label.nameEn") }}</td>
                <td><strong>{{ unit.name_en }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t("label.nameKm") }}</td>
                <td><strong>{{ unit.name_km }}</strong></td>
              </tr>
              <tr v-if="unit.product_type">
                <td>{{ $t("product.fields.label.productType") }}</td>
                <td><strong>{{ unit.product_type["name_" + $i18n.locale] }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t("food.table.createdAt") }}</td>
                <td><strong>{{ $moment(unit.created_at).format("DD/MM/YYYY hh:mm:ss") }}</strong></td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <NoResult :height="30" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoResult from "@/components/NoResult"

export default {
  name: "Show",
  metaInfo () {
    return {
      title: this.$t("label.product.unit.showUnit"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: { NoResult },
  data () {
    return {
      unit: null,
      config: this.$config
    }
  },
  methods: {
    getUnit () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-unit/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then(({ data }) => {
        this.unit = data.data
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.getUnit()
  }
}
</script>

<style scoped>

</style>
