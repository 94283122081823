<template>
  <div
    :class="{ 'showed': showDrawer }"
    class="slide-select-customer"
  >
    <div class="position-relative select-customer-content overflow-y-auto scrollbar">
      <div class="form-group  d-flex align-items-center justify-content-between user-select-none">
        <label class="title-section no-margin">{{ titleName }}</label>
        <button
          class="btn btn-danger btn-lg no-margin"
          @click="closingDrawer"
        >
          <i class="fas fa-times" />
        </button>
      </div>
      <div class="row no-margin">
        <div class="col-xs-12 no-padding">
          <div
            :class="{'has-error': validation && validation.hasOwnProperty('name_en')}"
            class="form-group"
          >
            <label class="font-s-16 required user-select-none">{{ $t("label.nameEn") }}</label>
            <input
              v-model="customerGroup.name_en"
              :placeholder="$t('placeholder.enterGroupNameAsEn')"
              class="pos-input pos-input-lg"
              type="text"
            >
            <template v-if=" validation && validation.hasOwnProperty('name_en')">
              <span>{{ validation.name_en[0] }}</span>
            </template>
          </div>
        </div>
        <div class="col-xs-12 no-padding">
          <div
            :class="{'has-error': validation && validation.hasOwnProperty('name_km')}"
            class="form-group"
          >
            <label class="font-s-16 required user-select-none">{{ $t("label.nameKm") }}</label>
            <input
              v-model="customerGroup.name_km"
              :placeholder="$t('placeholder.enterGroupNameAsKm')"
              class="pos-input pos-input-lg"
              type="text"
            >
            <template v-if=" validation && validation.hasOwnProperty('name_km')">
              <span>{{ validation.name_km[0] }}</span>
            </template>
          </div>
        </div>
        <div class="col-xs-12 no-padding">
          <div class="form-group">
            <label class="font-s-16 user-select-none">{{ $t("label.showOption") }}</label>
            <div>
              <toggle-button
                :font-size="16"
                :height="30"
                :labels="{checked: $t('label.active'), unchecked: $t('label.inactive')}"
                :sync="true"
                :value="customerGroup.active"
                :width="100"
                class="no-margin"
                color="#1876f2"
                @change="customerGroup.active = !customerGroup.active"
              />
            </div>
          </div>
        </div>
        <div class="col-xs-12 no-padding">
          <div class="form-group">
            <label class="font-s-16 user-select-none">{{ $t("fields.label.description") }}</label>
            <textarea
              v-model="customerGroup.description"
              :placeholder="$t('fields.placeholder.descriptionGroup')"
              class="pos-input pos-input-lg"
              rows="20"
              style="height: 200px; min-width: 100%; max-width: 100%;"
            />
          </div>
        </div>
        <div class="col-xs-12 no-padding">
          <div class="form-group">
            <button
              :class="colorButton"
              class="btn btn-block btn-lg"
              @click="storeCustomerGroup"
            >
              <i class="fas fa-save mr-2" />
              {{ labelButton }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DrawerCustomerGroup",
  props: {
    showDrawer: {
      type: Boolean,
      default: false
    },
    classListCustomer: {
      type: String,
      default: ""
    },
    // eslint-disable-next-line vue/prop-name-casing
    shop_id: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    },
    showCustomerForm: {
      type: Boolean,
      default: false
    },
    oldData: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data () {
    return {
      validation: null,
      customerGroup: {
        uuid: null,
        name_en: null,
        name_km: null,
        description: null,
        active: true
      }
    }
  },
  computed: {
    titleName () {
      return this.oldData ? this.$t("label.editCustomerGroup") : this.$t("label.newCustomerGroup")
    },

    labelButton () {
      return this.oldData ? this.$t("button.saveCustomerGroup") : this.$t("button.createCustomerGroup")
    },

    colorButton () {
      return this.oldData ? "btn-primary" : "btn-success"
    }
  },
  mounted () {
    if (this.oldData) {
      this.customerGroup = this.oldData
    }
  },
  methods: {
    clearData () {
      this.customerGroup = {
        uuid: null,
        name_en: null,
        name_km: null,
        description: null,
        active: true
      }
    },
    storeCustomerGroup () {
      this.$axios.post(this.$config.base_url + "/api/v2/customer-group/store", {
        shop_uuid: this.$config.shop_uuid,
        uuid: this.customerGroup.uuid,
        name_en: this.customerGroup.name_en,
        name_km: this.customerGroup.name_km,
        active: this.customerGroup.active,
        description: this.customerGroup.description
      }).then(() => {
        this.$emit("refreshData")
        this.closingDrawer()
        this.closeCustomerDetailSm()
      }).catch((err) => {
        if (err.response.status === 422) {
          this.validation = err.response.data.errors
        } else {
          this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
        }
      })
    },
    closingDrawer () {
      this.clearData()
      this.$emit("closeDrawer")
      this.validation = null
      if (!this.oldData) {
        this.closeCustomerDetailSm()
      }
    }
  }
}
</script>

<style scoped></style>
