<template>
  <div class="row">
    <slot name="displayAlert" />
    <template v-if="plans && Array.isArray(plans) && plans.length">
      <div class="col-md-6">
        <h3 class="subscription-plan-title">
          {{ $t("string.yourSubscriptionPlan") }}
          <span
            v-if="currentPlan"
            class="subscription-badge"
          >{{ currentPlan.title }}</span>
        </h3>
        <div
          v-if="plans && Array.isArray(plans) && plans.length"
          class="margin-top"
        >
          <div class="plan-wrapper">
            <div
              v-for="(plan, key) in plans"
              :key="key"
              :class="{'plan-selected' : selectedPlan && plan.id === selectedPlan.id}"
              class="plan-card"
              @click="setSelectedPlan(plan)"
            >
              <div class="plan-body">
                <p class="plan-price">
                  <span class="symbol">{{ plan.currency_symbol }}</span>
                  <span class="price">{{ plan.show_price }}</span>
                </p>
                <div class="margin-top margin-bottom">
                  <h3 class="plan-name">
                    {{ plan.title }}
                  </h3>
                </div>
                <p
                  v-if="plan.short_description"
                  v-html="plan.short_description"
                />
              </div>
            </div>
          </div>
          <div class="btn-change-plan">
            <button
              :disabled="selectedPlan && currentPlan && currentPlan.id === selectedPlan.id"
              class="btn btn-primary text-uppercase"
              @click="setStep(2)"
            >
              {{ $t("button.changePlan") }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="col-sm-6 margin-top">
        <button
          class="btn btn-primary btn-sm"
          @click="subscribe"
        >
          {{ $t("button.subscribe") }}
        </button>
      </div>
    </template>
    <div class="col-sm-6">
      <AccountPaymentInfo />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import AccountPaymentInfo from "@/views/settings/subscription/components/AccountPaymentInfo"

export default {
  name: "SubscriptionPlanList",
  components: {
    AccountPaymentInfo
  },
  computed: {
    ...mapGetters("subscription", {
      plans: "plans",
      currentPlan: "currentPlan",
      selectedPlan: "selectedPlan"
    })
  },
  methods: {
    ...mapActions("subscription", {
      setSelectedPlan: "setSelectedPlan",
      setStep: "setStep"
    }),
    subscribe () {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.$base_api + "/api/bill/subscribe-free-plan")
        .then(() => {
          this.$emit("getCustomerAndPlans")
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>
.subscription-badge {
  font-size: 16px;
  color: white;
  padding: 4px 16px;
  border-radius: 10px;
  background: var(--primary)
}

.plan-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.plan-card {
  border: 1px solid #edeeee;
  margin-bottom: 15px;
  border-radius: 12px;
  margin-right: 10px;
  min-width: 168px;
  cursor: pointer;
  position: relative;
}

.plan-card:hover {
  border-width: 2px;
  border-color: var(--primary);
}

.plan-selected {
  border-width: 2px;
  border-color: var(--primary);
  background-color: var(--primary);
  color: white !important;
}

.plan-selected .plan-price {
  color: white;
}

.plan-body {
  display: flex;
  flex-direction: column;
  min-height: 168px;
  align-items: center;
  justify-content: center;
}

.plan-price {
  padding: 0;
  margin: 0;
  color: var(--primary);
  font-weight: bold;
}

.plan-name {
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 14px;
}

.plan-price .price {
  font-size: 32px;
}

@media screen and (max-width: 768px) {
  .plan-card {
    margin-right: 0;
    min-width: 100%;
  }

  .btn-change-plan {
    text-align: right;
  }
}

.subscription-plan-title {
  margin-bottom: 3rem;
  line-height: 1.5;
}
</style>
