<template>
  <div
    :style="`height: ${height}vh;`"
    class="no-any-records"
  >
    <div class="d-block">
      <img
        :src="image"
        style="width: 210px;"
        alt="No any records"
      >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "NoAnyRecords",
  props: {
    height: {
      type: Number,
      default: 80
    },
    image: {
      type: String,
      default: "/img/undraw_empty_xct9.svg"
    }
  }
}
</script>

<style scoped>
.no-any-records {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
