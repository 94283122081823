<template>
  <div class="row">
    <div :class="columns">
      <div
        :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('name') ? 'has-error' : '')"
      >
        <label class="required">{{ $t("fields.label.name") }}</label>
        <input
          v-model="customer.name"
          :placeholder="$t('fields.placeholder.name')"
          class="form-control"
          type="text"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('name')">
          <span class="help-block">{{ message_validation["name"][0] }}</span>
        </template>
      </div>
      <div
        :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('description') ? 'has-error' : '')"
      >
        <label class="">{{ $t("fields.label.description") }}</label>
        <textarea
          v-model="customer.description"
          :placeholder="$t('fields.placeholder.description')"
          class="form-control"
        />
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('description')">
          <span class="help-block">{{ message_validation["description"][0] }}</span>
        </template>
      </div>
      <div class="form-group">
        <template v-if="$route.name !== 'show-customer-group'">
          <button
            :class="`btn btn-${$route.name === 'edit-customer-group' ? 'info' : 'primary'} btn-sm`"
            @click="storeCustomer"
          >
            <i class="fas fa-check-circle" />
            <template v-if="$route.name === 'edit-customer-group'">
              {{ $t("button.update") }}
            </template>
            <template v-else>
              {{ $t("button.save") }}
            </template>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerGroupForm",
  props: {
    columns: {
      type: String,
      default: "col-md-12"
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      customer: {
        shop_id: null,
        name: "",
        description: ""
      },
      genders: [],
      memberships: [],
      config: this.$config,
      message_validation: null
    }
  },
  methods: {
    getCustomerGroup () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/customer-group/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          const result = response.data.data
          this.customer.id = result.id
          this.customer.name = result.name
          this.customer.description = result.description
        }
        this.$isLoading(false)
      })
    },
    storeCustomer () {
      this.$isLoading(true)
      this.customer.shop_id = this.get_shop.id
      this.$axios.post(this.$config.base_url + "/api/customer-group/store", this.customer)
        .then((response) => {
          if (response.data.code === 1) {
            this.$router.push({ name: "list-customer-group" })
            if (this.customer.hasOwnProperty("id") && this.customer.id !== null) {
              this.$anotify(this.$t("model.customerGroup"), "update")
            } else {
              this.$anotify(this.$t("model.customerGroup"))
            }
          } else {
            this.message_validation = response.data.message
          }
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getCustomerGroup()
    }
    if (this.$route.name === "show-customer-group") {
      $("input").attr("disabled", true)
      $("textarea").attr("disabled", true)
    }
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}
</style>
