import invoice from "@/views/invoices/Index"
import invoiceLayout from "@/views/invoices/Layout"

export const invoiceRouters = {
  path: "/invoice",
  component: invoiceLayout,
  children: [
    {
      path: ":uuid",
      name: "print-invoice",
      component: invoice
    }
  ]
}
