<template>
  <div class="row">
    <div class="col-md-12 margin-top">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div>
            <h3 class="m-0 font-weight-bold text-primary payment-subtitle">
              {{ $t("label.ticketTypes") }}
            </h3>
          </div>
          <div>
            <button
              class="btn btn-success"
              @click="$router.push({name: 'ticket-type-create'})"
            >
              <i class="fas fa-plus-circle" /> {{ $t("label.newTicketType") }}
            </button>
            <button
              class="btn btn-default"
              @click="$router.push({name: 'aps-order'})"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <table
            id="ticket-type-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("table.ticket.name") }}</th>
                <th>{{ $t("table.ticket.code") }}</th>
                <th>{{ $t("table.ticket.price") }}</th>
                <th>{{ $t("table.ticket.startAge") }}</th>
                <th>{{ $t("table.ticket.endAge") }}</th>
                <th>{{ $t("table.ticket.createdAt") }}</th>
                <th>{{ $t("table.ticket.actions") }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  metaInfo () {
    return {
      title: "List ticket type",
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      oTable: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $("#ticket-type-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: self.$config.base_url + "/api/amusement-park/ticket-type/data",
          type: "POST",
          data: (d) => {
            d.shop_uuid = self.$config.shop_uuid
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "name",
            name: "name",
            title: self.$t("table.ticketType.name")
          },
          {
            data: "code",
            name: "code",
            title: self.$t("table.ticketType.code")
          },
          {
            data: "price",
            name: "price",
            title: self.$t("table.ticketType.price")
          },
          {
            data: "start_age",
            name: "start_age",
            title: self.$t("table.ticketType.startAge"),
            render: function (data) {
              let y = self.$t("date.year")
              if (data > 1) {
                y = self.$t("date.years")
              }
              return data + " " + y
            }
          },
          {
            data: "end_age",
            name: "end_age",
            title: self.$t("table.ticketType.endAge"),
            render: function (data) {
              let y = self.$t("date.year")
              if (data > 1) {
                y = self.$t("date.years")
              }
              return data + " " + y
            }
          },
          {
            data: "created_at",
            name: "created_at",
            searchable: false,
            orderable: true,
            title: self.$t("table.ticketType.createdAt"),
            render: function (data) {
              let format = "LLLL"
              if (self.$i18n.locale === "en") {
                format = "llll"
              }
              return self.$moment(data).locale(self.$i18n.locale).format(format)
            }
          },
          {
            data: "action",
            name: "action",
            searchable: false,
            orderable: false,
            title: self.$t("table.ticketType.actions")
          }
        ],
        order: [[5, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    onClickToggle (ticketTypeUuid, toggle) {
      this.$axios.post(this.$config.base_url + "/api/amusement-park/ticket-type/toggle", {
        shop_id: this.get_shop.id,
        show: toggle,
        ticket_type_uuid: ticketTypeUuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.oTable.draw(true)
          this.$toastr("info", "The request was processed", "Show/Hide ticket type")
        }
      })
    },
    loanAction () {
      const self = this
      $(document).off("click", ".btn-ticket-type-edit")
      $(document).on("click", ".btn-ticket-type-edit", function (e) {
        e.preventDefault()
        e.stopImmediatePropagation()
        self.$router.push({
          name: "ticket-type-edit",
          params: {
            ticket_type_uuid: $(this).attr("data-uuid")
          }
        })
      })

      $(document).off("click", ".btn-ticket-type-hide")
      $(document).on("click", ".btn-ticket-type-hide", function (e) {
        e.stopImmediatePropagation()
        e.preventDefault()
        self.onClickToggle($(this).attr("data-uuid"), false)
      })

      $(document).off("click", ".btn-ticket-type-show")
      $(document).on("click", ".btn-ticket-type-show", function (e) {
        e.stopImmediatePropagation()
        e.preventDefault()
        self.onClickToggle($(this).attr("data-uuid"), true)
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.loanAction()
  }
}
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
