<template>
  <div
    id="filterRecentSale"
    aria-labelledby="filterRecentSaleLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header-custom">
          <h4
            id="myModalLabel"
            class="modal-title capitalize"
          >
            {{ $t("label.filterRecentSale") }}
          </h4>
          <button
            ref="close"
            :class="class_btn_modal"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
          >
            <i
              aria-hidden="true"
              class="fas fa-times"
            />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <label class="la-4 user-select-none text-capitalize font-weight-normal">
                  {{ $t("fields.label.startDate") }}
                </label>
                <datepicker
                  v-model="start"
                  :clear-button="true"
                  :clear-button-icon="'fas fa-times'"
                  :format="dateFormat"
                  :input-class="'pos-input pos-input-lg'"
                  :placeholder="placeholder_display_date_format"
                  name="membershipStartDate"
                />
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <label class="la-4 user-select-none text-capitalize font-weight-normal">
                  {{ $t("fields.label.endDate") }}
                </label>
                <datepicker
                  v-model="end"
                  :clear-button="true"
                  :clear-button-icon="'fas fa-times'"
                  :disabled-dates="disabledDates"
                  :format="dateFormat"
                  :input-class="'pos-input pos-input-lg'"
                  :placeholder="placeholder_display_date_format"
                  name="membershipStartDate"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <label class="la-4 user-select-none text-capitalize font-weight-normal">
                  {{ $t("coupon.table.status") }}
                </label>
                <select
                  v-model="status"
                  class="pos-input pos-input-lg"
                >
                  <option :value="null">
                    {{ $t("label.all") }}
                  </option>
                  <option :value="false">
                    {{ $t("label.fullyPaid") }}
                  </option>
                  <option :value="true">
                    {{ $t("label.unpaid") }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <label class="la-4 user-select-none text-capitalize font-weight-normal">
                  {{ $t("label.paymentOption") }}
                </label>
                <select
                  v-model="payment_option"
                  class="pos-input pos-input-lg"
                >
                  <option :value="null">
                    {{ $t("label.all") }}
                  </option>
                  <template v-for="(item, each_payment_option_key) in paymentOptionsData">
                    <option
                      :value="item"
                      :key="each_payment_option_key"
                    >
                      {{ item["name_" + $i18n.locale] }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <label class="la-4 user-select-none text-capitalize font-weight-normal">
                  {{ $t("label.customer") }}
                </label>
                <input
                  v-model="customer"
                  :placeholder="$t('label.searchForCustomer')"
                  class="pos-input pos-input-lg"
                  type="text"
                >
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <label class="la-4 user-select-none text-capitalize font-weight-normal">
                  {{ $t("label.cashier") }}
                </label>
                <select
                  v-model="cashier"
                  class="pos-input pos-input-lg"
                >
                  <option :value="null">
                    {{ $t("label.all") }}
                  </option>
                  <template v-for="(item, each_cashier_key) in cashiersData">
                    <option
                      :value="item"
                      :key="each_cashier_key"
                    >
                      {{ item["full_name"] }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default btn-lg"
            @click="onClickReset"
          >
            <i class="fas fa-history" /> {{ $t("button.reset") }}
          </button>
          <button
            class="btn btn-success btn-lg"
            @click="onClickApply"
          >
            <i class="fas fa-check-circle" /> {{ $t("button.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "RecentSaleFilter",
  props: {
    paymentOptionsData: {
      type: Array,
      default: () => {
        return []
      }
    },
    cashiersData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      start: null,
      end: null,
      customer: null,
      cashier: null,
      status: null,
      payment_option: null,
      disabled_dates: {
        to: null
      }
    }
  },
  computed: {
    ...mapState({
      recent_sale_filter: state => state.sell.recent_sale_filter,
      dateFormat: state => state.sell.display_date_format,
      placeholder_display_date_format: state => state.sell.placeholder_display_date_format,
      disabledDates () {
        if (this.start) {
          this.disabled_dates.to = this.start
          if (this.end) {
            const s = this.$moment(this.start).format("YYYY-MM-DD")
            const e = this.$moment(this.end).format("YYYY-MM-DD")
            if (this.$moment(e).isBefore(s)) {
              this.end = null
            }
          }
        } else {
          this.disabled_dates.to = null
        }
        return this.disabled_dates
      }
    })
  },
  methods: {
    clearData () {
      this.start = null
      this.end = null
      this.customer = null
      this.cashier = null
      this.status = null
      this.payment_option = null
    },
    onClickApply () {
      this.$store.dispatch("sell/setRecentSaleFilter", {
        start_date: this.start,
        end_date: this.end,
        customer: this.customer,
        cashier: this.cashier,
        status: this.status,
        payment_option: this.payment_option
      })
      this.$emit("refreshList")
      this.$refs.close.click()
    },

    onClickReset () {
      this.clearData()
      this.$store.dispatch("sell/setRecentSaleFilter", {
        start_date: null,
        end_date: null,
        customer: null,
        cashier: null,
        status: null,
        payment_option: null
      })
      this.$emit("refreshList")
      this.$refs.close.click()
    }
  }
}
</script>
