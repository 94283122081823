<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <div class="card-header">
          <div>
            <button
              class="btn btn-default"
              @click="$router.push({name: 'ticket-type-list'})"
            >
              <i class="fas fa-vcard" /> {{ $t("label.manageTicket") }}
            </button>
            <button
              class="btn btn-default"
              @click="$router.push({name: 'aps-list-order'})"
            >
              <i class="fas fa-list-ul" /> {{ $t("label.listOrders") }}
            </button>
          </div>
          <div v-if="ticketTypes.length > 0">
            <button
              class="btn btn-primary btn-block"
              @click="storeOrder"
            >
              <i class="fas fa-check-circle" /> {{ $t("button.checkout") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <template v-if="ticketTypes.length > 0">
              <div class="col-md-12">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th />
                      <th>{{ $t("string.ticketType") }}</th>
                      <th>{{ $t("string.price") }}</th>
                      <th>{{ $t("string.totalPeople") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ticketType, _key) in ticketTypes"
                      :key="_key"
                    >
                      <td>
                        <button
                          class="btn btn-default btn-xs"
                          @click="removeTicketType(ticketType)"
                        >
                          <i class="fas fa-times" />
                        </button>
                      </td>
                      <td>{{ ticketType.name }}</td>
                      <td>$ {{ ticketType.price }}</td>
                      <td>
                        <input
                          :value="ticketType.total_people"
                          class="form-control"
                          type="number"
                          @change="updateTicketType(ticketType, $event)"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-number"
                        colspan="3"
                      >
                        {{ $t("string.subtotal") }}
                      </td>
                      <td class="text-number">
                        $ {{ subTotal.toFixed(2) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <div
              v-else
              class="col-md-12"
            >
              <empty-cart />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import EmptyCart from "@/components/EmptyCart"

export default {
  name: "OrderSection",
  components: { EmptyCart },
  computed: {
    ...mapState({
      ticketTypes: state => state.amusementPark.ticketTypes
    }),
    subTotal () {
      if (this.ticketTypes.length > 0) {
        let subTotal = 0
        this.ticketTypes.forEach((item) => {
          subTotal += (item.total_people * item.price)
        })
        return subTotal
      }
      return 0
    }
  },
  methods: {
    removeTicketType (ticketType) {
      this.$store.dispatch("amusementPark/removeTicketType", ticketType)
    },
    updateTicketType (ticketType, $event) {
      this.$nextTick(() => {
        this.$store.dispatch("amusementPark/updateTicketType", {
          ticketType: ticketType,
          value: $event.target.value
        })
      })
    },
    storeOrder () {
      this.$isLoading(true)
      const params = {
        shop_uuid: this.$config.shop_uuid,
        ticket_types: this.ticketTypes
      }
      if (this.$route.params.hasOwnProperty("order_uuid") &&
        this.$route.params.order_uuid !== undefined) {
        this.$set(params, "order_uuid", this.$route.params.order_uuid)
      }
      this.$axios.post(this.$config.base_url + "/api/amusement-park/order/store", {
        ...params
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.$router.push({
            name: "aps-payment",
            params: {
              order_uuid: response.data.data.uuid
            }
          })
          this.$store.dispatch("amusementPark/resetState")
          this.$anotify(this.$t("model.order"))
        }
      })
    },
    getOrder () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/amusement-park/order/view-order", {
        order_uuid: this.$route.params.order_uuid,
        shop_uuid: this.$config.shop_uuid
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.$store.dispatch("amusementPark/setState", response.data.data.ticketTypes)
        }
      })
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty("order_uuid") &&
      this.$route.params.order_uuid !== undefined) {
      this.getOrder()
    }
  }
}
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
