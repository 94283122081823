<template>
  <div class="d-flex flex-column h-100">
    <div class="non-footer-container flex-1">
      <div class="d-flex flex-column h-100">
        <NavbarV2 />
        <div class="container-fluid no-padding flex-1 w-100 overflow-y-auto scrollbar">
          <div class="d-flex h-100">
            <LeftSideBarMobileScreen>
              <LeftSideBarCustomer />
            </LeftSideBarMobileScreen>
            <div class="flex-1 d-flex aso-content-right">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foo hidden-xs">
      <DashboardFooter />
    </div>
  </div>
</template>

<script>
import NavbarV2 from "@/includes/NavbarV2"
import DashboardFooter from "@/includes/footer/Dashboard"
import LeftSideBarMobileScreen from "@/components/LeftSideBarMobileScreen"
import LeftSideBarCustomer from "@/components/customer/LeftSideBarCustomer"

export default {
  name: "Order",
  components: {
    LeftSideBarCustomer,
    LeftSideBarMobileScreen,
    DashboardFooter,
    NavbarV2
  }
}
</script>

<style scoped>
.non-footer-container {
  min-height: calc(100% - 50px);
}

@media screen and (max-height: 400px) {
  .foo {
    display: none;
  }
}
</style>
