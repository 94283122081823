import Index from "@/views/inventories/categories/Index"
import List from "@/views/inventories/categories/List"
import Create from "@/views/inventories/categories/Create"
import Edit from "@/views/inventories/categories/Edit"
import Show from "@/views/inventories/categories/Show"
import Order from "@/views/inventories/categories/Order"

export const categoryRouters = {
  path: "category",
  component: Index,
  name: "category",
  redirect: "/admin/inventories/category/",
  children: [
    {
      path: "",
      name: "list-product-category",
      component: List
    },
    {
      path: "create",
      name: "create-product-category",
      component: Create
    },
    {
      path: "order",
      name: "order-product-category",
      component: Order
    },
    {
      path: ":uuid/show",
      name: "show-product-category",
      component: Show
    },
    {
      path: ":uuid/edit",
      name: "edit-product-category",
      component: Edit
    }
  ]
}
