export const state = () => {
  return {
    menu: [
      {
        target: "subscription",
        icon: "fas fa-money-check",
        label: "string.subscription"
      },
      {
        target: "invoices",
        icon: "fas fa-file-invoice",
        label: "string.invoices"
      }
    ],
    menuActive: "subscription",
    selectedPlan: null,
    plans: [],
    currentPlan: null,
    step: 1,
    invoice: {
      step: 1
    },
    showAlertChangePlan: true,
    showAlertRenewalPlan: true
  }
}

export const getters = {
  menu: state => state.menu,
  menuActive: state => state.menuActive,
  plans: state => state.plans,
  currentPlan: state => state.currentPlan,
  selectedPlan: state => state.selectedPlan,
  step: state => state.step,
  showInvoiceList: state => state.invoice.step === 1,
  showAlertChangePlan: state => state.showAlertChangePlan,
  showAlertRenewalPlan: state => state.showAlertRenewalPlan
}

export const mutations = {
  switchMenu (state, target) {
    state.menuActive = target
  },

  setSelectedPlan (state, plan) {
    state.selectedPlan = plan
  },

  setPlans (state, plans) {
    state.plans = plans
  },

  setCurrentPlan (state, plan) {
    state.currentPlan = plan
  },

  setStep (state, step) {
    state.step = step
  },

  showInvoiceList (state) {
    state.invoice.step = 1
  },

  showRenewForm (state) {
    state.invoice.step = 2
  },

  setShowAlertChangePlan (state, show) {
    state.showAlertChangePlan = show
  },

  setShowAlertRenewalPlan (state, show) {
    state.showAlertRenewalPlan = show
  }
}

export const actions = {
  switchMenu ({ commit }, target) {
    commit("switchMenu", target)
  },

  setSelectedPlan ({ commit }, plan) {
    commit("setSelectedPlan", plan)
  },

  setPlans ({ commit }, plans) {
    commit("setPlans", plans)
  },

  setCurrentPlan ({ commit }, plan) {
    commit("setCurrentPlan", plan)
  },

  setStep ({ commit }, step) {
    commit("setStep", step)
  },

  showInvoiceList ({ commit }) {
    commit("showInvoiceList")
  },

  showRenewForm ({ commit }) {
    commit("showRenewForm")
  },

  setShowAlertChangePlan ({ commit }, show) {
    commit("setShowAlertChangePlan", show)
  },
  setShowAlertRenewalPlan ({ commit }, show) {
    commit("setShowAlertRenewalPlan", show)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
