<template>
  <div class="row">
    <div class="col-md-12 margin-top">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div>
            <h3 class="m-0 font-weight-bold text-primary payment-subtitle">
              {{ $t("label.orders") }}
            </h3>
          </div>
          <div>
            <button
              class="btn btn-success"
              @click="$router.push({name: 'amusement-park-order'})"
            >
              <i class="fas fa-plus-circle" /> {{ $t("label.newOrder") }}
            </button>
            <button
              class="btn btn-default"
              @click="$router.push({name: 'amusement-park-order'})"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <table
            id="order-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("table.order.orderNumber") }}</th>
                <th>{{ $t("table.order.orderedBy") }}</th>
                <th>{{ $t("table.order.totalPeople") }}</th>
                <th>{{ $t("table.order.createdAt") }}</th>
                <th>{{ $t("table.order.actions") }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "List",
  metaInfo () {
    return {
      title: "List Orders",
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      oTable: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $("#order-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: self.$config.base_url + "/api/amusement-park/order/data",
          type: "POST",
          data: (d) => {
            d.shop_uuid = self.$config.shop_uuid
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "order_number",
            name: "order_number",
            title: self.$t("table.order.orderNumber")
          },
          {
            data: "creator_id",
            name: "creator_id",
            title: self.$t("table.order.orderedBy")
          },
          {
            data: "total_people",
            name: "total_people",
            title: self.$t("table.order.totalPeople"),
            render: function (data) {
              if (data > 1) {
                return data + " " + self.$t("label.people")
              }
              return data + " " + self.$t("label.person")
            }
          },
          {
            data: "created_at",
            name: "created_at",
            searchable: false,
            orderable: true,
            title: self.$t("table.order.createdAt"),
            render: function (data) {
              let format = "LLLL"
              if (self.$i18n.locale === "en") {
                format = "llll"
              }
              return self.$moment(data).locale(self.$i18n.locale).format(format)
            }
          },
          {
            data: "action",
            name: "action",
            searchable: false,
            orderable: false,
            title: self.$t("table.order.actions")
          }
        ],
        order: [[3, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    deleteOrder (uuid) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.$config.base_url + "/api/amusement-park/order/delete", {
          shop_uuid: self.$config.shop_uuid,
          uuid: uuid
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.order"))
            self.oTable.draw(true)
          }
        })
      })
    },
    loanAction () {
      const self = this
      $(document).off("click", ".btn-aps-order-delete")
      $(document).on("click", ".btn-aps-order-delete", function (e) {
        e.preventDefault()
        self.deleteOrder($(this).attr("data-uuid"))
      })
      $(document).off("click", ".btn-aps-order-payment")
      $(document).on("click", ".btn-aps-order-payment", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "aps-payment",
          params: {
            order_uuid: $(this).attr("data-uuid")
          }
        })
      })

      $(document).off("click", ".btn-aps-order-edit")
      $(document).on("click", ".btn-aps-order-edit", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "aps-order",
          params: {
            order_uuid: $(this).attr("data-uuid")
          }
        })
      })

      $(document).off("click", ".btn-aps-order-show")
      $(document).on("click", ".btn-aps-order-show", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "aps-view-order",
          params: {
            order_uuid: $(this).attr("data-uuid")
          }
        })
      })

      $(document).off("click", ".btn-aps-order-print")
      $(document).on("click", ".btn-aps-order-print", function () {
        window.open(`/amusement-park/print/${$(this).attr("data-uuid")}`, "_blank")
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.loanAction()
  }
}
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
