<template>
  <div class=" card-review-report bg-white rounded">
    <label
      v-if="title"
      class="font-s-16 text-muted text-uppercase"
    >{{ $t(title) }}
      <slot name="title" />
    </label>
    <div class="margin-bottom">
      <label class="d-block font-s-28 text-bold no-margin">
        <template v-if="hasFormat">
          <template v-if="hasCurrency">{{ shop_currency ? shop_currency.code : "" }}</template>
          {{ mainValue | numFormat(num_format) }}
          <slot name="main-value" />
        </template>
        <template v-else>
          <template v-if="hasCurrency">{{ shop_currency ? shop_currency.code : "" }}</template>
          {{ mainValue }}
          <slot name="main-value" />
        </template>
        <small
          :class="'text-' + colorLabelCompare"
          class="d-block font-s-16"
        >
          <i
            :class="classIcon"
            class="fas font-s-18 mr-2"
          />
          <template v-if="labelCompare">{{ $t(labelCompare) }}</template>
          <template v-if="calcCompValueAsPercent">
            ({{ calcCompValueAsPercent | numFormat(num_format) }}%)
          </template>
        </small>
      </label>
      <small class="text-muted font-s-16 text-bold">
        <template v-if="hasFormat">
          <template v-if="hasCurrency">{{ shop_currency ? shop_currency.code : "" }}</template>
          {{ lastData | numFormat(num_format) }}
          <slot name="last-value" />
        </template>
        <template v-else>
          <template v-if="hasCurrency">{{ shop_currency ? shop_currency.code : "" }}</template>
          {{ lastData }}
          <slot name="last-value" />
        </template>
      </small>
    </div>
    <div>
      <LineChart
        :chart-data="chartData"
        :height="150"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import LineChart from "@/views/reports/chart/lineChart"

export default {
  name: "CardReportDashboard",
  components: { LineChart },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      shop_currency: state => state.shop.currency
    })
  },
  props: {
    title: {
      type: String,
      default: null
    },
    mainValue: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    },
    calcCompValueAsPercent: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    },
    colorLabelCompare: {
      type: String,
      default: "no_change"
    },
    labelCompare: {
      type: String,
      default: null
    },
    classIcon: {
      type: String,
      default: null
    },
    hasCurrency: {
      type: Boolean,
      default: false
    },
    hasFormat: {
      type: Boolean,
      default: false
    },
    lastData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    },
    chartData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped>

.card-review-report {
  padding: 15px;
}

.text-up {
  color: #00a65a !important;
}

.text-down {
  color: #dd4b39 !important;
}

.text-no_change {
  color: #777;
}
</style>
