<template>
  <div>
    <ProductFormCreate
      :label-type="$t('model.food')"
      columns=""
    />
  </div>
</template>

<script>
import ProductFormCreate from "./FormCreate"

export default {
  components: { ProductFormCreate },
  name: "CreateProduct",
  metaInfo () {
    return {
      title: this.$t("page.addNewProduct"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
