<template>
  <div class="row">
    <div class="col-md-12 margin-top">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div>
            <h3 class="m-0 font-weight-bold text-primary payment-subtitle">
              {{ $t("label.viewOrder") }}
            </h3>
          </div>
          <div>
            <button
              class="btn btn-default"
              @click="$router.push({name: 'list-coupon'})"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </button>
            <button
              v-if="coupons.length > 0"
              class="btn btn-default"
              @click="$router.push({name: 'print-coupon-cards', params: {coupon_order_uuid: $route.params.coupon_order_uuid}})"
            >
              <i class="fas fa-print" /> {{ $t("button.print") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            v-if="coupons && Array.isArray(coupons) && coupons.length > 0"
            class="row"
          >
            <div class="col-md-12">
              <h3>{{ shop.name }}</h3>
              <p>{{ shop.address }}</p>
              <p class="big-text">
                #{{ order.order_number }}
              </p>
              <p>{{ order.created_at }}</p>
            </div>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("string.amount") }}</th>
                    <th>{{ $t("string.quantity") }}</th>
                    <th>{{ $t("string.total") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(coupon, _key) in coupons"
                    :key="_key"
                  >
                    <td>{{ coupon.amount }}</td>
                    <td>{{ coupon.total }}</td>
                    <td>{{ coupon.currency.code }} {{ coupon.total * coupon.amount }}</td>
                  </tr>
                  <template v-if="invoice !== null">
                    <tr>
                      <td
                        class="text-right text-muted"
                        colspan="2"
                      >
                        {{ $t("string.subtotal") }}
                      </td>
                      <td>USD {{ parseFloat(invoice.subtotal).toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted"
                        colspan="2"
                      >
                        {{ $t("string.discount") }}
                      </td>
                      <td>% {{ invoice.discount }}</td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted"
                        colspan="2"
                      >
                        {{ $t("string.tax") }}
                      </td>
                      <td>% {{ invoice.tax }}</td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted"
                        colspan="2"
                      >
                        {{ $t("string.tax") }}
                      </td>
                      <td>USD {{ invoice.tax }}</td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted vertical-align"
                        colspan="2"
                      >
                        {{
                          $t("string.paymentAmount")
                        }}
                      </td>
                      <td>
                        USD {{ invoice.total_paid_dollar }}
                        {{ $t("label.and") }} KHR {{ invoice.total_paid_riel }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted vertical-align"
                        colspan="2"
                      >
                        {{
                          $t("string.balanceDue")
                        }}
                      </td>
                      <td>
                        USD {{ invoice.balance_due_dollar }} {{ $t("label.or") }} (KHR
                        {{ invoice.balance_due_riel }})
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted vertical-align"
                        colspan="2"
                      >
                        {{
                          $t("string.balanceOwe")
                        }}
                      </td>
                      <td>
                        USD {{ invoice.balance_owe_dollar || 0 }} {{ $t("label.or") }} (KHR {{
                          invoice.balance_owe_riel || 0
                        }})
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted"
                        colspan="2"
                      >
                        {{ $t("string.whoResponsibility") }}
                      </td>
                      <td>{{ invoice.cashier.full_name }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else
            class="row"
          >
            <no-any-records />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoAnyRecords from "@/components/NoAnyRecords"

export default {
  name: "Show",
  components: { NoAnyRecords },
  metaInfo () {
    return {
      title: this.$t("label.viewOrder"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      coupons: [],
      shop: null,
      invoice: null,
      order: null
    }
  },
  methods: {
    getCouponOrder () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/coupon/show", {
        coupon_order_uuid: this.$route.params.coupon_order_uuid,
        shop_id: this.get_shop.id
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.coupons = response.data.data.coupons
          this.shop = response.data.data.shop
          this.invoice = response.data.data.invoice
          this.order = response.data.data.order
        }
      })
    }
  },
  mounted () {
    this.getCouponOrder()
  }
}
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vertical-align {
  vertical-align: top;
}
</style>
