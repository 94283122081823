<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("strings.listingPromotions") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'create-promotion'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("button.newPromotion") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-2 col-sm-3 col-xs-4 margin-bottom">
                <multiselect
                  v-model="selectedActiveOption"
                  :allow-empty="false"
                  :option-height="104"
                  :options="activeOptions"
                  :placeholder="$t('string.pleaseSelect')"
                  :show-labels="false"
                  label="label"
                  track-by="value"
                  @input="getPromotion"
                />
              </div>
            </div>
            <table
              id="promotion-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("table.promotion.name") }}</th>
                  <th>{{ $t("table.product.name") }}</th>
                  <th>{{ $t("table.promotion.amountPercentage") }}</th>
                  <th>{{ $t("table.promotion.start") }}</th>
                  <th>{{ $t("table.promotion.end") }}</th>
                  <th>{{ $t("table.promotion.status") }}</th>
                  <th>{{ $t("table.promotion.createdAt") }}</th>
                  <th>{{ $t("table.promotion.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <PromotionDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>

import PromotionDataTableSm from "@/views/inventories/promotion/_components/PromotionDataTableSm"

export default {
  name: "PromotionList",
  components: { PromotionDataTableSm },
  metaInfo () {
    return {
      title: this.$t("strings.listingPromotions"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null,
      selectedActiveOption: {
        label: this.$t("report.label.all"),
        value: ""
      },
      activeOptions: [
        {
          label: this.$t("report.label.all"),
          value: ""
        },
        {
          label: this.$t("label.active"),
          value: true
        },
        {
          label: this.$t("label.inactive"),
          value: false
        }
      ],
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    windowWidth () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    oTable: function () {
      this.reloadButtonAction()
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    deletePromotion (id) {
      this.$axios.post(this.$config.base_url + "/api/promotion/delete", {
        shop_id: this.get_shop.id,
        uuid: id
      }).then((response) => {
        if (response.data.code === 1) {
          this.showDeleted()
          this.oTable.draw(true)
        }
      }).catch((error) => {
        this.$toastr("error", error, "Get Tax")
      })
    },
    getPromotion () {
      this.oTable.draw(true)
    },
    dataTable () {
      const self = this
      return $("#promotion-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.config.base_url + "/api/promotion/data",
          type: "POST",
          data: (d) => {
            d.uuid = self.config.shop_uuid
            d.active = self.selectedActiveOption.value
            d.lang = self.$i18n.locale
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "name",
            name: "name",
            title: self.$t("table.promotion.name")
          },
          {
            data: "products",
            name: "products",
            render: function (data, type, row) {
              let name = ""
              data.forEach(function (product) {
                name += "<li>" + product.name + "</li>"
              })
              return name
            },
            title: self.$t("table.product.name")
          },
          {
            data: "amount_percentage",
            name: "amount_percentage",
            title: self.$t("table.promotion.amountPercentage")
          },
          {
            data: "start",
            name: "start",
            searchable: false,
            orderable: false,
            title: self.$t("table.promotion.start")
          },
          {
            data: "end",
            name: "end",
            searchable: false,
            orderable: false,
            title: self.$t("table.promotion.end")
          },
          {
            data: "active",
            name: "active",
            searchable: false,
            orderable: false,
            title: self.$t("table.promotion.status")
          },
          {
            data: "created_at",
            name: "created_at",
            searchable: false,
            orderable: false,
            title: self.$t("table.promotion.createdAt")
          },
          {
            data: "actions",
            name: "actions",
            searchable: false,
            orderable: false,
            title: self.$t("table.promotion.actions")
          }
        ],
        order: [[3, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    reloadButtonAction () {
      const self = this
      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-edit")
      $(document).off("click", ".btn-delete")

      $(document).on("click", ".btn-show", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "show-promotion",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-edit", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "edit-promotion",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-delete", function (e) {
        e.preventDefault()
        self.$swal({
          title: this.swalTitle,
          text: this.$t("swal.confirm_delete.desc"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: this.$t("swal.confirm_delete.button.yes"),
          cancelButtonText: this.$t("swal.confirm_delete.button.no")
        }).then(() => {
          self.deletePromotion($(this).attr("data-uuid"))
        })
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
