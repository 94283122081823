<template>
  <div class="d-flex flex-column h-100">
    <div class="non-footer-container flex-1">
      <div class="d-flex flex-column h-100">
        <NavbarV2 />
        <div class="container-fluid no-padding flex-1 w-100 scrollbar">
          <div class="d-flex h-100">
            <LeftSideBarMobileScreen>
              <SidebarReportV2 />
            </LeftSideBarMobileScreen>
            <div class="flex-1 product_container-fluid minus-w-sidebar">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foo">
      <DashboardFooter />
    </div>
  </div>
</template>

<script>
import NavbarV2 from "@/includes/NavbarV2"
import DashboardFooter from "@/includes/footer/Dashboard"
import SidebarReportV2 from "@/includes/v2/sidebar/SidebarReportV2"
import LeftSideBarMobileScreen from "@/components/LeftSideBarMobileScreen"

export default {
  name: "Reports",
  components: {
    DashboardFooter,
    NavbarV2,
    LeftSideBarMobileScreen,
    SidebarReportV2
  },
  computed: {
    label () {
      return {
        noChange: this.$t("label.no_change"),
        customerCount: this.$t("label.customerCount"),
        discounted: this.$t("label.discounted")
      }
    }
  }
}
</script>

<style scoped>

</style>
