<template>
  <div class="h-100">
    <div class="scrollbar">
      <div class="row">
        <AdditionalApps />
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalApps from "@/components/AdditionalApps"

export default {
  name: "List",
  metaInfo () {
    return {
      title: this.$t("menu.settings.additional_apps"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: { AdditionalApps }
}
</script>

<style scoped>

</style>
