<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("strings.product.warehouse.listingWarehouse") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'create-warehouse'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("food.button.newWarehouse") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <table
              id="warehouse-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("food.table.name") }}</th>
                  <th>{{ $t("food.table.description") }}</th>
                  <th>{{ $t("food.table.createdAt") }}</th>
                  <th>{{ $t("food.table.action") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <WarehouseDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>

import WarehouseDataTableSm from "./_components/WarehouseDataTableSm"

export default {
  name: "ListWarehouses",
  components: { WarehouseDataTableSm },
  metaInfo () {
    return {
      title: this.$t("strings.product.warehouse.listingWarehouse"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null,
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    windowWidth () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    oTable () {
      this.reloadButtonActions()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    refreshListingTable () {
      this.oTable.draw(true)
    },
    dataTable () {
      const self = this
      return $("#warehouse-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: self.config.base_url + "/api/warehouse/data",
          type: "POST",
          data: {
            shop_id: self.get_shop.id,
            labelEdit: self.labelEdit,
            labelDelete: self.labelDelete,
            labelShow: self.labelShowTrans,
            labelViewStock: self.labelShowStock,
            lang: localStorage.getItem("locale")
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "name_" + this.$i18n.locale,
            name: "name_" + this.$i18n.locale,
            title: self.$t("food.table.name")
          },
          {
            data: "description",
            name: "description",
            title: self.$t("food.table.description")
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("food.table.createdAt"),
            searchable: false,
            orderable: true
          },
          {
            data: "actions",
            name: "actions",
            title: self.$t("food.table.action"),
            searchable: false,
            orderable: false
          }
        ],
        order: [
          [2, "desc"]
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    deleteWarehouse (params) {
      const self = this
      self.$swal({
        title: this.swalTitle,
        text: this.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.swalYesButton,
        cancelButtonText: this.swalNoButton
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/warehouse/delete", {
          ...params
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.warehouse"))
            self.oTable.draw(true)
          }
          if (response.data.code === 0) {
            if (typeof (response.data.message) === "string") {
              self.$toastr("error", response.data.message, "Notification")
            }
          }
        })
      })
    },
    reloadButtonActions () {
      const self = this
      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-edit-warehouse")
      $(document).off("click", ".btn-delete-warehouse")

      $(document).on("click", ".btn-show", function () {
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "show-warehouse",
          params: { uuid: uuid }
        })
      })

      $(document).on("click", ".btn-edit-warehouse", function () {
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "edit-warehouse",
          params: { uuid: uuid }
        })
      })

      $(document).on("click", ".btn-delete-warehouse", function () {
        const uuid = $(this).attr("data-uuid")
        const params = {
          shop_id: self.get_shop.id,
          uuid: uuid
        }
        self.deleteWarehouse(params)
      })
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonActions()
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
