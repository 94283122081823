<template>
  <div class="flex-1 d-flex">
    <div class="d-flex flex-column w-100 bg-white">
      <div class="d-flex h-100">
        <div class="tabBlock-pane px-0 pb-0 w-100">
          <div class="d-flex flex-column h-100">
            <div class="shadow-bottom z-index-2">
              <div class="row mx-0">
                <div class="col-xs-12">
                  <div class="form-group user-select-none">
                    <label class="title-section">
                      {{ $t("list.holdSale") }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mx-0">
                <div class="col-xs-12 d-flex align-items-center park-sale-list">
                  <div class="layout-search">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="w-100 box-search">
                          <div class="box-search-icon margin-l-5">
                            <i class="fas fa-search" />
                          </div>
                          <input
                            v-model="search_query"
                            :placeholder="$t('label.searchOrderNumber')"
                            class="pos-input-search pos-input-lg no-border"
                            type="text"
                          >
                        </div>
                        <div class="input-group-btn">
                          <button
                            :title="$t('label.filter')"
                            class="btn btn-default btn-lg"
                            data-placement="top"
                            data-target="#filterParkedSaleOffline"
                            data-toggle="modal"
                          >
                            <i class="fas fa-filter" />
                            <span class="hide-label-sm ml-2">{{ $t("label.filter") }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group no-margin font-s-18">
                      <div
                        v-if="parked_sale_filter.start_date || parked_sale_filter.end_date"
                        class="minus"
                      >
                        <label v-if="parked_sale_filter.start_date">
                          {{
                            $moment(parked_sale_filter.start_date).locale($i18n.locale).format(dateFormatLocaleNoTime)
                          }}
                        </label>
                        <label v-if="parked_sale_filter.end_date">
                          {{
                            $moment(parked_sale_filter.end_date).locale($i18n.locale).format(dateFormatLocaleNoTime)
                          }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="align-items-center layout-view-mode hidden-xs"
                    style="min-height: 61px"
                  >
                    <div class="form-group">
                      <div
                        class="btn-group btn-group-lg switch-view ml-2"
                        role="group"
                      >
                        <button
                          :class="{'btn-active': park_sale_view_mode === 'grid'}"
                          :title="$t('label.viewAsGrid')"
                          class="btn btn-default outline-none"
                          type="button"
                          @click="changeViewModeParkSale(grid_mode)"
                        >
                          <i class="fas fa-th" />
                        </button>
                        <button
                          :class="{'btn-active': park_sale_view_mode === 'list'}"
                          :title="$t('label.viewAsList')"
                          class="btn btn-default outline-none"
                          type="button"
                          @click="changeViewModeParkSale(list_mode)"
                        >
                          <i class="fas fa-th-list" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="onLoading">
              <div class="d-flex align-items-center justify-content-center on-loading-layout">
                <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
              </div>
            </template>
            <template v-else>
              <div class="flex-1 d-flex h-100 overflow-hidden">
                <div class="list-park-order d-flex flex-column scrollbar user-select-none w-100">
                  <div class="h-100">
                    <template v-if="!(getParkOrders.length > 0)">
                      <no-any-records :height="50" />
                    </template>
                    <template v-else>
                      <template v-if="park_sale_view_mode === grid_mode && windowWidth > 769">
                        <div class="row mx-0">
                          <template v-for="(item, key) in getParkOrders">
                            <div
                              :key="key"
                              class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-5 pr-0"
                            >
                              <div class="order-card">
                                <div class="order-card-header pb-0">
                                  <div class="d-flex align-items-center justify-content-between has-border-b pb-3">
                                    <div># {{ item.order_number }}</div>
                                    <div
                                      v-if="item.created_at"
                                      class="order-created-date"
                                    >
                                      <i class="fas fa-clock" />
                                      {{ $moment(item.created_at).format("HH:mm MMM DD YYYY") }}
                                    </div>
                                  </div>
                                </div>
                                <div class="order-card-body">
                                  <template v-for="(product, each_product_key) in item.product">
                                    <div
                                      class="row no-margin product-item"
                                      :key="each_product_key"
                                      v-if="key <= 3"
                                    >
                                      <div class="col-xs-6 pl-0 text-ellipsis">
                                        {{ product.name }}
                                      </div>
                                      <div class="col-xs-6 pr-0">
                                        <div class="d-flex justify-content-between">
                                          <div>X {{ product.pivot.quantity }}</div>
                                          <div>
                                            <span v-if="product.currency">{{ product.currency.symbol }}</span>
                                            <span>
                                              <template v-if="product.currency && product.currency.code === 'KHR'">
                                                {{ parseFloat(product.sale_price) | numFormat("0,0") }}
                                              </template>
                                              <template v-else>
                                                {{ parseFloat(product.sale_price) | numFormat(num_format) }}
                                              </template>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-if="item.product.length > 4">
                                    <div class="row product-item">
                                      <div class="col-xs-6 col-lg-7 text-ellipsis">
                                        {{ $t("label.more") }}
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <div class="order-card-footer pt-1">
                                  <div class="has-border-t font-s-16">
                                    <div class="d-flex align-items-center justify-content-between w-100 pt-3 pb-3">
                                      <div>{{ $t("recent_sell.label.customer") }}</div>
                                      <div class="text-bold">
                                        {{ item.customer ? item.customer.name : "-" }}
                                      </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between w-100 pb-3">
                                      <div>{{ $t("label.soldBy") }}</div>
                                      <div class="text-bold">
                                        {{ item.cashier ? item.cashier.full_name : "-" }}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="has-border-t text-right"
                                    style="padding-top: 10px"
                                  >
                                    <div class="d-flex align-items-center justify-content-end">
                                      <div class="btn-card-order mr-2">
                                        <button
                                          class="btn btn-lg btn-default"
                                          @click="deleteOrder(item)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </button>
                                      </div>
                                      <div class="btn-card-order">
                                        <button
                                          class="btn btn-lg btn-primary"
                                          @click="openToCart(item.uuid)"
                                        >
                                          <i class="fas fa-cart-plus mr-2" />
                                          <span class="ml-2">{{ $t("label.continueSell") }}</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <div class="park-sale-list">
                          <div class="tabBlock-pane pt-0 d-flex flex-column">
                            <div class="flex-1 overflow-y-auto h-100 scrollbar">
                              <template v-for="(item, key) in getParkOrders">
                                <div
                                  :key="key"
                                  :title="'Order Date: ' + $moment(item.created_at).locale($i18n.locale).format(dateFormatLocale)"
                                  class="user-select-none"
                                >
                                  <div class="sugg-customer-each px-3 py-3">
                                    <div class="layout-content-recent-sell-list font-s-16">
                                      <div class="order-label-number d-flex align-items-center">
                                        <div class="w-100 text-ellipsis">
                                          #{{ item.order_number }}
                                        </div>
                                      </div>
                                      <div class="order-label-date d-flex align-items-center w-30">
                                        <div class="w-100 text-ellipsis line-h-1_5">
                                          {{
                                            $moment(item.created_at).locale($i18n.locale).format("MMM DD HH:mm:ss")
                                          }}
                                        </div>
                                      </div>
                                      <div class="order-cashier-name w-25">
                                        {{ item.cashier ? item.cashier.full_name : "-" }}
                                      </div>
                                      <div class="has-border-t">
                                        <div class="d-flex align-items-center justify-content-end">
                                          <div class="btn-card-order mr-2">
                                            <button
                                              class="btn btn-lg btn-default"
                                              @click="deleteOrder(item.uuid)"
                                            >
                                              <i class="fas fa-trash-alt" />
                                            </button>
                                          </div>
                                          <div class="btn-card-order">
                                            <button
                                              class="btn btn-lg btn-primary"
                                              @click="openToCart(item.uuid)"
                                            >
                                              <i class="fas fa-cart-plus mr-2" />
                                              <span class="ml-2">{{ $t("label.continueSell") }}</span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="layout-content-recent-sell-card">
                                      <div class="row no-margin">
                                        <div class="col-sm-8 no-padding">
                                          <div class="row no-margin">
                                            <div class="col-xs-6 col-md-4 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      {{ $t("recent_sell.label.orderNumber") }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-xs-6 col-md-8 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      <strong>#{{ item.order_number }}</strong>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row no-margin">
                                            <div class="col-xs-6 col-md-4 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      {{ $t("recent_sell.label.orderDate") }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-xs-6 col-md-8 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      {{
                                                        $moment(item.created_at).locale($i18n.locale).format("MMM DD HH:mm:ss")
                                                      }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row no-margin">
                                            <div class="col-xs-6 col-md-4 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      {{ $t("label.cashier") }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-xs-6 col-md-8 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      <strong>
                                                        {{ item.cashier ? item.cashier.full_name : "-" }}
                                                      </strong>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-4 no-padding">
                                          <div class="row no-margin">
                                            <div class="col-xs-12 no-padding">
                                              <div class="has-border-t">
                                                <div class="d-flex align-items-center justify-content-end">
                                                  <div class="btn-card-order mr-2">
                                                    <button
                                                      class="btn btn-lg btn-default"
                                                      @click="deleteOrder(item.uuid)"
                                                    >
                                                      <i class="fas fa-trash-alt" />
                                                    </button>
                                                  </div>
                                                  <div class="btn-card-order">
                                                    <button
                                                      class="btn btn-lg btn-primary"
                                                      @click="openToCart(item.uuid)"
                                                    >
                                                      <i class="fas fa-cart-plus mr-2" />
                                                      <span class="ml-2">{{ $t("label.continueSell") }}</span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      id="filterParkedSaleOffline"
      aria-labelledby="filterParkedSaleLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header-custom">
            <h4
              id="myModalLabel"
              class="modal-title capitalize"
            >
              {{ $t("label.filterParkedSale") }}
            </h4>
            <button
              ref="close"
              :class="class_btn_modal"
              aria-label="Close"
              data-dismiss="modal"
              type="button"
            >
              <i
                aria-hidden="true"
                class="fas fa-times"
              />
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group">
                  <label class="font-s-16 user-select-none">{{ $t("fields.label.startDate") }}</label>
                  <datepicker
                    v-model="parked_sale_filter.start_date"
                    :clear-button="true"
                    :clear-button-icon="'fas fa-times'"
                    :format="dateFormat"
                    :input-class="'pos-input pos-input-lg'"
                    :placeholder="placeholder_display_date_format"
                    name="membershipStartDate"
                  />
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group">
                  <label class="font-s-16 user-select-none">{{ $t("fields.label.endDate") }}</label>
                  <datepicker
                    v-model="parked_sale_filter.end_date"
                    :clear-button="true"
                    :clear-button-icon="'fas fa-times'"
                    :disabled-dates="disabledDates"
                    :format="dateFormat"
                    :input-class="'pos-input pos-input-lg'"
                    :placeholder="placeholder_display_date_format"
                    name="membershipStartDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default btn-lg"
              @click="onClickReset"
            >
              <i class="fas fa-history" /> {{ $t("button.reset") }}
            </button>
            <button
              class="btn btn-success btn-lg"
              @click="onClickApply"
            >
              <i class="fas fa-check-circle" /> {{ $t("button.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoAnyRecords from "@/components/NoAnyRecords"
import indexedb from "@/indexedb"
import { mapState } from "vuex"

export default {
  name: "ListVersion2",
  components: {
    NoAnyRecords
  },
  metaInfo () {
    return {
      title: this.$t("string.orderList"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      cd_format: "YYYY-MM-DD",
      park_order_list: [],
      tmp: [],
      search_query: "",
      onLoading: true,
      park_sale_view_mode: "grid",
      grid_mode: "grid",
      list_mode: "list",
      parked_sale_filter: {
        start_date: null,
        end_date: null
      },
      disabled_dates: {
        to: null
      },
      windowWidth: null
    }
  },
  watch: {
    search_query () {
      if (this.search_query === "") {
        this.park_order_list = this.tmp
      }
    }
  },
  computed: {
    ...mapState({
      dateFormat: state => state.sell.display_date_format,
      placeholder_display_date_format: state => state.sell.placeholder_display_date_format,
      num_format: state => state.shop.numFormat,
      shop_currency: state => state.shop.currency
    }),
    getParkOrders () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.park_order_list = this.tmp
      if (this.search_query !== "") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.park_order_list = this.park_order_list.filter((item) => {
          const order_number = item.order_number.toString().toLowerCase()
          const search_query = this.search_query.toString().toLowerCase()
          if (order_number.includes(search_query)) {
            return item
          }
        })
      }
      this.filterOrder()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.park_order_list.sort((first, second) => {
        const a = first.order_number
        const b = second.order_number
        if (a > b) {
          return 1
        } else if (a < b) {
          return -1
        } else {
          return 0
        }
      })
    },
    disabledDates () {
      if (this.parked_sale_filter.start_date) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.disabled_dates.to = this.parked_sale_filter.start_date
        if (this.parked_sale_filter.end_date) {
          const s = this.$moment(this.parked_sale_filter.start_date).format("YYYY-MM-DD")
          const e = this.$moment(this.parked_sale_filter.end_date).format("YYYY-MM-DD")
          if (this.$moment(e).isBefore(s)) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.parked_sale_filter.end_date = null
          }
        }
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.disabled_dates.to = null
      }
      return this.disabled_dates
    }
  },
  mounted () {
    if (!localStorage.hasOwnProperty("park_sale_view_mode")) {
      localStorage.setItem("park_sale_view_mode", this.grid_mode)
      this.park_sale_view_mode = this.grid_mode
    } else {
      this.park_sale_view_mode = localStorage.getItem("park_sale_view_mode")
    }
    this.getOrders()
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
    })
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    clearData () {
      this.parked_sale_filter = {
        start_date: null,
        end_date: null
      }
    },
    onClickApply () {
      // eslint-disable-next-line no-unused-expressions
      this.getParkOrders
      this.$refs.close.click()
    },
    onClickReset () {
      this.clearData()
      // eslint-disable-next-line no-unused-expressions
      this.getParkOrders
      this.$refs.close.click()
    },
    filterOrder () {
      this.park_order_list = this.park_order_list.filter((item) => {
        if (item.created_at) {
          const od = this.$moment(item.created_at).format(this.cd_format)
          if (this.parked_sale_filter.start_date && this.parked_sale_filter.end_date) {
            const sd = this.$moment(this.parked_sale_filter.start_date).format(this.cd_format)
            const ed = this.$moment(this.parked_sale_filter.end_date).format(this.cd_format)
            if ((this.$moment(od).isSame(sd) || this.$moment(od).isAfter(sd)) && (this.$moment(od).isSame(ed) || this.$moment(od).isBefore(ed))) {
              return item
            }
          } else if (this.parked_sale_filter.start_date && !this.parked_sale_filter.end_date) {
            const sd = this.$moment(this.parked_sale_filter.start_date).format(this.cd_format)
            if (this.$moment(od).isSame(sd) || this.$moment(od).isAfter(sd)) {
              return item
            }
          } else if (!this.parked_sale_filter.start_date && this.parked_sale_filter.end_date) {
            const ed = this.$moment(this.parked_sale_filter.end_date).format(this.cd_format)
            if (this.$moment(od).isSame(ed) || this.$moment(od).isBefore(ed)) {
              return item
            }
          } else {
            return item
          }
        }
      })
    },
    getOrders () {
      indexedb.all("orders")
        .then((orders) => {
          this.tmp = orders.filter((item) => {
            if (!(item.hasOwnProperty("is_paid"))) {
              return item
            }
          })
          this.park_order_list = this.tmp
        })
        .finally(() => {
          this.onLoading = false
        })
    },
    deleteOrder (order) {
      const uuid = order.uuid
      this.$swal({
        title: this.swalTitle,
        text: this.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.swalYesButton,
        cancelButtonText: this.swalNoButton
      }).then(() => {
        indexedb.remove("orders", uuid)
          .then(() => {
            this.onResponseSuccess()
            this.getOrders()
          })
          .then(() => {
            indexedb.remove("order_products", uuid)
          })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },
    changeViewModeParkSale (mode) {
      this.park_sale_view_mode = mode
      localStorage.setItem("park_sale_view_mode", mode)
    },
    openToCart (uuid) {
      this.$router.push({
        name: "offline-sale",
        params: { order_uuid: uuid }
      })
    }
  }
}
</script>

<style scoped>
@import '../../../../assets/css/V2/tab-style.css';

.park-sale-list {
  width: 45%;
}

/*==========================================================================*/
.order-card {
  border-radius: 6px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
}

.order-card-header,
.order-card-body,
.order-card-footer {
  padding: 12px;
}

.order-card-header {
  font-weight: 600;
}

.order-card-body {
  height: 160px;
}

.order-card-body .product-item {
  padding-bottom: 8px;
}

.order-card-body .product-item:last-child {
  padding-bottom: 0 !important;
}

.order-created-date i {
  font-size: 22px;
  color: #757575;
}

.list-park-order {
  overflow-y: auto;
  padding: 16px 0 0;
}

.order-card-footer .has-border-t .btn-card-order:last-child .btn {
  margin-right: 0;
}

.layout-search {
  padding-right: 0;
  width: calc(100% - 108px)
}

.layout-view-mode {
  justify-content: flex-start;
}

@media screen and (max-width: 1230px) {
  .hide-label-btn-show {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .park-sale-list {
    width: 100%;
  }

  .layout-view-mode {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 991.98px) {
  .layout-search {
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .order-card-header,
  .order-card-body,
  .order-card-footer {
    padding: 8px;
  }

  .layout-search {
    padding-right: 0;
    width: 100%;
  }
}
</style>
