<template>
  <div class="customer-page-container w-100 bg-white h-100">
    <div class="block-invoice-list h-100">
      <div class="tabBlock-pane d-flex flex-column">
        <div class="form-group d-flex align-items-center justify-content-between user-select-none">
          <label class="title-section no-margin">{{ $t("strings.listingCustomers") }}</label>
          <template v-if="!hideBtnDelete">
            <button
              class="btn btn-success btn-lg no-margin show-customer-sidebar-detail"
              @click="clickAddCustomer()"
            >
              <i class="fas fa-plus" />
              <span class="hidden-sm hidden-md ml-2">{{ $t("label.add") }}</span>
            </button>
          </template>
        </div>
        <div class="form-group">
          <div class="input-group">
            <div class="box-search">
              <div class="box-search-icon margin-l-5">
                <i class="fas fa-search" />
              </div>
              <input
                v-model="search_customer"
                :placeholder="$t('label.search') + '...'"
                class="pos-input-search pos-input-lg no-border"
                type="text"
                @keyup="getCustomer(1)"
              >
            </div>
            <div class="input-group-btn">
              <button
                :title="$t('label.filter')"
                class="btn btn-default btn-lg no-margin"
                data-target="#filterCustomer"
                data-toggle="modal"
                type="button"
              >
                <i class="fas fa-filter" />
                <span class="hide-label-sm ml-2">{{ $t("label.filter") }}</span>
                <span
                  v-if="countFilter > 0"
                  class="ml-2"
                >({{ countFilter }})</span>
              </button>
            </div>
          </div>
        </div>
        <template v-if="onLoading">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <div
            v-if="list_customers.length > 0"
            class="list-sugg-customer overflow-y-auto scrollbar user-select-none flex-1"
          >
            <div class="col-xs-12 no-padding customer-list">
              <div class="sugg-customer-each sugg-customer-header">
                <div class="d-flex align-items-center w-100">
                  <div class="cus-number text-bold">
                    {{ $t("label.No") }}
                  </div>
                  <div class="cus-name text-bold mr-2">
                    <div>{{ $t("label.name") }}</div>
                  </div>
                  <div class="cus-tel text-capitalize text-bold text-center">
                    {{ $t("label.phoneNumber") }}
                  </div>
                  <div class="cus-address text-capitalize text-bold text-center">
                    {{ $t("label.address") }}
                  </div>
                  <div class="cus-count text-capitalize text-bold text-right">
                    {{ $t("label.totalPurchase") }}
                  </div>
                </div>
              </div>
            </div>
            <template v-for="(item, key) in list_customers">
              <div
                :key="key"
                class="col-xs-12 no-padding show-customer-sidebar-detail"
              >
                <a
                  class="customer-list"
                  @click="onClickSelectCustomer(item)"
                >
                  <div
                    :class="{'active': customerDetail && item.uuid === customerDetail.uuid}"
                    class="sugg-customer-each"
                  >
                    <div class="d-flex align-items-center w-100">
                      <!--order_count: It's the number of orders owed on the invoice-->
                      <div class="cus-number">{{ key + 1 }}</div>
                      <div
                        :class="{'text-red': item.orders_count > 0}"
                        class="cus-name mr-2"
                      >
                        <div><strong>{{ item.name }}</strong></div>
                        <div><span class="font-s-13 text-muted">({{ item.gender["name_" + $i18n.locale] }})</span></div>
                      </div>
                      <div class="cus-tel text-center">{{ item.tel ? item.tel : "-" }}</div>
                      <div class="cus-address text-center">{{ item.address ? item.address : "-" }}</div>
                      <div class="cus-count text-bold text-right">
                        {{ item.orders.length }}
                        <span
                          v-if="item.orders_count && item.orders_count > 0"
                          class="text-red text-capitalize"
                        >({{
                          $t("recent_sell.owe.label.is_owed")
                        }} {{ item.orders_count }})</span>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  class="customer-card"
                  @click="onClickSelectCustomer(item)"
                >
                  <div
                    :class="{'active': customerDetail && item.uuid === customerDetail.uuid}"
                    class="sugg-customer-each"
                  >
                    <div class="d-flex align-items-center">
                      <div class="w-10">{{ key + 1 }}</div>
                      <div>
                        <div class="text-ellipsis">
                          <!--order_count: It's the number of orders owed on the invoice-->
                          <span
                            :class="{'text-red': item.orders_count > 0}"
                            class="sugg-cus-name mr-2"
                          >{{ item.name }}</span>
                          <span>( {{ item.gender["name_" + $i18n.locale] }} )</span>
                        </div>
                        <div class="sugg-cus-contact">
                          <span v-if="item.tel">{{ item.tel }}</span>
                          <span v-if="item.address">{{ item.address }}</span>
                          <span class="text-capitalize">
                            {{ $t("label.totalPurchase") }} <strong>{{ item.orders.length }}</strong>
                            <span
                              v-if="item.orders_count && item.orders_count > 0"
                              class="text-red"
                            >
                              ({{ $t("recent_sell.owe.label.is_owed") }} {{ item.orders_count }})
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </template>
            <infinite-loading
              :identifier="infiniteCustomerId"
              spinner="spiral"
              @infinite="infiniteHandlerCustomerList"
            >
              <div slot="no-more" />
              <div slot="no-results">
                {{ $t("label.no_results_message") }}
              </div>
              <div
                slot="error"
                slot-scope="{ trigger }"
              >
                Error message, click <a
                  href="javascript:void(0)"
                  @click="trigger"
                >here</a> to retry
              </div>
            </infinite-loading>
          </div>
          <template v-else>
            <no-any-records>
              <div
                v-if="!search_customer"
                class="d-block w-100 text-center margin-top"
              />
            </no-any-records>
          </template>
        </template>
      </div>
    </div>
    <div class="block-invoice-detail cus-detail h-100">
      <div class="tabBlock-pane d-flex flex-column overflow-y-auto scrollbar">
        <template v-if="onLoadingDetail">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <div
            v-if="customerDetail"
            class="order-info w-100"
          >
            <div class="form-group d-flex align-items-center justify-content-between w-100 text-capitalize">
              <div>
                <button
                  class="btn btn-default btn-lg no-margin btn-close-cus-detail-sm"
                  @click="closeCustomerDetailSm"
                >
                  <i class="fas fa-arrow-left" />
                </button>
              </div>
              <div>
                <button
                  class="btn btn-default btn-lg no-margin"
                  @click="showFormEditCustomer = !showFormEditCustomer"
                >
                  <i class="fas fa-edit mr-2" />
                  {{ $t("label.edit") }}
                </button>
              </div>
            </div>
            <div class="row no-margin user-select-none">
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong class="mr-2">{{ $t("label.customerName") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ customerDetail.name }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="customerDetail.gender"
                class="col-xs-12 no-padding has-border-b"
              >
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("table.customer.gender") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      {{ customerDetail.gender["name_" + $i18n.locale] }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="customerDetail.tel"
                class="col-xs-12 no-padding has-border-b"
              >
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("table.customer.tel") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      {{ customerDetail.tel }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="customerDetail.address"
                class="col-xs-12 no-padding has-border-b"
              >
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("table.customer.address") }} </strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      {{ customerDetail.address }}
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="customerDetail.customer_group && customerDetail.customer_group.length ">
                <div class="col-xs-12 no-padding has-border-b">
                  <div class="row no-margin">
                    <div class="col-xs-6 col-md-4 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-total-amount px-0 py-3">
                            <div class="text-capitalize cus-det-label">
                              <strong>{{ $t("fields.label.customerGroup") }}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xs-6 col-md-8 no-padding"
                      v-if="customerDetail && customerDetail.customer_group && Array.isArray(customerDetail.customer_group) && customerDetail.customer_group.length > 0"
                    >
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-total-amount px-0 py-3">
                            <div class="text-capitalize cus-det-label">
                              <template v-for="(item, key) in customerDetail.customer_group">
                                <span
                                  class="col-cashier text-bold"
                                  :key="key"
                                >
                                  {{ item["name_" + $i18n.locale] }}
                                </span>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template
                v-if="customerDetail.customer_membership && customerDetail.customer_membership.length "
              >
                <div class="col-xs-12 no-padding has-border-b">
                  <div class="row no-margin">
                    <div class="col-xs-6 col-md-4 no-padding">
                      <div class="text-capitalize cus-det-label">
                        <strong>{{ $t("fields.label.membership") }}</strong>
                      </div>
                    </div>
                    <div class="col-xs-6 col-md-8 no-padding">
                      <div class="text-capitalize cus-det-label">
                        <template v-for="(item, key) in customerDetail.customer_membership">
                          <p
                            :key="key"
                            class="margin-bottom-none"
                          >
                            <label class="col-cashier text-bold d-block">
                              {{ item["name_" + $i18n.locale] }} ({{ item.amount_percentage }}%)
                            </label>
                            <small
                              v-if="item.pivot.expire_date"
                              class="d-block"
                            >
                              {{ durationOfMembership(null, item.pivot.expire_date) }}
                            </small>
                            <template v-else>
                              <small
                                v-if="item.membership_type"
                                class="d-block"
                              >
                                {{ item.membership_type["name_" + $i18n.locale] }}
                              </small>
                            </template>
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("table.membership.createdAt") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      {{
                        $moment(customerDetail.created_at).locale($i18n.locale).format(dateFormatLocale)
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("label.updatedAt") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      {{
                        $moment(customerDetail.updated_at).locale($i18n.locale).format(dateFormatLocale)
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="customerDetail.orders && customerDetail.orders.length">
                <div class="col-xs-12 no-padding">
                  <div class="text-capitalize cus-det-label">
                    <strong>{{ $t("label.orderedHistory") }}</strong>
                  </div>
                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div
                          v-if="customerDetail.orders.length > 0"
                          class="list-sugg-customer overflow-y-auto scrollbar user-select-none flex-1"
                        >
                          <div
                            class="col-xs-12 customer-ordered-each no-padding"
                          >
                            <div class="customer-ordered-content-list font-s-16">
                              <div class="ohn text-bold text-capitalize">
                                <span class="col-cashier mr-2">{{ $t("label.orderNo") }}</span>
                              </div>
                              <div class="ohc text-ellipsis text-capitalize">
                                <div class="col-cashier text-bold mr-2">
                                  {{ $t("label.soldBy") }}
                                </div>
                              </div>
                              <div class="ohs col-status text-capitalize text-center">
                                <div class="label-status text-capitalize text-bold rounded">
                                  {{ $t("label.status") }}
                                </div>
                              </div>
                              <div class="ohp text-right text-capitalize text-bold col-amount">
                                <div>{{ $t("label.saleTotal") }}</div>
                              </div>
                            </div>
                          </div>
                          <template
                            v-if="customerDetail && customerDetail.orders && Array.isArray(customerDetail.orders) && customerDetail.orders.length > 0"
                          >
                            <template v-for="(item, each_customer_detail_orders_key) in customerDetail.orders">
                              <template
                                v-if="item.is_paid && item.invoices && Array.isArray(item.invoices) && item.invoices.length"
                              >
                                <div
                                  class="col-xs-12 customer-ordered-each no-padding"
                                  :key="each_customer_detail_orders_key"
                                >
                                  <a
                                    class="text-black"
                                    @click="showOrderHistory(item)"
                                  >
                                    <div class="customer-ordered-content-list font-s-16">
                                      <div class="ohn">
                                        <span class="col-cashier mr-2">
                                          #{{ item.invoices[0].order_number }}
                                        </span>
                                      </div>
                                      <div
                                        v-if="item.invoices[0].cashier"
                                        class="ohc text-ellipsis"
                                      >
                                        <div class="col-cashier text-bold mr-2">
                                          {{ item.invoices[0].cashier.full_name }}
                                        </div>
                                        <div class="col-date text-ellipsis">
                                          {{
                                            $moment(item.invoices[0].created_at).locale($i18n.locale).format(dateFormatLocale)
                                          }}
                                        </div>
                                      </div>
                                      <div class="ohs col-status text-center">
                                        <span
                                          :class="item.invoices[0].is_owe ? 'label-danger' : 'label-success'"
                                          class="label-status text-white rounded text-center font-s-13"
                                        >
                                          {{
                                            item.invoices[0].is_owe ? $t("label.unpaid") : $t("label.fullyPaid")
                                          }}
                                        </span>
                                      </div>
                                      <div class="ohp text-right text-bold col-amount">
                                        <div v-if="item.invoices[0].amount_total_riel_number_format">
                                          ៛ {{ item.invoices[0].amount_total_riel_number_format }}
                                        </div>
                                        <div v-if="item.invoices[0].amount_total_dollar_number_format">
                                          $ {{ item.invoices[0].amount_total_dollar_number_format }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="customer-ordered-content-grid">
                                      <div class="row no-margin">
                                        <div class="col-xs-6 col-md-4 no-padding">
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1 font-s-16">
                                                <div class="text-capitalize">
                                                  {{ $t("label.orderNo") }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xs-6 col-md-8 no-padding">
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1 font-s-16">
                                                <div class="text-capitalize">
                                                  <strong>#{{ item.invoices[0].order_number }}</strong>
                                                </div>
                                                <div class="col-status">
                                                  <span
                                                    :class="item.invoices[0].is_owe ? 'label-danger' : 'label-success'"
                                                    class="label-status text-white rounded text-center font-s-13"
                                                  >
                                                    {{
                                                      item.invoices[0].is_owe ? $t("label.unpaid") : $t("label.fullyPaid")
                                                    }}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row no-margin">
                                        <div class="col-xs-6 col-md-4 no-padding">
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1 font-s-16">
                                                <div class="text-capitalize">
                                                  {{ $t("label.date") }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xs-6 col-md-8 no-padding">
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1 font-s-16">
                                                <div class="text-capitalize">
                                                  {{
                                                    $moment(item.invoices[0].created_at).locale($i18n.locale).format(dateFormatLocale)
                                                  }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row no-margin">
                                        <div class="col-xs-6 col-md-4 no-padding">
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1 font-s-16">
                                                <div class="text-capitalize">
                                                  {{ $t("label.soldBy") }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xs-6 col-md-8 no-padding">
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1 font-s-16">
                                                <div
                                                  v-if="item.invoices[0].cashier"
                                                  class="text-capitalize"
                                                >
                                                  <strong>{{ item.invoices[0].cashier.full_name }}</strong>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row no-margin">
                                        <div class="col-xs-6 col-md-4 no-padding">
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1 font-s-16">
                                                <div class="text-capitalize">
                                                  {{ $t("label.saleTotal") }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xs-6 col-md-8 no-padding">
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1 font-s-16">
                                                <div class="text-capitalize">
                                                  <strong
                                                    v-if="item.invoices[0].amount_total_riel_number_format"
                                                  >
                                                    ៛ {{ item.invoices[0].amount_total_riel_number_format }}
                                                  </strong>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="list-order-header no-border">
                                            <div class="w-100 h-100">
                                              <div class="list-total-amount px-0 py-1">
                                                <div class="text-capitalize">
                                                  <strong
                                                    v-if="item.invoices[0].amount_total_dollar_number_format"
                                                  >
                                                    $ {{ item.invoices[0].amount_total_dollar_number_format }}
                                                  </strong>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </template>
                            </template>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center justify-content-center w-100 h-100"
          >
            <p class="font-s-18 capitalize">
              {{ $t("string.noCustomerSelected") }}
            </p>
          </div>
        </template>
      </div>
      <template v-if="showFormCreateCustomer">
        <DrawerCustomer
          :show-customer-form="showFormCreateCustomer"
          class-list-customer="list-sugg-customer-order"
          @closeDrawer="closeDrawerCustomer"
          @refreshData="getCustomer(1)"
        />
      </template>
      <template v-if="showFormEditCustomer">
        <DrawerCustomer
          :old-data="customerDetail"
          :show-customer-form="showFormEditCustomer"
          class-list-customer="list-sugg-customer-order"
          @closeDrawer="closeDrawerCustomer"
          @refreshData="getCustomer(1)"
        />
      </template>
    </div>
    <CustomerFilter @refreshList="getCustomer(1)" />
  </div>
</template>

<script>
import { debounce } from "debounce"
import NoAnyRecords from "@/components/NoAnyRecords"
import DrawerCustomer from "@/components/DrawerCustomer"
import CustomerFilter from "@/views/customers/V2/_components/CustomerFilter"
import { mapState } from "vuex"

export default {
  name: "CustomerContainer",
  components: {
    CustomerFilter,
    DrawerCustomer,
    NoAnyRecords
  },
  props: {
    hideBtnDelete: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search_customer: null,
      list_customers: [],
      onLoading: true,
      onLoadingDetail: false,
      customerDetail: null,
      page: 1,
      infiniteCustomerId: +new Date(),
      showFormCreateCustomer: false,
      showFormEditCustomer: false
    }
  },
  computed: {
    ...mapState({
      filterGroupIds: state => state.customer.filterGroupIds,
      filterMembershipIds: state => state.customer.filterMembershipIds,
      shop: state => state.shop.shop,
      countFilter () {
        return this.filterGroupIds.length + this.filterMembershipIds.length
      }
    })
  },
  mounted () {
    this.getCustomer(1)
    this.$store.dispatch("customer/setFilterGroupIds", [])
    this.$store.dispatch("customer/setFilterMembershipIds", [])
    this.$store.dispatch("customer/setListGenders", [])
    this.$store.dispatch("customer/setListGroups", [])
    this.$store.dispatch("customer/setListMemberships", [])
    this.$store.dispatch("customer/setGroups", [])
    this.$store.dispatch("customer/setMemberships", [])
    this.getDataOptions()
  },
  methods: {
    clickAddCustomer () {
      this.showFormCreateCustomer = !this.showFormCreateCustomer
    },
    getDataOptions () {
      const customer_group = []
      const customer_membership = []
      this.$axios.post(this.$config.base_url + "/api/data-option/get-data-option-page-customer", {
        shop_uuid: this.shop.uuid
      }).then((res) => {
        const data_groups = res.data.data.customer_groups
        const data_memberships = res.data.data.customer_memberships
        const data_genders = res.data.data.genders
        // start set filter
        customer_group.push({
          name_en: "All",
          name_km: "ទាំងអស់",
          id: -1,
          select_active: true,
          active: true
        })
        customer_membership.push({
          name_en: "All",
          name_km: "ទាំងអស់",
          id: -1,
          select_active: true,
          active: true
        })
        data_groups.forEach((item) => {
          this.$set(item, "select_active", false)
          customer_group.push(item)
        })
        data_memberships.forEach((item) => {
          this.$set(item, "select_active", false)
          customer_membership.push(item)
        })
        this.$store.dispatch("customer/setGroups", customer_group)
        this.$store.dispatch("customer/setMemberships", customer_membership)
        // end set filter
        this.$store.dispatch("customer/setListGroups", data_groups)
        this.$store.dispatch("customer/setListMemberships", data_memberships)
        this.$store.dispatch("customer/setListGenders", data_genders)
      })
    },
    durationOfMembership (start, end) {
      if (start && end) {
        const s = this.$moment(start).locale(this.$i18n.locale).format("DD / MM / YYYY")
        const e = this.$moment(end).locale(this.$i18n.locale).format("DD / MM / YYYY")
        return this.$t("label.startFrom") + ": " + s + " " + this.$t("label.to") + " " + e
      } else if (start && !end) {
        const s = this.$moment(start).locale(this.$i18n.locale).format("DD / MM / YYYY")
        return this.$t("label.startFrom") + ": " + s
      } else if (!start && end) {
        const e = this.$moment(end).locale(this.$i18n.locale).format("DD / MM / YYYY")
        return this.$t("label.expired") + ": " + e
      }
    },
    deleteCustomer (uuid) {
      this.$swal({
        title: this.swalTitle,
        text: this.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.swalYesButton,
        cancelButtonText: this.swalNoButton
      }).then(() => {
        this.$isLoading(true)
        this.$axios.post(`${this.$config.base_url}/api/v2/customer/delete`, {
          uuid: uuid,
          shop_uuid: this.$config.shop_uuid
        }).then(() => {
          this.customerDetail = null
          this.getCustomer(1)
        }).catch((err) => {
          if (err.response.status === 422) {
            this.validation = err.response.data.errors
          } else {
            this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
          }
        }).finally(() => {
          this.$isLoading(false)
        })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },
    closeDrawerCustomer () {
      this.showFormCreateCustomer = false
      this.showFormEditCustomer = false
    },
    getCustomer (page = null) {
      this.onLoading = true
      this.page = 1
      if (page) {
        this.page = page
      }
      this.list_customers = []
      this.customerDetail = null
      this.infiniteCustomerId += 1
      this.infiniteHandlerCustomerList()
    },
    infiniteHandlerCustomerList: debounce(function ($state) {
      this.$axios.post(this.$config.base_url + "/api/customer/suggestions", {
        shop_uuid: this.$config.shop_uuid,
        search_customer: this.search_customer,
        page: this.page,
        customer_group_ids: this.filterGroupIds,
        customer_membership_ids: this.filterMembershipIds
      }).then(({ data }) => {
        if (!(this.page > data.total_pages)) {
          this.page += 1
          this.list_customers.push(...data.data)
          if ($state) {
            $state.loaded()
          }
        } else {
          if (data.data) {
            $state.loaded()
            $state.complete()
          }
        }
      }).catch((error) => {
        this.getResponseError(error)
      }).finally(() => {
        this.onLoading = false
      })
    }, 800),
    onClickSelectCustomer (item) {
      this.onLoadingDetail = true
      this.selectCustomer(item)
    },
    selectCustomer: debounce(function (item) {
      this.showFormCreateCustomer = false
      this.showFormEditCustomer = false
      this.$axios.post(this.$config.base_url + "/api/v2/customer/show", {
        uuid: item.uuid
      }).then((res) => {
        this.customerDetail = res.data.data
      }).catch((error) => {
        this.$toastr("error", error.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
      }).finally(() => {
        this.onLoadingDetail = false
      })
    }, 800),
    showOrderHistory (order) {
      if (order.is_paid && order.invoices.length) {
        if (order.invoices[0].is_owe) {
          this.$router.push({
            name: "payment-show",
            params: {
              uuid: order.invoices[0].uuid,
              orderFrom: "recent-sell"
            }
          })
        } else {
          this.$router.push({
            name: "print-invoice",
            params: { uuid: order.invoices[0].uuid }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
@import "../../../../assets/css/V2/customer.css";

.ohn {
  width: 55px;
}

.ohc {
  width: 340px;
}

.ohs {
  width: 130px;
}

.ohp {
  width: 150px;
}

.cus-number {
  width: 5%;
}

.cus-name {
  width: 25%;
}

.cus-address {
  width: 30%;
}

.cus-tel {
  width: 20%;
}

.cus-count {
  width: 20%;
}

.customer-list {
  display: block;
}

.customer-card {
  display: none;
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .ohs {
    font-size: 16px;
  }
}

@media screen and (max-width: 1300px) {

  .customer-list {
    display: none;
  }

  .customer-card {
    display: block;
  }
}
</style>
