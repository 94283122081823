<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary ">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("string.product") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'print-barcode-all'}"
                class="btn btn-default btn-sm margin-r-5"
              >
                <i class="fas fa-print" /> {{ $t("food.button.printProductBarcode") }}
              </router-link>
              <router-link
                :to="{name: 'order-product'}"
                class="btn btn-default btn-sm margin-r-5"
              >
                <i class="fas fa-sort-numeric-asc" /> {{ $t("inventories.button.products.order_products") }}
              </router-link>
              <router-link
                :to="{name: 'create-product'}"
                class="btn btn-success btn-sm"
              >
                <i class="fas fa-plus" /> {{ $t("string.newProduct") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <table
              id="product-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("food.table.image") }}</th>
                  <th>{{ $t("food.table.name") }}</th>
                  <th>{{ $t("string.barcode") }}</th>
                  <th>{{ $t("string.sku") }}</th>
                  <th>{{ $t("food.table.productCategory") }}</th>
                  <th>{{ $t("food.table.productStock") }}</th>
                  <th>{{ $t("food.table.active") }}</th>
                  <th>{{ $t("food.table.createdAt") }}</th>
                  <th class="tw-w-[80px]">
                    {{ $t("food.table.action") }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <ProductListDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>
import ProductListDataTableSm from "@/views/inventories/products/_components/ProductListDataTableSm"

export default {
  name: "List",
  components: {
    ProductListDataTableSm
  },
  metaInfo () {
    return {
      title: this.$t("string.product"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null,
      barcode: "",
      previousCode: "",
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.reDrawOTable()
    },
    "windowWidth" () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    "oTable" () {
      this.reloadButtonAction()
    }
  },
  created () {
    if (this.$route.name === "list-product") {
      window.addEventListener("keypress", this.onWindowKeyPress)
    } else {
      window.removeEventListener("keypress", this.onWindowKeyPress)
    }
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    reDrawOTable () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    dataTable () {
      const self = this
      return $("#product-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        pageLength: 100,
        ajax: {
          url: self.config.base_url + "/api/product/data",
          type: "POST",
          data: {
            shop_id: self.get_shop.id,
            labelEdit: self.labelEdit,
            labelDelete: self.labelDelete,
            labelShow: self.labelShowTrans,
            labelAdjustStock: self.labelAdjustStock,
            labelReduceStock: self.labelReduceStock,
            labelViewStock: self.labelShowStock,
            labelPrintBarCode: self.labelPrintBarCode,
            barcode: this.previousCode
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "image",
            name: "image",
            title: self.$t("food.table.image"),
            searchable: false,
            orderable: false
          },
          {
            data: "name",
            name: "name",
            title: self.$t("food.table.name")
          },
          {
            data: "barcode",
            name: "barcode",
            title: self.$t("string.barcode")
          },
          {
            data: "sku",
            name: "sku",
            title: self.$t("string.sku")
          },
          {
            data: "category.name_" + self.$i18n.locale,
            name: "category.name_" + self.$i18n.locale,
            title: self.$t("food.table.productCategory"),
            render: (data, type, row) => {
              if (row.category !== null) {
                return `${row.category["name_" + self.$i18n.locale]}`
              }
              return "-"
            }
          },
          {
            data: "productStock",
            name: "productStock",
            title: self.$t("food.table.productStock"),
            searchable: false,
            orderable: false
          },
          {
            data: "active",
            name: "active",
            title: self.$t("food.table.active"),
            searchable: false,
            render: (data, type, row) => {
              if (data) {
                return `<span class="label label-success tw-text-lg">${this.$t("string.active")}</span>`
              }
              return `<span class="label label-danger tw-text-lg">${this.$t("string.inactive")}</span>`
            }
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("food.table.createdAt"),
            searchable: false
          },
          {
            data: "actions",
            name: "actions",
            title: self.$t("food.table.action"),
            searchable: false,
            orderable: false
          }
        ],
        order: [[7, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    reloadButtonAction () {
      const self = this
      this.clearEventHandler([
        ".btn-show"
      ])
      $(document).on("click", ".btn-show", function () {
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "show-product",
          params: { uuid: uuid }
        })
      })
    },
    onWindowKeyPress (e) {
      if (e.key === "Enter") {
        this.previousCode = this.barcode
        if (this.previousCode.length > 4) {
          e.preventDefault()
          this.reDrawOTable()
        }
      } else {
        this.barcode += e.key
      }
      setTimeout(() => {
        this.barcode = ""
      }, 200)
    }
  },
  beforeDestroy () {
    window.removeEventListener("keypress", this.onWindowKeyPress)
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
