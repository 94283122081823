<template>
  <RouterView />
</template>

<script>
export default {
  name: "Primary",
  methods: {
    async getPlanExpiredInfo () {
      await this.$axios.post(this.$base_api + "/api/bill/check-plan-expired-info")
        .then(({ data }) => {
          if (data.data) {
            const { plan_expired } = data.data
            this.$store.dispatch("shop/setExpired", plan_expired)
          }
        })
    },
    async getUser () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(this.$config.base_url + "/api/check-user/get-data")
          .then(async ({ data }) => {
            if (data.data) {
              const result = data.data
              localStorage.setItem("user", JSON.stringify(result.user))
              localStorage.setItem("plan", JSON.stringify(result.plan))
              await this.$store.dispatch("user/updateUser", result.user)
              await this.$store.dispatch("shop/setPlan", result.plan)
              if (result.shop !== null) {
                await this.setShopProperty(result.shop)
                this.checkTokenDeviceForSell()
              }
              resolve(data.data)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  async beforeMount () {
    await this.getPlanExpiredInfo()
  },
  async mounted () {
    const ignore_routes = ["create-shop"]
    if (!ignore_routes.includes(this.$route.name)) {
      await this.getUser()
        .then(() => this.checkTokenDeviceForSell())
        .catch(error => console.log(error))
    }
  }
}
</script>

<style scoped>

</style>
