<template>
  <div class="row">
    <div
      v-if="couponCards && Array.isArray(couponCards) && couponCards.length > 0"
      class="col-md-12"
    >
      <div class="print-container">
        <div
          v-for="(couponCard, key) in couponCards"
          :key="key"
          class="col-md-12"
        >
          <div class="coupon-card">
            <div class="row">
              <div class="col-md-12">
                <h3 class="text-center">
                  {{ shop.name }}
                </h3>
                <p class="text-center small-text">
                  {{ shop.address }}
                </p>
                <p class="text-center big-text">
                  #0000000{{ couponCard.id }}
                </p>
                <p class="text-muted text-center">
                  USD {{ couponCard.amount.toFixed(2) }}
                </p>
                <div class="text-center">
                  <vue-qr-code
                    :options="{ width: 200 }"
                    :value="couponCard.uuid"
                  />
                </div>
                <p class="text-muted text-center">
                  {{ couponCard.created_at }}
                </p>
                <p class="text-muted text-center">
                  Power by asorasoft.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Print",
  metaInfo () {
    return {
      title: "Print coupon cards",
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      shop: null,
      couponCards: []
    }
  },
  methods: {
    getOrder () {
      this.$axios.post(this.$config.base_url + "/api/coupon/order/get-coupon-cards-by-order", {
        coupon_order_uuid: this.$route.params.coupon_order_uuid,
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1) {
          this.shop = response.data.data.shop
          this.couponCards = response.data.data.couponCards
        }
      })
    }
  },
  mounted () {
    this.getOrder()
  }
}
</script>

<style scoped>
.print-container {
  margin: 0 auto;
  width: 105mm;
  height: auto;
  background: #2e2d47 !important;
}

.coupon-card {
  margin-bottom: 10px;
  position: relative;
  background: #FFFFFF;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

.big-text {
  font-size: 20px;
  font-weight: bold;
}

.small-text {
  font-size: 10px;
  line-height: normal;
}

.coupon-card p {
  line-height: 0.5;
}
</style>
