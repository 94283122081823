<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("strings.product.type.listingTypes") }}
          </h3>
          <div class="box-tools">
            <router-link
              :to="{name: 'create-product-type'}"
              class="btn btn-primary btn-sm"
            >
              <i class="fas fa-plus-circle" /> {{ $t("food.button.newType") }}
            </router-link>
          </div>
        </div>
        <swal-delete-action
          ref="swalDeleteAction"
          route-to-delete="/api/product-type/delete"
          @refreshListingTable="refreshListingTable"
        />
        <div class="box-body">
          <table
            id="product-type-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("food.table.name") }}</th>
                <th>{{ $t("food.table.createdAt") }}</th>
                <th>{{ $t("food.table.action") }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwalDeleteAction from "@/components/SwalDeleteAction"

export default {
  name: "ListProductType",
  components: { SwalDeleteAction },
  metaInfo () {
    return {
      title: this.$t("strings.product.type.listingTypes"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.dataTable()
      this.oTable.draw()
    }
  },
  methods: {
    refreshListingTable () {
      this.oTable.draw(true)
    },
    dataTable () {
      const self = this
      return $("#product-type-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: this.$config.base_url + "/api/product-type/data",
          type: "POST",
          data: {
            shop_id: this.get_shop.id
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "name_" + this.$i18n.locale,
            name: "name_" + this.$i18n.locale
          },
          {
            data: "created_at",
            name: "created_at",
            searchable: false,
            orderable: false
          },
          {
            data: "actions",
            name: "actions",
            searchable: false
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    enableActions () {
      const self = this
      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-edit-product-type")
      $(document).off("click", ".btn-delete-product-type")

      $(document).on("click", ".btn-show", function () {
        self.$router.push({
          name: "show-product-type",
          params: { id: $(this).attr("data-id") }
        })
      })

      $(document).on("click", ".btn-edit-product-type", function () {
        self.$router.push({
          name: "edit-product-type",
          params: { id: $(this).attr("data-id") }
        })
      })

      $(document).on("click", ".btn-delete-product-type", function () {
        self.$refs.swalDeleteAction.onClickDelete({
          shop_id: self.get_shop.id,
          id: $(this).attr("data-id")
        })
      })
    }
  },
  mounted () {
    $.ajaxSetup({
      headers: {
        Accept: "application/json",
        Authorization: localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*"
      }
    })

    this.oTable = this.dataTable()
    this.enableActions()
  }
}
</script>

<style scoped>

</style>
