<template>
  <div>
    <div
      :class="(message_validation !== null && message_validation.hasOwnProperty('first_name') ? 'form-group has-error has-feedback row' : 'form-group row')"
    >
      <label class="col-md-3 required">{{ $t("user.field.label.firstName") }}</label>
      <div class="col-md-9">
        <input
          v-model="user.first_name"
          :placeholder="$t('user.field.placeholder.firstName')"
          class="form-control"
          type="text"
        >
        <small v-if="message_validation !== null && message_validation.hasOwnProperty('first_name')">
          {{ message_validation.first_name[0] }}
        </small>
      </div>
    </div>
    <div
      :class="(message_validation !== null && message_validation.hasOwnProperty('last_name') ? 'form-group has-error has-feedback row' : 'form-group row')"
    >
      <label class="col-md-3 required">{{ $t("user.field.label.lastName") }}</label>
      <div class="col-md-9">
        <input
          v-model="user.last_name"
          :placeholder="$t('user.field.placeholder.lastName')"
          class="form-control"
          type="text"
        >
        <small v-if="message_validation !== null && message_validation.hasOwnProperty('last_name')">
          {{ message_validation.last_name[0] }}
        </small>
      </div>
    </div>

    <div
      :class="{'has-error has-feedback': message_validation && message_validation.hasOwnProperty('username')}"
      class="form-group row"
    >
      <div class="col-md-3">
        <label class="required">{{ $t("fields.placeholder.username") }}</label>
        <button
          aria-controls="usernameTrip"
          aria-expanded="true"
          class="btn btn-default btn-xs ml-3"
          data-target="#usernameTrip"
          data-toggle="collapse"
          type="button"
        >
          <i class="fas fa-question-circle" />
        </button>
      </div>
      <div class="col-md-9">
        <input
          v-model="user.username"
          :placeholder="$t('fields.placeholder.username')"
          class="form-control"
          type="text"
        >
        <small v-if="message_validation && message_validation.hasOwnProperty('username')">
          {{ message_validation.username[0] }}
        </small>
        <div
          id="usernameTrip"
          class="collapse in margin-top"
        >
          <UsernameTrip />
        </div>
      </div>
    </div>

    <div
      :class="{'has-error has-feedback': message_validation && message_validation.hasOwnProperty('email')}"
      class="form-group row"
    >
      <label class="col-md-3">{{ $t("fields.placeholder.email") }}</label>
      <div class="col-md-9">
        <input
          v-model="user.email"
          :placeholder="$t('fields.placeholder.email')"
          class="form-control"
          type="email"
        >
        <small v-if="message_validation && message_validation.hasOwnProperty('email')">
          {{ message_validation.email[0] }}
        </small>
      </div>
    </div>

    <div
      :class="{'has-error': message_validation && message_validation.hasOwnProperty('gender_id')}"
      class="form-group row"
    >
      <label class="required col-md-3 control-label">{{ $t("label.gender") }}</label>
      <div class="col-md-9">
        <div>
          <template v-for="(gender, key) in genders">
            <label
              class="margin-right"
              :key="key"
            >
              <input
                v-model="user.gender"
                :value="gender"
                name="gender"
                type="radio"
              >
              <span class="ml-2"> {{ gender["name_" + $i18n.locale] }}</span>
            </label>
          </template>
        </div>
        <small v-if="message_validation && message_validation.hasOwnProperty('gender_id')">
          {{ message_validation["gender_id"][0] }}
        </small>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3 control-label">{{ $t("label.status") }}</label>
      <div class="col-md-9">
        <label>
          <input
            v-model="user.is_active"
            type="checkbox"
          >
          <span class="ml-3">{{ $t("label.active") }}</span>
        </label>
      </div>
    </div>
    <div
      :class="{'has-error': message_validation && message_validation.hasOwnProperty('roles')}"
      class="form-group row"
    >
      <label class="required col-md-3 control-label">{{ $t("label.role") }}</label>
      <div class="col-md-9">
        <div class="row">
          <template v-if="allRoles && allRoles.length > 0">
            <div
              v-for="(role, key) in allRoles"
              class="col-md-12"
              :key="key"
            >
              <label>
                <input
                  v-model="user.roles"
                  :value="role"
                  type="checkbox"
                >
                <span class="ml-3">{{ role["display_name_" + $i18n.locale] }}</span>
              </label>
            </div>
          </template>
        </div>
        <small v-if="message_validation && message_validation.hasOwnProperty('roles')">
          {{ message_validation["roles"][0] }}
        </small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-right">
        <template v-if="!statusShow">
          <button
            v-if="id === null"
            class="btn btn-primary"
            @click="onSubmitStoreUser"
          >
            <i class="fas fa-check-circle" /> {{ $t("button.save") }}
          </button>
          <button
            v-else
            class="btn btn-info"
            @click="onSubmitStoreUser"
          >
            <i class="fas fa-check-circle" /> {{ $t("button.update") }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import UsernameTrip from "@/views/settings/_components/UsernameTrip"

export default {
  name: "UserFormEditInformation",
  components: { UsernameTrip },
  props: {
    id: {
      type: Number,
      default: null
    },
    statusShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allRoles: [],
      genders: [],
      user: {
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        is_active: false,
        gender: null,
        roles: []
      },
      message_validation: null
    }
  },
  mounted () {
    this.getRoles()
    this.getGenders()
    this.getUser()
  },
  methods: {
    getGenders () {
      this.$axios
        .get(this.$config.base_url + "/api/gender/get-options")
        .then((res) => {
          this.genders = res.data.data
          if (this.genders.length > 0) {
            this.user.gender = this.genders[0]
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
    },
    getUser () {
      this.$axios.post(this.$config.base_url + "/api/user/show", {
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          const result = response.data.data
          this.user.first_name = result.first_name
          this.user.last_name = result.last_name
          this.user.username = result.username
          this.user.email = result.email
          this.user.is_active = result.active
          if (result.gender) {
            this.user.gender = result.gender
          }
          if (result.roles) {
            result.roles.forEach((item) => {
              this.user.roles.push({
                id: item.id,
                name: item.name,
                display_name_en: item.display_name_en,
                display_name_km: item.display_name_km
              })
            })
          }
        } else {
          this.$router.push({ name: "list-user" })
        }
      })
    },
    getRoles () {
      this.$axios.post(this.$config.base_url + "/api/user/get-option-roles")
        .then((response) => {
          if (response.data.code === 1) {
            if (response.data.data && response.data.data.length) {
              response.data.data.forEach((item) => {
                this.allRoles.push({
                  id: item.id,
                  name: item.name,
                  display_name_en: item.display_name_en,
                  display_name_km: item.display_name_km
                })
              })
            }
          }
        })
    },
    onSubmitStoreUser () {
      this.message_validation = null
      this.$isLoading(true)
      const data = {
        uuid: this.$route.params.uuid,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        active: this.user.is_active
      }

      if (this.user.username) {
        data.username = this.user.username.toLowerCase()
      }
      if (this.user.gender) {
        data.gender_id = this.user.gender.id
      }

      if (this.user.roles.length > 0) {
        data.roles = this.user.roles
      }
      this.$axios.post(this.$config.base_url + "/api/user/update", data)
        .then((response) => {
          if (response.data.code === 1) {
            this.$router.push({ name: "list-user" })
            this.$anotify(this.$t("model.user"), "update")
          } else {
            this.message_validation = response.data.message
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
