<template>
  <div
    id="productOrderModal"
    aria-labelledby="EditProductOrder"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <template v-if="product">
        <div class="modal-content">
          <div class="modal-header-custom">
            <h4 class="modal-title">
              {{ product.name }}
            </h4>
            <button
              ref="close"
              :class="class_btn_modal"
              aria-label="Close"
              data-dismiss="modal"
              type="button"
            >
              <i class="fas fa-times" />
            </button>
          </div>
          <div class="modal-body">
            <div>
              <template
                v-if="product && product.product_variants && Array.isArray(product.product_variants) && product.product_variants.length"
              >
                <template v-for="(item, key) in product.product_variants">
                  <div
                    v-if="item.pivot.value"
                    class="form-group row"
                    :key="key"
                  >
                    <div class="col-sm-4">
                      <label class="font-s-18 text-capitalize font-weight-normal">
                        {{ $i18n.locale === "km" ? item.name_km : item.name }}
                      </label>
                    </div>
                    <div class="col-sm-8">
                      <label class="font-s-18">{{ item.pivot.value }}</label>
                    </div>
                  </div>
                </template>
              </template>
              <div class="form-group row">
                <div class="col-sm-4">
                  <label class="font-s-18 text-capitalize font-weight-normal">{{ $t("label.amountInStock") }}</label>
                </div>
                <div class="col-sm-8">
                  <label
                    v-if="product.has_inventory"
                    :class="{'text-red': calcAmountProduct <= 0}"
                    class="font-s-18"
                  >
                    {{ calcAmountProduct | numFormat(isFloat(calcAmountProduct)) }}
                    <span class="font-weight-normal font-s-14 margin-r-5">{{
                      product.unit ? product.unit["name_" + $i18n.locale] : null
                    }}</span>
                  </label>
                  <label
                    v-else
                    class="font-s-18 text-capitalize"
                  >{{ $t("label.unlimited") }}</label>
                </div>
              </div>
              <div
                v-if="!product.active || product.deleted_at"
                class="form-group row"
              >
                <div class="col-sm-4">
                  <label class="font-s-18 text-capitalize font-weight-normal">{{ $t("label.status") }}</label>
                </div>
                <div class="col-sm-8">
                  <template v-if="product.deleted_at">
                    <label class="font-s-18 text-capitalize has-error">{{ $t("label.productDeleted") }}</label>
                  </template>
                  <template v-else-if="!product.active">
                    <label class="font-s-18 text-capitalize has-error">{{ $t("label.thisProductIsInactive") }}</label>
                  </template>
                </div>
              </div>
              <template v-if="!(!product.active || product.deleted_at)">
                <div class="form-group">
                  <label class="font-s-18 text-capitalize font-weight-normal">{{ $t("label.qty") }}</label>
                  <div class="input-group input-group-lg">
                    <input
                      v-model="pro_qty"
                      class="form-control input-lg"
                      type="number"
                    >
                    <template v-if="product.unit">
                      <span class="input-group-addon">
                        {{ product.unit["name_" + $i18n.locale] }}
                      </span>
                    </template>
                    <template>
                      <span class="input-group-btn">
                        <button
                          class="btn btn-default no-margin"
                          @click="minusOne"
                        >
                          <i class="fas fa-minus" />
                        </button>
                        <button
                          class="btn btn-default no-margin"
                          @click="addOne"
                        >
                          <i class="fas fa-plus" />
                        </button>
                      </span>
                    </template>
                  </div>
                </div>
                <template v-if="product.is_custom_product">
                  <div class="form-group">
                    <label class="font-s-18 text-capitalize font-weight-normal">{{ $t("label.price") }}</label>
                    <div :class="{'input-group input-group-lg': currency}">
                      <input
                        v-model="pro_price"
                        :class="{'rounded': !currency}"
                        class="form-control input-lg"
                        type="number"
                      >
                      <template v-if="currency">
                        <span class="input-group-addon rounded-br rounded-tr"> {{ currency.code }}</span>
                      </template>
                    </div>
                  </div>
                </template>
                <div class="form-group">
                  <label class="font-s-18 text-capitalize font-weight-normal">{{ $t("label.discount") }}</label>
                  <div class="input-group input-group-lg">
                    <input
                      v-model="pro_discount"
                      class="form-control input-lg"
                      type="number"
                    >
                    <div class="input-group-btn">
                      <button
                        :class="is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                        class="btn no-margin bl-unset"
                        type="button"
                        @click="is_percentage = true"
                      >
                        <i class="fas fa-percent" />
                      </button>
                      <button
                        :class="!is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                        class="btn no-margin"
                        @click="is_percentage = false"
                      >
                        {{ currency ? currency.code : "USD" }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-s-18 text-capitalize font-weight-normal">{{ $t("note") }}</label>
                  <textarea
                    id="product_note"
                    v-model="note"
                    :placeholder="$t('placeholder.enterNote')"
                    class="form-control rounded max-w-100 scrollbar"
                    name="note"
                    rows="10"
                    style="resize: none; height: 120px !important;"
                  />
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="!(!product.active || product.deleted_at)"
            class="modal-footer"
          >
            <button
              class="btn btn-lg btn-primary"
              @click="updateOrderProductQty(product)"
            >
              <i class="fas fa-edit" />
              {{ $t("button.update") }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "EditProductOrder",
  props: {
    product: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  computed: {
    ...mapState({
      currency: state => state.shop.currency
    }),
    calcAmountProduct () {
      if (this.product && this.product.has_inventory) {
        if (!this.pro_qty) {
          return parseFloat(this.amount)
        }
        return parseFloat(this.amount) - parseFloat(this.pro_qty)
      }
      return 0
    }
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler (newValue) {
        if (this.product) {
          this.pro_price = parseFloat(newValue.sale_price)
          this.pro_discount = parseFloat(newValue.pivot.discount)
          this.pro_qty = parseFloat(newValue.pivot.quantity)
          this.is_percentage = newValue.pivot.is_percentage
          this.note = newValue.pivot.note
          this.amount = parseFloat(newValue.initAmountInStock)
        }
      }
    },
    pro_discount () {
      this.checkDiscountValue()
    },
    is_percentage () {
      this.checkDiscountValue()
    }
  },
  data () {
    return {
      pro_price: 0,
      pro_discount: 0,
      pro_qty: 0,
      is_percentage: true,
      note: null,
      amount: 0
    }
  },
  methods: {
    checkDiscountValue () {
      if (this.is_percentage) {
        if (this.pro_discount < 0) {
          this.pro_discount = 0
        } else if (this.pro_discount > 100) {
          this.pro_discount = 100
        }
      }
    },
    minusOne () {
      if (this.pro_qty > 0) {
        this.pro_qty -= 1
      } else {
        this.pro_qty = 0
      }
    },
    addOne () {
      this.pro_qty = parseFloat(this.pro_qty) + 1
    },
    updateOrderProductQty (product) {
      if (!this.pro_qty) {
        this.pro_qty = this.product.pivot.quantity
      }
      this.$store.dispatch("order/updateOrderProductQuantity", {
        item: product,
        value: this.pro_qty,
        price: this.pro_price,
        discount: this.pro_discount,
        is_percentage: this.is_percentage,
        note: this.note
      })
      this.$store.dispatch("order/setDisplayPo", this)
      this.$refs.close.click()
    }
  }
}
</script>

<style scoped></style>
