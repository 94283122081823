<template>
  <div class="row">
    <div class="amusement-park-order">
      <div class="col-md-12">
        <h3>{{ $t("string.amusementParkSystem") }}</h3>
      </div>
      <div class="col-md-6">
        <ticket-section />
      </div>
      <div class="col-md-6">
        <order-section />
      </div>
    </div>
  </div>
</template>

<script>
import TicketSection from "../includes/TicketSection"
import OrderSection from "../includes/OrderSection"

export default {
  name: "Order",
  metaInfo () {
    return {
      title: "Order",
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    OrderSection,
    TicketSection
  }
}
</script>

<style scoped>
@import "./../../../assets/css/amusementPark.css";
</style>
