<template>
  <div class="no-print pos-menubar-h">
    <ul class="pos-menubar scrollbar">
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': dashboardReportTab}"
          :to="{name: 'report-dashboard'}"
        >
          <i class="fas fa-tachometer-alt" />
          <p>{{ $t("string.dashboard") }}</p>
        </router-link>
      </li>
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': productSaleTab}"
          :to="{name: 'list-product-sale'}"
        >
          <i class="fas fa-shopping-basket" />
          <p>{{ $t("report.menu.productSale") }}</p>
        </router-link>
      </li>
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': reportMonthlyTab}"
          :to="{name: 'list-report-monthly'}"
        >
          <i class="fas fa-book" />
          <p>{{ $t("report.menu.reportMonthly") }}</p>
        </router-link>
      </li>

      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': incomeTab}"
          :to="{name: 'list-income'}"
        >
          <i class="fas fa-money-check-alt" />
          <p>{{ $t("report.menu.income") }}</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SidebarReportV2",
  computed: {
    dashboardReportTab () {
      const route_names = [
        "report-dashboard"
      ]
      return route_names.includes(this.$route.name)
    },
    productSaleTab () {
      const route_names = [
        "list-product-sale",
        "list-sale-history"
      ]
      return route_names.includes(this.$route.name)
    },
    reportMonthlyTab () {
      const route_names = [
        "list-report-monthly",
        "inventory-transaction-history"
      ]
      return route_names.includes(this.$route.name)
    },
    incomeTab () {
      const route_names = [
        "list-income"
      ]
      return route_names.includes(this.$route.name)
    }
  }
}
</script>

<style scoped>

</style>
