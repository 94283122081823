import Promotion from "@/views/inventories/promotion/Index"
import List from "@/views/inventories/promotion/List"
import Create from "@/views/inventories/promotion/Create"
import Show from "@/views/inventories/promotion/Show"
import Edit from "@/views/inventories/promotion/Edit"

export const promotionRouters = {
  path: "promotion",
  component: Promotion,
  children: [
    {
      path: "",
      name: "list-promotion",
      meta: {
        title: "Payment Option"
      },
      component: List
    },
    {
      path: "create",
      name: "create-promotion",
      component: Create
    },
    {
      path: "edit-promotion/:uuid",
      name: "edit-promotion",
      component: Edit
    },
    {
      path: "show-promotion/:uuid",
      name: "show-promotion",
      component: Show
    }
  ]
}
