<template>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.product.category.showCategory") }}
          </h3>
          <div class="box-tools hidden-xs">
            <router-link
              class="btn btn-primary btn-sm mr-2"
              :to="{name: 'edit-product-category', params: {uuid: $route.params.uuid}}"
            >
              <i class="fas fa-edit" /> {{ $t("button.edit") }}
            </router-link>
            <a
              @click="$router.back()"
              class="btn btn-default btn-sm"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body">
          <table
            class="table table-striped"
            v-if="category"
          >
            <tbody>
              <tr>
                <td>{{ $t("label.nameEn") }}</td>
                <td><strong>{{ category.name_en }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t("label.nameKm") }}</td>
                <td><strong>{{ category.name_km }}</strong></td>
              </tr>
              <tr v-if="category.product_type">
                <td>{{ $t("product.fields.label.productType") }}</td>
                <td><strong>{{ category.product_type["name_" + $i18n.locale] }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t("food.table.createdAt") }}</td>
                <td><strong>{{ $moment(category.created_at).format("DD/MM/YYYY hh:mm:ss") }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t("fields.label.image") }}</td>
                <td>
                  <template v-if="category && category.image">
                    <div style="width: 270px">
                      <img
                        class="thumbnail w-100"
                        :src="category.image"
                        alt="Category image"
                      >
                    </div>
                  </template>
                  <template v-else>
                    <div style="width: 270px">
                      <img
                        class="thumbnail w-100"
                        :src="defaultImg"
                        alt="Category image"
                      >
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <NoResult :height="30" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoResult from "@/components/NoResult"

export default {
  name: "ProductCategoryShow",
  metaInfo () {
    return {
      title: this.$t("label.product.category.showCategory"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: { NoResult },
  data () {
    return {
      config: this.$config,
      category: null
    }
  },
  mounted () {
    this.getProductCategory()
  },
  methods: {
    getProductCategory () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-category/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.category = response.data.data
          if (this.category && this.category.image) {
            this.$set(this.category, "image", this.$config.base_url + "/" + this.category.image)
          }
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
