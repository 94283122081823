<template>
  <div class="subscription-sidebar">
    <div
      v-for="(item, key) in menu"
      :key="key"
      :class="{'active' : menuActive === item.target}"
      class="subscription-sidebar-item"
      @click="onSwitchMenu(item.target)"
    >
      <div class="subscription-sidebar-item-icon">
        <i :class="item.icon" />
      </div>
      <p class="subscription-sidebar-item-label">
        {{ $t(item.label) }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "SubscriptionSidebar",
  computed: {
    ...mapGetters("subscription", {
      menu: "menu",
      menuActive: "menuActive"
    })
  },
  methods: {
    ...mapActions("subscription", {
      switchMenu: "switchMenu",
      showInvoiceList: "showInvoiceList",
      setStep: "setStep"
    }),
    onSwitchMenu (target) {
      this.switchMenu(target)
      this.showInvoiceList()
      this.setStep(1)
    }
  }
}
</script>

<style scoped>
.subscription-sidebar {
  background: white;
  border-radius: 0.625rem;
  border: 1px solid #e7e9f4;
  margin-bottom: 15px;
}

.subscription-sidebar-item {
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

.subscription-sidebar-item.active {
  color: white;
  background-color: var(--primary);
}

.subscription-sidebar-item:first-child.active {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.subscription-sidebar-item {
  border-bottom: 1px solid #f1f1f1;
}

.subscription-sidebar-item:last-child {
  border-bottom: 1px solid transparent;
}

.subscription-sidebar-item-icon {
  width: 35px;
}

.subscription-sidebar-item-label {
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .subscription-sidebar {
    min-height: 435px;
  }
}

@media screen and (max-width: 768px) {
  .subscription-sidebar-item:last-child.active {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }
}
</style>
