<template>
  <div
    v-if="shop"
    class="row"
  >
    <div class="col-md-12">
      <ShopUploadCover :shop="shop" />
    </div>
  </div>
</template>

<script>
import ShopUploadCover from "@/views/settings/restaurant/ShopUploadCover"

export default {
  name: "BannerImageList",
  components: { ShopUploadCover },
  metaInfo () {
    return {
      title: this.$t("menu.settings.bannerImage"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      shop: null
    }
  },
  mounted () {
    this.getShop()
  },
  methods: {
    getShop () {
      const shop = JSON.parse(localStorage.getItem("shop"))

      if (!shop) {
        this.$toastr(
          "error",
          this.$t("string.pleaseFollowTheThrowExceptionError"),
          this.$t("string.throwExceptionError")
        )
        return 0
      }

      this.$axios.post(this.$config.base_url + "/api/restaurant/show", {
        shop_uuid: shop.uuid
      }).then(({ data }) => {
        if (data.code === 1 && data.data !== null) {
          this.shop = data.data
          localStorage.setItem("shop", JSON.stringify(this.shop))
          this.setShopProperty(this.shop)
        } else {
          this.$toastr(
            "error",
            this.$t("string.pleaseFollowTheThrowExceptionError"),
            this.$t("string.throwExceptionError")
          )
        }
      }).catch((err) => {
        this.onResponseError(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
