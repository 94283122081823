<template>
  <header class="main-header">
    <nav
      class="navbar navbar-static-top template-color"
      role="navigation"
    >
      <router-link
        class="text-white"
        to="/dashboard"
      >
        <span class="restau-name">
          <i class="fas fa-home mr-3" />
          <span v-if="shop !== null">{{ shop.name }}</span>
        </span>
      </router-link>
      <div class="navbar-custom-menu hidden-xs">
        <ul class="nav navbar-nav hidden-xs hidden-sm">
          <li :class="{'not-allowed-click': is_expired}">
            <template v-if="is_expired">
              <router-link
                :to="{name: '403'}"
                class="user-select-none"
              >
                <i class="fas fa-desktop mr-2" />
                {{ $t("label.displayCustomOrdered") }}
              </router-link>
            </template>
            <template v-else>
              <router-link
                :to="{path: '/display-order'}"
                class="user-select-none"
                target="_blank"
              >
                <i class="fas fa-desktop mr-2" />
                {{ $t("label.displayCustomOrdered") }}
              </router-link>
            </template>
          </li>
        </ul>
        <p class="navbar-text text-white user-select-none">
          {{ show_date }}
        </p>
        <template v-if="device_for_sell">
          <p class="navbar-text text-white">
            <i class="fas fa-shopping-cart font-s-16" />
          </p>
        </template>
        <ul class="nav navbar-nav">
          <template v-if="!device_for_sell">
            <li :class="{'not-allowed-click': is_expired}">
              <a
                style="padding-bottom: 8px; padding-top: 8px"
                @click="updateDeviceForSell"
              >
                <span
                  class="fa-stack fa-lg"
                  style="font-size: 16px;"
                >
                  <i class="fas fa-shopping-cart fa-stack-1x" />
                  <i class="fas fa-ban fa-stack-2x text-danger" />
                </span>
              </a>
            </li>
          </template>
          <li
            v-if="isOffline"
            :class="{'not-allowed-click': is_expired}"
          >
            <a style="padding-bottom: 8px; padding-top: 8px">
              <span
                class="fa-stack fa-lg"
                style="font-size: 16px;"
              >
                <i class="fas fa-wifi fa-stack-1x" />
                <i class="fas fa-ban fa-stack-2x text-danger" />
              </span>
            </a>
          </li>
          <li class="dropdown">
            <a
              aria-expanded="false"
              aria-haspopup="true"
              class="dropdown-toggle nav-color nav-icon-size"
              data-toggle="dropdown"
              href="javascript:void(0)"
              role="button"
              @click="changeLocale(locale === 'km' ? 'en' : 'km')"
            >
              <img
                :src="`/flag/${locale === 'km' ? 'en' : 'kh'}.png`"
                class="user-select-none"
                style="height: 16px; width: 26px;"
              >
            </a>
          </li>
          <li>
            <span class="nav-color nav-bar-size text-white">|</span>
          </li>
          <DropdownUserProfile
            :display-dropdown="false"
            :username-class="'text-white'"
          />
          <li>
            <a @click="logout">
              <i class="fas fa-sign-out-alt mr-2" /> {{ $t("label.logout") }}
            </a>
          </li>
        </ul>
      </div>
      <div class="navbar-custom-menu hidden-sm hidden-md hidden-lg">
        <ul class="nav navbar-nav">
          <li>
            <a
              class="open-menu font-s-18"
              role="button"
            >
              <i class="fas fa-bars" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { VueOfflineMixin } from "vue-offline"
import { offlineMixins } from "@/mixins/offline"
import DropdownUserProfile from "@/components/DropdownUserProfile"

export default {
  name: "NavbarV2",
  components: {
    DropdownUserProfile
  },
  mixins: [VueOfflineMixin, offlineMixins],
  data () {
    return {
      locale: localStorage.getItem("locale")
    }
  },
  mounted () {
    $("body").addClass("sidebar-collapse")
  },
  computed: {
    ...mapState({
      shop: state => state.shop.shop,
      device_for_sell: state => state.shop.device_for_sell
    }),
    ...mapGetters({
      is_expired: "shop/is_expired"
    })
  },
  methods: {
    changeLocale (locale) {
      this.locale = locale
      this.$i18n.locale = locale
      this.emit("locale", { locale: locale })
      localStorage.setItem("locale", locale)
    }
  }
}
</script>

<style scoped>
.main-header .navbar.template-color {
  background-color: var(--gray-dark-secondary);
}

.restau-name {
  float: left;
  cursor: pointer;
  background-color: transparent;
  background-image: none;
  padding: 7px 15px;
  font-size: 25px;
}

.skin-blue .main-header .navbar .nav > li > .nav-bar-size {
  font-size: 30px;
  opacity: 0.2;
  margin: 0 10px;
}

.skin-blue .main-header .navbar .nav > li > a {
  cursor: pointer;
}

.skin-blue .main-header .navbar .nav > li > a:hover,
.skin-blue .main-header .navbar .nav > li.active > a {
  background: #302f43;
  color: white;
}

@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .restau-name {
    font-size: 18px;
    height: 50px;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  .restau-name i,
  .restau-name span {
    font-size: 18px;
  }
}
</style>
