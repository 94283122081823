<template>
  <div class="row">
    <div class="col-md-12 margin-top">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div>
            <h3 class="m-0 font-weight-bold text-primary payment-subtitle">
              {{ $t("label.ediTicketTypes") }}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <ticket-type-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketTypeForm from "./Form"

export default {
  name: "Edit",
  components: { TicketTypeForm },
  metaInfo () {
    return {
      title: "Edit ticket type",
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
