<template>
  <div class="d-flex flex-column h-100">
    <div class="non-footer-container flex-1">
      <div class="d-flex flex-column h-100">
        <NavbarV2 />
        <div class="container-fluid no-padding flex-1 w-100 scrollbar">
          <div class="d-flex h-100">
            <LeftSideBarMobileScreen>
              <SidebarSettingV2 />
            </LeftSideBarMobileScreen>
            <div class="flex-1 product_container-fluid minus-w-sidebar">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foo">
      <DashboardFooter />
    </div>
  </div>
</template>

<script>
import NavbarV2 from "@/includes/NavbarV2"
import DashboardFooter from "@/includes/footer/Dashboard"
import SidebarSettingV2 from "@/includes/v2/sidebar/SidebarSettingV2"
import LeftSideBarMobileScreen from "@/components/LeftSideBarMobileScreen"

export default {
  name: "Settings",
  components: {
    DashboardFooter,
    LeftSideBarMobileScreen,
    NavbarV2,
    SidebarSettingV2
  }
}
</script>

<style scoped>
.non-footer-container {
  min-height: calc(100% - 40px);
}

.non-footer-container .d-flex .container-fluid {
  height: calc(100% - 50px);
}

@media screen and (max-height: 400px) {
  .foo {
    display: none;
  }
}
</style>
