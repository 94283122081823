<template>
  <div class="pos-menubar-content">
    <ul class="pos-menubar">
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': $route.name === 'customer-list-v2'}"
          :to="{name: 'customer-list-v2'}"
          class="closesidebar"
        >
          <i class="fas fa-user" />
          <p class="no-margin capitalize">
            {{ $t("label.customer") }}
          </p>
        </router-link>
      </li>
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': $route.name === 'customer-group-list-v2'}"
          :to="{name: 'customer-group-list-v2'}"
          class="closesidebar"
        >
          <i class="fas fa-users" />
          <p class="no-margin capitalize">
            {{ $t("label.customerGroup") }}
          </p>
        </router-link>
      </li>
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': $route.name === 'customer-membership-list-v2'}"
          :to="{name: 'customer-membership-list-v2'}"
          class="closesidebar"
        >
          <i class="fas fa-id-card" />
          <p class="no-margin capitalize">
            {{ $t("label.memberCard") }}
          </p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LeftSideBarCustomer",
  computed: {
    tabOrder () {
      const route_names = [
        "order-show",
        "payment-show"
      ]
      return route_names.includes(this.$route.name)
    },
    tabSale () {
      const route_names = [
        "list-recent-sell"
      ]
      return route_names.includes(this.$route.name)
    },
    tabCustomer () {
      const route_names = [
        "customer-list-v2",
        "customer-group-list-v2",
        "customer-membership-list-v2"
      ]
      return route_names.includes(this.$route.name)
    }
  }
}
</script>

<style scoped>
.pos-menubar-item a p {
  font-size: 13px;
}

.pos-menubar-content {
  height: 100%;
}

@media screen and (max-width: 768px) {
  .pos-menubar-content {
    height: auto;
  }
}
</style>
