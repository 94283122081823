<template>
  <div
    :class="hasBackground ? 'has-background' : ''"
    :style="`transform: scale(${scalePreview}) translate(${getTranslateValue(scalePreview)}%,
         ${getTranslateValue(scalePreview)}%);
         width: 15.7cm;
         margin-left: ${previewOnly? '0; margin: auto;' : '0'};
         padding: 1.5mm 5px;
         position: 'relative'`"
    class="on-print"
  >
    <div class="row">
      <template v-for="(eachBarcode, key) in barcodes">
        <div
          v-if="eachBarcode.barcode"
          :key="key"
          class="col-xs-4 bar-code-container"
        >
          <div class="background-sticker" />
          <vue-barcode
            v-if="eachBarcode"
            :options="{ lineColor: '#000', width: 2, height: 50, displayValue: true }"
            :value="eachBarcode.barcode"
            style="max-width: 5cm"
            tag="svg"
          />
          <div
            v-if="eachBarcode"
            class="product-name"
          >
            <strong>{{ eachBarcode.name }}</strong>
          </div>
          <div
            v-if="eachBarcode"
            class="row product-info"
          >
            <div
              class="col-xs-5"
              style="line-height: 0.8em; padding-right: 4mm"
            >
              {{ eachBarcode.currency.symbol }} {{ eachBarcode.sale_price }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarcodeList",
  props: {
    barcodes: {
      type: Array,
      default: () => {
        return []
      }
    },
    hasBackground: {
      type: Boolean,
      default: false
    },
    previewOnly: {
      type: Boolean,
      default: false
    },
    scalePreview: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getTranslateValue (scale) {
      return -((1 - scale) / (2 * scale)) * 100
    }
  }
}
</script>

<style scoped>
.has-background {
  background-color: #fffdd5;
}

.background-sticker {
  position: absolute;
  top: 0;
  width: 5cm;
  height: 1.85cm;
  border-radius: 4px;
  background-color: white;
}

.bar-code-container svg {
  width: 5cm !important;
  max-height: 1.1cm !important;
  margin-top: 5.2mm;
  transform: translate(0, 0) scale(1.2) !important;
}

.bar-code-container {
  height: 1.785cm !important;
  max-width: 5.2cm;
  margin: 1.5mm 0;
  position: relative;
  text-align: center;
}

.product-name {
  width: 5.2cm !important;
  padding-left: 0;
  text-align: center;
  font-size: 0.8em;
  line-height: 5.2mm;
  position: absolute;
  top: 0;
}

.product-info {
  width: 5.2cm !important;
  font-size: 0.7em;
  position: absolute;
  top: 1.4cm;
}

@media print {
  .on-print {
    margin-left: 26.5mm !important;
  }
}
</style>
