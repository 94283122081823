<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("currency.label.showCurrency") }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <CurrencyForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyForm from "./Form"

export default {
  name: "CurrencyShow",
  components: { CurrencyForm },
  metaInfo () {
    return {
      title: this.$t("currency.label.showCurrency"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
