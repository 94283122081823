const state = () => {
  return {
    user: null,
    administrators: [
      "administrator",
      "admin"
    ],
    roles: [],
    permissions: []
  }
}

const getters = {
  getUser: (state) => {
    return state.user
  },
  administrators: state => state.administrators,
  roles: state => state.roles,
  permissions: state => state.permissions
}

const mutations = {
  setUser (state, { user }) {
    const { confirmation_code } = user
    state.user = user
    localStorage.setItem(process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE, confirmation_code)
  },

  setRoles (state, roles) {
    state.roles = roles
  },

  setPermissions (state, permissions) {
    state.permissions = permissions
  }
}

const actions = {
  updateUser ({ commit }, user) {
    commit("setUser", { user: user })
  },

  setRoles ({ commit }, roles) {
    commit("setRoles", roles)
  },

  setPermissions ({ commit }, permissions) {
    commit("setPermissions", permissions)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
