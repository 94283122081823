<template>
  <div class="product-each-item user-select-none">
    <div
      class="product rounded-tr rounded-tl"
      @click="addToCart(customProduct)"
    >
      <div class="product-each-item__top rounded-tr rounded-tl">
        <div
          :style="`background-image: url('${defaultImg}')`"
          class="product-each-item__image image_contain rounded-tr rounded-tl"
        />
      </div>
      <div class="product-each-item__content">
        <div class="product-each-item__name text-ellipsis-2-line">
          {{ customProduct.name }}
        </div>
        <div class="text-bold font-s-18">
          {{ customProduct.currency ? customProduct.currency.symbol : null }}
          {{ productPrice | numFormat(num_format) }}
        </div>
        <div class="font-s-14">
          <div class="margin-bottom-none">
            {{ $t("label.unlimited") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "AsoraCustomProductItemGrid",
  props: {
    customProduct: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      origin_url: process.env.VUE_APP_ORIGIN_URL
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat
    }),
    productPrice () {
      if (this.customProduct) {
        const { custom_product_price } = this.customProduct
        if (custom_product_price && Array.isArray(custom_product_price) && custom_product_price.length > 0) {
          const { price } = custom_product_price[0]
          if (price) {
            return price
          }
        }
      }
      return this.$t("label.isAvailable")
    }
  },
  methods: {
    addToCart () {
      this.$store.dispatch("order/addProductToCart", this.customProduct)
      this.$store.dispatch("order/setDisplayPo", this)
    }
  }
}
</script>

<style scoped>
</style>
