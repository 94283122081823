<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs">
      <li class="active">
        <a
          data-toggle="tab"
          href="#images"
        >{{ $t("string.images") }}</a>
      </li>
      <li>
        <a
          data-toggle="tab"
          href="#options"
        >{{ $t("string.options") }}</a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        id="images"
        class="tab-pane active"
      >
        <div class="row">
          <div class="col-sm-5 col-md-8">
            <div class="upload-cover-wrapper">
              <template v-if="shopCover.length">
                <draggable
                  :list="shopCover"
                  class="tw-grid tw-grid-cols-2 md:tw-grid-cols-5 tw-gap-5 tw-mb-5"
                  group="order"
                  v-bind="dragOptions"
                  @change="updateOrdersCover"
                  @end="isDragging = false"
                  @start="isDragging = true"
                >
                  <template v-for="(item, key) in shopCover">
                    <div
                      class="tw-w-full tw-shadow-lg hover:tw-cursor-pointer"
                      :key="key"
                    >
                      <a
                        class="remove-cover"
                        target="_blank"
                      >
                        <div
                          class="remove-cover-btn"
                          @click="removeCover(item.id)"
                        >
                          <i class="fas fa-times" />
                        </div>
                        <img
                          :src="`${$config.base_url}/${item.src}`"
                          alt="Shop cover"
                          class="tw-w-full tw-rounded-lg"
                        >
                      </a>
                    </div>
                  </template>
                </draggable>
              </template>
              <template v-else>
                <div
                  class="d-flex align-items-center justify-content-center tw-h-[200px]"
                >
                  <p class="text-muted text-center w-100">
                    {{ $t("string.shopNoCover") }}
                  </p>
                </div>
              </template>
            </div>
            <div class="clearfix" />
          </div>
          <div class="col-sm-7 col-md-4">
            <CarouselImageUploader
              @fetchImages="updateOrdersCover"
            />
          </div>
        </div>
      </div>
      <div
        id="options"
        class="tab-pane"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label :for="'carousel_direction'">{{ $t("label.carousel_direction") }}</label>
              <select
                id="carousel_direction"
                v-model="carousel_direction"
                class="form-control"
              >
                <template v-for="(item, key) in carousel_directions">
                  <option
                    :key="key"
                    :value="item.value"
                  >
                    {{ item["name_" + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label :for="'carousel_effect'">{{ $t("label.carousel_effect") }}</label>
              <select
                id="carousel_effect"
                v-model="carousel_effect"
                class="form-control"
              >
                <template v-for="(item, key) in carousel_effects">
                  <option
                    :key="key"
                    :value="item.value"
                  >
                    {{ item["name_" + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label :for="'carousel_timeout'">{{ $t("label.carousel_timeout") }}</label>
              <select
                id="carousel_timeout"
                v-model="carousel_timeout"
                class="form-control"
              >
                <template v-for="(item, key) in carousel_timeouts">
                  <option
                    :key="key"
                    :value="item.value"
                  >
                    {{ item["name_" + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <label>{{ $t("label.carousel_loop") }}</label>
              <toggle-button
                v-model="carousel_loop"
                :font-size="14"
                :height="25"
                :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                :sync="true"
                :width="85"
                class="margin-bottom-none margin-t-5"
                color="#32c851"
              />
            </div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <label>{{ $t("label.carousel_pagination") }}</label>
              <toggle-button
                v-model="carousel_pagination"
                :font-size="14"
                :height="25"
                :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                :sync="true"
                :width="85"
                class="margin-bottom-none margin-t-5"
                color="#32c851"
              />
            </div>
            <div class="form-group tw-mt-3">
              <div class="tw-flex tw-items-end tw-justify-end tw-mt-3">
                <button
                  class="btn btn-info"
                  @click="updateConfiguration"
                >
                  <i class="fas fa-check-circle" />
                  {{ $t("button.update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce"
import draggable from "vuedraggable"
import CarouselImageUploader from "@/views/settings/restaurant/_components/CarouselImageUploader"

export default {
  name: "ShopUploadCover",
  components: {
    CarouselImageUploader,
    draggable
  },
  props: {
    shop: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data () {
    return {
      restaurant: null,
      isDragging: false,
      shopCover: [],
      carousel_direction: "horizontal",
      carousel_effect: "slide",
      carousel_timeout: 3000,
      carousel_loop: true,
      carousel_pagination: true,
      carousel_directions: [
        {
          name_en: "Horizontal",
          name_km: "ទិសដៅផ្ដេក",
          value: "horizontal"
        },
        {
          name_en: "Vertical",
          name_km: "ទិសដៅបញ្ឈរ",
          value: "vertical"
        }
      ],
      carousel_effects: [
        {
          name_en: "Slide Effect",
          name_km: "លក្ខណៈស្លាយ",
          value: "slide"
        },
        {
          name_en: "Fade Effect",
          name_km: "លក្ខណៈរសាត់",
          value: "fade"
        }
      ],
      carousel_timeouts: [
        {
          name_en: "1 second",
          name_km: "1 វិនាទី",
          value: 1000
        },
        {
          name_en: "2 seconds",
          name_km: "2 វិនាទី",
          value: 2000
        },
        {
          name_en: "3 seconds",
          name_km: "3 វិនាទី",
          value: 3000
        },
        {
          name_en: "4 seconds",
          name_km: "4 វិនាទី",
          value: 4000
        },
        {
          name_en: "5 seconds",
          name_km: "5 វិនាទី",
          value: 5000
        },
        {
          name_en: "6 seconds",
          name_km: "6 វិនាទី",
          value: 6000
        },
        {
          name_en: "7 seconds",
          name_km: "7 វិនាទី",
          value: 7000
        },
        {
          name_en: "8 seconds",
          name_km: "8 វិនាទី",
          value: 8000
        },
        {
          name_en: "9 seconds",
          name_km: "9 វិនាទី",
          value: 9000
        },
        {
          name_en: "10 seconds",
          name_km: "10 វិនាទី",
          value: 10000
        }
      ]
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 300,
        group: "order",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  mounted () {
    if (this.shop) {
      if (this.shop.media) {
        this.shopCover = this.shop.media
      }
      if (this.shop.carousel_direction) {
        this.carousel_direction = this.shop.carousel_direction
      }
      if (this.shop.carousel_effect) {
        this.carousel_effect = this.shop.carousel_effect
      }
      if (this.shop.carousel_timeout) {
        this.carousel_timeout = this.shop.carousel_timeout
      }
      if (this.shop.hasOwnProperty("carousel_loop")) {
        this.carousel_loop = this.shop.carousel_loop
      }
      if (this.shop.hasOwnProperty("carousel_pagination")) {
        this.carousel_pagination = this.shop.carousel_pagination
      }
    }
  },
  methods: {
    updateOrdersCover () {
      this.$axios.post(this.$config.base_url + "/api/shop/update-order-cover", {
        uuid: this.get_shop.uuid,
        covers: this.shopCover
      }).then((res) => {
        const shop = res.data.data
        localStorage.setItem("shop", JSON.stringify(shop))
        this.setShopProperty(shop)
        if (shop && shop.media) {
          this.shopCover = shop.media
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    removeCover (id) {
      this.$swal({
        html: `<p class="text-bold font-s-22">${this.$t("swal.removeCoverImage")}</p>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("swal.confirmation.button.yes"),
        cancelButtonText: this.swalNoButton
      }).then(() => {
        this.$axios.post(this.$config.base_url + "/api/shop/remove-cover-image", {
          uuid: this.get_shop.uuid,
          id: id
        }).then((res) => {
          const shop = res.data.data
          if (shop && shop.media) {
            this.shopCover = shop.media
          }

          localStorage.setItem("shop", JSON.stringify(shop))
          this.setShopProperty(shop)
          this.emit("display_banner_image", {
            display_banner_image: shop.media
          })
          this.$toastr("success", this.$t("message.removeBannerImageSuccessDesc"), this.$t("message.removeBannerImageSuccessTitle"))
        })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        this.onResponseError(err)
      })
    },
    updateConfiguration: debounce(function () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/restaurant/update-configuration", {
        uuid: this.get_shop.uuid,
        carousel_direction: this.carousel_direction,
        carousel_effect: this.carousel_effect,
        carousel_timeout: this.carousel_timeout,
        carousel_loop: this.carousel_loop,
        carousel_pagination: this.carousel_pagination
      }).then((res) => {
        localStorage.setItem("shop", JSON.stringify(res.data.data))
        this.setShopProperty(res.data.data)
        this.$toastr("success", this.$t("message.updateConfigurationSuccessDesc"), this.$t("message.updateConfigurationSuccessTitle"))
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }, 300)
  }
}
</script>

<style scoped>
.upload-cover-wrapper {

}

.cover-image {
  display: flex;
}

.cover-image a {
  margin-right: 8px;
}

.cover-image a img {
  height: 175px;
}

.cover-image a:last-child {
  margin-right: 0;
}

.remove-cover {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.remove-cover .remove-cover-btn {
  display: none;
}

.remove-cover:hover .remove-cover-btn {
  width: 45px;
  height: 45px;
  top: 5px;
  right: 5px;
  background-color: #808080e6;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 22px;
  font-weight: 900;
  color: white;
  text-shadow: 0 1px black;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .cover-image {
    display: block;
    width: 100%;
  }

  .cover-image a {
    margin-bottom: 8px;
  }

  .cover-image a:last-child {
    margin-bottom: 0;
  }

  .cover-image a img {
    height: auto;
    width: 100%;
  }
}
</style>
