<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t(title) }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <div
                    :class="{'has-error' : checkValidation('title_en') }"
                    class="form-group"
                  >
                    <label class="required">{{ $t("label.templateTitleEn") }}</label>
                    <input
                      v-model="invoice_template.title_en"
                      :placeholder="$t('placeholder.enterTemplateTitleAsEn')"
                      class="form-control"
                      type="text"
                    >
                    <template v-if="checkValidation('title_en')">
                      <span class="help-block">{{ message_validation["title_en"][0] }}</span>
                    </template>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="{'has-error' : checkValidation('title_km') }"
                    class="form-group"
                  >
                    <label>{{ $t("label.templateTitleKm") }}</label>
                    <input
                      v-model="invoice_template.title_km"
                      :placeholder="$t('placeholder.enterTemplateTitleAsKm')"
                      class="form-control"
                      type="text"
                    >
                    <template v-if="checkValidation('title_km')">
                      <span class="help-block">{{ message_validation["title_km"][0] }}</span>
                    </template>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div
                    :class="{'has-error' : checkValidation('header_en') }"
                    class="form-group"
                  >
                    <label>{{ $t("label.templateHeaderEn") }}</label>
                    <textarea
                      v-model="invoice_template.header_en"
                      :placeholder="$t('placeholder.enterTemplateHeaderAsEn')"
                      class="form-control text-area-h"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="{'has-error' : checkValidation('header_km') }"
                    class="form-group"
                  >
                    <label>{{ $t("label.templateHeaderKm") }}</label>
                    <textarea
                      v-model="invoice_template.header_km"
                      :placeholder="$t('placeholder.enterTemplateHeaderAsKm')"
                      class="form-control text-area-h"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div
                    :class="{'has-error' : checkValidation('footer_en') }"
                    class="form-group"
                  >
                    <label>{{ $t("label.templateFooterEn") }}</label>
                    <textarea
                      v-model="invoice_template.footer_en"
                      :placeholder="$t('placeholder.enterTemplateFooterAsEn')"
                      class="form-control text-area-h"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="{'has-error' : checkValidation('footer_km') }"
                    class="form-group"
                  >
                    <label>{{ $t("label.templateFooterKm") }}</label>
                    <textarea
                      v-model="invoice_template.footer_km"
                      :placeholder="$t('placeholder.enterTemplateFooterAsKm')"
                      class="form-control text-area-h"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("label.setOrderNumberForReset") }}</label>
                    <input
                      v-model="invoice_template.reset_number"
                      :placeholder="$t('placeholder.setOrderNumberForReset')"
                      class="form-control"
                      type="number"
                    >
                  </div>
                </div>
              </div>

              <div class="form-group d-flex align-items-center justify-content-between">
                <label>{{ $t("string.invoiceTemplate.showLogo") }}</label>
                <toggle-button
                  v-model="invoice_template.show_logo"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>{{ $t("string.invoiceTemplate.showName") }}</label>
                <toggle-button
                  v-model="invoice_template.show_shop_name"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>{{ $t("string.invoicetemplate.showordernumber") }}</label>
                <toggle-button
                  v-model="invoice_template.show_order_number"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>{{ $t("string.invoiceTemplate.showInvoiceNumber") }}</label>
                <toggle-button
                  v-model="invoice_template.show_invoice_number"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>{{ $t("string.invoiceTemplate.showContact") }}</label>
                <toggle-button
                  v-model="invoice_template.show_contact"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>
                  {{ $t("string.invoiceTemplate.showCustomerInfo") }}
                </label>
                <toggle-button
                  v-model="invoice_template.show_customer_info"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>
                  {{ $t("string.invoiceTemplate.showCashierName") }}
                </label>
                <toggle-button
                  v-model="invoice_template.show_cashier"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>
                  {{ $t("string.invoiceTemplate.showAmountPaid") }}
                </label>
                <toggle-button
                  v-model="invoice_template.show_amount_paid"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>
                  {{ $t("string.invoiceTemplate.showBalance") }}
                </label>
                <toggle-button
                  v-model="invoice_template.show_balance"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>
                  {{ $t("string.invoiceTemplate.showExchangeRate") }}
                </label>
                <toggle-button
                  v-model="invoice_template.show_exchange_rate"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.show'), unchecked: $t('label.none')}"
                  :sync="true"
                  :width="88"
                  color="#1876f2"
                />
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <label>
                  {{ $t("string.invoiceTemplate.setToDefault") }}
                </label>
                <toggle-button
                  v-model="invoice_template.active"
                  :font-size="14"
                  :height="25"
                  :labels="{checked: $t('label.active'), unchecked: $t('label.inactive')}"
                  :sync="true"
                  :width="85"
                  color="#32c851"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group text-center">
                <label>
                  <i class="fas fa-exclamation-circle" /> <strong>{{ $t("label.note") }}:
                    {{ $t("string.invoiceTemplate.note") }}</strong>
                </label>
              </div>
              <PreviewInvoiceTemplate
                :invoice-template="invoice_template"
                :shop="shop"
              />
            </div>
          </div>
        </div>
        <div class="box-footer text-right">
          <template v-if="this.$route.name !== 'show-tax'">
            <button
              :class="`btn-${this.$route.name === 'edit-invoice-template' ? 'info' : 'primary'}`"
              class="btn"
              @click="storeInvoiceTemplate"
            >
              <i class="fas fa-check-circle" />
              <template v-if="this.$route.name === 'edit-invoice-template'">
                {{ $t("button.update") }}
              </template>
              <template v-else>
                {{ $t("button.save") }}
              </template>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewInvoiceTemplate from "@/views/settings/invoice-template/_components/PreviewInvoiceTemplate"
import { mapGetters } from "vuex"

export default {
  name: "InvoiceTemplateForm",
  components: { PreviewInvoiceTemplate },
  props: {
    title: {
      type: String,
      default: "label.newInvoiceTemplate"
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      shop: "shop/getShop"
    })
  },
  data () {
    return {
      invoice_template: {
        uuid: null,
        shop_uuid: null,
        title_en: null,
        title_km: null,
        header_en: null,
        header_km: null,
        footer_en: "Thank you for coming",
        footer_km: "សូមអរគុណសម្រាប់ការអញ្ជីញមក។",
        active: false,
        show_logo: true,
        show_shop_name: true,
        show_invoice_number: true,
        show_order_number: true,
        show_contact: true,
        show_customer_info: true,
        show_cashier: true,
        show_amount_paid: true,
        show_balance: true,
        show_exchange_rate: true,
        reset_number: 9999999
      },
      message_validation: null
    }
  },
  mounted () {
    if (this.isEdit && this.$route.params.uuid) {
      this.getInvoiceTemplate()
    }
    $("#reset_number").tooltip("toggle")
  },
  methods: {
    checkValidation (key) {
      return this.message_validation !== null && this.message_validation.hasOwnProperty(key)
    },
    storeInvoiceTemplate () {
      if (this.shop) {
        this.invoice_template.shop_uuid = this.shop.uuid
      }
      this.$axios.post(this.$config.base_url + "/api/invoice-template/store-or-edit", this.invoice_template)
        .then((res) => {
          localStorage.setItem("invoice_template", JSON.stringify(res.data.data))
          this.$router.push({ name: "list-invoice-template" })
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.message_validation = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
    },
    getInvoiceTemplate () {
      this.$isLoading(true)
      let shopUuid = null
      if (this.shop) {
        shopUuid = this.shop.uuid
      }
      this.$axios.post(this.$config.base_url + "/api/invoice-template/show", {
        uuid: this.$route.params.uuid,
        shop_uuid: shopUuid
      }).then(({ data }) => {
        if (data.data) {
          const r = data.data
          this.invoice_template = {
            uuid: r.uuid,
            shop_uuid: null,
            title_en: r.title_en,
            title_km: r.title_km,
            header_en: r.custom_header_en,
            header_km: r.custom_header_km,
            footer_en: r.custom_footer_en,
            footer_km: r.custom_footer_km,
            active: r.active,
            show_logo: r.show_logo,
            show_shop_name: r.show_shop_name,
            show_invoice_number: r.show_invoice_number,
            show_order_number: r.show_order_number,
            show_contact: r.show_contact,
            show_customer_info: r.show_customer_info,
            show_cashier: r.show_cashier,
            show_amount_paid: r.show_amount_paid,
            show_balance: r.show_balance,
            show_exchange_rate: r.show_exchange_rate,
            reset_number: r.reset_number
          }
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.text-area-h {
  height: 75px;
  resize: none;
}
</style>
