import Order from "@/views/offline/order/Index"
import Sale from "@/views/offline/V2/order/SaleVersion2"
import List from "@/views/offline/order/List"

export const offlineOrderRouters = {
  path: "order",
  name: "offline-order",
  component: Order,
  children: [
    {
      path: "list",
      name: "offline-order-list",
      component: List
    },
    {
      path: "sale/:order_uuid?",
      name: "offline-sale",
      component: Sale
    }
  ]
}
