<template>
  <div
    :class="{ 'showed': showDrawer }"
    class="slide-select-customer"
  >
    <div class="position-relative select-customer-content overflow-y-auto scrollbar">
      <div class="form-group d-flex align-items-center justify-content-between user-select-none">
        <label class="title-section no-margin">{{ titleName }}</label>
        <button
          class="btn btn-danger btn-lg"
          @click="closingDrawer"
        >
          <i class="fas fa-times" />
        </button>
      </div>
      <div class="row no-margin">
        <div class="col-xs-12 no-padding">
          <div
            :class="{'has-error': validation && validation.hasOwnProperty('name_en')}"
            class="form-group"
          >
            <label class="font-s-16 required user-select-none">{{ $t("label.nameEn") }}</label>
            <input
              v-model="customerMembership.name_en"
              :placeholder="$t('placeholder.enterMembershipNameAsKm')"
              class="pos-input pos-input-lg"
              type="text"
            >
            <template v-if=" validation && validation.hasOwnProperty('name_en')">
              <span>{{ validation.name_en[0] }}</span>
            </template>
          </div>
        </div>
        <div class="col-xs-12 no-padding">
          <div
            :class="{'has-error': validation && validation.hasOwnProperty('name_km')}"
            class="form-group"
          >
            <label class="font-s-16 required user-select-none">{{ $t("label.nameKm") }}</label>
            <input
              v-model="customerMembership.name_km"
              :placeholder="$t('placeholder.enterMembershipNameAsKm')"
              class="pos-input pos-input-lg"
              type="text"
            >
            <template v-if=" validation && validation.hasOwnProperty('name_km')">
              <span>{{ validation.name_km[0] }}</span>
            </template>
          </div>
        </div>
        <div class="col-xs-12 no-padding">
          <div
            :class="{'has-error': validation && validation.hasOwnProperty('amount_percentage')}"
            class="form-group"
          >
            <label class="font-s-16 user-select-none required">
              {{ $t("table.membership.amountPercentage") }}
            </label>
            <input
              v-model="customerMembership.amount_percentage"
              :placeholder="$t('placeholder.enterAmountDiscountForMembership')"
              class="pos-input pos-input-lg"
              max="100"
              min="0"
              type="number"
            >
            <template v-if=" validation && validation.hasOwnProperty('amount_percentage')">
              <span>{{ validation.amount_percentage[0] }}</span>
            </template>
          </div>
        </div>

        <div class="col-xs-12 no-padding">
          <div
            :class="{'has-error': validation && validation.hasOwnProperty('type')}"
            class="form-group"
          >
            <label class="font-s-16 user-select-none required">
              {{ $t("table.membership.membershipType") }}
            </label>
            <select
              v-model="customerMembership.type"
              class="pos-input pos-input-lg"
              name="membership_type"
            >
              <template v-for="(item, key) in membershipTypes">
                <option
                  :key="key"
                  :value="item.value"
                >
                  {{ item["name_" + $i18n.locale] }}
                </option>
              </template>
            </select>
            <template v-if=" validation && validation.hasOwnProperty('type')">
              <span>{{ validation.type[0] }}</span>
            </template>
          </div>
        </div>
        <template v-if="customerMembership.type && customerMembership.type === 'duration'">
          <div class="col-xs-12 no-padding">
            <div
              :class="{'has-error': validation && validation.hasOwnProperty('duration')}"
              class="form-group margin-bottom-none"
            >
              <label class="font-s-16 user-select-none required">
                {{ $t("table.membership.membershipDuration") }}
              </label>
            </div>
            <div
              :class="{'has-error': validation && validation.hasOwnProperty('duration')}"
              class="form-inline w-100"
            >
              <div
                :class="{'has-error': validation && validation.hasOwnProperty('duration')}"
                class="form-group w-75"
              >
                <input
                  v-model="customerMembership.duration"
                  :placeholder="$t('placeholder.membershipDuration')"
                  class="pos-input pos-input-lg"
                  type="number"
                >
              </div>
              <div class="form-group w-25 pl-3">
                <select
                  v-model="customerMembership.duration_type"
                  class="pos-input pos-input-lg"
                  name="membership_type"
                >
                  <template v-for="(item, key) in durations_types">
                    <option
                      :key="key"
                      :value="item.value"
                    >
                      {{ item["name_" + $i18n.locale] }}
                    </option>
                  </template>
                </select>
              </div>
              <template v-if=" validation && validation.hasOwnProperty('duration')">
                <span>{{ validation.duration[0] }}</span>
              </template>
            </div>
          </div>
        </template>
        <template v-if="customerMembership.type && customerMembership.type === 'specific_date'">
          <div class="col-xs-12 no-padding">
            <div class="form-group">
              <label
                :class="{'has-error': validation && validation.hasOwnProperty('specific_date')}"
                class="font-s-16 user-select-none required"
              >
                {{ $t("table.membership.specificDate") }}
              </label>
              <datepicker
                v-model="customerMembership.specific_date"
                :clear-button="true"
                :disabled-dates="disabledDates"
                :format="dateFormat"
                :input-class="validation && validation.hasOwnProperty('specific_date') ? 'pos-input pos-input-lg border-red' : 'pos-input pos-input-lg'"
                :placeholder="'DD/MM/YYYY'"
                clear-button-icon="fas fa-times"
                name="membershipStartDate"
              />
              <template v-if="validation && validation.hasOwnProperty('specific_date')">
                <span class="has-error">{{ validation.specific_date[0] }}</span>
              </template>
            </div>
          </div>
        </template>
        <div class="col-xs-12 no-padding">
          <div class="form-group">
            <label class="font-s-16 user-select-none">{{ $t("label.active") }}</label>
            <div>
              <toggle-button
                :font-size="16"
                :height="30"
                :labels="{checked: $t('label.active'), unchecked: $t('label.inactive')}"
                :sync="true"
                :value="customerMembership.active"
                :width="100"
                class="no-margin"
                color="#1876f2"
                @change="customerMembership.active = !customerMembership.active"
              />
            </div>
          </div>
        </div>
        <div class="col-xs-12 no-padding">
          <div class="form-group text-right">
            <button
              :class="colorButton"
              class="btn btn-lg"
              @click="storeMembership"
            >
              <i class="fas fa-save mr-2" />
              {{ labelButton }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "DrawerMembership",
  props: {
    showDrawer: {
      type: Boolean,
      default: false
    },
    classListCustomer: {
      type: String,
      default: ""
    },
    // eslint-disable-next-line vue/prop-name-casing
    shop_id: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    },
    showCustomerForm: {
      type: Boolean,
      default: false
    },
    oldData: {
      type: Object,
      default: () => {
        return null
      }
    },
    membershipTypes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      validation: null,
      disabledDates: {
        to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      },
      durations_types: [
        {
          name_en: "Day",
          name_km: "ថ្ងៃ",
          value: "day"
        },
        {
          name_en: "Month",
          name_km: "ខែ",
          value: "month"
        },
        {
          name_en: "Year",
          name_km: "ឆ្នាំ",
          value: "year"
        }
      ],
      customerMembership: {
        uuid: null,
        name_en: null,
        name_km: null,
        amount_percentage: 0,
        active: true,
        type: "duration",
        specific_date: null,
        duration: 1,
        duration_type: "year"
      }
    }
  },
  computed: {
    ...mapState({
      dateFormat: state => state.sell.display_date_format
    }),
    titleName () {
      return this.oldData ? this.$t("label.editMembership") : this.$t("label.newMembership")
    },

    labelButton () {
      return this.oldData ? this.$t("button.saveMembership") : this.$t("button.createMembership")
    },

    colorButton () {
      return this.oldData ? "btn-primary" : "btn-success"
    }
  },
  mounted () {
    if (this.oldData) {
      this.customerMembership = this.oldData
    }
  },
  methods: {
    clearData () {
      this.customerMembership = {
        uuid: null,
        name_en: null,
        name_km: null,
        amount_percentage: null,
        active: true
      }
    },
    storeMembership () {
      let specificDate = null
      let duration = null
      let durationType = null
      if (this.customerMembership.type) {
        switch (this.customerMembership.type) {
        case "duration":
          if (this.customerMembership.duration) {
            duration = this.customerMembership.duration
          }
          durationType = this.customerMembership.duration_type
          break
        case "specific_date":
          if (this.customerMembership.specific_date) {
            specificDate = this.$moment(this.customerMembership.specific_date).format("YYYY-MM-DD")
          }
          break
        }
      }
      this.$axios.post(this.$config.base_url + "/api/v2/customer-membership/store", {
        shop_uuid: this.$config.shop_uuid,
        uuid: this.customerMembership.uuid,
        name_en: this.customerMembership.name_en,
        name_km: this.customerMembership.name_km,
        active: this.customerMembership.active,
        amount_percentage: parseFloat(this.customerMembership.amount_percentage),
        type: this.customerMembership.type,
        duration: duration,
        duration_type: durationType,
        specific_date: specificDate
      }).then(() => {
        this.$emit("refreshData")
        this.closingDrawer()
        this.closeCustomerDetailSm()
      }).catch((err) => {
        if (err.response.status === 422) {
          this.validation = err.response.data.errors
        } else {
          this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
        }
      })
    },
    closingDrawer () {
      this.$emit("closeDrawer")
      this.clearData()
      this.validation = null
      if (!this.oldData) {
        this.closeCustomerDetailSm()
      }
    }
  }
}
</script>

<style scoped>
.font-2x {
  font-size: 2em;
}

@media screen and (max-width: 1200px) {
  .font-2x {
    font-size: 1.5em;
  }

  .la-4 {
    font-size: 18px !important;
  }
}
</style>
