<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.showPromotion") }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <template>
            <div class="row">
              <div class="col-md-5 p-xs">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <h4>{{ $t("promotion.label.promotionDetail") }}</h4>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-5">{{ $t("promotion.label.promotionName") }}</label>
                  <label class="col-sm-7">{{ promotion.name ? promotion.name : $t("string.na") }}</label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-5">{{ $t("promotion.label.promotionProduct") }}</label>
                  <label class="col-sm-7">
                    {{ promotion.products.length > 0 ? promotion.products.length : 0 }}
                    {{ promotion.products.length === 1 ? $t("promotion.label.item") : $t("promotion.label.items") }}
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-5">{{ $t("promotion.label.promotionAmount") }}</label>
                  <label class="col-sm-7">
                    {{ promotion.amount_percentage ? promotion.amount_percentage : 0 }}
                    {{ promotion.is_percentage ? "%" : currency_code }}
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-5">{{ $t("promotion.label.startDate") }}</label>
                  <label class="col-sm-7">
                    {{ promotion.start ? $moment(promotion.start).format("Do MMM YYYY, h:mm:ss a") : $t("string.na") }}
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-5">{{ $t("promotion.label.endDate") }}</label>
                  <label class="col-sm-7">
                    {{
                      promotion.end ? $moment(promotion.end).format("Do MMM YYYY, h:mm:ss a") : $t("string.na")
                    }}
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-5">{{ $t("promotion.label.description") }}</label>
                  <label
                    class="col-sm-7"
                    style="text-align: justify"
                  >
                    {{ promotion.description ? promotion.description : $t("string.na") }}
                  </label>
                </div>
              </div>
              <div class="col-md-7 p-xs">
                <div class="row">
                  <div class="col-xs-6">
                    <h4>{{ $t("fields.productName") }}</h4>
                  </div>
                  <div class="col-xs-6">
                    <div
                      class="btn-group pull-right"
                      role="group"
                    >
                      <button
                        :class="changeLayout === 'list' ? 'btn btn-info' : 'btn btn-default'"
                        type="button"
                        @click="changeLayout = 'list'"
                      >
                        <i class="fas fa-bars" />
                      </button>
                      <button
                        :class="changeLayout === 'grid' ? 'btn btn-info' : 'btn btn-default'"
                        type="button"
                        @click="changeLayout = 'grid'"
                      >
                        <i class="fas fa-th-large" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <div class="product-promotion">
                      <template v-if="changeLayout === 'list'">
                        <template
                          v-if="promotion && promotion.products && Array.isArray(promotion.products) && promotion.products.length"
                        >
                          <div
                            v-for="(item, key) in promotion.products"
                            :key="key"
                            style="display: grid;"
                          >
                            <div class="product-item">
                              <div class="img-block">
                                <img
                                  v-if="item.images.length > 0"
                                  :src="$config.base_url + '/' + item.images[0].src"
                                  alt="Image"
                                  width="100%"
                                >
                                <img
                                  v-else
                                  alt="Image"
                                  src="/img/food_icon.png"
                                  width="100%"
                                >
                              </div>
                              <div
                                class="info-block"
                                style="width: calc(100% - 60px); display:flex; justify-content: space-between"
                              >
                                <div class="product-name">
                                  <div>
                                    <label><strong>{{ item.name }}</strong></label>
                                  </div>
                                  <div class="product-price">
                                    {{ item.currency ? item.currency.symbol : "" }}
                                    {{ parseFloat(item.sale_price).toFixed(2) }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <template v-for="(product, productIndex) in promotion.products">
                          <div
                            class="col-md-3 col-sm-4 col-xs-6"
                            :key="`productIndex${productIndex}`"
                          >
                            <div class="product">
                              <div class="product-image">
                                <template
                                  v-if="product.hasOwnProperty('images') && product.images.length > 0"
                                >
                                  <img
                                    alt="Product image"
                                    :src="$config.base_url + '/' + product.images[0].src"
                                    class="product-image-style"
                                  >
                                </template>
                                <template v-else>
                                  <img
                                    alt="Product image"
                                    class="img-responsive"
                                    src="/img/food_icon.png"
                                  >
                                </template>
                              </div>
                              <div class="product-content">
                                <p class="product-title">
                                  {{ product.name }}
                                </p>
                                <template v-if="product.has_inventory">
                                  <small>{{ product.amount_in_stock }} {{
                                    $t("label.items")
                                  }}</small>
                                </template>
                                <template
                                  v-else-if="product.has_inventory === false && product.is_available === true"
                                >
                                  <small class="text-muted">{{
                                    $t("label.isAvailable")
                                  }}</small>
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromotionShow",
  metaInfo () {
    return {
      title: this.$t("label.showPromotion"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
