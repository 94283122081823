<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="form-group"
        :class="{'has-error' : message_validation.name_en }"
      >
        <label class="required">{{ $t("label.nameEn") }}</label>
        <input
          :placeholder="$t('placeholder.enterNameAsEn')"
          class="form-control"
          type="text"
          v-model="productUnit.name_en"
        >
        <template v-if="message_validation.name_en">
          <span class="help-block">{{ message_validation["name_en"][0] }}</span>
        </template>
      </div>
    </div>
    <div class="col-md-12">
      <div
        class="form-group"
        :class="{'has-error' : message_validation.name_km }"
      >
        <label class="required">{{ $t("label.nameKm") }}</label>
        <input
          :placeholder="$t('placeholder.enterNameAsKm')"
          class="form-control"
          type="text"
          v-model="productUnit.name_km"
        >
        <template v-if="message_validation.name_km">
          <span class="help-block">{{ message_validation["name_km"][0] }}</span>
        </template>
      </div>
    </div>
    <div :class="columns">
      <div class="form-group text-right">
        <template v-if="this.$route.name !== 'show-product-unit'">
          <button
            :class="`btn btn-${$route.name === 'edit-product-unit' ? 'info' : 'primary'}`"
            @click="storeProductUnit"
          >
            <i class="fas fa-check-circle" />
            <template v-if="$route.name === 'edit-product-unit'">
              {{ $t("button.update") }}
            </template>
            <template v-else>
              {{ $t("button.save") }}
            </template>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductUnitForm",
  props: {
    columns: {
      type: String,
      default: "col-md-12"
    }
  },
  data () {
    return {
      productUnit: {
        shop_id: null,
        product_type: null,
        name_en: "",
        name_km: ""
      },
      config: this.$config,
      message_validation: {},
      productTypes: [],
      isShow: false
    }
  },
  methods: {
    getProductUnit () {
      this.$axios.post(this.$config.base_url + "/api/product-unit/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then(({ data }) => {
        this.productUnit = data.data
      }).catch((error) => {
        if (error.response && error.response.status && error.response.status === 422) {
          this.message_validation = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getProductType () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-type")
        .then((response) => {
          this.$isLoading(false)
          if (response.data.data !== null) {
            this.productTypes = response.data.data
          }
        })
    },
    storeProductUnit () {
      this.message_validation = {}
      this.productUnit.shop_id = this.get_shop.id
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-unit/store", this.productUnit)
        .then(() => {
          this.$router.push({ name: "list-product-unit" })
          if (this.productUnit.hasOwnProperty("id") && this.productUnit.id !== null) {
            this.$anotify(this.$t("model.unit"), "update")
          } else {
            this.$anotify(this.$t("model.unit"))
          }
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 422) {
            this.message_validation = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    this.getProductType()
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getProductUnit()
    }
    if (this.$route.name === "show-product-unit") {
      $("input").attr("disabled", true)
      this.isShow = true
    }
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}
</style>
