<template>
  <div class="asora-text-loading">
    <h3 class="text-muted">
      {{ $t("string.processing") }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "AsoraTextLoading"
}
</script>

<style scoped>
.asora-text-loading {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
