import Exchange from "@/views/settings/exchange/Index"
import List from "@/views/settings/exchange/List"
import Create from "@/views/settings/exchange/Create"
import Show from "@/views/settings/exchange/Show"
import Edit from "@/views/settings/exchange/Edit"

export const exchangeRouters = {
  path: "exchange",
  component: Exchange,
  children: [
    {
      path: "",
      name: "list-exchange",
      meta: {
        title: "Exchange Rate"
      },
      component: List
    },
    {
      path: "create",
      name: "create-exchange",
      component: Create
    },
    {
      path: "edit-exchange/:uuid",
      name: "edit-exchange",
      component: Edit
    },
    {
      path: "show-exchange/:uuid",
      name: "show-exchange",
      component: Show
    }
  ]
}
