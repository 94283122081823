export const shopMixin = {
  methods: {
    async setShopProperty (shop) {
      localStorage.setItem("shop", JSON.stringify(shop))
      localStorage.setItem("currency", JSON.stringify(shop.currency))
      localStorage.setItem("exchange", JSON.stringify(shop.exchange))
      localStorage.setItem("cover", shop.cover)
      localStorage.setItem("logo", shop.logo)

      if (shop.logo_base64) {
        localStorage.setItem("logo_base64", shop.logo_base64)
      }

      await this.$store.dispatch("shop/updateShop", shop)
      await this.$store.dispatch("shop/setCurrency", shop.currency)
      await this.$store.dispatch("shop/setDisplayBannerImage", this)

      if (shop.exchange) {
        await this.$store.dispatch("shop/setExchangeRate", shop.exchange)
        this.emit("display_exchange_rate", shop.exchange)
      }

      if (shop.tax) {
        await this.$store.dispatch("shop/setTax", shop.tax)
        this.emit("display_tax", shop.tax)
      }

      const data = {}
      if (shop.media) {
        data.media = shop.media
      }
      if (shop.carousel_direction) {
        data.carousel_direction = shop.carousel_direction
      }
      if (shop.carousel_effect) {
        data.carousel_effect = shop.carousel_effect
      }
      if (shop.carousel_timeout) {
        data.carousel_timeout = shop.carousel_timeout
      }
      if (shop.hasOwnProperty("carousel_loop")) {
        data.carousel_loop = shop.carousel_loop
      }
      if (shop.hasOwnProperty("carousel_pagination")) {
        data.carousel_pagination = shop.carousel_pagination
      }
      localStorage.setItem("display_banner_image", JSON.stringify(data))

      this.$config.shop_id = shop.id
      this.$config.shop_uuid = shop.uuid
      this.$config.app_name = shop.name
    }
  }
}
