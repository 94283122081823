<template>
  <div
    v-if="product_uuid"
    class="dropdown"
  >
    <button
      id="dropdownMenu1"
      aria-expanded="true"
      aria-haspopup="true"
      class="btn btn-default btn-sm dropdown-toggle tw-rounded-md"
      data-toggle="dropdown"
      type="button"
    >
      {{ $t("table.actions") }}
      <span class="caret" />
    </button>
    <ul
      aria-labelledby="dropdownMenu1"
      class="dropdown-menu dropdown-menu-right tw-rounded-md"
    >
      <DropdownItem
        :icon="product_active ? 'fas fa-stop' : 'fas fa-play'"
        :label="product_active ? $t('string.inactiveProduct') :$t('string.activeProduct')"
        @click="toggleProduct"
      />
      <DropdownItem
        :label="$t('button.editProduct')"
        icon="fa fa-edit"
        @click="$router.push({
          name: 'edit-product',
          params: {
            uuid: product_uuid
          }
        })"
      />
      <template v-if="product_has_inventory">
        <DropdownItem
          :label="$t('button.adjust_stock')"
          icon="fa fa-plus-circle"
          @click="openAdjustStockModal"
        />
        <DropdownItem
          :label="$t('button.reduce_stock')"
          icon="fa fa-minus-circle"
          @click="openReduceStockModal"
        />
      </template>
      <template v-else>
        <DropdownItem
          :label="$t('button.track_inventory')"
          icon="fas fa-warehouse"
          @click="trackInventory"
        />
      </template>
      <DropdownItem
        :label="$t('button.print')"
        icon="fa fa-print"
        @click="$router.push({
          name: 'print-barcode',
          params: {
            uuid: product_uuid
          }
        })"
      />
      <li
        class="divider tw-m-1"
        role="separator"
      />
      <DropdownItem
        :label="$t('button.deleteProduct')"
        class="tw-bg-red-500 tw-text-white hover:tw-bg-red-600"
        icon="fa fa-trash"
        @click="deleteProduct"
      />
    </ul>

    <ProductAdjustStockModal @fetchProductDetail="$emit('fetchProductDetail')" />
    <ProductReduceStockModal @fetchProductDetail="$emit('fetchProductDetail')" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import DropdownItem from "@/components/DropdownItem"
import ProductAdjustStockModal from "@/views/inventories/products/_components/ProductAdjustStockModal"
import ProductReduceStockModal from "@/views/inventories/products/_components/ProductReduceStockModal"

export default {
  name: "ProductActions",
  components: {
    ProductReduceStockModal,
    ProductAdjustStockModal,
    DropdownItem
  },
  props: {
    productName: {
      type: String,
      default: null
    }
  },
  computed: {
    product_uuid () {
      if (this.$route.params && this.$route.params.uuid) {
        return this.$route.params.uuid
      }
      return null
    },
    ...mapGetters("product", {
      product_has_inventory: "product_has_inventory",
      product_active: "product_active"
    }),
    deleteProductMessage () {
      if (this.productName) {
        return {
          message_en: "",
          message_km: ""
        }
      }
      return {
        message_en: "",
        message_km: ""
      }
    }
  },
  methods: {
    ...mapActions("product", {
      setAdjustProductUuid: "setAdjustProductUuid",
      setReduceProductUuid: "setReduceProductUuid"
    }),
    openAdjustStockModal () {
      $("#adjustStockModal").modal("show")
      this.setAdjustProductUuid(this.product_uuid)
    },
    openReduceStockModal () {
      $("#reduceStockModal").modal("show")
      this.setReduceProductUuid(this.product_uuid)
    },
    deleteProduct () {
      this.$swal({
        title: this.swalTitle,
        text: this.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.swalYesButton,
        cancelButtonText: this.swalNoButton
      }).then(() => {
        this.$axios.post(this.$config.base_url + "/api/product/delete", {
          shop_id: this.get_shop.id,
          uuid: this.product_uuid
        }).then((response) => {
          if (response.data.code === 1) {
            this.$toastr(
              "success",
              this.$t("string.delete_product_success"),
              this.$t("string.product")
            )
            this.$router.replace({
              name: "list-product"
            })
          }
        })
      })
    },
    trackInventory () {
      this.$router.push({
        name: "product-track-inventory",
        params: { uuid: this.$route.params.uuid }
      })
    },
    toggleProduct () {
      this.onConfirm({
        title: this.$t("string.toggle_product"),
        text: this.$t("string.do_you_want_to_toggle_this_product")
      }).then((accept) => {
        if (accept) {
          this.$axios.post(this.$config.base_url + "/api/product/toggle", {
            product_uuid: this.product_uuid
          }).then(() => {
            this.$emit("fetchProductDetail")
          })
        }
      })
    }
  },
  beforeDestroy () {
    this.setAdjustProductUuid(null)
    this.setReduceProductUuid(null)
  }
}
</script>

<style scoped>

</style>
