<template>
  <div class="row">
    <div :class="columns">
      <div
        class="form-group"
        :class="{'has-error' : validations.name_en }"
      >
        <label class="required">{{ $t("label.nameEn") }}</label>
        <input
          :placeholder="$t('placeholder.enterNameAsEn')"
          class="form-control"
          type="text"
          v-model="paymentOption.name_en"
        >
        <template v-if="validations.name_en">
          <span class="help-block">{{ validations["name_en"][0] }}</span>
        </template>
      </div>
      <div
        class="form-group"
        :class="{'has-error' : validations.name_km }"
      >
        <label class="required">{{ $t("label.nameKm") }}</label>
        <input
          :placeholder="$t('placeholder.enterNameAsKm')"
          class="form-control"
          type="text"
          v-model="paymentOption.name_km"
        >
        <template v-if="validations.name_km">
          <span class="help-block">{{ validations["name_km"][0] }}</span>
        </template>
      </div>
      <div class="form-group">
        <label class="">{{ $t("fields.label.description") }}</label>
        <textarea
          :placeholder="$t('fields.placeholder.description')"
          class="form-control"
          rows="6"
          v-model="paymentOption.description"
        />
      </div>
      <div class="form-group text-right">
        <template v-if="this.$route.name !== 'show-payment-option'">
          <button
            :class="`btn btn-${this.$route.name === 'edit-payment-option' ? 'info' : 'primary'}`"
            @click="storePaymentOption"
          >
            <i class="fas fa-check-circle" />
            <template v-if="this.$route.name === 'edit-payment-option'">
              {{ $t("button.update") }}
            </template>
            <template v-else>
              {{ $t("button.save") }}
            </template>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentOptionForm",
  props: {
    columns: {
      type: String,
      default: "col-md-12"
    }
  },
  data () {
    return {
      paymentOption: {
        code: "",
        name_en: "",
        name_km: "",
        description: "",
        active: true
      },
      config: this.$config,
      validations: {}
    }
  },
  methods: {
    getPaymentOption () {
      this.$axios.post(this.$config.base_url + "/api/payment-option/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then(({ data }) => {
        if (data.data) {
          this.paymentOption = data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    storePaymentOption () {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/payment-option/store", this.paymentOption)
        .then(({ data }) => {
          if (data.data) {
            this.$router.push({ name: "list-payment-option" })
            if (this.paymentOption.hasOwnProperty("id") && this.paymentOption.id !== null) {
              this.$anotify(this.$t("model.paymentOption"), "update")
            } else {
              this.$anotify(this.$t("model.paymentOption"))
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getPaymentOption()
    }
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}
</style>
