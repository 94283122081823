<template>
  <ProductTagForm :title="$t('label.product.tag.editTag')" />
</template>

<script>
import ProductTagForm from "@/views/inventories/tags/Form"

export default {
  components: {
    ProductTagForm
  },
  name: "EditProductTag",
  metaInfo () {
    return {
      title: this.$t("label.product.tag.editTag"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
