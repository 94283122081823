const state = {
  groups: [],
  memberships: [],

  filterGroupIds: [],
  filterMembershipIds: [],

  listGroups: [],
  listMemberships: [],
  listGenders: []
}

const getters = {}

const mutations = {
  // Customer Group
  setGroups (state, groups) {
    state.groups = groups
  },
  setListGroups (state, groups) {
    state.listGroups = groups
  },
  onChangeGroup (state, group) {
    if (group.id === -1) {
      state.groups.map((item) => {
        if (item.id === -1) {
          // eslint-disable-next-line no-return-assign
          return item.select_active = true
        } else {
          // eslint-disable-next-line no-return-assign
          return item.select_active = false
        }
      })
      state.filterGroupIds = []
    } else {
      if (state.groups.length > 0) {
        state.groups.map((item) => {
          if (item.id === -1) {
            // eslint-disable-next-line no-return-assign
            return item.select_active = false
          }
          if (item.id === group.id) {
            // eslint-disable-next-line no-return-assign
            return item.select_active = !item.select_active
          }
        })
      }
    }
  },
  setFilterGroupIds (state, ids) {
    state.filterGroupIds = ids
  },

  // Customer Membership
  setMemberships (state, memberships) {
    state.memberships = memberships
  },
  setListMemberships (state, memberships) {
    state.listMemberships = memberships
  },
  onChangeMembership (state, membership) {
    if (membership.id === -1) {
      state.memberships.map((item) => {
        if (item.id === -1) {
          // eslint-disable-next-line no-return-assign
          return item.select_active = true
        } else {
          // eslint-disable-next-line no-return-assign
          return item.select_active = false
        }
      })
      state.filterMembershipIds = []
    } else {
      if (state.memberships.length > 0) {
        state.memberships.map((item) => {
          if (item.id === -1) {
            // eslint-disable-next-line no-return-assign
            return item.select_active = false
          }
          if (item.id === membership.id) {
            // eslint-disable-next-line no-return-assign
            return item.select_active = !item.select_active
          }
        })
      }
    }
  },
  setFilterMembershipIds (state, ids) {
    state.filterMembershipIds = ids
  },

  // Customer Gender
  setListGenders (state, genders) {
    state.listGenders = genders
  }
}

const actions = {
  // Customer Group
  setGroups ({
    commit,
    state
  }, groups) {
    commit("setGroups", groups)
  },
  setListGroups ({
    commit,
    state
  }, groups) {
    commit("setListGroups", groups)
  },
  onChangeGroup ({
    commit,
    state
  }, group) {
    commit("onChangeGroup", group)
  },
  setFilterGroupIds ({
    commit,
    state
  }, ids) {
    commit("setFilterGroupIds", ids)
  },

  // Customer Membership
  setMemberships ({
    commit,
    state
  }, memberships) {
    commit("setMemberships", memberships)
  },
  setListMemberships ({
    commit,
    state
  }, memberships) {
    commit("setListMemberships", memberships)
  },
  onChangeMembership ({
    commit,
    state
  }, membership) {
    commit("onChangeMembership", membership)
  },
  setFilterMembershipIds ({
    commit,
    state
  }, ids) {
    commit("setFilterMembershipIds", ids)
  },

  // Customer Gender
  setListGenders ({
    commit,
    state
  }, genders) {
    commit("setListGenders", genders)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
