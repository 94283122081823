<template>
  <div>
    <div class="block-category d-flex rounded-tl rounded-tr">
      <div class="d-flex align-items-center justify-content-between">
        <p class="title-label mt-0 font-s-20 text-capitalize">
          <strong>{{ $t("label.reportDashboard") }}</strong>
        </p>
      </div>
    </div>
    <div class="report-dash-block-filter has-border-t has-border-b">
      <div class="block-view-by">
        <div class="form-group">
          <div>
            <label>{{ $t("label.viewBy") }}</label>
          </div>
          <div
            class="btn-group btn-group-lg"
            role="group"
          >
            <button
              :class="activeBtnStatus === this.keyword_today ? 'btn-primary text-white' : 'btn-default'"
              class="btn"
              type="button"
              @click="clickToday"
            >
              <i class="far fa-calendar-alt" />
              <span class="ml-2 hidden-xs hidden-sm">{{ $t("report.label.today") }}</span>
              <span class="ml-2 hidden-md hidden-lg">{{ $t("report.label.date") }}</span>
            </button>
            <button
              :class="activeBtnStatus === this.keyword_this_week ? 'btn-primary text-white' : 'btn-default'"
              class="btn btn-default"
              type="button"
              @click="clickThisWeek"
            >
              <i class="far fa-calendar-alt" />
              <span class="ml-2 hidden-xs hidden-sm">{{ $t("report.label.thisWeek") }}</span>
              <span class="ml-2 hidden-md hidden-lg">{{ $t("report.label.week") }}</span>
            </button>
            <button
              :class="activeBtnStatus === this.keyword_this_month ? 'btn-primary text-white' : 'btn-default'"
              class="btn btn-default"
              type="button"
              @click="clickThisMonth"
            >
              <i class="far fa-calendar-alt" />
              <span class="ml-2 hidden-xs hidden-sm">{{ $t("report.label.thisMonth") }}</span>
              <span class="ml-2 hidden-md hidden-lg">{{ $t("report.label.month") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="block-date-range">
        <div class="form-group">
          <div><label>{{ $t("label.date") }}</label></div>
          <date-picker
            v-model="date_range"
            :not-after="new Date()"
            :placeholder="$t('label.chooseDateRange')"
            :shortcuts="false"
            class="date-picker-lg"
            format="YYYY-MM-DD"
            lang="en"
            range
            style="width:100%;"
            type="date"
            @change="changeDateDuration('dateRange')"
            @clear="clickToday"
          />
        </div>
      </div>
      <div
        v-if="outlets.length"
        class="block-outlets"
      >
        <div class="form-group">
          <div><label>{{ $t("label.outlets") }}</label></div>
          <select
            v-model="shop_uuid"
            class="pos-input pos-input-lg box-shadow-none"
            name="shops"
            @change="clickToday"
          >
            <option
              v-for="(item, index) in outlets"
              :key="index"
              :value="item.uuid"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="report-dash-block-content">
      <div class="row">
        <div class="col-xs-12 col-md-6 col-lg-3">
          <CardReportDashboard
            :calc-comp-value-as-percent="calcCompValueAsPercent(revenue, last_revenue)"
            :chart-data="chartRevenueData"
            :chart-options="chartOptions"
            :class-icon="chartStatus(status_revenue)"
            :color-label-compare="status_revenue"
            :has-currency="true"
            :has-format="true"
            :label-compare="status_revenue ? 'label.' + status_revenue : null"
            :last-data="last_revenue"
            :main-value="revenue"
            title="label.revenue"
          />
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3">
          <CardReportDashboard
            :calc-comp-value-as-percent="calcCompValueAsPercent(sale_count, last_sale_count)"
            :chart-data="chartSaleCountData"
            :chart-options="chartOptionsNoCurrency"
            :class-icon="chartStatus(status_sale_count)"
            :color-label-compare="status_sale_count"
            :label-compare="status_sale_count ? 'label.' + status_sale_count : null"
            :last-data="last_sale_count"
            :main-value="sale_count"
            title="label.saleCount"
          />
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3">
          <CardReportDashboard
            :calc-comp-value-as-percent="calcCompValueAsPercent(customer_count, last_customer_count)"
            :chart-data="chartCustomerData"
            :chart-options="chartOptionsNoCurrency"
            :class-icon="chartStatus(status_customer_count)"
            :color-label-compare="status_customer_count"
            :label-compare="status_customer_count ? 'label.' + status_customer_count : null"
            :last-data="last_customer_count"
            :main-value="customer_count"
            title="label.customerCount"
          />
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3">
          <CardReportDashboard
            :calc-comp-value-as-percent="calcCompValueAsPercent(discounted, last_discounted)"
            :chart-data="chartDiscountValueData"
            :chart-options="chartOptions"
            :class-icon="chartStatus(status_discounted)"
            :color-label-compare="status_discounted"
            :has-currency="true"
            :has-format="true"
            :label-compare="status_discounted ? 'label.' + status_discounted : null"
            :last-data="last_discounted"
            :main-value="discounted"
            title="label.discounted"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6 col-lg-3">
          <CardReportDashboard
            :calc-comp-value-as-percent="calcCompValueAsPercent(discounted_percent, last_discounted_percent)"
            :chart-data="chartDiscountPercentData"
            :chart-options="chartOptionsWithPercent"
            :class-icon="chartStatus(status_discounted_percent)"
            :color-label-compare="status_discounted_percent"
            :has-format="true"
            :label-compare="status_discounted_percent ? 'label.' + status_discounted_percent : null"
            :last-data="last_discounted_percent"
            :main-value="discounted_percent"
            title="label.discounted"
          >
            <template slot="title">
              %
            </template>
            <template slot="main-value">
              %
            </template>
            <template slot="last-value">
              %
            </template>
          </CardReportDashboard>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3">
          <CardReportDashboard
            :calc-comp-value-as-percent="calcCompValueAsPercent(average_sale, last_average_sale)"
            :chart-data="chartAvgSaleValueData"
            :chart-options="chartOptions"
            :class-icon="chartStatus(status_average_sale)"
            :color-label-compare="status_average_sale"
            :has-currency="true"
            :has-format="true"
            :label-compare="status_average_sale ? 'label.' + status_average_sale : null"
            :last-data="last_average_sale"
            :main-value="average_sale"
            title="label.avgSaleValue"
          />
        </div>
        <div class="col-xs-12 col-md-6 col-lg-3">
          <CardReportDashboard
            :calc-comp-value-as-percent="calcCompValueAsPercent(average_item_sale, last_average_item_sale)"
            :chart-data="chartAvgItemSaleValueData"
            :chart-options="chartOptionsNoCurrency"
            :class-icon="chartStatus(status_average_item_sale)"
            :color-label-compare="status_average_item_sale"
            :label-compare="status_average_item_sale ? 'label.' + status_average_item_sale : null"
            :last-data="last_average_item_sale"
            :main-value="average_item_sale"
            title="label.avgItemsPerSale"
          />
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title text-capitalize">
            {{ $t("label.productSole") }}
            <template v-if="date_range.length">
              <span class="minus">
                ( <span>{{ $moment(date_range[0]).locale("en").format("DD/MM/YYYY") }}</span>
                <span>{{ $moment(date_range[1]).locale("en").format("DD/MM/YYYY") }}</span> )
              </span>
            </template>
          </h3>
        </div>
        <div class="box-body">
          <TotalSoldCount />

          <table
            id="report-product-sale-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("report.table.name") }}</th>
                <th>{{ $t("label.saleCount") }}</th>
                <th>{{ $t("label.price") }}</th>
                <th>{{ $t("label.revenue") }}</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title text-capitalize">
            {{ $t("label.topSalesPeople") }}
            <template v-if="date_range.length">
              <span class="minus">
                ( <span>{{ $moment(date_range[0]).locale("en").format("DD/MM/YYYY") }}</span>
                <span>{{ $moment(date_range[1]).locale("en").format("DD/MM/YYYY") }}</span> )
              </span>
            </template>
          </h3>
        </div>
        <div class="box-body">
          <table
            id="top-sales-people-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("report.table.name") }}</th>
                <th>{{ $t("label.saleCount") }}</th>
                <th>{{ $t("label.revenue") }}</th>
                <th>{{ $t("label.avgSaleValue") }}</th>
                <th>{{ $t("label.avgItemsPerSale") }}</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import { mapState } from "vuex"
import CardReportDashboard from "@/views/reports/dashboard/_components/CardReportDashboard"
import TotalSoldCount from "@/views/reports/dashboard/_components/TotalSoldCount"

export default {
  name: "Index",
  components: {
    TotalSoldCount,
    CardReportDashboard,
    DatePicker
  },
  metaInfo () {
    return {
      title: this.$t("label.reportDashboard"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      shop_uuid: null,
      outlets: [],
      keyword_today: "today",
      keyword_this_week: "thisWeek",
      keyword_this_month: "thisMonth",
      activeBtnStatus: "thisWeek",
      compare_result: "null",
      today: new Date(),
      date_range: [],
      chartOptions: {},
      chartOptionsNoCurrency: {},
      chartOptionsWithPercent: {},
      reportTable: null,
      topSalesPeopleTable: null,

      chartRevenueData: {},
      chartSaleCountData: {},
      chartCustomerData: {},
      chartDiscountValueData: {},
      chartDiscountPercentData: {},
      chartAvgSaleValueData: {},
      chartAvgItemSaleValueData: {},

      average_item_sale: null,
      average_sale: null,
      customer_count: null,
      revenue: null,
      sale_count: null,
      discounted: null,
      discounted_percent: null,

      last_average_item_sale: null,
      last_average_sale: null,
      last_customer_count: null,
      last_revenue: null,
      last_sale_count: null,
      last_discounted: null,
      last_discounted_percent: null,

      status_average_item_sale: null,
      status_average_sale: null,
      status_customer_count: null,
      status_revenue: null,
      status_sale_count: null,
      status_discounted: null,
      status_discounted_percent: null,

      timeout: 1500
    }
  },
  computed: {
    ...mapState({
      shop: state => state.shop.shop,
      shop_currency: state => state.shop.currency,
      num_format: state => state.shop.numFormat
    })
  },
  mounted () {
    this.$isLoading(true)
    if (localStorage.hasOwnProperty("shops")) {
      this.outlets = JSON.parse(localStorage.getItem("shops"))
    }
    this.shop_uuid = this.shop.uuid
    this.chartOptionsNoCurrency = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: "index",
        callbacks: {
          label: function (tooltipItems, data) {
            return " " + tooltipItems.yLabel
          }
        },
        titleFontSize: 16,
        bodyFontSize: 14
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            display: false
          }
        }]
      }
    }
    if (this.shop_currency) {
      this.chartOptions = this.customChartOption(this.shop_currency.code)
      this.chartOptionsWithPercent = this.customChartOption("%")
    }
    this.getReportDataPreviousMonths()
    this.clickToday()
    this.reportTable = this.reportDataTable()
    this.topSalesPeopleTable = this.topSalesPeople()
  },
  methods: {
    customChartOption (label) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          callbacks: {
            label: function (tooltipItems, data) {
              return " " + parseFloat(tooltipItems.yLabel).toFixed(2) + label
            }
          },
          titleFontSize: 16,
          bodyFontSize: 14
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              display: false
            }
          }]
        }
      }
    },
    calcCompValueAsPercent ($current_val, $last_val) {
      $current_val = parseFloat($current_val)
      $last_val = parseFloat($last_val)
      let $result = 0
      if ($current_val > $last_val) {
        $result = $current_val - $last_val
        return ($result / $current_val) * 100
      } else if ($current_val < $last_val) {
        $result = $last_val - $current_val
        $result = ($result / $last_val) * 100
        return "-" + $result
      }
      return null
    },
    chartStatus (status) {
      switch (status) {
      case "up":
        return "fa-caret-up text-green"
      case "down":
        return "fa-caret-down text-red"
      default:
        return "fa-sort text-muted"
      }
    },
    clickToday () {
      this.$isLoading(true)
      if (this.activeBtnStatus === this.keyword_today) return
      this.activeBtnStatus = this.keyword_today
      this.date_range = [this.today, this.today]
      this.$store.dispatch("totalSoldCount/setDateRange", this.date_range)
      this.compare_result = "day"
      setTimeout(() => {
        this.getReportDashboard()
        this.callReportDataTable()
        this.callTopSalesPeople()
        this.$isLoading(false)
      }, this.timeout)
    },
    clickThisWeek () {
      this.$isLoading(true)
      if (this.activeBtnStatus === this.keyword_this_week) return
      this.activeBtnStatus = this.keyword_this_week
      this.date_range = [this.getMonday(this.today), this.today]
      this.$store.dispatch("totalSoldCount/setDateRange", this.date_range)
      this.compare_result = "week"
      setTimeout(() => {
        this.getReportDashboard()
        this.callReportDataTable()
        this.callTopSalesPeople()
        this.$isLoading(false)
      }, this.timeout)
    },
    clickThisMonth () {
      this.$isLoading(true)
      if (this.activeBtnStatus === this.keyword_this_month) return
      this.activeBtnStatus = this.keyword_this_month
      this.date_range = [new Date(this.today.getFullYear(), this.today.getMonth(), 1), this.today]
      this.$store.dispatch("totalSoldCount/setDateRange", this.date_range)
      this.compare_result = "month"
      setTimeout(() => {
        this.getReportDashboard()
        this.callReportDataTable()
        this.callTopSalesPeople()
        this.$isLoading(false)
      }, this.timeout)
    },
    changeDateDuration () {
      this.$isLoading(true)
      this.activeBtnStatus = "chooseDateRange"
      this.compare_result = null
      this.$store.dispatch("totalSoldCount/setDateRange", this.date_range)
      setTimeout(() => {
        this.getReportDashboard()
        this.callReportDataTable()
        this.callTopSalesPeople()
        this.$isLoading(false)
      }, this.timeout)
    },

    getMonday (d) {
      d = new Date(d)
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    },
    getReportDashboard () {
      let start_date = null
      let end_date = null
      if (this.date_range.length) {
        if (this.date_range[0]) {
          start_date = this.$moment(this.date_range[0]).locale("en").format("YYYY-MM-DD")
        }
        if (this.date_range[1]) {
          end_date = this.$moment(this.date_range[1]).locale("en").format("YYYY-MM-DD")
        }
      }
      this.$axios.post(this.$config.base_url + "/api/report/get-report-dashboard", {
        uuid: this.shop_uuid,
        start_date: start_date,
        end_date: end_date,
        compare_result: this.compare_result
      }).then((res) => {
        const result = res.data.data
        this.average_item_sale = result.average_item_sale
        this.average_sale = result.average_sale
        this.customer_count = result.customer_count
        this.revenue = result.revenue
        this.sale_count = result.sale_count
        this.discounted = result.discounted
        this.discounted_percent = result.discounted_percent

        this.last_average_item_sale = result.last_average_item_sale
        this.last_average_sale = result.last_average_sale
        this.last_customer_count = result.last_customer_count
        this.last_revenue = result.last_revenue
        this.last_sale_count = result.last_sale_count
        this.last_discounted = result.last_discounted
        this.last_discounted_percent = result.last_discounted_percent

        this.status_average_item_sale = result.status_average_item_sale
        this.status_average_sale = result.status_average_sale
        this.status_customer_count = result.status_customer_count
        this.status_revenue = result.status_revenue
        this.status_sale_count = result.status_sale_count
        this.status_discounted = result.status_discounted
        this.status_discounted_percent = result.status_discounted_percent
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getReportDataPreviousMonths () {
      this.$axios.post(this.$config.base_url + "/api/report/get-report-data-previous-months", {
        uuid: this.shop_uuid
      }).then((res) => {
        const result = res.data.data
        this.chartRevenueData = {
          labels: result.chart_revenue_last_12.label,
          datasets: [
            {
              label: "Revenue",
              backgroundColor: "#1ccb87",
              data: result.chart_revenue_last_12.database
            }
          ]
        }
        this.chartSaleCountData = {
          labels: result.chart_sale_count_last_12.label,
          datasets: [
            {
              label: "Sale Count",
              backgroundColor: "#1ccb87",
              data: result.chart_sale_count_last_12.database
            }
          ]
        }
        this.chartCustomerData = {
          labels: result.chart_customer_last_12.label,
          datasets: [
            {
              label: "Customer",
              backgroundColor: "#1ccb87",
              data: result.chart_customer_last_12.database
            }
          ]
        }
        this.chartDiscountValueData = {
          labels: result.chart_dis_value_last_12.label,
          datasets: [
            {
              label: "Discounted",
              backgroundColor: "#1ccb87",
              data: result.chart_dis_value_last_12.database
            }
          ]
        }

        this.chartDiscountPercentData = {
          labels: result.chart_dis_percent_last_12.label,
          datasets: [
            {
              label: "Discounted %",
              backgroundColor: "#1ccb87",
              data: result.chart_dis_percent_last_12.database
            }
          ]
        }
        this.chartAvgSaleValueData = {
          labels: result.chart_average_sale_last_12.label,
          datasets: [
            {
              label: "Discounted",
              backgroundColor: "#1ccb87",
              data: result.chart_average_sale_last_12.database
            }
          ]
        }
        this.chartAvgItemSaleValueData = {
          labels: result.chart_average_item_sale_last_12.label,
          datasets: [
            {
              label: "Discounted",
              backgroundColor: "#1ccb87",
              data: result.chart_average_item_sale_last_12.database
            }
          ]
        }
      })
    },
    reportDataTable () {
      const self = this
      let start_date = null
      let end_date = null
      if (this.date_range.length) {
        if (this.date_range[0]) {
          start_date = this.$moment(this.date_range[0]).locale("en").format("YYYY-MM-DD")
        }
        if (this.date_range[1]) {
          end_date = this.$moment(this.date_range[1]).locale("en").format("YYYY-MM-DD")
        }
      }
      return $("#report-product-sale-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        ajax: {
          url: self.$config.base_url + "/api/report/get-sale-report-datatable",
          type: "POST",
          data: (d) => {
            d.start_date = start_date
            d.end_date = end_date
            d.uuid = self.shop_uuid
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "product_name",
            name: "product_name",
            title: self.$t("report.table.name")
          },
          {
            data: "amount",
            name: "amount",
            title: self.$t("label.saleCount")
          },
          {
            data: "sale_price",
            name: "sale_price",
            title: self.$t("label.price")
          },
          {
            data: "amount_total",
            name: "amount_total",
            title: self.$t("label.revenue")
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    topSalesPeople () {
      const self = this
      let start_date = null
      let end_date = null
      if (this.date_range.length) {
        if (this.date_range[0]) {
          start_date = this.$moment(this.date_range[0]).locale("en").format("YYYY-MM-DD")
        }
        if (this.date_range[1]) {
          end_date = this.$moment(this.date_range[1]).locale("en").format("YYYY-MM-DD")
        }
      }
      return $("#top-sales-people-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        ajax: {
          url: self.$config.base_url + "/api/report/get-top-sales-people",
          type: "POST",
          data: (d) => {
            d.start_date = start_date
            d.end_date = end_date
            d.uuid = self.shop_uuid
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "user_name",
            name: "user_name",
            title: self.$t("report.table.name")
          },
          {
            data: "sale_count",
            name: "sale_count",
            title: self.$t("label.saleCount")
          },
          {
            data: "revenue",
            name: "revenue",
            title: self.$t("label.revenue")
          },
          {
            data: "avg_sale_value",
            name: "avg_sale_value",
            title: self.$t("label.avgSaleValue")
          },
          {
            data: "avg_item_sale_value",
            name: "avg_item_sale_value",
            title: self.$t("label.avgItemsPerSale")
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    callReportDataTable () {
      if (this.reportTable) {
        this.reportTable.clear()
        this.reportTable.destroy()
        this.reportTable = this.reportDataTable()
      }
    },
    callTopSalesPeople () {
      if (this.topSalesPeopleTable) {
        this.topSalesPeopleTable.clear()
        this.topSalesPeopleTable.destroy()
        this.topSalesPeopleTable = this.topSalesPeople()
      }
    }
  }
}
</script>

<style scoped>
.report-dash-block-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 15px;
}

.report-dash-block-filter .block-view-by,
.report-dash-block-filter .block-date-range {
  margin-right: 15px;
}

.block-date-range,
.block-outlets {
  width: 20%;
}

.card-review-report,
.report-dash-block-content {
  padding: 15px;
}

.report-dash-block-content .row .col-md-6 {
  margin-bottom: 15px;
}

.text-up {
  color: #00a65a !important;
}

.text-down {
  color: #dd4b39 !important;
}

.text-no_change {
  color: #777;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .block-date-range,
  .block-outlets {
    width: 24.5%;
  }
}

@media screen and (max-width: 768px) {
  .report-dash-block-filter {
    display: block;
  }

  .block-date-range,
  .block-view-by {
    margin-right: 0;
  }

  .block-view-by,
  .block-date-range,
  .block-outlets {
    width: 100%;
  }
}

</style>
