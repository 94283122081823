<template>
  <div
    :style="`height: ${height}vh;`"
    class="empty-cart"
  >
    <img
      alt="Empty Cart Image"
      src="/img/undraw_empty_cart_co35.svg"
      style="width: 210px;"
    >
  </div>
</template>

<script>
export default {
  name: "EmptyCart",
  props: {
    height: {
      type: Number,
      default: 65
    }
  }
}
</script>

<style scoped>
.empty-cart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
