<template>
  <div class="content-wrapper">
    <section class="content container-fluid">
      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div
              v-if="selectedMonth && selectedYear"
              class="box-header with-border"
            >
              <h3 class="box-title">
                {{ $t("label.allProduct") }}
                ( {{ selectedMonth["label_" + $i18n.locale] | nullable }} / {{ selectedYear.label | nullable }} )
              </h3>
              <div class="box-tools">
                <button
                  class="btn btn-primary btn-sm"
                  @click="exportProductInventory"
                >
                  <i class="fas fa-download" /> {{ $t("button.export") }}
                </button>
              </div>
            </div>
            <div class="box-body">
              <div class="row margin-bottom">
                <div class="margin-bottom">
                  <div class="col-md-2 col-sm-3 col-xs-4">
                    <multiselect
                      v-model="selectedMonth"
                      :allow-empty="false"
                      :label="'label_' + $i18n.locale"
                      :option-height="104"
                      :options="months"
                      :placeholder="$t('string.selectMonth')"
                      :show-labels="false"
                      track-by="value"
                      @input="getDataTable"
                    />
                  </div>
                  <div class="col-md-2 col-sm-3 col-xs-4">
                    <multiselect
                      v-model="selectedYear"
                      :allow-empty="false"
                      :option-height="104"
                      :options="years"
                      :placeholder="$t('string.selectYear')"
                      :show-labels="false"
                      label="label"
                      track-by="value"
                      @input="getDataTable"
                    />
                  </div>
                  <div class="col-md-2 col-sm-3 col-xs-4">
                    <multiselect
                      v-model="selectedForSale"
                      :allow-empty="false"
                      :label="'label_' + $i18n.locale"
                      :option-height="104"
                      :options="forSaleOption"
                      :placeholder="$t('string.pleaseSelect')"
                      :show-labels="false"
                      track-by="value"
                      @input="getDataTable"
                    />
                  </div>
                </div>
              </div>
              <div class="margin-bottom">
                <table
                  id="report-product-sale-table"
                  class="table table-bordered"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("report.table.productName") }}</th>
                      <th>{{ $t("report.table.amountInStock") }}</th>
                      <th>{{ $t("report.table.importedPrice") }}</th>
                      <th>{{ $t("report.table.salePrice") }}</th>
                      <th>{{ $t("report.table.createdAt") }}</th>
                      <th>{{ $t("report.table.action") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t("label.inventoryTransaction") }}
              </h3>
            </div>
            <div class="box-body">
              <div class="row margin-bottom">
                <div class="col-xs-6 col-md-2 ">
                  <multiselect
                    v-model="selectedTransactionOption"
                    :allow-empty="false"
                    :label="'label_'+$i18n.locale"
                    :option-height="104"
                    :options="transactionOption"
                    :placeholder="$t('string.pleaseSelect')"
                    :show-labels="false"
                    track-by="value"
                    @input="getDataTable"
                  />
                </div>
              </div>
              <div class="margin-bottom">
                <table
                  id="report-product-sale-history-table"
                  class="table table-bordered"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("report.table.name") }}</th>
                      <th>{{ $t("report.table.name") }}</th>
                      <th>{{ $t("report.table.name") }}</th>
                      <th>{{ $t("report.table.productName") }}</th>
                      <th>{{ $t("report.table.productName") }}</th>
                      <th>{{ $t("report.table.amount") }}</th>
                      <th>{{ $t("report.table.price") }}</th>
                      <th>{{ $t("report.table.transactionType") }}</th>
                      <th>{{ $t("report.table.importedDate") }}</th>
                      <th>{{ $t("report.table.expiredDate") }}</th>
                      <th>{{ $t("report.table.exportedDate") }}</th>
                      <th>{{ $t("report.table.createdAt") }}</th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ProductSaleLayout",
  metaInfo () {
    return {
      title: this.$t("report.menu.reportMonthly"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      oTable: null,
      transactionTable: null,
      config: this.$config,
      shop_uuid: JSON.parse(localStorage.getItem("shop")).uuid,
      months: [
        {
          label_km: "មករា",
          label_en: "January",
          value: 0
        },
        {
          label_km: "កុម្ភៈ",
          label_en: "February",
          value: 1
        },
        {
          label_km: "មីនា",
          label_en: "March",
          value: 2
        },
        {
          label_km: "មេសា",
          label_en: "April",
          value: 3
        },
        {
          label_km: "ឧសភា",
          label_en: "May",
          value: 4
        },
        {
          label_km: "មិថុនា",
          label_en: "June",
          value: 5
        },
        {
          label_km: "កក្កដា",
          label_en: "July",
          value: 6
        },
        {
          label_km: "សីហា",
          label_en: "August",
          value: 7
        },
        {
          label_km: "កញ្ញា",
          label_en: "September",
          value: 8
        },
        {
          label_km: "តុលា",
          label_en: "October",
          value: 9
        },
        {
          label_km: "វិច្ឆិកា",
          label_en: "November",
          value: 10
        },
        {
          label_km: "ធ្នូ",
          label_en: "December",
          value: 11
        }
      ],
      years: [],
      transactionOption: [
        {
          label_km: "ទាំងអស់",
          label_en: "All",
          value: ""
        },
        {
          label_km: "ទំនិញនាំចូល",
          label_en: "Transaction In",
          value: "in"
        },
        {
          label_km: "ទំនិញនាំចេញ",
          label_en: "Transaction Out",
          value: "out"
        }
      ],
      selectedMonth: null,
      selectedYear: null,
      selectedTransactionOption: {
        label_km: "ទាំងអស់",
        label_en: "All",
        value: ""
      },
      selectedForSale: {
        label_km: "ទាំងអស់",
        label_en: "All",
        value: ""
      },
      productType: {
        label: this.$t("productType.all"),
        value: -1
      },
      chartData: {},
      chartOptions: {},
      product_id: ""
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)

      this.transactionTable.clear()
      this.transactionTable.destroy()
      this.transactionTable = this.transactionDataTable()
      this.transactionTable.draw(true)
    }
  },
  computed: {
    forSaleOption () {
      return [
        {
          label_km: "ទាំងអស់",
          label_en: "All",
          value: ""
        },
        {
          label_km: "សម្រាប់លក់",
          label_en: "For Selling",
          value: true
        },
        {
          label_km: "មិនមែនសម្រាប់លក់",
          label_en: "Not For Selling",
          value: false
        }
      ]
    }
  },
  methods: {
    getMonthYearOption () {
      const currentYear = new Date().getFullYear()
      for (let i = currentYear; i >= 2015; i--) {
        this.years.push({
          label: i,
          value: i
        })
      }

      this.selectedMonth = this.months.filter((month) => {
        return month.value === this.$moment().month()
      })[0]

      this.selectedYear = this.years.filter((year) => {
        return year.value === this.$moment().year()
      })[0]
    },

    getDataTable () {
      this.oTable.draw(true)
      this.transactionTable.draw(true)
    },

    dataTable () {
      const self = this
      return $("#report-product-sale-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        ajax: {
          url: this.$config.base_url + "/api/report/get-stock-monthly-datatable",
          type: "POST",
          data: (d) => {
            d.uuid = self.shop_uuid
            d.monthly = self.selectedMonth
            d.year = self.selectedYear
            d.forSale = self.selectedForSale.value
            d.lang = self.$i18n.locale
            d.productType = self.productType.value
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "name",
            name: "name",
            title: self.$t("report.table.productName")
          },
          {
            data: "amount_stock",
            name: "amount_stock",
            title: self.$t("report.table.amountInStock"),
            searchable: false
          },
          {
            data: "imported_price",
            name: "imported_price",
            title: self.$t("report.table.importedPrice"),
            searchable: false,
            orderable: false
          },
          {
            data: "sale_price",
            name: "sale_price",
            title: self.$t("report.table.salePrice"),
            searchable: false,
            orderable: false
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("report.table.createdAt"),
            searchable: true,
            orderable: true
          },
          {
            data: "action",
            name: "action",
            title: self.$t("report.table.action"),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },

    transactionDataTable () {
      const self = this
      return $("#report-product-sale-history-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        autoSize: true,
        ajax: {
          url: this.$config.base_url + "/api/report/get-inventory-transaction-monthly-datatable",
          type: "POST",
          data: (d) => {
            d.uuid = self.shop_uuid
            d.monthly = self.selectedMonth.value
            d.year = self.selectedYear.value
            d.transactionOption = self.selectedTransactionOption.value
            d.lang = self.$i18n.locale
            d.productType = self.productType.value
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "user_name",
            name: "user_name",
            title: self.$t("report.table.name"),
            searchable: false,
            orderable: false
          },
          {
            data: "user_name",
            name: "user.first_name",
            title: self.$t("report.table.name"),
            orderable: false
          },
          {
            data: "user_name",
            name: "user.last_name",
            title: self.$t("report.table.name"),
            orderable: false
          },
          {
            data: "product_name",
            name: "product_name",
            title: self.$t("report.table.productName")
          },
          {
            data: "product_name",
            name: "product.name",
            title: self.$t("report.table.productName")
          },
          {
            data: "amount",
            name: "amount",
            title: self.$t("report.table.amount"),
            orderable: false
          },
          {
            data: "price",
            name: "price",
            orderable: false
          },
          {
            data: "type",
            name: "type",
            title: self.$t("report.table.transactionType"),
            searchable: false,
            orderable: false
          },
          {
            data: "import_date",
            name: "import_date",
            title: self.$t("report.table.importedDate"),
            searchable: false,
            orderable: true
          },
          {
            data: "expired_date",
            name: "expired_date",
            title: self.$t("report.table.expiredDate"),
            searchable: false,
            orderable: true
          },
          {
            data: "exported_date",
            name: "created_at",
            title: self.$t("report.table.exportedDate"),
            searchable: false,
            orderable: true
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("report.table.createdAt"),
            searchable: false,
            orderable: true
          }
        ],
        columnDefs: [
          {
            targets: [1, 2, 4],
            visible: false
          }
        ],

        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    exportProductInventory () {
      const baseUrl = `${this.$config.base_url}/api/export-product-inventory`
      const params = `${parseInt(this.selectedMonth.value) + 1}/${this.selectedYear.value}/${this.productType.value}/${this.get_shop.uuid}`
      window.open(`${baseUrl}/${params}`, "_blank")
    }
  },
  mounted () {
    const self = this
    self.getMonthYearOption()
    self.oTable = self.dataTable()
    self.transactionTable = self.transactionDataTable()

    $(document).off("click", ".btn-show-detail")

    $(document).on("click", ".btn-show-detail", function () {
      const id = $(this).attr("data-id")
      self.$router.push({
        name: "inventory-transaction-history",
        params: {
          id: id,
          monthly: self.selectedMonth.value,
          year: self.selectedYear.value
        }
      })
    })
  }
}
</script>

<style scoped>
.table-padding {
  padding: 0 30px;
}
</style>
