<template>
  <div :class="columns">
    <div class="row">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("string.newProduct") }}
            </h3>
            <div class="box-tools hidden-xs">
              <button
                class="btn btn-default btn-sm"
                @click="goToListRoute"
              >
                <i class="fas fa-arrow-left" /> {{ $t("button.back") }}
              </button>
            </div>
          </div>
          <div class="box-body">
            <template v-if="!onLoading">
              <template v-if="allowed">
                <div class="row">
                  <div class="col-xs-12 col-md-8 col-lg-8 p-xs has-border-r no-border-xs">
                    <div class="row">
                      <div class="col-md-6">
                        <div
                          :class="'form-group ' + (errorsMessage !== null && errorsMessage.hasOwnProperty('barcode') ? 'has-error' : '')"
                        >
                          <label class="">{{ $t("string.barcode") }}</label>
                          <input
                            v-model="product.barcode"
                            :placeholder="$t('string.barcode')"
                            class="form-control input-lg"
                            type="text"
                          >
                          <template v-if="errorsMessage !== null && errorsMessage.hasOwnProperty('barcode')">
                            <span class="help-block">{{ errorsMessage["barcode"][$i18n.locale] }}</span>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div
                          :class="'form-group ' + (errorsMessage !== null && errorsMessage.hasOwnProperty('sku') ? 'has-error' : '')"
                        >
                          <label class="">{{ $t("string.sku") }}</label>
                          <input
                            v-model="product.sku"
                            :placeholder="$t('placeholder.sku')"
                            class="form-control input-lg"
                            type="text"
                          >
                          <template v-if="errorsMessage !== null && errorsMessage.hasOwnProperty('sku')">
                            <span class="help-block">{{ errorsMessage["sku"][$i18n.locale] }}</span>
                          </template>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          :class="'form-group ' + (errorsMessage !== null && errorsMessage.hasOwnProperty('name') ? 'has-error' : '')"
                        >
                          <label class="required">{{ $t("drinks.fields.label.name") }}</label>
                          <input
                            v-model="product.name"
                            :placeholder="$t('drinks.fields.placeholder.name')"
                            class="form-control input-lg"
                            type="text"
                          >
                          <template v-if="errorsMessage !== null && errorsMessage.hasOwnProperty('name')">
                            <span class="help-block">{{ errorsMessage["name"][0] }}</span>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("drinks.fields.label.importedPrice") }}</label>
                          <input
                            v-model="product.imported_price"
                            :placeholder="$t('drinks.fields.placeholder.importedPrice')"
                            class="form-control input-lg"
                            type="number"
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          :class="'form-group ' + (errorsMessage !== null && errorsMessage.hasOwnProperty('sale_price') ? 'has-error' : '')"
                        >
                          <label class="required">{{ $t("drinks.fields.label.salePrice") }}</label>
                          <input
                            v-model="product.sale_price"
                            :placeholder="$t('product.fields.placeholder.salePrice')"
                            class="form-control input-lg"
                            type="number"
                          >
                          <template
                            v-if="errorsMessage !== null && errorsMessage.hasOwnProperty('sale_price')"
                          >
                            <span class="help-block">{{ errorsMessage["sale_price"][0] }}</span>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div
                          :class="'form-group ' + (errorsMessage !== null && errorsMessage.hasOwnProperty('currency') ? 'has-error' : '')"
                        >
                          <label class="required">{{ $t("drinks.fields.label.currency") }}</label>
                          <select
                            v-model="product.currency"
                            class="form-control input-lg"
                            name="currency"
                          >
                            <option
                              :value="null"
                              disabled
                            >
                              {{ $t("drinks.fields.placeholder.currency") }}
                            </option>
                            <template v-for="(item, key) in currencyOptions">
                              <option
                                :key="key"
                                :value="item"
                              >
                                {{ item["name_" + $i18n.locale] }}
                              </option>
                            </template>
                          </select>
                          <template v-if="errorsMessage !== null && errorsMessage.hasOwnProperty('currency')">
                            <span class="help-block">{{ errorsMessage["currency"][0] }}</span>
                          </template>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("drinks.fields.label.category") }}</label>
                          <div class="input-group">
                            <multiselect
                              v-model="product.product_category"
                              :label="'name_' + $i18n.locale"
                              :option-height="104"
                              :options="categoryOptions"
                              :placeholder="$t('drinks.fields.placeholder.category')"
                              :show-labels="false"
                              track-by="id"
                            />
                            <div class="input-group-btn input-group-multiselect">
                              <button
                                class="btn btn-primary"
                                data-target="#newCategoryModal"
                                data-toggle="modal"
                                @click="clickModalNew('category')"
                              >
                                <i class="fas fa-plus-circle" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("drinks.fields.label.tag") }}</label>
                          <div class="input-group">
                            <multiselect
                              v-model="product.product_tags"
                              :label="'name_' + $i18n.locale"
                              :limit="3"
                              :multiple="true"
                              :option-height="104"
                              :options="tagOptions"
                              :placeholder="$t('drinks.fields.placeholder.tag')"
                              :show-labels="false"
                              track-by="id"
                            />
                            <div class="input-group-btn input-group-multiselect">
                              <button
                                class="btn btn-primary"
                                data-target="#newCategoryModal"
                                data-toggle="modal"
                                @click="clickModalNew('tag')"
                              >
                                <i class="fas fa-plus-circle" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("product.fields.label.chooseUnit") }}</label>
                          <div class="input-group">
                            <multiselect
                              v-model="product.product_unit"
                              :label="'name_' + $i18n.locale"
                              :option-height="104"
                              :options="unitOptions"
                              :placeholder="$t('product.fields.unit.chooseUnit')"
                              :show-labels="false"
                              track-by="id"
                            />
                            <div class="input-group-btn input-group-multiselect">
                              <button
                                class="btn btn-group btn-primary"
                                data-target="#newCategoryModal"
                                data-toggle="modal"
                                @click="clickModalNew('unit')"
                              >
                                <i class="fas fa-plus-circle" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="toggle_product">{{ $t("string.status") }}</label>
                          <div class="input-group">
                            <label for="toggle_product">
                              <input
                                id="toggle_product"
                                v-model="product.active"
                                name="toggle_product"
                                type="checkbox"
                              > {{ $t("string.active") }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <!--Inventory Section-->
                    <div class="row">
                      <div class="col-md-12">
                        <label style="font-size: 18px">{{ $t("dirnks.fields.label.inventory") }}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="checkbox">
                          <label>
                            <input
                              v-model="product.has_inventory"
                              type="checkbox"
                            >
                            {{ $t("product.fields.label.trackInventory") }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <template v-if="product.has_inventory === true">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{ $t("product.fields.label.amount") }}</label>
                            <input
                              v-model="product.amount"
                              :disabled="products.length > 0"
                              :placeholder="$t('product.fields.placeholder.amount')"
                              class="form-control input-lg"
                              type="number"
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{ $t("product.fields.label.warehouse") }}</label>
                            <multiselect
                              v-model="product.warehouse"
                              :label="'name_' + $i18n.locale"
                              :option-height="104"
                              :options="warehouseOptions"
                              :placeholder="$t('product.fields.placeholder.chooseWarehouse')"
                              :show-labels="false"
                              track-by="id"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{ $t("product.fields.label.expiredDate") }}</label>
                            <date-picker
                              v-model="product.expired_date"
                              :input-class="'form-control date-picker-lg'"
                              :placeholder="$t('product.fields.placeholder.expiredDate')"
                              format="YYYY-MM-DD"
                              lang="en"
                              style="width: 100%"
                              type="date"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{ $t("product.fields.label.stockAlertLevel") }}</label>
                            <input
                              v-model="product.stock_alert_level"
                              :placeholder="$t('product.fields.placeholder.stockAlertLevel')"
                              class="form-control input-lg"
                              type="number"
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- Product Variant-->
                    <div class="row">
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-3 col-sm-4 col-xs-6">
                        <label style="font-size: 18px">{{ $t("product.label.variants") }}</label>
                      </div>
                      <div class="col-md-9 col-sm-8 col-xs-6">
                        <template>
                          <div
                            v-if="variantUsed.length"
                            class="pull-right"
                          >
                            <button
                              class="btn btn-danger mr-0"
                              @click="clickBtnVariant"
                            >
                              {{ $t("button.cancel") }}
                            </button>
                          </div>
                          <div
                            v-if="variantTypes.length > variantUsed.length"
                            :class="{'mr-2': variantUsed.length}"
                            class="dropdown pull-right"
                          >
                            <button
                              id="dropdownMenu1"
                              aria-expanded="true"
                              aria-haspopup="true"
                              class="btn btn-add-other-option dropdown-toggle mr-0"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i class="fas fa-plus-circle" />
                              {{ $t("product.buttons.addAnotherOption") }}
                              <span class="caret" />
                            </button>
                            <template v-if="variantTypes && Array.isArray(variantTypes) && variantTypes.length">
                              <ul
                                aria-labelledby="dropdownMenu1"
                                class="dropdown-menu"
                              >
                                <template v-for="(item, key) in variantTypes">
                                  <li
                                    v-if="!item.used"
                                    :key="key"
                                  >
                                    <a @click="addMoreVariant(item.id)">{{ item["label_" + $i18n.locale] }}</a>
                                  </li>
                                </template>
                              </ul>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                    <template v-if="variantUsed.length > 0">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>
                              {{ $t("label.note") }} :
                            </label>
                            <span class="ml-3">{{ $t("label.addVariantTutorial") }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            v-for="(variantType, key) in variantUsed"
                            :key="key"
                            class="row margin-bottom"
                          >
                            <div
                              class="col-md-4 col-lg-2"
                              style="padding-top: 12px"
                            >
                              <label>{{ variantType["label_" + $i18n.locale] }}:</label>
                            </div>
                            <div class="col-md-7 col-lg-9">
                              <multiselect
                                v-model="variantType.values"
                                :multiple="true"
                                :option-height="104"
                                :options="variantType.options"
                                :placeholder="$t('fields.placeholder.searchOrAddAValue')"
                                :tag-placeholder="$t('fields.placeholder.addAValue')"
                                :taggable="true"
                                label="name"
                                track-by="value"
                                @tag="tagInput(variantType,$event, 'add')"
                              />
                            </div>
                            <div
                              class="col-md-1 col-lg-1 text-right"
                              @click="removeVariant(variantType)"
                            >
                              <button class="btn btn-danger">
                                <i class="fas fa-trash-alt" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div
                      v-if="products.length > 0"
                      class="row margin-top"
                    >
                      <div class="col-md-12 font-s-18">
                        <p><strong>{{ $t("product.label.modifyVariant") }}: {{ products.length }}</strong></p>
                      </div>
                      <div class="col-md-12">
                        <template v-for="(eachProduct, key) in products">
                          <div
                            :key="key"
                            :class="{'product-children-has-error': eachProduct.sale_price.errorMessage !== ''}"
                            class="product-children"
                          >
                            <div class="row">
                              <div class="col-md-12 d-flex align-items-center justify-content-between">
                                <div>
                                  <p class="slash margin-bottom-none">
                                    <span v-if="eachProduct.hasOwnProperty('value_level_1')">
                                      {{ eachProduct.label_1[$i18n.locale] }} : <strong>{{
                                        eachProduct.value_level_1
                                      }}</strong>
                                    </span>
                                    <span v-if="eachProduct.hasOwnProperty('value_level_2')">
                                      {{ eachProduct.label_2[$i18n.locale] }} : <strong>{{
                                        eachProduct.value_level_2
                                      }}</strong>
                                    </span>
                                    <span v-if="eachProduct.hasOwnProperty('value_level_3')">
                                      {{ eachProduct.label_3[$i18n.locale] }} : <strong>{{
                                        eachProduct.value_level_3
                                      }}</strong>
                                    </span>
                                    <span v-if="eachProduct.hasOwnProperty('value_level_4')">
                                      {{ eachProduct.label_4[$i18n.locale] }} : <strong>{{
                                        eachProduct.value_level_4
                                      }}</strong>
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <a
                                    :aria-controls="'collapseProductChild' + key"
                                    :href="'#collapseProductChild' + key"
                                    aria-expanded="true"
                                    class="btn btn-default btn-sm"
                                    data-toggle="collapse"
                                    role="button"
                                    @click="eachProduct.show_collapse = !eachProduct.show_collapse"
                                  >
                                    <i
                                      :class="eachProduct.show_collapse ? 'fa-chevron-up' : 'fa-chevron-down'"
                                      class="fas"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              :id="'collapseProductChild' + key"
                              class="collapse in"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.importedPrice") }}</label>
                                    <input
                                      v-model="eachProduct.imported_price"
                                      :placeholder="$t('product.fields.placeholder.importedPrice')"
                                      class="form-control input-lg"
                                      type="number"
                                    >
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div
                                    :class="{'has-error': eachProduct.sale_price.errorMessage !== ''}"
                                    class="form-group margin-bottom-none"
                                  >
                                    <label class="required">{{ $t("product.fields.label.salePrice") }}</label>
                                    <input
                                      v-model="eachProduct.sale_price.value"
                                      :placeholder="$t('product.fields.placeholder.salePrice')"
                                      class="form-control input-lg"
                                      type="number"
                                    >
                                    <template v-if="eachProduct.sale_price.errorMessage !== ''">
                                      <span class="help-block">{{ eachProduct.sale_price.errorMessage }}</span>
                                    </template>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.unit") }}</label>
                                    <multiselect
                                      v-model="eachProduct.product_unit"
                                      :label="'name_' + $i18n.locale"
                                      :option-height="104"
                                      :options="unitOptions"
                                      :placeholder="$t('product.fields.placeholder.unit')"
                                      :show-labels="false"
                                      track-by="id"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="product.has_inventory"
                                class="row margin-top"
                              >
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.amount") }}</label>
                                    <input
                                      v-model="eachProduct.amount"
                                      :placeholder="$t('product.fields.placeholder.amount')"
                                      class="form-control input-lg"
                                      type="number"
                                    >
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.warehouse") }}</label>
                                    <multiselect
                                      v-model="eachProduct.warehouse"
                                      :label="'name_' + $i18n.locale"
                                      :options="warehouseOptions"
                                      :placeholder="$t('product.fields.placeholder.warehouse')"
                                      :show-labels="false"
                                      track-by="id"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.expiredDate") }}</label>
                                    <date-picker
                                      v-model="eachProduct.expired_date"
                                      :placeholder="$t('product.fields.placeholder.expiredDate')"
                                      class="w-100 d-block"
                                      format="YYYY-MM-DD"
                                      input-class="form-control date-picker-lg"
                                      lang="en"
                                      type="date"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-4 col-lg-4 p-xs">
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <div class="form-group">
                          <label> {{ $t("fields.label.image") }}</label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <template v-if="$route.name !== 'show-product'">
                            <vue-croppie
                              ref="croppieRef"
                              :boundary="{ width: 250, height: 250 }"
                              :enable-orientation="true"
                              :show-zoomer="false"
                              :viewport="{ width: 245, height: 245 }"
                              @result="result"
                            />
                            <input
                              id="product-image"
                              accept="image/*"
                              style="display: none"
                              type="file"
                              @change="selectImg"
                            >
                            <div class="text-center margin-top">
                              <div
                                aria-label="..."
                                class="btn-group"
                                role="group"
                              >
                                <button
                                  class="btn btn-primary"
                                  @click="clickToBrowse"
                                >
                                  <i class="fas fa-paperclip" />
                                </button>
                                <button
                                  class="btn btn-default"
                                  @click="rotate(-90)"
                                >
                                  <i class="fas fa-undo" />
                                </button>
                                <button
                                  class="btn btn-default"
                                  @click="rotate(90)"
                                >
                                  <i class="fas fa-repeat" />
                                </button>
                                <button
                                  :class="{'disabled': !selected_image}"
                                  :disabled="!selected_image"
                                  class="btn btn-success"
                                  @click="crop"
                                >
                                  <i class="fas fa-crop-alt" />
                                </button>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <p v-if="productCategory.image !== null">
                              <img :src="config.base_url+'/'+productCategory.image">
                            </p>
                          </template>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <p class="text-bold">
                          {{ $t("note") }}:
                        </p>
                        <template v-if="$i18n.locale === 'km'">
                          <ul style="padding-left: 16px">
                            <li>ចុចប៊ូតុង "<i class="fas fa-paperclip" />" ដើម្បីជ្រើសរើសរូបភាព។</li>
                            <li>ចុចប៊ូតុង "<i class="fas fa-undo" />" ដើម្បីបង្វិលរូបភាពទៅខាងឆ្វេង។</li>
                            <li>ចុចប៊ូតុង "<i class="fas fa-repeat" />" ដើម្បីបង្វិលរូបភាពទៅខាងស្ដាំ។</li>
                            <li>ចុចប៊ូតុង "<i class="fas fa-crop-alt" />" ដើម្បីកាត់រូបភាពមុនពេលរក្សាទុក។</li>
                          </ul>
                        </template>
                        <template v-else>
                          <ul style="padding-left: 16px">
                            <li>Click on button "<i class="fas fa-paperclip" />" for choose image.</li>
                            <li>Click on button "<i class="fas fa-undo" />" for rotate image to the left.</li>
                            <li>Click on button "<i class="fas fa-repeat" />" for rotate image to the right.</li>
                            <li>Click on button "<i class="fas fa-crop-alt" />" for crop image before save.</li>
                          </ul>
                        </template>
                      </div>
                    </div>
                    <div class="row">
                      <template v-if="product.images && Array.isArray(product.images) && product.images.length">
                        <template v-for="(image, each_product_image_key) in product.images">
                          <div
                            :key="each_product_image_key"
                            v-if="product.images.length > 0"
                            class="col-md-4 clickable deletable"
                            @click="deleteImage(image)"
                          >
                            <img
                              alt="Product thumbnail"
                              :src="image"
                              class="img-thumbnail"
                            >
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <PlanMessage :message="message_allowed" />
              </template>
            </template>
          </div>
          <div
            v-if="allowed"
            class="box-footer text-right"
          >
            <button
              class="btn btn-primary"
              @click="autoCropImage"
            >
              <i class="fas fa-check-circle" />
              {{ $t("button.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Modal Part-->
    <div
      id="newCategoryModal"
      aria-labelledby="myModalLabel"
      class="modal fade"
      data-backdrop="static"
      data-keyboard="false"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header-custom">
            <h4
              id="myModalLabel"
              class="modal-title"
            >
              <span v-if="newData.title === 'category'">{{ $t("label.product.category.newCategory") }}</span>
              <span v-else-if="newData.title === 'tag'">{{ $t("label.product.tag.newTag") }}</span>
              <span v-else>{{ $t("label.product.unit.newUnit") }}</span>
            </h4>
            <button
              ref="close"
              :class="class_btn_modal"
              aria-label="Close"
              data-dismiss="modal"
              type="button"
            >
              <i
                aria-hidden="true"
                class="fas fa-times"
              />
            </button>
          </div>
          <div class="modal-body">
            <div
              :class="{'has-error': newData.errorsMessage !== null && newData.errorsMessage.hasOwnProperty('name_en')}"
              class="form-group"
            >
              <label class="required">{{ $t("label.nameEn") }}</label>
              <input
                v-model="newData.name_en"
                :placeholder="$t('placeholder.enterNameAsEn')"
                class="form-control input-lg"
                type="text"
              >
              <template
                v-if="newData.errorsMessage !== null && newData.errorsMessage.hasOwnProperty('name_en')"
              >
                <span class="help-block">{{ newData.errorsMessage["name_en"][0] }}</span>
              </template>
            </div>

            <div
              :class="{'has-error': newData.errorsMessage !== null && newData.errorsMessage.hasOwnProperty('name_km')}"
              class="form-group"
            >
              <label class="required">{{ $t("label.nameKm") }}</label>
              <input
                v-model="newData.name_km"
                :placeholder="$t('placeholder.enterNameAsKm')"
                class="form-control input-lg"
                type="text"
              >
              <template
                v-if="newData.errorsMessage !== null && newData.errorsMessage.hasOwnProperty('name_km')"
              >
                <span class="help-block">{{ newData.errorsMessage["name_km"][0] }}</span>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default"
              @click="cancelNewConfiguration"
            >
              <i class="fas fa-times-circle" />
              {{ $t("button.cancel") }}
            </button>
            <button
              class="btn btn-primary"
              @click="storeNewConfiguration"
            >
              <i class="fas fa-check-circle" />
              {{ $t("button.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanMessage from "@/components/PlanMessage"
import DatePicker from "vue2-datepicker"

export default {
  name: "ProductFormCreate",
  components: {
    DatePicker,
    PlanMessage
  },
  props: {
    columns: {
      type: String,
      default: "col-md-8 col-md-offset-2"
    },
    labelType: {
      type: String,
      default: "record"
    }
  },
  data () {
    return {
      collapse_all: false,
      product: {
        shop_id: null,
        name: "",
        sku: null,
        images: [],
        product_tags: [],
        product_category: "",
        product_type_id: "",
        imported_price: null,
        sale_price: null,
        amount: 0,
        product_unit: null,
        currency: {},
        has_inventory: false,
        stock_alert_level: 0,
        warehouse: 0,
        active: true,
        expired_date: null
      },
      products: [],
      config: this.$config,
      errorsMessage: {},
      cropped: null,
      categoryOptions: [],
      unitOptions: [],
      productTypeOptions: [],
      tagOptions: [],
      addVariant: false,
      variantTypes: [],
      variantUsed: [],
      currencyOptions: [],
      warehouseOptions: [],
      flag: "",
      flagValidateChild: true,
      newData: {
        name_en: "",
        name_km: "",
        title: "",
        errorsMessage: {}
      },

      onLoading: true,
      allowed: true,
      message_allowed: null,
      shop_uuid: null,
      selected_image: null
    }
  },
  watch: {
    variantUsed: {
      handler (val, oldVal) {
        this.productChild()
      },
      deep: true
    },
    "product.sale_price": {
      handler (val, oldVal) {
        if (this.products.length) {
          this.productChild()
        }
      },
      deep: true
    },
    "product.imported_price": {
      handler (val, oldVal) {
        if (this.products.length) {
          this.productChild()
        }
      },
      deep: true
    },
    "product.product_unit": {
      handler (val, oldVal) {
        if (this.products.length) {
          this.productChild()
        }
      },
      deep: true
    },
    "product.warehouse": {
      handler (val, oldVal) {
        if (this.products.length) {
          this.productChild()
        }
      },
      deep: true
    },
    "product.expired_date": {
      handler (val, oldVal) {
        if (this.products.length) {
          this.productChild()
        }
      },
      deep: true
    }
  },
  created () {
    window.addEventListener("keypress", this.onPressKey)
  },
  beforeMount () {
    if (this.get_shop) {
      this.shop_uuid = this.get_shop.uuid
    }
    if (this.$bill_active === "true") {
      this.checkCreateProductLimited()
    }
  },
  mounted () {
    const self = this
    this.product.product_type_id = 1
    this.flag = "Food"
    this.getProductCategory()
    this.getProductTag()
    this.getVariantType()
    this.getProductUnit()
    this.getCurrencyAndWarehouse()

    if (this.$refs.croppieRef) {
      self.$refs.croppieRef.bind({ url: "/img/pngwing.png" })
    }

    $(document).on("change", "#product-image", function () {
      if (this.files && this.files[0]) {
        const reader = new FileReader()
        reader.onload = function (e) {
          self.$refs.croppieRef.bind({
            url: e.target.result
          })
        }
        reader.readAsDataURL(this.files[0])
      }
    })
  },
  methods: {
    checkCreateProductLimited () {
      this.onLoading = true
      this.$axios.post(this.$config.base_url + "/api/bill/check-create-product-limited", {
        suuid: this.shop_uuid
      }).then((res) => {
        this.allowed = res.data.data.allowed
        this.message_allowed = res.data.data.message
      }).finally(() => {
        this.onLoading = false
      })
    },
    goToListRoute () {
      if (this.$route.name === "create-product") {
        this.$router.push({ name: "list-product" })
      } else {
        this.$router.push({ name: "list-drink" })
      }
    },
    tagInput (item, newTag, type) {
      if (item !== null) {
        this.variantUsed.forEach((ele) => {
          if (ele.id === item.id) {
            if (type === "add") {
              const tag = {
                name: newTag,
                value: newTag
              }
              ele.values.push(tag)
              ele.options.push(tag)
            } else {
              ele.options.splice(ele.values.indexOf(newTag), 1)
              ele.values.splice(ele.values.indexOf(newTag), 1)
            }
          }
        })
      }
    },
    clickToBrowse () {
      $("#product-image").click()
    },
    selectImg (event) {
      this.selected_image = event.target.files
    },
    autoCropImage () {
      this.$isLoading(true)
      if (this.selected_image && this.product.images.length === 0) {
        const options = { format: "png" }
        this.$refs.croppieRef.result(options)
      }
      setTimeout(() => {
        this.storeProduct()
      }, 1200)
    },
    crop () {
      if (this.selected_image) {
        const options = {
          format: "png"
        }
        this.$refs.croppieRef.result(options, (output) => {
          this.cropped = output
          this.product.images.push(output)
        })
      }
    },
    result (output) {
      this.product.images.push(output)
    },
    rotate (rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle)
    },
    deleteImage (image) {
      this.product.images.splice(this.product.images.indexOf(image), 1)
    },

    addMoreVariant (id) {
      const result = this.variantTypes.filter((eachVariant) => {
        return eachVariant.id === id
      })
      result[0].used = true
      result[0].options = []
      this.variantUsed.push(result[0])
    },
    clickBtnVariant () {
      this.addVariant = false
      this.variantTypes.forEach((variantType, index) => {
        this.$set(variantType, "used", false)
      })
      this.variantUsed = []
      this.products = []
    },
    removeVariant (item) {
      this.variantUsed.filter(function (ele) {
        if (ele === item) {
          // eslint-disable-next-line no-return-assign
          return ele.values = []
        }
      })
      const itemTotalVariant = this.variantTypes.find(eachVariant => {
        return eachVariant.id === item.id
      })
      itemTotalVariant.used = false
      this.variantUsed.splice(this.variantUsed.indexOf(item), 1)
      this.productChild()
    },
    validateVariant () {
      this.flagValidateChild = true
      if (this.products.length > 0) {
        this.products.forEach((eachChild) => {
          if (eachChild.sale_price.value === null) {
            this.flagValidateChild = false
            eachChild.sale_price.errorMessage = "The sale price field is required"
          } else if (eachChild.sale_price.value <= 0) {
            this.flagValidateChild = false
            eachChild.sale_price.errorMessage = "The sale price field should greater than 0"
          } else {
            eachChild.sale_price.errorMessage = ""
          }
        })
      }
    },
    productChild () {
      const result = []
      const product = Object.assign({}, this.product)
      const variantUsed = Object.assign({}, this.variantUsed)

      if (this.variantUsed.length > 0) {
        if (this.variantUsed.length === 1) {
          this.variantUsed[0].values.forEach((eachValue) => {
            result.push(Object.assign({}, {
              level_1: variantUsed[0].id,
              value_level_1: eachValue.value,
              label_1: {
                en: variantUsed[0].label_en,
                km: variantUsed[0].label_km
              },
              sale_price: {
                value: product.sale_price,
                errorMessage: ""
              },
              show_collapse: true,
              amount: 0,
              imported_price: product.imported_price,
              warehouse: product.warehouse,
              product_unit: product.product_unit,
              expired_date: product.expired_date
            }))
          })
        } else if (this.variantUsed.length === 2) {
          this.variantUsed[0].values.forEach((eachValueLevel1) => {
            this.variantUsed[1].values.forEach((eachValueLevel2) => {
              result.push(Object.assign({}, {
                level_1: variantUsed[0].id,
                level_2: variantUsed[1].id,
                value_level_1: eachValueLevel1.value,
                value_level_2: eachValueLevel2.value,
                label_1: {
                  en: variantUsed[0].label_en,
                  km: variantUsed[0].label_km
                },
                label_2: {
                  en: variantUsed[1].label_en,
                  km: variantUsed[1].label_km
                },
                sale_price: {
                  value: product.sale_price,
                  errorMessage: ""
                },
                show_collapse: true,
                amount: 0,
                imported_price: product.imported_price,
                warehouse: product.warehouse,
                product_unit: product.product_unit,
                expired_date: product.expired_date
              }))
            })
          })
        } else if (this.variantUsed.length === 3) {
          this.variantUsed[0].values.forEach((eachValueLevel1) => {
            this.variantUsed[1].values.forEach((eachValueLevel2) => {
              this.variantUsed[2].values.forEach((eachValueLevel3) => {
                result.push(Object.assign({}, {
                  level_1: variantUsed[0].id,
                  level_2: variantUsed[1].id,
                  level_3: variantUsed[2].id,
                  value_level_1: eachValueLevel1.value,
                  value_level_2: eachValueLevel2.value,
                  value_level_3: eachValueLevel3.value,
                  label_1: {
                    en: variantUsed[0].label_en,
                    km: variantUsed[0].label_km
                  },
                  label_2: {
                    en: variantUsed[1].label_en,
                    km: variantUsed[1].label_km
                  },
                  label_3: {
                    en: variantUsed[2].label_en,
                    km: variantUsed[2].label_km
                  },
                  sale_price: {
                    value: product.sale_price,
                    errorMessage: ""
                  },
                  show_collapse: true,
                  amount: 0,
                  imported_price: product.imported_price,
                  warehouse: product.warehouse,
                  product_unit: product.product_unit,
                  expired_date: product.expired_date
                }))
              })
            })
          })
        } else {
          this.variantUsed[0].values.forEach((eachValueLevel1) => {
            this.variantUsed[1].values.forEach((eachValueLevel2) => {
              this.variantUsed[2].values.forEach((eachValueLevel3) => {
                this.variantUsed[3].values.forEach((eachValueLevel4) => {
                  result.push(Object.assign({}, {
                    level_1: variantUsed[0].id,
                    level_2: variantUsed[1].id,
                    level_3: variantUsed[2].id,
                    level_4: variantUsed[3].id,
                    value_level_1: eachValueLevel1.value,
                    value_level_2: eachValueLevel2.value,
                    value_level_3: eachValueLevel3.value,
                    value_level_4: eachValueLevel4.value,
                    label_1: {
                      en: variantUsed[0].label_en,
                      km: variantUsed[0].label_km
                    },
                    label_2: {
                      en: variantUsed[1].label_en,
                      km: variantUsed[1].label_km
                    },
                    label_3: {
                      en: variantUsed[2].label_en,
                      km: variantUsed[2].label_km
                    },
                    label_4: {
                      en: variantUsed[3].label_en,
                      km: variantUsed[3].label_km
                    },
                    sale_price: {
                      value: product.sale_price,
                      errorMessage: ""
                    },
                    show_collapse: true,
                    amount: 0,
                    imported_price: product.imported_price,
                    warehouse: product.warehouse,
                    product_unit: product.product_unit,
                    expired_date: product.expired_date
                  }))
                })
              })
            })
          })
        }
      }
      this.products = result
    },

    clickModalNew (title) {
      this.newData.title = title
      this.newData.errorsMessage = {}
    },
    cancelNewConfiguration () {
      if (this.$refs.close) {
        this.$refs.close.click()
        this.newData = {
          name_en: "",
          name_km: "",
          title: "",
          errorsMessage: {}
        }
      }
    },
    storeNewConfiguration () {
      let url = this.$config.base_url
      const product_type = { id: "" }
      product_type.id = 1
      if (this.newData.title === "category") {
        url += "/api/product-category/store"
      } else if (this.newData.title === "tag") {
        url += "/api/product-tag/store"
      } else {
        url += "/api/product-unit/store"
      }
      this.$axios.post(url, {
        name_en: this.newData.name_en,
        name_km: this.newData.name_km,
        shop_id: this.get_shop.id,
        product_type: product_type
      })
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1) {
            this.newData.name = ""
            const data = {
              id: response.data.data.id,
              name_en: response.data.data.name_en,
              name_km: response.data.data.name_km
            }
            if (this.newData.title === "category") {
              this.categoryOptions.push(data)
              this.$toastr("success", this.$t("string.theCategoryWasCreatedSuccessfully"), this.$t("string.success"))
              this.product.product_category = data
            } else if (this.newData.title === "tag") {
              this.tagOptions.push(data)
              this.product.product_tags.push(data)
              this.$toastr("success", this.$t("string.theTagsWasCreatedSuccessfully"), this.$t("string.success"))
            } else {
              this.unitOptions.push(data)
              this.product.product_unit = data
              this.$toastr("success", this.$t("string.theUnitWasCreatedSuccessfully"), this.$t("string.success"))
            }
            this.cancelNewConfiguration()
          } else {
            if (typeof (response.data.message) === "object") {
              this.newData.errorsMessage = response.data.message
            } else {
              this.$toastr("error", response.data.message, this.newData.title)
              this.$router.push({ name: "list-product-category" })
            }
          }
        })
    },
    onPressKey (e) {
      if (e.code === "Enter") {
        this.storeNewConfiguration()
      }
    },
    getVariantType () {
      this.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/variant-type", {
          shop_id: this.get_shop.id
        })
        .then((response) => {
          if (response.data.code === 1) {
            response.data.data.forEach((variantType, index) => {
              this.$set(variantType, "used", false)
              this.variantTypes.push({
                id: variantType.id,
                label_en: variantType.name,
                label_km: variantType.name_km,
                used: false,
                values: [],
                options: []
              })
            })
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getProductCategory: function () {
      this.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/product/get-category-option", {
          flag: this.flag,
          shop_id: this.get_shop.id
        })
        .then((response) => {
          if (response.data.code === 1 && response.data.data !== null) {
            this.categoryOptions = response.data.data
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getProductTag: function () {
      this.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/product/get-tag-option", {
          flag: this.flag,
          shop_id: this.get_shop.id
        })
        .then((response) => {
          if (response.data.code === 1 && response.data.data !== null) {
            this.tagOptions = response.data.data
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getProductUnit: function () {
      this.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/product/get-unit-option", {
          flag: this.flag,
          shop_id: this.get_shop.id
        })
        .then((response) => {
          if (response.data.code === 1 && response.data.data !== null) {
            this.unitOptions = response.data.data
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getCurrencyAndWarehouse () {
      this.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/currency/get-restau-currency", {
          shop_id: this.get_shop.id
        })
        .then((response) => {
          if (response.data.code === 1 && response.data.data !== null) {
            this.currencyOptions = response.data.data.currencies
            this.warehouseOptions = response.data.data.warehouse
            this.product.warehouse = response.data.data.warehouse[0]
            if (response.data.data.restau_currency !== null) {
              this.product.currency = response.data.data.restau_currency
            } else {
              if (this.currencyOptions.length > 0) {
                this.product.currency = this.currencyOptions[0]
              }
            }
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    storeProduct () {
      this.validateVariant()
      this.product.shop_id = this.get_shop.id
      this.product.children = this.products
      if (this.products.length > 0) {
        this.product.amount = 0
      }
      this.product.flagValidateChild = this.flagValidateChild
      this.product.variants = this.variantUsed
      this.$axios
        .post(this.$config.base_url + "/api/product/store", this.product)
        .then((response) => {
          if (response.data.code === 1) {
            this.$router.push({ name: "list-product" })
            if (this.product.hasOwnProperty("id") && this.product.id !== null) {
              this.$anotify(this.labelType, "update")
            } else {
              this.$anotify(this.labelType)
            }
          } else {
            if (typeof (response.data.message) === "object") {
              this.errorsMessage = response.data.message
            } else {
              this.$toastr("error", response.data.message, this.$t("string.newProduct"))
            }
          }
        })
        .finally(() => {
          this.selected_image = null
          this.$isLoading(false)
        })
    }
  },
  beforeDestroy () {
    window.removeEventListener("keypress", this.onPressKey)
  }
}
</script>

<style scoped>
@import "../../../assets/css/V2/product-form.css";
</style>
