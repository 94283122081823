<template>
  <CustomerContainer />
</template>
<script>
import CustomerContainer from "@/views/customers/V2/_components/CustomerContainer"

export default {
  name: "CustomerListV2",
  components: { CustomerContainer },
  metaInfo () {
    return {
      title: this.$t("strings.listingCustomers"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>
