const state = {
  ticketTypes: []
}

const getters = {}

const mutations = {
  addTicket (state, ticketType) {
    if (state.ticketTypes.length > 0) {
      let foundTicketType = false
      state.ticketTypes.forEach((item) => {
        if (item.id === ticketType.id) {
          foundTicketType = true
        }
      })
      if (!foundTicketType) {
        state.ticketTypes.push(ticketType)
      }
    } else {
      state.ticketTypes.push(ticketType)
    }
  },

  removeTicketType (state, ticketType) {
    if (state.ticketTypes.length > 0) {
      let foundTicketType = null
      state.ticketTypes.forEach((item) => {
        if (item.id === ticketType.id) {
          foundTicketType = item
        }
      })
      if (foundTicketType !== null) {
        state.ticketTypes.splice(state.ticketTypes.indexOf(foundTicketType), 1)
      }
    }
  },

  updateTicketType (state, data) {
    const ticketType = data.ticketType
    const value = data.value
    if (state.ticketTypes.length > 0) {
      state.ticketTypes.map((item) => {
        if (item.id === ticketType.id) {
          // eslint-disable-next-line no-return-assign
          return item.total_people = value
        }
      })
    }
  },

  resetState (state) {
    state.ticketTypes = []
  },

  setState (state, ticketTypes) {
    state.ticketTypes = ticketTypes
  }
}

const actions = {
  addTicket ({
    commit,
    state
  }, ticketType) {
    commit("addTicket", ticketType)
  },
  removeTicketType ({
    commit,
    state
  }, ticketType) {
    commit("removeTicketType", ticketType)
  },
  updateTicketType ({
    commit,
    state
  }, data) {
    commit("updateTicketType", data)
  },
  resetState ({
    commit
  }) {
    commit("resetState")
  },
  setState ({
    commit,
    state
  }, ticketTypes) {
    commit("setState", ticketTypes)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
