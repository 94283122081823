import Tax from "@/views/settings/tax/Index"
import List from "@/views/settings/tax/List"
import Create from "@/views/settings/tax/Create"
import Show from "@/views/settings/tax/Show"
import Edit from "@/views/settings/tax/Edit"

export const taxRouters = {
  path: "tax",
  component: Tax,
  children: [
    {
      path: "",
      name: "list-tax",
      component: List
    },
    {
      path: "create",
      name: "create-tax",
      component: Create
    },
    {
      path: "edit-tax/:uuid",
      name: "edit-tax",
      component: Edit
    },
    {
      path: "show-tax/:uuid",
      name: "show-tax",
      component: Show
    }
  ]
}
