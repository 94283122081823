<template>
  <div class="row">
    <div class="col-md-12">
      <h3>{{ $t("string.coupon") }}</h3>
    </div>
    <div class="col-md-4">
      <div class="coupon-card margin-bottom">
        <div
          class="coupon-card-item shadow"
          @click="addCoupon"
        >
          <div class="coupon-card-item-body">
            <h3 class="text-primary ticket-name text-uppercase">
              ({{ totalCoupons }}) {{ $t("label.coupon") }}
            </h3>
            <p class="text-uppercase">
              {{ $t("label.value") }}
            </p>
            <h4><sup>$</sup><span class="big-text">000.00</span></h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <card>
          <template slot="header">
            <div>
              <button
                class="btn btn-default"
                @click="$router.push({name: 'list-coupon'})"
              >
                <i class="fas fa-list-ul" /> {{ $t("label.listCoupon") }}
              </button>
            </div>
            <div>
              <button
                class="btn btn-primary btn-block"
                @click="store"
              >
                <i class="fas fa-check-circle" /> {{ $t("button.checkout") }}
              </button>
            </div>
          </template>
          <div class="row">
            <div class="col-md-12">
              <template v-if="coupons.length > 0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <button
                          class="btn btn-default btn-xs"
                          @click="coupons = []"
                        >
                          <i class="fas fa-times" />
                        </button>
                      </th>
                      <th>{{ $t("label.amount") }}</th>
                      <th>{{ $t("label.quantity") }}</th>
                      <th>{{ $t("label.currency") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(coupon, each_coupon_key) in coupons"
                      :key="each_coupon_key"
                    >
                      <td>
                        <button
                          class="btn btn-default btn-xs"
                          @click="removeCoupon(coupon)"
                        >
                          <i class="fas fa-times" />
                        </button>
                      </td>
                      <td>
                        <input
                          :value="coupon.amount"
                          class="form-control"
                          type="number"
                          @change="updateCouponAmount(coupon, $event)"
                        >
                      </td>
                      <td>
                        <input
                          :value="coupon.quantity"
                          class="form-control"
                          type="number"
                          @change="updateCouponQuantity(coupon, $event)"
                        >
                      </td>
                      <td>
                        <select
                          v-model="coupon.currency"
                          class="form-control"
                        >
                          <option
                            v-for="(item, each_currency_key) in currencies"
                            :key="each_currency_key"
                            :value="item"
                          >
                            {{ item.code }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else>
                <no-any-records :height="65" />
              </template>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card"
import NoAnyRecords from "@/components/NoAnyRecords"

export default {
  name: "Order",
  metaInfo () {
    return {
      title: this.$t("string.coupon"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    NoAnyRecords,
    Card
  },
  data () {
    return {
      coupons: [],
      currencies: [],
      currency: null,
      id: 1
    }
  },
  computed: {
    totalCoupons () {
      let total = 0
      if (this.coupons.length > 0) {
        this.coupons.map((coupon) => {
          total = parseInt(total) + parseInt(coupon.quantity)
        })
      }
      return total
    }
  },
  methods: {
    addCoupon () {
      const coupon = {
        id: this.id,
        quantity: 1,
        amount: 5,
        currency: this.currency
      }
      this.coupons.unshift(coupon)
      this.id++
    },
    removeCoupon (coupon) {
      this.coupons.splice(this.coupons.indexOf(coupon), 1)
    },
    getCurrencies () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/currency/get-restau-currency", {
        shop_id: this.get_shop.id
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.currencies = response.data.data.currencies
        }
      })
    },
    store () {
      const params = {
        shop_id: this.get_shop.id,
        coupons: this.coupons
      }
      if (this.$route.params.hasOwnProperty("coupon_order_uuid")) {
        params.uuid = this.$route.params.coupon_order_uuid
      }
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/coupon/order", params)
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1) {
            this.currencies = response.data.data.currencies
            this.$router.push({
              name: "payment-coupon",
              params: {
                coupon_order_uuid: response.data.data.uuid
              }
            })
            this.$toastr(
              "info",
              this.$t("string.theRequestHaveBeenProcessed"),
              this.$t("string.success")
            )
          }
        })
    },
    updateCouponQuantity (coupon, $event) {
      if (this.coupons.length > 0) {
        this.coupons.map((item) => {
          if (item.id === coupon.id) {
            item.quantity = $event.target.value
          }
        })
      }
    },
    updateCouponAmount (coupon, $event) {
      if (this.coupons.length > 0) {
        this.coupons.map((item) => {
          if (item.id === coupon.id) {
            item.amount = $event.target.value
          }
        })
      }
    },
    updateCouponCurrency (coupon, $event) {
      if (this.coupons.length > 0) {
        this.coupons.map((item) => {
          if (item.id === coupon.id) {
            item.currency = $event.target.value
          }
        })
      }
    },
    getCoupons () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/coupon/order/get-coupon-cards", {
        shop_id: this.get_shop.id,
        coupon_order_uuid: this.$route.params.coupon_order_uuid
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.coupons = response.data.data
        }
      })
    }
  },
  mounted () {
    this.currency = JSON.parse(localStorage.getItem("currency"))
    this.getCurrencies()
    if (this.$route.params.hasOwnProperty("coupon_order_uuid")) {
      this.getCoupons()
    }
  }
}
</script>

<style scoped>
.coupon-card {
  width: 100%;
  display: flex;
}

.coupon-card-item {
  width: 100%;
  min-height: 200px;
  background: -webkit-gradient(linear, left top, left bottom, from(#21c8f6), to(#637bff));
  background: linear-gradient(180deg, #21c8f6, #637bff);
  border-radius: 0.5rem;
  cursor: pointer;
}

.coupon-card-item-body {
  padding: 2.5rem;
}

.coupon-card-item h3 {
  line-height: normal;
  font-weight: bold;
  color: darkred !important;
}

.big-text {
  font-size: 5rem;
  color: #FFFFFF;
}

.coupon-card-item-body h4 {
  font-weight: bold;
}
</style>
