<template>
  <footer
    class="restau-footer no-print"
    style=" margin-left: 0 !important;"
  >
    <div class="pull-right hidden-xs">
      {{ $t("label.contactUs") }} | (+855) {{ telSupporter }}
    </div>
    <p>
      {{ $t("string.copyright") }} &copy; 2021 <a href="https://figos.app">Figos.app</a>
      <span class="d-none">Powered by <a href="https://asorasoft.com">Asorasoft.com</a></span>
    </p>
  </footer>
</template>

<script>
export default {
  name: "DashboardFooter",
  data () {
    return {
      telSupporter: process.env.VUE_APP_TEL_SUPPOTER
    }
  }
}
</script>

<style scoped>
.restau-footer {
  padding: 8px 16px;
  color: #333;
  height: 36px;
  width: 100%;
  background-color: var(--purple-very-light);
}

.link {
  text-decoration: none;
  color: #333333;
}

@media screen and (max-width: 768px) {
  .restau-footer {
    text-align: center;
    padding: 4px 8px;
    height: auto;
    font-size: 13px;
  }

  .restau-footer p {
    margin: 0;
  }
}

@media screen and (max-width: 350px) {
  .restau-footer {
    font-size: 12px;
  }
}

</style>
