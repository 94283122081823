<template>
  <li
    class="tw-flex tw-group tw-full tw-items-center tw-rounded-md tw-cursor-pointer hover:tw-bg-primary tw-px-3 tw-py-2 tw-mr-2 tw-ml-2"
    @click="$emit('click')"
  >
    <div class="tw-mr-2 group-hover:tw-text-white">
      <i :class="icon" />
    </div>
    <div class="group-hover:tw-text-white">
      {{ label }}
    </div>
  </li>
</template>

<script>
export default {
  name: "DropdownItem",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    icon: String,
    // eslint-disable-next-line vue/require-default-prop
    label: String
  }
}
</script>

<style scoped>

</style>
