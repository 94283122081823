<template>
  <div class="container default-container">
    <div class="container title">
      <div class="col-md-12 no-padding">
        <a
          class="btn btn-default"
          style="margin-right: 5px"
          @click="$router.push({name: 'list-customer-group'})"
        >
          <i class="fas fa-arrow-circle-left" />
          {{ $t("button.back") }}
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="wrapper-header">
          <div class="row">
            <div class="col-md-12">
              <h3 class="title-label">
                {{ $t("label.editCustomerGroup") }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="wrapper-form">
          <CustomerGroupForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerGroupForm from "./Form"

export default {
  name: "CustomerGroupEdit",
  metaInfo () {
    return {
      title: this.$t("label.editCustomerGroup"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    CustomerGroupForm
  }
}
</script>

<style scoped>
.wrapper-form {
  background: var(--white);
  margin: 1% auto 0 auto;
  padding: 15px;
}

.wrapper-header {
  margin-bottom: 15px;
  margin-top: 15px;
}
</style>
