import productCreate from "@/views/inventories/products/Create"
import productIndex from "@/views/inventories/products/Index"
import productList from "@/views/inventories/products/List"
import productEdit from "@/views/inventories/products/Edit"
import productShow from "@/views/inventories/products/Show"
import PrintBarcode from "@/views/inventories/products/ProductBarcode"
import TrackInventory from "@/views/inventories/products/_components/ProductTrackInventory"
import Order from "@/views/inventories/products/Order"

export const productRouters = {
  path: "product",
  component: productIndex,
  name: "product",
  redirect: "/admin/inventories/product/",
  children: [
    {
      path: "",
      name: "list-product",
      component: productList
    },
    {
      path: "create",
      name: "create-product",
      component: productCreate
    },
    {
      path: "order",
      name: "order-product",
      component: Order
    },
    {
      path: ":uuid/edit",
      name: "edit-product",
      component: productEdit
    },
    {
      path: ":uuid/show",
      name: "show-product",
      component: productShow
    },
    {
      path: "print-barcode-all",
      name: "print-barcode-all",
      component: PrintBarcode
    },
    {
      path: "print-barcode/:uuid",
      name: "print-barcode",
      component: PrintBarcode
    },
    {
      path: ":uuid/track-inventory",
      name: "product-track-inventory",
      component: TrackInventory
    }
  ]
}
