import { mapGetters, mapState } from "vuex"
import { shopMixin } from "@/mixins/shop"
import { facebookSdkMixin } from "@/mixins/facebookLogin"
import indexedb from "@/indexedb"

export const helpers = {
  mixins: [shopMixin, facebookSdkMixin],
  data () {
    return {
      t_d_s: process.env.VUE_APP_KEYWORD_TOKEN_DEVICE,
      a_t_d_s: process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE,
      defaultImg: "/img/pngwing.png",
      defaultBannerImage: "/img/slider_default.png",
      categoryAllImg: "/img/category_all.png",
      class_btn_modal: "btn btn-default btn-sm",
      defaultAvatar: "/img/avatar.jpg",
      defaultShopLogo: ""
    }
  },
  computed: {
    ...mapState({
      current_shop: state => state.shop.shop,
      shop_exchange_rate: state => state.shop.exchange,
      currency: state => state.shop.currency,
      get_shop: state => state.shop.shop,
      orderProducts: state => state.order.orderProducts
    }),
    ...mapGetters({
      currency_code: "shop/currencyCode",
      currency_symbol: "shop/currencySymbol"
    }),
    show_date () {
      return this.$moment().locale(this.$i18n.locale).format(this.dateFormatLocaleNoTime)
    },
    dateFormatLocaleNoTime () {
      return this.$i18n.locale === "km" ? "ថ្ងៃdddd ទីDD ខែMMMM ឆ្នាំYYYY" : "dddd, DD MMMM, YYYY"
    },
    dateFormatLocale () {
      return this.$i18n.locale === "km" ? "ថ្ងៃdddd, Do MMMM  YYYY, h:mm A" : "ddd, MMMM Do YYYY, h:mm A"
    }
  },
  methods: {
    checkMembershipExpired (membership) {
      if (membership) {
        if (membership.type) {
          if (membership.type !== "forever") {
            if (membership.pivot && membership.pivot.expire_date) {
              const expireDate = this.$moment(membership.pivot.expire_date, "YYYY-MM-DD HH:mm:ss")
              const now = this.$moment(this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss")
              return now.isBefore(expireDate)
            } else {
              return false
            }
          } else {
            return true
          }
        }
      }
      return false
    },
    checkExpiredDemo (routePath, isCheck = true) {
      if (isCheck) {
        if (this.is_expired) {
          this.$router.push({ name: "403" })
        } else {
          this.$router.push({ path: routePath })
        }
      } else {
        if (routePath === "/admin/settings" && this.is_expired) {
          this.$router.push({ name: "list-subscription" })
        } else {
          this.$router.push({ path: routePath })
        }
      }
    },
    alertStockProduct () {
      this.$axios.post(this.$config.base_url + "/api/product/alert-stock-product", {
        shop_uuid: this.get_shop.uuid
      }).then((res) => {
        const hasAlertStock = res.data.data && res.data.data.length > 0
        this.$store.dispatch("shop/setHasAlertStock", hasAlertStock)
      })
    },
    checkUrlAvatar (avatar) {
      if (avatar) {
        if (avatar.includes("http")) {
          return avatar
        } else {
          return this.$config.base_url + "/" + avatar
        }
      }
      return null
    },
    updateDeviceForSell () {
      if (this.is_expired) {
        this.$router.push({ name: "403" })
      } else {
        this.$swal({
          html: `<p class="text-bold font-s-22">${this.$t("string.switchDeviceTitle")}</p>
                <p class="text-bold font-s-20">${this.$t("string.switchDeviceDec")}</p>`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: this.$t("swal.confirmation.button.yes"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          let token = null
          if (localStorage.hasOwnProperty(this.t_d_s) && localStorage.getItem(this.t_d_s) !== "undefined") {
            token = localStorage.getItem(this.t_d_s)
          } else {
            token = this.generate_token(32)
            localStorage.setItem(this.t_d_s, token)
          }
          this.$axios.post(this.$config.base_url + "/api/check-user/update-device-for-sell", {
            token_device_for_sell: token
          }).then((res) => {
            const user = res.data.data
            localStorage.setItem(process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE, user.confirmation_code)
            this.checkTokenDeviceForSell()
          }).catch((error) => {
            this.getResponseError(error)
          })
        }, (dismiss) => {
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          throw err
        })
      }
    },
    checkTokenDeviceForSell () {
      if (localStorage.hasOwnProperty(this.a_t_d_s)) {
        const active_token = localStorage.getItem(this.a_t_d_s)
        if (localStorage.hasOwnProperty(this.t_d_s)) {
          const a = active_token === localStorage.getItem(this.t_d_s)
          this.$store.dispatch("shop/setDeviceForSell", a)
        }
      }
    },
    generate_token (length) {
      const a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("")
      const b = []
      for (let i = 0; i < length; i++) {
        const j = (Math.random() * (a.length - 1)).toFixed(0)
        b[i] = a[j]
      }
      return b.join("")
    },
    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    replaceImgUrl (string, type = "original") {
      if (string) {
        return string.replace("storage", "/media/" + type)
      }
      return ""
    },
    onLogout () {
      this.$axios
        .post(this.$config.base_url + "/api/auth/user/logout")
        .finally(() => {
          const locale = localStorage.getItem("locale")
          const cover = localStorage.getItem("cover")
          const logo = localStorage.getItem("logo")
          const view_mode = localStorage.getItem("view_mode")
          const token_device = localStorage.getItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE)
          const token_active_device = localStorage.getItem(process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE)

          localStorage.clear()
          localStorage.setItem("locale", locale)
          localStorage.setItem("cover", cover)
          localStorage.setItem("logo", logo)
          localStorage.setItem("view_mode", view_mode)
          localStorage.setItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE, token_device)
          localStorage.setItem(process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE, token_active_device)
          this.$router.push({ name: "Login" })
        })
    },
    getResponseError (error, show_message_validate = false) {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 401 || error.response.status === 403) {
          if (error.response.status === 401) {
            this.onLogout()
          } else if (error.response.status === 403) {
            this.$toastr("error", this.$t("string.youAreNotAllowToAccessThisPage"), this.$t("string.error"))
          }
        } else if (error.response.status === 422 && show_message_validate) {
          this.$toastr("error", error.response.data.message, this.$t("string.error"))
        } else {
          if (error.response.data) {
            if (error.response.data.message) {
              const message = error.response.data.message
              this.$toastr("error", message["message_" + this.$i18n.locale], this.$t("string.error"))
              if (message.hasOwnProperty("a_t")) {
                const { a_t } = message
                localStorage.setItem(this.a_t_d_s, a_t)
                this.checkTokenDeviceForSell()
                this.$router.replace({ name: "dashboard" })
              }
              if (this.$bill_active === "true" && message.hasOwnProperty("plan_expired")) {
                if (message.plan_expired) {
                  this.$store.dispatch("shop/setExpired", message.plan_expired)
                  this.$router.push({ name: "dashboard" })
                }
              }
            }
          }
        }
      }
    },
    isFloat (n) {
      if (Number(n) === n && n % 1 !== 0) {
        return "0,0.00"
      }
      return "0,0"
    },
    precise (num) {
      const floatingPointCount = 10
      const digit = ("" + num).split(".")[0].length
      return parseFloat(Number.parseFloat(num).toPrecision(digit + floatingPointCount))
    },
    countDecimals (value) {
      return value % 1 ? value.toString().split(".")[1].length : 0
    },
    moneyEquivalent (currency, value) {
      const result = this.exchangeMoney(currency, currency === "USD" ? "KHR" : "USD", value)
      return parseFloat(result)
    },
    exchangeMoney (from, to, value) {
      if (!this.shop_exchange_rate || !this.shop_exchange_rate.value) return 0
      if (value === "" || value === 0 || value === null) value = 0
      if (from === to) {
        return parseFloat(value)
      }
      switch (from) {
      case "USD":
        switch (to) {
        case "KHR":
          // eslint-disable-next-line no-case-declarations
          const v = value * parseFloat(this.shop_exchange_rate.value)
          return Math.round(v / 100) * 100
        }
        // eslint-disable-next-line no-fallthrough
      case "KHR":
        switch (to) {
        case "USD":
          // eslint-disable-next-line no-case-declarations
          const result = value / parseFloat(this.shop_exchange_rate.value)
          return parseFloat(result)
        }
      }
    },
    calcPromotion (product, promotions = []) {
      this.$set(product, "has_promotion", false)
      this.$set(product, "origin_price", product.sale_price)

      if (promotions && promotions.length > 0) {
        let amount_percentage = 0
        let amount_value = 0
        promotions.forEach((item) => {
          if (item.active) {
            this.$set(product, "has_promotion", true)
            if (item.is_percentage) {
              amount_percentage += parseFloat(item.amount_percentage)
            } else {
              amount_value += parseFloat(item.amount_percentage)
            }
          }
        })
        if (amount_percentage > 0 || amount_value > 0) {
          const sale_price = this.exchangeMoney(product.currency.code, this.currency ? this.currency.code : "USD", parseFloat(product.sale_price))
          const value_of_percentage = (sale_price * amount_percentage) / 100
          const price_after_promotion = sale_price - (value_of_percentage + amount_value)
          const promotion_price = parseFloat(this.exchangeMoney(this.currency.code, product.currency.code, parseFloat(price_after_promotion))).toFixed(2)
          this.$set(product, "sale_price", promotion_price)
          if (amount_percentage > 0) {
            this.$set(product, "promotion_is_percentage", true)
            this.$set(product, "promotion_value", amount_percentage)
          } else if (amount_value > 0) {
            this.$set(product, "promotion_is_percentage", false)
            this.$set(product, "promotion_value", amount_value)
          } else {
            this.$set(product, "promotion_is_percentage", false)
            this.$set(product, "promotion_value", 0)
          }
        }
      }
      return product
    },
    addVariantType (product) {
      if (product) {
        this.$set(product, "variants", {})
        const children_sale_price = []
        const children_origin_price = []
        product.children.forEach((child) => {
          children_sale_price.push(child.sale_price)
          children_origin_price.push(child.origin_price)
          child.product_variants.forEach((vr) => {
            if (product.variants.hasOwnProperty(vr.name)) {
              const found = product.variants[vr.name].values.find((item) => {
                return item.pivot.value === vr.pivot.value
              })
              if (!found) {
                product.variants[vr.name].values.push(vr)
              }
            } else {
              this.$set(product.variants, vr.name, {})
              this.$set(product.variants[vr.name], "name", {})
              this.$set(product.variants[vr.name], "values", [])
              product.variants[vr.name].name = {
                name_en: vr.name,
                name_km: vr.name_km
              }
              product.variants[vr.name].values.push(vr)
            }
          })
        })
        this.$set(product, "children_sale_price", children_sale_price)
        if (product.has_promotion) {
          this.$set(product, "children_origin_price", children_origin_price)
        }
      }
      return product
    },
    changeViewModeProductOrder (mode) {
      localStorage.setItem("view_mode", mode)
      this.$store.dispatch("order/updateViewMode", mode)
    },
    changeSellMode (mode) {
      localStorage.setItem("sell_mode", mode)
      this.$store.dispatch("sell/setSellMode", mode)
    },
    logout () {
      if (this.isOffline) {
        this.$swal({
          html: `<p class="text-bold font-s-20">${this.$t("string.doYouWantLogout")}</p>
                 <ul class="text-left">
                   <li>${this.$t("string.warningBeforeLogout")}</li>
                   <li>${this.$t("label.click")}: <strong>"${this.$t("swal.confirmation.button.yes")}"</strong> ${this.$t("string.forContinueLogout")}</li>
                   <li>${this.$t("label.click")}: <strong>"${this.$t("swal.confirm_delete.button.no")}"</strong> ${this.$t("string.forCancelLogout")}</li>
                  </ul>`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: this.$t("swal.confirmation.button.yes"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          this.revokeToken()
        }, (dismiss) => {
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          throw err
        })
      } else {
        this.$swal({
          title: this.$t("swal.confirm_logout.title"),
          text: this.$t("swal.confirm_logout.dec"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: this.$t("swal.confirmation.button.yes"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          let orders = []
          let invoices = []
          let order_products = []
          indexedb.all("orders")
            .then((data) => {
              if (data !== undefined && data.length > 0) {
                orders = data
              }
            })
            .then(() => {
              indexedb.all("order_products")
                .then((data) => {
                  if (data !== undefined && data.length > 0) {
                    order_products = data
                  }
                })
                .then(() => {
                  indexedb.all("invoices")
                    .then((data) => {
                      if (data !== undefined && data.length > 0) {
                        invoices = data
                      }
                    })
                    .then(() => {
                      if (orders.length > 0 && order_products.length > 0) {
                        this.$swal({
                          html: `<p class="text-bold font-s-20">${this.$t("string.youNeedToSyncDataOffline")}</p>
                              <ul class="text-left">
                              <li>${this.$t("label.click")}: <strong>"${this.$t("string.process")}"</strong> ${this.$t("string.forSyncData")}</li>
                              <li>${this.$t("label.click")}: <strong>"${this.$t("button.cancel")}"</strong> ${this.$t("string.forCancelData")}</li>
                              <li>${this.$t("string.removeOfflineRecordsAfterLogout")}</li>
                              <li>${this.$t("string.checkYourInternet")}</li>
                              </ul>`,
                          type: "warning",
                          showCancelButton: true,
                          cancelButtonColor: "#d33",
                          confirmButtonText: this.$t("string.process"),
                          cancelButtonText: this.$t("button.cancel")
                        }).then(() => {
                          this.$isLoading(true)
                          this.$axios.post(`${this.$config.base_url}/api/offline/sync-data`, {
                            orders: orders,
                            order_products: order_products,
                            invoices: invoices,
                            shop_uuid: this.get_shop.uuid
                          }).then((response) => {
                            this.onResponseSuccess()
                            orders.forEach((order) => {
                              try {
                                indexedb.remove("orders", order.uuid)
                                indexedb.remove("order_products", order.uuid)
                                indexedb.remove("invoices", order.uuid)
                                // eslint-disable-next-line no-empty
                              } catch (e) {
                              }
                            })
                            this.revokeToken()
                          }).catch((error) => {
                            this.onResponseError(error)
                          }).finally(() => {
                            this.$isLoading(false)
                          })
                        }, (dismiss) => {
                          if (!(dismiss === "cancel")) {
                            throw dismiss
                          } else {
                            orders.forEach((order) => {
                              try {
                                indexedb.remove("orders", order.uuid)
                                indexedb.remove("order_products", order.uuid)
                                indexedb.remove("invoices", order.uuid)
                                // eslint-disable-next-line no-empty
                              } catch (e) {
                              }
                            })
                            this.revokeToken()
                          }
                        }).catch(function (err) {
                          throw err
                        })
                      } else {
                        this.revokeToken()
                      }
                    })
                })
            })
        }, (dismiss) => {
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          throw err
        })
      }
    },
    revokeToken () {
      this.$axios
        .post(this.$config.base_url + "/api/auth/user/logout")
        .finally(() => {
          this.logoutWithFB()
          const locale = localStorage.getItem("locale")
          const logo = localStorage.getItem("logo")
          const view_mode = localStorage.getItem("view_mode")
          const token_device = localStorage.getItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE)
          const token_active_device = localStorage.getItem(process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE)
          localStorage.clear()
          localStorage.setItem("locale", locale)
          localStorage.setItem("logo", logo)
          localStorage.setItem("view_mode", view_mode)
          localStorage.setItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE, token_device)
          localStorage.setItem(process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE, token_active_device)
          this.$router.push({ name: "Login" })
          indexedb.removeDatabase()
          this.$isLoading(false)
        })
    },
    closeSidebarOrderProductSm () {
      $(".side-bar").removeClass("active")
      $(".overlay").removeClass("active")
    },
    closeCustomerDetailSm () {
      $(".cus-detail").removeClass("customer-show-sidebar")
    },
    showDeleted () {
      this.$swal({
        title: this.$t("string.deleted"),
        text: this.$t("string.yourItemHasBeenDeleted"),
        type: "success",
        confirmButtonText: this.$t("button.ok")
      })
    },
    emit (event, data) {
      if (!this.isOffline) {
        this.$socket.emit(event, { shop_id: this.current_shop.id, ...data })
      }
    },
    onConfirm (options) {
      const self = this
      return new Promise((resolve, reject) => {
        self.$swal(Object.assign({
          title: self.$t("string.are_you_sure"),
          text: self.$t("string.you_wont_be_able_to_revert_this"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3a7afe",
          cancelButtonColor: "#ed524f",
          cancelButtonText: self.$t("string.no"),
          confirmButtonText: self.$t("string.yes")
        }, options)).then((result) => {
          if (result) {
            resolve({
              accept: true
            })
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              accept: false
            })
          }
        })
      })
    }
  },
  mounted () {
    $(".dismiss, .overlay, .closesidebar").on("click", function () {
      $(".side-bar").removeClass("active")
      $(".overlay").removeClass("active")
    })

    $(".open-menu").on("click", function (e) {
      e.preventDefault()
      $(".side-bar").addClass("active")
      $(".overlay").addClass("active")
      // close opened sub-menus
      $(".collapse.show").toggleClass("show")
      $("a[aria-expanded=true]").attr("aria-expanded", "false")

      // close all sidebar right
      $(".show-order-pro-sm").removeClass("active")
      $(".cus-detail").removeClass("customer-show-sidebar")
      $(".block-product-list").removeClass("hide-pro-list-sm")
    })

    // ==========================Start: Function for order product list ==========================================
    $(".dismiss-order-pro-sm").on("click", function () {
      $(".show-order-pro-sm").removeClass("active")
      $(".block-product-list").removeClass("hide-pro-list-sm")
    })

    $(".btn-sopl-xs").on("click", function (e) {
      e.preventDefault()
      $(".show-order-pro-sm").addClass("active")
      $(".block-product-list").addClass("hide-pro-list-sm")
    })
    // ==========================End: Function for order product list ==========================================
    // ==========================Start: Function for customer list ==========================================
    $(".show-customer-sidebar-detail").on("click", function (e) {
      e.preventDefault()
      $(".cus-detail").addClass("customer-show-sidebar")
    })
    // ==========================End: Function for customer list ==========================================
  }
}
export default {
  install (Vue, options) {
    Vue.t = function (args) {
      console.log(args)
    }
    Vue.mixin(helpers)

    Vue.prototype.$base_api = process.env.VUE_APP_API
  }
}
