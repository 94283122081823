<template>
  <div class="content-wrapper">
    <section class="content container-fluid">
      <div class="row">
        <!-- Nav tabs -->
        <div class="col-md-12">
          <ul
            class="nav nav-tabs"
            role="tablist"
          >
            <li
              v-for="(item, key) in menus"
              :class="item.id === menu_id ? 'active' : ''"
              :key="key"
              role="presentation"
            >
              <a
                :aria-controls="item.name"
                data-toggle="tab"
                href="#tab-container"
                role="tab"
                @click="showMenuHasFood(item.id)"
              >
                <template v-if="item.id === 0">
                  {{ $t(item.name) }}
                </template>
                <template v-else>
                  {{ item.name }}
                </template>
              </a>
            </li>
            <li role="presentation">
              <button
                v-if="userRoles.filter(value => (value.name === $config.roles.administrator) || (value.name === $config.roles.owner)).length"
                class="icon-plus"
                data-target="#createMenu"
                data-toggle="modal"
                type="button"
              >
                <i class="fas fa-plus" />
              </button>
              <!-- Modal -->
              <div
                id="createMenu"
                ref="modal"
                aria-labelledby="myModalLabel"
                class="modal fade"
                role="dialog"
                tabindex="-1"
              >
                <div
                  class="modal-dialog modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header-custom">
                      <h4
                        id="myModalLabel"
                        class="modal-title"
                      >
                        {{ $t("label.newMenu") }}
                      </h4>
                      <button
                        :class="class_btn_modal"
                        aria-label="Close"
                        data-dismiss="modal"
                        type="button"
                      >
                        <i
                          aria-hidden="true"
                          class="fas fa-times"
                        />
                      </button>
                    </div>
                    <div class="modal-body">
                      <menu-form
                        :is-used-by-modal="true"
                        modal-id="createMenu"
                        @refreshMenu="getMenu"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div class="tab-content">
            <div
              id="tab-container"
              class="tab-pane active"
              role="tabpanel"
            >
              <div class="container-item">
                <div
                  v-if="menus.length > 0"
                  class="row"
                >
                  <div class="col-xs-6">
                    <div
                      v-if="menu_id !== 0"
                      class="form-group"
                    >
                      <button
                        class="btn btn-primary margin-left"
                        data-target="#addFood"
                        data-toggle="modal"
                        type="button"
                      >
                        <i class="fas fa-plus-circle" />
                        {{ $t("button.addFood") }}
                      </button>
                      <div
                        id="addFood"
                        ref="modal"
                        aria-labelledby="myModalLabel"
                        class="modal fade bs-example-modal-lg"
                        role="dialog"
                        tabindex="-1"
                      >
                        <div
                          class="modal-dialog modal-lg"
                          role="document"
                        >
                          <div class="modal-content">
                            <div class="modal-header">
                              <button
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                              >
                                <span
                                  aria-hidden="true"
                                >&times;</span>
                              </button>
                              <h4 class="modal-title">
                                {{ $t("label.addNewFood") }}
                              </h4>
                            </div>
                            <div class="modal-body">
                              <div
                                class="row"
                                style="margin-bottom: 10px"
                              >
                                <div class="col-md-3">
                                  <restau-checkbox
                                    id="select_all"
                                    v-model="selectAll"
                                  >
                                    {{ $t("label.selectAll") }}
                                  </restau-checkbox>
                                </div>
                                <div class="col-md-9">
                                  <input
                                    v-model="search_query"
                                    class="form-control"
                                    placeholder="Filter Food by Name or Number"
                                    type="text"
                                    @keyup="getFoods"
                                  >
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 overflowTask">
                                  <table class="table table-hover table-padding">
                                    <thead>
                                      <tr class="active tr-padding">
                                        <th>Select</th>
                                        <th>{{ $t("table.menu.no") }}</th>
                                        <th>{{ $t("table.menu.name") }}</th>
                                        <th>{{ $t("table.menu.category") }}</th>
                                        <th>{{ $t("table.menu.price") }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <template v-if="foods.length > 0">
                                        <tr
                                          v-for="(item, key) in foods"
                                          :key="key"
                                        >
                                          <td class="td-padding-top">
                                            <input
                                              :id="item.id"
                                              v-model="food_id"
                                              :value="item.id"
                                              class="checkbox-btn"
                                              type="checkbox"
                                            >
                                            <label
                                              :for="item.id"
                                              class="checkbox-label"
                                            >
                                              <div class="checkbox-div"><i
                                                class="fas fa-check"
                                              />
                                              </div>
                                            </label>
                                          </td>
                                          <td class="td-padding-top">
                                            {{ key + 1 }}
                                          </td>
                                          <td>
                                            <template
                                              v-if="item.hasOwnProperty('images') && item.images.length > 0"
                                            >
                                              <img
                                                :src="`${$config.base_url}/${item.images[0].src}`"
                                                alt="food images"
                                                class="food-img"
                                              >
                                            </template>
                                            <template v-else>
                                              <img
                                                :src="`${defaultImg}`"
                                                class="food-img"
                                              >
                                            </template>
                                            <label>{{ item.name }}</label>
                                          </td>
                                          <td>
                                            <template
                                              v-if="item.hasOwnProperty('category') && item.category !== null"
                                            >
                                              {{ item.category.name }}
                                            </template>
                                            <template v-else>
                                              {{ $t("string.na") }}
                                            </template>
                                          </td>
                                          <td class="td-padding-top">
                                            {{
                                              item.sale_price ? item.sale_price
                                              : $t("string.na")
                                            }}{{
                                              item.sale_price &&
                                                item.currency ? item.currency.symbol
                                              : ""
                                            }}
                                          </td>
                                        </tr>
                                      </template>
                                      <template v-else>
                                        <tr class="tr-padding">
                                          <td
                                            colspan="5"
                                            style="text-align: center"
                                          >
                                            {{ $t("strings.notFound") }}
                                          </td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer no-border">
                              <h3
                                class="pull-left"
                                style="font-size: 20px; font-weight: 600"
                              >
                                Selected ({{ food_id.length }})
                              </h3>
                              <button
                                class="btn btn-default"
                                data-dismiss="modal"
                                type="button"
                              >
                                {{ $t("button.cancel") }}
                              </button>
                              <button
                                class="btn btn-primary"
                                type="button"
                                @click="addNewFood"
                              >
                                {{ $t("button.save") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-offset-3 col-md-3">
                    <div class="form-group">
                      <input
                        v-model="query"
                        :placeholder="$t('fields.placeholder.search')"
                        class="form-control"
                        type="text"
                        @keyup="showMenuHasFood(menu_id)"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 overflowTask">
                    <table class="table table-bordered table-condensed table-striped table-hover table-padding">
                      <tbody>
                        <tr class="active">
                          <th>{{ $t("table.menu.no") }}</th>
                          <th>{{ $t("table.menu.name") }}</th>
                          <th>{{ $t("table.menu.category") }}</th>
                          <th>{{ $t("table.menu.price") }}</th>
                        </tr>
                        <template v-if="menu_has_foods.length > 0">
                          <tr
                            v-for="(item, key) in menu_has_foods"
                            :key="key"
                          >
                            <td class="td-padding-top">
                              {{ key + 1 }}
                            </td>
                            <td>
                              <template
                                v-if="item.hasOwnProperty('images') && item.images.length > 0"
                              >
                                <img
                                  :src="`${$config.base_url}/${item.images[0].src}`"
                                  alt="food images"
                                  class="food-img"
                                >
                              </template>
                              <template v-else>
                                <img
                                  :src="`${defaultImg}`"
                                  class="food-img"
                                >
                              </template>
                              {{ item.name }}
                            </td>
                            <td class="td-padding-top">
                              <template
                                v-if="item.hasOwnProperty('category') && item.category !== null"
                              >
                                {{ item.category.name }}
                              </template>
                              <template v-else>
                                {{ $t("string.na") }}
                              </template>
                            </td>
                            <td class="td-padding-top">
                              {{ item.sale_price ? item.sale_price : $t("string.na") }}
                              {{ item.sale_price && item.currency ? item.currency.symbol : "" }}
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr class="tr-padding">
                            <td
                              colspan="5"
                              style="text-align: center"
                            >
                              {{ $t("strings.notFound") }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MenuForm from "./Form"
import RestauCheckbox from "@/components/RestaurantCheckbox"

export default {
  name: "MenuList",
  metaInfo () {
    return {
      title: this.$t("strings.listingMenus"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    RestauCheckbox,
    MenuForm
  },
  data () {
    return {
      userRoles: [],
      food_id: [],
      menus: [],
      foods: [],
      menu_id: "",
      menu_has_foods: [],
      menu: {
        id: "",
        name: "",
        icon: "",
        start: "",
        end: "",
        active: true,
        photo: ""
      },
      search_query: null,
      query: null,
      cropped: null
    }
  },
  computed: {
    selectAll: {
      get: function () {
        return this.foods ? this.food_id.length === this.foods.length : false
      },
      set: function (value) {
        const selected = []

        if (value) {
          this.foods.forEach(function (food) {
            selected.push(food.id)
          })
        }
        this.food_id = selected
      }
    }
  },
  methods: {
    getMenu () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/menu", {
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1) {
          const data = this.menus = response.data.data
          data.unshift({
            name: "menu.string.defaultMenuName",
            id: 0
          })
          this.menus = data

          if (this.menus.length > 0) {
            this.showMenuHasFood(this.menus[0].id)
          }
        }
        this.$isLoading(false)
      })
    },
    addNewFood () {
      this.search_query = null
      this.$axios.post(this.$config.base_url + "/api/menu/add_new_food", {
        shop_id: this.get_shop.id,
        id: this.menu_id,
        product_id: this.food_id
      }).then((response) => {
        if (response.data.code === 1) {
          $("#addFood").modal("toggle")
          this.getMenu()
          this.$toastr("success", this.$t("string.theMenuWasCreatedSuccessfully"), this.$t("string.success"))
        }
      })
    },
    getFoods () {
      this.$axios.post(this.$config.base_url + "/api/product", {
        search_query: this.search_query,
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1) {
          this.foods = response.data.data
        }
      })
    },
    showMenuHasFood (menu_id) {
      this.menu_id = menu_id
      if (this.menu_id === 0) {
        this.$axios.post(this.$config.base_url + "/api/product", {
          search_query: this.query,
          shop_id: this.get_shop.id
        }).then((response) => {
          if (response.data.code === 1) {
            this.menu_has_foods = response.data.data
          }
        })
      } else {
        this.$axios.post(this.$config.base_url + "/api/menu/show", {
          search_query: this.query,
          id: this.menu_id
        }).then((response) => {
          if (response.data.code === 1) {
            this.menu_has_foods = response.data.data.products
            this.food_id = this.menu_has_foods.map((obj) => obj.id)
          }
        })
      }
    }
  },
  mounted () {
    this.userRoles = JSON.parse(localStorage.user).roles
    this.getMenu()
    this.getFoods()
    const self = this
    $(document).on("change", "#menu-image", function () {
      if (this.files && this.files[0]) {
        const reader = new FileReader()
        reader.onload = function (e) {
          self.$refs.croppieRef.bind({
            url: e.target.result
          })
        }
        reader.readAsDataURL(this.files[0])
      }
    })
  }
}
</script>

<style scoped>
.container-item {
  margin: 1% 0;
}

.nav > li > .icon-plus {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav-tabs > li > .icon-plus {
  margin-right: 2px;
  line-height: 1.42857143;
  color: #3c8dbc;
  background: var(--white);
  border-radius: 4px 4px 0 0;
  border-color: #dddddd;
}

.nav-tabs > li > .icon-plus:hover,
.nav-tabs > li > .icon-plus:focus {
  color: #555;
  background: #f7f7f7;
  border: 1px solid;
  border-color: #eee #eee #ddd;
}

.form-control {
  border-radius: 4px;
}

.margin-left {
  margin-left: 5px;
}

.margin-right {
  margin-right: 5px;
}

.overflowTask {
  display: block;
  max-height: 600px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.food-img {
  width: 50px;
  margin: 0 20px 0 0;
  border: 1px solid #CACACA;
}

.td-padding-top {
  padding-top: 25px;
}

.checkbox-btn {
  display: none;
}

.checkbox-label .checkbox-div {
  width: 23px;
  height: 23px;
  display: inline-block;
  border: 2px solid var(--gray-dark);
  text-align: center;
  line-height: 20px;
  margin-right: 6px;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox-label i {
  font-size: 16px;
  opacity: 0;
}

.checkbox-label:hover div {
  background: var(--blue-opacity);
}

.checkbox-btn:checked + .checkbox-label i {
  opacity: 1;
}

.checkbox-btn:checked + .checkbox-label .checkbox-div {
  background: var(--blue-opacity);
}
</style>
