<template>
  <IndexLayout
    :btn-title="btn_title"
    :is-not-route-list="isNotRouteList"
    :route-name-create="route_name_create"
    :title="title"
  />
</template>

<script>
import IndexLayout from "@/components/layout/IndexLayout"

export default {
  name: "CurrencyIndex",
  components: { IndexLayout },
  computed: {
    isNotRouteList () {
      return this.$route.name !== "list-currency"
    }
  },
  data () {
    return {
      route_name_create: "create-currency",
      title: "label.currency",
      btn_title: "currency.button.newCurrency"
    }
  },
  mounted () {
    this.$router.replace("/")
  }
}
</script>

<style scoped>

</style>
