<template>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.showTax") }}
          </h3>
          <div class="box-tools hidden-xs">
            <router-link
              class="btn btn-primary btn-sm mr-2"
              :to="{name: 'edit-tax', params: {uuid: $route.params.uuid}}"
            >
              <i class="fas fa-edit" /> {{ $t("button.edit") }}
            </router-link>
            <a
              @click="$router.back()"
              class="btn btn-default btn-sm"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <table
            class="table table-striped"
            v-if="tax"
          >
            <tbody>
              <tr>
                <td>{{ $t("label.nameEn") }}</td>
                <td><strong>{{ tax.name_en || $t("string.na") }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t("label.nameKm") }}</td>
                <td><strong>{{ tax.name_km || $t("string.na") }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t("fields.label.amountPercentage") }}</td>
                <td>
                  <template v-if="tax.amount_percentage">
                    <strong>{{ tax.amount_percentage }}%</strong>
                  </template>
                  <template v-else>
                    <strong>{{ $t("string.na") }}</strong>
                  </template>
                </td>
              </tr>
              <tr>
                <td>{{ $t("fields.label.active") }}</td>
                <td>
                  <template v-if="tax.active">
                    <span class="label label-success">{{ $t("string.active") }}</span>
                  </template>
                  <template v-else>
                    <span class="label label-danger">{{ $t("string.inactive") }}</span>
                  </template>
                </td>
              </tr>
              <tr>
                <td>{{ $t("food.table.createdAt") }}</td>
                <td><strong>{{ $moment(tax.created_at).format("DD/MM/YYYY hh:mm:ss") }}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TaxShow",
  metaInfo () {
    return {
      title: this.$t("label.showTax"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      tax: null
    }
  },
  methods: {
    getTax () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/tax/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.tax = response.data.data
        }
        if (response.data.code === 0) {
          this.$router.push({ name: "list-tax" })
          this.$toastr("error", response.data.message, "Get Tax")
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.getTax()
  }
}
</script>

<style scoped>

</style>
