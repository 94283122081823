import Index from "@/views/inventories/stockAlertControl/Index"
import List from "@/views/inventories/stockAlertControl/List"

export const stockAlertControlRouters = {
  path: "stock-alert-control",
  component: Index,
  children: [
    {
      path: "",
      name: "list-stock-alert-control",
      component: List
    }
  ]
}
