<template>
  <SubscriptionCard :title="$t('string.subscription')">
    <SubscriptionPlanList
      v-if="step_plan === 1"
      @getCustomerAndPlans="getCustomerAndPlans"
    >
      <template slot="displayAlert">
        <SubscriptionChangePlanStatus ref="changePlanStatus" />
      </template>
    </SubscriptionPlanList>
    <SubscriptionUpgradePlan
      v-else-if="step_plan === 2"
      @onSubmit="$refs.changePlanStatus.getPlanChangeStatus()"
    />
  </SubscriptionCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import SubscriptionCard from "@/views/settings/subscription/components/SubscriptionCard"
import SubscriptionUpgradePlan from "@/views/settings/subscription/components/SubscriptionUpgradePlan"
import SubscriptionPlanList from "@/views/settings/subscription/components/SubscriptionPlanList"
import SubscriptionChangePlanStatus from "@/views/settings/subscription/components/SubscriptionChangePlanStatus"

export default {
  name: "SubscriptionPlans",
  components: {
    SubscriptionChangePlanStatus,
    SubscriptionPlanList,
    SubscriptionUpgradePlan,
    SubscriptionCard
  },
  computed: {
    ...mapGetters("subscription", {
      step_plan: "step",
      currentPlan: "currentPlan",
      showAlertChangePlan: "showAlertChangePlan"
    })
  },
  methods: {
    ...mapActions("subscription", {
      setSelectedPlan: "setSelectedPlan",
      setPlans: "setPlans",
      setCurrentPlan: "setCurrentPlan",
      setStep: "setStep"
    }),
    getCustomerAndPlans () {
      this.$axios.post(this.$base_api + "/api/bill/get-customer-and-plans")
        .then(({ data }) => {
          if (data.data) {
            const result = data.data
            this.setPlans(result.plans)

            this.customer = result.customer

            const { current_plan } = result.customer

            if (current_plan) {
              this.setSelectedPlan(current_plan)
              this.setCurrentPlan(current_plan)
            }
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    }
  },
  mounted () {
    this.getCustomerAndPlans()
  }
}
</script>

<style scoped>

</style>
