<template>
  <div>
    <div
      v-if="product !== null"
      class="row"
    >
      <div class="col-md-6">
        <table class="table table-striped">
          <tbody>
            <template v-if="isParent">
              <tr>
                <td style="width: 40%;">
                  {{ $t("fields.label.name") }}
                </td>
                <td style="width: 60%">
                  {{ product.name ? product.name : $t("string.na") }}
                </td>
              </tr>
              <tr v-if="product.barcode">
                <td style="width: 40%;">
                  {{ $t("string.barcode") }}
                </td>
                <td style="width: 60%">
                  {{ product.barcode }}
                </td>
              </tr>
              <tr v-if="product.sku">
                <td style="width: 40%;">
                  {{ $t("string.sku") }}
                </td>
                <td style="width: 60%">
                  {{ product.sku }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("fields.label.productType") }}</td>
                <td>{{ product.product_type ? product.product_type["name_" + $i18n.locale] : $t("string.na") }}</td>
              </tr>
              <tr>
                <td>{{ $t("fields.label.category") }}</td>
                <td>{{ product.category ? product.category["name_" + $i18n.locale] : $t("string.na") }}</td>
              </tr>
              <tr>
                <td>{{ $t("fields.label.tag") }}</td>
                <td>
                  <template
                    v-if="product && product.product_tags && Array.isArray(product.product_tags) && product.product_tags.length > 0"
                  >
                    <template v-for="(item, product_tag_key) in product.product_tags">
                      <div
                        class="slash"
                        :key="product_tag_key"
                      >
                        <label class="font-weight-normal margin-bottom-none">
                          {{ item["name_" + $i18n.locale] }}
                        </label>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    {{ $t("string.na") }}
                  </template>
                </td>
              </tr>
              <tr>
                <td>{{ $t("product.label.unit") }}</td>
                <td>{{ product.unit ? product.unit["name_" + $i18n.locale] : $t("string.na") }}</td>
              </tr>
            </template>
            <template v-else>
              <template
                v-if="product.hasOwnProperty('product_variants') && product.product_variants.length > 0"
              >
                <template v-for="(item, key) in product.product_variants">
                  <tr
                    v-if="item.pivot.value"
                    :key="key"
                  >
                    <td>{{ $i18n.locale === "km" ? item.name_km : item.name }}</td>
                    <td>{{ item.pivot.value }}</td>
                  </tr>
                </template>
              </template>
            </template>
            <tr>
              <td>{{ $t("fields.label.importedPrice") }}</td>
              <td>
                <template v-if="product.imported_price">
                  {{ product.imported_price | numFormat(isFloat(product.imported_price)) }}
                  {{ product.currency ? product.currency.code : null }}
                </template>
                <template v-else>
                  {{ $t("string.na") }}
                </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t("fields.label.salePrice") }}</td>
              <td>
                <template v-if="product.sale_price">
                  {{ product.sale_price | numFormat(isFloat(product.sale_price)) }}
                  {{ product.currency ? product.currency.code : null }}
                </template>
                <template v-else>
                  {{ $t("string.na") }}
                </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t("string.status") }}</td>
              <td>
                <template v-if="product.active">
                  <span class="label label-success tw-text-lg">{{ $t("string.active") }}</span>
                </template>
                <template v-else>
                  <span class="label label-danger tw-text-lg">{{ $t("string.inactive") }}</span>
                </template>
              </td>
            </tr>
            <template v-if="product.has_inventory === true">
              <tr>
                <td>{{ $t("fields.label.amountInStock") }}</td>
                <td>
                  <template v-if="product.children && product.children.length > 0">
                    <ul class="pl-0">
                      <li
                        v-for="(child, pKey) in product.children"
                        :key="pKey"
                      >
                        <label class="margin-bottom-none slash">
                          <span
                            v-for="(variant, ckey) in child.product_variants"
                            :key="ckey"
                          >{{
                            variant.pivot.value
                          }}</span> :
                          {{ child.child_warehouse | numFormat(isFloat(child.child_warehouse)) }}
                        </label>
                      </li>
                    </ul>
                  </template>
                  <div
                    v-else-if="product.warehouses && Array.isArray(product.warehouses) && product.warehouses.length > 0"
                  >
                    <template v-for="(productWarehouse, each_product_warehouse) in product.warehouses">
                      <ul
                        style="padding-left: 16px"
                        :key="each_product_warehouse"
                      >
                        <li>
                          <label>
                            {{ productWarehouse["name_" + $i18n.locale] }}:
                            {{
                              productWarehouse.pivot.amount | numFormat(isFloat(parseFloat(productWarehouse.pivot.amount)))
                            }}
                          </label>
                        </li>
                      </ul>
                    </template>
                  </div>
                  <span v-else>-</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--picture-->
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <div
              v-if="product.images && Array.isArray(product.images) && product.images.length"
              class="row"
            >
              <div
                v-for="(image, each_product_image_key) in product.images"
                :key="each_product_image_key"
                class="col-md-4 margin-bottom"
              >
                <img
                  alt="Product thumbnail"
                  :src="getSrc(image.src)"
                  class="img-thumbnail"
                >
              </div>
            </div>
            <div
              v-else-if="!isParent && parentImages && Array.isArray(parentImages) && parentImages.length"
              class="row"
            >
              <div
                v-for="(image, each_image_key) in parentImages"
                :key="each_image_key"
                class="col-md-4 margin-bottom"
              >
                <img
                  alt="Product thumbnail"
                  :src="getSrc(image.src)"
                  class="img-thumbnail"
                >
              </div>
            </div>
            <div
              v-else
              class="row"
            >
              <div class="col-md-12">
                <p class="text-muted text-center">
                  {{ $t("inventory.label.noImages") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductFormShow",
  props: {
    columns: {
      type: String,
      default: "col-md-8 col-md-offset-2"
    },
    product: {
      type: Object,
      default: null
    },
    isParent: {
      type: Boolean,
      default: true
    },
    parentImages: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      products: [],
      config: this.$config,
      message_validation: null,
      cropped: null,
      categoryOptions: [],
      unitOptions: [],
      productTypeOptions: [],
      tagOptions: [],
      currencyOptions: [],
      addVariant: false,
      variantTypes: [],
      variantUsed: []
    }
  },
  methods: {
    goToListRoute () {
      if (this.$route.name === "show-product") {
        this.$router.push({ name: "list-product" })
      } else {
        this.$router.push({ name: "list-drink" })
      }
    },
    tagInput (item, newTag, type) {
      if (item !== null) {
        this.variantUsed.forEach((ele) => {
          if (ele.id === item.id) {
            if (type === "add") {
              const tag = {
                name: newTag,
                value: newTag
              }
              ele.values.push(tag)
              ele.options.push(tag)
            } else {
              ele.options.splice(ele.values.indexOf(newTag), 1)
              ele.values.splice(ele.values.indexOf(newTag), 1)
            }
          }
        })
      }
    },
    productChild () {
      const result = []
      const product = Object.assign({}, this.product)
      const variantUsed = Object.assign({}, this.variantUsed)

      if (this.variantUsed.length > 0) {
        if (this.variantUsed.length === 1) {
          this.variantUsed[0].values.forEach((eachValue) => {
            result.push(Object.assign({}, {
              level_1: variantUsed[0].id,
              value_level_1: eachValue.value,
              sale_price: {
                value: product.sale_price,
                errorMessage: ""
              },
              imported_price: product.imported_price
            }))
          })
        } else if (this.variantUsed.length === 2) {
          this.variantUsed[0].values.forEach((eachValueLevel1) => {
            this.variantUsed[1].values.forEach((eachValueLevel2) => {
              result.push(Object.assign({}, {
                level_1: variantUsed[0].id,
                level_2: variantUsed[1].id,
                value_level_1: eachValueLevel1.value,
                value_level_2: eachValueLevel2.value,
                sale_price: {
                  value: product.sale_price,
                  errorMessage: ""
                },
                imported_price: product.imported_price
              }))
            })
          })
        } else {
          this.variantUsed[0].values.forEach((eachValueLevel1) => {
            this.variantUsed[1].values.forEach((eachValueLevel2) => {
              this.variantUsed[2].values.forEach((eachValueLevel3) => {
                result.push(Object.assign({}, {
                  level_1: variantUsed[0].id,
                  level_2: variantUsed[1].id,
                  level_3: variantUsed[2].id,
                  value_level_1: eachValueLevel1.value,
                  value_level_2: eachValueLevel2.value,
                  value_level_3: eachValueLevel3.value,
                  sale_price: {
                    value: product.sale_price,
                    errorMessage: ""
                  },
                  imported_price: product.imported_price
                }))
              })
            })
          })
        }
      }
      this.products = result
    },
    deleteImage () {
      this.$toastr("info", this.$t("string.viewOnly"), this.$t("string.deleteImage"))
    },
    addMoreVariant () {
      const result = this.variantTypes.filter((eachVariant) => {
        return eachVariant.used === false
      })
      if (result && Array.isArray(result) && result[0]) {
        result[0].used = true
        result[0].options = []
        this.variantUsed.push(result[0])
      }
    },
    clickBtnVariant () {
      this.addVariant = this.addVariant !== true
    },
    removeVariant (item) {
      this.variantUsed.filter(function (ele) {
        if (ele === item) {
          // eslint-disable-next-line no-return-assign
          return ele.values = []
        }
      })
      const itemTotalVariant = this.variantTypes.find(eachVariant => {
        return eachVariant.id === item.id
      })
      itemTotalVariant.used = false
      this.variantUsed.splice(this.variantUsed.indexOf(item), 1)
      this.productChild(null, null, null)
    },
    clickToBrowse () {
      $("#category-image").click()
    },
    getVariantType () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/variant-type", {
        shop_id: this.get_shop.id
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          response.data.data.forEach((variantType, index) => {
            if (index === 0) {
              this.variantTypes.push({
                id: variantType.id,
                label: variantType.name,
                used: true,
                values: [],
                options: []
              })
              this.variantUsed.push({
                id: variantType.id,
                label: variantType.name,
                used: true,
                values: [],
                options: []
              })
            } else {
              this.variantTypes.push({
                id: variantType.id,
                label: variantType.name,
                used: false,
                values: [],
                options: []
              })
            }
          })
        }
      })
    },
    getProductCategory () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-category", {
        shop_id: this.get_shop.id
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1 && response.data.data !== null) {
          this.categoryOptions = response.data.data
        }
      })
    },
    getProductUnit () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-unit", {
        shop_id: this.get_shop.id
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1 && response.data.data !== null) {
          this.unitOptions = response.data.data
        }
      })
    },
    getProductTag () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-tag", {
        shop_id: this.get_shop.id
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.tagOptions = response.data.data
        }
      })
    },
    getProductType () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-type", {
        shop_id: this.get_shop.id
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.productTypeOptions = response.data.data
        }
      })
    },
    storeProduct () {
      this.$isLoading(true)
      this.product.shop_id = this.get_shop.id
      this.product.is_parent = this.isParent
      this.product.children = this.products
      this.$axios.post(this.$config.base_url + "/api/product/store", this.product)
        .then((response) => {
          this.$isLoading(false)
          this.$emit("refreshProduct")
          if (response.data.code === 1) {
            this.products = []
            this.variantUsed = []
            if (this.product.hasOwnProperty("id") && this.product.id !== null) {
              this.$toastr("success", this.$t("string.theProductWasUpdatedSuccessfully"), this.$t("string.success"))
            } else {
              this.$toastr("success", this.$t("string.theProductWasCreatedSuccessfully"), this.$t("string.success"))
            }
          } else {
            this.message_validation = response.data.message
            this.$toastr("error", this.$t("string.canNotCreateOrUpdateProduct"), this.$t("string.error"))
          }
        })
    },
    crop () {
      const options = {
        format: "png"
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.product.images.push(output)
        this.cropped = output
      })
    },
    result (output) {
      this.product.images.push(output)
    },
    rotate (rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle)
    },
    getCurrency () {
      this.$axios.post(this.$config.base_url + "/api/currency/get-restau-currency", {
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1) {
          this.currencyOptions = response.data.data.currencies
        }
      })
    },
    refreshListingTable () {
      this.$emit("refreshProduct")
    }
  }
}
</script>

<style scoped></style>
