import Index from "@/views/inventories/types/Index"
import List from "@/views/inventories/types/List"
import Create from "@/views/inventories/types/Create"
import Edit from "@/views/inventories/types/Edit"

export const productTypeRouters = {
  path: "type",
  component: Index,
  name: "type",
  redirect: "/admin/inventories/type/",
  children: [
    {
      path: "",
      name: "list-product-type",
      component: List
    },
    {
      path: "create",
      name: "create-product-type",
      component: Create
    },
    {
      path: ":id/show",
      name: "show-product-type",
      component: Edit
    },
    {
      path: ":id/edit",
      name: "edit-product-type",
      component: Edit
    }
  ]
}
