import Menu from "@/views/settings/menu/Index"
import Setting from "@/views/settings/menu/Setting"
import List from "@/views/settings/menu/List"
import Create from "@/views/settings/menu/Create"
import Edit from "@/views/settings/menu/Edit"
import Show from "@/views/settings/menu/Show"

export const menuRouters = {
  path: "menu",
  component: Menu,
  redirect: "menu",
  children: [
    {
      path: "",
      name: "menu-list",
      component: Setting
    },
    {
      path: "setting",
      name: "menu-setting",
      component: List
    },
    {
      path: "create",
      name: "menu-create",
      component: Create
    },
    {
      path: "edit/:uuid",
      name: "menu-edit",
      component: Edit
    },
    {
      path: "show/:uuid",
      name: "menu-show",
      component: Show
    }
  ]
}
