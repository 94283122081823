import Layout from "@/views/amusementPark/Index"

import OrderLayout from "@/views/amusementPark/order/Index"
import Order from "@/views/amusementPark/order/Order"
import OrderList from "@/views/amusementPark/order/List"

import PaymentLayout from "@/views/amusementPark/payment/Index"
import Payment from "@/views/amusementPark/payment/Payment"

import Print from "@/views/amusementPark/Print"
import View from "@/views/amusementPark/order/View"

import TicketTypeLayout from "@/views/amusementPark/ticketType/Index"
import TicketTypeList from "@/views/amusementPark/ticketType/List"
import TicketTypeEdit from "@/views/amusementPark/ticketType/Edit"
import TicketTypeCreate from "@/views/amusementPark/ticketType/Create"

export const amusementRouters = {
  path: "/amusement-park",
  component: Layout,
  redirect: "/amusement-park/order",
  children: [
    {
      path: "order",
      name: "amusement-park-order",
      component: OrderLayout,
      redirect: "order",
      children: [
        {
          path: "list",
          name: "aps-list-order",
          component: OrderList
        },
        {
          path: ":order_uuid?/",
          name: "aps-order",
          component: Order
        },
        {
          path: "view/:order_uuid",
          name: "aps-view-order",
          component: View
        }
      ]
    },
    {
      path: "payment",
      name: "amusement-park-payment",
      component: PaymentLayout,
      redirect: "payment",
      children: [
        {
          path: "aps-payment/:order_uuid",
          name: "aps-payment",
          component: Payment
        }
      ]
    },
    {
      path: "print/:order_uuid",
      name: "amusement-park-print",
      component: Print
    },
    {
      path: "ticket-type",
      name: "ticket-type-layout",
      redirect: "ticket-type",
      component: TicketTypeLayout,
      children: [
        {
          path: "",
          name: "ticket-type-list",
          component: TicketTypeList
        },
        {
          path: "create",
          name: "ticket-type-create",
          component: TicketTypeCreate
        },
        {
          path: ":ticket_type_uuid/edit",
          name: "ticket-type-edit",
          component: TicketTypeEdit
        }
      ]
    }
  ]
}
