import Vue from "vue"
import VueI18n from "vue-i18n"

const files = require.context("./locale/", false, /\.json$/i)
const messages = {}
files.keys().forEach(key => {
  const lang = key.split("/")[1].split(".")[0]
  messages[lang] = files(key)
})

Vue.use(VueI18n)

const locale = localStorage.getItem("locale") || process.env.VUE_APP_I18N_LOCALE
const fallbackLocale = localStorage.getItem("locale") || process.env.VUE_APP_I18N_FALLBACK_LOCALE

export const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages
})
