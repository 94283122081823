<template>
  <div class="container-dashboard container">
    <div class="row">
      <PlanExpiredMessage />
      <template v-if="isOffline">
        <offline-dashboard />
      </template>
      <template v-else>
        <OnlineDashboard />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { VueOfflineMixin } from "vue-offline"
import { offlineMixins } from "@/mixins/offline"
import OfflineDashboard from "./OfflineDashboard"
import PlanExpiredMessage from "@/views/dashboards/PlanExpiredMessage"
import OnlineDashboard from "@/views/dashboards/OnlineDashboard"

export default {
  name: "Default",
  components: {
    OnlineDashboard,
    PlanExpiredMessage,
    OfflineDashboard
  },
  mixins: [VueOfflineMixin, offlineMixins],
  metaInfo () {
    return {
      title: this.$t("string.dashboard"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      token_device: process.env.VUE_APP_KEYWORD_TOKEN_DEVICE,
      token_active_device: process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE,
      gen_token: null
    }
  },
  computed: {
    ...mapState({
      shop: state => state.shop.shop,
      user: state => state.user.user,
      device_for_sell: state => state.shop.device_for_sell
    }),
    ...mapGetters("shop", {
      is_expired: "is_expired"
    })
  },
  async mounted () {
    await this.checkHasShop()
      .then(async () => {
        if (this.$bill_active === "true") {
          if (!this.is_expired && !this.isOffline) {
            this.getOfflineDataCache()
          }
        }
      })
      .catch(() => {
        this.onLogout()
      })
  },
  methods: {
    async checkHasShop () {
      return new Promise((resolve, reject) => {
        if (localStorage.hasOwnProperty("shop")) {
          const shop = JSON.parse(localStorage.getItem("shop"))
          if (shop) {
            resolve(shop)
          }
        }
        reject(new Error({
          message: {
            message_en: "Shop was not found",
            message_km: "រកមិនឃើញព័ត៌មានហាងទេ"
          }
        }))
      })
    }
  }
}
</script>

<style scoped>
@import "./../../assets/css/dashboard.css";
</style>
