import cashierLayout from "@/views/order/Index"
import cashierListV2 from "@/views/order/V2/List"
import showPayment from "@/views/cashiers/payment/Show"
import ListCustomer from "@/views/cashiers/customers/List"
import recentSellRouters from "@/routers/recentSellRouters"

export const cashierRouters = {
  path: "/cashier",
  component: cashierLayout,
  redirect: "/cashier/recent-sell",
  children: [
    {
      path: "/parked-sale",
      name: "cashier-list-v2",
      component: cashierListV2
    },
    {
      path: "payment/payment-show/:uuid/:orderFrom",
      name: "payment-show",
      component: showPayment
    },
    {
      path: "list-customer",
      name: "cashier-list-customer",
      component: ListCustomer
    },
    recentSellRouters
  ]
}
