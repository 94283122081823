<template>
  <div class="content-wrapper">
    <section class="content container-fluid">
      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t("report.strings.listingReportIncome") }} ( {{ statusDay }} )
              </h3>
            </div>
            <div class="box-body">
              <div class="row margin-bottom">
                <div class="col-xs-12">
                  <date-picker
                    v-model="date_range"
                    :not-after="new Date()"
                    :placeholder="$t('label.chooseDateRange')"
                    :shortcuts="false"
                    format="YYYY-MM-DD"
                    lang="en"
                    range
                    style="width: 50%; margin-bottom: 10px"
                    type="date"
                    @change="changeDateDuration('dateRange')"
                  />
                </div>
                <div class="col-xs-12">
                  <button
                    :class="selectDayStatus === 'Today'? 'btn-primary' : 'btn-default'"
                    class="btn"
                    @click="changeDateDuration('Today')"
                  >
                    <i class="far fa-calendar-alt" /> {{ $t("report.label.today") }}
                  </button>
                  <button
                    :class="selectDayStatus === 'This Week'? 'btn-primary' : 'btn-default'"
                    class="btn"
                    @click="changeDateDuration('This Week')"
                  >
                    <i class="far fa-calendar-alt" /> {{ $t("report.label.thisWeek") }}
                  </button>
                  <button
                    :class="selectDayStatus === 'This Month'? 'btn-primary' : 'btn-default'"
                    class="btn"
                    @click="changeDateDuration('This Month')"
                  >
                    <i class="far fa-calendar-alt" /> {{ $t("report.label.thisMonth") }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-xs-6 col-md-4 col-lg-3"
                  style="text-align: center"
                >
                  <div class="row restau-box restau-box-height-auto">
                    <div class="col-xs-12">
                      <label class="restau-box-label">
                        {{ $t("report.label.totalIncomeKhr") }}
                      </label>
                      <label class="restau-box-label_blue">
                        {{ totalAllIncome.riel | numFormat("0,0") }} KHR
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xs-6 col-md-4 col-lg-3"
                  style="text-align: center"
                >
                  <div class="row restau-box restau-box-height-auto">
                    <div class="col-xs-12">
                      <label class="restau-box-label">
                        {{ $t("report.label.totalIncomeUsd") }}
                      </label>
                      <label class="restau-box-label_blue">
                        {{ totalAllIncome.dollar | numFormat(num_format) }} USD
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <table
                    id="report-income-table"
                    class="table table-bordered table-hover"
                  >
                    <thead>
                      <tr>
                        <th>{{ $t("report.table.date") }}</th>
                        <th>{{ $t("report.table.amountTotalRiel") }}</th>
                        <th>{{ $t("report.table.amountTotalDollar") }}</th>
                      </tr>
                    </thead>
                    <tbody />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t("report.strings.incomeOutcomeMonthly") }}
              </h3>
            </div>
            <div class="box-body">
              <bar-chart
                :chart-data="chartData"
                :height="300"
                :options="chartOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import DatePicker from "vue2-datepicker"
import BarChart from "./../chart/barChart"
import { mapState } from "vuex"

export default {
  name: "ReportIncome",
  components: {
    DatePicker,
    BarChart
  },
  metaInfo () {
    return {
      title: this.$t("report.menu.income"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      shop_uuid: JSON.parse(localStorage.getItem("shop")).uuid,
      chartData: {},
      chartOptions: {},
      modesDay: [
        "Today", "This Week", "This Month"
      ],
      modesView: [
        "chart", "table"
      ],
      selectedModeView: "chart",
      selectDayStatus: "This Month",
      date_range: [],
      start_date: this.$moment().startOf("isoWeek").format("YYYY-MM-DD"),
      end_date: this.$moment().format("YYYY-MM-DD"),
      status: "Today",
      oTable: null,
      totalAllIncome: {
        dollar: 0,
        riel: 0
      }
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      currency: state => state.shop.currency
    }),
    outcomeLabel () {
      return this.$t("label.outcome")
    },
    incomeLabel () {
      return this.$t("label.income")
    },
    statusDay () {
      if (this.status === "Today") {
        return this.$t("report.label.today")
      } else if (this.status === "This Week") {
        return this.$t("report.label.thisWeek")
      } else if (this.status === "This Month") {
        return this.$t("report.label.thisMonth")
      } else {
        return this.status
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const self = this
      self.getIncomeOutcomeMonthly()
      self.oTable = self.dataTable()
      self.changeDateDuration(self.status)
    })
  },
  methods: {
    changeModeView (mode) {
      this.selectedModeView = (mode === "chart") ? this.modesView[0] : this.modesView[1]
    },
    changeDateDuration (status) {
      if (status === "dateRange") {
        if (!this.date_range[0] && !this.date_range[1]) {
          this.start_date = this.$moment().startOf("month").format("YYYY-MM-DD")
          this.end_date = this.$moment().format("YYYY-MM-DD")
          this.status = "This Month"
          this.selectDayStatus = this.modesDay[2]
        } else {
          this.start_date = this.$moment(this.date_range[0]).utc("+7:00").format("YYYY-MM-DD")
          this.end_date = this.$moment(this.date_range[1]).utc("+7:00").format("YYYY-MM-DD")
          this.status = this.start_date + " ~ " + this.end_date
          this.selectDayStatus = null
        }
      } else if (status === "Today") {
        this.selectDayStatus = this.modesDay[0]
        this.start_date = this.$moment().format("YYYY-MM-DD")
        this.end_date = this.$moment().format("YYYY-MM-DD")
        this.status = "Today"
        this.date_range = []
      } else if (status === "This Week") {
        this.selectDayStatus = this.modesDay[1]
        this.start_date = this.$moment().startOf("isoWeek").format("YYYY-MM-DD")
        this.end_date = this.$moment().format("YYYY-MM-DD")
        this.status = "This Week"
        this.date_range = []
      } else {
        this.selectDayStatus = this.modesDay[2]
        this.start_date = this.$moment().startOf("month").format("YYYY-MM-DD")
        this.end_date = this.$moment().format("YYYY-MM-DD")
        this.status = "This Month"
        this.date_range = []
      }
      this.getTotalIncome()
      this.oTable.draw(true)
    },
    getIncomeOutcomeMonthly () {
      this.$axios.post(this.$config.base_url + "/api/report/get-income-outcome-monthly-chart", {
        uuid: this.shop_uuid
      }).then((response) => {
        if (response.data.code === 1) {
          if (this.currency && this.currency.code === "KHR") {
            this.chartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              tooltips: {
                // enabled: true,
                mode: "point",
                callbacks: {
                  label: function (tooltipItems) {
                    return " " + parseFloat(tooltipItems.yLabel).toFixed(0) + "KHR"
                  }
                },
                titleFontSize: 18,
                bodyFontSize: 17
              },
              scales: {
                legend: {
                  display: true
                },
                yAxes: [{
                  position: "left",
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    callback: function (value) {
                      return `${parseFloat(value).toFixed(0)}KHR`
                    }
                  },
                  gridLines: {
                    display: true
                  }
                }]
              }
            }
          } else {
            this.chartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              tooltips: {
                // enabled: true,
                mode: "point",
                callbacks: {
                  label: function (tooltipItems) {
                    return " " + parseFloat(tooltipItems.yLabel).toFixed(2) + "USD"
                  }
                },
                titleFontSize: 18,
                bodyFontSize: 17
              },
              scales: {
                legend: {
                  display: true
                },
                yAxes: [{
                  position: "left",
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    callback: function (value) {
                      return `${parseFloat(value).toFixed(2)}USD`
                    }
                  },
                  gridLines: {
                    display: true
                  }
                }]
              }
            }
          }
          this.chartData = {
            labels: response.data.data.label,
            datasets: [
              {
                label: this.incomeLabel,
                data: response.data.data.incomeData,
                barPercentage: 0.5,
                barThickness: 50,
                backgroundColor: "#3c8dbc"
              }
            ]
          }
        } else if (response.data.code === 0) {
          const text = typeof response.data.message === "string" ? typeof response.data.message : this.$t("string.somethingWentWrong")
          this.$swal({
            type: "error",
            title: this.$t("string.error"),
            text
          })
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },

    dataTable () {
      const self = this
      return $("#report-income-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        searching: false,
        lengthChange: false,
        ajax: {
          url: self.config.base_url + "/api/report/get-income-daily-report",
          type: "POST",
          data: (d) => {
            d.uuid = self.shop_uuid
            d.start_date = self.start_date
            d.end_date = self.end_date
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "date",
            name: "date",
            title: self.$t("report.table.date")
          },
          {
            data: "amount_total_riel",
            name: "amount_total_riel",
            title: self.$t("report.table.amountTotalRiel"),
            searchable: false,
            orderable: false
          },
          {
            data: "amount_total_dollar",
            name: "amount_total_dollar",
            title: self.$t("report.table.amountTotalDollar"),
            searchable: false,
            orderable: false
          }
        ],
        order: [[0, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },

    getTotalIncome () {
      this.$axios.post(this.$config.base_url + "/api/report/get-data-income-daily-report", {
        uuid: this.shop_uuid,
        start_date: this.start_date,
        end_date: this.end_date
      }).then((response) => {
        if (response.data.code === 1) {
          const data = response.data.data
          this.totalAllIncome.dollar = data.amount_total_dollar
          this.totalAllIncome.riel = data.amount_total_riel
        } else if (response.data.code === 0) {
          this.$swal({
            type: "error",
            title: this.$t("swal.title.havingProblem"),
            text: response.data.message
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.restau-box {
  width: 100%;
  list-style: none;
  box-shadow: 1px 2px 10px lightgrey;
  margin: 10px 0;
  position: relative;
  cursor: pointer;
  counter-increment: item;
  background-color: var(--white);
  transition: 200ms;
}

.restau-box-height-auto {
  height: auto;
  min-height: 130px;
}

.restau-box:hover {
  box-shadow: 0 8px 16px var(--gray-very-light-shadow);
  transform: translateY(-2px);
}

.restau-box-label,
.restau-box-label_blue {
  font-size: 28px;
  margin: 8px 0;
  display: block;
  position: relative;
}

.restau-box-label {
  color: var(--gray);
}

.restau-box-label_blue {
  color: #337ab7;
}

.restau-box-icon {
  height: 60%;
  opacity: 0.8;
}

.restau-box-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 130px;
  display: flex;
  min-width: 200px;
  align-items: center;
  justify-content: flex-end;
}
</style>
