<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        {{ $t("inventory.strings.product") }}
        <small>{{ $t("inventory.strings.productManagement") }}</small>
      </h1>
      <ol class="breadcrumb">
        <li><a href="#"><i class="fas fa-tachometer-alt" /> {{ $t("inventory.label.inventory") }}</a></li>
        <li class="active">
          {{ $t("string.offlineInventoryTransaction") }}
        </li>
      </ol>
    </section>
    <section class="content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <router-view />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "OfflineInventoryTransaction"
}
</script>

<style scoped>

</style>
