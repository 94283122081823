<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            <span v-if="this.$route.name === 'edit-product-tag'">{{ $t("label.product.type.editType") }}</span>
            <span v-else>{{ $t("label.product.type.showType") }}</span>
          </h3>
        </div>
        <div class="box-body">
          <product-type-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductTypeForm from "./Form"

export default {
  components: { ProductTypeForm },
  name: "EditProductType",
  metaInfo () {
    return {
      title: this.$route.name === "edit-product-type" ? this.$t("label.product.type.editType") : this.$t("label.product.type.showType"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
