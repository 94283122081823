<template>
  <div class="content-wrapper">
    <section class="content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t("inventory.label.inventoryHistory") }}
              </h3>
              <div class="box-tools">
                <a
                  class="btn btn-sm btn-default"
                  @click="$router.back()"
                >
                  <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
                </a>
              </div>
            </div>
            <div class="box-body">
              <div class="row margin-bottom">
                <div class="col-xs-6 col-md-3">
                  <multiselect
                    v-model="selectedTransactionOption"
                    :allow-empty="false"
                    :option-height="104"
                    :options="transactionOption"
                    :placeholder="$t('string.pleaseSelect')"
                    :show-labels="false"
                    label="label"
                    track-by="value"
                    @input="getDataTable"
                  />
                </div>
              </div>
              <table
                id="report-product-sale-history-table"
                class="table table-bordered"
              >
                <thead>
                  <tr>
                    <th>{{ $t("report.table.name") }}</th>
                    <th>{{ $t("report.table.name") }}</th>
                    <th>{{ $t("report.table.name") }}</th>
                    <th>{{ $t("report.table.productName") }}</th>
                    <th>{{ $t("report.table.amount") }}</th>
                    <th>{{ $t("report.table.price") }}</th>
                    <th>{{ $t("report.table.transactionType") }}</th>
                    <th>{{ $t("report.table.expiredDate") }}</th>
                    <th>{{ $t("report.table.importedDate") }}</th>
                    <th>{{ $t("report.table.exportedDate") }}</th>
                    <th>{{ $t("report.table.createdAt") }}</th>
                  </tr>
                </thead>
                <tbody />
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "SaleHistory",
  metaInfo () {
    return {
      title: this.$t("inventory.label.inventoryHistory"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      shop_id: JSON.parse(localStorage.getItem("shop")).id,
      oTable: null,
      selectedTransactionOption: {
        label: this.$t("report.label.all"),
        value: ""
      }
    }
  },
  computed: {
    transactionOption () {
      return [
        {
          label: this.$t("report.label.all"),
          value: ""
        },
        {
          label: this.$t("report.label.transactionIn"),
          value: "in"
        },
        {
          label: this.$t("report.label.transactionOut"),
          value: "out"
        }
      ]
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw()
      this.selectedTransactionOption = this.transactionOption.find((item) => item.value === this.selectedTransactionOption.value)
    }
  },
  methods: {
    getDataTable () {
      this.oTable.draw()
    },
    dataTable () {
      const self = this
      return $("#report-product-sale-history-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        ajax: {
          url: this.$config.base_url + "/api/report/get-stock-detail-datatable",
          type: "POST",
          data: (d) => {
            d.shop_id = self.shop_id
            d.product_id = self.$route.params.id
            d.monthly = self.$route.params.monthly
            d.year = self.$route.params.year
            d.transactionOption = self.selectedTransactionOption.value
            d.lang = self.$i18n.locale
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "user_name",
            name: "user_name",
            title: this.$t("report.table.name")
          },
          {
            data: "user_name",
            name: "user.first_name",
            title: this.$t("report.table.name")
          },
          {
            data: "user_name",
            name: "user.last_name",
            title: this.$t("report.table.name")
          },
          {
            data: "product_name",
            name: "product_name",
            title: this.$t("report.table.productName")
          },
          {
            data: "amount",
            name: "amount",
            title: this.$t("report.table.amount")
          },
          {
            data: "price",
            name: "price",
            title: this.$t("report.table.price")
          },
          {
            data: "type",
            name: "type",
            title: this.$t("report.table.transactionType")
          },
          {
            data: "expired_date",
            name: "expired_date",
            title: this.$t("report.table.expiredDate")
          },
          {
            data: "import_date",
            name: "import_date",
            title: this.$t("report.table.importedDate")
          },
          {
            data: "exported_date",
            name: "created_at",
            title: this.$t("report.table.exportedDate")
          },
          {
            data: "created_at",
            name: "created_at",
            title: this.$t("report.table.createdAt")
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        },
        columnDefs: [
          {
            targets: [1, 2],
            visible: false
          },
          {
            targets: [0, 3, 5, 6, 7, 8, 9],
            searchable: false
          },
          {
            targets: [3, 5],
            orderable: false
          }
        ],
        order: [[10, "desc"]]
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
  }
}
</script>

<style scoped>

</style>
