<template>
  <div>
    <master-header />
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import MasterHeader from "../../../includes/Header"

export default {
  name: "TicketType",
  components: { MasterHeader }
}
</script>

<style scoped>

</style>
