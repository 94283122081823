<template>
  <IndexLayout
    :hidden-button="true"
    :is-not-route-list="true"
    title="string.additional_apps"
  />
</template>

<script>
import IndexLayout from "@/components/layout/IndexLayout"

export default {
  name: "Index",
  components: { IndexLayout }
}
</script>

<style scoped>

</style>
