<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("strings.listingInvoiceTemplates") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'create-invoice-template'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("button.newInvoiceTemplate") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <table
              id="invoice-template-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("label.templateTitleEn") }}</th>
                  <th>{{ $t("label.templateTitleKm") }}</th>
                  <th>{{ $t("table.invoiceTemplate.status") }}</th>
                  <th>{{ $t("table.invoiceTemplate.createdAt") }}</th>
                  <th>{{ $t("table.invoiceTemplate.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <InvoiceTemplateUserDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>
import InvoiceTemplateUserDataTableSm
from "@/views/settings/invoice-template/_components/InvoiceTemplateUserDataTableSm"

export default {
  name: "InvoiceTemplateList",
  components: { InvoiceTemplateUserDataTableSm },
  metaInfo () {
    return {
      title: this.$t("strings.listingInvoiceTemplates"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null,
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    windowWidth: function () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    oTable () {
      this.reloadButtonAction()
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    deleteExchange (uuid) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/invoice-template/delete", {
          shop_uuid: self.get_shop.uuid,
          uuid: uuid
        }).then(() => {
          self.$aswaldelete(self.$t("model.invoiceTemplate"))
          self.oTable.draw(true)
        }).catch((error) => {
          this.onResponseError(error)
        })
      })
    },
    activeTemplate (uuid) {
      const self = this
      self.$swal({
        title: self.$t("menu.settings.invoiceTemplate"),
        text: self.$t("swal.setInvoiceTemplate.desc"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.$t("string.yesIAmSure"),
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(this.$config.base_url + "/api/invoice-template/active-template", {
          uuid,
          shop_uuid: this.get_shop.uuid
        }).then(() => {
          self.oTable.draw(true)
        }).catch((error) => {
          this.onResponseError(error)
        })
      })
    },
    dataTable () {
      const self = this
      return $("#invoice-template-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: self.config.base_url + "/api/invoice-template/datatable",
          type: "POST",
          data: (d) => {
            d.shop_id = self.get_shop.id
            d.labelEdit = self.labelEdit
            d.labelDelete = self.labelDelete
            d.labelInactive = self.labelInactive
            d.labelActive = self.labelActive
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "title_en",
            name: "title_en",
            title: self.$t("label.templateTitleEn")
          },
          {
            data: "title_km",
            name: "title_km",
            title: self.$t("label.templateTitleKm")
          },
          {
            data: "active",
            name: "active",
            title: self.$t("table.invoiceTemplate.status")
          },
          {
            data: "created_at",
            name: "created_at",
            searchable: false,
            title: self.$t("table.exchange.createdAt")
          },
          {
            data: "actions",
            name: "actions",
            searchable: false,
            orderable: false,
            title: self.$t("table.exchange.actions")
          }
        ],
        order: [[3, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    reloadButtonAction () {
      const self = this
      $(document).off("click", ".btn-active")
      $(document).off("click", ".btn-edit")
      $(document).off("click", ".btn-delete")

      $(document).on("click", ".btn-edit", function (e) {
        e.preventDefault()
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "edit-invoice-template",
          params: { uuid: uuid }
        })
      })

      $(document).on("click", ".btn-active", function (e) {
        e.preventDefault()
        const uuid = $(this).attr("data-uuid")
        self.activeTemplate(uuid)
      })

      $(document).on("click", ".btn-delete", function (e) {
        e.preventDefault()
        const uuid = $(this).attr("data-uuid")
        self.deleteExchange(uuid)
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
