import Payment from "@/views/offline/payment/Index"
import Bill from "@/views/offline/V2/payment/BillVersion2"
import PreBill from "@/views/offline/payment/PreBill"

export const offlinePaymentRouters = {
  path: "payment",
  name: "offline-payment",
  component: Payment,
  children: [
    {
      path: "pre-bill/:order_uuid",
      name: "offline-pre-bill",
      component: PreBill
    },
    {
      path: "bill/:order_uuid",
      name: "offline-bill",
      component: Bill
    }
  ]
}
