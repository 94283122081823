<template>
  <div class="container-error">
    <div class="container-error-content">
      <h3 class="text-uppercase">
        {{ $t("string.accessDenied") }}
      </h3>
      <p>{{ $t("string.sorry") }}, {{ $t("string.youDoNotHavePermissionToAccessThisPage") }}</p>
      <div>
        <img
          alt="403 Image"
          src="/img/errors/403.svg"
        >
      </div>
      <div class="margin-top">
        <router-link
          class="btn btn-default btn-lg shadow btn-border"
          to="/"
        >
          <i class="fas fa-arrow-left" /> {{ $t("string.goToDashboard") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessDenied",
  metaInfo () {
    return {
      title: this.$t("string.accessDenied"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>
.container-error {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-error-content {
  display: inline-block;
  line-height: 5rem;
  text-align: center;
  width: 400px;
}

.container-error-content p {
  font-size: 2rem;
}

.container-error img {
  width: 30vw;
}

.btn-border {
  border-radius: 9999px;
  border: none;
  font-size: 18px !important;
  text-transform: unset;
  padding: 10px 35px;
}
</style>
