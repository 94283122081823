<template>
  <div class="row">
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-md-6">
              <div
                :class="{'has-error' : message_validation !== null && message_validation.hasOwnProperty('name_en') }"
                class="form-group"
              >
                <label class="required">{{ $t("label.nameEn") }}</label>
                <input
                  v-model="productCategory.name_en"
                  :placeholder="$t('placeholder.enterNameAsEn')"
                  class="form-control"
                  type="text"
                >
                <template v-if="message_validation !== null && message_validation.hasOwnProperty('name_en')">
                  <span class="help-block">{{ message_validation["name_en"][0] }}</span>
                </template>
              </div>
            </div>
            <div class="col-md-6">
              <div
                :class="{'has-error' : message_validation !== null && message_validation.hasOwnProperty('name_km') }"
                class="form-group"
              >
                <label class="required">{{ $t("label.nameKm") }}</label>
                <input
                  v-model="productCategory.name_km"
                  :placeholder="$t('placeholder.enterNameAsKm')"
                  class="form-control"
                  type="text"
                >
                <template v-if="message_validation !== null && message_validation.hasOwnProperty('name_km')">
                  <span class="help-block">{{ message_validation["name_km"][0] }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>{{ $t("fields.label.image") }}</label>
                <div class="row">
                  <div class="col-lg-6">
                    <vue-croppie
                      ref="croppieRef"
                      :boundary="{ width: 270, height: 270 }"
                      :enable-orientation="true"
                      :enable-resize="false"
                      :show-zoomer="false"
                      :viewport="{ width: 265, height: 265 }"
                      @result="result"
                    />
                    <input
                      id="category-image"
                      accept="image/*"
                      style="display: none"
                      type="file"
                    >
                    <div class="text-center margin-top">
                      <div
                        aria-label="..."
                        class="btn-group"
                        role="group"
                      >
                        <button
                          class="btn btn-success"
                          @click="clickToBrowse"
                        >
                          <i
                            class="fas fa-paperclip"
                          />
                        </button>
                        <button
                          class="btn btn-default"
                          @click="rotate(-90)"
                        >
                          <i class="fas fa-undo" />
                        </button>
                        <button
                          class="btn btn-default"
                          @click="rotate(90)"
                        >
                          <i class="fas fa-repeat" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="d-flex align-items-center">
                      <template v-if="productCategory && productCategory.image">
                        <div style="width: 270px; margin: 0 auto">
                          <img
                            :src="productCategory.image"
                            class="img-thumbnail w-100"
                            alt="Product category image"
                          >
                        </div>
                        <div style="width: 270px; margin: 0 auto">
                          <img
                            :src="productCategory.image"
                            class="img-thumbnail w-100 img-circle"
                            alt="Product category image"
                          >
                        </div>
                      </template>
                      <template v-else>
                        <div style="width: 270px; margin: 0 auto">
                          <img
                            alt="270x270"
                            class="img-thumbnail w-100"
                            src="/img/270x270.png"
                          >
                        </div>
                        <div style="width: 270px; margin: 0 auto">
                          <img
                            alt="270x270"
                            class="img-thumbnail w-100 img-circle"
                            src="/img/270x270.png"
                          >
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="text-right">
            <template v-if="this.$route.name !== 'show-product-category'">
              <button
                :class="`btn btn-${$route.name === 'edit-product-category' ? 'info' : 'primary' }`"
                @click="storeProductCategory"
              >
                <i class="fas fa-check-circle" />
                <template v-if="$route.name === 'edit-product-category'">
                  {{ $t("button.update") }}
                </template>
                <template v-else>
                  {{ $t("button.save") }}
                </template>
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCategoryForm",
  metaInfo () {
    return {
      title: this.title,
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  props: {
    columns: {
      type: String,
      default: "col-md-8 col-md-offset-2"
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return this.isEdit ? this.$t("label.product.category.editCategory") : this.$t("label.product.category.newCategory")
    }
  },
  data () {
    return {
      productCategory: {
        shop_id: null,
        product_type: null,
        name_en: "",
        name_km: "",
        image: "",
        icon: ""
      },
      config: this.$config,
      message_validation: null,
      cropped: null,
      productTypes: [],
      isShow: false
    }
  },
  methods: {
    clickToBrowse () {
      $("#category-image").click()
    },
    getProductType () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-type")
        .then((response) => {
          this.$isLoading(false)
          if (response.data.data !== null) {
            this.productTypes = response.data.data
          }
        })
    },
    getProductCategory () {
      this.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/product-category/show", {
          shop_id: this.get_shop.id,
          uuid: this.$route.params.uuid
        })
        .then((response) => {
          if (response.data.code === 1) {
            this.productCategory = response.data.data
            if (this.productCategory && this.productCategory.image) {
              this.$set(this.productCategory, "image", this.$config.base_url + "/" + this.productCategory.image)
            }
          }
          if (response.data.code === 0) {
            if (typeof (response.data.message) === "object") {
              this.message_validation = response.data.message
            } else {
              this.$toastr("error", response.data.message, "Product category")
              this.$router.push({ name: "list-product-category" })
            }
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    storeProductCategory () {
      this.$isLoading(true)
      const options = {
        format: "png",
        circle: false
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output
      })
      setTimeout(() => {
        this.productCategory.shop_id = this.get_shop.id
        this.productCategory.image = this.cropped
        this.$axios
          .post(this.$config.base_url + "/api/product-category/store", this.productCategory)
          .then((response) => {
            if (response.data.code === 1) {
              this.$router.push({ name: "list-product-category" })
              if (this.productCategory.hasOwnProperty("id") && this.productCategory.id !== null) {
                this.$anotify(this.$t("model.category"), "update")
              } else {
                this.$anotify(this.$t("model.category"))
              }
            }
            if (response.data.code === 0) {
              if (typeof (response.data.message) === "object") {
                this.message_validation = response.data.message
              } else {
                this.$toastr("error", response.data.message, "Product unit")
                this.$router.push({ name: "list-product-category" })
              }
            }
          })
          .finally(() => {
            this.$isLoading(false)
          })
      }, 1200)
    },
    crop () {
      const options = {
        format: "png",
        circle: false
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output
      })
    },
    result (output) {
      this.productCategory.image = output
    },
    rotate (rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle)
    }
  },
  mounted () {
    const self = this
    this.getProductType()
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getProductCategory()
    }
    if (this.$route.name === "show-product-category") {
      $("input").attr("disabled", true)
      this.isShow = true
    }

    if (this.$refs.croppieRef) {
      this.$refs.croppieRef.bind({
        url: "/img/270x270.png"
      })
    }

    $(document).on("change", "#category-image", function () {
      if (this.files && this.files[0]) {
        const reader = new FileReader()
        reader.onload = function (e) {
          if (self.$refs.croppieRef) {
            self.$refs.croppieRef.bind({
              url: e.target.result
            })
          }
        }
        reader.readAsDataURL(this.files[0])
      }
    })
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}

.multiselect__option i.fa {
  font-size: 32px !important;
}

.cat_no_img {
  width: 270px;
  height: 270px;
  margin: 0 auto;
}

.cat_no_img p {
  font-size: 18px;
  font-weight: 700;
}
</style>
