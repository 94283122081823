<template>
  <div
    :style="`height: ${height}`"
    class="w-100 bg-white"
  >
    <div class="d-flex align-items-center justify-content-center h-100">
      <div class="d-block">
        <p
          v-if="message"
          class="text-bold font-s-22"
        >
          <i class="fas fa-exclamation-triangle mr-2" /> {{ message["message_" + $i18n.locale] }}
        </p>
        <template v-if="$i18n.locale === 'km'">
          <p class="font-s-18">
            ដំឡើងគម្រោង:
          </p>
          <p>ប្រសិនបើអ្នកចង់ដំឡើងគម្រោង</p>
          <ol>
            <li>ចូលទៅកាន់ផ្ទាំងគ្រប់គ្រងហើយចុចលើ <strong>ការកំណត់</strong>។</li>
            <li>
              ជ្រើសរើសផ្ទាំង
              <router-link :to="{name: 'list-subscription'}">
                <strong>ការជាវ</strong>
              </router-link>
              ។
            </li>
            <li>ជ្រើសរើសគម្រោងដែលអ្នកចង់ដំឡើង។</li>
            <li>ចុចប៊ូតុង <strong>ផ្លាស់ប្តូរគម្រោង</strong>។</li>
            <li>បញ្ជូនសំណុំបែបបទ។</li>
          </ol>
          <p class="font-s-16">
            <i class="fas fa-info-circle mr-2" /> សម្រាប់ព័ត៌មានបន្ថែម សូមទាក់ទង
            <a
              href="https://figos.app"
              target="_blank"
            ><strong>Figos.app</strong></a>.
          </p>
        </template>
        <template v-else>
          <p class="font-s-18">
            Upgrade plan:
          </p>
          <p>If you want to upgrade plan.</p>
          <ol>
            <li>Go to dashboard and click on <strong>Settings</strong>.</li>
            <li>
              Select tab
              <router-link :to="{name: 'list-subscription'}">
                <strong>Subscription</strong>
              </router-link>
              .
            </li>
            <li>Select the plan that you want to upgrade.</li>
            <li>Click button <strong>Change Plan</strong>.</li>
            <li>Submit form.</li>
          </ol>
          <p class="font-s-16">
            <i class="fas fa-info-circle mr-2" /> please contact
            <a
              href="https://figos.app"
              target="_blank"
            ><strong>Figos.app</strong></a>។
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanMessage",
  props: {
    message: {
      type: Object,
      default: () => {
        return {
          message_km: "គម្រោងរបស់អ្នកត្រូវការដំឡើងសម្រាប់ដំណើរការបន្ត",
          message_en: "Your plan needs to be upgraded for resume"
        }
      }
    },
    height: {
      type: String,
      default: "50vh"
    }
  }
}
</script>

<style scoped>

</style>
