import Cashier from "@/views/offline/cashier/Index"
import List from "@/views/offline/V2/cashier/ListVersion2"
import ListRecentSale from "@/views/offline/V2/cashier/RecentSaleList"

export const offlineCashierRouters = {
  path: "cashier",
  name: "offline-cashier",
  component: Cashier,
  children: [
    {
      path: "order",
      name: "offline-cashier-order-list",
      component: List
    },
    {
      path: "list-recent-sale",
      name: "offline-list-recent-sale",
      component: ListRecentSale
    }
  ]
}
