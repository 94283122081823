<template>
  <div
    v-if="shop"
    class="d-flex w-100"
  >
    <div
      v-if="message_paid.paid"
      class="alert alert-dismissible alert-invoice-paid"
      role="alert"
    >
      <button
        aria-label="Close"
        class="close"
        data-dismiss="alert"
        type="button"
        @click="message_paid.paid = false"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <i class="fas fa-exclamation-circle text-yellow" />
      {{ message_paid["message_" + $i18n.locale] }}
      <router-link
        :to="{name: 'list-recent-sell', params: {uuid: message_paid.invoice_uuid}}"
        class="alert-link"
        replace
      >
        {{ $t("label.clickToViewInvoice") }}
      </router-link>
    </div>
    <div class="pos-content-container flex-1 bg-white">
      <div class="d-flex flex-column payment-container rounded">
        <div class="block-category d-flex rounded-tl rounded-tr">
          <div
            class="d-flex w-100 align-items-center justify-content-between"
            style="padding-top: 8px !important;"
          >
            <div class="w-100">
              <div class="d-flex align-items-center justify-content-between">
                <p class="title-label mt-0 font-s-20">
                  <span>
                    <strong>{{ $t("label.payment") }} </strong>
                  </span>
                  <span
                    v-if="order_number"
                    class="text-capitalize"
                  >
                    <strong>{{ $t("table.order.orderNumber") }} : #{{ order_number }}</strong>
                  </span>
                </p>
                <div>
                  <a
                    aria-controls="collapseExample"
                    aria-expanded="false"
                    class="btn btn-primary btn-lg mr-3 hidden-sm hidden-md hidden-lg"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                  >
                    <i class="fas fa-eye" />
                  </a>
                  <a
                    class="btn btn-default btn-lg no-margin"
                    @click="$router.back()"
                  >
                    <i class="fas fa-arrow-left back" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 payment-info-container rounded-tl rounded-br">
          <DisplayOrderInfo
            :amount-total="amountTotal"
            :dic-is-percentage="is_percentage"
            :discount-value="discount"
            :exchange-rate="exchangeRate"
            :membership-discount="membershipDis"
            :products="products"
            :shop-tax="shop_tax"
            :sub-total="subTotal"
            class="w-50 border-r hidden-xs"
          />
          <div
            id="collapseExample"
            class="collapse hidden-sm hidden-md hidden-lg"
          >
            <DisplayOrderInfo
              :amount-total="amountTotal"
              :dic-is-percentage="is_percentage"
              :discount-value="discount"
              :exchange-rate="exchangeRate"
              :membership-discount="membershipDis"
              :products="products"
              :shop-tax="shop_tax"
              :sub-total="subTotal"
              class="w-50 border-r"
            />
          </div>
          <div class="h-100 w-50 d-flex flex-column block-content-h">
            <div class="h-100 flex-1 position-relative overflow-hidden">
              <div class="h-100 d-flex flex-column w-100 block-payment-balance-content overflow-y-auto scrollbar">
                <div class="row mx-0 ">
                  <div class="col-xs-12 no-padding user-select-none hidden-sm hidden-md hidden-lg">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 py-0">
                          <div class="block-total-l text-capitalize">
                            <div class="font-s-16">
                              <strong>{{ $t("label.amountTotal") }}</strong>
                            </div>
                            <div style="line-height: 0.8">
                              <small class="font-s-14">({{ $t("label.includeVat") }})</small>
                            </div>
                          </div>
                          <div class="block-total-r">
                            <div class="font-s-22">
                              <span :class="parseInt(amountTotal) <= 0 ? 'text-red' : 'pos-menubar_active'">
                                {{ currency ? currency.symbol : "" }}
                                <template v-if="currency && currency.code === 'KHR'">
                                  {{ amountTotal | numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ amountTotal | numFormat(num_format) }}
                                </template>
                              </span>
                            </div>
                            <div
                              :class="parseInt(amountTotal) <= 0 ? 'text-red' : 'pos-menubar_active'"
                              class="font-s-16"
                            >
                              {{ currency.code === "USD" ? "៛" : "$" }}
                              <template v-if="currency && currency.code !== 'KHR'">
                                {{ moneyEquivalent(currency.code, amountTotal) | numFormat("0,0") }}
                              </template>
                              <template v-else>
                                {{ moneyEquivalent(currency.code, amountTotal) | numFormat(num_format) }}
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 no-padding user-select-none">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top no-padding">
                          <div class="w-100">
                            <div class="form-group">
                              <div class="input-group input-group-lg input-group-h-xl">
                                <span
                                  id="amount-riel"
                                  class="input-group-addon rounded-bl rounded-tl"
                                >KHR (៛)</span>
                                <input
                                  v-model="paidRiel"
                                  aria-describedby="amount-riel"
                                  class="form-control"
                                  type="number"
                                  @click="onFocusInput"
                                >
                                <div class="input-group-btn">
                                  <button
                                    class="btn btn-default no-margin"
                                    type="button"
                                    @click="payAmountTotal('KHR')"
                                  >
                                    <i class="far fa-credit-card" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="form-group no-margin">
                              <div class="input-group input-group-lg input-group-h-xl">
                                <span
                                  id="amount-dollar"
                                  class="input-group-addon rounded-bl rounded-tl"
                                >USD ($)</span>
                                <input
                                  v-model="paidDollar"
                                  aria-describedby="amount-dollar"
                                  class="form-control"
                                  type="number"
                                  @click="onFocusInput"
                                >
                                <div class="input-group-btn">
                                  <button
                                    class="btn btn-default no-margin"
                                    type="button"
                                    @click="payAmountTotal('USD')"
                                  >
                                    <i class="far fa-credit-card" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <button
                            class="btn btn-default btn-lg btn-block no-margin"
                            data-target="#addDiscount"
                            data-toggle="modal"
                            type="button"
                            @click="openDiscountModal"
                          >
                            <span class="fa-stack fa-discount mr-2">
                              <i class="fas fa-tag fa-rotate-90 fa-stack-2x" />
                              <i class="fas fa-percent fa-stack-1x fa-inverse" />
                            </span>
                            <span>{{ $t("label.discount") }}</span>
                            <template v-if="order_discount && order_discount > 0">
                              <span class="ml-2 font-s-18 text-bold text-red">
                                <template v-if="dis_as_percentage">
                                  {{ order_discount | numFormat(num_format) }} %
                                </template>
                                <template v-else>
                                  <template v-if="currency">
                                    {{ currency.code }}
                                    <template v-if="currency && currency.code === 'KHR'">
                                      {{ order_discount | numFormat("0,0") }}
                                    </template>
                                    <template v-else>
                                      {{ order_discount | numFormat(num_format) }}
                                    </template>
                                  </template>
                                  <template v-else>
                                    USD {{ order_discount | numFormat(num_format) }}
                                  </template>
                                </template>
                              </span>
                            </template>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="parseFloat(amountDue) > 0"
                    class="col-xs-12 no-padding user-select-none"
                  >
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount amount align-items-top px-0 pt-3 pb-0">
                          <div class="block-total-l user-select-none text-capitalize">
                            <strong>{{ $t("label.amountChange") }}</strong>
                          </div>
                          <div class="block-total-r">
                            <div class="text-bold">
                              {{ currency ? currency.symbol : "" }}
                              <template v-if="currency && currency.code === 'KHR'">
                                {{ amountDue | numFormat("0,0") }}
                              </template>
                              <template v-else>
                                {{ amountDue | numFormat(num_format) }}
                              </template>
                            </div>
                            <div class="text-bold font-s-14">
                              ({{ currency.code === "USD" ? "៛" : "$" }}
                              <template v-if="currency && currency.code !== 'KHR'">
                                {{ moneyEquivalent(currency.code, amountDue) | numFormat("0,0") }})
                              </template>
                              <template v-else>
                                {{ moneyEquivalent(currency.code, amountDue) | numFormat(num_format) }})
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="parseFloat(amountOwe) > 0"
                    class="col-xs-12 no-padding user-select-none"
                  >
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount amount align-items-top px-0 pt-3 pb-0">
                          <div class="block-total-l user-select-none text-capitalize">
                            <strong>{{ $t("label.amountOwe") }}</strong>
                          </div>
                          <div class="block-total-r">
                            <div
                              :class="{'text-red': parseFloat(amountOwe) > 0}"
                              class="text-bold"
                            >
                              {{ currency ? currency.symbol : "" }}
                              <template v-if="currency && currency.code === 'KHR'">
                                {{ amountOwe | numFormat("0,0") }}
                              </template>
                              <template v-else>
                                {{ amountOwe | numFormat(num_format) }}
                              </template>
                            </div>
                            <div
                              :class="{'text-red': parseFloat(amountOwe) > 0}"
                              class="text-bold font-s-14"
                            >
                              ({{ currency.code === "USD" ? "៛" : "$" }}
                              <template v-if="currency && currency.code !== 'KHR'">
                                {{ moneyEquivalent(currency.code, amountOwe) | numFormat("0,0") }})
                              </template>
                              <template v-else>
                                {{ moneyEquivalent(currency.code, amountOwe) | numFormat(num_format) }})
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="text-capitalize user-select-none">
                            <strong>{{ $t("label.paymentOption") }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="list-category list-payment-option h-auto">
                            <div
                              v-for="(item, key) in paymentOptions"
                              :key="key"
                              :class="paymentOptionModel && paymentOptionModel.uuid === item.uuid ? 'active' : ''"
                              class="list-category-item has-border list-category-item-h max-w-100 margin-b-8"
                              @click="paymentOptionModel = item"
                            >
                              <h4 class="list-category-label">
                                {{ item["name_" + $i18n.locale] }}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="user-select-none text-capitalize">
                            <strong>{{ $t("label.customer") }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="selectedCustomer"
                    class="col-xs-12 payment-selected-customer-display user-select-none"
                  >
                    <div class="selected-customer">
                      <div class="selected-customer-info">
                        <div class="sugg-cus-name">
                          <strong>{{ selectedCustomer.name }}</strong>
                        </div>
                        <div class="sugg-cus-contact">
                          <template v-if="selectedCustomer.customer_membership.length">
                            <span v-if="checkMembershipExpired(selectedCustomer.customer_membership[0])">
                              <strong>
                                {{ selectedCustomer.customer_membership[0]["name_" + $i18n.locale] }}
                                ({{ selectedCustomer.customer_membership[0].amount_percentage }}%)
                              </strong>
                            </span>
                          </template>
                          <span v-if="selectedCustomer.tel">{{ selectedCustomer.tel }}</span>
                          <span v-if="selectedCustomer.address">{{ selectedCustomer.address }}</span>
                        </div>
                      </div>
                      <div>
                        <button
                          class="btn btn-lg btn-danger no-margin"
                          @click="clearSelectCustomer"
                        >
                          <i class="fas fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="col-xs-12 no-padding user-select-none"
                  >
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="row mx-0 w-100">
                            <div class="col-xs-12 px-0">
                              <button
                                class="btn btn-block btn-default btn-lg"
                                @click="showSelectCustomer = !showSelectCustomer"
                              >
                                <i class="fas fa-plus" />
                                {{ $t("button.select_customer") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="row mx-0 w-100">
                            <div class="col-xs-12 px-0">
                              <button
                                :class="paymentOptionModel && paymentOptionModel.code === 'coupon' && couponPaymentInfo.status !== 1? 'disabled' : ''"
                                class="btn btn-success btn-block btn-pay justify-content-center"
                                @click="onConfirmPayment"
                              >
                                {{ $t("button.completePayment") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DrawerCustomer
                :show-select-customer="showSelectCustomer"
                class-list-customer="list-sugg-customer-payment"
                @closeDrawer="closeDrawerCustomer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="addDiscount"
      aria-labelledby="myModalLabel"
      class="modal fade"
      data-backdrop="static"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header-custom">
            <h4
              id="myModalLabel"
              class="modal-title"
            >
              {{ $t("label.discount") }}
            </h4>
            <button
              ref="close"
              :class="class_btn_modal"
              aria-label="Close"
              data-dismiss="modal"
              type="button"
              @click="resetDiscountModal"
            >
              <i
                aria-hidden="true"
                class="fas fa-times"
              />
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12">
                <div class="row form-group">
                  <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                    {{ $t("label.amountTotal") }}
                  </div>
                  <div class="col-md-8">
                    <p class="control-label user-select-none font-s-18 mt-3 font-weight-bold">
                      {{ currency ? currency.code : "USD" }}
                      <template v-if="currency && currency.code === 'KHR'">
                        {{ amountTotal | numFormat("0,0") }}
                      </template>
                      <template v-else>
                        {{ amountTotal | numFormat(num_format) }}
                      </template>
                    </p>
                    <small class="control-label user-select-none font-s-16 mt-3 font-weight-bold">
                      {{ currency.code === "USD" ? "KHR" : "USD" }}
                      <template v-if="currency && currency.code !== 'KHR'">
                        {{ moneyEquivalent(currency.code, amountTotal) | numFormat("0,0") }}
                      </template>
                      <template v-else>
                        {{ moneyEquivalent(currency.code, amountTotal) | numFormat(num_format) }}
                      </template>
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="row form-group">
                  <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                    {{ $t("label.discount") }}
                  </div>
                  <div class="col-md-8">
                    <div class="input-group input-group-lg">
                      <input
                        v-model="discount"
                        aria-describedby="discount"
                        class="form-control"
                        min="0"
                        type="number"
                      >
                      <div class="input-group-btn">
                        <button
                          :class="is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                          class="btn no-margin bl-unset"
                          @click="is_percentage = true"
                        >
                          <i class="fas fa-percent" />
                        </button>
                        <button
                          :class="!is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                          class="btn"
                          @click="is_percentage = false"
                        >
                          {{ currency ? currency.code : "USD" }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="row form-group">
                  <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                    {{ $t("label.authorizedBy") }}
                  </div>
                  <div class="col-md-8">
                    <input
                      v-model="discountAuthorizer"
                      :placeholder="$t('label.authorizesTheDiscount')"
                      class="form-control input-lg"
                      style="border-radius: 6px"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default btn-lg"
              @click="resetDiscountModal"
            >
              <i class="fas fa-history" />
              {{ $t("button.reset") }}
            </button>
            <button
              aria-label="Close"
              class="btn btn-lg btn-primary"
              data-dismiss="modal"
              type="button"
              @click="updateDiscountModal"
            >
              <i class="fas fa-edit" />
              {{ $t("button.update") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import payment from "@/mixins/payment"
import { mapState } from "vuex"
import DrawerCustomer from "@/components/DrawerCustomer"
import DisplayOrderInfo from "@/views/cashiers/payment/_components/DisplayOrderInfo"

export default {
  name: "Show",
  metaInfo () {
    return {
      title: this.$t("label.payment"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  mixins: [payment],
  components: {
    DisplayOrderInfo,
    DrawerCustomer
  },
  data () {
    return {
      paymentOptions: [],
      shop_type: process.env.VUE_APP_SHOP_TYPE,
      paymentOptionModel: null,
      products: null,
      paidRiel: 0,
      paidDollar: 0,
      discount: 0,
      discountAuthorizer: "",
      is_percentage: true,
      couponCode: null,
      couponPaymentSuccess: false,
      couponPaymentInfo: {
        amount: 0,
        currency: "",
        status: 0
      },
      order_from: this.$route.params.orderFrom,
      showSelectCustomer: false,
      shop_tax: null,
      order_uuid: null,
      order_number: null,
      message_paid: {
        paid: false,
        message_en: null,
        message_km: null,
        invoice_uuid: null
      }
    }
  },
  watch: {
    discount () {
      this.checkDiscountValue()
    },
    is_percentage () {
      this.checkDiscountValue()
    },
    paymentOptionModel () {
      this.couponCode = null
      this.couponPaymentInfo = {
        amount: 0,
        currency: "",
        status: 0
      }
    },
    amountTotal () {
      if (this.paymentOptionModel && this.paymentOptionModel.code === "coupon") {
        this.couponPaymentInfo = {
          amount: 0,
          currency: "",
          status: 0
        }
      }
    },
    couponPaymentInfo () {
      if (this.paymentOptionModel && this.paymentOptionModel.code === "coupon") {
        this.paidDollar = this.couponPaymentInfo.currency === "USD" ? this.couponPaymentInfo.amount : 0
        this.paidRiel = this.couponPaymentInfo.currency === "KHR" ? this.couponPaymentInfo.amount : 0
      }
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      selectedCustomer: state => state.order.selectedCustomer,
      currency: state => state.shop.currency,
      shop: state => state.shop.shop,
      exchangeRate: state => state.shop.exchange,
      order_discount: state => state.order.order_discount,
      dis_as_percentage: state => state.order.is_percentage,
      authorizer: state => state.order.discount_authorizer,
      device_for_sell: state => state.shop.device_for_sell
    }),
    amountTotal () {
      let amount = 0
      if (this.discount > 0) {
        this.$store.dispatch("order/setOrderDiscountStatus", this.is_percentage)
        this.emit("display_dis_percent", {
          display_dis_percent: this.is_percentage
        })
        this.$store.dispatch("order/setOrderDiscount", this.discount)
        this.emit("display_dis", {
          discount: this.discount
        })
        if (this.is_percentage) {
          amount = parseFloat(this.precise(((100 - this.discount) / 100) * this.subTotal))
        } else {
          amount = parseFloat(this.precise(this.subTotal - this.discount))
        }
      } else {
        amount = parseFloat(this.subTotal)
      }

      if (this.shop_tax) {
        const tax_value_percentage = parseFloat(this.shop_tax.amount_percentage)
        const tax_value = (amount * tax_value_percentage) / 100
        amount = parseFloat(amount) + parseFloat(tax_value)
      }
      if (this.selectedCustomer) {
        if (this.selectedCustomer.customer_membership.length) {
          const member = this.selectedCustomer.customer_membership[0]
          if (this.checkMembershipExpired(member)) {
            const value_percentage = parseFloat(member.amount_percentage)
            const value = (amount * value_percentage) / 100
            amount = parseFloat(amount) - parseFloat(value)
          }
        }
      }
      return amount
    },
    paidCompareWithTotal () {
      try {
        const display_balance = {
          paid_reil: this.paidRiel ? parseFloat(this.paidRiel) : 0,
          paid_dollar: this.paidDollar ? parseFloat(this.paidDollar) : 0
        }
        localStorage.setItem("display_balance", JSON.stringify(display_balance))
        this.emit("display_balance", {
          display_balance: display_balance
        })

        const amountTotal = this.amountTotal.toFixed(2)
        if (this.paidDollar && !this.paidRiel) {
          const d = parseFloat(this.paidDollar).toFixed(2)
          const a = this.exchangeMoney(this.currency.code, "USD", this.amountTotal).toFixed(2)
          if (d === a) {
            return 0
          } else {
            const paidDollar = this.exchangeMoney("USD", this.currency.code, this.paidDollar)
            return this.precise(paidDollar - amountTotal)
          }
        } else if (this.paidRiel && !this.paidDollar) {
          const r = parseFloat(this.paidRiel).toFixed(2)
          const a = this.exchangeMoney(this.currency.code, "KHR", this.amountTotal).toFixed(2)
          if (r === a) {
            return 0
          } else {
            const paidRiel = this.exchangeMoney("KHR", this.currency.code, this.paidRiel)
            return this.precise(paidRiel - amountTotal)
          }
        } else {
          const paidRiel = this.exchangeMoney("KHR", this.currency.code, this.paidRiel)
          const paidDollar = this.exchangeMoney("USD", this.currency.code, this.paidDollar)
          return this.precise((paidRiel + paidDollar) - amountTotal)
        }
      } catch (e) {
        return 0
      }
    },
    amountDue () {
      let amount_due = 0
      if (this.paidCompareWithTotal > 0) {
        amount_due = Math.abs(this.paidCompareWithTotal)
      }
      localStorage.setItem("display_change", amount_due)
      this.emit("display_change", {
        display_change: amount_due
      })
      return amount_due
    },
    amountOwe () {
      return this.paidCompareWithTotal >= 0 ? 0 : Math.abs(this.paidCompareWithTotal)
    },
    subTotal () {
      if (this.products) {
        let total = 0
        let valKhr = 0
        let valUsd = 0
        this.products.forEach((product) => {
          let result = 0
          // calculate product discount
          if (product.pivot && product.pivot.discount > 0) {
            if (product.pivot.is_percentage) {
              const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
              result = parseFloat(product.sale_price) - amount_discount
            } else {
              let amount_discount = parseFloat(product.pivot.discount)
              if (this.currency && product.currency && this.currency.code !== product.currency.code) {
                amount_discount = this.exchangeMoney(this.currency.code, product.currency.code, amount_discount)
              }
              result = parseFloat(product.sale_price) - amount_discount
            }
          } else {
            result = parseFloat(product.sale_price)
          }
          if (product.currency && product.currency.code === "USD") {
            valUsd = parseFloat(valUsd) + (parseFloat(product.pivot.quantity) * result)
          } else {
            valKhr = parseFloat(valKhr) + (parseFloat(product.pivot.quantity) * result)
          }

          const usd = this.exchangeMoney("USD", this.currency.code, valUsd)
          const khr = parseFloat(this.exchangeMoney("KHR", this.currency.code, valKhr))
          total = khr + usd
        })
        return parseFloat(total).toFixed(2)
      }
      return 0
    },
    hasAmountPaid () {
      return !!((this.paidDollar || this.paidRiel) && (this.paidDollar > 0 || this.paidRiel > 0))
    },
    checkUrl () {
      if (this.$route.params.orderFrom === "recent-sell") {
        return "/api/recent-sell/show"
      } else if (this.$route.params.orderFrom === "order") {
        return "/api/v2/payment/get-info"
      } else {
        return ""
      }
    },
    membershipDis () {
      if (this.selectedCustomer && this.selectedCustomer.customer_membership && this.selectedCustomer.customer_membership.length) {
        if (this.checkMembershipExpired(this.selectedCustomer.customer_membership[0])) {
          return this.selectedCustomer.customer_membership[0].amount_percentage
        }
      }
      return 0
    }
  },
  created () {
    window.addEventListener("storage", (event) => {
      if (event.storageArea !== localStorage) return
      if (event.key === this.t_d_s) {
        this.token_device = localStorage.getItem(this.t_d_s)
      }
    })
  },
  mounted () {
    this.checkTokenDeviceForSell()
    if (!this.device_for_sell) {
      this.$router.replace({ name: "dashboard" })
    }
    if (localStorage.hasOwnProperty(this.t_d_s)) {
      this.token_device = localStorage.getItem(this.t_d_s)
    }
    this.getFetchDataOptions()
    this.getPaymentInfo()
    this.getCustomer()
  },
  methods: {
    getCustomer () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/v2/order/get-customer", {
        order_uuid: this.$route.params.uuid
      }).then(({ data }) => {
        if (data.data) {
          this.$store.dispatch("order/setSelectedCustomer", data.data)
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    checkDiscountValue () {
      if (this.is_percentage) {
        if (this.discount < 0) {
          this.discount = 0
        } else if (this.discount > 100) {
          this.discount = 100
        }
      }
    },
    payAmountTotal (code_currency) {
      if (this.currency) {
        if (code_currency === "KHR") {
          this.paidRiel = Math.ceil(this.exchangeMoney(this.currency.code, "KHR", this.amountTotal))
          this.paidDollar = 0
        } else {
          const result = this.exchangeMoney(this.currency.code, "USD", this.amountTotal)
          if (result === 0) {
            this.paidDollar = result
          } else {
            this.paidDollar = result.toFixed(2)
          }
          this.paidRiel = 0
        }
      }
      const display_balance = {
        paid_reil: this.paidRiel ? parseFloat(this.paidRiel) : 0,
        paid_dollar: this.paidDollar ? parseFloat(this.paidDollar) : 0
      }
      localStorage.setItem("display_balance", JSON.stringify(display_balance))
      this.emit("display_balance", {
        display_balance: display_balance
      })
    },
    getFetchDataOptions () {
      this.$axios.post(this.$config.base_url + "/api/data-option/get-data-option-page-payment", {
        shop_uuid: this.shop.uuid
      })
        .then((res) => {
          this.paymentOptions = res.data.data.payment_options
          this.shop_tax = res.data.data.tax
        })
        .catch((error) => {
          this.getResponseError(error)
        })
    },
    closeDrawerCustomer () {
      this.showSelectCustomer = false
    },
    clearSelectCustomer () {
      this.$store.dispatch("order/setSelectCustomer", null)
      this.emit("display_sc", {
        customer: null
      })
    },
    onSubmitCoupon () {
      this.$axios.post(this.$config.base_url + "/api/coupon/check-coupon-status", {
        uuid: this.couponCode,
        shop_id: this.get_shop.id,
        required_amount: {
          USD: (this.currency.code === "USD" ? this.amountTotal : this.moneyEquivalent("KHR", this.amountTotal)).toFixed(2),
          KHR: (this.currency.code === "KHR" ? this.amountTotal : this.moneyEquivalent("USD", this.amountTotal)).toFixed(2)
        }
      }).then((response) => {
        if (response.data.code === 1) {
          this.couponPaymentInfo = {
            amount: response.data.data.required_amount,
            currency: response.data.data.coupon_currency,
            status: 1
          }
        } else {
          this.couponPaymentInfo = {
            amount: 0,
            currency: "",
            status: -1
          }
          this.$toastr("error", response.data.message, "Alert")
        }
      })
    },
    onConfirmPayment () {
      this.message_paid.paid = false
      if (this.order_from !== "recent-sell" && this.order_from !== "order") {
        this.$toastr("warning", this.$t("string.theUrlLinkIsIncorrect"), this.$t("string.warning"))
        this.$router.push({ name: "order-list" })
        return ""
      }

      if (this.paymentOptionModel && this.paymentOptionModel.code === "coupon" && this.couponPaymentInfo.status !== 1) {
        return this.$toastr("error", this.$t("string.invalidCoupon"), this.$t("string.error"))
      }
      if (parseFloat(this.amountOwe) > 0 && !this.hasAmountPaid) {
        this.$swal({
          title: this.$t("label.unpaid"),
          text: this.$t("string.unPainOrderYet"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f63547",
          confirmButtonText: this.$t("string.yesIAmSure"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          this.completeThePayment()
        }, (dismiss) => {
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          console.error(err)
          throw err
        })
      } else if (parseFloat(this.amountOwe) > 0 && this.hasAmountPaid) {
        this.$swal({
          title: this.$t("label.NotFullyPaid"),
          text: this.$t("string.unPainOrderYet"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f63547",
          confirmButtonText: this.$t("string.yesIAmSure"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          this.completeThePayment()
        }, (dismiss) => {
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          console.error(err)
          throw err
        })
      } else {
        this.completeThePayment()
      }
    },
    completeThePayment () {
      this.$isLoading(true)
      const data = {
        order_from: this.order_from,
        shop_uuid: this.$config.shop_uuid,
        shop_type: this.shop_type,
        uuid: this.order_uuid ? this.order_uuid : this.$route.params.uuid,
        payment_option_id: this.paymentOptionModel ? this.paymentOptionModel.id : null,
        is_owe: parseInt(this.amountOwe) > 0,
        amount_subtotal_dollar: (this.currency.code === "USD" ? parseFloat(this.subTotal).toFixed(2) : this.moneyEquivalent("KHR", this.subTotal).toFixed(2)),
        amount_subtotal_riel: (this.currency.code === "KHR" ? Math.ceil(this.subTotal) : Math.ceil(this.moneyEquivalent("USD", this.subTotal))),
        amount_discount: this.discount ? this.discount : 0,
        discount_authorizer: this.discount > 0 ? this.discountAuthorizer : null,
        is_percentage: this.is_percentage,
        amount_total_dollar: (this.currency.code === "USD" ? parseFloat(this.amountTotal).toFixed(2) : this.moneyEquivalent("KHR", this.amountTotal).toFixed(2)),
        amount_total_riel: (this.currency.code === "KHR" ? Math.ceil(this.amountTotal) : Math.ceil(this.moneyEquivalent("USD", this.amountTotal))),
        amount_paid_dollar: this.paidDollar ? this.paidDollar : 0,
        amount_paid_riel: this.paidRiel ? this.paidRiel : 0,
        amount_due_dollar: (this.currency.code === "USD" ? parseFloat(this.amountDue).toFixed(2) : this.moneyEquivalent("KHR", this.amountDue).toFixed(2)),
        amount_due_riel: (this.currency.code === "KHR" ? Math.ceil(this.amountDue) : Math.ceil(this.moneyEquivalent("USD", this.amountDue))),
        amount_owe_dollar: (this.currency.code === "USD" ? parseFloat(this.amountOwe).toFixed(2) : this.moneyEquivalent("KHR", this.amountOwe).toFixed(2)),
        amount_owe_riel: (this.currency.code === "KHR" ? Math.ceil(this.amountOwe) : Math.ceil(this.moneyEquivalent("USD", this.amountOwe))),
        token_device: this.token_device
      }
      if (this.selectedCustomer) {
        data.customer_uuid = this.selectedCustomer.uuid
      }
      if (this.paymentOptionModel && this.paymentOptionModel.code === "coupon") {
        data.coupon_code = this.couponCode
      }
      this.$axios
        .post(this.$config.base_url + "/api/v2/payment/confirm-payment", data)
        .then((response) => {
          this.$store.dispatch("order/setSelectCustomer", null)
          this.emit("display_sc", {
            customer: null
          })
          this.$router.push({
            name: "print-invoice",
            params: { uuid: response.data.data.uuid }
          })
        })
        .catch((error) => {
          if (error.hasOwnProperty("response")) {
            if (error.response.data.message && error.response.data.message.hasOwnProperty("paid")) {
              this.message_paid = error.response.data.message
            } else {
              this.getResponseError(error)
            }
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    onFocusInput (e) {
      e.target.select()
    },
    getSubtotal (item) {
      let subtotal = 0
      if (item.pivot.is_percentage) {
        subtotal = item.pivot.served_quantity * item.sale_price * (100 - item.pivot.discount) / 100
      } else {
        subtotal = (item.pivot.served_quantity * item.sale_price) - item.pivot.discount
      }

      return this.exchangeMoney(item.currency.code, this.currency.code, subtotal)
    },
    getPaymentInfo () {
      this.$axios.post(this.$config.base_url + this.checkUrl, {
        shop_uuid: this.shop.uuid,
        uuid: this.$route.params.uuid
      }).then((response) => {
        const result = response.data.data
        if (this.$route.params.orderFrom === "recent-sell") {
          if (result.order) {
            this.order_number = result.order.order_number
            this.order_uuid = result.order.uuid
            this.is_percentage = result.order.is_percentage
          }
          this.products = JSON.parse(result.items)
          this.discountAuthorizer = result.discount_authorizer
          this.discount = parseFloat(result.amount_discount) || 0
          if (result.data_info) {
            if (result.data_info.tax) {
              this.shop_tax = result.data_info.tax
            }
            if (result.data_info.payment_option) {
              this.paymentOptionModel = result.data_info.payment_option
            }
          }
          if (result.order && result.order.customer) {
            if (result.data_info && result.data_info.customer_membership) {
              this.$set(result.order.customer, "customer_membership", result.data_info.customer_membership)
            } else {
              this.$set(result.order.customer, "customer_membership", [])
            }
            this.$store.dispatch("order/setSelectCustomer", result.order.customer)
            this.emit("display_sc", {
              customer: result.order.customer
            })
          }
        } else {
          const order = result.order
          if (order) {
            this.order_number = result.order.order_number
            this.order_uuid = order.uuid
            this.discount = order.discount || 0
            // set discount, and authorized
            this.is_percentage = order.is_percentage
            this.discountAuthorizer = order.discount_authorizer
          }
          this.products = result.order.products
          this.paymentOptions = result.payment_options
          if (this.paymentOptions.length) {
            this.paymentOptionModel = this.paymentOptions[0]
          }
        }
        if (this.products && this.products.length) {
          this.products.map((item) => {
            if (item.is_custom_product && item.custom_product_price.length) {
              const custom_prices = item.custom_product_price
              custom_prices.forEach((sub_item) => {
                if (result.order.id === sub_item.order_id) {
                  item.sale_price = parseFloat(sub_item.price)
                }
              })
            }
            item = this.calcPromotion(item, item.promotions)
            return item
          })
        }
      })
    },

    openDiscountModal () {
      this.$store.dispatch("order/setOrderDiscountStatus", this.dis_as_percentage)
      this.emit("display_dis_percent", {
        display_dis_percent: this.dis_as_percentage
      })
      this.$store.dispatch("order/setOrderDiscount", parseFloat(this.order_discount))
      this.emit("display_dis", {
        discount: parseFloat(this.order_discount)
      })
      this.$store.dispatch("order/setDiscountAuthorizer", this.authorizer)
    },
    resetDiscountModal () {
      this.discount = 0
      this.discountAuthorizer = null
      this.is_percentage = true
      this.$store.dispatch("order/setOrderDiscountStatus", this.is_percentage)
      this.emit("display_dis_percent", {
        display_dis_percent: this.is_percentage
      })
      this.$store.dispatch("order/setOrderDiscount", this.discount)
      this.emit("display_dis", {
        discount: this.discount
      })
      this.$store.dispatch("order/setDiscountAuthorizer", this.discountAuthorizer)
    },
    updateDiscountModal () {
      this.$store.dispatch("order/setOrderDiscountStatus", this.is_percentage)
      this.emit("display_dis_percent", {
        display_dis_percent: this.is_percentage
      })
      this.$store.dispatch("order/setOrderDiscount", this.discount)
      this.emit("display_dis", {
        discount: this.discount
      })
      this.$store.dispatch("order/setDiscountAuthorizer", this.discountAuthorizer)
    }
  },
  destroyed () {
    window.removeEventListener("storage", (event) => {
      if (event.storageArea !== localStorage) return
      if (event.key === this.t_d_s) {
        this.token_device = localStorage.getItem(this.t_d_s)
      }
    })
  }

}
</script>

<style scoped>
.margin-b-8 {
  margin-bottom: 8px;
}

.alert-invoice-paid {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 5000;
  transition: all 2s ease-in-out;
  background-color: white;
  color: black;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15);
}

.alert-invoice-paid a {
  color: black;
}

.payment-container {
  height: 98%;
  width: 75%;
  box-shadow: 0 0 12px #08080817;
  margin: .5% auto 0;
}

.payment-info-container {
  display: flex;
  overflow: hidden;
}

.border-r {
  border-right: 1px solid #f3f2f2;
}

.fa-discount {
  font-size: 10px !important;
  line-height: 1.8rem !important;
}

.fa-discount .fa-stack-2x {
  font-size: 20px !important;
}

.fa-discount .fa-stack-1x {
  font-size: 8px !important;
  transform: rotate(135deg);
}

@media screen and (max-width: 1200px) {
  .payment-container {
    width: 100%;
    margin: 0;
    padding-bottom: unset;
    box-shadow: unset;
  }
}

@media screen and (max-width: 767px) {
  .block-payment-balance-content {
    padding: 8px 0 0 !important;
  }

  .payment-info-container {
    display: block;
    overflow: unset;
  }

  .payment-info-container .w-50 {
    width: 100% !important;
  }

  .payment-info-container .h-100.w-50 {
    height: auto !important;
  }
}
</style>
