<template>
  <div class="row">
    <div class="col-sm-12">
      <template v-if="selectedPlan && selectedPlan.code === plan_free_code">
        <SubscriptionFreePlanForm />
      </template>
      <template v-else>
        <SubscriptionChargePlanForm />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SubscriptionChargePlanForm from "@/views/settings/subscription/forms/SubscriptionChargePlanForm"
import SubscriptionFreePlanForm from "@/views/settings/subscription/forms/SubscriptionFreePlanForm"

export default {
  name: "SubscriptionUpgradePlan",
  components: {
    SubscriptionFreePlanForm,
    SubscriptionChargePlanForm
  },
  data () {
    return {
      plan_free_code: process.env.VUE_APP_SUBSCRIPTION_FREE_CODE
    }
  },
  computed: {
    ...mapGetters("subscription", {
      selectedPlan: "selectedPlan"
    })
  }
}
</script>

<style scoped>

</style>
