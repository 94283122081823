import Settings from "@/views/settings/Index"
import { taxRouters } from "@/routers/settings/taxRouters"
import { currencyRouters } from "@/routers/settings/currencyRouters"
import { exchangeRouters } from "@/routers/settings/exchangeRouters"
import { menuRouters } from "@/routers/settings/menuRouter"
import { paymentOptionRouters } from "@/routers/settings/paymentOptionRouters"
import { userRouters } from "@/routers/settings/userRouters"
import { restaurantRouters } from "@/routers/settings/restaurantRouters"
import { subscriptionRouters } from "@/routers/settings/subscriptionRouters"
import { invoiceTemplateRouters } from "@/routers/settings/invoiceTemplateRouters"
import { bannerImageRouters } from "@/routers/settings/bannerImageRouters"
import { additionalAppsRouters } from "@/routers/settings/additionalAppsRouters"

export const settingsRouters = {
  path: "/admin/settings",
  component: Settings,
  redirect: "/admin/settings/tax",
  children: [
    taxRouters,
    exchangeRouters,
    paymentOptionRouters,
    userRouters,
    restaurantRouters,
    bannerImageRouters,
    currencyRouters,
    menuRouters,
    subscriptionRouters,
    invoiceTemplateRouters,
    additionalAppsRouters
  ]
}
