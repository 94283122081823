<template>
  <div
    :id="'modalVariant' + modalId"
    ref="productVariantModalContainer"
    class="modal fade"
    data-backdrop="static"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div
        v-if="product"
        class="modal-content"
      >
        <div class="modal-header-custom">
          <h4 class="modal-title">
            {{ product.name }}
          </h4>
          <button
            ref="close"
            :class="class_btn_modal"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
            @click="onClickReset"
          >
            <i class="fas fa-times" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <div class="col-xs-5 col-sm-4">
                      <label class="font-s-16 text-capitalize font-weight-normal">{{ $t("label.price") }}</label>
                    </div>
                    <div class="col-xs-7 col-sm-8">
                      <template v-if="product_child_data">
                        <label class="font-s-16">
                          {{ product_child_data.currency ? product_child_data.currency.symbol : "$" }}
                          <template v-if="product_child_data.currency && product_child_data.currency.code === 'KHR'">
                            {{ product_child_data.sale_price | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ product_child_data.sale_price | numFormat(num_format) }}
                          </template>
                        </label><br>
                        <template v-if="product.has_promotion">
                          <del class="badge badge-danger badge-del-price">
                            <em>
                              {{ product_child_data.currency ? product_child_data.currency.symbol : "$" }}
                              <template
                                v-if="product_child_data.currency && product_child_data.currency.code === 'KHR'"
                              >
                                {{ product_child_data.origin_price | numFormat("0,0") }}
                              </template>
                              <template v-else>
                                {{ product_child_data.origin_price | numFormat(num_format) }}
                              </template>
                            </em>
                          </del>
                        </template>
                      </template>
                      <template v-else>
                        <label class="font-s-16 minus">
                          <span v-if="showChildrenSalePrice.min">
                            {{ product.currency ? product.currency.symbol : "$" }}
                            <template v-if="product.currency && product.currency.code === 'KHR'">
                              {{ showChildrenSalePrice.min | numFormat("0,0") }}
                            </template>
                            <template v-else>
                              {{ showChildrenSalePrice.min | numFormat(num_format) }}
                            </template>
                          </span>
                          <span v-if="showChildrenSalePrice.max">
                            {{ product.currency ? product.currency.symbol : "$" }}
                            <template v-if="product.currency && product.currency.code === 'KHR'">
                              {{ showChildrenSalePrice.max | numFormat("0,0") }}
                            </template>
                            <template v-else>
                              {{ showChildrenSalePrice.max | numFormat(num_format) }}
                            </template>
                          </span>
                        </label>
                        <br>
                        <template v-if="product.has_promotion">
                          <del class="font-s-16 badge badge-danger badge-del-price">
                            <em class="minus no-margin">
                              <span v-if="showChildrenOriginPrice.min">
                                {{ product.currency ? product.currency.symbol : "$" }}
                                <template v-if="product.currency && product.currency.code === 'KHR'">
                                  {{ showChildrenOriginPrice.min | numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ showChildrenOriginPrice.min | numFormat(num_format) }}
                                </template>
                              </span>
                              <span v-if="showChildrenOriginPrice.max">
                                {{ product.currency ? product.currency.symbol : "$" }}
                                <template v-if="product.currency && product.currency.code === 'KHR'">
                                  {{ showChildrenOriginPrice.max | numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ showChildrenOriginPrice.max | numFormat(num_format) }}
                                </template>
                              </span>
                            </em>
                          </del>
                        </template>
                      </template>
                    </div>
                  </div>
                  <template v-if="product_child_data">
                    <div class="form-group row">
                      <div class="col-xs-5 col-sm-4">
                        <label class="font-s-16 text-capitalize font-weight-normal">
                          {{ $t("label.amountInStock") }}
                        </label>
                      </div>
                      <div class="col-xs-7 col-sm-8">
                        <template>
                          <label
                            v-if="product_child_data.has_inventory"
                            :class="{'text-red': product_child_data.amount_in_stock <= 0}"
                            class="font-weight-normal"
                          >
                            <span class="font-s-18 text-bold">
                              {{
                                product_child_data.amount_in_stock | numFormat(isFloat(product_child_data.amount_in_stock))
                              }}
                            </span>
                            <span class="margin-l-5 font-s-14">{{
                              product_child_data.unit ? product_child_data.unit["name_" + $i18n.locale] : null
                            }}</span>
                          </label>
                          <label
                            v-else
                            class="font-s-16"
                          >{{ $t("label.unlimited") }}</label>
                        </template>
                      </div>
                    </div>
                    <div
                      v-if="!product_child_data.active"
                      class="form-group row"
                    >
                      <div class="col-xs-5 col-sm-4">
                        <label class="font-s-16 text-capitalize font-weight-normal">
                          {{ $t("label.status") }}
                        </label>
                      </div>
                      <div class="col-xs-7 col-sm-8">
                        <label class="font-s-16 has-error">{{ $t("label.thisProductIsInactive") }}</label>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-md-12">
                  <div
                    v-if="product.images.length || (product_child_data && product_child_data.images.length)"
                    class="image-product_row w-100"
                  >
                    <template v-if="product_child_data">
                      <template v-if="product_child_data.images.length">
                        <div
                          v-for="(image, key) in product_child_data.images"
                          :key="key"
                          class="image-product_column"
                        >
                          <div class="thumbnail no-margin">
                            <img
                              :src="`${$config.base_url}/${replaceImgUrl(image.src, 'thumbnail')}`"
                              width="100%"
                            >
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div
                          v-for="(image, key) in product.images"
                          :key="key"
                          class="image-product_column"
                        >
                          <div class="thumbnail no-margin">
                            <img
                              :src="`${$config.base_url}/${replaceImgUrl(image.src, 'thumbnail')}`"
                              width="100%"
                            >
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="product.images.length">
                        <div
                          v-for="(image, key) in product.images"
                          :key="key"
                          class="image-product_column"
                        >
                          <div class="thumbnail no-margin">
                            <img
                              :src="`${$config.base_url}/${replaceImgUrl(image.src, 'thumbnail')}`"
                              width="100%"
                            >
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div
                    v-if="alert_message"
                    class="alert alert-danger alert-dismissible"
                    role="alert"
                  >
                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="alert"
                      type="button"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <label>{{ alert_message["message_" + $i18n.locale] }}</label>
                  </div>
                </div>
              </div>
              <div
                v-if="product && product.variants"
                class="row"
              >
                <template v-for="(item, each_item_key) in product.variants">
                  <div
                    class="col-sm-12"
                    :key="each_item_key"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <label class="font-s-16 text-capitalize font-weight-normal">
                          {{ item.name["name_" + $i18n.locale] }} :
                        </label>
                      </div>
                      <div class="col-md-12 margin-b-15">
                        <div class="list-category list-product-variant scrollbar-x h-auto no-padding">
                          <div
                            v-for="(sub_item, each_sub_item_key) in item.values"
                            :key="each_sub_item_key"
                            :class="{'active': variantTypeActive(sub_item)}"
                            class="list-category-item popup-category-item-variant rounded on-page-order"
                            @click="onClickVariant(sub_item)"
                          >
                            <div
                              class="position-relative d-flex align-items-center justify-content-center flex-column h-100"
                            >
                              <div class="position-relative text-center popup-category-item-content__label">
                                <label class="list-category-label no-margin font-s-15 text-ellipsis-2-line">
                                  {{ sub_item.pivot.value }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <template v-if="product_child_data">
                <div class="row">
                  <div class="col-md-6">
                    <div
                      :class="{'has-error': validation && validation.hasOwnProperty('quantity')}"
                      class="form-group"
                    >
                      <label class="font-s-16 text-capitalize font-weight-normal required">
                        {{ $t("label.quantity") }}
                      </label>
                      <div :class="{'input-group': product_child_data.unit}">
                        <input
                          v-model="selected_product_child_qty"
                          class="form-control"
                          min="1"
                          type="number"
                        >
                        <span
                          v-if="product_child_data.unit"
                          class="input-group-addon"
                        >
                          {{ product_child_data.unit["name_" + $i18n.locale] }}
                        </span>
                      </div>
                      <template v-if="validation && validation.hasOwnProperty('quantity')">
                        <span>{{ validation.quantity[0] }}</span>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="product_child_data && product_child_data.active"
          class="modal-footer"
        >
          <button
            class="btn btn-success btn-lg"
            type="button"
            @click="checkSelectedVariant(false)"
          >
            <i class="fas fa-plus" /> {{ $t("button.addToCart") }}
          </button>
          <button
            class="btn btn-primary btn-lg"
            type="button"
            @click="checkSelectedVariant(true)"
          >
            <i class="fas fa-plus" /> {{ $t("button.addToCartAndClose") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "SelectProductVariant",
  props: {
    modalId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: ""
    },
    product: {
      type: Object,
      default: null
    },
    checkVariant: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      product_child_data: null,
      alert_message: null,
      selected_product_child_qty: 1,
      validation: null
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      selectedVariant: state => state.order.selected_variants,
      orderProducts: state => state.order.orderProducts
    }),
    showChildrenSalePrice () {
      if (this.product.children_sale_price && this.product.children_sale_price.length > 0) {
        const max = Math.max(...this.product.children_sale_price)
        const min = Math.min(...this.product.children_sale_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.sale_price,
            min: null
          }
        }
      }
      return {
        max: this.product.sale_price,
        min: null
      }
    },
    showChildrenOriginPrice () {
      if (this.product.children_origin_price && this.product.children_origin_price.length > 0) {
        const max = Math.max(...this.product.children_origin_price)
        const min = Math.min(...this.product.children_origin_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.origin_price,
            min: null
          }
        }
      }
      return {
        max: this.product.origin_price,
        min: null
      }
    }
  },
  methods: {
    onClickReset () {
      this.validation = null
      this.alert_message = null
      this.$store.dispatch("order/clearSelectedVariant")
      this.$store.dispatch("order/clearSelectedProduct")
      this.product_child_data = null
    },
    showModal () {
      this.validation = null
      this.alert_message = null
      this.product_child_data = null
      $(this.$refs.productVariantModalContainer).modal("show")
    },
    hideModal () {
      this.validation = null
      this.alert_message = null
      $(this.$refs.productVariantModalContainer, ".close").click()
    },
    toggleModal () {
      this.validation = null
      this.alert_message = null
      $(this.$refs.productVariantModalContainer).modal("toggle")
      this.product_child_data = null
    },
    variantTypeActive (variant) {
      if (this.selectedVariant.length) {
        return this.selectedVariant.includes(variant)
      }
      return false
    },
    checkSelectedVariant (isClose = false) {
      this.alert_message = null
      this.validation = null
      if (this.selectedVariant.length === Object.entries(this.product.variants).length) {
        if (this.selected_product_child_qty && this.selected_product_child_qty > 0) {
          if (this.product_child_data) {
            this.$store.dispatch("order/addProductWithQtyToCart", {
              product: this.product_child_data,
              qty: this.selected_product_child_qty
            })

            const amountInStock = this.product_child_data.amount_in_stock
            const amount = parseFloat(amountInStock) - parseFloat(this.selected_product_child_qty)
            this.$set(this.product_child_data, "amount_in_stock", amount)

            this.$store.dispatch("order/setDisplayPo", this)
            if (isClose) {
              this.$store.dispatch("order/clearSelectedVariant")
              $(this.$refs.productVariantModalContainer).modal("toggle")
            }
          }
        } else if (this.selected_product_child_qty && this.selected_product_child_qty <= 0) {
          this.validation = {
            quantity: ["The quantity must be at least 1."]
          }
        } else {
          this.validation = {
            quantity: ["The quantity field is required."]
          }
        }
      } else {
        this.alert_message = {
          message_en: "Please select all options before adding to the cart.",
          message_km: "សូមជ្រើសរើសជំរើសទាំងអស់មុននឹងដាក់ចូលកន្ត្រក។"
        }
      }
    },
    onClickVariant (variant_item) {
      this.$store.dispatch("order/setSelectedVariant", variant_item)
      this.selected_product_child_qty = 1
      if (this.selectedVariant.length === Object.entries(this.product.variants).length) {
        this.product_child_data = null
        const a = []
        this.selectedVariant.forEach((item) => {
          a.push(item.pivot.value)
        })
        const pro = this.product.children.find((item) => {
          const pro_variants = item.product_variants
          switch (pro_variants.length) {
          case 1:
            if (a.includes(pro_variants[0].pivot.value)) {
              return item
            }
            break
          case 2:
            if (a.includes(pro_variants[0].pivot.value) && a.includes(pro_variants[1].pivot.value)) {
              return item
            }
            break
          case 3:
            if (a.includes(pro_variants[0].pivot.value) && a.includes(pro_variants[1].pivot.value) && a.includes(pro_variants[2].pivot.value)) {
              return item
            }
            break
          case 4:
            if (a.includes(pro_variants[0].pivot.value) && a.includes(pro_variants[1].pivot.value) && a.includes(pro_variants[2].pivot.value) && a.includes(pro_variants[3].pivot.value)) {
              return item
            }
            break
          default:
            return null
          }
        })
        if (pro) {
          if (this.orderProducts.length) {
            let p = null
            p = this.orderProducts.find((item) => {
              return item.id === pro.id
            })
            if (p) {
              this.$set(pro, "amount_in_stock", parseFloat(pro.initAmountInStock) - parseFloat(pro.pivot.quantity))
            } else {
              this.$set(pro, "amount_in_stock", pro.initAmountInStock)
            }
          } else {
            this.$set(pro, "amount_in_stock", pro.initAmountInStock)
          }
        }
        this.$set(this, "product_child_data", pro)
      }
    }
  }
}
</script>

<style scoped>
.product-has-variant .icon {
  font-size: 20px;
}

.image-product_row {
  content: "";
  clear: both;
  display: table;
}

.image-product_row .image-product_column {
  float: left;
  width: 16.66%;
  padding: 0 5px 5px 0;
}
</style>
