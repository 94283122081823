import reportIndex from "@/views/reports/Index"
import reportDashboardIndex from "@/views/reports/dashboard/Index.vue"
import productSaleIndex from "@/views/reports/product-sale/Index"
import productSaleList from "@/views/reports/product-sale/List"
import reportMonthlyIndex from "@/views/reports/report-monthly/Index"
import incomeIndex from "@/views/reports/income/Index"
import SaleHistory from "@/views/reports/product-sale/SaleHistory"
import { inventoryTransactionHistory } from "@/routers/blankRouters/reportRouters"

export const reportRouters = {
  path: "/admin/reports",
  component: reportIndex,
  redirect: "/admin/reports/report-dashboard",
  children: [
    {
      path: "report-dashboard",
      name: "report-dashboard",
      component: reportDashboardIndex
    },
    {
      path: "product-sale",
      component: productSaleIndex,
      redirect: "/admin/reports/product-sale",
      children: [
        {
          path: "",
          name: "list-product-sale",
          component: productSaleList
        }
      ]
    },
    {
      path: "report-monthly",
      name: "list-report-monthly",
      component: reportMonthlyIndex
    },
    {
      path: "income",
      name: "list-income",
      component: incomeIndex
    },
    {
      path: "list-sale-history/:uuid/:start_date/:end_date",
      name: "list-sale-history",
      component: SaleHistory
    },
    inventoryTransactionHistory
  ]
}
