<template>
  <div
    v-if="user"
    class="row"
  >
    <div class="col-md-12 tw-mb-8">
      <div class="row">
        <card>
          <template slot="header">
            <h3 class="tw-font-bold tw-text-2xl tw-m-0 tw-p-0">
              {{ $t("profile.sidebar.overview") }}
            </h3>
          </template>
          <div class="row">
            <div class="col-md-6">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>{{ $t("profile.field.label.firstName") }}</td>
                    <td>
                      <strong>{{ user.first_name || $t("string.na") }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("profile.field.label.lastName") }}</td>
                    <td>
                      <strong>{{ user.last_name || $t("string.na") }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("profile.field.label.email") }}</td>
                    <td>
                      <strong>{{ user.email || $t("string.na") }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("string.roles") }}</td>
                    <td>
                      <strong>{{ userRoles || $t("string.na") }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6">
              <profile-upload-avatar />
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <card>
          <template slot="header">
            <h3 class="tw-font-bold tw-text-2xl tw-m-0 tw-p-0">
              {{ $t("profile.label.resetPassword") }}
            </h3>
          </template>
          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-info alert-dismissible">
                <button
                  aria-hidden="true"
                  class="close"
                  data-dismiss="alert"
                  type="button"
                >
                  ×
                </button>
                <h4>
                  <i class="fas fa-info-circle" /> {{ $t("resetPassword.title") }}
                </h4>
                <p class="margin-top">
                  {{ $t("resetPassword.intro") }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div
                class="form-group row"
                :class="{'has-error' : validations.old_password}"
              >
                <label class="required control-label col-md-4">
                  {{ $t("profile.field.label.oldPassword") }}
                </label>
                <div class="col-md-8">
                  <input
                    v-model="reset.old_password"
                    :placeholder="$t('profile.field.placeholder.oldPassword')"
                    class="form-control"
                    type="password"
                  >
                  <span
                    v-if="validations.old_password"
                    class="has-feedback"
                  >
                    {{ validations.old_password[0] }}
                  </span>
                </div>
              </div>

              <div
                class="form-group row"
                :class="{'has-error' : validations.password}"
              >
                <label class="required control-label col-md-4">
                  {{ $t("profile.field.label.newPassword") }}
                </label>
                <div class="col-md-8">
                  <input
                    v-model="reset.password"
                    :placeholder="$t('profile.field.placeholder.newPassword')"
                    class="form-control"
                    type="password"
                  >
                  <span
                    v-if="validations.password"
                    class="has-feedback"
                  >
                    {{ validations.password[0] }}
                  </span>
                </div>
              </div>

              <div
                class="form-group row"
                :class="{'has-error' : validations.password_confirmation}"
              >
                <label class="required control-label col-md-4">
                  {{ $t("profile.field.label.confirmationPassword") }}
                </label>
                <div class="col-md-8">
                  <input
                    v-model="reset.password_confirmation"
                    :placeholder="$t('profile.field.placeholder.confirmationPassword')"
                    class="form-control"
                    type="password"
                  >
                  <span
                    v-if="validations.password_confirmation"
                    class="has-feedback"
                  >
                    {{ validations.password_confirmation[0] }}
                  </span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-offset-4 col-md-8">
                  <button
                    class="btn btn-primary"
                    @click="resetPassword"
                  >
                    {{ $t("button.resetPassword") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileUploadAvatar from "./Upload"
import Card from "@/components/Card"

export default {
  name: "Overview",
  components: {
    Card,
    ProfileUploadAvatar
  },
  metaInfo () {
    return {
      title: this.$t("profile.sidebar.overview"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      user: null,
      reset: {
        old_password: "",
        password: "",
        password_confirmation: null
      },
      validations: {}
    }
  },
  computed: {
    userRoles () {
      if (this.user && this.user.roles && Array.isArray(this.user.roles) && this.user.roles.length > 0) {
        return this.user.roles.join(", ")
      }
      return null
    }
  },
  methods: {
    resetPassword () {
      this.validations = {}
      this.$axios.post(this.$config.base_url + "/api/user/profile-reset-password", this.reset)
        .then((response) => {
          if (response.data.code === 1 && response.data.data !== null) {
            this.$toastr("info", this.$t("string.thePasswordHasBeenReset"), this.$t("string.resetPassword"))
            this.reset = {
              old_password: "",
              password: ""
            }
            this.validations = {}
          } else if (response.data.code === 2) {
            this.validations = {}
            this.$toastr("info", response.data.message, "Reset Password")
          } else {
            this.validations = response.data.message
          }
        })
    }
  },
  mounted () {
    if (localStorage.hasOwnProperty("user") && localStorage.getItem("user") !== null) {
      this.user = JSON.parse(localStorage.getItem("user"))
    }
  }
}
</script>

<style scoped>

</style>
