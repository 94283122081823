<template>
  <div class="h-100">
    <div class="subscription-content-mobile scrollbar">
      <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-2">
          <SubscriptionSidebar />
        </div>
        <div class="col-sm-9 col-md-9 col-lg-10">
          <template v-if="target === 'subscription'">
            <SubscriptionPlans />
          </template>
          <template v-else-if="target === 'payment_method'">
            <SubscriptionPaymentMethods />
          </template>
          <template v-else-if="target === 'invoices'">
            <SubscriptionInvoices />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import SubscriptionPlans from "@/views/settings/subscription/includes/SubscriptionPlans"
import SubscriptionSidebar from "@/views/settings/subscription/includes/SubscriptionSidebar"
import SubscriptionInvoices from "@/views/settings/subscription/includes/SubscriptionInvoices"
import SubscriptionPaymentMethods from "@/views/settings/subscription/includes/SubscriptionPaymentMethods"

export default {
  name: "SubscriptionList",
  components: {
    SubscriptionInvoices,
    SubscriptionPaymentMethods,
    SubscriptionPlans,
    SubscriptionSidebar
  },
  metaInfo () {
    return {
      title: this.$t("menu.subscription"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  computed: {
    ...mapGetters("subscription", {
      target: "menuActive"
    })
  },
  methods: {
    ...mapActions("subscription", {
      switchMenu: "switchMenu",
      setStep: "setStep"
    })
  },
  beforeDestroy () {
    this.switchMenu("subscription")
    this.setStep(1)
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .subscription-content-mobile {
    max-height: calc(100vh - 150px);
    min-height: calc(100vh - 150px);
    overflow-y: auto;
  }
}
</style>
