import Index from "@/views/order/Index"
import Show from "@/views/order/Show"
import Order from "@/views/order/Order.vue"

export const orderRouters = {
  path: "/order",
  component: Index,
  redirect: "",
  children: [
    {
      path: "show/:uuid/:orderFrom",
      name: "order-show",
      component: Order
    },
    {
      path: "pre-receipt/:uuid",
      name: "show-order",
      component: Show
    }
  ]
}
