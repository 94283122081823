<template>
  <div class="vh-100">
    <router-view />
    <AppBottomBar />
  </div>
</template>

<script>
import { VueOfflineMixin } from "vue-offline"
import { offlineMixins } from "@/mixins/offline"
import { shopMixin } from "@/mixins/shop"
import AppBottomBar from "@/components/AppBottomBar"
import { mapGetters, mapState } from "vuex"

export default {
  name: "App",
  components: { AppBottomBar },
  mixins: [VueOfflineMixin, offlineMixins, shopMixin],
  computed: {
    ...mapState({
      user: state => state.user.user,
      shop: state => state.shop.shop,
      plan: state => state.shop.plan,
      shop_currency: state => state.shop.currency,
      shop_exchange_rate: state => state.shop.exchange
    }),
    ...mapGetters({
      is_expired: "shop/is_expired"
    }),
    disablesRouteReplace () {
      const routes = [
        "second-customer-display",
        "display-order"
      ]
      return routes.includes(this.$route.name)
    },
    isLoadingOptions () {
      return {
        slot: `
        <div class="d-flex align-items-center justify-content-center text-white">
          <h3><i class="fas fa-circle-notch fa-spin"></i> <span class="font-weight-bold">${this.$t("string.loading")}</span></h3>
        </div>
      `
      }
    }
  },
  watch: {
    "$i18n.locale" () {
      this.$changeIsLoadingOptions(this.isLoadingOptions)
    }
  },
  beforeMount () {
    this.$changeIsLoadingOptions(this.isLoadingOptions)

    if (this.is_expired && localStorage.hasOwnProperty("shop")) {
      this.$router.replace({ name: "dashboard" })
    }
    if (!this.shop && localStorage.hasOwnProperty("shop")) {
      const shop = JSON.parse(localStorage.getItem("shop"))
      if (shop) {
        this.setShopProperty(shop)
      }
    }
    if (!this.plan && localStorage.hasOwnProperty("plan")) {
      const plan = JSON.parse(localStorage.getItem("plan"))
      if (plan) {
        this.$store.dispatch("shop/setPlan", plan)
      }
    }
    if (!localStorage.hasOwnProperty(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE) || localStorage.getItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE) === "undefined") {
      localStorage.setItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE, this.generate_token(32))
    }

    if (!this.user && localStorage.hasOwnProperty("user")) {
      this.$store.dispatch("user/updateUser", JSON.parse(localStorage.getItem("user")))
    }

    if (!this.shop_currency && localStorage.hasOwnProperty("currency")) {
      this.$store.dispatch("shop/setCurrency", JSON.parse(localStorage.getItem("currency")))
    }

    if (!this.shop_exchange_rate && localStorage.hasOwnProperty("exchange")) {
      this.$store.dispatch("shop/setExchangeRate", JSON.parse(localStorage.getItem("exchange")))
    }

    if (!localStorage.hasOwnProperty("view_mode")) {
      this.changeViewModeProductOrder("grid")
    } else {
      this.$store.dispatch("order/updateViewMode", localStorage.getItem("view_mode"))
    }

    if (!localStorage.hasOwnProperty("sell_mode")) {
      this.changeSellMode("normal")
    } else {
      this.$store.dispatch("sell/setSellMode", localStorage.getItem("sell_mode"))
    }
  },
  methods: {
    async setLocale () {
      return new Promise(resolve => {
        const tds = localStorage.getItem("t.d.s")
        if (tds && tds === "null") {
          localStorage.setItem("t.d.s", this.generate_token(32))
        }
        const locale = localStorage.getItem("locale")
        if (locale && locale !== "en" && locale !== "km") {
          localStorage.setItem("locale", process.env.VUE_APP_I18N_LOCALE)
          this.$axios.defaults.headers.common["X-localization"] = process.env.VUE_APP_I18N_LOCALE
        } else {
          this.$axios.defaults.headers.common["X-localization"] = locale
        }
        resolve()
      })
    }
  },
  async mounted () {
    await this.setLocale()

    this.$on("offline", () => {
      if (this.$route.name !== "display-order") {
        localStorage.setItem("display_po", JSON.stringify([]))
        this.emit("display_po", { products: [] })
        if (!this.disablesRouteReplace) {
          this.$router.replace({ name: "dashboard" })
        }
      }
    })

    this.$on("online", () => {
      if (this.$route.name !== "display-order") {
        localStorage.setItem("display_po", JSON.stringify([]))
        this.emit("display_po", { products: [] })
        if (!this.disablesRouteReplace) {
          this.$router.replace({ name: "dashboard" })
          this.syncOfflineData()
        }
      }
    })
  }
}
</script>

<style scoped>

</style>
