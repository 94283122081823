<template>
  <warehouse-form />
</template>

<script>

import WarehouseForm from "./Form"

export default {
  name: "CreateNewWarehouse",
  components: { WarehouseForm },
  metaInfo () {
    return {
      title: this.$t("string.newWarehouse"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
