<template>
  <div class="row">
    <div class="col-md-12">
      <div
        :style="products.length > 0 ? '': 'min-height: 788px'"
        class="box box-primary"
      >
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.showMenu") }}
          </h3>
          <div
            v-if="products.length > 0"
            class="box-tools"
          >
            <router-link
              :to="{name: 'menu-edit', params: {uuid: this.$route.params.uuid}}"
              class="btn btn-primary margin-r-5 btn-sm"
            >
              <i class="fas fa-edit" /> {{ $t("button.edit") }}
            </router-link>
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div
              v-if="menu"
              class="row no-margin"
            >
              <div class="col-xs-12 text-center">
                <h1>{{ menu.name ? menu.name : $t("string.na") }}</h1>
                <template v-if="menu.start && menu.end">
                  <label>
                    Time: {{ $moment(menu.start, "hh:mm:ss").format("hh:mm A") }}
                    ~ {{ $moment(menu.end, "hh:mm:ss").format("hh:mm A") }}
                  </label>
                </template>
              </div>
              <div
                v-if="products.length > 0"
                class="col-xs-12"
              >
                <div class="row margin-bottom">
                  <div class="col-xs-12 margin-bottom">
                    <div class="col-xs-6 no-padding" />
                    <div class="col-xs-6 no-padding">
                      <div
                        class="btn-group pull-right"
                        role="group"
                      >
                        <button
                          :class="changeLayout === 'grid' ? 'btn btn-info' : 'btn btn-default'"
                          type="button"
                          @click="changeLayout = 'grid'"
                        >
                          <i class="fas fa-th-large" />
                        </button>
                        <button
                          :class="changeLayout === 'list' ? 'btn btn-info' : 'btn btn-default'"
                          type="button"
                          @click="changeLayout = 'list'"
                        >
                          <i class="fas fa-bars" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row no-margin product-list">
                    <template v-if="changeLayout === 'list'">
                      <template v-for="(product, productIndex) in products">
                        <div
                          class="col-xs-12 col-sm-6 col-lg-4 margin-bottom"
                          :key="productIndex"
                          style="display: inline-grid"
                        >
                          <div class="product-list-block">
                            <div class="product-list-block-left">
                              <template
                                v-if="product.hasOwnProperty('images') && product.images.length > 0"
                              >
                                <img
                                  :src="`${$config.base_url}/${product.images[0].src}`"
                                  class="product-image-style_list"
                                  alt="Product Image"
                                >
                              </template>
                              <template v-else>
                                <img
                                  class="img-responsive"
                                  src="/img/food_icon.png"
                                  alt="Product Image"
                                >
                              </template>
                            </div>
                            <div class="product-list-block-right product-content_list">
                              <p class="product-title">
                                {{ product.name }}
                              </p>
                              <div class="product-content_list_label">
                                <label>{{ parseFloat(product.sale_price).toFixed(2) }}{{
                                  product.currency.symbol
                                }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-for="(product, productIndex) in products">
                        <div
                          class="col-xs-4 col-sm-3 col-lg-2 margin-bottom no-padding"
                          :key="productIndex"
                        >
                          <div
                            :key="`productIndex${productIndex}`"
                            class="product"
                          >
                            <div class="product-image">
                              <template
                                v-if="product.hasOwnProperty('images') && product.images.length > 0"
                              >
                                <img
                                  :src="`${$config.base_url}/${product.images[0].src}`"
                                  class="product-image-style"
                                  alt="Product Image"
                                >
                              </template>
                              <template v-else>
                                <img
                                  class="img-responsive"
                                  src="/img/food_icon.png"
                                  alt="Product Image"
                                >
                              </template>
                            </div>
                            <div class="menu-product-content">
                              <p class="product-title">
                                {{ product.name }}
                              </p>
                              <label>{{ parseFloat(product.sale_price).toFixed(2) }}{{
                                product.currency.symbol
                              }}</label>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <a
                      class="btn btn-default margin-r-5 btn-sm"
                      @click="$router.go(-1)"
                    >
                      <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
                    </a>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="col-xs-12"
              >
                <div
                  class="row"
                  style="text-align: center"
                >
                  <div
                    class="col-xs-12"
                    style="margin: 35px 0"
                  >
                    <img
                      :src="img_empty"
                      alt="image empty"
                      style="width: 30%;"
                    >
                  </div>
                  <div class="col-xs-12 margin-bottom">
                    <label style="font-size: 16pt; font-style: italic">{{ $t("menu.label.noProduct") }}</label>
                  </div>
                  <div class="col-xs-12 margin-bottom">
                    <div class="form-group">
                      <a
                        class="btn btn-default margin-r-5"
                        @click="$router.back()"
                      >
                        <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
                      </a>
                      <router-link
                        :to="{name: 'menu-edit', params: {uuid: this.$route.params.uuid}}"
                        class="btn btn-success"
                      >
                        <i class="fas fa-plus-circle" /> {{ $t("button.addProduct") }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuShow",
  metaInfo () {
    return {
      title: this.$t("label.showMenu"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      menu: null,
      products: [],
      changeLayout: "list",
      config: this.$config,
      listProducts: [],
      img_empty: require("./../../../assets/icon/empty.svg")
    }
  },
  methods: {
    getMenu (uuid) {
      this.$isLoading(true)
      const self = this
      this.message_validation = null
      this.$axios
        .post(this.$config.base_url + "/api/menu/show", {
          shop_uuid: this.$config.shop_uuid,
          uuid: uuid
        })
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1 && response.data.data !== null) {
            self.menu = response.data.data
            self.products = response.data.data.products
          } else if (response.data.code === 0) {
            this.$swal({
              type: "error",
              title: this.$t("swal.title.havingProblem"),
              text: response.data.message
            })
          }
        })
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getMenu(this.$route.params.uuid)
    }
  }
}
</script>

<style scoped>

.product-list {
  overflow-y: auto;
  height: 523px;
  width: 100%;
}

.product {
  margin: 0 auto;
  width: 85%;
  border-radius: 6px;
  cursor: auto;
}

.product-image {
  justify-content: center;
  width: 100%;
}

.product-image > img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.menu-product-content {
  text-align: center;
  padding: 5px 10px;
  background-color: #eee;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.menu-product-content > p {
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 0;
}

.menu-product-content > label {
  color: #5e5e5e;
  margin-bottom: 5px;
  font-weight: 300;
}

/********************************For Product List************************************/
.product-image-style_list {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  width: 100%;
}

.product-content_list {
  position: relative;
  padding: 15px 10px 10px 15px;
  background-color: #eee;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.product-content_list_label {
  bottom: 0;
  position: absolute;
}

.product-list-block {
  background-color: #eee;
  position: relative;
  border-radius: 6px;
  display: flex;
}

.product-list-block-left {
  width: 25%;
  float: left;
}

.product-list-block-right {
  width: 75%;
  float: right;
}

.product-content_list > .product-title {
  white-space: normal;
  overflow: hidden;
  font-size: 15pt;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-weight: bold;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (max-width: 500px) {
  .product-content_list {
    padding: 5px 10px 5px 10px;
  }

  .product-content_list > .product-title {
    font-size: 13pt;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .product-content_list_label {
    font-size: 17pt;
  }
}

/* Small devices (tablets, 768px and down) */
@media (min-width: 768px) {
  .product-content_list {
    padding: 5px 10px;
  }

  .product-content_list > .product-title {
    font-size: 13pt;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .product-content_list {
    padding: 10px;
  }

  .product-content_list > .product-title {
    font-size: 19pt;
  }

  .product-content_list_label {
    font-size: 15pt;
  }
}

/* Medium devices (desktops, 1200px and up) */
@media (min-width: 1200px) {
  .product-content_list {
    padding: 5px 10px 5px 10px;
  }

  .product-content_list > .product-title {

    font-size: 15pt;
  }
}

/* Medium devices (desktops, 1455px and up) */
@media (min-width: 1455px) {
  .product-content_list > .product-title {
    font-size: 19pt;
  }

  .product-content_list_label {
    font-size: 17pt;
  }
}

</style>
