<template>
  <IndexLayout
    :btn-title="btn_title"
    :is-not-route-list="isNotRouteList"
    :route-name-create="route_name_create"
    :title="title"
  >
    <template :slot="'header-button'">
      <a
        v-if="isNotRouteList"
        class="btn btn-default btn-lg no-margin"
        @click="$router.back()"
      >
        <i class="fas fa-arrow-left back" />
      </a>
    </template>
  </IndexLayout>
</template>

<script>

import IndexLayout from "@/components/layout/IndexLayout"

export default {
  name: "InvoiceTemplateIndex",
  components: { IndexLayout },
  computed: {
    isNotRouteList () {
      return this.$route.name !== "list-invoice-template"
    }
  },
  data () {
    return {
      route_name_create: "create-invoice-template",
      title: "menu.settings.invoiceTemplate",
      btn_title: "button.newInvoiceTemplate"
    }
  }
}
</script>
