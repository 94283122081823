<template>
  <div class="row">
    <card>
      <template slot="header">
        <div class="pull-left">
          <h3 class="card-header-title">
            {{ $t("string.orderList") }}
          </h3>
        </div>
        <div class="pull-right">
          <back-button />
          <template v-if="$route.name === 'offline-cashier-order-list'">
            <router-link
              :to="{name: 'offline-sale'}"
              class="btn btn-primary"
            >
              <i class="fas fa-plus" /> {{ $t("string.newOrder") }}
            </router-link>
          </template>
        </div>
      </template>
      <table
        id="orders-table"
        class="table table-striped table-bordered table-condensed"
      >
        <thead>
          <tr>
            <th>{{ $t("string.cashier") }}</th>
            <th>{{ $t("string.discount") }}</th>
            <th>{{ $t("string.authorized") }}</th>
            <th>{{ $t("string.issuedDate") }}</th>
            <th>{{ $t("string.status") }}</th>
            <th style="width: 170px !important;">
              {{ $t("string.action") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="orders.length>0">
            <tr
              v-for="(order, key) in orders"
              :key="key"
            >
              <td>
                <template v-if="order.cashier!==null">
                  {{ order.cashier.full_name }}
                </template>
                <template v-else>
                  {{ $t("string.na") }}
                </template>
              </td>
              <td>{{ order.discount || $t("string.na") }}</td>
              <td>{{ order.authorized || $t("string.na") }}</td>
              <td>{{ $moment(order.created_at).format("DD/MM/YYYY HH:mm:ss A") }}</td>
              <td>
                <template v-if="order.is_paid">
                  <span class="label label-primary">{{ $t("string.paid") }}</span>
                </template>
                <template v-else>
                  <span class="label label-warning">{{ $t("string.unpaid") }}</span>
                </template>
              </td>
              <td>
                <template v-if="order.is_paid">
                  <router-link
                    :to="{
                      name: 'offline-print-invoice',
                      params: {
                        order_uuid: order.uuid,
                      }
                    }"
                    class="btn btn-primary margin-r-5"
                  >
                    <i class="fas fa-search" /> {{ $t("string.view") }}
                  </router-link>
                </template>
                <template v-else>
                  <router-link
                    :to="{
                      name: 'offline-sale',
                      params: {
                        order_uuid: order.uuid,
                      }
                    }"
                    class="btn btn-info margin-r-5"
                  >
                    <i class="fas fa-check-circle" /> {{ $t("string.checkout") }}
                  </router-link>
                  <button
                    class="btn btn-danger"
                    @click="deleteOrder(order)"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7">
                <p class="text-center text-muted">
                  {{ $t("string.noRecords") }}
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </card>
  </div>
</template>

<script>
import indexedb from "@/indexedb"
import Card from "@/components/Card"
import BackButton from "@/components/BackButton"

export default {
  name: "OfflineOrderList",
  metaInfo () {
    return {
      title: this.$t("string.orderList"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    BackButton,
    Card
  },
  data () {
    return {
      orders: [],
      oTable: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.getOrders()
    }
  },
  methods: {
    deleteOrder (order) {
      const uuid = order.uuid
      this.$swal({
        title: this.swalTitle,
        text: this.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.swalYesButton,
        cancelButtonText: this.swalNoButton
      }).then(() => {
        indexedb.remove("orders", uuid)
          .then(() => {
            this.onResponseSuccess()
            this.getOrders()
          })
          .then(() => {
            indexedb.remove("order_products", uuid)
          })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },
    getOrders () {
      this.oTable = null
      indexedb.all("orders")
        .then((orders) => {
          this.orders = orders
        })
    }
  },
  mounted () {
    this.getOrders()
  }
}
</script>

<style scoped>

</style>
