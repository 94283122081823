<template>
  <div
    v-if="product && !product.is_custom_product"
    class="product-each-item user-select-none rounded-tr rounded-tl"
  >
    <div
      :class="addClassNames ? addClassNames : ''"
      class="product rounded-tr rounded-tl"
      @click="checkProductHavVariant(product)"
    >
      <div class="product-each-item__top rounded-tr rounded-tl position-relative">
        <template v-if="product.hasOwnProperty('images') && product.images.length > 0">
          <div
            :style="`background-image: url('${$config.base_url}/${replaceImgUrl(product.images[0].src, 'thumbnail')}')`"
            class="product-each-item__image rounded-tr rounded-tl"
          />
        </template>
        <template v-else>
          <div
            :style="`background-image: url('${defaultImg}')`"
            class="product-each-item__image image_w-65 rounded-tr rounded-tl"
          />
        </template>
        <template v-if="checkVariant">
          <div class="product-has-variant">
            <i class="fas fa-plus-square icon" />
          </div>
        </template>
        <div
          :class="'stock-' + checkStock"
          class="product-amount-in-stock"
        >
          <div
            v-if="product.has_inventory"
            class="user-select-none margin-bottom-none"
          >
            {{ calcAmountInStock | numFormat(isFloat(parseFloat(calcAmountInStock))) }}
            <span class="font-weight-normal font-s-14 margin-r-5">{{
              product.unit ? product.unit["name_" + $i18n.locale] : null
            }}</span>
          </div>
          <div
            v-else
            class="margin-bottom-none"
          >
            {{ $t("label.unlimited") }}
          </div>
        </div>
      </div>
      <div class="product-each-item__content">
        <div class="product-each-item__name text-ellipsis-2-line">
          {{ product.name }}
        </div>
        <template v-if="product.category">
          <div class="font-s-12 text-muted text-capitalize text-ellipsis">
            {{ product.category["name_" + $i18n.locale] }}
          </div>
        </template>
        <template v-else>
          <div style="height: 17px" />
        </template>
        <template v-if="product.sale_price">
          <div class="text-ellipsis">
            <template v-if="product.has_promotion">
              <del class="badge badge-danger badge-del-price max-w-100">
                <em class="minus no-margin">
                  <span v-if="showChildrenOriginPrice.min">
                    {{ product.currency ? product.currency.symbol : "$" }}
                    <template v-if="product.currency && product.currency.code === 'KHR'">
                      {{ showChildrenOriginPrice.min | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ showChildrenOriginPrice.min | numFormat(num_format) }}
                    </template>
                  </span>
                  <span v-if="showChildrenOriginPrice.max">
                    {{ product.currency ? product.currency.symbol : "$" }}
                    <template v-if="product.currency && product.currency.code === 'KHR'">
                      {{ showChildrenOriginPrice.max | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ showChildrenOriginPrice.max | numFormat(num_format) }}
                    </template>
                  </span>
                </em>
              </del>
            </template>
            <template v-else>
              <div style="height: 20px" />
            </template>
          </div>
          <div class="text-bold font-s-16 pro-p">
            <label class="minus no-margin text-ellipsis">
              <span v-if="showChildrenSalePrice.min">
                {{ product.currency ? product.currency.symbol : "$" }}
                <template v-if="product.currency && product.currency.code === 'KHR'">
                  {{ showChildrenSalePrice.min | numFormat("0,0") }}
                </template>
                <template v-else>
                  {{ showChildrenSalePrice.min | numFormat(num_format) }}
                </template>
              </span>
              <span v-if="showChildrenSalePrice.max">
                {{ product.currency ? product.currency.symbol : "$" }}
                <template v-if="product.currency && product.currency.code === 'KHR'">
                  {{ showChildrenSalePrice.max | numFormat("0,0") }}
                </template>
                <template v-else>
                  {{ showChildrenSalePrice.max | numFormat(num_format) }}
                </template>
              </span>
            </label>
          </div>
        </template>
      </div>
    </div>

    <SelectProductVariant
      ref="productVariantModal"
      :check-variant="checkVariant"
      :modal-id="product.id + ''"
      :product="product"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import SelectProductVariant from "@/components/product/SelectProductVariant"

export default {
  name: "AsoraProductItemGrid",
  components: { SelectProductVariant },
  props: {
    product: {
      type: Object,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    addClassNames: {
      type: String
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      order_products: state => state.order.orderProducts
    }),
    checkStock () {
      if (this.product && this.product.has_inventory) {
        if (parseFloat(this.product.initAmountInStock) <= 0) {
          return "danger"
        } else if (parseFloat(this.product.initAmountInStock) < parseFloat(this.product.stock_alert_level) && this.product.amount_in_stock > 0) {
          return "warning"
        }
      }
      return "success"
    },
    checkVariant () {
      return !!(this.product && this.product.children && this.product.children.length)
    },
    showChildrenSalePrice () {
      if (this.product.children_sale_price && this.product.children_sale_price.length > 0) {
        const max = Math.max(...this.product.children_sale_price)
        const min = Math.min(...this.product.children_sale_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.sale_price,
            min: null
          }
        }
      }
      return {
        max: this.product.sale_price,
        min: null
      }
    },
    showChildrenOriginPrice () {
      if (this.product.children_origin_price && this.product.children_origin_price.length > 0) {
        const max = Math.max(...this.product.children_origin_price)
        const min = Math.min(...this.product.children_origin_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.origin_price,
            min: null
          }
        }
      }
      return {
        max: this.product.origin_price,
        min: null
      }
    },
    calcAmountInStock () {
      let op = 0
      if (this.order_products.length) {
        this.order_products.forEach((item) => {
          if (item.parent_id && item.parent_id === this.product.id) {
            op += parseFloat(item.pivot.quantity)
          } else if (item.id === this.product.id) {
            op += parseFloat(item.pivot.quantity)
          }
        })
      }
      const pa = this.product.amount_in_stock
      const total = parseFloat(pa) + parseFloat(op)
      const re = parseFloat(total) - parseFloat(op)
      this.$set(this.product, "initAmountInStock", re)
      return this.product.initAmountInStock
    }
  },
  data () {
    return {
      origin_url: process.env.VUE_APP_ORIGIN_URL
    }
  },
  methods: {
    addToCart (product) {
      this.$store.dispatch("order/addProductToCart", product)
      this.$store.dispatch("order/setDisplayPo", this)
    },
    checkProductHavVariant (product) {
      if (this.checkVariant) {
        this.$refs.productVariantModal.toggleModal()
      } else {
        this.addToCart(product)
      }
    }
  }
}
</script>

<style scoped>
.pro-p {
  padding: 6px 0 6px;
}
</style>
