<template>
  <PromotionForm :title="$t('label.newPromotion')" />
</template>

<script>
import PromotionForm from "@/views/inventories/promotion/Form"

export default {
  name: "PromotionCreate",
  metaInfo () {
    return {
      title: this.$t("label.newPromotion"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: { PromotionForm }
}
</script>

<style scoped>

</style>
