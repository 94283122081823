<template>
  <IndexLayout
    :btn-title="btn_title"
    :hidden-button="true"
    :is-not-route-list="true"
    :route-name-create="route_name_create"
    :title="title"
  />
</template>

<script>
import IndexLayout from "@/components/layout/IndexLayout"

export default {
  name: "SubscriptionIndex",
  components: { IndexLayout },
  data () {
    return {
      route_name_create: "",
      title: "menu.subscription",
      btn_title: ""
    }
  },
  created () {
    if (this.$bill_active !== "true") {
      this.$router.replace({ name: "dashboard" })
    }
  }
}
</script>

<style scoped>

</style>
