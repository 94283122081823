<template>
  <div>
    <div v-if="barcodes && barcodes.length > 0">
      <div class="no-print margin-bottom container-print">
        <button
          class="btn btn-sm btn-primary"
          @click="onClickPrint"
        >
          <i class="fas fa-print" /> {{ $t("button.print") }}
        </button>
        <button
          class="btn btn-sm btn-default"
          @click="$router.back()"
        >
          <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
        </button>
      </div>
      <barcode-list
        :barcodes="barcodes"
        :has-background="true"
        :preview-only="true"
      />
      <div
        v-if="totalPage > 0"
        class="no-print container-print"
      >
        <paginate
          v-model="page"
          :click-handler="getProductToPrintBarcode"
          :container-class="'pagination'"
          :margin-pages="2"
          :next-text="$t('button.next')"
          :page-count="totalPage"
          :page-range="2"
          :prev-text="$t('button.prev')"
        />
      </div>
    </div>
    <div v-else>
      <div>
        <div class="no-print margin-bottom container-print">
          <button
            class="btn btn-sm btn-default"
            @click="$router.back()"
          >
            <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
          </button>
        </div>
        <div class="no-barcode-found bg-white no-print">
          <div class="d-block">
            <p class="text-center no_result-title">
              <strong>{{ $t("label.product_no_barcode") }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarcodeList from "./../../../components/BarcodeList.vue"
import Paginate from "vuejs-paginate"

export default {
  name: "ProductBarcode",
  metaInfo () {
    return {
      title: this.$t("page.printProductBarcode"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    BarcodeList,
    Paginate
  },
  data () {
    return {
      page: 1,
      totalPage: 0,
      numberPerPage: 30,
      barcodes: [],
      id: null
    }
  },
  methods: {
    async getProductToPrintBarcode (page) {
      this.$isLoading(true)
      this.page = page
      if (this.$route.params.uuid) {
        this.uuid = this.$route.params.uuid
      } else {
        this.id = "all"
      }
      this.$axios.post(this.$config.base_url + "/api/product/get-product-to-print-barcode", {
        shop_id: this.get_shop.id,
        uuid: this.uuid,
        page: this.page,
        number_per_page: this.numberPerPage
      }).then((response) => {
        if (response.data.code === 1) {
          this.barcodes = response.data.data
          if (response.data.total_page > 0) this.totalPage = response.data.total_page

          if (this.barcodes.length === 1) {
            for (let i = 1; i < this.numberPerPage; i++) {
              this.barcodes.push(this.barcodes[i - 1])
            }
          }
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    onClickPrint () {
      window.print()
    }
  },
  async mounted () {
    await this.getProductToPrintBarcode(this.page).then(() => {
      const delayRenderView = () => new Promise(resolve => setTimeout(resolve, 1000))
      delayRenderView().then(() => {
        if (this.barcodes.length > 0) {
          window.print()
        }
      })
    })
  }
}
</script>

<style scoped>
.container-print {
  width: 15.7cm;
  margin-right: auto;
  margin-left: auto;
  text-align: right;
}

.no-barcode-found {
  width: 15.7cm;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
