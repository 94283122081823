<template>
  <div
    v-if="shop"
    class="d-flex w-100 h-100"
  >
    <div class="tw-flex tw-bg-white tw-w-full">
      <template v-if="isOffline">
        <div class="d-flex flex-column payment-container w-100 border-l">
          <div class="d-flex align-items-center justify-content-center h-100">
            <div class="d-block w-100 text-center">
              <template v-if="logo_base64">
                <div
                  class="w-10 mb-5"
                  style="margin: 0 auto;"
                >
                  <img
                    :src="logo_base64"
                    alt="Shop log"
                    width="100%"
                  >
                </div>
              </template>
              <template v-else>
                <div class="no_logo_base64 mb-5">
                  <svg
                    aria-hidden="true"
                    class="svg-inline--fa fa-wifi-slash fa-w-20 fa-9x"
                    data-icon="wifi-slash"
                    data-prefix="fas"
                    focusable="false"
                    role="img"
                    viewBox="0 0 640 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class=""
                      d="M5.09 154.87c-6.66 6.16-6.79 16.59-.35 22.97l34.24 33.96c6.14 6.09 16.02 6.23 22.4.38 6.99-6.4 14.31-12.22 21.65-18.01l-64.96-50.21c-4.3 3.71-8.79 7.04-12.98 10.91zm471.75 181.9l45.42-45.21c6.52-6.46 6.29-17.06-.57-23.17-64.94-57.74-148.91-82.66-230.34-74.98l-83.16-64.27c125.94-38.36 267.75-11.01 370.43 83.05 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.96c6.44-6.39 6.3-16.82-.35-22.97C496.46 26.82 298.08-.76 133.42 71.35L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.03-2.81-22.45L476.84 336.77zm-358.53-68.38c-6.86 6.1-7.08 16.7-.57 23.17l34.28 34.01c5.97 5.93 15.59 6.32 21.94.8 13.35-11.6 28.01-20.66 43.15-28.55l-68.36-52.83c-10.48 7.15-20.74 14.78-30.44 23.4zM256 416c0 35.35 28.65 64 64 64 31.91 0 58.15-23.42 62.99-53.98l-88.7-68.56C271.77 367.37 256 389.82 256 416z"
                      fill="#d1d1d1"
                    />
                  </svg>
                </div>
              </template>
              <div>
                <strong class="font-s-32">{{ $t("label.secondDisplayNotSupported") }}</strong>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="cover-block align-items-center scrollbar overflow-y-auto justify-content-center">
          <template v-if="renderComponent">
            <template v-if="shop.media && shop.media.length">
              <template v-if="shop.media.length > 1">
                <slider
                  ref="slider"
                  :options="slideOptions"
                >
                  <slideritem
                    v-for="(cover, index) in shop.media"
                    :key="index"
                  >
                    <img
                      :src="`${base_url}/${cover.src}`"
                      alt="Side image"
                      class="tw-w-full tw-h-full tw-object-center tw-object-cover lg:tw-w-full lg:tw-h-full"
                    >
                  </slideritem>
                </slider>
              </template>
              <template v-else>
                <template v-for="(cover, key) in shop.media">
                  <img
                    :key="key"
                    :src="`${base_url}/${cover.src}`"
                    alt="Side image"
                    class="tw-w-full tw-h-full tw-object-center tw-object-cover lg:tw-w-full lg:tw-h-full"
                  >
                </template>
              </template>
            </template>
            <template v-else>
              <img
                :src="`${defaultBannerImage}`"
                alt="Side image"
                class="tw-w-full tw-h-full tw-object-center tw-object-cover lg:tw-w-full lg:tw-h-full"
              >
            </template>
          </template>
        </div>
        <div class="d-flex flex-column payment-container border-l">
          <template v-if="show_mode === 'show_welcome'">
            <div class="d-flex align-items-center justify-content-center h-100">
              <div class="d-block w-100 text-center">
                <div
                  class="w-20"
                  style="margin: 0 auto 16px"
                >
                  <img
                    :src="shop.logo_base64"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <strong class="text-capitalize font-s-32">{{ $t("strings.welcomeTo") }} {{ shop.name }}</strong>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="show_mode === 'show_list'">
            <div class="rounded-tl rounded-tr pt-3 pl-3 pr-3 shop-logo-and-name">
              <div class="shop-logo">
                <p style="margin: 0">
                  <img
                    id="logo"
                    :src="shop.logo_base64"
                    alt="Shop logo"
                    style="max-width: 75px; max-height: 75px;"
                  >
                </p>
              </div>
              <div>
                <p class="margin-bottom-none font-s-18">
                  <strong>{{ shop.name }}</strong>
                </p>
                <p class="margin-bottom-none">
                  {{ shop.address }} | {{ shop.tel }}
                </p>
              </div>
            </div>
            <div class="flex-1 payment-info-container rounded-tl rounded-br">
              <DisplayOrderInfo
                :amount-change="display_change"
                :amount-total="amountTotal"
                :balance="display_balance"
                :dic-is-percentage="is_percentage"
                :discount-value="discount"
                :exchange-rate="exchangeRate"
                :hide-in-xs="false"
                :membership-discount="membershipDis"
                :products="products"
                :shop-tax="shop_tax"
                :sub-total="subTotal"
                class="w-100"
              />
            </div>
          </template>
          <template v-else-if="show_mode === 'show_thank_you'">
            <div class="d-flex align-items-center justify-content-center h-100">
              <div class="d-block w-100 text-center">
                <div
                  class="w-20"
                  style="margin: 0 auto 16px"
                >
                  <img
                    :src="shop.logo_base64"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <strong class="text-capitalize font-s-32">{{ $t("strings.thankYouForComing") }}!</strong>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import DisplayOrderInfo from "@/views/cashiers/payment/_components/DisplayOrderInfo"
import { slider, slideritem } from "vue-concise-slider"
import { VueOfflineMixin } from "vue-offline"
import { offlineMixins } from "@/mixins/offline"

export default {
  name: "DisplayOrderToCustomer",
  components: {
    DisplayOrderInfo,
    slider,
    slideritem
  },
  metaInfo () {
    return {
      title: this.$t("label.displayOrder"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  mixins: [VueOfflineMixin, offlineMixins],
  data () {
    return {
      renderComponent: false,
      base_url: process.env.VUE_APP_API,
      message_paid: {
        paid: false,
        message_en: null,
        message_km: null,
        invoice_uuid: null
      },
      shop_tax: null,
      products: [],
      selectedCustomer: null,
      discount: 0,
      is_percentage: true,
      show_mode: "show_welcome",
      display_balance: null,
      display_change: 0,
      slideOptions: {
        direction: "horizontal",
        effect: "slide",
        autoplay: 4000,
        loop: true,
        pagination: true,
        thresholdDistance: 500,
        thresholdTime: 100,
        currentPage: 0,
        loopedSlides: 1,
        slidesToScroll: 1,
        timingFunction: "ease",
        speed: 300
      },
      locale: localStorage.getItem("locale") ? localStorage.getItem("locale") : "en",
      logo_base64: localStorage.getItem("logo_base64")
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      currency: state => state.shop.currency,
      shop: state => state.shop.shop,
      exchangeRate: state => state.shop.exchange
    }),
    amountTotal () {
      let amount = 0
      if (this.discount > 0) {
        if (this.is_percentage) {
          amount = parseFloat(this.precise(((100 - this.discount) / 100) * this.subTotal))
        } else {
          amount = parseFloat(this.precise(this.subTotal - this.discount))
        }
      } else {
        amount = parseFloat(this.subTotal)
      }

      if (this.shop_tax) {
        const tax_value_percentage = parseFloat(this.shop_tax.amount_percentage)
        const tax_value = (amount * tax_value_percentage) / 100
        amount = parseFloat(amount) + parseFloat(tax_value)
      }
      if (this.selectedCustomer) {
        if (this.selectedCustomer.customer_membership && this.selectedCustomer.customer_membership.length) {
          const member = this.selectedCustomer.customer_membership[0]
          if (this.checkMembershipExpired(member)) {
            const value_percentage = parseFloat(member.amount_percentage)
            const value = (amount * value_percentage) / 100
            amount = parseFloat(amount) - parseFloat(value)
          }
        }
      }
      return amount
    },
    paidCompareWithTotal () {
      try {
        const amountTotal = this.amountTotal.toFixed(2)
        const paidRiel = this.exchangeMoney("KHR", this.currency.code, this.paidRiel)
        const paidDollar = this.exchangeMoney("USD", this.currency.code, this.paidDollar)
        return this.precise((paidRiel + paidDollar) - amountTotal)
      } catch (e) {
        return 0
      }
    },
    subTotal () {
      if (this.products) {
        let total = 0
        let valKhr = 0
        let valUsd = 0
        this.products.forEach((product) => {
          let result = 0
          // calculate product discount
          if (product.pivot && product.pivot.discount > 0) {
            if (product.pivot.is_percentage) {
              const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
              result = parseFloat(product.sale_price) - amount_discount
            } else {
              const amount_discount = parseFloat(product.pivot.discount)
              result = parseFloat(product.sale_price) - amount_discount
            }
          } else {
            result = parseFloat(product.sale_price)
          }
          if (product.currency && product.currency.code === "USD") {
            valUsd = parseFloat(valUsd) + (parseFloat(product.pivot.quantity) * result)
          } else {
            valKhr = parseFloat(valKhr) + (parseFloat(product.pivot.quantity) * result)
          }

          const usd = this.exchangeMoney("USD", this.currency.code, valUsd)
          const khr = parseFloat(this.exchangeMoney("KHR", this.currency.code, valKhr))
          total = khr + usd
        })
        return parseFloat(total).toFixed(2)
      }
      return 0
    },
    hasAmountPaid () {
      return !!((this.paidDollar || this.paidRiel) && (this.paidDollar > 0 || this.paidRiel > 0))
    },
    checkUrl () {
      if (this.$route.params.orderFrom === "recent-sell") {
        return "/api/recent-sell/show"
      } else if (this.$route.params.orderFrom === "order") {
        return "/api/v2/payment/get-info"
      } else {
        return ""
      }
    },
    membershipDis () {
      if (this.selectedCustomer && this.selectedCustomer.customer_membership && this.selectedCustomer.customer_membership.length) {
        if (this.checkMembershipExpired(this.selectedCustomer.customer_membership[0])) {
          return this.selectedCustomer.customer_membership[0].amount_percentage
        }
      }
      return 0
    }
  },
  created () {
    window.addEventListener("storage", (event) => {
      if (event.storageArea !== localStorage) return
      if (event.key === "locale") {
        this.locale = localStorage.getItem("locale")
        this.changeLocale(this.locale)
      }
      if (event.key === "display_order_mode") {
        this.show_mode = localStorage.getItem("display_order_mode")
      }
      if (event.key === "display_po") {
        this.show_mode = "show_list"
        if (localStorage.hasOwnProperty("display_po") && localStorage.getItem("display_po")) {
          this.products = JSON.parse(localStorage.getItem("display_po"))
        }
      }
      if (event.key === "display_sc") {
        this.selectedCustomer = JSON.parse(localStorage.getItem("display_sc"))
      }
      if (event.key === "display_dis") {
        this.discount = JSON.parse(localStorage.getItem("display_dis"))
      }
      if (event.key === "display_dis_percent") {
        this.is_percentage = JSON.parse(localStorage.getItem("display_dis_percent"))
      }
      if (event.key === "tax") {
        this.shop_tax = JSON.parse(localStorage.getItem("tax"))
      }
      if (event.key === "display_balance") {
        this.display_balance = JSON.parse(localStorage.getItem("display_balance"))
      }
      if (event.key === "display_change") {
        this.display_change = parseFloat(localStorage.getItem("display_change"))
      }
      if (event.key === "display_banner_image") {
        this.display_banner_image = JSON.parse(localStorage.getItem("display_banner_image"))
        this.renderComponent = false
        this.$nextTick(() => {
          if (this.display_banner_image) {
            const banImg = this.display_banner_image
            if (banImg.media) {
              this.$store.dispatch("shop/setMedia", banImg.media)
            }
            if (banImg.carousel_direction) {
              this.$set(this.slideOptions, "direction", banImg.carousel_direction)
            }
            if (banImg.carousel_effect) {
              this.$set(this.slideOptions, "effect", banImg.carousel_effect)
            }
            if (banImg.carousel_timeout) {
              this.$set(this.slideOptions, "autoplay", banImg.carousel_timeout)
            }
            if (banImg.hasOwnProperty("carousel_loop")) {
              this.$set(this.slideOptions, "loop", banImg.carousel_loop)
            }
            if (banImg.hasOwnProperty("carousel_pagination")) {
              this.$set(this.slideOptions, "pagination", banImg.carousel_pagination)
            }
          }
          this.renderComponent = true
        })
      }
    })
  },
  mounted () {
    this.getData()
    this.$on("offline", () => {
      this.renderComponent = false
    })

    this.$on("online", () => {
      this.renderComponent = true
      this.getData()
      this.show_mode = "show_welcome"
    })
  },
  methods: {
    changeLocale (locale) {
      this.$i18n.locale = locale
      this.$axios.defaults.headers.common["X-localization"] = locale
    },
    getData () {
      if (localStorage.hasOwnProperty("locale") && localStorage.getItem("locale")) {
        this.locale = localStorage.getItem("locale")
      }
      if (localStorage.hasOwnProperty("display_order_mode") && localStorage.getItem("display_order_mode")) {
        this.show_mode = localStorage.getItem("display_order_mode")
      }
      if (localStorage.hasOwnProperty("display_po") && localStorage.getItem("display_po")) {
        this.products = JSON.parse(localStorage.getItem("display_po"))
      }
      if (localStorage.hasOwnProperty("display_sc") && localStorage.getItem("display_sc")) {
        this.selectedCustomer = JSON.parse(localStorage.getItem("display_sc"))
      }
      if (localStorage.hasOwnProperty("display_dis") && localStorage.getItem("display_dis")) {
        this.discount = JSON.parse(localStorage.getItem("display_dis"))
      }
      if (localStorage.hasOwnProperty("display_dis_percent") && localStorage.getItem("display_dis_percent")) {
        this.is_percentage = JSON.parse(localStorage.getItem("display_dis_percent"))
      }
      if (localStorage.hasOwnProperty("tax") && localStorage.getItem("tax")) {
        this.shop_tax = JSON.parse(localStorage.getItem("tax"))
      }
      if (localStorage.hasOwnProperty("display_balance") && localStorage.getItem("display_balance")) {
        this.display_balance = JSON.parse(localStorage.getItem("display_balance"))
      }
      if (localStorage.hasOwnProperty("display_change") && localStorage.getItem("display_change")) {
        this.display_change = parseFloat(localStorage.getItem("display_change"))
      }
      if (localStorage.hasOwnProperty("display_banner_image") && localStorage.getItem("display_banner_image")) {
        this.display_banner_image = JSON.parse(localStorage.getItem("display_banner_image"))
        this.renderComponent = false
        this.$nextTick(() => {
          if (this.display_banner_image) {
            const banImg = this.display_banner_image
            if (banImg.media) {
              this.$store.dispatch("shop/setMedia", banImg)
            }
            if (banImg.carousel_direction) {
              this.$set(this.slideOptions, "direction", banImg.carousel_direction)
            }
            if (banImg.carousel_effect) {
              this.$set(this.slideOptions, "effect", banImg.carousel_effect)
            }
            if (banImg.carousel_timeout) {
              this.$set(this.slideOptions, "autoplay", banImg.carousel_timeout)
            }
            if (banImg.hasOwnProperty("carousel_loop")) {
              this.$set(this.slideOptions, "loop", banImg.carousel_loop)
            }
            if (banImg.hasOwnProperty("carousel_pagination")) {
              this.$set(this.slideOptions, "pagination", banImg.carousel_pagination)
            }
          }
          this.renderComponent = true
        })
      }
    }
  }
}
</script>

<style scoped>
@import "../../../assets/css/displayToCustomer.css";
</style>
