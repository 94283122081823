<template>
  <header class="main-header">
    <router-link
      class="logo"
      to="/dashboard"
    >
      <span class="logo-mini">
        <i class="fas fa-home" />
      </span>
      <RestaurantName />
    </router-link>

    <nav
      class="navbar navbar-static-top"
      role="navigation"
    >
      <a
        class="sidebar-toggle"
        data-toggle="push-menu"
        href="#"
        role="button"
      >
        <span class="sr-only">Toggle navigation</span>
      </a>
      <div class="navbar-custom-menu">
        <p class="navbar-text">
          {{ show_date }}
        </p>
        <ul class="nav navbar-nav">
          <li class="dropdown">
            <a
              aria-expanded="false"
              aria-haspopup="true"
              class="dropdown-toggle nav-color nav-icon-size"
              data-toggle="dropdown"
              href="javascript:void(0)"
              @click="changeLocale"
            >
              <img
                :src="`/flag/${$i18n.locale === 'km' ? 'en' : 'kh'}.png`"
                alt="Flag Image"
                style="height: 16px; width: 26px;"
              >
            </a>
          </li>
          <li>
            <span class="nav-color nav-bar-size">|</span>
          </li>
          <dropdown-user-profile
            :display-dropdown="false"
          />
          <li>
            <a
              class="nav-color"
              @click="logout"
            >
              <i class="fas fa-sign-out-alt mr-2" /> {{ $t("label.logout") }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import RestaurantName from "@/components/RestaurantName"
import DropdownUserProfile from "@/components/DropdownUserProfile"

export default {
  name: "Header",
  components: {
    DropdownUserProfile,
    RestaurantName
  },
  methods: {
    changeLocale () {
      let locale = process.env.VUE_APP_I18N_LOCALE
      if (localStorage.getItem("locale")) {
        locale = localStorage.getItem("locale")
      }

      locale = locale === "km" ? "en" : "km"

      this.$i18n.locale = locale
      localStorage.setItem("locale", locale)
      this.$axios.defaults.headers.common["X-localization"] = locale
    }
  },
  destroyed () {
    $("body").removeClass("sidebar-collapse")
  },
  mounted () {
    $("body").addClass("sidebar-collapse")
  }
}
</script>

<style scoped>
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    font-size: 25px;
  }
}

.skin-blue .main-header .logo {
  background-color: var(--gray-secondary);
}

.skin-blue .main-header .logo:hover {
  background-color: #343351;
}

.skin-blue .main-header .navbar {
  background-color: var(--white);
}

.skin-blue .main-header .navbar .nav > li > .nav-color {
  color: var(--gray);
}

.skin-blue .main-header .navbar .nav > li > .nav-icon-size {
  font-size: 16px;
}

.skin-blue .main-header .navbar .nav > li > .nav-bar-size {
  font-size: 30px;
  opacity: 0.2;
}

.skin-blue .main-header .navbar .nav > li > .nav-name-color {
  color: var(--gray-dark);
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: var(--gray-very-light);
}

.skin-blue .main-header .navbar .sidebar-toggle {
  color: var(--gray);
}

.skin-blue .main-header .logo {
  color: var(--white);
}
</style>
