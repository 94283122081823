<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("string.stockAlertControl") }}
            </h3>
          </div>
          <div class="box-body">
            <table
              id="stock-alert-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("food.table.image") }}</th>
                  <th>{{ $t("food.table.name") }}</th>
                  <th>{{ $t("food.table.name") }}</th>
                  <th>{{ $t("food.table.productType") }}</th>
                  <th>{{ $t("food.table.productCategory") }}</th>
                  <th>{{ $t("food.table.stockAlertLevel") }}</th>
                  <th>{{ $t("food.table.productStock") }}</th>
                  <th>{{ $t("food.table.salePrice") }}</th>
                  <th>{{ $t("food.table.updatedAt") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <StockAlertControlDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>
import StockAlertControlDataTableSm
from "@/views/inventories/stockAlertControl/_components/StockAlertControlDataTableSm"

export default {
  name: "StockAlertControlList",
  components: { StockAlertControlDataTableSm },
  metaInfo () {
    return {
      title: this.$t("string.stockAlertControl"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      oTable: null,
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    windowWidth: function () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    dataTable () {
      const self = this
      return $("#stock-alert-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        pageLength: 100,
        scrollX: true,
        ajax: {
          url: this.$config.base_url + "/api/product/get-inventory-orders",
          type: "POST",
          data: {
            shop_uuid: this.$config.shop_uuid,
            lang: this.$i18n.locale
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "image",
            name: "image",
            title: self.$t("food.table.image"),
            searchable: false,
            orderable: false
          },
          {
            data: "name",
            name: "name",
            title: self.$t("food.table.name")
          },
          {
            data: "product_name",
            name: "product_name",
            title: self.$t("food.table.name")
          },
          {
            data: "product_type.name_" + this.$i18n.locale,
            name: "productType.name_" + this.$i18n.locale,
            title: self.$t("food.table.productType")
          },
          {
            data: "category.name_" + this.$i18n.locale,
            name: "category.name_" + this.$i18n.locale,
            title: self.$t("food.table.productCategory"),
            render: (data, type, row) => {
              if (row.category !== null) {
                return `${row.category["name_" + self.$i18n.locale]}`
              }
              return "-"
            }
          },
          {
            data: "stock_alert_level",
            name: "stock_alert_level",
            title: self.$t("food.table.stockAlertLevel")
          },
          {
            data: "productStock",
            name: "productStock",
            title: self.$t("food.table.productStock"),
            searchable: false,
            orderable: false
          },
          {
            data: "sale_price",
            name: "sale_price",
            title: self.$t("food.table.salePrice")
          },
          {
            data: "updated_at",
            name: "updated_at",
            title: self.$t("food.table.updatedAt")
          }
        ],
        columnDefs: [
          {
            targets: [1],
            visible: false
          }
        ],
        order: [
          [2, "desc"]
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
