<template>
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title text-capitalize">
            {{ $t("report.strings.listingReportProductSale") }}
            <span v-if="statusDay">( {{ statusDay }} )</span>
          </h3>
        </div>
        <div class="box-body p-xs">
          <div class="row">
            <div class="col-xs-12 col-lg-6">
              <date-picker
                v-model="date_range"
                :not-after="new Date()"
                :placeholder="$t('label.chooseDateRange')"
                :shortcuts="false"
                format="YYYY-MM-DD"
                lang="en"
                range
                style="width:100%; margin-bottom: 10px"
                type="date"
                @change="changeDateDuration('dateRange')"
              />
            </div>
            <div class="col-xs-12 margin-bottom">
              <button
                :class="selectDayStatus === 'Today'? 'btn-primary':'btn-default'"
                class="btn"
                @click="changeDateDuration('Today')"
              >
                <i class="far fa-calendar-alt" /> {{ $t("report.label.today") }}
              </button>
              <button
                :class="selectDayStatus === 'This Week'? 'btn-primary':'btn-default'"
                class="btn"
                @click="changeDateDuration('This Week')"
              >
                <i class="far fa-calendar-alt" /> {{ $t("report.label.thisWeek") }}
              </button>
              <button
                :class="selectDayStatus === 'This Month'? 'btn-primary':'btn-default'"
                class="btn"
                @click="changeDateDuration('This Month')"
              >
                <i class="far fa-calendar-alt" /> {{ $t("report.label.thisMonth") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <bar-chart
                v-if="selectedModeView === 'chart'"
                :chart-data="chartData"
                :height="300"
                :options="chartOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title text-capitalize">
            {{ $t("label.hotSale") }}
            <span v-if="statusDay">( {{ statusDay }} )</span>
          </h3>
        </div>
        <div class="box-body hidden-xs">
          <table
            id="report-product-sale-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("report.table.name") }}</th>
                <th>{{ $t("report.table.quantity") }}</th>
                <th>{{ $t("report.table.unitPrice") }}</th>
                <th>{{ $t("report.table.amountTotal") }}</th>
                <th>{{ $t("report.table.action") }}</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
        <div>
          <div class="box-body p-xs hidden-sm hidden-md hidden-lg">
            <div class="h-100 bg-white scrollbar in-box">
              <HotSaleDataTableSm
                ref="hotSaleDataTableSm"
                :end-date="end_date"
                :start-date="start_date"
                :window-width="windowWidth"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title text-capitalize">
            {{ $t("label.noneSale") }}
            <span v-if="statusDay">( {{ statusDay }} )</span>
          </h3>
          <div class="box-tools">
            <button
              class="btn btn-box-tool"
              data-target="#noneSale"
              data-widget="collapse"
              type="button"
            >
              <i class="fas fa-minus" />
            </button>
          </div>
        </div>
        <div
          id="noneSale"
          class="box-body"
        >
          <table
            id="report-product-none-sale-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("report.table.name") }}</th>
                <th>{{ $t("report.table.amountInStock") }}</th>
                <th>{{ $t("report.table.unitPrice") }}</th>
                <th>{{ $t("report.table.createdAt") }}</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from "vue2-datepicker"
import BarChart from "./../chart/barChart"
import HotSaleDataTableSm from "@/views/reports/product-sale/_components/HotSaleDataTableSm"

export default {
  name: "List",
  components: {
    HotSaleDataTableSm,
    DatePicker,
    BarChart
  },
  metaInfo () {
    return {
      title: this.$t("report.menu.productSale"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      reportTable: null,
      noneSaleTable: null,
      config: this.$config,
      shop_uuid: JSON.parse(localStorage.getItem("shop")).uuid,
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            position: "left",
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5
            }
          }]
        }
      },
      modesDay: [
        "Today", "This Week", "This Month"
      ],
      modesView: [
        "chart", "table"
      ],
      selectedModeView: "chart",
      selectDayStatus: "This Month",
      date_range: [],
      start_date: null,
      end_date: null,
      status: "This Month",
      chartLabels: [],
      chartValues: [],
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.reportTable.clear()
      this.reportTable.destroy()
      this.reportTable = this.reportDataTable()
      this.reportTable.draw(true)

      this.noneSaleTable.clear()
      this.noneSaleTable.destroy()
      this.noneSaleTable = this.noneSaleDataTable()
      this.noneSaleTable.draw(true)
    },
    windowWidth: function () {
      if (this.windowWidth > 768) {
        if (!this.reportTable) {
          this.reportTable = this.reportDataTable()
        }
        if (!this.noneSaleTable) {
          this.noneSaleTable = this.noneSaleDataTable()
        }
        this.reloadButtonAction()
      }
    }
  },
  computed: {
    statusDay () {
      if (this.status === "Today") {
        return this.$t("report.label.today")
      } else if (this.status === "This Week") {
        return this.$t("report.label.thisWeek")
      } else if (this.status === "This Month") {
        return this.$t("report.label.thisMonth")
      } else {
        return this.status
      }
    },
    productSaleLabel () {
      return this.$t("label.productSale")
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    this.$isLoading(true)
    this.$nextTick(() => {
      setTimeout(() => {
        this.changeDateDuration(this.status)
      }, 800)
    })

    if (this.windowWidth > 768) {
      this.reportTable = this.reportDataTable()
      this.noneSaleTable = this.noneSaleDataTable()
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    changeModeView (mode) {
      if (mode === "chart") {
        this.selectedModeView = this.modesView[0]
      } else {
        this.selectedModeView = this.modesView[1]
      }
    },

    changeDateDuration (status) {
      if (status === "dateRange") {
        if (!this.date_range[0] && !this.date_range[1]) {
          this.start_date = this.$moment().startOf("month").format("YYYY-MM-DD")
          this.end_date = this.$moment().format("YYYY-MM-DD")
          this.status = "This Month"
          this.selectDayStatus = this.modesDay[2]
        } else {
          this.start_date = this.$moment(this.date_range[0]).utc("+7:00").format("YYYY-MM-DD")
          this.end_date = this.$moment(this.date_range[1]).utc("+7:00").format("YYYY-MM-DD")
          this.status = this.start_date + " ~ " + this.end_date
          this.selectDayStatus = null
        }
      } else if (status === "Today") {
        this.selectDayStatus = this.modesDay[0]
        this.start_date = this.$moment().format("YYYY-MM-DD")
        this.end_date = this.$moment().format("YYYY-MM-DD")
        this.status = "Today"
        this.date_range = []
      } else if (status === "This Week") {
        this.selectDayStatus = this.modesDay[1]
        this.start_date = this.$moment().startOf("isoWeek").format("YYYY-MM-DD")
        this.end_date = this.$moment().format("YYYY-MM-DD")
        this.status = "This Week"
        this.date_range = []
      } else {
        this.selectDayStatus = this.modesDay[2]
        this.start_date = this.$moment().startOf("month").format("YYYY-MM-DD")
        this.end_date = this.$moment().format("YYYY-MM-DD")
        this.status = "This Month"
        this.date_range = []
      }
      this.getSaleReport()
      this.$isLoading(false)
    },

    getSaleReport () {
      this.$axios.post(this.$config.base_url + "/api/report/get-sale-report", {
        start_date: this.start_date,
        end_date: this.end_date,
        uuid: this.shop_uuid

      }).then((response) => {
        if (response.data.code === 1) {
          this.chartData = {
            labels: response.data.data.label,
            datasets: [
              {
                barPercentage: 0.5,
                barThickness: 50,
                label: this.productSaleLabel,
                backgroundColor: "#3c8dbc",
                data: response.data.data.amount
              }
            ]
          }
        } else {
          const result = response.data
          if (result.code === 603) {
            this.$swal({
              type: "warning",
              title: this.$t("swal.title.havingProblem"),
              text: this.$i18n.locale === "km" ? result.message_km : result.message_en
            })
          } else {
            this.$swal({
              type: "error",
              title: this.$t("swal.title.havingProblem"),
              text: response.data.message
            })
          }
        }
      }).finally(() => {
        if (this.windowWidth > 768) {
          this.reportTable.draw(true)
          this.noneSaleTable.draw(true)
        } else if (this.windowWidth <= 768) {
          if (this.$refs.hotSaleDataTableSm) {
            this.$refs.hotSaleDataTableSm.getListData()
          }
        }
        this.$isLoading(false)
      })
    },

    reportDataTable () {
      const self = this
      return $("#report-product-sale-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        ajax: {
          url: this.$config.base_url + "/api/report/get-sale-report-datatable",
          type: "POST",
          data: (d) => {
            d.start_date = self.start_date
            d.end_date = self.end_date
            d.uuid = self.shop_uuid
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "product_name",
            name: "product_name",
            title: self.$t("report.table.name")
          },
          {
            data: "amount",
            name: "amount",
            title: self.$t("report.table.quantity")
          },
          {
            data: "sale_price",
            name: "sale_price",
            title: self.$t("report.table.unitPrice"),
            searchable: false,
            orderable: false
          },
          {
            data: "amount_total",
            name: "amount_total",
            title: self.$t("report.table.amountTotal"),
            searchable: false,
            orderable: false
          },
          {
            data: "action",
            name: "action",
            title: self.$t("report.table.action"),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },

    noneSaleDataTable () {
      const self = this
      return $("#report-product-none-sale-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        ajax: {
          url: this.$config.base_url + "/api/report/get-none-sale-product-datatable",
          type: "POST",
          data: (d) => {
            d.start_date = self.start_date
            d.end_date = self.end_date
            d.uuid = self.shop_uuid
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "product_name",
            name: "product_name",
            title: self.$t("report.table.name")
          },
          {
            data: "amount_in_stock",
            name: "amount_in_stock",
            title: self.$t("report.table.amountInStock"),
            searchable: false,
            orderable: false
          },
          {
            data: "sale_price",
            name: "sale_price",
            title: self.$t("report.table.unitPrice"),
            searchable: false,
            orderable: false
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("report.table.createdAt"),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },

    reloadButtonAction () {
      const self = this
      $(document).off("click", ".btn-product-sale-detail")

      $(document).on("click", ".btn-product-sale-detail", function () {
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "list-sale-history",
          params: {
            uuid: uuid,
            start_date: self.start_date,
            end_date: self.end_date
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
