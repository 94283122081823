<template>
  <div :class="{'d-flex flex-column h-100' : $route.name !== 'offline-print-invoice'}">
    <template v-if="$route.name === 'offline-print-invoice'">
      <NavbarV2 class="no-print" />
      <LeftSideBarMobileScreen class="no-print" />
      <router-view />
    </template>
    <template v-else>
      <div class="non-footer-container flex-1 h-100">
        <div class="d-flex flex-column h-100">
          <NavbarV2 />
          <div class="container-fluid no-padding flex-1 overflow-y-auto w-100 scrollbar">
            <div class="d-flex h-100">
              <LeftSideBarMobileScreen class="no-print">
                <LeftSideBarOrder />
              </LeftSideBarMobileScreen>
              <div class="flex-1 d-flex aso-content-right">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import NavbarV2 from "@/includes/NavbarV2"
import LeftSideBarMobileScreen from "@/components/LeftSideBarMobileScreen"
import LeftSideBarOrder from "@/components/LeftSideBarOrder"

export default {
  name: "Offline",
  components: {
    LeftSideBarOrder,
    LeftSideBarMobileScreen,
    NavbarV2
  }
}
</script>

<style scoped>

</style>
