<template>
  <div class="d-flex w-100">
    <div class="pos-content-container flex-1 bg-white">
      <div class="d-flex flex-column payment-container rounded">
        <div class="block-category d-flex rounded-tl rounded-tr">
          <div class="d-flex w-100 align-items-center justify-content-between">
            <div class="w-100">
              <div
                class="d-flex align-items-center justify-content-between"
                style="padding-top: 8px !important;"
              >
                <p class="title-label mt-0 font-s-20">
                  <strong>{{ $t("label.payment") }}</strong>
                </p>
                <div>
                  <a
                    aria-controls="collapseExample"
                    aria-expanded="false"
                    class="btn btn-primary btn-lg mr-3 hidden-sm hidden-md hidden-lg"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                  >
                    <i class="fas fa-eye" />
                  </a>
                  <a
                    class="btn btn-default btn-lg no-margin"
                    @click="goBack"
                  >
                    <i class="fas fa-arrow-left back" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 payment-info-container rounded-tl rounded-br">
          <DisplayOrderInfo
            :amount-total="amountTotal"
            :dic-is-percentage="is_percentage"
            :discount-value="discount"
            :exchange-rate="exchangeRate"
            :membership-discount="membershipDis"
            :products="products"
            :shop-tax="shop_tax"
            :sub-total="subTotal"
            class="w-50 border-r hidden-xs"
          />
          <div
            id="collapseExample"
            class="collapse hidden-sm hidden-md hidden-lg"
          >
            <DisplayOrderInfo
              :amount-total="amountTotal"
              :dic-is-percentage="is_percentage"
              :discount-value="discount"
              :exchange-rate="exchangeRate"
              :membership-discount="membershipDis"
              :products="products"
              :shop-tax="shop_tax"
              :sub-total="subTotal"
              class="w-50 border-r"
            />
          </div>
          <div class="h-100 w-50 d-flex flex-column block-content-h">
            <div class="h-100 flex-1 position-relative overflow-hidden">
              <div class="h-100 d-flex flex-column w-100 block-payment-balance-content overflow-y-auto scrollbar">
                <div class="row mx-0 ">
                  <div class="col-xs-12 no-padding user-select-none hidden-sm hidden-md hidden-lg">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 py-0">
                          <div class="block-total-l text-capitalize">
                            <div class="font-s-20">
                              <strong>{{ $t("label.amountTotal") }}</strong>
                            </div>
                            <div style="line-height: 0.8">
                              <small class="font-s-14">({{ $t("label.includeVat") }})</small>
                            </div>
                          </div>
                          <div class="block-total-r">
                            <div class="font-s-20">
                              <span :class="parseInt(amountTotal) <= 0 ? 'text-red' : 'pos-menubar_active'">{{
                                currency ? currency.symbol : ""
                              }} {{ amountTotal | numFormat(num_format) }}</span>
                            </div>
                            <div class="font-s-20">
                              <span :class="parseInt(amountTotal) <= 0 ? 'text-red' : 'pos-menubar_active'">
                                {{ currency.code === "USD" ? "៛" : "$" }}
                                {{ moneyEquivalent(currency.code, amountTotal) | numFormat(num_format) }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 no-padding user-select-none">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 py-0">
                          <div class="w-100">
                            <div class="form-group">
                              <div class="input-group input-group-lg">
                                <span
                                  id="amount-riel"
                                  class="input-group-addon rounded-bl rounded-tl"
                                >KHR (៛)</span>
                                <input
                                  v-model="paidRiel"
                                  aria-describedby="amount-riel"
                                  class="form-control"
                                  type="number"
                                >
                                <div class="input-group-btn">
                                  <button
                                    class="btn btn-default"
                                    type="button"
                                    @click="payAmountTotal('KHR')"
                                  >
                                    <i class="fa fa-credit-card" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="input-group input-group-lg">
                                <span
                                  id="amount-dollar"
                                  class="input-group-addon rounded-bl rounded-tl"
                                >USD ($)</span>
                                <input
                                  v-model="paidDollar"
                                  aria-describedby="amount-dollar"
                                  class="form-control"
                                  type="number"
                                >
                                <div class="input-group-btn">
                                  <button
                                    class="btn btn-default"
                                    type="button"
                                    @click="payAmountTotal('USD')"
                                  >
                                    <i class="fa fa-credit-card" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount px-0 pt-3 pb-0">
                          <div class="block-total-l user-select-none w-30">
                            <strong>{{ $t("label.discount") }}</strong>
                          </div>
                          <div class="block-total-r w-70">
                            <div class="input-group input-group-lg">
                              <input
                                v-model="discount"
                                aria-describedby="discount"
                                class="form-control"
                                min="0"
                                type="number"
                              >
                              <div class="input-group-btn ">
                                <button
                                  :class="is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                                  class="btn no-margin bl-unset"
                                  @click="is_percentage = true"
                                >
                                  <i class="fas fa-percent" />
                                </button>
                                <button
                                  :class="!is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                                  class="btn no-margin"
                                  @click="is_percentage = false"
                                >
                                  {{ currency ? currency.code : "USD" }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="discount > 0"
                    class="col-xs-12 no-padding"
                  >
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount px-0 pt-3 pb-0">
                          <div class="block-total-l user-select-none w-30">
                            <strong>{{ $t("label.authorizedBy") }}</strong>
                          </div>
                          <div class="block-total-r w-70">
                            <input
                              v-model="authorized"
                              :placeholder="$t('label.authorizesTheDiscount')"
                              class="form-control input-lg"
                              style="border-radius: 6px"
                              type="text"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="amountDue > 0"
                    class="col-xs-12 no-padding user-select-none"
                  >
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount amount align-items-top px-0 pt-3 pb-0">
                          <div class="user-select-none block-total-l text-capitalize">
                            <strong>{{ $t("label.amountDue") }}</strong>
                          </div>
                          <div class="block-total-r">
                            <div class="text-bold">
                              {{ defaultCurrency }}
                              <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                {{ amountDue | numFormat("0,0") }}
                              </template>
                              <template v-else>
                                {{ amountDue | numFormat(num_format) }}
                              </template>
                            </div>
                            <div class="text-bold font-s-14">
                              ({{ reverseCurrency }}
                              <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                                {{ moneyEquivalent(getCurrencyCode, amountDue) | numFormat("0,0") }})
                              </template>
                              <template v-else>
                                {{ moneyEquivalent(getCurrencyCode, amountDue) | numFormat(num_format) }})
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="amountOwe > 0"
                    class="col-xs-12 no-padding user-select-none"
                  >
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount amount align-items-top px-0 pt-3 pb-0">
                          <div class="user-select-none block-total-l text-capitalize">
                            <strong>{{ $t("label.amountOwe") }}</strong>
                          </div>
                          <div class="block-total-r">
                            <div class="text-bold">
                              <div :class="{'text-red': parseFloat(amountOwe) > 0}">
                                {{ defaultCurrency }}
                                <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                  {{ amountOwe | numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ amountOwe | numFormat(num_format) }}
                                </template>
                              </div>
                            </div>
                            <div class="text-bold font-s-14">
                              <div :class="{'text-red': parseFloat(amountOwe) > 0}">
                                ({{ reverseCurrency }}
                                <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                                  {{ moneyEquivalent(getCurrencyCode, amountOwe) | numFormat("0,0") }})
                                </template>
                                <template v-else>
                                  {{ moneyEquivalent(getCurrencyCode, amountOwe) | numFormat(num_format) }})
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="text-capitalize user-select-none">
                            <strong>{{
                              $t("label.paymentOption")
                            }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="list-category list-payment-option h-auto">
                            <template v-for="(item, key) in paymentOptions">
                              <a
                                :key="key"
                                :class="paymentOption && paymentOption === item ? 'active' : ''"
                                class="list-category-item shadow list-category-item-h mb-8"
                                @click="selectPaymentOption(item)"
                              >
                                <h4 class="list-category-label">{{ item["name_" + $i18n.locale] }}</h4>
                              </a>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="user-select-none text-capitalize">
                            <strong>{{ $t("label.customer") }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="selectedCustomer"
                    class="col-xs-12 payment-selected-customer-display user-select-none"
                  >
                    <div class="selected-customer">
                      <div class="selected-customer-info">
                        <div class="sugg-cus-name">
                          <strong>{{ selectedCustomer.name }}</strong>
                        </div>
                        <div class="sugg-cus-contact">
                          <template
                            v-if="selectedCustomer.customer_membership && selectedCustomer.customer_membership.length"
                          >
                            <span v-if="checkMembershipExpired(selectedCustomer.customer_membership[0])">
                              <strong>
                                {{ selectedCustomer.customer_membership[0]["name_" + $i18n.locale] }}
                                ( {{ selectedCustomer.customer_membership[0].amount_percentage }} % )
                              </strong>
                            </span>
                          </template>
                          <span v-if="selectedCustomer.tel">{{ selectedCustomer.tel }}</span>
                          <span v-if="selectedCustomer.address">{{ selectedCustomer.address }}</span>
                        </div>
                      </div>
                      <div>
                        <button
                          class="btn btn-lg btn-danger no-margin"
                          @click="clearSelectCustomer"
                        >
                          <i class="fas fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="col-xs-12 no-padding user-select-none"
                  >
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <template>
                          <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                            <div class="row mx-0 w-100">
                              <div class="col-xs-12 px-0">
                                <button
                                  class="btn btn-block btn-default btn-lg"
                                  @click="showSelectCustomer = !showSelectCustomer"
                                >
                                  <i class="fas fa-plus" />
                                  {{ $t("label.newCustomer") }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 no-padding">
                    <div class="list-order-header no-border">
                      <div class="w-100 h-100">
                        <div class="list-total-amount align-items-top px-0 pt-3 pb-0">
                          <div class="row mx-0 w-100">
                            <div class="col-xs-12 px-0">
                              <button
                                class="btn btn-success btn-block btn-pay justify-content-center"
                                @click="onConfirmPayment"
                              >
                                {{ $t("button.completePayment") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DrawerCustomer
                ref="addOfflineCustomer"
                :shop_id="shop ? shop.id : null"
                :show-select-customer="showSelectCustomer"
                class-list-customer="list-sugg-customer-payment"
                @closeDrawer="closeDrawerCustomer"
                @getChildData="getCustomer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexedb from "../../../../indexedb"
import DrawerCustomer from "@/components/DrawerCustomer"
import { mapState } from "vuex"
import DisplayOrderInfo from "@/views/cashiers/payment/_components/DisplayOrderInfo"

export default {
  name: "BillVersion2",
  metaInfo () {
    return {
      title: this.$t("string.payment"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    DisplayOrderInfo,
    DrawerCustomer
  },
  data () {
    return {
      paymentOptions: [],
      paymentOption: null,
      genders: [],
      shop: null,
      discount: 0,
      is_percentage: true,
      authorized: null,
      paidRiel: 0,
      paidDollar: 0,
      tax: null,

      /* Add Keys New For Version 2 */
      products: [],
      order: null,
      showSelectCustomer: false,
      selectedCustomer: null,
      offlineCustomerRelation: null,
      shop_tax: null
    }
  },
  watch: {
    discount () {
      this.checkDiscountValue()
    },
    is_percentage () {
      this.checkDiscountValue()
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      exchangeRate: state => state.shop.exchange,
      shop_currency: state => state.shop.currency
    }),
    defaultCurrency () {
      if (this.shop_currency) {
        return this.shop_currency.code === "USD" ? "$" : "៛"
      }
      return "$"
    },
    reverseCurrency () {
      if (this.shop_currency) {
        return this.shop_currency.code === "USD" ? "៛" : "$"
      }
      return "៛"
    },
    getCurrencyCode () {
      return this.defaultCurrency === "$" ? "USD" : "KHR"
    },
    getDigitFixedUSD () {
      return this.getCurrencyCode === "USD" ? 2 : 0
    },
    getDigitFixedKHR () {
      return this.getCurrencyCode === "USD" ? 0 : 2
    },
    amountTotal () {
      let amount = 0
      if (this.discount > 0) {
        this.$store.dispatch("order/setOrderDiscountStatus", this.is_percentage)
        this.emit("display_dis_percent", {
          display_dis_percent: this.is_percentage
        })
        this.$store.dispatch("order/setOrderDiscount", this.discount)
        this.emit("display_dis", {
          discount: this.discount
        })
        if (this.is_percentage) {
          amount = parseFloat(this.precise(((100 - this.discount) / 100) * this.subTotal))
        } else {
          amount = parseFloat(this.precise(this.subTotal - this.discount))
        }
      } else {
        amount = parseFloat(this.subTotal)
      }

      if (this.shop_tax) {
        const tax_value_percentage = parseFloat(this.shop_tax.amount_percentage)
        const tax_value = (amount * tax_value_percentage) / 100
        amount = parseFloat(amount) + parseFloat(tax_value)
      }

      if (this.selectedCustomer && this.selectedCustomer.customer_membership) {
        if (this.selectedCustomer.customer_membership.length) {
          const member = this.selectedCustomer.customer_membership[0]
          if (this.checkMembershipExpired(member)) {
            const value_percentage = parseFloat(member.amount_percentage)
            const value = (amount * value_percentage) / 100
            amount = parseFloat(amount) - parseFloat(value)
          }
        }
      }
      return amount
    },
    paidCompareWithTotal () {
      try {
        const amountTotal = this.amountTotal.toFixed(2)
        if (this.paidDollar && !this.paidRiel) {
          const d = parseFloat(this.paidDollar).toFixed(2)
          const a = this.exchangeMoney(this.currency.code, "USD", this.amountTotal).toFixed(2)
          if (d === a) {
            return 0
          } else {
            const paidDollar = this.exchangeMoney("USD", this.currency.code, this.paidDollar)
            return this.precise(paidDollar - amountTotal)
          }
        } else if (this.paidRiel && !this.paidDollar) {
          const r = parseFloat(this.paidRiel).toFixed(2)
          const a = this.exchangeMoney(this.currency.code, "KHR", this.amountTotal).toFixed(2)
          if (r === a) {
            return 0
          } else {
            const paidRiel = this.exchangeMoney("KHR", this.currency.code, this.paidRiel)
            return this.precise(paidRiel - amountTotal)
          }
        } else {
          const paidRiel = this.exchangeMoney("KHR", this.shop_currency.code, this.paidRiel)
          const paidDollar = this.exchangeMoney("USD", this.shop_currency.code, this.paidDollar)
          return this.precise((paidRiel + paidDollar) - amountTotal)
        }
      } catch (e) {
        return 0
      }
    },
    amountDue () {
      return this.paidCompareWithTotal < 0 ? 0 : this.paidCompareWithTotal
    },
    amountOwe () {
      return this.paidCompareWithTotal >= 0 ? 0 : Math.abs(this.paidCompareWithTotal)
    },
    subTotal () {
      let total = 0
      let valKhr = 0
      let valUsd = 0
      this.products.forEach((product) => {
        let result = 0
        if (product.pivot && product.pivot.discount > 0) {
          if (product.pivot.is_percentage) {
            const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
            result = parseFloat(product.sale_price) - parseFloat(amount_discount)
          } else {
            let amount_discount = parseFloat(product.pivot.discount)
            if (this.shop_currency && product.currency && this.shop_currency.code !== product.currency.code) {
              amount_discount = this.exchangeMoney(this.shop_currency.code, product.currency.code, amount_discount)
            }
            result = parseFloat(product.sale_price) - parseFloat(amount_discount)
          }
        } else {
          result = parseFloat(product.sale_price)
        }
        if (product.currency && product.currency.code === "USD") {
          valUsd = parseFloat(valUsd) + (parseFloat(product.pivot.quantity) * result)
        } else {
          valKhr = parseFloat(valKhr) + (parseFloat(product.pivot.quantity) * result)
        }
        const usd = this.exchangeMoney("USD", this.shop_currency.code, valUsd)
        const khr = parseFloat(this.exchangeMoney("KHR", this.shop_currency.code, valKhr))
        total = khr + usd
      })
      return parseFloat(total).toFixed(2)
    },
    hasAmountPaid () {
      return !!((this.paidDollar || this.paidRiel) && (this.paidDollar > 0 || this.paidRiel > 0))
    },
    membershipDis () {
      if (this.selectedCustomer && this.selectedCustomer.customer_membership && this.selectedCustomer.customer_membership.length) {
        if (this.checkMembershipExpired(this.selectedCustomer.customer_membership[0])) {
          return this.selectedCustomer.customer_membership[0].amount_percentage
        }
      }
      return 0
    }
  },
  methods: {
    checkDiscountValue () {
      if (this.is_percentage) {
        if (this.discount < 0) {
          this.discount = 0
        } else if (this.discount > 100) {
          this.discount = 100
        }
      }
    },
    payAmountTotal (code_currency) {
      if (this.shop_currency) {
        if (code_currency === "KHR") {
          this.paidRiel = Math.ceil(this.exchangeMoney(this.shop_currency.code, "KHR", this.amountTotal))
          this.paidDollar = 0
        } else {
          const result = this.exchangeMoney(this.shop_currency.code, "USD", this.amountTotal)
          if (result === 0) {
            this.paidDollar = result
          } else {
            this.paidDollar = result.toFixed(2)
          }
          this.paidRiel = 0
        }
      }
    },
    onConfirmPayment () {
      if (parseFloat(this.amountOwe) > 0 && !this.hasAmountPaid) {
        this.$swal({
          text: this.$t("string.unPainOrderYet"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f63547",
          confirmButtonText: this.$t("string.yesIAmSure"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          this.completeThePayment()
        }, (dismiss) => {
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch((err) => {
          this.onResponseError(err)
        })
      } else {
        this.completeThePayment()
      }
    },
    getOrder () {
      indexedb.find("order_products", this.$route.params.order_uuid)
        .then((order_product) => {
          this.products = order_product.data
          if (this.products && this.products.length) {
            this.products.map((product) => {
              product = this.calcPromotion(product, product.promotions)
              return product
            })
          }
        })
    },
    getPaymentOptions () {
      indexedb.all("payment_options")
        .then((payment_options) => {
          this.paymentOptions = payment_options
          this.paymentOption = this.paymentOptions[0]
        })
    },
    getShop () {
      indexedb.all("shop")
        .then((shop) => {
          this.shop = shop[0]
        })
    },

    /* Add New For Version 2 */
    selectPaymentOption (item) {
      this.paymentOption = item
    },
    checkOrderPaid () {
      let findOrder = null
      indexedb.find("orders", this.$route.params.order_uuid)
        .then((order) => {
          findOrder = order
        }).then(() => {
          if (findOrder) {
            this.order = findOrder
            if (this.order.discount) {
              this.discount = parseFloat(this.order.discount)
              this.is_percentage = this.order.is_percentage
              this.$store.dispatch("order/setOrderDiscount", parseFloat(this.order.discount))
              this.emit("display_dis", {
                discount: parseFloat(this.order.discount)
              })
            }
            if (this.order.customer) {
              this.selectedCustomer = this.order.customer
            }
            this.authorized = this.order.authorized
            this.$store.dispatch("order/setDiscountAuthorizer", this.order.authorized)
            if (findOrder.is_paid) {
              this.$router.push({ name: "offline-sale" })
            } else {
              this.getPaymentOptions()
              this.getOrder()
              this.getShop()
            }
          }
        })
    },
    clearSelectCustomer () {
      this.selectedCustomer = null
    },
    closeDrawerCustomer () {
      this.showSelectCustomer = false
    },
    getCustomer () {
      if (this.$refs.addOfflineCustomer) {
        this.selectedCustomer = this.$refs.addOfflineCustomer.customer
        this.offlineCustomerRelation = this.$refs.addOfflineCustomer.offlineCustomerRelation
      }
    },
    goBack () {
      if (this.$route.params.order_uuid) {
        this.$router.push({
          name: "offline-sale",
          params: { order_uuid: this.$route.params.order_uuid }
        })
      } else {
        this.$router.back()
      }
    },
    completeThePayment () {
      this.$isLoading(true)
      let cashier = null
      if (localStorage.hasOwnProperty("user")) {
        cashier = JSON.parse(localStorage.getItem("user"))
      }
      const data = {
        tax: this.tax,
        shop: this.shop,
        order_uuid: this.$route.params.order_uuid,
        order_number: this.order.order_number,
        payment_option: this.paymentOption,
        amount_subtotal_dollar: parseFloat(this.shop_currency.code === "USD" ? this.subTotal : this.moneyEquivalent("KHR", this.subTotal)).toFixed(2),
        amount_subtotal_riel: parseFloat(this.shop_currency.code === "KHR" ? this.subTotal : Math.ceil(this.moneyEquivalent("USD", this.subTotal))),
        amount_discount: this.discount ? this.discount : 0,
        discount_authorizer: this.discount > 0 ? this.authorized : null,
        is_percentage: this.is_percentage,
        amount_total_dollar: parseFloat(this.shop_currency.code === "USD" ? this.amountTotal : this.moneyEquivalent("KHR", this.amountTotal)).toFixed(2),
        amount_total_riel: parseFloat(this.shop_currency.code === "KHR" ? this.amountTotal : Math.ceil(this.moneyEquivalent("USD", this.amountTotal))),
        amount_paid_dollar: this.paidDollar ? this.paidDollar : 0,
        amount_paid_riel: this.paidRiel ? this.paidRiel : 0,
        amount_due_dollar: parseFloat(this.shop_currency.code === "USD" ? this.amountDue : this.moneyEquivalent("KHR", this.amountDue)).toFixed(2),
        amount_due_riel: parseFloat(this.shop_currency.code === "KHR" ? this.amountDue : Math.ceil(this.moneyEquivalent("USD", this.amountDue))),
        amount_owe_dollar: parseFloat(this.shop_currency.code === "USD" ? this.amountOwe : this.moneyEquivalent("KHR", this.amountOwe)).toFixed(2),
        amount_owe_riel: parseFloat(this.shop_currency.code === "KHR" ? this.amountOwe : Math.ceil(this.moneyEquivalent("USD", this.amountOwe))),
        created_at: new Date(),
        updated_at: new Date(),
        cashier: cashier,
        info: {
          tax: this.shop_tax,
          exchange_rate: this.exchangeRate,
          payment_option: this.paymentOption,
          customer_membership: []
        }
      }
      if (this.amountOwe > 0) {
        data.is_owe = true
      }

      if (this.selectedCustomer) {
        data.customer_uuid = this.selectedCustomer.uuid
        data.customer_name = this.selectedCustomer.name
        data.customer_gender = this.selectedCustomer.gender ? this.selectedCustomer.gender.name_en : null
        data.customer_gender_id = this.selectedCustomer.gender ? this.selectedCustomer.gender.id : null
        data.customer_telephone = this.selectedCustomer.tel
        data.customer_address = this.selectedCustomer.address
        data.info.customer_membership = this.selectedCustomer.customer_membership ? this.selectedCustomer.customer_membership : []
      }

      if (this.offlineCustomerRelation) {
        data.customer_group_ids = this.offlineCustomerRelation.group_ids ? this.offlineCustomerRelation.group_ids : null
        data.customer_membership_ids = this.offlineCustomerRelation.membership_ids ? this.offlineCustomerRelation.membership_ids : null
        data.membership_start_date = this.offlineCustomerRelation.start_date ? this.offlineCustomerRelation.start_date : null
        data.membership_end_date = this.offlineCustomerRelation.end_date ? this.offlineCustomerRelation.end_date : null
      }
      indexedb.save("invoices", {
        uuid: this.$route.params.order_uuid,
        ...data
      }).then((response) => {
        if (response.status) {
          // mark order is paid
          let findOrder = null
          indexedb.find("orders", this.$route.params.order_uuid)
            .then((order) => {
              findOrder = order
            }).then(() => {
              findOrder.is_paid = true
              indexedb.save("orders", findOrder)
            })
        }
      }).then(() => {
        this.$router.push({
          name: "offline-print-invoice",
          params: {
            order_uuid: this.$route.params.order_uuid
          }
        })
      }).catch(() => {
        this.$isLoading(false)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    if (localStorage.hasOwnProperty("tax")) {
      this.shop_tax = JSON.parse(localStorage.getItem("tax"))
    }
    this.checkOrderPaid()
  }
}
</script>

<style scoped>
.mb-8 {
  margin-bottom: 8px;
}

.payment-container {
  height: 98%;
  width: 75%;
  box-shadow: 0 0 12px #08080817;
  margin: .5% auto 0;
}

.payment-info-container {
  display: flex;
  overflow: hidden;
}

.border-r {
  border-right: 1px solid #f3f2f2;
}

@media screen and (max-width: 1200px) {
  .payment-container {
    width: 100%;
    margin: 0;
    padding-bottom: unset;
    box-shadow: unset;
  }
}

@media screen and (max-width: 767px) {
  .block-payment-balance-content {
    padding: 8px 0 0 !important;
  }

  .payment-info-container {
    display: block;
    overflow: unset;
  }

  .payment-info-container .w-50 {
    width: 100% !important;
  }

  .payment-info-container .h-100.w-50 {
    height: auto !important;
  }
}

/* End here */
</style>
