<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.product.type.newType") }}
          </h3>
        </div>
        <div class="box-body">
          <product-type-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductTypeForm from "./Form"

export default {
  components: { ProductTypeForm },
  name: "CreateProductType",
  metaInfo () {
    return {
      title: this.$t("label.product.type.newType"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
