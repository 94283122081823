<template>
  <div
    class="product-list-each-item rounded"
    @click="addToCart"
  >
    <div class="w-100 d-flex align-items-center bg-white">
      <div class="product-photo user-select-none">
        <div
          :style="`background-image: url('${defaultImg}')`"
          class="product-each-item__top rounded"
        />
      </div>
      <div class="product-info">
        <p class="product-name h-auto font-s-16 margin-bottom-none w-100">
          {{ customProduct.name }}
        </p>
        <p class="font-s-16 margin-bottom-none">
          {{ $t("label.amountInStock") }}: {{ $t("label.unlimited") }}
        </p>
        <p
          v-if="customProduct.hasOwnProperty('sale_price')"
          class="text-bold font-s-18 user-select-none"
        >
          {{ customProduct.currency.symbol }} {{ customProduct.sale_price | numFormat(num_format) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "AsoraCustomProductItemList",
  props: {
    customProduct: {
      type: Object,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    addClassNames: {
      type: String
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat
    })
  },
  data () {
    return {
      origin_url: process.env.VUE_APP_ORIGIN_URL
    }
  },
  methods: {
    addToCart () {
      this.$store.dispatch("order/addProductToCart", this.customProduct)
      this.$store.dispatch("order/setDisplayPo", this)
    }
  }
}
</script>

<style scoped>
.product-photo {
  width: 200px;
}

.product-list-each-item {
  width: 100%;
  padding: 8px 0;
  box-shadow: 0 0 10px #0000001a;
  margin: 8px 16px;
  cursor: pointer;
}

.product-list-each-item:hover {
  background-color: #f4f4f4b5;
  box-shadow: 0 0 10px #30303030;
}

.product-photo {
  width: 130px;
}

.product-image {
  min-height: 100px;
  width: 100px;
  height: 100px;
}

.product-info {
  width: calc(100% - 130px);
}

.product-photo,
.product-info {
  padding: 0 15px;
}

.product-name {
  -webkit-line-clamp: 1;
}
</style>
