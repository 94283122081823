<template>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              @click="$router.back()"
              class="btn btn-default btn-sm"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <div class="row">
            <div class="col-md-12">
              <div
                class="form-group"
                :class="{'has-error' : message_validation.name_en }"
              >
                <label class="required">{{ $t("label.nameEn") }}</label>
                <input
                  :placeholder="$t('placeholder.enterNameAsEn')"
                  class="form-control"
                  type="text"
                  v-model="productTag.name_en"
                >
                <template v-if="message_validation.name_en">
                  <span class="help-block">{{ message_validation["name_en"][0] }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                class="form-group"
                :class="{'has-error' : message_validation.name_km }"
              >
                <label class="required">{{ $t("label.nameKm") }}</label>
                <input
                  :placeholder="$t('placeholder.enterNameAsKm')"
                  class="form-control"
                  type="text"
                  v-model="productTag.name_km"
                >
                <template v-if="message_validation.name_km">
                  <span class="help-block">{{ message_validation["name_km"][0] }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="text-right">
            <button
              :class="`btn btn-${$route.name === 'edit-product-tag' ? 'info' : 'primary'}`"
              @click="storeProductTag"
            >
              <i class="fas fa-check-circle" />
              <span v-if="this.$route.params.hasOwnProperty('uuid')"> {{ $t("button.update") }}</span>
              <span v-else> {{ $t("button.save") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductTagForm",
  props: {
    title: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      productTag: {
        shop_id: null,
        product_type: null,
        name_en: "",
        name_km: ""
      },
      config: this.$config,
      message_validation: {},
      productTypes: [],
      isShow: false
    }
  },
  methods: {
    getProductTag () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-tag/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then(({ data }) => {
        this.productTag = data.data
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getProductType () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-type")
        .then((response) => {
          this.$isLoading(false)
          if (response.data.data !== null) {
            this.productTypes = response.data.data
          }
        })
    },
    storeProductTag () {
      this.message_validation = {}
      this.productTag.shop_id = this.get_shop.id
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-tag/store", this.productTag)
        .then(() => {
          this.$router.push({ name: "list-product-tag" })
          if (this.productTag.hasOwnProperty("id") && this.productTag.id !== null) {
            this.$anotify(this.$t("model.tag"), "update")
          } else {
            this.$anotify(this.$t("model.tag"))
          }
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 422) {
            this.message_validation = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    this.getProductType()
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getProductTag()
    }
    if (this.$route.name === "show-product-tag") {
      $("input").attr("disabled", true)
      this.isShow = true
    }
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}
</style>
