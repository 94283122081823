import { shopMixin } from "@/mixins/shop"

export const facebookSdkMixin = {
  mixins: [shopMixin],
  data () {
    return {
      getAccessToken: true,
      step: 2,
      login_type: null
    }
  },
  mounted () {
    window.fbAsyncInit = function () {
      FB.init({
        appId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
        cookie: true,
        xfbml: true,
        version: process.env.VUE_APP_FACEBOOK_VERSION
      })
      FB.AppEvents.logPageView()
    };

    (function (d, s, id) {
      let js
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      // eslint-disable-next-line prefer-const
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    }(document, "script", "facebook-jssdk"))
  },
  methods: {
    loginWithFB () {
      const self = this
      FB.login(function (response) {
        if (response && response.status === "connected") {
          // Logged into your webpage and Facebook.
          // self.$store.dispatch('user/setFacebookAuth', response.authResponse)
          localStorage.setItem("f.a", JSON.stringify(response.authResponse.accessToken))
          self.resultCallback(response.authResponse.accessToken)
        }
      }, { scope: "email" })
    },
    logoutWithFB () {
      FB.logout()
    },
    checkLoginState () {
      // this.login_type = 'facebook'
      const self = this
      FB.getLoginStatus(function (response) {
        self.statusChangeCallback(response)
      })
    },
    statusChangeCallback (response) {
      const self = this
      if (response && response.status === "connected") {
        // Logged into your webpage and Facebook.
        // self.$store.dispatch('user/setFacebookAuth', response.authResponse)
        localStorage.setItem("f.a", JSON.stringify(response.authResponse.accessToken))
        self.resultCallback(response.authResponse.accessToken)
      } else {
        self.loginWithFB()
      }
    },
    resultCallback (access_token) {
      const self = this
      self.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/social-account/login-with-fb", {
          access_token
        })
        .then((response) => {
          const result = response.data.data
          const token = result.token_type + " " + result.access_token
          localStorage.setItem("token", token)

          this.$axios.defaults.headers.common.Accept = "application/json"
          this.$axios.defaults.headers.common.Authorization = token

          const user = result.user
          localStorage.setItem("user", JSON.stringify(user))
          self.$store.dispatch("user/updateUser", user)

          if (self.$bill_active === "true") {
            const plan = result.plan
            localStorage.setItem("plan", JSON.stringify(plan))
            self.$store.dispatch("shop/setPlan", plan)
            if (result.hasOwnProperty("expired")) {
              this.$store.dispatch("shop/setExpired", result.expired)
            }
          }

          const shop = result.shop
          $.ajaxSetup({
            headers: {
              Accept: "application/json",
              Authorization: localStorage.getItem("token"),
              "Access-Control-Allow-Origin": "*"
            }
          })
          if (shop !== null) {
            self.setShopProperty(shop)
            self.checkTokenDeviceForSell()
            self.$router.push({ name: "dashboard" })
          } else {
            self.$router.replace({ name: "create-shop" })
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          self.$isLoading(false)
        })
    }
  }
}
