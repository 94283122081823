<template>
  <div
    class="col-md-12"
    v-if="is_expired"
  >
    <div class="alert alert-warning">
      <template v-if="$i18n.locale === 'km'">
        <div class="text-black">
          <i class="fas fa-exclamation-triangle mr-2" />
          គម្រោងរបស់អ្នកបានផុតកំណត់ហើយ។ សូមបន្តគម្រោងរបស់អ្នកដើម្បីអាចដំណើរបន្ត។
          <ul>
            <li>ចុចលើពាក្យ <strong>ការកំណត់</strong></li>
            <li>
              ចុចលើពាក្យ
              <router-link
                :to="{name: 'list-subscription'}"
                class="text-black"
              >
                <strong>ការជាវ</strong>
              </router-link>
              ។
            </li>
            <li>ចុចលើពាក្យ <strong>បញ្ជីវិក័យបត្រ</strong></li>
            <li>ចុចលើប៊ូតុង <strong>បន្តជាថ្មី</strong></li>
            <li>បញ្ចូលព័ត៌មានដែលតម្រូវ</li>
            <li>ចុចលើប៊ូតុង <strong>បញ្ជូន</strong></li>
            <li>
              សម្រាប់ព័ត៌មានបន្ថែម សូមទាក់ទង
              <a
                href="https://figos.app"
                target="_blank"
                class="text-black"
              ><strong>Figos.app</strong></a>។
            </li>
          </ul>
        </div>
      </template>
      <template v-else>
        <div class="text-black">
          <i class="fas fa-exclamation-triangle mr-2" />
          Your plan has already expired. Please renew your plan to be able to continue.
          <ul>
            <li>Click on <strong>Settings</strong></li>
            <li>
              Click on
              <router-link
                :to="{name: 'list-subscription'}"
                class="text-black"
              >
                <strong>Subscription</strong>
              </router-link>
              .
            </li>
            <li>Click on <strong>Invoices</strong> tap</li>
            <li>Click on button <strong>Renew</strong></li>
            <li>Fill the information</li>
            <li>Click on button <strong>Submit</strong></li>
            <li>
              For more information, please contact
              <a
                href="https://figos.app"
                target="_blank"
                class="text-black"
              ><strong>Figos.app</strong></a>.
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "PlanExpiredMessage",
  computed: {
    ...mapGetters("shop", {
      is_expired: "is_expired"
    })
  }
}
</script>

<style scoped>

</style>
