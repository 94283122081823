<template>
  <div
    v-if="restaurant"
    class="row"
  >
    <div class="col-md-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("restaurant.label.logo") }}
          </h3>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-md-12">
              <div class="upload-cover-wrapper">
                <div class="upload-cover-body">
                  <div class="upload-cover-image">
                    <template v-if="restaurant.logo">
                      <img
                        :src="`${$config.base_url}/${restaurant.logo}`"
                        alt="shop logo"
                        class="img-responsive thumbnail"
                        style="margin-left: auto; margin-right: auto"
                      >
                    </template>
                    <template v-else>
                      <p class="text-muted">
                        {{ $t("string.noLogo") }}
                      </p>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 text-center">
              <button
                class="btn btn-primary"
                @click="toggleShow"
              >
                <i class="fas fa-upload" /> {{ $t("button.upload") }}
              </button>
            </div>

            <div class="col-md-12">
              <my-upload
                v-model="show"
                :headers="headers"
                :params="params"
                :url="`${$config.base_url}/api/restaurant/upload-logo`"
                field="logo"
                img-format="png"
                lang-type="en"
                @crop-success="cropSuccess"
                @crop-upload-fail="cropUploadFail"
                @crop-upload-success="cropUploadSuccess"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myUpload from "vue-image-crop-upload"

export default {
  components: { "my-upload": myUpload },
  name: "RestaurantUpload",
  data () {
    return {
      show: false,
      params: {
        name: "logo",
        shop_id: null
      },
      headers: {
        Authorization: localStorage.getItem("token"),
        Accept: "application/json"
      },
      imgDataUrl: "",
      restaurant: null
    }
  },
  methods: {
    toggleShow () {
      this.show = !this.show
    },
    cropSuccess (imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl
    },
    cropUploadSuccess (jsonData, field) {
      this.restaurant = jsonData.data
      this.$toastr("success", "The restaurant was updated", "Upload Logo")
    },
    cropUploadFail (status, field) {
    },
    getRestaurant () {
      const data = JSON.parse(localStorage.getItem("shop"))
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/restaurant/show", {
        shop_uuid: data.uuid
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1 && response.data.data !== null) {
          this.restaurant = response.data.data
        } else {
          this.$toastr("error", response.data.message, "Show restaurant")
        }
      })
    }
  },
  mounted () {
    const restaurant = JSON.parse(localStorage.getItem("shop"))
    if (restaurant !== null && restaurant !== undefined && restaurant !== "") {
      this.params.shop_id = restaurant.id
    }
    this.getRestaurant()
  }
}
</script>

<style scoped>
.choose-logo-img {
  width: 75%;
  margin: 15px 0;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
