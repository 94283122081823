<template>
  <div class="row">
    <div class="col-md-12 margin-top">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div>
            <h3 class="m-0 font-weight-bold text-primary payment-subtitle">
              {{ $t("label.viewOrder") }}
            </h3>
          </div>
          <div>
            <button
              class="btn btn-default"
              @click="$router.push({name: 'aps-list-order'})"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </button>
            <button
              class="btn btn-default"
              @click="$router.push({name: 'amusement-park-print', params: {order_uid: $route.params.order_uuid}})"
            >
              <i class="fas fa-print" /> {{ $t("button.print") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            v-if="ticketTypes.length > 0"
            class="row"
          >
            <div class="col-md-12">
              <h3>{{ shop.name }}</h3>
              <p>{{ shop.address }}</p>
              <p class="big-text">
                #{{ order.order_number }}
              </p>
              <p>{{ $t("string.whoResponsibility") }}: {{ invoice.cashier.full_name }}</p>
              <p>{{ $t("label.date") }}: {{ $moment(order.created_at).locale($i18n.locale).format("LLLL") }}</p>
            </div>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("string.ticketType") }}</th>
                    <th>{{ $t("string.totalPeople") }}</th>
                    <th>{{ $t("string.price") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ticketType, _key) in ticketTypes"
                    :key="_key"
                  >
                    <td>{{ ticketType.name }}</td>
                    <td>
                      {{ ticketType.total_people }}
                      <span v-if="ticketType.total_people > 1">{{ $t("label.people") }}</span>
                      <span v-else>{{ $t("label.person") }}</span>
                    </td>
                    <td>USD {{ parseFloat(ticketType.price).toFixed(2) }}</td>
                  </tr>
                  <template v-if="invoice !== null">
                    <tr>
                      <td
                        class="text-right text-muted"
                        colspan="2"
                      >
                        {{ $t("string.subtotal") }}
                      </td>
                      <td><strong>USD {{ parseFloat(invoice.subtotal).toFixed(2) }}</strong></td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted"
                        colspan="2"
                      >
                        {{ $t("string.discount") }}
                      </td>
                      <td>% {{ invoice.discount }}</td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted"
                        colspan="2"
                      >
                        {{ $t("string.tax") }}
                      </td>
                      <td>% {{ invoice.tax }}</td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted vertical-align"
                        colspan="2"
                      >
                        {{
                          $t("string.paymentAmount")
                        }}
                      </td>
                      <td>
                        <strong>
                          USD {{ parseFloat(invoice.total_paid_dollar).toFixed(2) }}
                          {{ $t("label.and") }}
                          KHR {{ parseFloat(invoice.total_paid_riel).toFixed(2) }}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted vertical-align"
                        colspan="2"
                      >
                        {{
                          $t("string.balanceDue")
                        }}
                      </td>
                      <td>
                        USD {{ parseFloat(invoice.balance_due_dollar).toFixed(2) }} {{ $t("label.or") }}
                        (KHR {{ parseFloat(invoice.balance_due_riel).toFixed(2) }})
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-right text-muted vertical-align"
                        colspan="2"
                      >
                        {{
                          $t("string.balanceOwe")
                        }}
                      </td>
                      <td>
                        USD {{ parseFloat(invoice.total_owe_dollar).toFixed(2) }} {{ $t("label.or") }}
                        (KHR {{ parseFloat(invoice.total_owe_riel).toFixed(2) }})
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewOrder",
  metaInfo () {
    return {
      title: "View Order",
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      ticketTypes: [],
      shop: null,
      invoice: null,
      order: null
    }
  },

  methods: {
    getOrder () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/amusement-park/order/view-order", {
        order_uuid: this.$route.params.order_uuid,
        shop_uuid: this.$config.shop_uuid
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.ticketTypes = response.data.data.ticketTypes
          this.shop = response.data.data.shop
          this.invoice = response.data.data.invoice
          this.order = response.data.data.order
        }
      })
    }
  },
  mounted () {
    this.getOrder()
  }
}
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vertical-align {
  vertical-align: top;
}
</style>
