export default {
  install (Vue, options) {
    Vue.prototype.$anotify = function (model, type = "save") {
      if (type.toLowerCase() === "save") {
        this.$toastr(this.$t("message.success"), this.$t("message.new", { model: model }), "success")
      } else if (type.toLowerCase() === "update" || type.toLowerCase() === "delete") {
        this.$toastr(this.$t("message.success"), this.$t("message.update", { model: model }), "info")
      } else {
        this.$toastr(this.$t("message.error"), this.$t("message.update", { model: model }), "danger")
      }
    }

    Vue.prototype.$alog = function (info) {
      if (process.env.NODE_ENV !== "production") {
        console.log(info)
      }
    }

    Vue.prototype.$aswaldelete = function (model) {
      this.$swal({
        title: this.$t("swal.delete.title"),
        text: this.$t("swal.delete.desc", { model: model }),
        type: "success",
        confirmButtonText: this.$t("button.ok")
      })
    }

    Vue.mixin({
      computed: {
        swalTitle () {
          return this.$root.$t("swal.confirm_delete.title")
        },
        swalDesc () {
          return this.$root.$t("swal.confirm_delete.desc")
        },
        swalYesButton () {
          return this.$root.$t("swal.confirm_delete.button.yes")
        },
        swalNoButton () {
          return this.$root.$t("swal.confirm_delete.button.no")
        },
        labelEdit () {
          return this.$t("label.edit")
        },
        labelDelete () {
          return this.$t("label.delete")
        },
        labelShowTrans () {
          return this.$t("label.show")
        },
        labelUpdate () {
          return this.$t("label.update")
        },
        labelPrintBarCode () {
          return this.$t("label.printBarCode")
        },
        labelAdjustStock () {
          return this.$t("label.adjustStock")
        },
        labelReduceStock () {
          return this.$t("label.reduceStock")
        },
        labelShowStock () {
          return this.$t("label.viewStock")
        },
        labelActive () {
          return this.$t("label.active")
        },
        labelInactive () {
          return this.$t("label.inactive")
        },
        labelResetPassword () {
          return this.$t("label.resetPassword")
        }
      },
      methods: {
        getSrc (src) {
          if (src.includes("http")) {
            return src
          } else {
            return this.$config.base_url + "/" + src
          }
        },
        random_uuidv4 () {
          return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0
            const v = c === "x" ? r : (r & 0x3 | 0x8)
            return v.toString(16)
          })
        },
        onResponseErrorDatatable (jqXHR, ajaxOptions, thrownError) {
          if (jqXHR.status) {
            const statusCode = jqXHR.status
            if (statusCode === 401) {
              this.onLogout()
            }
          }

          let message = thrownError
          const error = JSON.parse(jqXHR.responseText)

          if (error.message) {
            if (this.$bill_active === "true" && error.message.hasOwnProperty("plan_expired")) {
              const { plan_expired } = error.message
              this.$store.dispatch("shop/setExpired", plan_expired)
              this.$router.push({ name: "dashboard" })
            }
            if (message.hasOwnProperty("message_" + this.$i18n.locale)) {
              message = message["message_" + this.$i18n]
            }
          }

          this.$toastr("danger", message, this.$t("string.error"))
        },
        onResponseError (error) {
          if (error.response && error.response.status) {
            const statusCode = error.response.status
            if (statusCode === 401) {
              this.onLogout()
              return 0
            }
          }

          let message = ""
          if (error.response && error.response.statusText) {
            message = error.response.statusText
          }

          if (error.response && error.response.data && error.response.data.message) {
            const _message = error.response.data.message
            if (typeof _message === "object") {
              if (_message.hasOwnProperty("message_" + this.$i18n.locale)) {
                message = _message["message_" + this.$i18n.locale]
              }
              if (_message.hasOwnProperty("plan_expired")) {
                const { plan_expired } = _message
                this.$store.dispatch("shop/setExpired", plan_expired)
                this.$router.push({ name: "dashboard" })
              }
            }
          }

          this.$toastr("error", message, this.$t("string.error"))
        },
        checkValidationHasColumn (validations, column) {
          return validations !== null && validations.hasOwnProperty(column)
        },
        getValidationColumn (validations, column) {
          let message = {
            required: false,
            message: null
          }
          if (this.checkValidationHasColumn(validations, column)) {
            message = {
              required: true,
              message: validations[column][0]
            }
          }
          return message
        },
        clearEventHandler (classNames) {
          classNames.map((className) => {
            $(document).off("click", className)
          })
        },
        abort (code) {
          this.$router.push({ name: code })
        },
        swalSuccess () {
          this.$swal("Success", "The request have been processed", "success")
        },
        swalError () {
          this.$swal("Error", "There were some technical internal server", "error")
        },
        onResponseSuccess (message = null) {
          this.$toastr(
            "success",
            message || this.$t("string.theRequestHaveBeenSuccessfullyProcessed"),
            this.$t("string.success")
          )
        }
      }
    })
  }
}
