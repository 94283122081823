<template>
  <a
    class="btn btn-default"
    style="margin-right: 5px"
    @click="$router.back()"
  >
    <i class="fas fa-arrow-circle-left" />
    {{ $t("button.back") }}
  </a>
</template>

<script>
export default {
  name: "BackButton"
}
</script>

<style scoped>

</style>
