import { render, staticRenderFns } from "./TotalSoldCount.vue?vue&type=template&id=59f6881e&scoped=true&"
import script from "./TotalSoldCount.vue?vue&type=script&lang=js&"
export * from "./TotalSoldCount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59f6881e",
  null
  
)

export default component.exports