import recentSellLayout from "@/views/cashiers/recentSell/RecentSellLayout"
import recentSellList from "@/views/cashiers/recentSell/V2/List"
import recentSellShow from "@/views/cashiers/recentSell/Show"

export default {
  path: "recent-sell",
  name: "recent-sell",
  component: recentSellLayout,
  redirect: "/cashier/recent-sell/:uuid?",
  children: [
    {
      path: ":uuid?",
      name: "list-recent-sell",
      meta: {
        title: "Recent sell"
      },
      component: recentSellList
    },
    {
      path: "show-recent-sell/:uuid",
      name: "show-recent-sell",
      meta: {
        title: "Recent sell"
      },
      component: recentSellShow
    }
  ]
}
