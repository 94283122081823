<template>
  <div class="col-sm-12">
    <div class="tw-w-full mb-5">
      <div class="tw-mb-5">
        <h3 class="tw-m-0 tw-p-0 tw-hidden sm:tw-block tw-mt-5 tw-font-bold">
          {{ $t("string.additional_apps") }}
        </h3>
      </div>
      <div class="tw-w-full tw-flex tw-space-x-3">
        <div
          v-for="(apk, key) of apks"
          :key="key"
          class="tw-w-1/3 sm:tw-w-3/12 md:tw-w-2/12 lg:tw-w-1/12 tw-cursor-pointer"
          @click="download(apk)"
        >
          <div class="tw-bg-white tw-rounded-lg tw-border tw-shadow-md hover:tw-shadow-2xl tw-h-full">
            <img
              :alt="apk.title"
              :src="apk.image"
              class="tw-w-full tw-object-fit"
            >
            <div class="tw-p-3 tw-px-5">
              <p class="tw-text-xl tw-p-0 tw-m-0 tw-text-gray-400">
                {{ apk.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdditionalApps",
  data () {
    return {
      config: process.env
    }
  },
  computed: {
    apks () {
      return [
        {
          image: "/images/additional_apps/customer_display.png",
          url: "api/additional-apks/download/customer_display.apk",
          title: "Customer Display"
        },
        {
          image: "/images/additional_apps/usb_printer_service.png",
          url: "api/additional-apks/download/usb_printer_service.apk",
          title: "USB Printer Service"
        },
        {
          image: "/images/additional_apps/bluetooth_printer_service.png",
          url: "api/additional-apks/download/bluetooth_printer_service.apk",
          title: "Bluetooth Printer Service"
        }
      ]
    }
  },
  methods: {
    download (apk) {
      this.$isLoading(true)
      this.$axios({
        url: `${process.env.VUE_APP_API}/${apk.url}`,
        method: "GET",
        responseType: "blob"
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${apk.title}.apk`)
        document.body.appendChild(link)
        link.click()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
