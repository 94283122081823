<template>
  <div
    v-if="product && !product.is_custom_product"
    class="product-list-each-item bg-white rounded"
  >
    <div
      class="w-100 d-flex align-items-center position-relative"
      @click="checkProductHavVariant(product)"
    >
      <template v-if="checkVariant">
        <div class="product-has-variant">
          <i class="fas fa-plus-square icon" />
        </div>
      </template>
      <div class="product-list-each-item__photo user-select-none">
        <template v-if="product.hasOwnProperty('images') && product.images.length > 0">
          <div
            :style="`background-image: url('${$config.base_url}/${replaceImgUrl(product.images[0].src, 'thumbnail')}')`"
            class="product-list-each-item__image rounded-tl rounded-bl"
          />
        </template>
        <template v-else>
          <div
            :style="`background-image: url('${defaultImg}')`"
            class="product-list-each-item__image image_w-65 rounded-tl rounded-bl"
          />
        </template>
      </div>
      <div class="product-list-each-item__info py-0">
        <p
          class="product-each-item__name text-ellipsis-2-line product-each-item__name_one h-auto font-s-16 margin-bottom-none w-100"
        >
          {{ product.name }}
        </p>
        <p
          v-if="product.category"
          class="text-muted text-ellipsis product-each-item__name_one h-auto font-s-12 margin-bottom-none w-100"
        >
          {{ product.category["name_" + $i18n.locale] }}
        </p>
        <p
          v-if="product.barcode"
          class="font-s-14 margin-bottom-none"
        >
          <i class="fas fa-barcode mr-2" />
          {{ product.barcode }}
        </p>
        <p class="font-s-14 user-select-none margin-bottom-none">
          <template v-if="product.has_inventory">
            <label :class="{'text-red': product.amount_in_stock <= 0}">
              <i class="fas fa-shopping-basket mr-2" />
              {{ calcAmountInStock | numFormat(isFloat(parseFloat(calcAmountInStock))) }}
              <span class="font-s-12 font-weight-normal">
                {{ product.unit ? product.unit["name_" + $i18n.locale] : null }}
              </span>
            </label>
          </template>
          <template v-else>
            <i class="fas fa-shopping-basket mr-2" /> {{ $t("label.unlimited") }}
          </template>
        </p>

        <template v-if="product.sale_price">
          <p class="text-bold font-s-16 user-select-none minus no-margin text-ellipsis">
            <span v-if="showChildrenSalePrice.min">
              {{ product.currency ? product.currency.symbol : "$" }}
              <template v-if="product.currency && product.currency.code === 'KHR'">
                {{ showChildrenSalePrice.min | numFormat("0,0") }}
              </template>
              <template v-else>
                {{ showChildrenSalePrice.min | numFormat(num_format) }}
              </template>
            </span>
            <span v-if="showChildrenSalePrice.max">
              {{ product.currency ? product.currency.symbol : "$" }}
              <template v-if="product.currency && product.currency.code === 'KHR'">
                {{ showChildrenSalePrice.max | numFormat("0,0") }}
              </template>
              <template v-else>
                {{ showChildrenSalePrice.max | numFormat(num_format) }}
              </template>
            </span>
          </p>
          <div class="font-s-13">
            <template v-if="product.has_promotion">
              <del class="badge badge-danger badge-del-price">
                <em class="minus no-margin">
                  <span v-if="showChildrenOriginPrice.min">
                    {{ product.currency ? product.currency.symbol : "$" }}
                    <template v-if="product.currency && product.currency.code === 'KHR'">
                      {{ showChildrenOriginPrice.min | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ showChildrenOriginPrice.min | numFormat(num_format) }}
                    </template>
                  </span>
                  <span v-if="showChildrenOriginPrice.max">
                    {{ product.currency ? product.currency.symbol : "$" }}
                    <template v-if="product.currency && product.currency.code === 'KHR'">
                      {{ showChildrenOriginPrice.max | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ showChildrenOriginPrice.max | numFormat(num_format) }}
                    </template>
                  </span>
                </em>
              </del>
            </template>
          </div>
        </template>
      </div>
    </div>

    <SelectProductVariant
      ref="productVariantModal"
      :check-variant="checkVariant"
      :product="product"
      modal-id="list"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import SelectProductVariant from "@/components/product/SelectProductVariant"

export default {
  name: "AsoraProductItemList",
  components: { SelectProductVariant },
  props: {
    product: {
      type: Object,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    addClassNames: {
      type: String
    },
    variantTypes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      selectedVariant: state => state.order.selected_variants,
      order_products: state => state.order.orderProducts
    }),
    checkVariant () {
      if (this.product && this.product.children && this.product.children.length) {
        return true
      }
      return false
    },
    showChildrenSalePrice () {
      if (this.product.children_sale_price && this.product.children_sale_price.length > 0) {
        const max = Math.max(...this.product.children_sale_price)
        const min = Math.min(...this.product.children_sale_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.sale_price,
            min: null
          }
        }
      }
      return {
        max: this.product.sale_price,
        min: null
      }
    },
    showChildrenOriginPrice () {
      if (this.product.children_origin_price && this.product.children_origin_price.length > 0) {
        const max = Math.max(...this.product.children_origin_price)
        const min = Math.min(...this.product.children_origin_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.origin_price,
            min: null
          }
        }
      }
      return {
        max: this.product.origin_price,
        min: null
      }
    },
    calcAmountInStock () {
      let op = 0
      if (this.order_products.length) {
        this.order_products.forEach((item) => {
          if (item.parent_id && item.parent_id === this.product.id) {
            op += parseFloat(item.pivot.quantity)
          } else if (item.id === this.product.id) {
            op += parseFloat(item.pivot.quantity)
          }
        })
      }
      const pa = this.product.amount_in_stock
      const total = parseFloat(pa) + parseFloat(op)
      const re = parseFloat(total) - parseFloat(op)
      this.$set(this.product, "initAmountInStock", re)
      return this.product.initAmountInStock
    }
  },
  data () {
    return {
      origin_url: process.env.VUE_APP_ORIGIN_URL
    }
  },
  methods: {
    addToCart (product) {
      this.$store.dispatch("order/addProductToCart", product)
      this.$store.dispatch("order/setDisplayPo", this)
    },

    checkProductHavVariant (product) {
      if (this.checkVariant) {
        this.$refs.productVariantModal.toggleModal()
      } else {
        this.addToCart(product)
      }
    }
  }
}
</script>

<style scoped>
.product-list-each-item {
  width: calc(50% - 16px);
  box-shadow: 0 0 10px #0000001a;
  margin: 8px;
  cursor: pointer;
}

.product-list-each-item:hover {
  background-color: #f4f4f4b5;
  box-shadow: 0 0 10px #30303030;
}

.product-list-each-item__photo {
  width: 130px;
}

.product-list-each-item__info {
  padding: 8px 15px;
  width: calc(100% - 130px);
}

.product-each-item__name_one {
  -webkit-line-clamp: 1 !important;
}

.badge-del-price {
  font-size: 11px !important;
  border-radius: 4px !important;
}

@media screen and  (max-width: 1200px) {
  .product-list-each-item {
    width: 100%;
    margin: 8px 16px;
  }
}

@media screen and  (max-width: 767px) {
  .product-list-each-item {
    margin: 8px;
  }
}
</style>
