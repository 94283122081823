<template>
  <div class="customer-page-container w-100 bg-white h-100">
    <div class="block-invoice-list h-100">
      <div class="tabBlock-pane d-flex flex-column">
        <div class="form-group d-flex align-items-center justify-content-between user-select-none">
          <label class="title-section">{{ $t("strings.listingCustomerGroups") }}</label>
          <button
            class="btn btn-success btn-lg no-margin show-customer-sidebar-detail no-margin"
            @click="showFormCreateCustomer = !showFormCreateCustomer"
          >
            <i class="fas fa-plus" />
            <span class="hidden-sm hidden-md ml-2">{{ $t("label.add") }}</span>
          </button>
        </div>
        <div class="form-group">
          <div class="w-100 box-search">
            <div class="box-search-icon margin-l-5">
              <i class="fas fa-search" />
            </div>
            <input
              v-model="search_query"
              :placeholder="$t('label.search') + '...'"
              class="pos-input-search pos-input-lg no-border"
              type="text"
              @keyup="getCustomerGroup(1)"
            >
          </div>
        </div>
        <template v-if="onLoading">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <div
            v-if="list_customer_groups.length > 0"
            class="list-sugg-customer overflow-y-auto scrollbar user-select-none flex-1"
          >
            <div class="col-xs-12 no-padding">
              <div class="sugg-customer-each sugg-customer-header d-flex">
                <div class="text-bold font-s-16 w-20">
                  #
                </div>
                <div class="text-bold font-s-16 w-60">
                  {{ $t("label.name") }}
                </div>
                <div class="text-bold font-s-16 w-20 text-center">
                  {{ $t("label.customer") }}
                </div>
              </div>
            </div>
            <template v-for="(item, key) in list_customer_groups">
              <div
                :key="key"
                class="col-xs-12 no-padding show-customer-sidebar-detail"
              >
                <a @click="onClickSelectCustomerGroup(item)">
                  <div
                    :class="{'active': customerGroupDetail && item.uuid === customerGroupDetail.uuid}"
                    class="sugg-customer-each d-flex"
                  >
                    <div class="font-s-16 w-20">{{ key + 1 }}</div>
                    <div class="font-s-16 w-60">{{ item["name_" + $i18n.locale] }}</div>
                    <div class="font-s-16 w-20 text-center">{{ item.customers_count }}</div>
                  </div>
                </a>
              </div>
            </template>
            <infinite-loading
              :identifier="infiniteCustomerGroupId"
              spinner="spiral"
              @infinite="infiniteHandlerCustomerGroupList"
            >
              <div slot="no-more" />
              <div slot="no-results">
                {{ $t("label.no_results_message") }}
              </div>
              <div
                slot="error"
                slot-scope="{ trigger }"
              >
                Error message, click <a
                  href="javascript:void(0)"
                  @click="trigger"
                >here</a> to retry
              </div>
            </infinite-loading>
          </div>
          <template v-else>
            <no-any-records>
              <div
                v-if="!search_query"
                class="d-block w-100 text-center margin-top"
              />
            </no-any-records>
          </template>
        </template>
      </div>
    </div>
    <div class="block-invoice-detail cus-detail h-100">
      <div class="tabBlock-pane d-flex flex-column overflow-y-auto scrollbar">
        <template v-if="onLoadingDetail">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <div
            v-if="customerGroupDetail"
            class="order-info w-100"
          >
            <div class="form-group d-flex align-items-center justify-content-between w-100 text-capitalize">
              <div>
                <button
                  class="btn btn-default btn-lg no-margin btn-close-cus-detail-sm"
                  @click="closeCustomerDetailSm"
                >
                  <i class="fas fa-arrow-left" />
                </button>
              </div>
              <div>
                <button
                  class="btn btn-default btn-lg no-margin"
                  @click="showFormEditCustomer = !showFormEditCustomer "
                >
                  <i class="fas fa-edit mr-2" />
                  {{ $t("label.edit") }}
                </button>
              </div>
            </div>
            <div class="row no-margin user-select-none">
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong class="mr-2">{{ $t("label.nameKm") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ customerGroupDetail.name_km }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong class="mr-2">{{ $t("label.nameEn") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ customerGroupDetail.name_en }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="customerGroupDetail.description"
                class="col-xs-12 no-padding has-border-b"
              >
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong class="mr-2">{{ $t("fields.label.description") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ customerGroupDetail.description }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("label.showOption") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <toggle-button
                        :font-size="16"
                        :height="30"
                        :labels="{checked: $t('label.active'), unchecked: $t('label.inactive')}"
                        :sync="true"
                        :value="customerGroupDetail.active"
                        :width="100"
                        color="#1876f2"
                        @change="toggleActive(customerGroupDetail.uuid)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("table.membership.createdAt") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      {{ $moment(customerGroupDetail.created_at).locale($i18n.locale).format(dateFormatLocale) }}
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="customerGroupDetail.customers.length">
                <div class="col-xs-12 no-padding">
                  <div class="text-capitalize cus-det-label">
                    <strong>{{ $t("label.customer") }}</strong>
                  </div>
                </div>
                <div class="col-xs-12 no-padding">
                  <div class="list-sugg-customer overflow-y-auto scrollbar user-select-none flex-1">
                    <template v-for="(item, key) in customerGroupDetail.customers">
                      <div
                        :key="key"
                        class="col-xs-12 no-padding"
                      >
                        <div
                          class="sugg-customer-each"
                          style="cursor: initial"
                        >
                          <div>
                            <!--order_count: It's the number of orders owed on the invoice-->
                            <span class="sugg-cus-name mr-2">{{ item.name }}</span>
                            <span v-if="item.gender">( {{ item.gender["name_" + $i18n.locale] }} )</span>
                          </div>
                          <div class="sugg-cus-contact">
                            <span v-if="item.tel">{{ item.tel }}</span>
                            <span v-if="item.address">{{ item.address }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center justify-content-center w-100 h-100"
          >
            <p class="font-s-18 capitalize">
              {{ $t("string.noCustomerGroupSelected") }}
            </p>
          </div>
        </template>
      </div>
      <template v-if="showFormCreateCustomer">
        <DrawerCustomerGroup
          :show-drawer="showFormCreateCustomer"
          @closeDrawer="closeDrawer"
          @refreshData="getCustomerGroup(1)"
        />
      </template>
      <template v-if="showFormEditCustomer">
        <DrawerCustomerGroup
          :old-data="customerGroupDetail"
          :show-drawer="showFormEditCustomer"
          @closeDrawer="closeDrawer"
          @refreshData="getCustomerGroup(1)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce"
import NoAnyRecords from "@/components/NoAnyRecords"
import DrawerCustomerGroup from "@/views/customers/customerGroups/V2/_components/DrawerCustomerGroup"

export default {
  name: "CustomerGroupList",
  metaInfo () {
    return {
      title: this.$t("label.customerGroup"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    DrawerCustomerGroup,
    NoAnyRecords
  },
  data () {
    return {
      list_customer_groups: [],
      search_query: null,
      onLoading: true,
      onLoadingDetail: false,
      customerGroupDetail: null,
      page: 1,
      infiniteCustomerGroupId: +new Date(),
      showFormCreateCustomer: false,
      showFormEditCustomer: false
    }
  },
  mounted () {
    this.getCustomerGroup(1)
  },
  methods: {
    toggleActive (uuid) {
      this.$axios.post(this.$config.base_url + "/api/v2/customer-group/toggle", {
        uuid: uuid
      }).then((res) => {
        this.customerGroupDetail = res.data.data
      }).catch((err) => {
        if (err.response.status === 422) {
          this.validation = err.response.data.errors
        } else {
          this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
        }
      })
    },
    deleteCustomerGroup (uuid) {
      this.$swal({
        title: this.swalTitle,
        text: this.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.swalYesButton,
        cancelButtonText: this.swalNoButton
      }).then(() => {
        this.$isLoading(true)
        this.$axios.post(`${this.$config.base_url}/api/v2/customer-group/delete`, {
          uuid: uuid,
          shop_uuid: this.$config.shop_uuid
        }).then(() => {
          this.customerGroupDetail = null
          this.getCustomerGroup(1)
        }).catch((err) => {
          if (err.response.status === 422) {
            this.validation = err.response.data.errors
          } else {
            this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
          }
        }).finally(() => {
          this.$isLoading(false)
        })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },
    closeDrawer () {
      this.showFormCreateCustomer = false
      this.showFormEditCustomer = false
    },
    onClickSelectCustomerGroup (item) {
      this.onLoadingDetail = true
      this.selectCustomerGroup(item)
    },
    selectCustomerGroup: debounce(function (item) {
      this.showFormCreateCustomer = false
      this.showFormEditCustomer = false
      this.$axios.post(this.$config.base_url + "/api/v2/customer-group/show", {
        uuid: item.uuid
      }).then((res) => {
        this.customerGroupDetail = res.data.data
      }).catch((err) => {
        this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
      }).finally(() => {
        this.onLoadingDetail = false
      })
    }, 800),
    getCustomerGroup (page) {
      this.onLoading = true
      this.page = 1
      if (page) {
        this.page = page
      }
      this.list_customer_groups = []
      this.customerGroupDetail = null
      this.infiniteCustomerGroupId += 1
      this.infiniteHandlerCustomerGroupList()
    },
    infiniteHandlerCustomerGroupList: debounce(function ($state) {
      this.$axios
        .post(this.$config.base_url + "/api/v2/customer-group/list", {
          shop_uuid: this.$config.shop_uuid,
          page: this.page,
          search_query: this.search_query
        })
        .then(({ data }) => {
          if (!(this.page > data.total_pages)) {
            this.page += 1
            this.list_customer_groups.push(...data.data)
            if ($state) {
              $state.loaded()
            }
          } else {
            if (data.data) {
              $state.loaded()
              $state.complete()
            }
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.onLoading = false
        })
    }, 800)
  }
}
</script>

<style scoped>
@import "../../../../assets/css/V2/customer.css";
</style>
