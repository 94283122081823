<template>
  <span class="restau-name">
    <router-link to="/dashboard">
      <div v-if="shop !== null">
        <i class="fas fa-home mr-3" />
        <span>{{ shop.name }}</span>
      </div>
    </router-link>
  </span>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "BrandName",
  computed: {
    ...mapState({
      shop: state => state.shop.shop
    })
  }
}
</script>
