<template>
  <div>
    <div class="asora-list-data-header">
      <div class="block-search-box">
        <div class="box-search box-search-mb w-100">
          <div class="box-search-icon margin-l-5">
            <i class="fas fa-search" />
          </div>
          <input
            v-model="search_query"
            :placeholder="$t('label.search')"
            class="pos-input-search pos-input-lg no-border"
            type="text"
          >
        </div>
      </div>
    </div>
    <div class="asora-list-data-body scrollbar">
      <template v-if="onLoading">
        <div
          class="d-flex align-items-center justify-content-center on-loading-layout"
          style="height: 50vh;"
        >
          <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
        </div>
      </template>
      <template v-else>
        <template v-if="list_data && Array.isArray(list_data) && list_data.length">
          <template v-for="(item, key) in list_data">
            <div
              v-if="!item.is_custom_product"
              :key="key"
              class="list-data-item rounded position-relative"
            >
              <div
                class="list-data-item-content list-data-item-content-pt list-data-item-content-full user-select-none"
              >
                <div class="list-data-item-btn-action">
                  <div class="btn-group">
                    <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-link dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                    >
                      <i class="fas fa-ellipsis-v text-muted" />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li>
                        <router-link :to="{name: route_name_show, params: {uuid: item.uuid}}">
                          <i class="fas fa-eye" />
                          {{ $t("button.show") }}
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          :to="{name: route_name_edit, params: {uuid: item.uuid}}"
                          href="#"
                        >
                          <i class="fas fa-edit" />
                          {{ $t("button.edit") }}
                        </router-link>
                      </li>
                      <li>
                        <a
                          class="text-red"
                          @click="deleteItem(item.uuid)"
                        >
                          <i class="fas fa-trash-alt" />
                          {{ $t("button.delete") }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="text-ellipsis font-s-16">
                  {{ item.name }}
                  <span
                    v-if="item.amount_percentage"
                    class="ml-3"
                  >
                    ( {{
                      item.amount_percentage | numFormat(number_format)
                    }} {{ item.is_percentage ? "%" : shop_curreny ? shop_curreny.code : "USD" }})
                  </span>
                  <i
                    v-if="item.active"
                    class="fas fa-check-circle ml-3 text-success"
                  />
                </div>
                <div
                  v-if="item.start || item.end"
                  class="text-ellipsis text-muted minus"
                >
                  <span v-if="item.start">{{ $moment(item.start).locale("en").format("DD/MM/YYYY hh:mm:ss") }}</span>
                  <span v-if="item.end">{{ $moment(item.end).locale("en").format("DD/MM/YYYY hh:mm:ss") }}</span>
                </div>
                <div
                  v-if="item.description"
                  class="text-ellipsis"
                >
                  {{ item.description }}
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="list-data-no_data">
            {{ $t("label.noData") }}
          </div>
        </template>
        <infinite-loading
          :identifier="infiniteId"
          spinner="spiral"
          @infinite="infiniteHandler"
        >
          <div slot="no-more" />
        </infinite-loading>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce"
import { mapState } from "vuex"

export default {
  name: "PromotionDataTableSm",
  props: {
    windowWidth: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: null
    }
  },
  data () {
    return {
      api_get_data: "/api/promotion/get-list-data",
      api_delete: "/api/promotion/delete",
      route_name_edit: "edit-promotion",
      route_name_show: "show-promotion",
      search_query: null,
      infiniteId: +new Date(),
      list_data: [],
      number_per_page: 50,
      onLoading: true
    }
  },
  watch: {
    search_query () {
      this.onLoading = true
      if (!this.awaitingSearch) {
        if (this.time_out) {
          clearTimeout(this.time_out)
        }
        this.time_out = setTimeout(() => {
          this.search()
          this.awaitingSearch = false
        }, 2000)
      }
      this.awaitingSearch = true
    },
    windowWidth: function () {
      if (this.windowWidth && this.windowWidth < 769 && this.list_data.length === 0) {
        this.getListData(1)
      }
    }
  },
  computed: {
    ...mapState({
      shop: state => state.shop.shop,
      shop_curreny: state => state.shop.currency,
      number_format: state => state.shop.numFormat
    })
  },
  mounted () {
    if (this.windowWidth && this.windowWidth < 769) {
      this.getListData(1)
    }
  },
  methods: {
    search () {
      this.getListData(1)
    },
    getListData (page = null) {
      this.page = 1
      if (page) {
        this.page = page
      }
      this.list_data = []
      this.infiniteId += 1
      this.infiniteHandler()
    },
    infiniteHandler: debounce(function ($state) {
      this.$axios
        .post(this.$config.base_url + this.api_get_data, {
          page: this.page,
          shop_uuid: this.shop.uuid,
          search_query: this.search_query,
          number_per_page: this.number_per_page
        })
        .then(({ data }) => {
          if (!(this.page > data.total_pages)) {
            this.page += 1
            this.list_data.push(...data.data)
            if ($state) {
              $state.loaded()
            }
          } else {
            $state.loaded()
            $state.complete()
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.onLoading = false
        })
    }, 500),
    deleteItem (uuid) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.$config.base_url + this.api_delete, {
          shop_id: self.get_shop.id,
          uuid: uuid
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.promotion"))
            this.getListData(1)
          }
        }, (dismiss) => {
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          throw err
        })
      })
    }
  }
}
</script>

<style scoped>
@import "./../../../../assets/css/V2/list-data-table-sm.css";
</style>
