import Vue from "vue"
import Router from "vue-router"
import {
  forgetPasswordRouter,
  loginRouter,
  registerRouter,
  renewPasswordRouter,
  secondCustomerDisplayRouter,
  sendMailRouter
} from "@/routers/singleRouters"
import { allowAccess } from "@/features"
import { primaryRouters } from "@/routers/primaryRouters"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    loginRouter,
    registerRouter,
    sendMailRouter,
    forgetPasswordRouter,
    secondCustomerDisplayRouter,
    renewPasswordRouter,
    primaryRouters
  ]
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem("user"))
  const token = localStorage.getItem("token")
  const publicRoutes = to.meta.hasOwnProperty("auth") && !to.meta.auth

  if (user && token && (user.roles && Array.isArray(user.roles) && user.roles.length > 0)) {
    const is_plan_expired = router.app.$store.getters["shop/is_expired"]
    const userRoles = user.roles
    if (publicRoutes) {
      next("/dashboard")
    } else {
      if (allowAccess(userRoles, to)) {
        if (is_plan_expired && !to.path.startsWith("/admin/settings/subscription")) {
          next("/dashboard")
        } else {
          next()
        }
      } else {
        if (["dashboard", "overview", "display-order", "create-shop"].includes(to.name)) {
          next()
        } else {
          next("/dashboard")
        }
      }
    }
  } else {
    logout()
    if (publicRoutes) {
      next()
    } else {
      next("/")
    }
  }
})

const logout = () => {
  const locale = localStorage.getItem("locale")
  const cover = localStorage.getItem("cover")
  const logo = localStorage.getItem("logo")
  const view_mode = localStorage.getItem("view_mode")
  const token_device = localStorage.getItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE)
  const token_active_device = localStorage.getItem(process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE)

  localStorage.clear()
  localStorage.setItem("locale", locale)
  localStorage.setItem("cover", cover)
  localStorage.setItem("logo", logo)
  localStorage.setItem("view_mode", view_mode)
  localStorage.setItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE, token_device)
  localStorage.setItem(process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE, token_active_device)
}

const originalPush = router.push
router.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

export default router
