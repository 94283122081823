<template>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label
          class="required"
          for="plan"
        >{{ $t("string.plan") }}</label>
        <input
          id="plan"
          v-model="selectedPlan.title"
          class="form-control"
          readonly
          type="text"
        >
      </div>

      <div class="form-group">
        <label
          class="required"
          :for="'monthly'"
        >{{ $t("string.monthly") }}</label>
        <select
          id="monthly"
          v-model="duration"
          class="form-control"
        >
          <option :value="6">
            6 Months
          </option>
          <option :value="12">
            12 Months
          </option>
        </select>
      </div>

      <div class="form-group">
        <label
          class="required"
          for="price"
        >{{ $t("string.price") }}</label>
        <div class="input-group">
          <input
            id="price"
            :value="price"
            class="form-control"
            readonly
            type="text"
          >
          <span class="input-group-addon">{{ selectedPlan.currency_symbol }}</span>
        </div>
      </div>

      <div
        :class="{'has-error' : validations.document}"
        class="form-group"
      >
        <label
          class="required"
          for="receipt"
        >{{ $t("string.reference") }}</label>
        <input
          id="receipt"
          ref="document"
          accept="application/pdf,image/*"
          class="form-control"
          type="file"
        >
        <span
          v-if="validations.document"
          class="help-block"
        >{{ validations["document"][0] }}</span>
        <span class="help-block">
          {{ $t("string.pleaseUploadPaymentTransaction") }}
        </span>
      </div>

      <div class="form-group btn-submit-plan">
        <button
          class="btn btn-default"
          @click="setStep(1)"
        >
          {{ $t("button.back") }}
        </button>
        <button
          class="btn btn-primary"
          @click="submit"
        >
          {{ $t("button.submit") }}
        </button>
      </div>
    </div>
    <div class="col-sm-6">
      <AccountPaymentInfo />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import AccountPaymentInfo from "@/views/settings/subscription/components/AccountPaymentInfo"

export default {
  name: "SubscriptionChargePlanForm",
  data () {
    return {
      duration: 6,
      validations: {}
    }
  },
  components: {
    AccountPaymentInfo
  },
  computed: {
    ...mapGetters("subscription", {
      selectedPlan: "selectedPlan",
      currentPlan: "currentPlan"
    }),
    price () {
      if (this.selectedPlan) {
        if (this.selectedPlan.title === "Standard") {
          if (this.duration === 6) {
            return this.selectedPlan.price * 6
          }
          return 132
        } else if (this.selectedPlan.title === "Premium") {
          if (this.duration === 6) {
            return this.selectedPlan.price * 6
          }
          return 275
        }
      }
      return 0
    }
  },
  methods: {
    ...mapActions("subscription", {
      setStep: "setStep",
      setSelectedPlan: "setSelectedPlan"
    }),
    submit () {
      this.validations = {}
      const params = new FormData()

      params.set("to_plan_id", this.selectedPlan.id)
      params.set("from_plan_id", this.currentPlan.id)
      params.set("duration", this.duration)
      params.set("price", this.price)

      if (this.$refs.document.files[0]) {
        params.set("document", this.$refs.document.files[0])
      } else {
        params.delete("document")
      }

      this.$isLoading(true)
      this.$axios.post(this.$base_api + "/api/bill/change-plan", params)
        .then(({ data }) => {
          if (data.data) {
            this.$refs.document.value = null
            this.setSelectedPlan(this.currentPlan)
            this.$toastr("success", this.$t("string.yourRequestHasBeenSubmitted"), this.$t("string.changePlan"))
            this.setStep(1)
            this.$emit("onSubmit")
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .btn-submit-plan {
    text-align: right;
  }
}
</style>
