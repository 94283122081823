<template>
  <div class="row">
    <div class="col-xs-12 col-md-9">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            <span>{{ $t("button.track_inventory") }}</span>
          </h3>
          <div class="box-tools">
            <button
              class="btn btn-default btn-sm hidden-xs"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" />
              {{ $t("button.back") }}
            </button>
          </div>
        </div>
        <div class="box-body">
          <template v-if="product">
            <div class="row">
              <div class="col-xs-6 col-md-3">
                <div class="form-group">
                  {{ $t("fields.label.name") }}
                </div>
              </div>
              <div class="col-xs-6 col-md-9">
                <div class="form-group">
                  {{ product.name }}
                </div>
              </div>
            </div>
            <div
              v-if="product.barcode"
              class="row"
            >
              <div class="col-xs-6 col-md-3">
                <div class="form-group">
                  {{ $t("string.barcode") }}
                </div>
              </div>
              <div class="col-xs-6 col-md-9">
                <div class="form-group">
                  {{ product.barcode }}
                </div>
              </div>
            </div>
            <div
              v-if="product.sku"
              class="row"
            >
              <div class="col-xs-6 col-md-3">
                <div class="form-group">
                  {{ $t("string.sku") }}
                </div>
              </div>
              <div class="col-xs-6 col-md-9">
                <div class="form-group">
                  {{ product.sku }}
                </div>
              </div>
            </div>
            <div
              v-if="product.sale_price"
              class="row"
            >
              <div class="col-xs-6 col-md-3">
                <div class="form-group">
                  {{ $t("fields.label.salePrice") }}
                </div>
              </div>
              <div class="col-xs-6 col-md-9">
                <div class="form-group">
                  {{ product.sale_price | numFormat(isFloat(product.sale_price)) }}
                  {{ product.currency ? product.currency.code : null }}
                </div>
              </div>
            </div>
            <div
              v-if="product.unit"
              class="row"
            >
              <div class="col-xs-6 col-md-3">
                <div class="form-group">
                  {{ $t("fields.label.unit") }}
                </div>
              </div>
              <div class="col-xs-6 col-md-9">
                <div class="form-group">
                  {{ product.unit["name_" + $i18n.locale] }}
                </div>
              </div>
            </div>
            <div
              v-if="product.images && product.images.length"
              class="row"
            >
              <div
                v-for="(image, key) in product.images"
                :key="key"
                class="col-xs-4 col-sm-3 col-md-2 form-group"
              >
                <img
                  :src="getSrc(image.src)"
                  alt=""
                  class="img-thumbnail"
                >
              </div>
            </div>
            <template v-if="product.children && product.children.length">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("product.fields.label.stockAlertLevel") }}</label>
                    <input
                      v-model="product.stock_alert_level"
                      :placeholder="$t('product.fields.placeholder.stockAlertLevel')"
                      class="form-control input-lg"
                      type="number"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 form-group text-bold">
                  <hr>
                  {{ $t("product.label.variants") }}: {{ product.children.length }}
                </div>
              </div>
              <template v-for="(child, key) in product.children">
                <div
                  :class="{'product-children-has-error': child.errorMessage}"
                  :key="key"
                  class="product-children"
                >
                  <div
                    v-if="child.product_variants && child.product_variants.length"
                    class="row"
                  >
                    <div class="col-md-12 d-flex align-items-center justify-content-between">
                      <div class="slash">
                        <template v-for="(variant, v_key) in child.product_variants">
                          <span
                            v-if="variant.pivot && variant.pivot.value"
                            :key="v_key"
                          >
                            {{ $i18n.locale === "km" ? variant.name_km : variant.name }}:
                            <strong>{{ variant.pivot.value }}</strong>
                          </span>
                        </template>
                      </div>
                      <div>
                        <a
                          :aria-controls="'collapseProductChild' + key"
                          :href="'#collapseProductChild' + key"
                          aria-expanded="true"
                          class="btn btn-default btn-sm"
                          data-toggle="collapse"
                          role="button"
                          @click="child.show_collapse = !child.show_collapse"
                        >
                          <i
                            :class="child.show_collapse ? 'fa-chevron-up' : 'fa-chevron-down'"
                            class="fas"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    :id="'collapseProductChild' + key"
                    class="collapse in margin-top"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div
                          :class="{'has-error': child.errorMessage && child.errorMessage.hasOwnProperty('warehouse')}"
                          class="form-group"
                        >
                          <label class="required">{{ $t("product.fields.label.warehouse") }}</label>
                          <multiselect
                            v-model="child.warehouse"
                            :allow-empty="false"
                            :label="'name_' + $i18n.locale"
                            :option-height="104"
                            :options="warehouses"
                            :placeholder="$t('product.fields.placeholder.chooseWarehouse')"
                            :show-labels="false"
                            track-by="id"
                          />
                          <template v-if="child.errorMessage && child.errorMessage.hasOwnProperty('warehouse')">
                            <small class="help-block">
                              {{ child.errorMessage.warehouse[0] }}
                            </small>
                          </template>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          :class="{'has-error': child.errorMessage && child.errorMessage.hasOwnProperty('amount')}"
                          class="form-group"
                        >
                          <label>{{ $t("product.fields.label.amount") }}</label>
                          <div :class="{'input-group': child.unit}">
                            <input
                              v-model="child.amount"
                              :placeholder="$t('product.fields.placeholder.amount')"
                              class="form-control input-lg"
                              type="number"
                            >
                            <div
                              v-if="child.unit"
                              class="input-group-addon"
                            >
                              {{ child.unit["name_" + $i18n.locale] }}
                            </div>
                          </div>
                          <template v-if="child.errorMessage && child.errorMessage.hasOwnProperty('amount')">
                            <small class="help-block">
                              {{ child.errorMessage.amount[0] }}
                            </small>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("product.fields.label.importedPrice") }}</label>
                          <div :class="{'input-group': child.currency}">
                            <input
                              v-model="child.imported_price"
                              :placeholder="$t('product.fields.placeholder.importedPrice')"
                              class="form-control input-lg"
                              type="number"
                            >
                            <div
                              v-if="child.currency"
                              class="input-group-addon"
                            >
                              {{ child.currency.code }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("product.fields.label.expiredDate") }}</label>
                          <date-picker
                            v-model="child.expired_date"
                            :input-class="'form-control date-picker-lg'"
                            :placeholder="$t('product.fields.placeholder.expiredDate')"
                            format="YYYY-MM-DD"
                            lang="en"
                            style="width: 100%"
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-md-6">
                  <div
                    :class="{'has-error': this.validations && this.validations.hasOwnProperty('warehouse')}"
                    class="form-group"
                  >
                    <label class="required">{{ $t("product.fields.label.warehouse") }}</label>
                    <multiselect
                      v-model="product.warehouse"
                      :allow-empty="false"
                      :label="'name_' + $i18n.locale"
                      :option-height="104"
                      :options="warehouses"
                      :placeholder="$t('product.fields.placeholder.chooseWarehouse')"
                      :show-labels="false"
                      track-by="id"
                    />
                    <template v-if="this.validations && this.validations.hasOwnProperty('warehouse')">
                      <small class="help-block">
                        {{ this.validations.warehouse[0] }}
                      </small>
                    </template>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    :class="{'has-error': this.validations && this.validations.hasOwnProperty('amount')}"
                    class="form-group"
                  >
                    <label class="required">{{ $t("product.fields.label.amount") }}</label>
                    <div :class="{'input-group': product.unit}">
                      <input
                        v-model="product.amount"
                        :placeholder="$t('product.fields.placeholder.amount')"
                        class="form-control input-lg"
                        type="number"
                      >
                      <div
                        v-if="product.unit"
                        class="input-group-addon"
                      >
                        {{ product.unit["name_" + $i18n.locale] }}
                      </div>
                    </div>
                    <template v-if="this.validations && this.validations.hasOwnProperty('amount')">
                      <small class="help-block">
                        {{ this.validations.amount[0] }}
                      </small>
                    </template>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("product.fields.label.importedPrice") }}</label>
                    <div :class="{'input-group': product.currency}">
                      <input
                        v-model="product.imported_price"
                        :placeholder="$t('product.fields.placeholder.importedPrice')"
                        class="form-control input-lg"
                        type="number"
                      >
                      <div
                        v-if="product.currency"
                        class="input-group-addon"
                      >
                        {{ product.currency.code }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("product.fields.label.stockAlertLevel") }}</label>
                    <input
                      v-model="product.stock_alert_level"
                      :placeholder="$t('product.fields.placeholder.stockAlertLevel')"
                      class="form-control input-lg"
                      type="number"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("product.fields.label.expiredDate") }}</label>
                    <date-picker
                      v-model="product.expired_date"
                      :input-class="'form-control date-picker-lg'"
                      :placeholder="$t('product.fields.placeholder.expiredDate')"
                      format="YYYY-MM-DD"
                      lang="en"
                      style="width: 100%"
                      type="date"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="d-flex align-items-center justify-content-center">
              {{ $t("label.productNotFound") }}
            </div>
          </template>
        </div>
        <div
          v-if="product"
          class="box-footer text-right"
        >
          <button
            class="btn btn-default hidden-xs"
            @click="$router.back()"
          >
            <i class="fas fa-arrow-circle-left" />
            {{ $t("button.back") }}
          </button>
          <button
            class="btn btn-primary"
            @click="storeProduct"
          >
            <i class="fas fa-check-circle" />
            {{ $t("button.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"

export default {
  name: "ProductTrackInventory",
  metaInfo () {
    return {
      title: this.$t("button.track_inventory"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: { DatePicker },
  data () {
    return {
      config: this.$config,
      product: null,
      warehouses: [],
      flagValidateChild: false,
      validations: null
    }
  },
  mounted () {
    this.getProduct()
  },
  methods: {
    getProduct () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product/get-product-by-id", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          const result = response.data.data
          if (result.product.has_inventory) {
            this.$router.back()
            this.$toastr("")
          } else {
            this.product = result.product
            this.warehouses = result.warehouses
            if (this.product) {
              this.$set(this.product, "amount", 0)
              this.$set(this.product, "is_parent", !this.product.parent_id)
              this.$set(this.product, "stock_alert_level", 0)
              this.$set(this.product, "expired_date", null)
              this.$set(this.product, "imported_price", this.product.imported_price)
              if (this.warehouses && this.warehouses.length) {
                this.$set(this.product, "warehouse", this.warehouses[0])
              }
              if (this.product.children && this.product.children.length) {
                this.product.children.forEach((item) => {
                  this.$set(item, "amount", 0)
                  this.$set(item, "show_collapse", true)
                  this.$set(item, "errorMessage", null)
                  this.$set(item, "expired_date", null)
                  this.$set(item, "imported_price", this.product.imported_price)
                  if (this.warehouses && this.warehouses.length) {
                    this.$set(item, "warehouse", this.warehouses[0])
                  }
                })
              }
            }
          }
        }
        if (response.data.code === 0) {
          if (typeof (response.data.message) === "object") {
            this.message_validation = response.data.message
          } else {
            this.$toastr("error", response.data.message, this.$t("menu.inventories.products.product"))
          }
        }
      })
    },
    validateVariant () {
      this.flagValidateChild = false
      if (this.product && this.product.children && this.product.children.length > 0) {
        this.product.children.forEach((eachChild) => {
          let message = null
          this.$set(eachChild, "errorMessage", null)
          if (!eachChild.warehouse) {
            this.flagValidateChild = true
            message = {
              warehouse: ["The warehouse field is required."]
            }
          }
          if (eachChild.amount === null || eachChild.amount === "") {
            this.flagValidateChild = true
            if (message) {
              message.amount = ["The amount field is required."]
            } else {
              message = {
                amount: ["The amount field is required."]
              }
            }
          }
          this.$set(eachChild, "errorMessage", message)
        })
      }
    },
    storeProduct () {
      this.$isLoading(true)
      this.validateVariant()
      if (this.product) {
        const data = {
          flagValidateChild: this.flagValidateChild,
          suuid: this.get_shop.uuid,
          uuid: this.product.uuid,
          is_parent: this.product.is_parent,
          has_inventory: true,
          warehouse: this.product.warehouse,
          amount: this.product.amount,
          imported_price: this.product.imported_price,
          expired_date: this.product.expired_date,
          stock_alert_level: this.product.stock_alert_level,
          children: []
        }
        if (this.product.children && this.product.children.length) {
          this.product.children.forEach((item) => {
            const childData = {
              pcid: item.id,
              uuid: item.uuid,
              has_inventory: true,
              warehouse: item.warehouse,
              amount: item.amount,
              imported_price: item.imported_price,
              expired_date: item.expired_date
            }
            data.children.push(childData)
          })
        }
        this.$axios.post(this.$base_api + "/api/v2/product/track-inventory", data)
          .then((res) => {
            if (res.data.data) {
              this.$router.replace({
                name: "show-product",
                params: { uuid: res.data.data.uuid }
              })
            }
          }).catch((error) => {
            if (error.response.status === 422) {
              this.validations = error.response.data.errors
            } else {
              this.onResponseError(error)
            }
          }).finally(() => {
            this.$isLoading(false)
          })
      }
    }
  }
}
</script>

<style scoped>
@import "../../../../assets/css/V2/product-form.css";
</style>
