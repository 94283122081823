import Offline from "@/views/offline/Index"
import { offlinePaymentRouters } from "@/routers/offline/payment"
import { offlineOrderRouters } from "@/routers/offline/order"
import { offlineInvoiceRouters } from "@/routers/offline/invoice"
import { offlineCashierRouters } from "@/routers/offline/cashier"

export const offlineRouters = {
  path: "/offline",
  component: Offline,
  meta: {
    group: "offline"
  },
  children: [
    offlinePaymentRouters,
    offlineOrderRouters,
    offlineInvoiceRouters,
    offlineCashierRouters
  ]
}
