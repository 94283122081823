var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('card',[_c('template',{slot:"header"},[_c('div',{staticClass:"pull-left"},[_c('h3',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t("string.orderList"))+" ")])]),_c('div',{staticClass:"pull-right"},[_c('back-button'),(_vm.$route.name === 'offline-cashier-order-list')?[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{name: 'offline-sale'}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("string.newOrder"))+" ")])]:_vm._e()],2)]),_c('table',{staticClass:"table table-striped table-bordered table-condensed",attrs:{"id":"orders-table"}},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("string.cashier")))]),_c('th',[_vm._v(_vm._s(_vm.$t("string.discount")))]),_c('th',[_vm._v(_vm._s(_vm.$t("string.authorized")))]),_c('th',[_vm._v(_vm._s(_vm.$t("string.issuedDate")))]),_c('th',[_vm._v(_vm._s(_vm.$t("string.status")))]),_c('th',{staticStyle:{"width":"170px !important"}},[_vm._v(" "+_vm._s(_vm.$t("string.action"))+" ")])])]),_c('tbody',[(_vm.orders.length>0)?_vm._l((_vm.orders),function(order,key){return _c('tr',{key:key},[_c('td',[(order.cashier!==null)?[_vm._v(" "+_vm._s(order.cashier.full_name)+" ")]:[_vm._v(" "+_vm._s(_vm.$t("string.na"))+" ")]],2),_c('td',[_vm._v(_vm._s(order.discount || _vm.$t("string.na")))]),_c('td',[_vm._v(_vm._s(order.authorized || _vm.$t("string.na")))]),_c('td',[_vm._v(_vm._s(_vm.$moment(order.created_at).format("DD/MM/YYYY HH:mm:ss A")))]),_c('td',[(order.is_paid)?[_c('span',{staticClass:"label label-primary"},[_vm._v(_vm._s(_vm.$t("string.paid")))])]:[_c('span',{staticClass:"label label-warning"},[_vm._v(_vm._s(_vm.$t("string.unpaid")))])]],2),_c('td',[(order.is_paid)?[_c('router-link',{staticClass:"btn btn-primary margin-r-5",attrs:{"to":{
                    name: 'offline-print-invoice',
                    params: {
                      order_uuid: order.uuid,
                    }
                  }}},[_c('i',{staticClass:"fas fa-search"}),_vm._v(" "+_vm._s(_vm.$t("string.view"))+" ")])]:[_c('router-link',{staticClass:"btn btn-info margin-r-5",attrs:{"to":{
                    name: 'offline-sale',
                    params: {
                      order_uuid: order.uuid,
                    }
                  }}},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v(" "+_vm._s(_vm.$t("string.checkout"))+" ")]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteOrder(order)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]],2)])}):[_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('p',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.$t("string.noRecords"))+" ")])])])]],2)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }