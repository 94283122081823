import Vue from "vue"

const state = {
  categories: [],
  customProducts: [],
  products: [],
  orderProducts: [],
  editProductOrder: null,
  selectedCustomer: null,
  takeAway: "takeAway",

  tables: [],
  selectedTables: [],
  tmpSelectedTables: [],

  filterProducts: {
    number_per_page: 50,
    totalPages: 0,
    search_query: "",
    shop_uuid: null,
    categories: []
  },

  selected_variants: [],
  view_mode: "grid",
  order_discount: 0,
  is_percentage: true,
  discount_authorizer: null
}

const getters = {}

const mutations = {
  clearSelectedVariant (state) {
    state.selected_variants = []
  },
  setSelectedVariant (state, variant_item) {
    if (state.selected_variants.length) {
      state.selected_variants.forEach((item) => {
        if (item.name === variant_item.name) {
          state.selected_variants.splice(state.selected_variants.indexOf(item), 1)
        }
      })
      state.selected_variants.push(variant_item)
    } else {
      state.selected_variants.push(variant_item)
    }
  },
  setDiscountAuthorizer (state, authorizer) {
    state.discount_authorizer = authorizer
  },
  setOrderDiscount (state, discount) {
    if (state.is_percentage) {
      if (discount < 0) {
        state.order_discount = 0
      } else if (discount > 100) {
        state.order_discount = 100
      } else {
        state.order_discount = discount
      }
    } else {
      state.order_discount = discount
    }
    localStorage.setItem("display_dis", JSON.stringify(discount))
  },
  setOrderDiscountStatus (state, is_percentage) {
    state.is_percentage = is_percentage
    localStorage.setItem("display_dis_percent", JSON.stringify(is_percentage))
  },
  setSelectCustomer (state, customer) {
    state.selectedCustomer = customer
    localStorage.setItem("display_sc", JSON.stringify(customer))
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  onChangeCategories (state, category) {
    if (category.id === -1) {
      state.categories.map((item) => {
        if (item.id === -1) {
          // eslint-disable-next-line no-return-assign
          return item.active = true
        } else {
          // eslint-disable-next-line no-return-assign
          return item.active = false
        }
      })
    } else {
      if (state.categories.length > 0) {
        state.categories.map((item) => {
          if (item.id === -1) {
            // eslint-disable-next-line no-return-assign
            return item.active = false
          }
          if (item.id === category.id) {
            // eslint-disable-next-line no-return-assign
            return item.active = !item.active
          }
        })
      }
    }

    let mustEnableAll = true
    if (state.categories.length > 0) {
      state.categories.map((item) => {
        if (item.active) {
          mustEnableAll = false
        }
      })
    }
    if (mustEnableAll) {
      state.categories[0].active = true
    }
  },
  setEditProductOrder (state, product) {
    state.editProductOrder = product
  },
  setCustomProducts (state, products) {
    state.customProducts = products
  },
  setProducts (state, products) {
    state.products = products
  },
  setOrderProducts (state, orderProducts) {
    state.orderProducts = orderProducts
    localStorage.setItem("display_po", JSON.stringify(state.orderProducts))
  },
  setTables (state, tables) {
    state.tables = tables
  },
  setSelectedTables (state, tables) {
    state.selectedTables = tables
  },
  setTmpSelectedTables (state, tables) {
    state.tmpSelectedTables = tables
  },
  addProductToCart (state, product) {
    let found = false
    if (product.is_custom_product && product.custom_product_price.length) {
      Vue.set(product, "sale_price", product.custom_product_price[0].price)
    }
    state.orderProducts.forEach((eachProduct) => {
      if (product.id === eachProduct.id) {
        found = true
        eachProduct.pivot.quantity++
      }
    })

    if (!found) {
      product.pivot.quantity = 1
      product.pivot.discount = 0
      product.pivot.is_percentage = true
      product.pivot.note = null
      Vue.set(product, "class_active", true)
      state.orderProducts.unshift(product)
    }
    localStorage.setItem("display_po", JSON.stringify(state.orderProducts))
  },
  updateOrderProductQuantity (state, data) {
    state.orderProducts.forEach((item) => {
      if (item.id === data.item.id) {
        if (item.pivot) {
          if (data.value) {
            Vue.set(item.pivot, "quantity", parseFloat(data.value))
          }
          if (data.discount) {
            Vue.set(item.pivot, "discount", parseFloat(data.discount))
            Vue.set(item.pivot, "is_percentage", data.is_percentage)
          } else {
            Vue.set(item.pivot, "discount", 0)
          }

          if (data.note) {
            Vue.set(item.pivot, "note", data.note)
          }
        }
        if (data.price) {
          item.sale_price = data.price
        }
      }
    })
    localStorage.setItem("display_po", JSON.stringify(state.orderProducts))
  },
  removeAnItemFromCart (state, item) {
    state.orderProducts.splice(state.orderProducts.indexOf(item), 1)
    localStorage.setItem("display_po", JSON.stringify(state.orderProducts))
  },
  clearCartOrderProducts (state) {
    state.orderProducts = []
    localStorage.setItem("display_po", JSON.stringify(state.orderProducts))
  },
  updateSearchQuery (state, word) {
    state.filterProducts.search_query = word
  },
  setFilterProductionShopUuid (state, uuid) {
    state.filterProducts.shop_uuid = uuid
  },
  setFilterProductCategoryIds (state, ids) {
    state.filterProducts.categories = ids
  },
  addSelectedTable (state, table) {
    let foundTable = null
    if (state.selectedTables.length > 0) {
      state.selectedTables.forEach((eachTable) => {
        if (eachTable.id === table.id) {
          foundTable = eachTable
        }
      })
      if (foundTable === null) {
        table.selected = true
        state.selectedTables.unshift(table)
      } else {
        foundTable.selected = false
        foundTable.status = "available"
        state.selectedTables.splice(state.selectedTables.indexOf(foundTable), 1)
      }
    } else {
      table.selected = true
      state.selectedTables.unshift(table)
    }
  },
  toggleSelectableTables (state, flag) {
    state.tables.map((table) => {
      table.disabled = !flag
      return table
    })
    if (state.takeAway === "takeAway") {
      state.selectedTables = []
      state.tables.map((table) => {
        if (table.selected) {
          table.selected = false
          return table
        }
      })
    }
  },
  changeAwayValue (state, value) {
    state.takeAway = value
    state.tables.map((table) => {
      table.disabled = value
      return table
    })
  },
  undoProductRemoved (state, product) {
    let found = false
    if (product.is_custom_product && product.custom_product_price.length) {
      Vue.set(product, "sale_price", product.custom_product_price[0].price)
    }
    state.orderProducts.forEach((eachProduct) => {
      if (product.id === eachProduct.id) {
        found = true
        eachProduct.pivot.quantity += product.pivot.quantity
      }
    })
    if (!found) {
      state.orderProducts.unshift(product)
    }
    localStorage.setItem("display_po", JSON.stringify(state.orderProducts))
  },
  updateViewMode (state, mode) {
    state.view_mode = mode
  },
  setDisplayPo (state, vm) {
    vm.emit("display_po", { products: state.orderProducts })
  },
  addProductWithQtyToCart (state, data) {
    let found = false
    state.orderProducts.forEach((eachProduct) => {
      if (data.product.id === eachProduct.id) {
        found = true
        eachProduct.pivot.quantity = parseFloat(eachProduct.pivot.quantity) + parseFloat(data.qty)
        state.products.forEach((item) => {
          if (data.product.id === item.id) {
            const amount = parseFloat(item.amount_in_stock) - parseFloat(eachProduct.pivot.quantity)
            item.amount_in_stock = amount
            item.initAmountInStock = amount
          }
        })
      }
    })

    if (!found) {
      data.product.pivot.quantity = parseFloat(data.qty)
      data.product.pivot.discount = 0
      data.product.pivot.is_percentage = true
      data.product.pivot.note = null
      Vue.set(data.product, "class_active", true)
      state.orderProducts.unshift(data.product)
    }
    localStorage.setItem("display_po", JSON.stringify(state.orderProducts))
  },

  setSelectedCustomer (state, customer) {
    state.selectedCustomer = customer
  }
}

const actions = {
  setDiscountAuthorizer ({
    commit,
    state
  }, authorizer) {
    commit("setDiscountAuthorizer", authorizer)
  },
  setOrderDiscount ({
    commit,
    state
  }, discount) {
    commit("setOrderDiscount", discount)
  },
  setOrderDiscountStatus ({
    commit,
    state
  }, is_percentage) {
    commit("setOrderDiscountStatus", is_percentage)
  },
  clearSelectedVariant ({
    commit
  }) {
    commit("clearSelectedVariant")
  },
  setSelectedVariant ({
    commit,
    state
  }, variant_item) {
    commit("setSelectedVariant", variant_item)
  },
  setSelectCustomer ({
    commit,
    state
  }, customer) {
    commit("setSelectCustomer", customer)
  },
  setCategories ({
    commit,
    state
  }, categories) {
    commit("setCategories", categories)
  },
  onChangeCategories ({
    commit,
    state
  }, category) {
    commit("onChangeCategories", category)
  },
  setCustomProducts ({
    commit,
    state
  }, products) {
    commit("setCustomProducts", products)
  },
  setProducts ({
    commit,
    state
  }, products) {
    commit("setProducts", products)
  },
  setTables ({
    commit,
    state
  }, tables) {
    commit("setTables", tables)
  },
  setSelectedTables ({
    commit,
    state
  }, tables) {
    commit("setSelectedTables", tables)
  },
  setTmpSelectedTables ({
    commit,
    state
  }, tables) {
    commit("setTmpSelectedTables", tables)
  },
  setOrderProducts ({
    commit,
    state
  }, orderProducts) {
    commit("setOrderProducts", orderProducts)
  },
  undoProductRemoved ({
    commit,
    state
  }, product) {
    commit("undoProductRemoved", product)
  },
  addProductToCart ({
    commit,
    state
  }, product) {
    commit("addProductToCart", product)
  },
  setFilterProductionShopUuid ({
    commit,
    state
  }, uuid) {
    commit("setFilterProductionShopUuid", uuid)
  },
  setFilterProductCategoryIds ({
    commit,
    state
  }, ids) {
    commit("setFilterProductCategoryIds", ids)
  },
  toggleSelectableTables ({
    commit,
    state
  }, flag) {
    commit("toggleSelectableTables", flag)
  },
  addSelectedTable ({
    commit,
    state
  }, table) {
    commit("addSelectedTable", table)
  },
  removeAnItemFromCart ({
    commit,
    state
  }, item) {
    commit("removeAnItemFromCart", item)
  },
  clearCartOrderProducts ({
    commit
  }) {
    commit("clearCartOrderProducts")
  },
  updateOrderProductQuantity ({
    commit,
    state
  }, data) {
    commit("updateOrderProductQuantity", data)
  },
  updateSearchQuery ({
    commit,
    state
  }, word) {
    commit("updateSearchQuery", word)
  },
  changeAwayValue ({
    commit,
    state
  }, value) {
    commit("changeAwayValue", value)
  },
  setEditProductOrder ({
    commit,
    state
  }, value) {
    commit("setEditProductOrder", value)
  },
  updateViewMode ({
    commit,
    state
  }, mode) {
    commit("updateViewMode", mode)
  },
  setDisplayPo ({ commit }, vm) {
    commit("setDisplayPo", vm)
  },
  addProductWithQtyToCart ({
    commit,
    state
  }, data) {
    commit("addProductWithQtyToCart", data)
  },

  setSelectedCustomer ({ commit }, customer) {
    commit("setSelectedCustomer", customer)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
