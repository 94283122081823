import PaymentOption from "@/views/settings/payment-option/Index"
import List from "@/views/settings/payment-option/List"
import Create from "@/views/settings/payment-option/Create"
import Show from "@/views/settings/payment-option/Show"
import Edit from "@/views/settings/payment-option/Edit"

export const paymentOptionRouters = {
  path: "payment-option",
  component: PaymentOption,
  children: [
    {
      path: "",
      name: "list-payment-option",
      meta: {
        title: "Payment Option"
      },
      component: List
    },
    {
      path: "create",
      name: "create-payment-option",
      component: Create
    },
    {
      path: "edit-payment-option/:uuid",
      name: "edit-payment-option",
      component: Edit
    },
    {
      path: "show-payment-option/:uuid",
      name: "show-payment-option",
      component: Show
    }
  ]
}
