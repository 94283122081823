<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t("string.product") }}
      </h3>
    </div>
    <div class="box-body">
      <table
        id="product-table"
        class="table table-bordered table-hover"
      >
        <thead>
          <tr>
            <th class="w-24">
              {{ $t("food.table.image") }}
            </th>
            <th>{{ $t("food.table.name") }}</th>
            <th>{{ $t("food.table.productCategory") }}</th>
            <th>{{ $t("food.table.createdAt") }}</th>
            <th>{{ $t("food.table.action") }}</th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      oTable: null,
      config: this.$config
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw()
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $("#product-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        pageLength: 25,
        ajax: {
          url: self.config.base_url + "/api/product/get-products-datatable",
          type: "POST",
          data: (d) => {
            d.shop_id = this.get_shop.id
            d.insert_into_order_list = this.$t("button.insert_into_order_list")
          }
        },
        columns: [
          {
            data: "image",
            name: "image",
            title: self.$t("food.table.image"),
            searchable: false,
            orderable: false
          },
          {
            data: "name",
            name: "name",
            title: self.$t("food.table.name")
          },
          {
            data: "category.name_en",
            name: "category.name_km",
            title: self.$t("food.table.productCategory"),
            searchable: false,
            orderable: false,
            render: (data, type, row) => {
              if (row.category) {
                return row.category["name_" + this.$i18n.locale]
              }
              return this.$t("string.na")
            }
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("food.table.createdAt"),
            searchable: false
          },
          {
            data: "actions",
            name: "actions",
            title: self.$t("food.table.action"),
            searchable: false,
            orderable: false
          }
        ],
        order: [[3, "desc"]],
        language: {
          url: `/locale/${self.$i18n.locale}.json`
        }
      })
    },
    insertProductIntoOrderList (product_id) {
      this.onConfirm({
        title: this.$t("string.insert_into_the_order_list"),
        text: this.$t("string.do_you_want_to_insert_into_the_order_list")
      }).then(({ accept }) => {
        if (accept) {
          this.$isLoading(true)
          this.$axios.post(this.$config.base_url + "/api/product/insert-product-into-order-list", {
            product_id,
            shop_id: this.get_shop.id
          }).then(() => {
            this.onResponseSuccess()
            this.$emit("getProductsOrdered")
            this.oTable.draw()
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
    enableActions () {
      this.clearEventHandler([
        ".btn-add"
      ])
      const self = this
      $(document).on("click", ".btn-add", function () {
        self.insertProductIntoOrderList($(this).attr("data-id"))
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.enableActions()
  }
}
</script>

<style>

</style>
