<template>
  <CustomerContainer :hide-btn-delete="true">
    <LeftSideBarOrder />
  </CustomerContainer>
</template>

<script>
import CustomerContainer from "@/views/customers/V2/_components/CustomerContainer"
import LeftSideBarOrder from "@/components/LeftSideBarOrder"

export default {
  name: "CustomerList",
  metaInfo () {
    return {
      title: this.$t("strings.listingCustomers"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    LeftSideBarOrder,
    CustomerContainer
  }
}
</script>
