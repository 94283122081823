<template>
  <div>
    <div class="row">
      <div class="col-sm-6 col-md-6">
        <div
          :class="{'has-error' : message_validation !== null && message_validation.hasOwnProperty('name_en') }"
          class="form-group"
        >
          <label class="required">{{ $t("label.nameEn") }}</label>
          <input
            v-model="data_currency.name_en"
            :placeholder="$t('placeholder.enterNameAsEn')"
            class="form-control"
            type="text"
          >
          <template v-if="message_validation !== null && message_validation.hasOwnProperty('name_en')">
            <span class="help-block">{{ message_validation["name_en"][0] }}</span>
          </template>
        </div>
      </div>
      <div class="col-sm-6 col-md-6">
        <div
          :class="{'has-error' : message_validation !== null && message_validation.hasOwnProperty('name_km') }"
          class="form-group"
        >
          <label class="required">{{ $t("label.nameKm") }}</label>
          <input
            v-model="data_currency.name_km"
            :placeholder="$t('placeholder.enterNameAsKm')"
            class="form-control"
            type="text"
          >
          <template v-if="message_validation !== null && message_validation.hasOwnProperty('name_km')">
            <span class="help-block">{{ message_validation["name_km"][0] }}</span>
          </template>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div
          :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('code') ? 'has-error' : '')"
        >
          <label class="required">{{ $t("currency.fields.label.code") }}</label>
          <input
            v-model="data_currency.code"
            :placeholder="$t('currency.fields.placeholder.code')"
            class="form-control"
            type="text"
          >
          <template v-if="message_validation !== null && message_validation.hasOwnProperty('code')">
            <span class="help-block">{{ message_validation["code"][0] }}</span>
          </template>
        </div>
      </div>
      <div class="col-md-6 col-sm-6">
        <div class="form-group">
          <label>{{ $t("currency.fields.label.symbol") }}</label>
          <input
            v-model="data_currency.symbol"
            :placeholder="$t('currency.fields.placeholder.symbol')"
            class="form-control"
            type="text"
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group text-right">
          <template v-if="this.$route.name !== 'show-currency'">
            <button
              :class="`btn btn-${this.$route.name === 'edit-currency' ? 'info' : 'primary'}`"
              @click="storeCurrency"
            >
              <i class="fas fa-check-circle" />
              <template v-if="this.$route.name === 'edit-currency'">
                {{ $t("button.update") }}
              </template>
              <template v-else>
                {{ $t("button.save") }}
              </template>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrencyForm",
  props: {
    columns: {
      type: String,
      default: "col-md-12"
    }
  },
  data () {
    return {
      data_currency: {
        name_km: "",
        name_en: "",
        code: "",
        symbol: ""
      },
      config: this.$config,
      message_validation: null
    }
  },
  methods: {
    getCurrency () {
      this.$axios.post(this.$config.base_url + "/api/currency/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.data_currency = response.data.data
        }
        if (response.data.code === 0) {
          this.$router.push({ name: "list-currency" })
          this.$toastr("error", response.data.message, "Get currency")
        }
      })
    },
    storeCurrency () {
      this.data_currency.shop_id = this.get_shop.id
      this.$axios.post(this.$config.base_url + "/api/currency/store", this.data_currency)
        .then((response) => {
          if (response.data.code === 1) {
            this.$router.push({ name: "list-currency" })
            if (this.data_currency.hasOwnProperty("id") && this.data_currency.id !== null) {
              this.$anotify(this.$t("model.currency"), "update")
            } else {
              this.$anotify(this.$t("model.currency"))
            }
          } else {
            this.message_validation = response.data.message
          }
        })
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getCurrency()
    }
    if (this.$route.name === "show-currency") {
      $("input").attr("disabled", true)
    }
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}
</style>
