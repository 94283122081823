<template>
  <div class="invoice-container shadow">
    <div
      v-if="shop"
      id="container"
      style="padding: 0 !important; min-height: auto;"
    >
      <div class="header text-center pt-3">
        <p
          v-if="invoiceTemplate && invoiceTemplate.show_logo"
          style="margin: 0"
        >
          <template v-if="shop.logo">
            <img
              id="logo"
              :src="getSrc(shop.logo)"
              style="max-width: 220px; max-height: 100px;"
              alt="Shop Logo"
            >
          </template>
          <template v-else>
            <img
              src="/images/icons/icon-512x512.png"
              style="max-width: 220px; max-height: 100px;"
              alt="Shop Logo"
            >
          </template>
        </p>
        <table class="table table-hover auto-width">
          <tr v-if="invoiceTemplate && invoiceTemplate.show_shop_name">
            <td
              class="no-border"
              colspan="4"
              style="text-align: center"
            >
              <strong>{{ shop.name }}</strong>
            </td>
          </tr>
          <tr
            class="below-calculate"
            v-if="invoiceTemplate && invoiceTemplate.show_order_number"
          >
            <td colspan="4">
              <p class="margin-bottom-none font-s-16">
                <strong>{{ $t("label.number") }}: {{ order_number }}</strong>
              </p>
            </td>
          </tr>
          <template v-if="invoiceTemplate && invoiceTemplate.show_contact">
            <tr
              v-if="shop.address || shop.tel"
              class="below-calculate"
            >
              <td
                class="no-border tiny-text slash-bar"
                colspan="4"
              >
                <span v-if="shop.address">{{ shop.address }}</span>
                <span v-if="shop.tel">{{ shop.tel }}</span>
              </td>
            </tr>
          </template>
          <template v-if="invoiceTemplate && invoiceTemplate['header_' + $i18n.locale]">
            <tr class="below-calculate">
              <td
                class="no-border tiny-text whitespace-pre"
                colspan="5"
              >
                {{ invoiceTemplate["header_" + $i18n.locale] }}
              </td>
            </tr>
          </template>
          <template v-if="invoiceTemplate && invoiceTemplate.show_customer_info">
            <tr class="below-calculate tiny-text">
              <td class="text-left">
                {{ $t("label.customer") }}:
              </td>
              <td
                class="text-left"
                colspan="3"
              >
                <strong v-if="customer_name">{{ customer_name }}</strong>
                <span v-else>{{ $t("string.na") }}</span>
              </td>
            </tr>
            <tr class="below-calculate tiny-text">
              <td class="text-left">
                {{ $t("label.telephone") }}:
              </td>
              <td class="text-left">
                <strong>{{ customer_tel }}</strong>
              </td>
              <td class="text-left">
                {{ $t("label.gender") }}:
              </td>
              <td class="text-left">
                <strong>{{ customer_gender }}</strong>
              </td>
            </tr>
            <tr class="below-calculate tiny-text">
              <td class="text-left">
                {{ $t("label.address") }}:
              </td>
              <td
                class="text-left"
                colspan="3"
              >
                <strong>{{ customer_address }}</strong>
              </td>
            </tr>
          </template>
          <tr class="below-calculate tiny-text">
            <td class="text-left">
              {{ $t("label.date") }}:
            </td>
            <td class="text-left">
              <strong>{{ $moment(created_at).format("DD/MM/YYYY") }}</strong>
            </td>
            <td class="text-left">
              {{ $t("string.time") }}:
            </td>
            <td class="text-left">
              <strong>{{ $moment(created_at).format("hh:mm:ss A") }}</strong>
            </td>
          </tr>
          <template v-if="invoiceTemplate && invoiceTemplate.show_cashier">
            <tr class="below-calculate tiny-text">
              <td class="no-border text-left">
                {{ $t("label.cashier") }}:
              </td>
              <td class="no-border text-left">
                <strong>{{ cashier_name }}</strong>
              </td>
              <template v-if="invoiceTemplate && invoiceTemplate.show_invoice_number">
                <td class="no-border text-left">
                  {{ $t("label.inv") }}:
                </td>
                <td class="no-border text-left">
                  <strong>{{ invoice_number }}</strong>
                </td>
              </template>
            </tr>
          </template>
        </table>
      </div>
      <div class="body">
        <table class="table table-hover auto-width small-text margin-bottom-none">
          <thead>
            <tr>
              <th style="width: 5%">
                #
              </th>
              <th
                class="text-center"
                style="width: 30%"
              >
                {{ $t("label.item") }}
              </th>
              <th
                class="text-center"
                style="width: 10%"
              >
                {{ $t("label.qty") }}
              </th>
              <th
                class="text-center"
                style="width: 25%"
              >
                {{ $t("label.price") }}
              </th>
              <th
                class="text-right"
                style="width: 30%"
              >
                {{ $t("label.subTotal") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, key) in products"
              :key="key"
            >
              <td class="text-center">
                {{ key + 1 }}.
              </td>
              <td class="text-center">
                <div>{{ product.name }}</div>
                <div
                  v-if="product.product_variants.length"
                  class="slash font-s-13"
                >
                  <template v-for="(variant, index) in product.product_variants">
                    <span :key="index">{{ variant.value }}</span>
                  </template>
                </div>
              </td>
              <td class="text-center">
                {{ product.served_quantity }}
              </td>
              <td class="text-center">
                {{ product.price }} {{ product.currency_symbol }}
              </td>
              <td class="text-right">
                {{ product.subTotal }} {{ product.currency_symbol }}
              </td>
            </tr>
            <tr class="no-border below-calculate">
              <td
                class="text-right"
                colspan="3"
              >
                {{ $t("label.subTotal") }}
              </td>
              <td
                class="text-right"
                colspan="2"
              >
                {{ subTotal }} ៛
              </td>
            </tr>
            <template>
              <tr class="no-border below-calculate">
                <td
                  class="text-right no-border"
                  colspan="3"
                >
                  {{ $t("label.includeTax") }}
                  ({{ tax_amount_percentage }}%)
                </td>
                <td
                  class="text-right no-border"
                  colspan="2"
                >
                  {{ calc_tax_amount }} ៛
                </td>
              </tr>
            </template>
            <tr class="no-border below-calculate">
              <td
                class="text-right no-border"
                colspan="3"
              >
                {{ $t("label.discount") }}
              </td>
              <td
                class="text-right no-border"
                colspan="2"
              >
                {{ amount_discount }} %
              </td>
            </tr>
            <tr
              v-if="amount_discount > 0 && discount_authorizer"
              class="no-border below-calculate"
            >
              <td
                class="text-right no-border"
                colspan="3"
              >
                {{ $t("label.by") }}
              </td>
              <td
                class="text-right no-border"
                colspan="2"
              >
                {{ discount_authorizer || $t("string.na") }}
              </td>
            </tr>
            <tr class="no-border below-calculate">
              <td
                class="text-right no-border"
                colspan="3"
              >
                <strong>{{ $t("label.amountTotal") }}(USD)</strong>
              </td>
              <td
                class="text-right no-border"
                colspan="2"
              >
                <strong>{{ total_dollar | numFormat(num_format) }} $</strong>
              </td>
            </tr>
            <tr class="no-border below-calculate">
              <td
                class="text-right no-border"
                colspan="3"
              >
                <strong>{{ $t("label.amountTotal") }}(KHR)</strong>
              </td>
              <td
                class="text-right no-border"
                colspan="2"
              >
                <strong>{{ total_riel | numFormat("0,0") }} ៛</strong>
              </td>
            </tr>
            <template v-if="invoiceTemplate && invoiceTemplate.show_amount_paid">
              <tr class="no-border below-calculate">
                <td
                  class="text-right"
                  colspan="3"
                >
                  {{ $t("label.amountPaid") }}(USD)
                </td>
                <td
                  class="text-right"
                  colspan="2"
                >
                  {{ amount_paid_dollar | numFormat(num_format) }} $
                </td>
              </tr>
              <tr class="no-border below-calculate">
                <td
                  class="text-right no-border"
                  colspan="3"
                >
                  {{ $t("label.amountPaid") }}(KHR)
                </td>
                <td
                  class="text-right no-border"
                  colspan="2"
                >
                  {{ amount_paid_riel | numFormat("0,0") }} ៛
                </td>
              </tr>
            </template>
            <template v-if="invoiceTemplate && invoiceTemplate.show_balance">
              <tr class="no-border below-calculate">
                <td
                  class="text-right no-border"
                  colspan="3"
                >
                  {{ $t("label.amountChange") }}(USD)
                </td>
                <td
                  class="text-right no-border"
                  colspan="2"
                >
                  {{ amount_due_dollar | numFormat(num_format) }} $
                </td>
              </tr>
              <tr class="no-border below-calculate">
                <td
                  class="text-right no-border"
                  colspan="3"
                >
                  {{ $t("label.amountChange") }}(KHR)
                </td>
                <td
                  class="text-right no-border"
                  colspan="2"
                >
                  {{ amount_due_riel | numFormat("0,0") }} ៛
                </td>
              </tr>
            </template>
            <template v-if="invoiceTemplate && invoiceTemplate.show_exchange_rate">
              <tr class="below-calculate">
                <td
                  class="tiny-text no-border"
                  colspan="5"
                >
                  <span class="font-s-14">{{ $t("label.exchangeRate") }} 1$ = 4,000 ៛</span>
                </td>
              </tr>
            </template>
            <template v-if="invoiceTemplate && invoiceTemplate['footer_' + $i18n.locale]">
              <tr class="below-calculate">
                <td
                  class="tiny-text no-border pt-3 pb-3 text-center whitespace-pre"
                  colspan="5"
                >
                  {{ invoiceTemplate["footer_" + $i18n.locale] }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div
        class="footer"
        style="border-top: 1px solid #ddd; text-align: center; min-height: 50px"
      >
        <div>{{ $t("label.poweredBy") }} Asorasoft.com</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "PreviewInvoiceTemplate",
  props: {
    shop: {
      type: Object,
      default: () => {
        return null
      }
    },
    invoiceTemplate: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      shop_currency: state => state.shop.currency
    })
  },
  data () {
    return {
      order_number: "00001",
      invoice_number: "1",
      customer_name: "Anonymous",
      customer_tel: "012343434",
      customer_gender: "Male",
      customer_address: "Phnom Penh",
      created_at: this.$moment(),
      cashier_name: "Cashier name",
      tax_amount_percentage: 10,
      calc_tax_amount: "4,950",
      amount_discount: 15,
      calc_amount_discount: "4,300",
      subTotal: "4,500",
      discount_authorizer: "Manager",
      total_dollar: "1.075",
      total_riel: "4,300",
      amount_paid_dollar: 2,
      amount_paid_riel: "8,000",
      amount_due_dollar: "0.925",
      amount_due_riel: "3,700",
      products: [
        {
          name: "Product (1)",
          price: "4,500",
          served_quantity: 1,
          subTotal: "4,500",
          currency_symbol: "៛",
          product_variants: [
            { value: "Red" },
            { value: "Medium" }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.margin-left {
  margin-left: 6px;
}

.qr-container {
  transform: scale(0.35);
  transform-origin: 50% 0;
}

.pb-15 {
  padding-bottom: 15px;
}

.invoice-container {
  width: 100mm;
  background-color: white;
  margin: auto;
  min-height: 700px;
}

.auto-width {
  table-layout: auto;
  padding: 0 12px;
}

.auto-width td, .auto-width th {
  padding: 8px 0;
  vertical-align: middle;
}

.auto-width td:first-child, .auto-width th:first-child {
  padding-left: 8px;
}

.auto-width td:last-child, .auto-width th:last-child {
  padding-right: 8px;
}

.auto-width > thead > tr:first-child, .auto-width > tbody > tr:first-child {
  width: 10mm !important;
}

.below-calculate > td {
  height: 5mm;
  padding: 0 2px;
}

.small-text {
  font-size: 1.1em;
}

.tiny-text {
  font-size: 1em;
}

.item-list > td {
  padding: 3px 0;
  vertical-align: middle;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }

  @page {
    margin: 0;
  }
}
</style>
