import inventoryLayout from "@/views/inventories/Index"
import { productRouters } from "@/routers/inventoryRouters/productRouters"
import { unitRouters } from "@/routers/inventoryRouters/unitRouters"
import { tagRouters } from "@/routers/inventoryRouters/productTagRouters"
import { categoryRouters } from "@/routers/inventoryRouters/productCategoryRouters"
import { productTypeRouters } from "@/routers/inventoryRouters/productTypeRouters"
import { warehouseRouters } from "@/routers/inventoryRouters/warehouseRouters"
import { stockAlertControlRouters } from "@/routers/inventoryRouters/stockAlertControlRouters"
import { offlineInventoryTransactionRouters } from "@/routers/inventoryRouters/offlineInventoryTransaction"
import { promotionRouters } from "@/routers/inventoryRouters/promotionRouters"

export const inventoryRouters = {
  path: "/admin/inventories",
  component: inventoryLayout,
  name: "/admin/inventories",
  children: [
    productRouters,
    unitRouters,
    tagRouters,
    categoryRouters,
    promotionRouters,
    productTypeRouters,
    warehouseRouters,
    stockAlertControlRouters,
    offlineInventoryTransactionRouters
  ]
}
