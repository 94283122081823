<template>
  <div class="row">
    <div class="col-sm-12">
      <template v-if="currentPlan.code === plan_free_code">
        <SubscriptionRenewalPlanFreeFrom />
      </template>
      <template v-else>
        <SubscriptionRenewalPlanChargeFrom />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SubscriptionRenewalPlanFreeFrom from "@/views/settings/subscription/forms/SubscriptionRenewalPlanFreeFrom"
import SubscriptionRenewalPlanChargeFrom from "@/views/settings/subscription/forms/SubscriptionRenewalPlanChargeFrom"

export default {
  name: "SubscriptionRenewPlan",
  components: {
    SubscriptionRenewalPlanChargeFrom,
    SubscriptionRenewalPlanFreeFrom
  },
  data () {
    return {
      plan_free_code: process.env.VUE_APP_SUBSCRIPTION_FREE_CODE
    }
  },
  computed: {
    ...mapGetters("subscription", {
      currentPlan: "currentPlan"
    })
  }
}
</script>

<style scoped>

</style>
