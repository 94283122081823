<template>
  <div
    id="filterCustomer"
    aria-labelledby="filterCustomerLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header-custom">
          <h4
            id="myModalLabel"
            class="modal-title capitalize"
          >
            {{ $t("label.filterCustomer") }}
          </h4>
          <button
            ref="close"
            :class="class_btn_modal"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
          >
            <i
              aria-hidden="true"
              class="fas fa-times"
            />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 margin-bottom">
              <label class="font-s-18 mb-2 text-capitalize font-weight-normal">{{
                $t("fields.label.customerGroup")
              }}</label>
              <div
                v-if="customerGroupsList.length > 0"
                class="list-category h-auto"
              >
                <div
                  v-for="(group, key) in customerGroupsList"
                  :key="key"
                  :class="`${group.select_active ? 'active' : ''}`"
                  class="list-category-item list-category-item-mb list-category-item-h shadow"
                  @click="onClickCustomerGroup(group)"
                >
                  <h4 class="list-category-label">
                    {{ group["name_" + $i18n.locale] }}
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="font-s-18 mb-2 text-capitalize font-weight-normal">{{
                $t("fields.label.membership")
              }}</label>
              <div
                v-if="customerMembershipList.length > 0"
                class="list-category h-auto"
              >
                <div
                  v-for="(membership, key) in customerMembershipList"
                  :key="key"
                  :class="`${membership.select_active ? 'active' : ''}`"
                  class="list-category-item list-category-item-mb list-category-item-h shadow"
                  @click="onClickCustomerMembership(membership)"
                >
                  <h4 class="list-category-label">
                    {{ membership["name_" + $i18n.locale] }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default btn-lg"
            @click="onClickReset"
          >
            <i class="fas fa-history" /> {{ $t("button.reset") }}
          </button>
          <button
            class="btn btn-success btn-lg"
            @click="onClickApply"
          >
            <i class="fas fa-check-circle" /> {{ $t("button.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "CustomerFilter",
  data () {
    return {}
  },
  computed: {
    ...mapState({
      customerGroupsList: state => state.customer.groups,
      customerMembershipList: state => state.customer.memberships
    })
  },
  methods: {
    onClickCustomerGroup (item) {
      this.$store.dispatch("customer/onChangeGroup", item)
    },
    onClickCustomerMembership (item) {
      this.$store.dispatch("customer/onChangeMembership", item)
    },

    onClickApply () {
      const group_ids = []
      const membership_ids = []

      this.customerGroupsList.forEach((item) => {
        if (item.select_active === true && item.id !== -1) {
          group_ids.push(item.id)
        }
      })
      this.customerMembershipList.forEach((item) => {
        if (item.select_active === true && item.id !== -1) {
          membership_ids.push(item.id)
        }
      })
      this.$store.dispatch("customer/setFilterGroupIds", group_ids)
      this.$store.dispatch("customer/setFilterMembershipIds", membership_ids)
      this.$emit("refreshList")
      this.$refs.close.click()
    },

    onClickReset () {
      this.onClickCustomerGroup(this.customerGroupsList[0])
      this.onClickCustomerMembership(this.customerMembershipList[0])
      this.$emit("refreshList")
      this.$refs.close.click()
    }
  }
}
</script>

<style scoped>
.list-category-item:last-child {
  margin-right: 0;
}
</style>
