<template>
  <div class="row">
    <div class="col-md-12 margin-top">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div>
            <h3 class="m-0 font-weight-bold text-primary payment-subtitle">
              {{ $t("label.counter") }}
            </h3>
          </div>
          <div>
            <button
              class="btn btn-default"
              @click="$router.push({name: 'amusement-park-order'})"
            >
              {{ $t("button.back") }}
            </button>
            <button
              class="btn btn-success"
              @click="processPayment"
            >
              {{ $t("button.complete") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="payment-info">
                <div class="row">
                  <div class="col-md-12 margin-vertical">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="payment-subtitle">
                          {{ $t("string.subtotal") }}
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div>
                          <strong class="big-text">{{ subtotal.toFixed(2) }} $</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 margin-vertical">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="payment-subtitle">
                          {{ $t("string.discount") }}
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="discount-input input-group">
                          <input
                            v-model="discount"
                            aria-describedby="discount"
                            class="form-control"
                            max="100"
                            min="0"
                            type="number"
                          >
                          <span
                            id="discount"
                            class="input-group-addon"
                          >%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 margin-vertical">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="payment-subtitle">
                          {{ $t("string.tax") }}
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="discount-input input-group">
                          <input
                            v-model="tax"
                            aria-describedby="discount"
                            class="form-control"
                            max="100"
                            min="0"
                            type="number"
                          >
                          <span class="input-group-addon">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 margin-vertical">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="payment-subtitle">
                          {{ $t("string.totalAmount") }}
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div>
                          <strong class="big-text">{{ amount_payment.toFixed(2) }} $</strong>
                        </div>
                        <div>({{ amount_payment_riel.toFixed(2) }} ៛)</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 margin-vertical">
                    <div class="row">
                      <div class="col-sm-6">
                        {{ $t("string.exchange") }}
                      </div>
                      <div class="col-sm-6">
                        1$ = 4100
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row margin-vertical">
                <div class="col-md-4">
                  {{ $t("string.paymentAmount") }}
                </div>
                <div class="col-md-8">
                  <div class="input-group margin-b-15">
                    <span
                      id="amount-riel"
                      class="input-group-addon"
                    >KHR (៛)</span>
                    <input
                      v-model="total_paid_riel"
                      aria-describedby="amount-riel"
                      class="form-control zero-z-index"
                      type="number"
                    >
                  </div>
                  <div class="input-group">
                    <span
                      id="amount-dollar"
                      class="input-group-addon"
                    >USD ($)</span>
                    <input
                      v-model="total_paid_dollar"
                      aria-describedby="amount-dollar"
                      class="form-control zero-z-index"
                      type="number"
                    >
                  </div>
                </div>
              </div>
              <div class="row margin-vertical">
                <div class="col-md-4">
                  {{ $t("string.balanceDue") }}
                </div>
                <div class="col-md-8">
                  <div class="big-text">
                    {{ balance_due.toFixed(2) }} $
                  </div>
                  <div>({{ balance_due.toFixed(2) * exchange }} ៛)</div>
                </div>
              </div>
              <div class="row margin-vertical">
                <div class="col-md-4">
                  {{ $t("string.balanceOwe") }}
                </div>
                <div class="col-md-8">
                  <span class="badge">
                    <strong class="big-text">
                      {{ balance_owe.toFixed(2) }} $
                    </strong>
                  </span>
                  <div>
                    ({{ balance_owe.toFixed(2) * exchange }} ៛)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",
  metaInfo () {
    return {
      title: "Payment",
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      subtotal: 0,
      order_uuid: null,
      discount: 0,
      tax: 0,
      total_paid_dollar: 0,
      total_paid_riel: 0,
      amount_payment: 0,
      total_less_discount: 0,
      exchange: 4100,
      balance_due: 0,
      balance_owe: 0
    }
  },
  computed: {
    amount_payment_riel () {
      return this.amount_payment * this.exchange
    }
  },
  watch: {
    subtotal () {
      this.amount_payment = this.subtotal
    },
    discount () {
      this.total_less_discount = this.subtotal - (this.subtotal * this.discount / 100)
      this.amount_payment = this.total_less_discount + (this.total_less_discount * (this.tax / 100))
    },
    tax () {
      this.total_less_discount = this.subtotal - (this.subtotal * this.discount / 100)
      this.amount_payment = this.total_less_discount + (this.total_less_discount * (this.tax / 100))
    },
    total_paid_dollar () {
      this.calPayment()
    },
    total_paid_riel () {
      this.calPayment()
    }
  },
  methods: {
    getOrder () {
      this.$axios.post(this.$config.base_url + "/api/amusement-park/order/get-order-payment", {
        uuid: this.$route.params.order_uuid,
        shop_uuid: this.$config.shop_uuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.order_uuid = response.data.data.order_uuid
          this.subtotal = response.data.data.subtotal
        }
      })
    },
    calPayment () {
      const convert_riel_to_dollar = this.total_paid_riel / this.exchange
      const total_paid = parseFloat(this.total_paid_dollar) + parseFloat(convert_riel_to_dollar)
      if (total_paid > this.amount_payment) {
        this.balance_due = total_paid - this.amount_payment
        this.balance_owe = 0
      } else {
        this.balance_due = 0
        this.balance_owe = this.amount_payment - total_paid
      }
    },
    processPayment () {
      this.$axios.post(this.$config.base_url + "/api/amusement-park/invoice/store", {
        aps_order_uuid: this.order_uuid,
        shop_id: this.get_shop.id,
        subtotal: this.subtotal,
        discount: this.discount,
        tax: this.tax,
        total_paid_dollar: parseFloat(this.total_paid_dollar).toFixed(2),
        total_paid_riel: parseFloat(this.total_paid_riel).toFixed(2),
        balance_due_dollar: parseFloat(this.balance_due).toFixed(2),
        balance_due_riel: parseFloat(this.balance_due * this.exchange).toFixed(2),
        total_owe_dollar: parseFloat(this.balance_owe).toFixed(2),
        total_owe_riel: parseFloat(this.balance_owe * this.exchange).toFixed(2)
      }).then((response) => {
        if (response.data.code === 1) {
          this.$router.push({
            name: "amusement-park-print",
            params: {
              order_uuid: response.data.data
            }
          })
        }
      })
    }
  },
  mounted () {
    this.getOrder()
  }
}
</script>

<style scoped>
.payment-info {
  border-right: 1px solid var(--gray-very-light);
  padding-right: 15px;
}

.payment-subtitle {
  font-size: 1.5em;
}

.big-text {
  font-size: 2em;
}

.margin-vertical {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
