<template>
  <div class="row">
    <div class="col-md-12">
      <div class="wrapper-header">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title-label">
              {{ $t("label.editMembership") }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="wrapper-form">
        <MembershipForm />
      </div>
    </div>
  </div>
</template>

<script>
import MembershipForm from "./Form"

export default {
  name: "MembershipEdit",
  metaInfo () {
    return {
      title: this.$t("label.editMembership"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: { MembershipForm }
}
</script>

<style scoped>
.wrapper-form {
  background: var(--white);
  margin: 1% auto 0 auto;
  padding: 15px;
}

.wrapper-header {
  margin-bottom: 15px;
  margin-top: 15px;
}
</style>
