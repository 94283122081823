<template>
  <ReportIncome />
</template>

<script>
import ReportIncome from "@/views/reports/income/ReportIncome"

export default {
  name: "IncomeIndex",
  components: { ReportIncome }
}
</script>
