<template>
  <div class="customer-page-container w-100 bg-white h-100">
    <div class="block-invoice-list h-100">
      <div class="tabBlock-pane d-flex flex-column">
        <div class="form-group d-flex align-items-center justify-content-between user-select-none">
          <label class="title-section">{{ $t("strings.listingMemberships") }}</label>
          <button
            class="btn btn-success btn-lg no-margin show-customer-sidebar-detail"
            @click="showFormCreateMembership = !showFormCreateMembership"
          >
            <i class="fas fa-plus" />
            <span class="hidden-sm hidden-md ml-2">{{ $t("label.add") }}</span>
          </button>
        </div>
        <div class="form-group">
          <div class="w-100 box-search">
            <div class="box-search-icon margin-l-5">
              <i class="fas fa-search" />
            </div>
            <input
              v-model="search_query"
              :placeholder="$t('label.search') + '...'"
              class="pos-input-search pos-input-lg no-border"
              type="text"
              @keyup="getMemberships(1)"
            >
          </div>
        </div>
        <template v-if="onLoading">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <div
            v-if="list_memberships.length > 0"
            class="list-sugg-customer overflow-y-auto scrollbar user-select-none flex-1"
          >
            <div class="col-xs-12 no-padding">
              <div class="sugg-customer-each sugg-customer-header">
                <div class="d-flex justify-content-between">
                  <div class="text-bold font-s-16 w-10 text-capitalize">
                    #
                  </div>
                  <div class="text-bold font-s-16 w-50 text-capitalize">
                    {{ $t("label.name") }}
                  </div>
                  <div class="text-bold font-s-16 w-20 text-center text-capitalize">
                    {{ $t("label.customer") }}
                  </div>
                  <div class="text-bold font-s-16 w-20 text-center text-capitalize">
                    {{ $t("table.membership.amountPercentage") }}
                  </div>
                </div>
              </div>
            </div>
            <template v-for="(item, key) in list_memberships">
              <div
                :key="key"
                class="col-xs-12 no-padding show-customer-sidebar-detail"
              >
                <a @click="onClickSelectMembership(item)">
                  <div
                    :class="{'active': membershipDetail && item.uuid === membershipDetail.uuid}"
                    class="sugg-customer-each"
                  >
                    <div class="d-flex justify-content-between">
                      <div class="font-s-16 w-10">{{ key + 1 }}</div>
                      <div class="font-s-16 w-50">{{ item["name_" + $i18n.locale] }}</div>
                      <div class="font-s-16 w-20 text-center">{{ item.customers_count }}</div>
                      <div class="font-s-16 w-20 text-center">({{ item.amount_percentage }}%)</div>
                    </div>
                  </div>
                </a>
              </div>
            </template>
            <infinite-loading
              :identifier="infiniteMembershipId"
              spinner="spiral"
              @infinite="infiniteHandlerMembershipList"
            >
              <div slot="no-more" />
              <div slot="no-results">
                {{ $t("label.no_results_message") }}
              </div>
              <div
                slot="error"
                slot-scope="{ trigger }"
              >
                Error message, click <a
                  href="javascript:void(0)"
                  @click="trigger"
                >here</a> to retry
              </div>
            </infinite-loading>
          </div>
          <template v-else>
            <no-any-records>
              <div
                v-if="!search_query"
                class="d-block w-100 text-center margin-top"
              />
            </no-any-records>
          </template>
        </template>
      </div>
    </div>
    <div class="block-invoice-detail cus-detail h-100">
      <div class="tabBlock-pane d-flex flex-column overflow-y-auto scrollbar">
        <template v-if="onLoadingDetail">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <div
            v-if="membershipDetail"
            class="order-info w-100"
          >
            <div class="form-group d-flex align-items-center justify-content-between w-100 text-capitalize">
              <div>
                <button
                  class="btn btn-default btn-lg no-margin btn-close-cus-detail-sm"
                  @click="closeCustomerDetailSm"
                >
                  <i class="fas fa-arrow-left" />
                </button>
              </div>
              <div>
                <button
                  class="btn btn-default btn-lg no-margin"
                  @click="showFormEditMembership = !showFormEditMembership "
                >
                  <i class="fas fa-edit mr-2" />
                  {{ $t("label.edit") }}
                </button>
              </div>
            </div>
            <div class="row no-margin user-select-none">
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong class="mr-2">{{ $t("label.nameKm") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ membershipDetail.name_km }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong class="mr-2">{{ $t("label.nameEn") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ membershipDetail.name_en }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="membershipDetail.amount_percentage"
                class="col-xs-12 no-padding has-border-b"
              >
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong class="mr-2">{{ $t("table.membership.amountPercentage") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ membershipDetail.amount_percentage }}%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="membershipDetail.membership_type">
                <div class="col-xs-12 no-padding has-border-b">
                  <div class="row no-margin">
                    <div class="col-xs-6 col-md-4 no-padding">
                      <div class="text-capitalize cus-det-label">
                        <strong class="mr-2">{{ $t("table.membership.membershipType") }}</strong>
                      </div>
                    </div>
                    <div class="col-xs-6 col-md-8 no-padding">
                      <div class="text-capitalize cus-det-label">
                        <strong>{{ membershipDetail.membership_type["name_" + $i18n.locale] }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="membershipDetail.membership_type.value === 'duration'">
                  <div
                    v-if="membershipDetail.duration"
                    class="col-xs-12 no-padding has-border-b"
                  >
                    <div class="row no-margin">
                      <div class="col-xs-6 col-md-4 no-padding">
                        <div class="text-capitalize cus-det-label">
                          <strong class="mr-2">{{ $t("table.membership.membershipDuration") }}</strong>
                        </div>
                      </div>
                      <div class="col-xs-6 col-md-8 no-padding">
                        <div class="text-capitalize cus-det-label">
                          <strong>
                            {{ membershipDetail.duration }}
                            {{ checkLabelDate(membershipDetail.duration, membershipDetail.duration_type) }}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="membershipDetail.membership_type.value === 'specific_date'">
                  <div
                    v-if="membershipDetail.specific_date"
                    class="col-xs-12 no-padding has-border-b"
                  >
                    <div class="row no-margin">
                      <div class="col-xs-6 col-md-4 no-padding">
                        <div class="text-capitalize cus-det-label">
                          <strong class="mr-2">{{ $t("table.membership.membershipSpecificDate") }}</strong>
                        </div>
                      </div>
                      <div class="col-xs-6 col-md-8 no-padding">
                        <div class="text-capitalize cus-det-label">
                          <strong>
                            {{ $moment(membershipDetail.specific_date).format("DD / MM / YYYY") }}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("label.showOption") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <toggle-button
                        :font-size="16"
                        :height="30"
                        :labels="{checked: $t('label.active'), unchecked: $t('label.inactive')}"
                        :sync="true"
                        :value="membershipDetail.active"
                        :width="100"
                        color="#1876f2"
                        @change="toggleActive(membershipDetail.uuid)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 no-padding has-border-b">
                <div class="row no-margin">
                  <div class="col-xs-6 col-md-4 no-padding">
                    <div class="text-capitalize cus-det-label">
                      <strong>{{ $t("table.membership.createdAt") }}</strong>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-8 no-padding">
                    <div class="text-capitalize cus-det-label">
                      {{ $moment(membershipDetail.created_at).locale($i18n.locale).format(dateFormatLocale) }}
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="membershipDetail.customers.length">
                <div class="col-xs-12 no-padding">
                  <div class="text-capitalize cus-det-label">
                    <strong>{{ $t("label.customer") }}</strong>
                  </div>
                </div>
                <div class="col-xs-12 no-padding">
                  <div class="list-sugg-customer overflow-y-auto scrollbar user-select-none flex-1">
                    <template v-for="(item, key) in membershipDetail.customers">
                      <div
                        :key="key"
                        class="col-xs-12 no-padding"
                      >
                        <div
                          class="sugg-customer-each"
                          style="cursor: initial"
                        >
                          <div>
                            <!--order_count: It's the number of orders owed on the invoice-->
                            <span class="sugg-cus-name mr-2">{{ item.name }}</span>
                            <span>( {{ item.gender["name_" + $i18n.locale] }} )</span>
                          </div>
                          <div class="sugg-cus-contact">
                            <span v-if="item.tel">{{ item.tel }}</span>
                            <span v-if="item.address">{{ item.address }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center justify-content-center w-100 h-100"
          >
            <p class="font-s-18 capitalize">
              {{ $t("string.noMembershipSelected") }}
            </p>
          </div>
        </template>
      </div>
      <template v-if="showFormCreateMembership">
        <DrawerMembership
          :membership-types="membershipTypes"
          :show-drawer="showFormCreateMembership"
          @closeDrawer="closeDrawer"
          @refreshData="getMemberships(1)"
        />
      </template>
      <template v-if="showFormEditMembership">
        <DrawerMembership
          :membership-types="membershipTypes"
          :old-data="membershipDetail"
          :show-drawer="showFormEditMembership"
          @closeDrawer="closeDrawer"
          @refreshData="getMemberships(1)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce"
import NoAnyRecords from "@/components/NoAnyRecords"
import DrawerMembership from "@/views/customers/memberships/V2/_components/DrawerMembership"

export default {
  name: "CustomerMembershipList",
  metaInfo () {
    return {
      title: this.$t("label.memberCard"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    DrawerMembership,
    NoAnyRecords
  },
  data () {
    return {
      list_memberships: [],
      membershipTypes: [],
      search_query: null,
      onLoading: true,
      onLoadingDetail: false,
      membershipDetail: null,
      page: 1,
      infiniteMembershipId: +new Date(),
      showFormCreateMembership: false,
      showFormEditMembership: false
    }
  },
  mounted () {
    this.getMembershipTypes()
    this.getMemberships(1)
  },
  methods: {
    getMembershipTypes () {
      this.$axios.get(this.$base_api + "/api/v2/customer-membership/get-membership-type")
        .then((res) => {
          this.membershipTypes = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
        })
    },
    toggleActive (uuid) {
      this.$axios.post(this.$config.base_url + "/api/v2/customer-membership/toggle", {
        uuid: uuid
      }).then((res) => {
        this.membershipDetail = res.data.data
      }).catch((err) => {
        if (err.response.status === 422) {
          this.validation = err.response.data.errors
        } else {
          this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
        }
      })
    },
    deleteCustomerMembership (uuid) {
      this.$swal({
        title: this.swalTitle,
        text: this.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.swalYesButton,
        cancelButtonText: this.swalNoButton
      }).then(() => {
        this.$isLoading(true)
        this.$axios.post(`${this.$config.base_url}/api/v2/customer-membership/delete`, {
          uuid: uuid,
          shop_uuid: this.$config.shop_uuid
        }).then((res) => {
          this.membershipDetail = null
          this.getMemberships(1)
        }).catch((err) => {
          if (err.response.status === 422) {
            this.validation = err.response.data.errors
          } else {
            this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
          }
        }).finally(() => {
          this.$isLoading(false)
        })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },
    closeDrawer () {
      this.showFormCreateMembership = false
      this.showFormEditMembership = false
    },
    onClickSelectMembership (item) {
      this.onLoadingDetail = true
      this.selectMembership(item)
    },
    selectMembership: debounce(function (item) {
      this.showFormCreateMembership = false
      this.showFormEditMembership = false
      this.$axios.post(this.$config.base_url + "/api/v2/customer-membership/show", {
        uuid: item.uuid
      }).then((res) => {
        this.membershipDetail = res.data.data
      }).catch((err) => {
        this.getResponseError(err)
      }).finally(() => {
        this.onLoadingDetail = false
      })
    }, 800),
    getMemberships (page) {
      this.onLoading = true
      this.page = 1
      if (page) {
        this.page = page
      }
      this.list_memberships = []
      this.membershipDetail = null
      this.infiniteMembershipId += 1
      this.infiniteHandlerMembershipList()
    },
    infiniteHandlerMembershipList: debounce(function ($state) {
      this.$axios
        .post(this.$config.base_url + "/api/v2/customer-membership/list", {
          shop_uuid: this.$config.shop_uuid,
          page: this.page,
          search_query: this.search_query
        })
        .then(({ data }) => {
          if (!(this.page > data.total_pages)) {
            this.page += 1
            this.list_memberships.push(...data.data)
            if ($state) {
              $state.loaded()
            }
          } else {
            if (data.data) {
              $state.loaded()
              $state.complete()
            }
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.onLoading = false
        })
    }, 800),
    checkLabelDate (duration, type) {
      switch (type) {
      case "day":
        if (duration > 1) {
          return this.$t("date.days")
        } else {
          return this.$t("date.day")
        }
      case "month":
        if (duration > 1) {
          return this.$t("date.months")
        } else {
          return this.$t("date.month")
        }
      case "year":
        if (duration > 1) {
          return this.$t("date.years")
        } else {
          return this.$t("date.year")
        }
      default:
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
