<template>
  <div>
    <master-header />
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="coupon-container">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MasterHeader from "@/includes/Header"

export default {
  name: "CouponIndex",
  components: { MasterHeader }
}
</script>

<style scoped>
.coupon-container {
  margin-top: 25px;
  margin-bottom: 25px;
  height: 100vh;
  width: 100%;
}
</style>
