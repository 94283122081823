<template>
  <div class="well margin-bottom-none">
    <ul
      class="margin-bottom-none"
      style="padding-left: 15px"
    >
      <template v-if="$i18n.locale === 'km'">
        <li>
          ឈ្មោះអ្នកប្រើប្រាស់អាចមានអក្សរ <strong>(a-z)</strong>, លេខ <strong>(0-9)</strong>, សញ្ញាគូសក្រោម
          <strong>(_)</strong>, និងសញ្ញាចុច <strong>(.)</strong>
        </li>
        <li>
          ឈ្មោះអ្នកប្រើមិនអាចប្រើសញ្ញាគូសក្រោម <strong>(_)</strong> ឬសញ្ញាចុច <strong>(.)</strong> ផ្ទួនគ្នាបានទេ។
          <br>
          ឧ: " abc__def ", " abc..def ", " abc_.def "។
        </li>
        <li>
          ឈ្មោះអ្នកប្រើមិនអាចមានការចាប់ផ្តើម និងបញ្ចប់ដោយសញ្ញាគូសក្រោម <strong>(_)</strong> ឬសញ្ញាចុច
          <strong>(.)</strong>
          <br>
          ឧ: " abcdef. ", " _abcdef "។
        </li>
      </template>
      <template v-else>
        <li>
          Username can contain letters <strong>(a-z)</strong>, numbers <strong>(0-9)</strong>, underscore
          <strong>(_)</strong>, and periods <strong>(.)</strong>
        </li>
        <li>
          Username cannot duplicate underscore <strong>(_)</strong>, or periods <strong>(.)</strong>.
          <br>
          Ex: " abc__def ", " abc..def ", " abc_.def ".
        </li>
        <li>
          Username cannot contain start and end with underscore <strong>(_)</strong> or periods <strong>(.)</strong>
          <br>
          Ex: " abcdef. ", " _abcdef ".
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "UsernameTrip"
}
</script>

<style scoped>

</style>
