<template>
  <div class="product-each-item user-select-none rounded-tr rounded-tl">
    <div
      class="product rounded-tr rounded-tl"
      @click="checkProductHavVariant"
    >
      <div class="product-each-item__top rounded-tr rounded-tl position-relative">
        <template v-if="product.hasOwnProperty('images') && product.images.length > 0">
          <div
            :style="`background-image: url('${$config.base_url}/${replaceImgUrl(product.images[0].src, 'thumbnail')}')`"
            class="product-each-item__image rounded-tr rounded-tl"
          />
        </template>
        <template v-else>
          <div
            :style="`background-image: url('${defaultImg}')`"
            class="product-each-item__image image_contain rounded-tr rounded-tl"
          />
        </template>
        <template v-if="checkVariant">
          <div class="product-has-variant">
            <i class="fas fa-plus-square icon" />
          </div>
        </template>
        <div
          :class="'stock-' + checkStock"
          class="product-amount-in-stock"
        >
          <div
            v-if="product.has_inventory"
            class="user-select-none margin-bottom-none"
          >
            {{ product.initAmountInStock | numFormat(isFloat(parseFloat(product.initAmountInStock))) }}
            {{ product.unit ? product.unit["name_" + $i18n.locale] : null }}
          </div>
          <div
            v-else
            class="margin-bottom-none"
          >
            {{ $t("label.unlimited") }}
          </div>
        </div>
      </div>
      <div class="product-each-item__content">
        <div class="product-each-item__name text-ellipsis-2-line">
          {{ product.name }}
        </div>
        <template v-if="product.sale_price">
          <div
            class="font-s-13 text-ellipsis"
            style="height: 18px;"
          >
            <template v-if="product.has_promotion">
              <del class="badge badge-danger badge-del-price max-w-100">
                <em class="minus no-margin">
                  <span v-if="showChildrenOriginPrice.min">
                    {{ product.currency ? product.currency.symbol : "$" }}
                    <template v-if="product.currency && product.currency.code === 'KHR'">
                      {{ showChildrenSalePrice.min | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ showChildrenSalePrice.min | numFormat(num_format) }}
                    </template>
                  </span>
                  <span v-if="showChildrenOriginPrice.max">
                    {{ product.currency ? product.currency.symbol : "$" }}
                    <template v-if="product.currency && product.currency.code === 'KHR'">
                      {{ showChildrenSalePrice.max | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ showChildrenSalePrice.max | numFormat(num_format) }}
                    </template>
                  </span>
                </em>
              </del>
            </template>
          </div>
          <div class="text-bold font-s-16">
            <label class="minus no-margin text-ellipsis">
              <span v-if="showChildrenSalePrice.min">
                {{ product.currency ? product.currency.symbol : "$" }}
                {{ showChildrenSalePrice.min | numFormat(num_format) }}
              </span>
              <span v-if="showChildrenSalePrice.max">
                {{ product.currency ? product.currency.symbol : "$" }}
                {{ showChildrenSalePrice.max | numFormat(num_format) }}
              </span>
            </label>
          </div>
        </template>
      </div>
    </div>

    <OfflineProductVariant
      ref="offlineProductVariantModal"
      :check-variant="checkVariant"
      :offline-order-products="offlineOrderProducts"
      :product="product"
      modal-id="grid"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import OfflineProductVariant from "@/views/offline/order/includes/OfflineProductVariant"

export default {
  name: "OfflineProductCard",
  components: { OfflineProductVariant },
  props: {
    product: {
      type: Object,
      required: true
    },
    offlineOrderProducts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      origin_url: process.env.VUE_APP_ORIGIN_URL
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat
    }),
    currency () {
      if (this.product.currency_id) {
        return this.product.symbol
      }
      return ""
    },
    checkStock () {
      if (this.product && this.product.has_inventory) {
        if (parseFloat(this.product.initAmountInStock) <= 0) {
          return "danger"
        } else if (parseFloat(this.product.initAmountInStock) < parseFloat(this.product.stock_alert_level) && this.product.amount_in_stock > 0) {
          return "warning"
        }
      }
      return "success"
    },
    checkVariant () {
      if (this.product && this.product.children && Array.isArray(this.product.children) && this.product.children.length > 0) {
        return true
      }
      return false
    },
    showChildrenSalePrice () {
      if (this.product.children_sale_price && this.product.children_sale_price.length > 0) {
        const max = Math.max(...this.product.children_sale_price)
        const min = Math.min(...this.product.children_sale_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.sale_price,
            min: null
          }
        }
      }
      return {
        max: this.product.sale_price,
        min: null
      }
    },
    showChildrenOriginPrice () {
      if (this.product.children_origin_price && this.product.children_origin_price.length > 0) {
        const max = Math.max(...this.product.children_origin_price)
        const min = Math.min(...this.product.children_origin_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.origin_price,
            min: null
          }
        }
      }
      return {
        max: this.product.origin_price,
        min: null
      }
    }
  },
  methods: {
    onClick () {
      this.$emit("onClick")
    },
    checkProductHavVariant () {
      if (this.checkVariant) {
        this.$refs.offlineProductVariantModal.toggleModal()
      } else {
        this.onClick()
      }
    }
  }
}
</script>
