<template>
  <IndexLayout
    :btn-title="btn_title"
    :is-not-route-list="isNotRouteList"
    :route-name-create="route_name_create"
    :title="title"
  />
</template>

<script>
import IndexLayout from "@/components/layout/IndexLayout"

export default {
  name: "InventoryLayout",
  components: { IndexLayout },
  computed: {
    isNotRouteList () {
      return this.$route.name !== "list-product"
    }
  },
  data () {
    return {
      route_name_create: "create-product",
      title: "string.product",
      btn_title: "string.newProduct"
    }
  }
}
</script>

<style scoped>

</style>
