<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            <span v-if="this.$route.name === 'edit-product'">{{ $t("product.label.edit") }}</span>
            <span v-else>{{ $t("product.label.show") }}</span>
          </h3>
          <div class="box-tools pull-right">
            <template v-if="!isParent">
              <button
                class="btn btn-danger btn-sm"
                @click="deleteProductVariant"
              >
                <i class="fas fa-trash-alt" /> {{ $t("button.deleteVariant") }}
              </button>
            </template>
            <button
              class="btn btn-default btn-sm hidden-xs"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </button>
          </div>
        </div>
        <div
          v-if="product"
          class="box-body"
        >
          <div class="row">
            <div class="col-md-5 col-lg-3">
              <div class="form-group">
                <label class="box-title">{{ product.name }}</label>
              </div>
              <template v-if="productChild && Array.isArray(productChild) && productChild.length > 0">
                <div class="list-group">
                  <a
                    :class="'list-group-item ' + [isParent === true ? 'active' : '']"
                    :href="`javascript:void(0)`"
                    @click="selectedParent"
                  >
                    {{ product.name }}
                  </a>
                </div>
                <div class="list-group">
                  <template v-for="(child, each_product_child_key) in productChild">
                    <template
                      v-if="child.product_variants && Array.isArray(child.product_variants) && child.product_variants.length > 0"
                    >
                      <a
                        :key="each_product_child_key"
                        :class="'list-group-item  ' + child.is_active"
                        href="javascript:void(0)"
                        @click="selectedVariant(child)"
                      >
                        <i class="fas fa-list-ul" />
                        <template v-for="(variant, each_child_product_variant_key) in child.product_variants">
                          <span :key="each_child_product_variant_key">
                            {{ variant.pivot.value }}
                            <span v-if="each_child_product_variant_key < child.product_variants.length-1">/</span>
                          </span>
                        </template>
                      </a>
                    </template>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="block">
                  <p class="text-center">
                    {{ $t("string.noProductVariants") }}
                  </p>
                </div>
              </template>
            </div>
            <div class="col-md-7 col-lg-6">
              <div
                v-if="product !== null"
                class="row"
              >
                <div class="col-md-12 has-border-r has-border-l no-border-xs">
                  <template v-if="isParent">
                    <div class="row">
                      <div class="col-md-6">
                        <div
                          :class="{'has-error': errorsMessage && errorsMessage.hasOwnProperty('barcode')}"
                          class="form-group"
                        >
                          <label class="">{{ $t("string.barcode") }}</label>
                          <input
                            v-model="product.barcode"
                            :placeholder="$t('string.barcode')"
                            class="form-control input-lg"
                            type="text"
                          >
                          <template v-if="errorsMessage && errorsMessage.hasOwnProperty('barcode')">
                            <span class="help-block">{{ errorsMessage["barcode"]["en"] }}</span>
                          </template>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          :class="{'has-error': errorsMessage && errorsMessage.hasOwnProperty('sku')}"
                          class="form-group"
                        >
                          <label class="">{{ $t("string.sku") }}</label>
                          <input
                            v-model="product.sku"
                            :placeholder="$t('placeholder.sku')"
                            class="form-control input-lg"
                            type="text"
                          >
                          <template v-if="errorsMessage && errorsMessage.hasOwnProperty('sku')">
                            <span class="help-block">{{ errorsMessage["sku"]["en"] }}</span>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div
                          :class="{'has-error': errorsMessage && errorsMessage.hasOwnProperty('name')}"
                          class="form-group"
                        >
                          <label class="required">{{ $t("fields.label.name") }}</label>
                          <input
                            v-model="product.name"
                            :placeholder="$t('fields.placeholder.name')"
                            class="form-control input-lg"
                            type="text"
                          >
                          <template
                            v-if="errorsMessage && errorsMessage.hasOwnProperty('name')"
                          >
                            <span class="help-block">{{ errorsMessage["name"][0] }}</span>
                          </template>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("fields.label.category") }}</label>
                          <multiselect
                            v-model="product.category"
                            :label="'name_' + $i18n.locale"
                            :option-height="104"
                            :options="categoryOptions"
                            :placeholder="$t('fields.placeholder.category')"
                            :show-labels="false"
                            track-by="id"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("fields.label.tag") }}</label>
                          <multiselect
                            v-model="product.product_tags"
                            :label="'name_' + $i18n.locale"
                            :multiple="true"
                            :option-height="104"
                            :options="tagOptions"
                            :placeholder="$t('fields.placeholder.tag')"
                            :show-labels="false"
                            track-by="id"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("fields.chooseUnit.label") }}</label>
                          <multiselect
                            v-model="product.unit"
                            :label="'name_' + $i18n.locale"
                            :option-height="104"
                            :options="unitOptions"
                            :placeholder="$t('fields.placeholder.chooseUnit')"
                            :show-labels="false"
                            track-by="id"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <template
                      v-if="product.product_variants !== null && product.product_variants.length > 0"
                    >
                      <template v-for="(item, key) in product.product_variants">
                        <div
                          :key="key"
                          class="form-group"
                        >
                          <label>{{ $i18n.locale === "km" ? item.name_km : item.name }}</label>
                          <input
                            v-model="item.pivot.value"
                            class="form-control input-lg"
                            type="text"
                          >
                        </div>
                      </template>
                    </template>
                  </template>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ $t("fields.label.importedPrice") }}</label>
                        <input
                          v-model="product.imported_price"
                          :placeholder="$t('fields.placeholder.importedPrice')"
                          class="form-control input-lg"
                          type="number"
                        >
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div
                        :class="{'has-error': errorsMessage && errorsMessage.hasOwnProperty('sale_price')}"
                        class="form-group"
                      >
                        <label class="required">{{ $t("fields.label.salePrice") }}</label>
                        <input
                          v-model="product.sale_price"
                          :placeholder="$t('fields.placeholder.salePrice')"
                          class="form-control input-lg"
                          type="number"
                        >
                        <template
                          v-if="errorsMessage && errorsMessage.hasOwnProperty('sale_price')"
                        >
                          <span class="help-block">{{ errorsMessage["sale_price"][0] }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group margin-bottom-none">
                        <label>{{ $t("fields.label.currency") }}</label>
                        <multiselect
                          v-model="product.currency"
                          :label="'name_' + $i18n.locale"
                          :option-height="104"
                          :options="currencyOptions"
                          :placeholder="$t('fields.placeholder.chooseCurrency')"
                          :show-labels="false"
                          track-by="id"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group margin-bottom-none">
                        <label for="toggle_product">{{ $t("string.status") }}</label>
                        <div class="input-group">
                          <label for="toggle_product">
                            <input
                              id="toggle_product"
                              v-model="product.active"
                              name="toggle_product"
                              type="checkbox"
                            > {{ $t("string.active") }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Start Section Variant-->
                  <template v-if="isParent">
                    <div class="row">
                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                    <!--Section Variant-->
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <label style="font-size: 18px">{{ $t("product.label.variants") }}</label>
                          </div>
                          <div class="col-md-6">
                            <template v-if="!(product.children && product.children.length)">
                              <div
                                v-if="variantUsed.length"
                                class="pull-right"
                              >
                                <button
                                  class="btn btn-danger mr-0"
                                  @click="clickBtnVariant"
                                >
                                  {{ $t("button.cancel") }}
                                </button>
                              </div>
                              <div
                                v-if="variantTypes.length > variantUsed.length"
                                :class="{'mr-2': variantUsed.length}"
                                class="dropdown pull-right"
                              >
                                <button
                                  id="dropdownMenu1"
                                  aria-expanded="true"
                                  aria-haspopup="true"
                                  class="btn btn-add-other-option dropdown-toggle mr-0"
                                  data-toggle="dropdown"
                                  type="button"
                                >
                                  <i class="fas fa-plus-circle" />
                                  {{ $t("product.buttons.addAnotherOption") }}
                                  <span class="caret" />
                                </button>
                                <template v-if="variantTypes && Array.isArray(variantTypes) && variantTypes.length > 0">
                                  <ul
                                    aria-labelledby="dropdownMenu1"
                                    class="dropdown-menu"
                                  >
                                    <template v-for="(item, key) in variantTypes">
                                      <li
                                        v-if="!item.used"
                                        :key="key"
                                      >
                                        <a @click="addMoreVariant(item.id)">{{ item["label_" + $i18n.locale] }}</a>
                                      </li>
                                    </template>
                                  </ul>
                                </template>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="variantUsed && Array.isArray(variantUsed) && variantUsed.length > 0"
                        class="col-md-12"
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>
                                {{ $t("label.note") }} :
                              </label>
                              <ul>
                                <li>{{ $t("label.beforeUpdateProduct") }}</li>
                                <li>{{ $t("label.addVariantTutorial") }}</li>
                                <li>{{ $t("label.updateVariantExisting") }}</li>
                                <li>{{ $t("label.notAllowAddMoreVariant") }}</li>
                                <li>{{ $t("label.wantToAddMoreVariant") }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <template v-for="(variantType, key) in variantUsed">
                          <div
                            v-if="variantUsed.length > 0"
                            :key="key"
                            class="row margin-bottom"
                          >
                            <div
                              class="col-md-4 col-lg-2"
                              style="padding-top: 12px"
                            >
                              <label>{{ variantType["label_" + $i18n.locale] }}:</label>
                            </div>
                            <div
                              :class="!(product.children && product.children.length) ? 'col-md-7 col-lg-9' : 'col-md-8 col-lg-10'"
                            >
                              <multiselect
                                v-model="variantType.values"
                                :multiple="true"
                                :option-height="104"
                                :options="variantType.options"
                                :placeholder="$t('fields.placeholder.searchOrAddAValue')"
                                :tag-placeholder="$t('fields.placeholder.addAValue')"
                                :taggable="true"
                                label="name"
                                track-by="value"
                                @tag="tagInput(variantType, $event, 'add')"
                              />
                            </div>
                            <template v-if="!(product.children && product.children.length)">
                              <div
                                class="col-md-1 col-lg-1 text-right"
                                style="padding-top: 3px"
                                @click="removeVariant(variantType)"
                              >
                                <button class="btn btn-danger">
                                  <i class="fa fa-trash" />
                                </button>
                              </div>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div
                      v-if="productVariants.length > 0"
                      class="row"
                    >
                      <div class="col-md-12 font-s-18">
                        <p><strong>{{ $t("product.label.modifyVariant") }}: {{ productVariants.length }}</strong></p>
                      </div>
                      <div class="col-md-12">
                        <template v-for="(eachProduct, key) in productVariants">
                          <div
                            :key="key"
                            :class="{'product-children-has-error': eachProduct.sale_price.errorMessage !== ''}"
                            class="product-children"
                          >
                            <div class="row">
                              <div class="col-md-12 d-flex align-items-center justify-content-between">
                                <div>
                                  <p class="slash margin-bottom-none">
                                    <label
                                      v-if="eachProduct.hasOwnProperty('value_level_1')"
                                      class="margin-bottom-none"
                                    >
                                      {{ eachProduct.label_1[$i18n.locale] }} :
                                      <strong>{{ eachProduct.value_level_1 }}</strong>
                                    </label>
                                    <label
                                      v-if="eachProduct.hasOwnProperty('value_level_2')"
                                      class="margin-bottom-none"
                                    >
                                      {{ eachProduct.label_2[$i18n.locale] }} :
                                      <strong>{{ eachProduct.value_level_2 }}</strong>
                                    </label>
                                    <label
                                      v-if="eachProduct.hasOwnProperty('value_level_3')"
                                      class="margin-bottom-none"
                                    >
                                      {{ eachProduct.label_3[$i18n.locale] }} :
                                      <strong>{{ eachProduct.value_level_3 }}</strong>
                                    </label>
                                    <label
                                      v-if="eachProduct.hasOwnProperty('value_level_4')"
                                      class="margin-bottom-none"
                                    >
                                      {{ eachProduct.label_4[$i18n.locale] }} :
                                      <strong>{{ eachProduct.value_level_4 }}</strong>
                                    </label>
                                  </p>
                                </div>
                                <div>
                                  <a
                                    :aria-controls="'collapseProductChild' + key"
                                    :href="'#collapseProductChild' + key"
                                    aria-expanded="true"
                                    class="btn btn-default btn-sm"
                                    data-toggle="collapse"
                                    role="button"
                                    @click="eachProduct.show_collapse = !eachProduct.show_collapse"
                                  >
                                    <i
                                      :class="eachProduct.show_collapse ? 'fa-chevron-up' : 'fa-chevron-down'"
                                      class="fas"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              :id="'collapseProductChild' + key"
                              class="collapse in"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.importedPrice") }}</label>
                                    <input
                                      v-model="eachProduct.imported_price"
                                      :placeholder="$t('product.fields.placeholder.importedPrice')"
                                      class="form-control input-lg"
                                      type="number"
                                    >
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div
                                    :class="{'has-error': eachProduct.sale_price.errorMessage !== ''}"
                                    class="form-group margin-bottom-none"
                                  >
                                    <label class="required">{{ $t("product.fields.label.salePrice") }}</label>
                                    <input
                                      v-model="eachProduct.sale_price.value"
                                      :placeholder="$t('product.fields.placeholder.salePrice')"
                                      class="form-control input-lg"
                                      type="number"
                                    >
                                    <template v-if="eachProduct.sale_price.errorMessage !== ''">
                                      <span class="help-block">{{ eachProduct.sale_price.errorMessage }}</span>
                                    </template>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.unit") }}</label>
                                    <multiselect
                                      v-model="eachProduct.product_unit"
                                      :label="'name_' + $i18n.locale"
                                      :option-height="104"
                                      :options="unitOptions"
                                      :placeholder="$t('product.fields.placeholder.unit')"
                                      :show-labels="false"
                                      track-by="id"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="product.has_inventory"
                                class="row margin-top"
                              >
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.amount") }}</label>
                                    <input
                                      v-model="eachProduct.amount"
                                      :placeholder="$t('product.fields.placeholder.amount')"
                                      class="form-control input-lg"
                                      type="number"
                                    >
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.warehouse") }}</label>
                                    <multiselect
                                      v-model="eachProduct.warehouse"
                                      :label="'name_' + $i18n.locale"
                                      :options="warehouseOptions"
                                      :placeholder="$t('product.fields.placeholder.warehouse')"
                                      :show-labels="false"
                                      track-by="id"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group margin-bottom-none">
                                    <label>{{ $t("product.fields.label.expiredDate") }}</label>
                                    <date-picker
                                      v-model="eachProduct.expired_date"
                                      :placeholder="$t('product.fields.placeholder.expiredDate')"
                                      class="w-100 d-block"
                                      format="YYYY-MM-DD"
                                      input-class="form-control date-picker-lg"
                                      lang="en"
                                      type="date"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                  <!--End Section Variant-->
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-3">
              <div class="form-group text-center">
                <label>{{ $t("fields.label.image") }}</label>
                <template>
                  <vue-croppie
                    ref="croppieRef"
                    :boundary="{ width: 250, height: 250 }"
                    :enable-orientation="true"
                    :show-zoomer="false"
                    :viewport="{ width: 240, height: 240 }"
                    @result="result"
                  />
                  <input
                    id="product-input-file"
                    accept="image/*"
                    style="display: none"
                    type="file"
                    @change="selectImg"
                  >
                  <div class="text-center margin-top">
                    <div
                      aria-label="..."
                      class="btn-group"
                      role="group"
                    >
                      <button
                        class="btn btn-primary"
                        title="Choose Image"
                        @click="clickToBrowse"
                      >
                        <i class="fas fa-paperclip" />
                      </button>
                      <button
                        class="btn btn-default"
                        title="Rotate to Left"
                        @click="rotate(-90)"
                      >
                        <i class="fas fa-undo" />
                      </button>
                      <button
                        class="btn btn-default"
                        title="Rotate to Right"
                        @click="rotate(90)"
                      >
                        <i class="fas fa-repeat" />
                      </button>
                      <button
                        ref="cropImage"
                        :class="{'disabled': !selected_image}"
                        :disabled="!selected_image"
                        class="btn btn-success"
                        title="Crop Image"
                        @click="crop"
                      >
                        <i class="fas fa-crop-alt" />
                      </button>
                    </div>
                  </div>
                </template>
              </div>
              <div class="form-group">
                <p class="text-bold">
                  {{ $t("note") }}:
                </p>
                <template v-if="$i18n.locale === 'km'">
                  <ul style="padding-left: 16px">
                    <li>ចុចប៊ូតុង "<i class="fas fa-paperclip" />" ដើម្បីជ្រើសរើសរូបភាព។</li>
                    <li>ចុចប៊ូតុង "<i class="fas fa-undo" />" ដើម្បីបង្វិលរូបភាពទៅខាងឆ្វេង។</li>
                    <li>ចុចប៊ូតុង "<i class="fas fa-repeat" />" ដើម្បីបង្វិលរូបភាពទៅខាងស្ដាំ។</li>
                    <li>ចុចប៊ូតុង "<i class="fas fa-crop-alt" />" ដើម្បីកាត់រូបភាពមុនពេលរក្សាទុក។</li>
                  </ul>
                </template>
                <template v-else>
                  <ul style="padding-left: 16px">
                    <li>Click on button "<i class="fas fa-paperclip" />" for choose image.</li>
                    <li>Click on button "<i class="fas fa-undo" />" for rotate image to the left.</li>
                    <li>Click on button "<i class="fas fa-repeat" />" for rotate image to the right.</li>
                    <li>Click on button "<i class="fas fa-crop-alt" />" for crop image before save.</li>
                  </ul>
                </template>
              </div>
              <template v-if="product.images && Array.isArray(product.images) && product.images.length > 0">
                <div class="row">
                  <div
                    v-for="(image, each_product_image_key) in product.images"
                    :key="each_product_image_key"
                    class="col-md-4 clickable deletable"
                    @click="deleteImage(image)"
                  >
                    <img
                      alt="Product thumbnail"
                      :src="`${$config.base_url}/${image.src}`"
                      class="img-thumbnail"
                    >
                  </div>
                </div>
              </template>
              <template v-else>
                <p class="text-muted text-center">
                  {{ $t("inventory.label.noImages") }}
                </p>
              </template>
              <template v-if="newImages && Array.isArray(newImages) && newImages.length > 0">
                <div class="row">
                  <div
                    v-for="(image, each_new_product_image_key) in newImages"
                    :key="each_new_product_image_key"
                    class="col-md-4 clickable deletable"
                    @click="removeNewImage(image)"
                  >
                    <img
                      alt="Product thumbnail"
                      :src="image"
                      class="img-thumbnail"
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="box-footer text-right">
          <template v-if="$route.name !== 'show-product-category'">
            <button
              class="btn btn-default hidden-xs"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </button>
            <button
              :class="`btn btn-${$route.name === 'edit-drink' ? 'info' : 'primary'}`"
              @click="autoCropImage"
            >
              <i class="fas fa-check-circle" />
              <template v-if="$route.name === 'edit-drink'">
                {{ $t("button.update") }}
              </template>
              <template v-else>
                {{ $t("button.save") }}
              </template>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"

export default {
  name: "EditProduct",
  metaInfo () {
    return {
      title: this.$t("page.editProduct"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    DatePicker
  },
  data () {
    return {
      config: this.$config,
      product: null,
      productChild: null,
      parentProduct: {},
      isParent: true,

      errorsMessage: null,
      cropped: null,
      categoryOptions: [],
      unitOptions: [],
      productTypeOptions: [],
      tagOptions: [],
      currencyOptions: [],
      warehouseOptions: [],
      addVariant: false,
      variantTypes: [],
      variantUsed: [],
      newImages: [],
      selected_image: null,
      flagValidateChild: true,
      productVariants: []
    }
  },
  watch: {
    variantUsed: {
      handler (val, oldVal) {
        this.setProductChild()
      },
      deep: true
    },
    "product.sale_price": {
      handler (val, oldVal) {
        if (this.productVariants.length) {
          this.setProductChild()
        }
      },
      deep: true
    },
    "product.imported_price": {
      handler (val, oldVal) {
        if (this.productVariants.length) {
          this.setProductChild()
        }
      },
      deep: true
    },
    "product.product_unit": {
      handler (val, oldVal) {
        if (this.productVariants.length) {
          this.setProductChild()
        }
      },
      deep: true
    }
  },
  computed: {
    messageDeleteVariant () {
      if (this.product && this.product.product_variants) {
        let variant = ""
        this.product.product_variants.forEach((item) => {
          if (item.pivot && item.pivot.value) {
            variant += `<span>${item.pivot.value}</span>`
          }
        })
        return {
          message_en: "Do you want to delete this variant: (<label class=\"slash\">" + variant + "</label>)?" +
            "<br> Please check up your variant data before delete." + "<br> You won't be able to revert this!",
          message_km: "តើអ្នកចង់លុបលក្ខណៈរបស់ទំនិញនេះ (<label class=\"slash\">" + variant + "</label>) ទេ? " +
            "<br> សូមពិនិត្យមើលទិន្នន័យលក្ខណៈរបស់ទំនិញរបស់អ្នកមុនពេលលុប។" + "<br> អ្នកនឹងមិនអាចត្រឡប់វាវិញបានទេ!"
        }
      }
      return {
        message_en: "Do you want to delete the variant?" +
          "<br> Please check up your variant data before delete." + "<br> You won't be able to revert this!",
        message_km: "តើអ្នកចង់លុបលក្ខណៈរបស់ទំនិញ? " +
          "<br> សូមពិនិត្យមើលទិន្នន័យលក្ខណៈរបស់ទំនិញរបស់អ្នកមុនពេលលុប។" + "<br> អ្នកនឹងមិនអាចត្រឡប់វាវិញបានទេ!"
      }
    }
  },
  methods: {
    storeProduct () {
      this.errorsMessage = null
      if (this.isParent === true) {
        this.validateVariant()
      }
      this.product.shop_id = this.get_shop.id
      this.product.is_parent = this.isParent
      this.product.flagValidateChild = this.flagValidateChild
      if (this.product && this.product.children) {
        this.product.children.forEach((child) => {
          this.productVariants.forEach((variant) => {
            if (child.variant_label === variant.variant_label) {
              variant.product_id = child.id
            }
          })
        })
      }
      this.product.new_children = this.productVariants
      this.product.variants = this.variantUsed
      this.$axios.post(this.$config.base_url + "/api/product/store", {
        ...this.product,
        images: this.newImages
      }).then((response) => {
        if (response.data.code === 1) {
          this.products = []
          this.variantUsed = []
          this.$router.back()
          if (this.product.hasOwnProperty("id") && this.product.id !== null) {
            this.$toastr("success", this.$t("string.theProductWasUpdatedSuccessfully"), this.$t("string.success"))
          } else {
            this.$toastr("success", this.$t("string.theProductWasCreatedSuccessfully"), this.$t("string.success"))
          }
        } else {
          if (typeof (response.data.message) === "object") {
            this.errorsMessage = response.data.message
            if (this.errorsMessage.hasOwnProperty("message_en") && this.errorsMessage.hasOwnProperty("message_km")) {
              this.$toastr("error", this.errorsMessage["message_" + this.$i18n.locale], this.$t("string.error"))
            }
          } else {
            this.$toastr("error", this.$t("string.canNotCreateOrUpdateProduct"), this.$t("string.error"))
          }
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
      this.selected_image = null
    },
    deleteProductVariant () {
      const self = this
      self.$swal({
        html: `<p class="text-bold font-s-18">${this.messageDeleteVariant["message_" + this.$i18n.locale]}</p>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then((result) => {
        this.$axios.post(this.$base_api + "/api/product/delete", {
          id: this.product.id,
          uuid: this.product.uuid,
          shop_id: this.get_shop.id
        }).then(() => {
          this.$isLoading(true)
          this.variantTypes = []
          this.variantUsed = []
          this.getVariantType()
          this.getProduct()
          this.selectedParent()
        }).catch((error) => {
          this.onResponseError(error)
        })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },

    selectedVariant (product) {
      this.isParent = false
      this.product = product
      this.productChild.forEach((item) => {
        if (item.id === product.id) {
          item.is_active = "active"
        } else {
          item.is_active = ""
        }
      })
    },
    selectedParent () {
      this.isParent = true
      this.productChild.forEach((item) => {
        item.is_active = ""
      })
      this.product = this.parentProduct
    },

    resetErrorsMessageObject () {
      this.errorsMessage = null
    },
    goToListRoute () {
      if (this.$route.name === "edit-product") {
        this.$router.push({ name: "list-product" })
      } else {
        this.$router.push({ name: "list-drink" })
      }
    },
    tagInput (item, newTag, type) {
      if (item !== null) {
        this.variantUsed.forEach((ele) => {
          if (ele.id === item.id) {
            if (type === "add") {
              const tag = {
                name: newTag,
                value: newTag
              }
              ele.values.push(tag)
              ele.options.push(tag)
            } else {
              ele.options.splice(ele.values.indexOf(newTag), 1)
              ele.values.splice(ele.values.indexOf(newTag), 1)
            }
          }
        })
      }
    },

    addMoreVariant (id) {
      const result = this.variantTypes.filter((eachVariant) => {
        return eachVariant.id === id
      })

      if (!(result && result[0])) {
        return 0
      }

      result[0].used = true
      result[0].options = []

      if (this.product.children.length) {
        this.product.children.forEach((item) => {
          if (item.product_variants && item.product_variants.length) {
            item.product_variants.forEach((variant) => {
              if (variant.id === result[0].id) {
                if (variant.pivot) {
                  const data = {
                    product_id: item.id,
                    name: variant.pivot.value,
                    value: variant.pivot.value
                  }
                  if (!(result[0].options.filter(e => e.value === variant.pivot.value).length > 0)) {
                    result[0].options.push(data)
                  }
                }
              }
            })
          }
        })
      }
      this.variantUsed.push(result[0])
    },
    clickBtnVariant () {
      this.addVariant = false
      this.variantTypes.forEach((variantType, index) => {
        this.$set(variantType, "used", false)
      })
      this.variantUsed = []
      this.products = []
    },
    removeVariant (item) {
      this.variantUsed.filter(function (ele) {
        if (ele === item) {
          // eslint-disable-next-line no-return-assign
          return ele.values = []
        }
      })
      const itemTotalVariant = this.variantTypes.find(eachVariant => {
        return eachVariant.id === item.id
      })
      itemTotalVariant.used = false
      this.variantUsed.splice(this.variantUsed.indexOf(item), 1)
      this.setProductChild()
    },
    validateVariant () {
      this.flagValidateChild = true
      if (this.productVariants.length > 0) {
        this.productVariants.forEach((eachChild) => {
          if (eachChild.sale_price.value === null || eachChild.sale_price.value === "") {
            this.flagValidateChild = false
            eachChild.sale_price.errorMessage = "The sale price field is required"
          } else {
            eachChild.sale_price.errorMessage = ""
          }
        })
      }
    },
    setProductChild () {
      const result = []
      const product = Object.assign({}, this.product)
      const variantUsed = Object.assign({}, this.variantUsed)

      if (this.variantUsed.length > 0) {
        if (this.variantUsed.length === 1) {
          this.variantUsed[0].values.forEach((eachValue) => {
            result.push(Object.assign({}, {
              level_1: variantUsed[0].id,
              value_level_1: eachValue.value,
              label_1: {
                en: variantUsed[0].label_en,
                km: variantUsed[0].label_km
              },
              sale_price: {
                value: product.sale_price,
                errorMessage: ""
              },
              show_collapse: true,
              amount: 0,
              imported_price: product.imported_price,
              warehouse: product.warehouses && product.warehouses.length ? product.warehouses[0] : null,
              product_unit: product.unit,
              expired_date: product.expired_date,
              variant_label: eachValue.value + "/"
            }))
          })
        } else if (this.variantUsed.length === 2) {
          this.variantUsed[0].values.forEach((eachValueLevel1) => {
            this.variantUsed[1].values.forEach((eachValueLevel2) => {
              result.push(Object.assign({}, {
                level_1: variantUsed[0].id,
                level_2: variantUsed[1].id,
                value_level_1: eachValueLevel1.value,
                value_level_2: eachValueLevel2.value,
                label_1: {
                  en: variantUsed[0].label_en,
                  km: variantUsed[0].label_km
                },
                label_2: {
                  en: variantUsed[1].label_en,
                  km: variantUsed[1].label_km
                },
                sale_price: {
                  value: product.sale_price,
                  errorMessage: ""
                },
                show_collapse: true,
                amount: 0,
                imported_price: product.imported_price,
                warehouse: product.warehouses && product.warehouses.length ? product.warehouses[0] : null,
                product_unit: product.unit,
                expired_date: product.expired_date,
                variant_label: eachValueLevel1.value + "/" + eachValueLevel2.value + "/"
              }))
            })
          })
        } else if (this.variantUsed.length === 3) {
          this.variantUsed[0].values.forEach((eachValueLevel1) => {
            this.variantUsed[1].values.forEach((eachValueLevel2) => {
              this.variantUsed[2].values.forEach((eachValueLevel3) => {
                result.push(Object.assign({}, {
                  level_1: variantUsed[0].id,
                  level_2: variantUsed[1].id,
                  level_3: variantUsed[2].id,
                  value_level_1: eachValueLevel1.value,
                  value_level_2: eachValueLevel2.value,
                  value_level_3: eachValueLevel3.value,
                  label_1: {
                    en: variantUsed[0].label_en,
                    km: variantUsed[0].label_km
                  },
                  label_2: {
                    en: variantUsed[1].label_en,
                    km: variantUsed[1].label_km
                  },
                  label_3: {
                    en: variantUsed[2].label_en,
                    km: variantUsed[2].label_km
                  },
                  sale_price: {
                    value: product.sale_price,
                    errorMessage: ""
                  },
                  show_collapse: true,
                  amount: 0,
                  imported_price: product.imported_price,
                  warehouse: product.warehouses && product.warehouses.length ? product.warehouses[0] : null,
                  product_unit: product.unit,
                  expired_date: product.expired_date,
                  variant_label: eachValueLevel1.value + "/" + eachValueLevel2.value + "/" + eachValueLevel3.value + "/"
                }))
              })
            })
          })
        } else {
          this.variantUsed[0].values.forEach((eachValueLevel1) => {
            this.variantUsed[1].values.forEach((eachValueLevel2) => {
              this.variantUsed[2].values.forEach((eachValueLevel3) => {
                this.variantUsed[3].values.forEach((eachValueLevel4) => {
                  result.push(Object.assign({}, {
                    level_1: variantUsed[0].id,
                    level_2: variantUsed[1].id,
                    level_3: variantUsed[2].id,
                    level_4: variantUsed[3].id,
                    value_level_1: eachValueLevel1.value,
                    value_level_2: eachValueLevel2.value,
                    value_level_3: eachValueLevel3.value,
                    value_level_4: eachValueLevel4.value,
                    label_1: {
                      en: variantUsed[0].label_en,
                      km: variantUsed[0].label_km
                    },
                    label_2: {
                      en: variantUsed[1].label_en,
                      km: variantUsed[1].label_km
                    },
                    label_3: {
                      en: variantUsed[2].label_en,
                      km: variantUsed[2].label_km
                    },
                    label_4: {
                      en: variantUsed[3].label_en,
                      km: variantUsed[3].label_km
                    },
                    sale_price: {
                      value: product.sale_price,
                      errorMessage: ""
                    },
                    show_collapse: true,
                    amount: 0,
                    imported_price: product.imported_price,
                    warehouse: product.warehouses && product.warehouses.length ? product.warehouses[0] : null,
                    product_unit: product.unit,
                    expired_date: product.expired_date,
                    variant_label: eachValueLevel1.value + "/" + eachValueLevel2.value + "/" + eachValueLevel3.value + "/" + eachValueLevel4.value + "/"
                  }))
                })
              })
            })
          })
        }
      }
      this.productVariants = result
    },

    clickToBrowse () {
      $("#product-input-file").click()
    },
    selectImg (event) {
      this.selected_image = event.target.files
    },
    autoCropImage () {
      this.$isLoading(true)
      if (this.selected_image && this.newImages.length === 0) {
        const options = { format: "png" }
        this.$refs.croppieRef.result(options)
      }
      setTimeout(() => {
        this.storeProduct()
      }, 1200)
    },
    crop () {
      if (this.selected_image) {
        const options = { format: "png" }
        this.$refs.croppieRef.result(options, (output) => {
          this.newImages.push(output)
          this.cropped = output
        })
      }
    },
    result (output) {
      this.newImages.push(output)
    },
    rotate (rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle)
    },
    removeNewImage (image) {
      const self = this
      self.$swal({
        title: this.swalTitle,
        text: this.$t("swal.confirm_delete.desc"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("swal.confirm_delete.button.yes"),
        cancelButtonText: this.$t("swal.confirm_delete.button.no")
      }).then(() => {
        self.newImages.splice(self.newImages.indexOf(image), 1)
      })
    },
    deleteImage (image) {
      const self = this
      self.$swal({
        title: this.swalTitle,
        text: this.$t("swal.confirm_delete.desc"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("swal.confirm_delete.button.yes"),
        cancelButtonText: this.$t("swal.confirm_delete.button.no")
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/product/delete-image", {
          id: image.id
        }).then((response) => {
          if (response.data.code === 1) {
            self.showDeleted()
            self.product.images.splice(self.product.images.indexOf(image), 1)
          }
        })
      })
    },

    getProduct () {
      this.$axios.post(this.$config.base_url + "/api/product/get-product-by-id", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          const result = response.data.data.product
          result.is_active = "active"
          this.parentProduct = result
          this.product = result
          if (this.product && this.product.children && this.product.children.length) {
            const children = this.product.children
            children.forEach((item) => {
              item.is_active = ""
              item.variant_label = ""
              if (item.product_variants && item.product_variants.length) {
                item.product_variants.forEach((variant) => {
                  if (variant.pivot && variant.pivot.value) {
                    item.variant_label += variant.pivot.value + "/"
                  }
                })
              }
            })
            if (this.product.product_variants && this.product.product_variants.length) {
              this.product.product_variants.forEach((variant) => {
                this.addMoreVariant(variant.id)
              })
            }
            this.productChild = children
          }
        }
        if (response.data.code === 0) {
          if (typeof (response.data.message) === "object") {
            this.message_validation = response.data.message
          } else {
            this.$toastr("error", response.data.message, "Product")
            if (this.$route.name === "edit-product") {
              this.$router.push({ name: "list-product" })
            } else {
              this.$router.push({ name: "list-drink" })
            }
          }
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getCurrencyAndWarehouse () {
      this.$axios.post(this.$config.base_url + "/api/currency/get-restau-currency", {
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1) {
          const result = response.data.data
          this.currencyOptions = result.currencies
          this.warehouseOptions = result.warehouse
        }
      })
    },
    getProductCategory () {
      this.$axios.post(this.$config.base_url + "/api/product-category", {
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1 && response.data.data !== null) {
          this.categoryOptions = response.data.data
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getProductUnit () {
      this.$axios.post(this.$config.base_url + "/api/product-unit", {
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1 && response.data.data !== null) {
          this.unitOptions = response.data.data
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getVariantType () {
      this.$axios.post(this.$config.base_url + "/api/variant-type", {
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1) {
          const result = response.data.data
          result.forEach((variantType) => {
            this.$set(variantType, "used", false)
            this.variantTypes.push({
              id: variantType.id,
              label_en: variantType.name,
              label_km: variantType.name_km,
              used: false,
              values: [],
              options: []
            })
          })
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getProductTag () {
      this.$axios.post(this.$config.base_url + "/api/product-tag", {
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1) {
          this.tagOptions = response.data.data
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      const self = this
      this.$isLoading(true)
      this.getProductCategory()
      this.getProductTag()
      this.getProductUnit()
      this.getCurrencyAndWarehouse()
      this.getVariantType()
      this.getProduct()
      $(document).on("change", "#product-input-file", function () {
        if (this.files && this.files[0]) {
          const reader = new FileReader()
          reader.onload = function (e) {
            if (self.$refs.croppieRef) {
              self.$refs.croppieRef.bind({
                url: e.target.result
              })
            }
          }
          reader.readAsDataURL(this.files[0])
        }
      })
    })
  }
}
</script>

<style scoped>
@import "../../../assets/css/V2/product-form.css";
</style>
