<template>
  <div class="row">
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.product.category.orderCategory") }}
          </h3>
          <div class="box-tools hidden-xs">
            <button
              class="btn btn-primary btn-sm margin-r-5"
              @click="setOrderProductCategories"
            >
              {{ $t("button.update_order") }}
            </button>
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body">
          <template v-if="Array.isArray(categories) && categories.length > 0">
            <draggable
              v-model="categories"
              group="categories"
              @end="drag=false"
              @start="drag=true"
            >
              <transition-group
                :name="'flip-list'"
                class="tw-space-y-2"
                type="transition"
              >
                <div
                  v-for="element in categories"
                  :key="element.id"
                >
                  <div class="tw-flex tw-bg-gray-100 hover:tw-bg-gray-200 tw-rounded-md tw-p-3 tw-cursor-move">
                    <div class="tw-ring">
                      <img
                        :src="getSrc(element.image)"
                        alt=""
                        class="tw-w-16 tw-h-auto"
                      >
                    </div>
                    <div class="tw-pl-3">
                      {{ element["name_" + $i18n.locale] }}
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </template>
          <template v-else>
            <EmptyCart />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import EmptyCart from "@/components/EmptyCart"

export default {
  name: "ProductCategoryOrder",
  metaInfo () {
    return {
      title: this.$t("label.product.category.orderCategory"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    EmptyCart,
    draggable
  },
  data () {
    return {
      config: this.$config,
      categories: []
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      }
    }
  },
  methods: {
    getProductCategories () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product-category/get-categories", {
        shop_id: this.get_shop.id
      }).then(({ data }) => {
        if (data.data) {
          this.categories = data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    setOrderProductCategories () {
      this.onConfirm({
        title: this.$t("string.ordering_category"),
        text: this.$t("string.do_you_update_the_order")
      }).then(({ accept }) => {
        if (accept) {
          this.$isLoading(true)
          this.$axios.post(this.$config.base_url + "/api/product-category/set-ordering-categories", {
            categories: this.categories,
            shop_id: this.get_shop.id
          }).then(() => {
            this.onResponseSuccess()
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    }
  },
  mounted () {
    this.getProductCategories()
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
