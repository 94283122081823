import Layout from "@/views/coupon/Index"
import List from "@/views/coupon/List"
import Show from "@/views/coupon/Show"
import Order from "@/views/coupon/Order"
import Payment from "@/views/coupon/Payment"
import Print from "@/views/coupon/Print"

export const couponRouters = {
  path: "/coupon",
  component: Layout,
  redirect: "coupon",
  children: [
    {
      path: "",
      name: "order-coupon",
      component: Order
    },
    {
      path: ":coupon_order_uuid/edit-coupon",
      name: "order-edit-coupon",
      component: Order
    },
    {
      path: ":coupon_order_uuid/payment",
      name: "payment-coupon",
      component: Payment
    },
    {
      path: "list",
      name: "list-coupon",
      component: List
    },
    {
      path: "show-coupon/:coupon_order_uuid",
      name: "show-coupon",
      component: Show
    }
  ]
}

export const couponPrintRouters = {
  path: "/coupon/:coupon_order_uuid/print",
  name: "print-coupon-cards",
  component: Print
}
