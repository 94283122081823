import Invoice from "@/views/offline/invoice/Index"
import Print from "@/views/offline/invoice/Print"

export const offlineInvoiceRouters = {
  path: "invoice",
  name: "offline-invoice",
  component: Invoice,
  children: [
    {
      path: "print/:order_uuid",
      name: "offline-print-invoice",
      component: Print
    }
  ]
}
