<template>
  <div class="no-print pos-menubar-h">
    <ul class="pos-menubar scrollbar">
      <template v-if="!is_expired">
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': taxTab}"
            :to="{name: 'list-tax'}"
            class="closesidebar"
          >
            <i class="fas fa-bus" />
            <p>{{ $t("menu.settings.tax") }}</p>
          </router-link>
        </li>
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': paymentOptionTab}"
            :to="{name: 'list-payment-option'}"
            class="closesidebar"
          >
            <i class="fas fa-credit-card" />
            <p>{{ $t("menu.settings.paymentOption") }}</p>
          </router-link>
        </li>
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': exchangeTab}"
            :to="{name: 'list-exchange'}"
            class="closesidebar"
          >
            <i class="fas fa-coins" />
            <p>{{ $t("menu.settings.exchange") }}</p>
          </router-link>
        </li>
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': bannerImageTab}"
            :to="{name: 'list-banner-image'}"
            class="closesidebar"
          >
            <i class="far fa-images" />
            <p>{{ $t("menu.settings.bannerImage") }}</p>
          </router-link>
        </li>
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': userTab}"
            :to="{name: 'list-user'}"
            class="closesidebar"
          >
            <i class="fas fa-users" />
            <p>{{ $t("menu.settings.user") }}</p>
          </router-link>
        </li>
        <template v-if="hasRoles([])">
          <li class="pos-menubar-item">
            <router-link
              :class="{'pos-menubar_active': aclUserTab}"
              :to="{name: 'acl-list-user'}"
              class="closesidebar"
            >
              <i class="fas fa-briefcase" />
              <p>{{ $t("menu.settings.acl") }}</p>
            </router-link>
          </li>
        </template>
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': invoiceTemplateTab}"
            :to="{name: 'list-invoice-template'}"
            class="closesidebar"
          >
            <i class="fas fa-file-invoice" />
            <p>{{ $t("menu.settings.invoiceTemplate") }}</p>
          </router-link>
        </li>
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': restaurantInfoTab}"
            :to="{name: 'restaurant-info'}"
            class="closesidebar"
          >
            <i class="fas fa-info-circle" />
            <p>{{ $t("menu.settings.aboutShop") }}</p>
          </router-link>
        </li>
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': additionalAppsTab}"
            :to="{name: 'list-additional-apps'}"
            class="closesidebar"
          >
            <i class="fab fa-buromobelexperte" />
            <p>{{ $t("menu.settings.additional_apps") }}</p>
          </router-link>
        </li>
      </template>
      <template v-if="$bill_active === 'true'">
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': subscriptionTab}"
            :to="{name: 'list-subscription'}"
            class="closesidebar"
          >
            <i class="fas fa-receipt" />
            <p>{{ $t("menu.subscription") }}</p>
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "SidebarSettingV2",
  computed: {
    ...mapGetters({
      is_expired: "shop/is_expired"
    }),
    taxTab () {
      const route_names = [
        "list-tax",
        "create-tax",
        "edit-tax",
        "show-tax"
      ]
      return route_names.includes(this.$route.name)
    },
    paymentOptionTab () {
      const route_names = [
        "list-payment-option",
        "create-payment-option",
        "edit-payment-option",
        "show-payment-option"
      ]
      return route_names.includes(this.$route.name)
    },
    exchangeTab () {
      const route_names = [
        "list-exchange",
        "create-exchange",
        "edit-exchange",
        "show-exchange"
      ]
      return route_names.includes(this.$route.name)
    },
    currencyTab () {
      const route_names = [
        "list-currency",
        "create-currency",
        "edit-currency",
        "show-currency"
      ]
      return route_names.includes(this.$route.name)
    },
    userTab () {
      const route_names = [
        "list-user",
        "create-user",
        "edit-user",
        "show-user",
        "user-reset-password"
      ]
      return route_names.includes(this.$route.name)
    },
    bannerImageTab () {
      const route_names = [
        "list-banner-image"
      ]
      return route_names.includes(this.$route.name)
    },
    aclUserTab () {
      const route_names = [
        "acl-list-user",
        "acl-create-user",
        "acl-edit-user",
        "acl-show-user",
        "acl-reset-password"
      ]
      return route_names.includes(this.$route.name)
    },
    restaurantInfoTab () {
      const route_names = [
        "restaurant-info",
        "restaurant-history-detail"
      ]
      return route_names.includes(this.$route.name)
    },
    subscriptionTab () {
      const route_names = [
        "list-subscription"
      ]
      return route_names.includes(this.$route.name)
    },
    invoiceTemplateTab () {
      const route_names = [
        "list-invoice-template",
        "create-invoice-template",
        "edit-invoice-template"
      ]
      return route_names.includes(this.$route.name)
    },
    additionalAppsTab () {
      const route_names = [
        "list-additional-apps"
      ]
      return route_names.includes(this.$route.name)
    }
  }
}
</script>

<style scoped>
</style>
