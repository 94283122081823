<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("strings.listingTaxes") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'create-tax'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("button.newTax") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-2 col-sm-3 col-xs-4 margin-bottom">
                <multiselect
                  v-model="selectedActiveOption"
                  :allow-empty="false"
                  :option-height="104"
                  :options="activeOptions"
                  :placeholder="$t('string.pleaseSelect')"
                  :show-labels="false"
                  label="label"
                  track-by="value"
                  @input="getTax"
                />
              </div>
            </div>
            <table
              id="tax-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("table.tax.name") }}</th>
                  <th>{{ $t("table.tax.amountPercentage") }}</th>
                  <th>{{ $t("table.tax.status") }}</th>
                  <th>{{ $t("table.tax.createdAt") }}</th>
                  <th>{{ $t("table.tax.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <TaxDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>
import TaxDataTableSm from "@/views/settings/tax/_components/TaxDataTableSm"

export default {
  name: "TaxList",
  components: { TaxDataTableSm },
  metaInfo () {
    return {
      title: this.$t("strings.listingTaxes"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null,
      selectedActiveOption: {
        label: this.$t("report.label.all"),
        value: ""
      },
      windowWidth: null
    }
  },
  computed: {
    activeOptions () {
      return [
        {
          label: this.$t("report.label.all"),
          value: ""
        },
        {
          label: this.$t("label.active"),
          value: true
        },
        {
          label: this.$t("label.inactive"),
          value: false
        }
      ]
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
      this.selectedActiveOption = this.activeOptions.find(item => item.value === this.selectedActiveOption.value)
    },
    "windowWidth" () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    oTable () {
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    deleteTax (uuid) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/tax/delete", {
          shop_id: self.get_shop.id,
          uuid: uuid
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.tax"))
            self.oTable.draw(true)
          }
        })
      })
    },
    getTax () {
      this.oTable.draw(true)
    },
    dataTable () {
      const self = this
      return $("#tax-table")
        .DataTable({
          processing: true,
          serverSide: true,
          stateSave: true,
          scrollX: true,
          ajax: {
            url: self.config.base_url + "/api/tax/data",
            type: "POST",
            data: (d) => {
              d.shop_id = self.get_shop.id
              d.labelEdit = self.labelEdit
              d.labelDelete = self.labelDelete
              d.labelShow = self.labelShowTrans
              d.labelInactive = self.labelInactive
              d.labelActive = self.labelActive
              d.active = self.selectedActiveOption.value
            },
            error: function (jqXHR, ajaxOptions, thrownError) {
              self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
            }
          },
          columns: [
            {
              data: "name_" + this.$i18n.locale,
              name: "name_" + this.$i18n.locale,
              title: self.$t("table.tax.name")
            },
            {
              data: "amount_percentage",
              name: "amount_percentage",
              title: self.$t("table.tax.amountPercentage")
            },
            {
              data: "active",
              name: "active",
              searchable: false,
              orderable: false,
              title: self.$t("table.tax.status")
            },
            {
              data: "created_at",
              name: "created_at",
              searchable: false,
              orderable: true,
              title: self.$t("table.tax.createdAt")
            },
            {
              data: "actions",
              name: "actions",
              searchable: false,
              orderable: false,
              title: self.$t("table.tax.actions")
            }
          ],
          order: [[3, "desc"]],
          language: {
            url: `/locale/${localStorage.getItem("locale")}.json`
          }
        })
    },
    reloadButtonAction () {
      const self = this

      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-edit")
      $(document).off("click", ".btn-delete")

      $(document).on("click", ".btn-show", function (e) {
        e.preventDefault()
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "show-tax",
          params: { uuid: uuid }
        })
      })

      $(document).on("click", ".btn-edit", function (e) {
        e.preventDefault()
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "edit-tax",
          params: { uuid: uuid }
        })
      })

      $(document).on("click", ".btn-delete", function (e) {
        e.preventDefault()
        const uuid = $(this).attr("data-uuid")
        self.deleteTax(uuid)
      })
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonAction()
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
