<template>
  <div>
    <ul class="pos-menubar">
      <template v-if="isOffline">
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': $route.name === 'offline-sale'}"
            :to="{name: 'offline-sale'}"
            class="closesidebar"
          >
            <i class="fas fa-shopping-cart" />
            <p class="no-margin capitalize">
              {{ $t("label.order") }}
            </p>
          </router-link>
        </li>
        <li class="pos-menubar-item">
          <a
            :class="{'pos-menubar_active': $route.name === 'offline-cashier-order-list'}"
            class="closesidebar"
            @click="checkHasOrderOffline('offline-cashier-order-list')"
          >
            <i class="fas fa-history" />
            <p class="no-margin capitalize">{{ $t("label.parkSale") }}</p>
          </a>
        </li>
        <li class="pos-menubar-item">
          <a
            :class="{'pos-menubar_active': $route.name === 'offline-list-recent-sale'}"
            class="closesidebar"
            @click="checkHasOrderOffline('offline-list-recent-sale')"
          >
            <i class="fas fa-money-check" />
            <p class="no-margin capitalize">{{ $t("label.sales") }}</p>
          </a>
        </li>
      </template>

      <template v-else>
        <li class="pos-menubar-item">
          <template v-if="device_for_sell">
            <a
              :class="{'pos-menubar_active': tabOrder}"
              @click="onClickNewOrder"
            >
              <i class="fas fa-shopping-cart" />
              <p>{{ $t("label.order") }}</p>
            </a>
          </template>
          <template v-else>
            <a style="cursor: not-allowed">
              <span
                class="fa-stack fa-lg"
                style="font-size: 16px;"
              >
                <span class="fas fa-shopping-cart fa-stack-1x" />
                <span class="fas fa-ban fa-stack-2x text-danger" />
              </span>
              <p>{{ $t("label.order") }}</p>
            </a>
          </template>
        </li>
        <li class="pos-menubar-item">
          <a
            :class="{'pos-menubar_active': $route.name === 'cashier-list-v2'}"
            class="closesidebar"
            @click="checkHasOrder('cashier-list-v2')"
          >
            <i class="fas fa-history" />
            <p>{{ $t("label.parkSale") }}</p>
          </a>
        </li>
        <li class="pos-menubar-item">
          <a
            :class="{'pos-menubar_active': tabSale}"
            class="closesidebar"
            @click="checkHasOrder('list-recent-sell')"
          >
            <i class="fas fa-money-check" />
            <p>{{ $t("label.sales") }}</p>
          </a>
        </li>
        <li class="pos-menubar-item">
          <a
            :class="{'pos-menubar_active': tabCustomer}"
            class="closesidebar"
            @click="checkHasOrder('cashier-list-customer')"
          >
            <i class="fas fa-user" />
            <p>{{ $t("label.customer") }}</p>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { VueOfflineMixin } from "vue-offline"
import { offlineMixins } from "@/mixins/offline"

export default {
  name: "LeftSideBarOrder",
  mixins: [VueOfflineMixin, offlineMixins],
  computed: {
    ...mapState({
      orderProducts: state => state.order.orderProducts,
      selectedCustomer: state => state.order.selectedCustomer,
      order_discount: state => state.order.order_discount,
      dis_as_percentage: state => state.order.is_percentage,
      authorizer: state => state.order.discount_authorizer,
      shop: state => state.shop.shop,
      device_for_sell: state => state.shop.device_for_sell
    }),

    tabOrder () {
      const route_names = [
        "order-show",
        "payment-show"
      ]
      return route_names.includes(this.$route.name)
    },
    tabSale () {
      const route_names = [
        "list-recent-sell"
      ]
      return route_names.includes(this.$route.name)
    },
    tabCustomer () {
      const route_names = [
        "cashier-list-customer"
      ]
      return route_names.includes(this.$route.name)
    }
  },
  methods: {
    onClickNewOrder () {
      if (!(this.$route.name === "order-show" && this.orderProducts.length === 0)) {
        this.$isLoading(true)
        this.$axios.post(this.$config.base_url + "/api/order/create-new-blank-order", {
          shop_uuid: this.shop.uuid
        })
          .then((response) => {
            const path = `/order/show/${response.data.data.uuid}/order`
            if (this.$route.path !== path) {
              this.$router.push({
                name: "order-show",
                params: {
                  uuid: response.data.data.uuid,
                  orderFrom: "order"
                }
              })
            }
            this.$store.dispatch("order/setOrderProducts", [])
            this.$store.dispatch("order/setDisplayPo", this)
            this.$store.dispatch("order/setSelectCustomer", null)
            this.emit("display_sc", {
              customer: null
            })
            this.$isLoading(false)
          })
          .catch((error) => {
            this.getResponseError(error)
            this.$isLoading(false)
          })
      }
      this.closeSidebarOrderProductSm()
    },

    checkHasOrderOffline (route_name) {
      if (this.$route.name !== route_name) {
        this.$router.push({ name: route_name })
      }
    },

    checkHasOrder (route_name) {
      if (this.$route.name !== route_name) {
        if (this.orderProducts.length) {
          this.$swal({
            html: `<p class="text-bold font-s-18">${this.$t("string.confirmParkBeforeLeave")}</p>`,
            type: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("string.yesIAmSure"),
            cancelButtonText: this.swalNoButton
          }).then(() => {
            this.storeAndCreateNewOrder(route_name, "saved")
          }, (dismiss) => {
            if (!(dismiss === "cancel")) {
              throw dismiss
            } else {
              this.$store.dispatch("order/setOrderDiscount", 0)
              this.emit("display_dis", {
                discount: 0
              })
              this.$store.dispatch("order/setOrderDiscountStatus", true)
              this.emit("display_dis_percent", {
                display_dis_percent: true
              })
              this.$store.dispatch("order/setDiscountAuthorizer", null)
              this.$store.dispatch("order/setSelectCustomer", null)
              this.emit("display_sc", {
                customer: null
              })
              this.$store.dispatch("order/setOrderProducts", [])
              this.$store.dispatch("order/setDisplayPo", this)
              this.$router.push({ name: route_name })
            }
          }).catch(function (err) {
            throw err
          })
        } else {
          this.$router.push({ name: route_name })
        }
      }
    },
    storeAndCreateNewOrder (route_name, type) {
      this.$isLoading(true)
      localStorage.setItem("display_order_mode", "show_welcome")
      this.emit("display_order_mode", {
        display_order_mode: "show_welcome"
      })
      const formData = {
        shop_uuid: this.$config.shop_uuid,
        uuid: this.$route.params.uuid,
        type: type,
        products: this.orderProducts,
        customer_uuid: this.selectedCustomer ? this.selectedCustomer.uuid : null,
        discount: this.order_discount,
        is_percentage: this.dis_as_percentage,
        authorizedBy: this.authorizer
      }
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/v2/order/store-and-create-new-order", formData)
        .then((res) => {
          this.order = res.data.data
          this.$store.dispatch("order/setOrderDiscount", 0)
          this.emit("display_dis", {
            discount: 0
          })
          this.$store.dispatch("order/setOrderDiscountStatus", true)
          this.emit("display_dis_percent", {
            display_dis_percent: true
          })
          this.$store.dispatch("order/setDiscountAuthorizer", null)
          this.$store.dispatch("order/setSelectCustomer", null)
          this.emit("display_sc", {
            customer: null
          })
          this.$store.dispatch("order/setDisplayPo", {
            products: []
          })
          this.$store.dispatch("order/setDisplayPo", [])
          this.$router.push({ name: route_name })
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
          this.closeSidebarOrderProductSm()
        })
    }
  }
}
</script>

<style scoped>

</style>
