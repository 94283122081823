<template>
  <div class="row">
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("restaurant.history.label.detail") }}
          </h3>
          <div class="box-tools hidden-xs">
            <router-link
              :to="{name: 'restaurant-info'}"
              class="btn btn-default btn-sm"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </router-link>
          </div>
        </div>
        <div
          class="box-body"
          v-if="shop"
        >
          <table class="table table-striped table-bordered">
            <tbody>
              <tr>
                <td><label>{{ $t("restaurant.label.logo") }}</label></td>
                <td>
                  <div style="width: 150px">
                    <img
                      alt="Logo"
                      class="thumbnail w-100"
                      :src="`${$config.base_url}/${logo}`"
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td><label>{{ $t("fields.label.name") }}</label></td>
                <td><label>{{ shop.name }}</label></td>
              </tr>
              <tr>
                <td><label>{{ $t("fields.label.currency") }}</label></td>
                <td>
                  <label>{{ shop.currency ? shop.currency.code : $t("string.na") }}</label>
                </td>
              </tr>
              <tr>
                <td><label>{{ $t("fields.label.address") }}</label></td>
                <td><label>{{ shop.address || $t("string.na") }}</label></td>
              </tr>
              <tr>
                <td><label>{{ $t("fields.label.tel") }}</label></td>
                <td><label>{{ shop.tel || $t("string.na") }}</label></td>
              </tr>
              <tr>
                <td><label>{{ $t("fields.label.fax") }}</label></td>
                <td><label>{{ shop.fax || $t("string.na") }}</label></td>
              </tr>
              <tr>
                <td><label>{{ $t("fields.label.url") }}</label></td>
                <td><label>{{ shop.website || $t("string.na") }}</label></td>
              </tr>
              <tr>
                <td><label>{{ $t("fields.label.description") }}</label></td>
                <td><label>{{ shop.description || $t("string.na") }}</label></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowRestaurantHistory",
  metaInfo () {
    return {
      title: this.$t("restaurant.history.label.detail"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      shop: {},
      config: this.$config,
      logo: null
    }
  },
  methods: {
    getHistory () {
      this.$axios.post(this.$config.base_url + "/api/restaurant-history/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then(({ data }) => {
        if (data.data) {
          const history = data.data
          this.shop = JSON.parse(history.info)
          this.logo = history.shop && history.shop.logo ? history.shop.logo : ""
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    }
  },
  mounted () {
    this.getHistory()
  }
}
</script>

<style scoped>
.table > tbody > tr > td {
  border-top: 0;
}
</style>
