<template>
  <ProductTagForm :title="$t('label.product.tag.newTag')" />
</template>

<script>
import ProductTagForm from "./Form"

export default {
  components: { ProductTagForm },
  name: "CreateProductTag",
  metaInfo () {
    return {
      title: this.$t("label.product.tag.newTag"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
