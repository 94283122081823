<template>
  <div class="row">
    <div class="col-md-12">
      <div class="profile-panel">
        <div class="profile-panel-header">
          <h3>{{ $t("profile.sidebar.tasks") }}</h3>
        </div>

        <div class="profile-panel-body">
          <p>{{ $t("profile.sidebar.tasks") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tasks",
  metaInfo () {
    return {
      title: this.$t("profile.sidebar.tasks"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config
    }
  }
}
</script>

<style scoped>
.wrapper-profile {
  background-color: #ffffff;
  padding: 15px;
}
</style>
