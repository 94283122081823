<template>
  <div
    class="app-bottom-bar"
    v-if="updateExists"
  >
    <div class="app-bottom-bar-label">
      <p>🚀 New version available!</p>
    </div>
    <div
      class="app-bottom-bar-button"
      @click="refreshApp"
    >
      <button>Refresh</button>
    </div>
    <div
      class="app-bottom-bar-times"
      @click="closeAppBottomBar"
    >
      <i class="fas fa-times" />
    </div>
  </div>
</template>

<script>
import swUpdates from "@/mixins/swUpdates"

export default {
  name: "AppBottomBar",
  mixins: [swUpdates]
}
</script>

<style scoped>

.app-bottom-bar {
  position: fixed;
  right: 40px;
  bottom: 50px;
  display: flex;
  background: #000;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 8px 15px;
  font-weight: 900;
  color: #ffffff;
}

.app-bottom-bar-button button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  text-transform: uppercase;
  color: #0cc80c;
  font-weight: bold;
}

.app-bottom-bar-label p {
  padding: 0;
  margin: 0;
}

.app-bottom-bar-times {
  font-size: 16px;
}
</style>
