<template>
  <div
    :class="{ 'showed': showSelectCustomer || showCustomerForm }"
    class="slide-select-customer"
  >
    <!--Form use on page customer (no offline mode)-->
    <template v-if="showCustomerForm">
      <div class="position-relative select-customer-content overflow-y-auto scrollbar">
        <div class="form-group d-flex align-items-center justify-content-between user-select-none">
          <label class="title-section no-margin">{{ titleName }}</label>
          <button
            class="btn btn-danger btn-lg no-margin"
            @click="closingDrawer"
          >
            <i class="fas fa-times" />
          </button>
        </div>
        <div class="row no-margin">
          <div class="col-xs-12 no-padding">
            <div
              :class="{'has-error': validation && validation.hasOwnProperty('name')}"
              class="form-group"
            >
              <label class="font-s-16 required user-select-none">{{ $t("label.customerName") }}</label>
              <input
                v-model="customer.name"
                :placeholder="$t('placeholder.enterCustomerName')"
                class="pos-input pos-input-lg"
                type="text"
              >
              <template v-if="validation && validation.hasOwnProperty('name')">
                <span>{{ validation.name[0] }}</span>
              </template>
            </div>
          </div>
          <div class="col-xs-12 no-padding">
            <div
              :class="{'has-error': validation && validation.hasOwnProperty('gender_id')}"
              class="form-group"
            >
              <label class="font-s-16 required user-select-none">{{ $t("label.gender") }}</label>
              <select
                v-model="customer.gender_id"
                class="pos-input pos-input-lg"
              >
                <option :value="null">
                  {{ $t("label.selectCustomerGender") }}
                </option>
                <template v-if="genders.length > 0">
                  <option
                    v-for="(item, each_gender_key) in genders"
                    :key="each_gender_key"
                    :value="item.id"
                  >
                    {{ item["name_" + $i18n.locale] }}
                  </option>
                </template>
              </select>
              <template v-if="validation && validation.hasOwnProperty('gender_id')">
                <span>{{ validation.gender_id[0] }}</span>
              </template>
            </div>
          </div>
          <div class="col-xs-12 no-padding">
            <div
              :class="{'has-error': validation && validation.hasOwnProperty('tel')}"
              class="form-group"
            >
              <label class="font-s-16 required user-select-none">{{ $t("label.telephone") }}</label>
              <input
                v-model="customer.tel"
                :placeholder="$t('placeholder.enterTelephone')"
                class="pos-input pos-input-lg"
                type="text"
              >
              <template v-if="validation && validation.hasOwnProperty('tel')">
                <span>{{ validation.tel[0] }}</span>
              </template>
            </div>
          </div>
          <div class="col-xs-12 no-padding">
            <div class="form-group">
              <label class="font-s-16 user-select-none">{{ $t("label.address") }}</label>
              <input
                v-model="customer.address"
                :placeholder="$t('placeholder.enterAddress')"
                class="pos-input pos-input-lg"
                type="text"
              >
            </div>
          </div>
          <template v-if="hideGroupAndMember">
            <div
              v-if="customerGroupsList.length"
              class="col-xs-12 no-padding"
            >
              <div class="form-group">
                <label class="font-s-16 user-select-none">{{ $t("fields.label.customerGroup") }}</label>
                <select
                  v-model="selectedCustomerGroup"
                  class="pos-input pos-input-lg"
                >
                  <option :value="null">
                    {{ $t("selectCustomerGroup") }}
                  </option>
                  <option
                    v-for="(item, each_customer_groups_list_key) in customerGroupsList"
                    :value="item.id"
                    :key="each_customer_groups_list_key"
                  >
                    {{ item["name_" + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-if="customerMembershipList.length"
              class="col-xs-12 no-padding"
            >
              <div class="form-group">
                <label class="font-s-16 user-select-none">{{ $t("fields.label.membership") }}</label>
                <select
                  v-model="selectedCustomerMembership"
                  class="pos-input pos-input-lg"
                >
                  <option :value="null">
                    {{ $t("selectMembership") }}
                  </option>
                  <option
                    v-for="(item, each_customer_membership_list_key) in customerMembershipList"
                    :key="each_customer_membership_list_key"
                    :value="item.id"
                  >
                    {{ item["name_" + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>
          </template>
          <div class="col-xs-12 no-padding">
            <div class="form-group">
              <button
                :class="colorButton"
                class="btn btn-block btn-lg"
                @click="storeCustomer"
              >
                <i class="fas fa-save mr-2" />
                {{ labelButton }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--Form use on page order or page payment (have offline mode)-->
    <template v-else>
      <template v-if="showFormCreateCustomer">
        <div class="position-relative select-customer-content overflow-y-auto scrollbar">
          <div class="form-group d-flex align-items-center justify-content-between user-select-none">
            <label class="title-section no-margin">{{ $t("label.newCustomer") }}</label>
            <button
              class="btn btn-lg btn-danger no-margin"
              @click="showFormCreateCustomer = false"
            >
              <i class="fas fa-times" />
            </button>
          </div>
          <div class="row no-margin">
            <div class="col-xs-12 no-padding">
              <div
                :class="{'has-error': validation && validation.hasOwnProperty('name')}"
                class="form-group"
              >
                <label class="font-s-16 required user-select-none">{{ $t("label.customerName") }}</label>
                <input
                  v-model="customer.name"
                  :placeholder="$t('placeholder.enterCustomerName')"
                  class="pos-input pos-input-lg"
                  type="text"
                >
                <template v-if=" validation && validation.hasOwnProperty('name')">
                  <span>{{ validation.name[0] }}</span>
                </template>
              </div>
            </div>
            <template v-if="isOffline">
              <div class="col-xs-12 no-padding">
                <div
                  :class="{'has-error': validation && validation.hasOwnProperty('gender_id')}"
                  class="form-group"
                >
                  <label class="font-s-16 required user-select-none">{{ $t("label.gender") }}</label>
                  <select
                    v-model="customer.gender"
                    class="pos-input pos-input-lg"
                  >
                    <option :value="null">
                      {{ $t("label.selectCustomerGender") }}
                    </option>
                    <template v-if="genders.length > 0">
                      <option
                        v-for="(item, each_gender_key) in genders"
                        :key="each_gender_key"
                        :value="item"
                      >
                        {{ item["name_" + $i18n.locale] }}
                      </option>
                    </template>
                  </select>
                  <template v-if=" validation && validation.hasOwnProperty('gender_id')">
                    <span>{{ validation.gender_id[0] }}</span>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-xs-12 no-padding">
                <div
                  :class="{'has-error': validation && validation.hasOwnProperty('gender_id')}"
                  class="form-group"
                >
                  <label class="font-s-16 required user-select-none">{{ $t("label.gender") }}</label>
                  <select
                    v-model="customer.gender_id"
                    class="pos-input pos-input-lg"
                  >
                    <option :value="null">
                      {{ $t("label.selectCustomerGender") }}
                    </option>
                    <template v-if="genders.length > 0">
                      <option
                        v-for="(item, each_gender_key) in genders"
                        :key="each_gender_key"
                        :value="item.id"
                      >
                        {{ item["name_" + $i18n.locale] }}
                      </option>
                    </template>
                  </select>
                  <template v-if=" validation && validation.hasOwnProperty('gender_id')">
                    <span>{{ validation.gender_id[0] }}</span>
                  </template>
                </div>
              </div>
            </template>
            <div class="col-xs-12 no-padding">
              <div
                :class="{'has-error': validation && validation.hasOwnProperty('tel')}"
                class="form-group"
              >
                <label class="font-s-16 required user-select-none">{{ $t("label.telephone") }}</label>
                <input
                  v-model="customer.tel"
                  :placeholder="$t('placeholder.enterTelephone')"
                  class="pos-input pos-input-lg"
                  type="text"
                >
                <template v-if="validation && validation.hasOwnProperty('tel')">
                  <span>{{ validation.tel[0] }}</span>
                </template>
              </div>
            </div>
            <div class="col-xs-12 no-padding">
              <div class="form-group">
                <label class="font-s-16 user-select-none">{{ $t("label.address") }}</label>
                <input
                  v-model="customer.address"
                  :placeholder="$t('placeholder.enterAddress')"
                  class="pos-input pos-input-lg"
                  type="text"
                >
              </div>
            </div>
            <div class="col-xs-12 no-padding">
              <div class="form-group">
                <template v-if="isOffline">
                  <button
                    class="btn btn-block btn-lg btn-primary"
                    @click="addOfflineNewCustomer"
                  >
                    <i class="fas fa-save mr-2" />
                    {{ $t("button.saveCustomer") }}
                  </button>
                </template>
                <template v-else>
                  <button
                    class="btn btn-block btn-lg btn-primary"
                    @click="storeCustomer"
                  >
                    <i class="fas fa-save mr-2" />
                    {{ $t("button.saveCustomer") }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="position-relative select-customer-content d-flex flex-column">
          <div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <label class="title-section no-margin">{{ $t("button.select_customer") }}</label>
              <button
                class="btn btn-lg btn-danger no-margin"
                @click="closingDrawer"
              >
                <i class="fas fa-times" />
              </button>
            </div>
            <div>
              <div class="col-xs-9 col-lg-7 pl-0 margin-bottom input-search-customer">
                <div class="box-search">
                  <div class="box-search-icon margin-l-5">
                    <i class="fas fa-search" />
                  </div>
                  <input
                    v-model="searchCustomer"
                    :placeholder="$t('label.searchCustomer')"
                    class="pos-input-search pos-input-lg no-border"
                    type="text"
                    @keyup="getSuggestionCustomer"
                  >
                </div>
              </div>
              <div class="col-xs-3 col-lg-5 px-0">
                <div class="form-group">
                  <button
                    class="btn btn-block btn-lg btn-success"
                    @click="createNewCustomer"
                  >
                    <i class="fas fa-plus" />
                    <span class="hide-label-sm ml-2">{{ $t("label.addCustomer") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="suggestionCustomer && Array.isArray(suggestionCustomer) && suggestionCustomer.length > 0"
            :class="classListCustomer"
            class="list-sugg-customer overflow-y-auto scrollbar user-select-none flex-1"
          >
            <template v-for="(item, key) in suggestionCustomer">
              <div
                :key="key"
                class="col-xs-12 no-padding"
              >
                <a @click="selectCustomer(item)">
                  <div class="sugg-customer-each">
                    <div>
                      <span class="sugg-cus-name mr-2">{{ item.name }}</span>
                      <span>( {{ item.gender["name_" + $i18n.locale] }} )</span>
                    </div>
                    <div class="sugg-cus-contact">
                      <template
                        v-if="item.customer_membership && Array.isArray(item.customer_membership) && item.customer_membership.length"
                      >
                        <template v-for="(member, member_key) in item.customer_membership">
                          <span
                            v-if="checkMembershipExpired(member)"
                            :key="member_key"
                          >
                            <strong>
                              {{ member["name_" + $i18n.locale] }}( {{ member["amount_percentage"] }}%)
                            </strong>
                          </span>
                        </template>
                      </template>
                      <span v-if="item.tel">{{ item.tel }}</span>
                      <span v-if="item.address">{{ item.address }}</span>
                    </div>
                  </div>
                </a>
              </div>
            </template>
          </div>
          <div
            v-else
            :class="classListCustomer"
            class="list-sugg-customer overflow-y-auto user-select-none flex-1"
          >
            <div class="col-xs-12 no-padding">
              <div class="sugg-no-data text-center">
                {{ $t("string.noDataCustomers") }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { debounce } from "debounce"
import { VueOfflineMixin } from "vue-offline"
import { offlineMixins } from "@/mixins/offline"
import indexedb from "@/indexedb"
import { mapState } from "vuex"

export default {
  name: "DrawerCustomer",
  props: {
    showSelectCustomer: {
      type: Boolean,
      default: false
    },
    classListCustomer: {
      type: String,
      default: ""
    },
    // eslint-disable-next-line vue/prop-name-casing
    shop_id: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    },
    showCustomerForm: {
      type: Boolean,
      default: false
    },
    oldData: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  mixins: [VueOfflineMixin, offlineMixins],
  data () {
    return {
      showFormCreateCustomer: false,
      customer: {
        uuid: null,
        name: null,
        gender_id: null,
        gender: null,
        tel: null,
        address: null
      },
      validation: null,
      suggestionCustomer: [],
      searchCustomer: null,

      // for customer group
      selectedCustomerGroup: null,

      // for customer membership
      selectedCustomerMembership: null,
      membership_start_date: null,
      membership_end_date: null,

      number_per_page: 25,
      offlineCustomerRelation: {
        group_ids: null,
        membership_ids: null,
        start_date: null,
        end_date: null
      }
    }
  },
  watch: {
    showSelectCustomer: function () {
      if (this.isOffline) {
        indexedb.all("customers")
          .then((res) => {
            this.suggestionCustomer = res
          })
      }
    }
  },
  computed: {
    ...mapState({
      customerGroupsList: state => state.customer.listGroups,
      customerMembershipList: state => state.customer.listMemberships,
      genders: state => state.customer.listGenders,
      dateFormat: state => state.sell.display_date_format
    }),
    titleName () {
      return this.oldData ? this.$t("label.editCustomer") : this.$t("label.newCustomer")
    },

    labelButton () {
      return this.oldData ? this.$t("button.saveCustomer") : this.$t("button.createCustomer")
    },

    colorButton () {
      return this.oldData ? "btn-primary" : "btn-success"
    },

    hideGroupAndMember () {
      const routes = [
        "order-show",
        "payment-show"
      ]
      return !routes.includes(this.$route.name)
    }
  },
  mounted () {
    if (this.isOffline) {
      indexedb.all("customers")
        .then((res) => {
          this.suggestionCustomer = res
        })
      indexedb.all("genders")
        .then((genders) => {
          this.$store.dispatch("customer/setListGenders", genders)
        })
      indexedb.all("customer_groups")
        .then((groups) => {
          this.$store.dispatch("customer/setListGroups", groups)
        })
      indexedb.all("customer_memberships")
        .then((membership) => {
          this.$store.dispatch("customer/setListMemberships", membership)
        })
    } else {
      if (this.oldData) {
        this.customer = this.oldData
        if (this.oldData.customer_group && this.oldData.customer_group.length) {
          this.selectedCustomerGroup = this.oldData.customer_group[0].id
        }
        if (this.oldData.customer_membership && this.oldData.customer_membership.length) {
          this.selectedCustomerMembership = this.oldData.customer_membership[0].id
          this.membership_start_date = this.oldData.customer_membership[0].pivot.start_date
          this.membership_end_date = this.oldData.customer_membership[0].pivot.expire_date
        }
      }
      this.getSuggestionCustomer()
    }
  },
  methods: {
    getSuggestionCustomer: debounce(function ($event) {
      this.$axios.post(this.$config.base_url + "/api/customer/suggestions", {
        shop_uuid: this.$config.shop_uuid,
        search_customer: this.searchCustomer,
        number_per_page: this.number_per_page
      }).then((response) => {
        this.suggestionCustomer = response.data.data
      }).catch((error) => {
        this.$toastr("error", error.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
      })
    }, 500),
    clearDataCustomer () {
      this.customer = {
        uuid: null,
        name: null,
        gender_id: null,
        gender: null,
        tel: null,
        address: null
      }
    },
    createNewCustomer () {
      this.clearDataCustomer()
      this.showFormCreateCustomer = true
    },
    storeCustomer () {
      this.validation = null
      let start_date = null
      let end_date = null
      if (this.membership_start_date) {
        start_date = this.$moment(this.membership_start_date).locale("en").format("YYYY-MM-DD")
      }
      if (this.membership_end_date) {
        end_date = this.$moment(this.membership_end_date).locale("en").format("YYYY-MM-DD")
      }
      this.$axios.post(this.$config.base_url + "/api/v2/customer/store", {
        shop_id: this.get_shop.id,
        uuid: this.customer.uuid,
        name: this.customer.name,
        gender_id: this.customer.gender_id,
        tel: this.customer.tel,
        address: this.customer.address,
        customer_group_ids: this.selectedCustomerGroup ? [this.selectedCustomerGroup] : [],
        customer_membership_ids: this.selectedCustomerMembership ? [this.selectedCustomerMembership] : [],
        membership_start_date: start_date,
        membership_end_date: end_date
      }).then((res) => {
        this.getSuggestionCustomer()
        if (!(this.showCustomerForm)) {
          this.$store.dispatch("order/setSelectCustomer", res.data.data)
          this.emit("display_sc", {
            customer: res.data.data
          })
        }
        this.$emit("refreshData")
        this.closingDrawer()
        this.showFormCreateCustomer = false
        this.closeCustomerDetailSm()
      }).catch((err) => {
        if (err.response.status === 422) {
          this.validation = err.response.data.errors
        } else {
          this.$toastr("error", err.response.data.message["message_" + this.$i18n.locale], this.$t("string.warning"))
        }
      })
    },
    selectCustomer (customer) {
      if (this.isOffline) {
        this.customer = customer
        this.offlineCustomerRelation.group_ids = customer.customer_group_ids
        this.offlineCustomerRelation.membership_ids = customer.customer_membership_ids
        this.offlineCustomerRelation.start_date = customer.membership_end_date
        this.offlineCustomerRelation.end_date = customer.membership_start_date
        this.$emit("getChildData")
        this.closingDrawer()
      } else {
        this.$store.dispatch("order/setSelectCustomer", customer)
        this.emit("display_sc", {
          customer: customer
        })
        this.closingDrawer()
      }
    },
    addOfflineNewCustomer () {
      this.$isLoading(true)
      this.validation = {}
      if (this.customer && (!this.customer.name || !this.customer.gender)) {
        if (!this.customer.name) {
          this.validation.name = ["This field is required."]
        }
        if (!this.customer.gender) {
          this.validation.gender_id = ["This field is required."]
        }
        this.$isLoading(false)
        return
      } else {
        const random_uuid = this.random_uuidv4()
        const start_date = this.membership_start_date ? this.$moment(this.membership_start_date).locale("en").format("YYYY-MM-DD") : null
        const end_date = this.membership_end_date ? this.$moment(this.membership_end_date).locale("en").format("YYYY-MM-DD") : null
        const group_ids = this.selectedCustomerGroup ? [this.selectedCustomerGroup] : null
        const membership_ids = this.selectedCustomerMembership ? [this.selectedCustomerMembership] : null
        this.offlineCustomerRelation.group_ids = group_ids
        this.offlineCustomerRelation.membership_ids = membership_ids
        this.offlineCustomerRelation.start_date = start_date
        this.offlineCustomerRelation.end_date = end_date

        const data = {
          address: this.customer.address,
          created_at: new Date(),
          deleted_at: null,
          gender_id: this.customer.gender.id,
          gender: this.customer.gender,
          name: this.customer.name,
          shop_id: this.shop_id,
          tel: this.customer.tel,
          updated_at: new Date(),
          uuid: random_uuid,
          customer_group_ids: group_ids,
          customer_membership_ids: membership_ids,
          membership_start_date: start_date,
          membership_end_date: end_date
        }

        indexedb.save("customers", {
          uuid: random_uuid,
          ...data
        }).then(() => {
          indexedb.find("customers", random_uuid)
            .then((res) => {
              this.selectCustomer(res)
            })
        })
      }

      this.$emit("getChildData")
      this.showFormCreateCustomer = false
      this.closingDrawer()
      this.$isLoading(false)
    },
    closingDrawer () {
      this.showFormCreateCustomer = false
      if (!this.oldData) {
        this.closeCustomerDetailSm()
      }
      this.$emit("closeDrawer")
      this.validation = null
      this.clearDataCustomer()
    }
  }
}
</script>

<style scoped>
.input-search-customer {
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .input-search-customer {
    padding-right: 15px;
    margin-bottom: 5px;
  }

  .sugg-customer-each {
    margin-bottom: 8px;
    padding: 8px;
  }

  .col-xs-12:last-child a .sugg-customer-each {
    margin-bottom: 0;
  }
}
</style>
