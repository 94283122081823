<template>
  <div class="row">
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("user.newUser") }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <template v-if="!onLoading">
            <template v-if="allowed">
              <div
                :class="{'has-error': validations && validations.hasOwnProperty('first_name')}"
                class="form-group row"
              >
                <label class="required col-md-3">{{ $t("label.firstName") }}</label>
                <div class="col-md-9">
                  <input
                    v-model="user.first_name"
                    :placeholder="$t('label.firstName')"
                    autofocus
                    class="form-control"
                    type="text"
                  >
                  <small
                    v-if="validations && validations.hasOwnProperty('first_name')"
                    class="help-block"
                  >
                    {{ validations["first_name"][0] }}
                  </small>
                </div>
              </div>
              <div
                :class="{'has-error': validations && validations.hasOwnProperty('last_name')}"
                class="form-group row"
              >
                <label class="required col-md-3">{{ $t("label.lastName") }}</label>
                <div class="col-md-9">
                  <input
                    v-model="user.last_name"
                    :placeholder="$t('label.lastName')"
                    autofocus
                    class="form-control"
                    type="text"
                  >
                  <small
                    v-if="validations && validations.hasOwnProperty('last_name')"
                    class="label-error"
                  >
                    {{ validations["last_name"][0] }}
                  </small>
                </div>
              </div>
              <div
                :class="{'has-error': validations && validations.hasOwnProperty('username')}"
                class="form-group row"
              >
                <div class="col-md-3">
                  <label class="required">{{ $t("fields.placeholder.username") }}</label>
                  <button
                    aria-controls="usernameTrip"
                    aria-expanded="true"
                    class="btn btn-default btn-xs ml-3"
                    data-target="#usernameTrip"
                    data-toggle="collapse"
                    type="button"
                  >
                    <i class="fas fa-question-circle" />
                  </button>
                </div>
                <div class="col-md-9">
                  <input
                    v-model="user.username"
                    :placeholder="$t('fields.placeholder.username')"
                    autofocus
                    class="form-control"
                    type="text"
                  >
                  <small
                    v-if="validations && validations.hasOwnProperty('username')"
                    class="label-error"
                  >
                    {{ validations["username"][0] }}
                  </small>
                  <div
                    id="usernameTrip"
                    class="collapse in margin-top"
                  >
                    <UsernameTrip />
                  </div>
                </div>
              </div>
              <div
                :class="{'has-error': validations && validations.hasOwnProperty('email')}"
                class="form-group row"
              >
                <label class="col-md-3">{{ $t("label.email") }}</label>
                <div class="col-md-9">
                  <input
                    v-model="user.email"
                    :placeholder="$t('label.email')"
                    autofocus
                    class="form-control"
                    type="email"
                  >
                  <small
                    v-if="validations && validations.hasOwnProperty('email')"
                    class="label-error"
                  >
                    {{ validations["email"][0] }}
                  </small>
                </div>
              </div>
              <div
                :class="{'has-error': validations && validations.hasOwnProperty('password')}"
                class="form-group row"
              >
                <label class="required col-md-3">{{ $t("label.password") }}</label>
                <div class="col-md-9">
                  <input
                    v-model="user.password"
                    :placeholder="$t('label.password')"
                    autofocus
                    class="form-control"
                    type="password"
                  >
                  <small
                    v-if="validations && validations.hasOwnProperty('password')"
                    class="label-error"
                  >
                    {{ validations["password"][0] }}
                  </small>
                </div>
              </div>
              <div
                :class="{'has-error': validations && validations.hasOwnProperty('password_confirmation')}"
                class="form-group row"
              >
                <label class="required col-md-3">{{ $t("label.passwordConfirmation") }}</label>
                <div class="col-md-9">
                  <input
                    v-model="user.password_confirmation"
                    :placeholder="$t('label.passwordConfirmation')"
                    autofocus
                    class="form-control"
                    type="password"
                  >
                  <small
                    v-if="validations && validations.hasOwnProperty('password_confirmation')"
                    class="label-error"
                  >
                    {{ validations["password_confirmation"][0] }}
                  </small>
                </div>
              </div>
              <div
                :class="{'has-error': validations && validations.hasOwnProperty('gender')}"
                class="form-group row"
              >
                <label class="required col-md-3">{{ $t("label.gender") }}</label>
                <div class="col-md-9">
                  <template v-for="(gender, key) in genders">
                    <label
                      class="margin-right"
                      :key="key"
                    >
                      <input
                        v-model="user.gender"
                        :value="gender"
                        name="gender"
                        type="radio"
                      >
                      <span class="ml-3"> {{ gender["name_" + $i18n.locale] }}</span>
                    </label>
                  </template>
                </div>
                <small
                  v-if="validations && validations.hasOwnProperty('gender')"
                  class="label-error"
                >
                  {{ validations["gender"][0] }}
                </small>
              </div>
              <div class="form-group row">
                <label class="col-md-3">{{ $t("label.status") }}</label>
                <div class="col-md-9">
                  <label>
                    <input
                      v-model="user.is_active"
                      type="checkbox"
                    >
                    <span class="ml-3">{{ $t("label.active") }}</span>
                  </label>
                </div>
              </div>
              <div
                :class="{'has-error': validations && validations.hasOwnProperty('roles')}"
                class="form-group row"
              >
                <label class="required col-md-3">{{ $t("label.role") }}</label>
                <div class="col-md-9">
                  <div class="row">
                    <template v-if="optionRoles && Array.isArray(optionRoles) && roles.length > 0">
                      <div
                        v-for="(role, key) in optionRoles"
                        class="col-md-12"
                        :key="key"
                      >
                        <label>
                          <input
                            v-model="user.roles"
                            :value="role"
                            type="checkbox"
                          >
                          <span class="ml-3">{{ role["display_name_" + $i18n.locale] }}</span>
                        </label>
                      </div>
                    </template>
                  </div>
                </div>
                <small
                  v-if="validations && validations.hasOwnProperty('roles')"
                  class="label-error"
                >
                  {{ validations["roles"][0] }}
                </small>
              </div>
            </template>
            <template v-else>
              <PlanMessage :message="message_allowed" />
            </template>
          </template>
        </div>
        <div
          v-if="allowed"
          class="box-footer text-right"
        >
          <button
            class="btn btn-primary margin-r-5"
            @click="store"
          >
            <i class="fas fa-check-circle" /> {{ $t("button.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanMessage from "@/components/PlanMessage"
import UsernameTrip from "@/views/settings/_components/UsernameTrip"

export default {
  name: "UserCreate",
  metaInfo () {
    return {
      title: this.$t("user.newUser"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    UsernameTrip,
    PlanMessage
  },
  data () {
    return {
      optionRoles: [],
      user: {
        id: null,
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        password: null,
        password_confirmation: null,
        is_active: true,
        confirmed: true,
        gender: null,
        roles: []
      },
      shop_uuid: null,
      genders: [],
      validations: null,
      allowed: true,
      message_allowed: null,
      onLoading: true
    }
  },
  beforeMount () {
    if (this.get_shop) {
      this.shop_uuid = this.get_shop.uuid
    }
    if (this.$bill_active === "true") {
      this.checkCreateUserLimited()
    }
  },
  mounted () {
    this.getRoles()
    this.getGenders()
  },
  methods: {
    checkCreateUserLimited () {
      this.onLoading = true
      this.$axios.post(this.$config.base_url + "/api/bill/check-create-user-limited", {
        suuid: this.shop_uuid
      }).then((res) => {
        this.allowed = res.data.data.allowed
        this.message_allowed = res.data.data.message
      }).finally(() => {
        this.onLoading = false
      })
    },
    getRoles () {
      this.$isLoading(true)
      this.$axios
        .post(this.$config.base_url + "/api/user/get-option-roles")
        .then(({ data }) => {
          if (data.data) {
            this.optionRoles = data.data
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getGenders () {
      this.$axios
        .get(this.$config.base_url + "/api/gender/get-options")
        .then((res) => {
          this.genders = res.data.data
          if (this.genders.length > 0) {
            this.user.gender = this.genders[0]
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
    },
    store () {
      this.validations = null
      this.$isLoading(true)
      this.user.suuid = this.shop_uuid
      this.$axios.post(this.$config.base_url + "/api/user/store", this.user)
        .then(() => {
          this.$router.push({ name: "list-user" })
          if (this.$route.name === "edit-user") {
            this.$anotify(this.$t("model.user"), "update")
          } else {
            this.$anotify(this.$t("model.user"))
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.getResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
