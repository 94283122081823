<template>
  <div class="row">
    <div :class="columns">
      <div
        :class="{'has-error' : message_validation !== null && message_validation.hasOwnProperty('name_en') }"
        class="form-group"
      >
        <label class="required">{{ $t("label.nameEn") }}</label>
        <input
          v-model="productType.name_en"
          :placeholder="$t('placeholder.enterNameAsEn')"
          class="form-control"
          type="text"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('name_en')">
          <span class="help-block">{{ message_validation["name_en"][0] }}</span>
        </template>
      </div>
      <div
        :class="{'has-error' : message_validation !== null && message_validation.hasOwnProperty('name_km') }"
        class="form-group"
      >
        <label class="required">{{ $t("label.nameKm") }}</label>
        <input
          v-model="productType.name_km"
          :placeholder="$t('placeholder.enterNameAsKm')"
          class="form-control"
          type="text"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('name_km')">
          <span class="help-block">{{ message_validation["name_km"][0] }}</span>
        </template>
      </div>
      <div class="form-group">
        <template v-if="this.$route.name !== 'show-product-type'">
          <button
            class="btn btn-primary btn-sm"
            @click="storeProductType"
          >
            <i class="fas fa-plus-circle" />
            <span v-if="this.$route.params.hasOwnProperty('id')"> {{ $t("button.update") }}</span>
            <span v-else> {{ $t("button.submit") }}</span>
          </button>
        </template>
        <BackButton />
      </div>
    </div>
  </div>
</template>

<script>

import BackButton from "@/components/BackButton"

export default {
  name: "ProductTypeForm",
  components: { BackButton },
  props: {
    columns: {
      type: String,
      default: "col-md-8 col-md-offset-2"
    }
  },
  data () {
    return {
      productType: {
        shop_id: null,
        name_en: "",
        name_km: ""
      },
      config: this.$config,
      message_validation: null
    }
  },
  methods: {
    getProductType () {
      this.$isLoading(true)
      this.$axios.defaults.headers.common.Accept = "application/json"
      this.$axios.defaults.headers.common.Authorization = localStorage.getItem("token")
      this.$axios.post(this.$config.base_url + "/api/product-type/show", {
        shop_id: this.get_shop.id,
        id: this.$route.params.id
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.productType = response.data.data
        }
      })
    },
    storeProductType () {
      this.$isLoading(true)
      this.productType.shop_id = this.get_shop.id
      this.$axios.defaults.headers.common.Accept = "application/json"
      this.$axios.defaults.headers.common.Authorization = localStorage.getItem("token")
      this.$axios.post(this.$config.base_url + "/api/product-type/store", this.productType)
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1) {
            this.$router.push({ name: "list-product-type" })
            if (this.productType.hasOwnProperty("id") && this.productType.id !== null) {
              this.$toastr("success", this.$t("string.theProductTypeWasUpdatedSuccessfully"), this.$t("string.success"))
            } else {
              this.$toastr("success", this.$t("string.theProductTypeWasCreatedSuccessfully"), this.$t("string.success"))
            }
          } else {
            this.message_validation = response.data.message
            this.$toastr("error", this.$t("string.canNotCreateOrUpdateProductType"), this.$t("string.error"))
          }
        })
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty("id")) {
      this.getProductType()
    }
    if (this.$route.name === "show-product-type") {
      $("input").attr("disabled", true)
    }
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}
</style>
