<template>
  <div class="col-md-12">
    <div class="row">
      <template v-if="!is_expired">
        <div class="col-md-12">
          <h3>
            {{ $t("offline.string.welcome") }}
            <span
              class="fa-stack fa-lg"
              style="font-size: 16px;"
            >
              <i class="fas fa-wifi fa-stack-1x" />
              <i class="fas fa-ban fa-stack-2x text-danger" />
            </span>
          </h3>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-6 col-xs-12 col-md-4 padding-10">
              <router-link :to="{name: 'offline-sale'}">
                <div class="restau-box shadow">
                  <div class="col-md-8">
                    <label class="restau-box-label user-select-none">{{ $t("offline.string.order") }}</label>
                  </div>
                  <div class="col-md-4 restau-box-icon-container">
                    <img
                      alt="Order Image"
                      class="restau-box-icon"
                      src="./../../assets/icon/product.svg"
                    >
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-sm-6 col-xs-12 col-md-4 padding-10">
              <router-link :to="{name: 'offline-cashier-order-list'}">
                <div class="restau-box shadow">
                  <div class="col-md-8">
                    <label class="restau-box-label user-select-none">{{ $t("offline.string.cashier") }}</label>
                  </div>
                  <div class="col-md-4 restau-box-icon-container">
                    <img
                      alt="Cashier Image"
                      class="restau-box-icon"
                      src="./../../assets/icon/Cashier.svg"
                    >
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "OfflineDashboard",
  metaInfo () {
    return {
      title: this.$t("string.dashboard"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  computed: {
    ...mapGetters({
      is_expired: "shop/is_expired"
    })
  }
}
</script>

<style scoped>
@import "./../../assets/css/dashboard.css";
</style>
