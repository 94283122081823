<template>
  <div class="product-list-each-item rounded">
    <div
      class="w-100 d-flex align-items-center position-relative bg-white"
      @click="checkProductHavVariant"
    >
      <template v-if="checkVariant">
        <div class="product-has-variant">
          <i class="fas fa-plus-square icon" />
        </div>
      </template>
      <div class="product-list-each-item__photo user-select-none">
        <template v-if="product.hasOwnProperty('images') && product.images.length > 0">
          <div
            :style="`background-image: url('${$config.base_url}/${replaceImgUrl(product.images[0].src, 'thumbnail')}')`"
            class="product-list-each-item__image rounded-tl rounded-bl"
          />
        </template>
        <template v-else>
          <div
            :style="`background-image: url('${defaultImg}')`"
            class="product-list-each-item__image rounded-tl rounded-bl"
          />
        </template>
      </div>
      <div class="product-list-each-item__info">
        <p
          class="product-each-item__name text-ellipsis-2-line product-each-item__name_one h-auto font-s-16 margin-bottom-none w-100"
        >
          {{ product.name }}
        </p>
        <p
          v-if="product.barcode"
          class="font-s-16 margin-bottom-none"
        >
          <span class="user-select-none">{{ $t("string.barcode") }}:</span>
          {{ product.barcode }}
        </p>
        <p
          v-if="product.has_inventory"
          :class="{'text-red': parseFloat(product.initAmountInStock) <= 0}"
          class="font-s-16 user-select-none margin-bottom-none"
        >
          {{ $t("label.amountInStock") }}:
          {{ product.initAmountInStock | numFormat(isFloat(parseFloat(product.initAmountInStock))) }}
          {{ product.unit ? product.unit["name_" + $i18n.locale] : null }}
        </p>
        <p
          v-else
          class="font-s-16 margin-bottom-none"
        >
          {{ $t("label.amountInStock") }}: {{ $t("label.unlimited") }}
        </p>
        <template v-if="product.sale_price">
          <div class="text-bold font-s-16 user-select-none">
            <label class="minus no-margin text-ellipsis">
              <span v-if="showChildrenSalePrice.min">
                {{ product.currency ? product.currency.symbol : "$" }}
                <template v-if="product.currency && product.currency.code === 'KHR'">
                  {{ showChildrenSalePrice.min | numFormat("0,0") }}
                </template>
                <template v-else>
                  {{ showChildrenSalePrice.min | numFormat(num_format) }}
                </template>
              </span>
              <span v-if="showChildrenSalePrice.max">
                {{ product.currency ? product.currency.symbol : "$" }}
                <template v-if="product.currency && product.currency.code === 'KHR'">
                  {{ showChildrenSalePrice.max | numFormat("0,0") }}
                </template>
                <template v-else>
                  {{ showChildrenSalePrice.max | numFormat(num_format) }}
                </template>
              </span>
            </label>
          </div>
          <div class="font-s-13">
            <template v-if="product.has_promotion">
              <del class="badge badge-danger badge-del-price">
                <em class="minus no-margin">
                  <span v-if="showChildrenOriginPrice.min">
                    {{ product.currency ? product.currency.symbol : "$" }}
                    {{ showChildrenOriginPrice.min | numFormat(num_format) }}
                  </span>
                  <span v-if="showChildrenOriginPrice.max">
                    {{ product.currency ? product.currency.symbol : "$" }}
                    {{ showChildrenOriginPrice.max | numFormat(num_format) }}
                  </span>
                </em>
              </del>
            </template>
          </div>
        </template>
      </div>
    </div>

    <OfflineProductVariant
      ref="offlineProductVariantModal"
      :check-variant="checkVariant"
      :offline-order-products="offlineOrderProducts"
      :product="product"
      modal-id="grid"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import OfflineProductVariant from "@/views/offline/order/includes/OfflineProductVariant"

export default {
  name: "OfflineProductList",
  components: { OfflineProductVariant },
  props: {
    product: {
      type: Object,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    addClassNames: {
      type: String
    },
    offlineOrderProducts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat
    }),
    checkStock () {
      if (this.product && this.product.has_inventory) {
        if (this.product.amount_in_stock <= 0) {
          return "danger"
        } else if (this.product.amount_in_stock < parseFloat(this.product.stock_alert_level) && this.product.amount_in_stock > 0) {
          return "warning"
        }
      }
      return "success"
    },
    checkVariant () {
      return !!(this.product && this.product.children && this.product.children.length)
    },
    showChildrenSalePrice () {
      if (this.product.children_sale_price && this.product.children_sale_price.length > 0) {
        const max = Math.max(...this.product.children_sale_price)
        const min = Math.min(...this.product.children_sale_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.sale_price,
            min: null
          }
        }
      }
      return {
        max: this.product.sale_price,
        min: null
      }
    },
    showChildrenOriginPrice () {
      if (this.product.children_origin_price && this.product.children_origin_price.length > 0) {
        const max = Math.max(...this.product.children_origin_price)
        const min = Math.min(...this.product.children_origin_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.origin_price,
            min: null
          }
        }
      }
      return {
        max: this.product.origin_price,
        min: null
      }
    }
  },
  data () {
    return {
      origin_url: process.env.VUE_APP_ORIGIN_URL
    }
  },
  methods: {
    addToCart () {
      this.$emit("onClick")
    },
    checkProductHavVariant () {
      if (this.checkVariant) {
        this.$refs.offlineProductVariantModal.toggleModal()
      } else {
        this.addToCart()
      }
    }
  }
}
</script>

<style scoped>
.btn-select-customer {
  padding: 14px 16px 14px 8px;
}

.btn-discount-order {
  padding: 14px 8px 14px 16px;
  border-right: 1px solid #f3f2f2;
}

.fa-discount {
  font-size: 10px !important;
  line-height: 1.8rem !important;
}

.fa-discount .fa-stack-2x {
  font-size: 20px !important;
}

.fa-discount .fa-stack-1x {
  font-size: 8px !important;
  transform: rotate(135deg);
}

.has-greater-than-sign:before {
  content: none;
  font-family: "FontAwesome", serif;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: -6px;
  color: #878787;
}

.block-btn-pay {
  padding: 14px 16px !important;
}

.product-list-each-item {
  width: calc(50% - 16px);
  box-shadow: 0 0 10px #0000001a;
  margin: 8px;
  cursor: pointer;
}

.product-list-each-item:hover {
  background-color: #f4f4f4b5;
  box-shadow: 0 0 10px #30303030;
}

.product-list-each-item__photo {
  width: 130px;
}

.product-list-each-item__info {
  padding: 8px 15px;
  width: calc(100% - 130px);
}

.product-each-item__name_one {
  -webkit-line-clamp: 1 !important;
}

.badge-del-price {
  font-size: 11px !important;
  border-radius: 4px !important;
}

@media screen and  (max-width: 1200px) {
  .product-list-each-item {
    width: 100%;
    margin: 8px 16px;
  }

  .btn-select-customer {
    padding: 4px 8px 8px;
  }

  .btn-discount-order {
    padding: 8px 8px 4px;
    border-right: unset;
  }
}
</style>
