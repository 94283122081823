import customerLayout from "@/views/customers/V2/Layout.vue"
import customerIndex from "@/views/customers/V2/List"
import customerGroupRouters from "@/routers/customerGroupRouters"
import customerMembershipRouters from "@/routers/customerMembershipRouters"

export const customerRouters = {
  path: "/customer",
  component: customerLayout,
  redirect: "/customer",
  children: [
    {
      path: "",
      name: "customer-list-v2",
      component: customerIndex
    },
    customerGroupRouters,
    customerMembershipRouters
  ]
}
