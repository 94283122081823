<template>
  <div>
    <p
      class="text-muted text-center"
      v-if="locale === 'km'"
    >
      ដោយចុច "បន្ទាប់" អ្នកយល់ព្រមនឹង<a
        href="https://figos.app/legal/terms-and-conditions"
        target="_blank"
      >
        លក្ខខណ្ឌប្រើប្រាស់
      </a>របស់ Figos
      <br>
      ហើយបញ្ជាក់ថាអ្នកបានអាន និងទទួលស្គាល់<a
        href="https://figos.app/legal/privacy-policy"
        target="_blank"
      >
        គោលការណ៍ឯកជនភាព
      </a>របស់ Figos
    </p>
    <p
      class="text-muted text-center"
      v-else
    >
      By clicking "Next" you agree to Figos's
      <a
        href="https://figos.app/legal/terms-and-conditions"
        target="_blank"
      >
        terms of use
      </a>
      <br>
      and confirm that you've read and acknowledged Figos's
      <a
        href="https://figos.app/legal/privacy-policy"
        target="_blank"
      >
        privacy policy
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "RegistrationAgreement",
  computed: {
    locale () {
      return this.$i18n.locale
    }
  }
}
</script>

<style scoped>

</style>
