<template>
  <div class="d-flex flex-column">
    <div class="non-footer-container flex-1">
      <NavbarV2 />
      <LeftSideBarMobileScreen />
      <BodyDefault />
    </div>
    <div>
      <DashboardFooter />
    </div>
  </div>
</template>

<script>
import NavbarV2 from "@/includes/NavbarV2"
import BodyDefault from "@/views/dashboards/Default"
import DashboardFooter from "@/includes/footer/Dashboard"
import LeftSideBarMobileScreen from "@/components/LeftSideBarMobileScreen"

export default {
  name: "Dashboard",
  components: {
    LeftSideBarMobileScreen,
    NavbarV2,
    DashboardFooter,
    BodyDefault
  },
  mounted () {
    localStorage.setItem("display_order_mode", "show_welcome")
    this.emit("display_order_mode", {
      display_order_mode: "show_welcome"
    })
  }
}
</script>

<style scoped>
.non-footer-container {
  min-height: calc(100vh - 36px);
}

@media screen and (max-width: 768px) {
  .non-footer-container {
    min-height: calc(100vh - 26px);
  }
}
</style>
