<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("user.listingUsers") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'create-user'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("user.button.newUser") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <table
              id="user-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("user.table.name") }}</th>
                  <th>{{ $t("fields.placeholder.username") }}</th>
                  <th>{{ $t("user.table.email") }}</th>
                  <th>{{ $t("user.table.roles") }}</th>
                  <th>{{ $t("label.status") }}</th>
                  <th>{{ $t("user.table.createdAt") }}</th>
                  <th>{{ $t("user.table.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <UserDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>
import UserDataTableSm from "@/views/settings/user/_components/UserDataTableSm"

export default {
  name: "UserList",
  metaInfo () {
    return {
      title: this.$t("user.listingUsers"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: { UserDataTableSm },
  data () {
    return {
      config: this.$config,
      oTable: null,
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    windowWidth: function () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    oTable: function () {
      this.reloadButtonAction()
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    deleteExchange (id) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/user/delete", {
          shop_id: self.get_shop.id,
          id: id
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.user"))
            self.oTable.draw(true)
          }
        })
      })
    },
    dataTable () {
      const self = this
      return $("#user-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: this.$config.base_url + "/api/user/data",
          type: "POST",
          data: {
            lang: this.$i18n.locale,
            shop_id: this.get_shop.id,
            labelEdit: self.labelEdit,
            labelDelete: self.labelDelete,
            labelShow: self.labelShowTrans,
            labelResetPassword: self.labelResetPassword
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "first_name",
            name: "first_name",
            title: self.$t("user.table.name"),
            render: function (data, type, row) {
              return row.first_name + " " + row.last_name
            }
          },
          {
            data: "username",
            name: "username",
            title: self.$t("fields.placeholder.username")
          },
          {
            data: "email",
            name: "email",
            title: self.$t("user.table.email")
          },
          {
            data: "roles",
            name: "roles",
            searchable: false,
            orderable: false,
            title: self.$t("user.table.roles")
          },
          {
            data: "active",
            name: "active",
            searchable: false,
            orderable: true,
            title: self.$t("label.status")
          },
          {
            data: "created_at",
            name: "created_at",
            searchable: false,
            orderable: true,
            title: self.$t("user.table.createdAt")
          },
          {
            data: "actions",
            name: "actions",
            searchable: false,
            orderable: false,
            title: self.$t("user.table.actions"),
            width: "150px"
          }
        ],
        order: [[5, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    reloadButtonAction () {
      const self = this

      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-edit")
      $(document).off("click", ".btn-change-password")
      $(document).off("click", ".btn-delete")

      $(document).on("click", ".btn-show", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "show-user",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-edit", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "edit-user",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-change-password", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "user-reset-password",
          params: { uuid: $(this).attr("data-uuid") }
        })
      })

      $(document).on("click", ".btn-delete", function (e) {
        e.preventDefault()
        self.deleteExchange($(this).attr("data-uuid"))
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
