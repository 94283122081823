<template>
  <SubscriptionCard :title="$t('string.payment_methods')">
    <div class="row">
      <div class="col-md-6">
        <h3>Update Your Credit Card</h3>
        <p>
          Want to update the credit card that we have on file? Provide the new details here.<br>
          And don't worry; your card information will never directly touch our servers.
        </p>

        <div class="margin-bottom">
          <input
            class="form-control"
            type="text"
          >
        </div>

        <button class="btn btn-primary text-uppercase">
          Update Card
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <hr>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h3>Erase Your Credit Card Details</h3>
        <p>
          If you need to remove your card token from our server,<br>
          please first cancel your subscription and then return to this page.
        </p>
        <button class="btn btn-default text-uppercase">
          Remove Card
        </button>
      </div>
    </div>
  </SubscriptionCard>
</template>

<script>
import SubscriptionCard from "@/views/settings/subscription/components/SubscriptionCard"

export default {
  name: "SubscriptionPaymentMethods",
  components: { SubscriptionCard }
}
</script>

<style scoped>

</style>
