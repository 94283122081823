<template>
  <div class="d-flex flex-column h-100">
    <div class="non-footer-container flex-1">
      <div class="d-flex flex-column h-100">
        <NavbarV2 />
        <div class="container-fluid no-padding flex-1 overflow-y-auto w-100 scrollbar">
          <div class="d-flex h-100">
            <template v-if="hidSidebar">
              <LeftSideBarMobileScreen>
                <LeftSideBarOrder />
              </LeftSideBarMobileScreen>
            </template>
            <div class="flex-1 d-flex aso-content-right">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foo hidden-xs">
      <DashboardFooter />
    </div>
  </div>
</template>

<script>
import DashboardFooter from "../../includes/footer/Dashboard"
import NavbarV2 from "@/includes/NavbarV2"
import LeftSideBarOrder from "@/components/LeftSideBarOrder"
import LeftSideBarMobileScreen from "@/components/LeftSideBarMobileScreen"

export default {
  name: "Order",
  components: {
    LeftSideBarMobileScreen,
    LeftSideBarOrder,
    NavbarV2,
    DashboardFooter
  },
  computed: {
    hidSidebar () {
      const route_names = [
        "customer-group-list-v2",
        "customer-membership-list-v2"
      ]
      return !(route_names.includes(this.$route.name))
    }
  },
  mounted () {

  }
}
</script>

<style scoped>
.non-footer-container {
  min-height: calc(100% - 50px);
}

@media screen and (max-height: 400px) {
  .foo {
    display: none;
  }
}

</style>
