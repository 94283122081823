<template>
  <div class="subscription-card">
    <div class="subscription-card-header">
      <h3 class="subscription-card-title font-weight-bold">
        {{ title }}
      </h3>
    </div>
    <div class="subscription-card-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionCard",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.subscription-card {
  padding: 22px;
  border-radius: 0.625rem;
  border: 1px solid #e7e9f4;
  background: white;
  min-height: 435px;
}

.subscription-card-header {
  padding-bottom: 11px;
}

.subscription-card-title {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .subscription-card {
    padding: 12px 0;
    min-height: unset;
    height: auto;
  }

  .subscription-card-header {
    padding-bottom: 0;
  }

  .subscription-card-title {
    padding: 0 12px 12px;
  }
}
</style>
