<template>
  <div class="list-order flex-1 h-100 overflow-hidden position-relative">
    <template>
      <div class="d-flex flex-column h-100">
        <div class="block-category order-product-head no-padding shadow-bottom">
          <div class="list-order-header no-border">
            <div class="list-order-header-w h-100">
              <div class="list-order-label d-flex">
                <span class="order-item_number"><strong>#</strong></span>
                <span class="order-item_name"><strong>{{ $t("order.product.name") }}</strong></span>
                <span class="order-item_qty"><strong>{{ $t("label.qty") }}</strong></span>
                <span class="order-item_price"><strong>{{ $t("label.price") }}</strong></span>
                <span class="order-item_amount"><strong>{{ $t("label.amount") }}</strong></span>
              </div>
            </div>
            <div
              v-if="hasProductOrder"
              class="list-order-btn"
            >
              <button
                class="btn btn-danger mr-0"
                @click="clearCartOrderProducts"
              >
                <i class="fas fa-trash-alt" />
              </button>
            </div>
          </div>
        </div>
        <div class="flex-1 overflow-y-auto h-100 scrollbar">
          <div
            v-if="hasProductOrder"
            class="row mx-0 list-order-height scrollbar overflow-y-auto user-select-none"
          >
            <template v-for="(orderItem, orderItemIndex) in orderProducts">
              <div
                :key="orderItemIndex"
                class="col-xs-12 no-padding list-order-item has-border-b"
              >
                <div class="icon-order-product">
                  <i class="fas fa-chevron-right" />
                </div>
                <div class="d-flex align-items-center w-100 position-relative">
                  <a
                    class="list-order-item-w h-100"
                    data-placement="top"
                    data-target="#productOrderModal"
                    data-toggle="modal"
                    @click="editProductOrderItem(orderItem)"
                  >
                    <div class="list-order-label d-block">
                      <div class="has-greater-than-sign d-flex align-items-center position-relative">
                        <span class="order-item_number text-ellipsis">{{ parseInt(orderItemIndex) + 1 }}</span>
                        <span class="order-item_name text-ellipsis">
                          {{ orderItem.name }}
                          <template v-if="orderItem.product_variants && orderItem.product_variants.length > 0">
                            <small class="slash">
                              <span
                                v-for="(item, itemIndex) in orderItem.product_variants"
                                :key="itemIndex"
                              >
                                <template v-if="item.pivot.value">
                                  {{ item.pivot.value }}
                                </template>
                              </span>
                            </small>
                          </template>
                          <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                            <small>
                              {{ $t("label.discount") }}:
                              <template
                                v-if="!orderItem.pivot.is_percentage && shop_currency && shop_currency.code === 'KHR'"
                              >
                                {{ orderItem.pivot.discount | numFormat("0,0") }}
                              </template>
                              <template v-else>
                                {{ orderItem.pivot.discount | numFormat(num_format) }}
                              </template>
                              {{ orderItem.pivot.is_percentage ? "%" : shop_currency ? shop_currency.symbol : "$" }}
                            </small>
                          </template>
                          <template v-if="orderItem.promotion_value">
                            <small>{{ $t("label.promotion") }}:
                              {{ orderItem.promotion_value | numFormat(num_format) }}{{
                                orderItem.promotion_is_percentage ? "%" : shop_currency ? shop_currency.symbol : "$"
                              }}</small>
                          </template>
                        </span>
                        <span
                          :class="{'grow': orderItem.class_active}"
                          class="order-item_qty font-weight-normal text-ellipsis"
                        >
                          {{ orderItem.pivot.quantity }}
                          <small>{{ orderItem.unit ? orderItem.unit["name_" + $i18n.locale] : null }}</small>
                        </span>
                        <span class="order-item_price font-weight-normal text-ellipsis">
                          <template v-if="orderItem.sale_price">
                            <template v-if="parseInt(orderItem.sale_price) > 0">
                              <span :class="{'text-red': calcDiscountPrice(orderItem) <= 0}">
                                {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                                <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                  {{ calcDiscountPrice(orderItem) | numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ calcDiscountPrice(orderItem) | numFormat(num_format) }}
                                </template>
                              </span>
                            </template>
                            <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                              <br>
                              <small style="font-weight: normal">
                                <del class="text-red">
                                  {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                    {{ orderItem.sale_price | numFormat("0,0") }}
                                  </template>
                                  <template v-else>
                                    {{ orderItem.sale_price | numFormat(num_format) }}
                                  </template>
                                </del>
                              </small>
                            </template>
                          </template>
                          <template v-else>
                            <span class="text-danger">0.00</span>
                          </template>
                        </span>
                        <span class="order-item_amount font-weight-normal text-ellipsis">
                          <template v-if="orderItem.sale_price">
                            <template v-if="parseInt(orderItem.sale_price) > 0">
                              <span :class="{'text-red': calcDiscountPrice(orderItem) <= 0}">
                                {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                                <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                  {{
                                    calculateAmountTotal(calcDiscountPrice(orderItem), orderItem.pivot.quantity) | numFormat("0,0")
                                  }}
                                </template>
                                <template v-else>
                                  {{
                                    calculateAmountTotal(calcDiscountPrice(orderItem), orderItem.pivot.quantity) | numFormat(num_format)
                                  }}
                                </template>
                              </span>
                            </template>
                          </template>
                          <template v-else>
                            <span class="text-danger">0.00</span>
                          </template>
                        </span>
                      </div>
                    </div>
                  </a>
                  <div class="list-order-btn">
                    <button
                      class="btn btn-danger mr-0"
                      @click="removeAnItemFromCart(orderItem)"
                    >
                      <i class="fas fa-times" />
                    </button>
                  </div>
                </div>
                <div
                  v-if="orderItem.pivot.note"
                  class="list-order-label d-block py-0 pb-3 text"
                >
                  <small class="font-s-14 help-block mt-0 text-black">{{ $t("note") }}:
                    {{ orderItem.pivot.note }}</small>
                </div>
              </div>
            </template>
          </div>
          <template v-else>
            <div class="row mx-0 list-order-height h-100">
              <no-any-records
                :height="50"
                image="/img/undraw_empty_cart_co35.svg"
              />
            </div>
          </template>
        </div>
        <div class="row mx-0 block-amount scrollbar">
          <div class="col-xs-12 no-padding">
            <div class="row no-margin has-border-b">
              <div
                :class="order_discount && order_discount > 0 ? 'col-lg-7' : 'col-lg-5'"
                class="col-xs-12 col-md-6 no-padding"
              >
                <div class="list-total-amount btn-discount-order">
                  <button
                    :class="{'disabled': !hasProductOrder}"
                    :disabled="!hasProductOrder"
                    class="btn btn-default btn-lg btn-block no-margin"
                    data-target="#addDiscount"
                    data-toggle="modal"
                    type="button"
                    @click="openDiscountModal"
                  >
                    <span class="fa-stack fa-discount mr-2">
                      <i class="fas fa-tag fa-rotate-90 fa-stack-2x" />
                      <i class="fas fa-percent fa-stack-1x fa-inverse" />
                    </span>
                    <span class="hidden-sm hidden-md">{{ $t("label.discount") }}</span>
                    <template v-if="order_discount && order_discount > 0">
                      <span class="ml-2 font-s-18 text-bold text-red">
                        <template v-if="dis_as_percentage">
                          {{ order_discount | numFormat(num_format) }} %
                        </template>
                        <template v-else>
                          <template v-if="shop_currency">
                            {{ shop_currency.code }}
                            <template v-if="shop_currency && shop_currency.code === 'KHR'">
                              {{ order_discount | numFormat("0,0") }}
                            </template>
                            <template v-else>
                              {{ order_discount | numFormat(num_format) }}
                            </template>
                          </template>
                          <template v-else>
                            USD {{ order_discount | numFormat(num_format) }}
                          </template>
                        </template>
                      </span>
                    </template>
                  </button>
                </div>
              </div>
              <div
                :class="order_discount && order_discount > 0 ? 'col-lg-5' : 'col-lg-7'"
                class="col-xs-12 col-md-6 no-padding"
              >
                <div class="list-total-amount btn-select-customer">
                  <template v-if="selectedCustomer">
                    <div class="selected-customer">
                      <div class="sugg-cus-name">
                        <strong>{{ selectedCustomer.name }}</strong>
                      </div>
                    </div>
                    <div>
                      <button
                        :class="{'disabled': !hasProductOrder}"
                        :disabled="!hasProductOrder"
                        class="btn btn-lg btn-danger no-margin"
                        @click="clearSelectCustomer"
                      >
                        <i class="fas fa-trash-alt" />
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <button
                      :class="{'disabled': !hasProductOrder}"
                      :disabled="!hasProductOrder"
                      class="btn btn-default btn-lg btn-block mx-0"
                      @click="showSelectCustomer = !showSelectCustomer"
                    >
                      <i class="fas fa-user mr-2" />
                      <span class="hidden-sm hidden-md">{{ $t("label.customer") }}</span>
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 no-padding">
            <div class="w-100 h-100">
              <div class="list-total-amount block-btn-pay">
                <button
                  :class="{'disabled': !hasProductOrder}"
                  :disabled="!hasProductOrder"
                  class="btn-success btn-pay justify-content-between"
                  @click="onProceedOrder"
                >
                  <label class="no-margin text-left d-block">
                    <strong>{{ $t("button.pay") }}</strong>
                    <small class="d-block font-s-14">{{ countItems }} {{ $t("label.items") }}</small>
                  </label>
                  <label class="no-margin text-right d-block">
                    <label class="d-block margin-bottom-none">
                      <label
                        v-if="order_discount && order_discount > 0"
                        class="font-s-14 mr-2 badge badge-danger badge-del-price text-white hidden-md hidden-sm hidden-xs margin-bottom-none"
                      >
                        <del>
                          {{ shop_currency ? shop_currency.code : "USD" }}
                          <template v-if="shop_currency && shop_currency.code === 'KHR'">
                            {{ origin_amount_total | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ origin_amount_total | numFormat(num_format) }}
                          </template>
                        </del>
                      </label>
                      <label class="font-s-22 margin-bottom-none">
                        {{ shop_currency ? shop_currency.code : "USD" }}
                        <template v-if="shop_currency && shop_currency.code === 'KHR'">
                          {{ subtotal | numFormat("0,0") }}
                        </template>
                        <template v-else>
                          {{ subtotal | numFormat(num_format) }}
                        </template>
                      </label>
                      <small
                        v-if="order_discount && order_discount > 0"
                        class="d-block text-white font-s-14"
                      >
                        <del class="font-s-14 badge badge-danger badge-del-price text-white hidden-lg">
                          {{ shop_currency ? shop_currency.code : "USD" }}
                          <template v-if="shop_currency && shop_currency.code === 'KHR'">
                            {{ origin_amount_total | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ origin_amount_total | numFormat(num_format) }}
                          </template>
                        </del>
                      </small>
                    </label>
                    <label class="d-block margin-bottom-none">
                      <label
                        v-if="order_discount && order_discount > 0"
                        class="font-s-14 mr-2 badge badge-danger badge-del-price text-white hidden-md hidden-sm hidden-xs margin-bottom-none"
                      >
                        <del>
                          {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                          <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                            {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat(num_format) }}
                          </template>
                        </del>
                      </label>
                      <label class="font-s-18 margin-bottom-none">
                        {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                        <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                          {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat("0,0") }}
                        </template>
                        <template v-else>
                          {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat(num_format) }}
                        </template>
                      </label>
                      <small
                        v-if="order_discount && order_discount > 0"
                        class="d-block text-white font-s-14"
                      >
                        <del class="font-s-14 badge badge-danger badge-del-price text-white hidden-lg">
                          {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                          <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                            {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat(num_format) }}
                          </template>
                        </del>
                      </small>
                    </label>
                  </label>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{'show-alert': show_alert}"
          class="alert alert-dismissible hide-alert"
          role="alert"
        >
          <div class="font-s-15 d-flex align-items-center alcf">
            <span class="mr-2">{{ $t("string.removeProduct") }} <strong>({{ remove_items.length }})</strong></span>
            <div class="button-undo ml-3">
              <a
                class="alert-link"
                href="#"
                @click="undo"
              >{{ $t("button.undo") }}</a>
            </div>
          </div>
          <div class="button-close">
            <a
              type="button"
              @click="removeItem"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
        </div>
      </div>
    </template>
    <DrawerCustomer
      ref="drawerCustomer"
      :show-select-customer="showSelectCustomer"
      class-list-customer="list-sugg-customer-order"
      @closeDrawer="closeDrawerCustomer"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import NoAnyRecords from "../../../components/NoAnyRecords"
import DrawerCustomer from "@/components/DrawerCustomer"

export default {
  name: "OrderProduct",
  components: {
    DrawerCustomer,
    NoAnyRecords
  },
  props: {
    orderFrom: {
      type: String,
      default: "order"
    }
  },
  data () {
    return {
      showSelectCustomer: false,
      show_alert: false,
      remove_items: [],
      time: null,
      discount: 0,
      is_percentage: true,
      discountAuthorizer: null,
      origin_amount_total: 0
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      shop_currency: state => state.shop.currency,
      orderProducts: state => state.order.orderProducts,
      takeAway: state => state.order.takeAway,
      selectedCustomer: state => state.order.selectedCustomer,
      order_discount: state => state.order.order_discount,
      dis_as_percentage: state => state.order.is_percentage,
      authorizer: state => state.order.discount_authorizer
    }),
    subtotal () {
      let total = 0
      let valKhr = 0
      let valUsd = 0
      this.orderProducts.forEach((product) => {
        let result = 0
        // calculate product discount
        if (product.pivot && product.pivot.discount > 0) {
          if (product.pivot.is_percentage) {
            const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
            result = parseFloat(product.sale_price) - amount_discount
          } else {
            let amount_discount = parseFloat(product.pivot.discount)
            if (this.shop_currency && product.currency && this.shop_currency.code !== product.currency.code) {
              amount_discount = this.exchangeMoney(this.shop_currency.code, product.currency.code, amount_discount)
            }
            result = parseFloat(product.sale_price) - amount_discount
          }
        } else {
          result = parseFloat(product.sale_price)
        }
        if (product.currency && product.currency.code === "USD") {
          valUsd = parseFloat(valUsd) + (parseFloat(product.pivot.quantity) * result)
        } else {
          valKhr = parseFloat(valKhr) + (parseFloat(product.pivot.quantity) * result)
        }

        const usd = this.exchangeMoney("USD", this.shop_currency.code, valUsd)
        const khr = parseFloat(this.exchangeMoney("KHR", this.shop_currency.code, valKhr))
        total = khr + usd
      })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.origin_amount_total = total
      if (this.order_discount) {
        if (this.dis_as_percentage) {
          total = total - ((total * parseFloat(this.order_discount)) / 100)
        } else {
          total = total - parseFloat(this.order_discount)
        }
      }
      return parseFloat(total).toFixed(2)
    },
    countItems () {
      let total = 0
      this.orderProducts.forEach((product) => {
        total += parseFloat(product.pivot.quantity)
      })
      return total
    },
    hasProductOrder () {
      return this.orderProducts.length > 0
    }
  },
  mounted () {
    if (this.order_discount) {
      this.discount = this.order_discount
    }
  },
  methods: {
    createAlert (item) {
      this.show_alert = true
      this.remove_items.push(item)
      if (this.time) {
        clearTimeout(this.time)
      }
      this.setDuration()
    },
    setDuration () {
      this.time = setTimeout(() => {
        this.removeItem()
      }, 10000)
    },
    removeItem () {
      this.remove_items = []
      this.show_alert = false
    },
    undo () {
      this.show_alert = false
      if (this.remove_items.length) {
        this.remove_items.forEach((item) => {
          this.$store.dispatch("order/undoProductRemoved", item)
          this.$store.dispatch("order/setDisplayPo", this)
        })
      }
      this.remove_items = []
    },
    closeDrawerCustomer () {
      this.showSelectCustomer = false
    },
    calculateAmountTotal (price, quantity) {
      return parseFloat(price) * parseFloat(quantity)
    },
    calcDiscountPrice (product) {
      if (product.pivot && product.pivot.discount > 0) {
        if (product.pivot.is_percentage) {
          const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
          const result = parseFloat(product.sale_price) - amount_discount
          return parseFloat(result).toFixed(2)
        } else {
          // Convert price of product currency to shop currency.
          const val = this.exchangeMoney(product.currency.code, this.shop_currency.code, parseFloat(product.sale_price))
          const amount_discount = parseFloat(product.pivot.discount)
          let result = parseFloat(val) - amount_discount
          // Convert price back to product currency.
          result = this.exchangeMoney(this.shop_currency.code, product.currency.code, parseFloat(result))
          return parseFloat(result).toFixed(2)
        }
      }
      // call computed subtotal
      // this.subtotal
      return parseFloat(product.sale_price).toFixed(2)
    },
    editProductOrderItem (item) {
      this.$store.dispatch("order/setEditProductOrder", item)
    },
    clearCartOrderProducts () {
      this.$swal({
        title: this.$t("string.clearAllOrderedProduct"),
        text: this.$t("string.clearAllOrderedProductDes"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("swal.confirmation.button.yes"),
        cancelButtonText: this.swalNoButton
      }).then(() => {
        this.$store.dispatch("order/clearCartOrderProducts")
        this.$store.dispatch("order/setDisplayPo", this)
        this.$store.dispatch("order/setOrderDiscountStatus", true)
        this.emit("display_dis_percent", {
          display_dis_percent: true
        })
        this.$store.dispatch("order/setOrderDiscount", 0)
        this.emit("display_dis", {
          discount: 0
        })
        this.$store.dispatch("order/setDiscountAuthorizer", null)
        this.closeSidebarOrderProductSm()
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },
    updateOrderProductQuantity (item, $event) {
      this.$store.dispatch("order/updateOrderProductQuantity", {
        item: item,
        value: $event.target.value
      })
      this.$store.dispatch("order/setDisplayPo", this)
    },
    removeAnItemFromCart (item) {
      this.createAlert(item)
      this.$store.dispatch("order/removeAnItemFromCart", item)
      this.$store.dispatch("order/setDisplayPo", this)
    },
    clearSelectCustomer () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/v2/order/remove-customer", {
        order_uuid: this.$route.params.uuid
      }).then(({ data }) => {
        if (data.data) {
          this.$store.dispatch("order/setSelectCustomer", null)
          this.emit("display_sc", {
            customer: null
          })
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    onProceedOrder () {
      const hasError = this.orderProducts.filter(product => {
        return !product.sale_price || product.sale_price === "0"
      }).length > 0

      if (hasError) {
        return this.$toastr("error", this.$t("string.pleaseInputPriceOfItem"), this.$t("string.error"))
      }

      if (this.subtotal <= 0) {
        const subtotal = this.subtotal
        this.$swal({
          title: this.swalTitle,
          html: "<label>" + this.$t("string.continueTheProcess") + "</label><br>" +
            this.$t("label.subTotal") +
            " = <span class=\"badge\" style=\"font-size: 14px; background-color: red\">" + subtotal + "</span>",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: this.$t("swal.confirmation.button.yes"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          this.$emit("pay")
        }, (dismiss) => {
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          throw err
        })
      } else {
        this.$emit("pay")
      }
    },
    openDiscountModal () {
      this.$store.dispatch("order/setOrderDiscountStatus", this.dis_as_percentage)
      this.emit("display_dis_percent", {
        display_dis_percent: this.dis_as_percentage
      })
      this.$store.dispatch("order/setOrderDiscount", parseFloat(this.order_discount))
      this.emit("display_dis", {
        discount: parseFloat(this.order_discount)
      })
      this.$store.dispatch("order/setDiscountAuthorizer", this.authorizer)
    }
  }
}
</script>

<style scoped>
.icon-order-product {
  position: absolute;
  font-size: 8px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 10px;
  color: #878787;
}

.btn-select-customer {
  padding: 14px 16px 14px 8px;
}

.btn-discount-order {
  padding: 14px 8px 14px 16px;
  border-right: 1px solid #f3f2f2;
}

.fa-discount {
  font-size: 10px !important;
  line-height: 1.8rem !important;
}

.fa-discount .fa-stack-2x {
  font-size: 20px !important;
}

.fa-discount .fa-stack-1x {
  font-size: 8px !important;
  transform: rotate(135deg);
}

.has-greater-than-sign:before {
  content: none;
  font-family: "FontAwesome", serif;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: -6px;
  color: #878787;
}

.block-btn-pay {
  padding: 14px 16px !important;
}

@media screen and (max-width: 1200px) {
  .block-btn-pay,
  .btn-discount-order,
  .btn-select-customer {
    padding: 8px 8px !important;
  }

  .btn-discount-order {
    border-right: unset;
  }

  .has-greater-than-sign:before {
    left: -3px;
  }
}
</style>
