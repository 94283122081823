import offlineInventoryTransaction from "@/views/inventories/offlineInventoryTransaction/Index"
import List from "@/views/inventories/offlineInventoryTransaction/List"

export const offlineInventoryTransactionRouters = {
  path: "inventory-transaction",
  component: offlineInventoryTransaction,
  redirect: "",
  children: [
    {
      path: "",
      name: "list-offline-inventory-transaction",
      component: List
    }
  ]
}
