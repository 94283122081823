<template>
  <IndexLayout
    :btn-title="btn_title"
    :is-not-route-list="isNotRouteList"
    :route-name-create="route_name_create"
    :title="title"
  />
</template>

<script>
import IndexLayout from "@/components/layout/IndexLayout"

export default {
  name: "ExchangeIndex",
  components: { IndexLayout },
  computed: {
    isNotRouteList () {
      return this.$route.name !== "list-exchange"
    }
  },
  data () {
    return {
      route_name_create: "create-exchange",
      title: "label.exchangeRate",
      btn_title: "button.newExchange"
    }
  }
}
</script>

<style scoped>

</style>
