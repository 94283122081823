import RestaurantLayout from "@/views/settings/restaurant/Index"
import RestaurantInfo from "@/views/settings/restaurant/Detail"
import RestaurantHistoryDetail from "@/views/settings/restaurant/ShowRestaurantHistory"

export const restaurantRouters = {
  path: "restaurant",
  component: RestaurantLayout,
  name: "restaurant",
  redirect: "restaurant/info",
  children: [
    {
      path: "info",
      name: "restaurant-info",
      component: RestaurantInfo
    },
    {
      path: "restaurant-history/:uuid/detail",
      name: "restaurant-history-detail",
      component: RestaurantHistoryDetail
    }
  ]
}
