import axios from "axios"
import router from "./router"

// config
axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-CSRF-TOKEN": "FhtMBOptzwWbZ63FanxrrFBiKieYR2EOkeYudxsb"
}
if (localStorage.getItem("token") !== undefined) {
  axios.defaults.headers.common.Authorization = localStorage.getItem("token")
  $.ajaxSetup({
    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("token")
    }
  })
}

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data

  if (response.data.hasOwnProperty("code")) {
    if (response.data.code === 603) {
      const lang = localStorage.getItem("locale")
      if (lang === "km") {
        alert(response.data.message_km)
      } else {
        alert(response.data.message_en)
      }
      swal("Error", "Some kind of error", "error")
      return response
    } else {
      return response
    }
  } else {
    return response
  }
}, function (error) {
  if (error) {
    if (error.response && error.response.status && error.response.status === 401) {
      router.push({ name: "Login" })
    }
  }
  // Do something with response error
  return Promise.reject(error)
})

export default axios
