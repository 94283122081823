<template>
  <div
    v-if="user"
    class="row"
  >
    <div class="col-md-12 text-center">
      <div class="wrapper-avatar">
        <template v-if="avatar_location">
          <img
            :src="`${checkUrlAvatar(avatar_location)}`"
            height="200"
            width="200"
            alt="User Avatar"
          >
        </template>
        <template v-else>
          <img
            :src="defaultAvatar"
            height="200"
            alt="User Avatar"
            width="200"
          >
        </template>
      </div>
      <button
        class="btn btn-primary btn-margin-top"
        @click="toggleShow"
      >
        {{ $t("button.uploadNewPicture") }}
      </button>

      <my-upload
        v-model="show"
        :headers="headers"
        :height="200"
        :params="params"
        :url="`${$config.base_url}/api/user/upload-avatar`"
        :width="200"
        field="avatar"
        img-format="png"
        lang-type="en"
        @crop-success="cropSuccess"
        @crop-upload-fail="cropUploadFail"
        @crop-upload-success="cropUploadSuccess"
      />
    </div>
  </div>
</template>

<script>
import myUpload from "vue-image-crop-upload"
import { mapGetters } from "vuex"

export default {
  components: { "my-upload": myUpload },
  name: "ProfileUploadAvatar",
  data () {
    return {
      show: false,
      params: {
        user_id: null,
        name: "avatar"
      },
      headers: {
        Authorization: localStorage.getItem("token"),
        Accept: "application/json"
      },
      imgDataUrl: ""
    }
  },
  computed: {
    ...mapGetters("user", {
      user: "getUser"
    }),
    avatar_location () {
      if (this.user) {
        const { avatar_location } = this.user
        if (avatar_location) {
          return avatar_location
        }
      }
      return null
    }
  },
  methods: {
    toggleShow () {
      this.show = !this.show
    },
    cropSuccess (imgDataUrl) {
      this.imgDataUrl = imgDataUrl
    },
    cropUploadSuccess (jsonData) {
      this.$store.dispatch("user/updateUser", jsonData.data)
      localStorage.setItem("user", JSON.stringify(jsonData.data))
    },
    cropUploadFail (status, field) {
    }
  },
  mounted () {
    if (this.user === null) {
      this.$store.dispatch("user/updateUser", JSON.parse(localStorage.getItem("user")))
      this.params.user_id = this.user.id
    }
  }
}
</script>

<style scoped>

</style>
