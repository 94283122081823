<template>
  <InvoiceTemplateForm />
</template>

<script>
import InvoiceTemplateForm from "@/views/settings/invoice-template/Form"

export default {
  name: "InvoiceTemplateCreate",
  components: { InvoiceTemplateForm },
  metaInfo () {
    return {
      title: this.$t("label.newInvoiceTemplate"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
