import Layout from "@/views/chef/Index"
import Board from "@/views/chef/Board"

export const chefRouters = {
  path: "/chef",
  component: Layout,
  redirect: "chef",
  children: [
    {
      path: "",
      name: "chef-board",
      component: Board
    }
  ]
}
