import { mapActions, mapGetters } from "vuex"

const acl = {
  install (Vue, options) {
    Vue.mixin({
      computed: {
        ...mapGetters("user", {
          administrators: "administrators",
          roles: "roles",
          permissions: "permissions"
        })
      },
      methods: {
        ...mapActions("user", {
          setRoles: "setRoles",
          setPermissions: "setPermissions"
        }),
        can (permissions) {
          if (Array.isArray(this.permissions)) {
            return this.isAdmin() || this.permissions.some(p => permissions.includes(p))
          }
          return false
        },
        hasRoles (roles) {
          if (Array.isArray(this.roles)) {
            return this.isAdmin() || this.roles.some(r => roles.includes(r))
          }
          return false
        },
        onlyHasRole (role) {
          if (Array.isArray(this.roles) && typeof role === "string") {
            return this.roles.some(r => r === role) && this.roles.length === 1
          }
          return false
        },
        isAdmin () {
          if (Array.isArray(this.roles)) {
            return this.administrators.some(r => this.roles.includes(r))
          }
          return false
        },
        setRolesPermissions () {
          let roles = []
          let permissions = []
          const user = JSON.parse(localStorage.getItem("user"))

          if (user && user.roles && Array.isArray(user.roles)) {
            roles = user.roles
          }

          if (user && user.permissions && Array.isArray(user.permissions) && user.permissions.length > 0) {
            permissions = user.permissions.map(p => p.name)
          }

          try {
            this.$store.dispatch("user/setRoles", roles).then()
            this.$store.dispatch("user/setPermissions", permissions).then()
            // eslint-disable-next-line no-empty
          } catch (e) {
          }
        }
      },
      mounted () {
        this.setRolesPermissions()
      }
    })
  }
}

export default acl
