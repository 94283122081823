<template>
  <div>
    <div class="container title no-print pb-15">
      <div class="col-xs-6 col-lg-4 col-lg-offset-2">
        <button
          class="btn btn-secondary no-margin"
          @click="onClickPrint"
        >
          <i class="fas fa-print" />
          {{ $t("button.print") }}
        </button>
      </div>
      <div class="col-xs-6 col-lg-4 text-right">
        <template v-if="backRoute === 'payment-show'">
          <a
            class="btn btn-default no-margin"
            style="margin-right: 5px"
            @click="onClickNewOrder"
          >
            <i class="fas fa-times-circle" />
            {{ $t("button.close") }}
          </a>
        </template>
        <template v-else>
          <button
            class="btn btn-default no-margin"
            style="margin-right: 5px"
            @click="$router.back()"
          >
            <i class="fas fa-times-circle" />
            {{ $t("button.close") }}
          </button>
        </template>
      </div>
    </div>
    <div class="invoice-container">
      <div
        v-if="shop && invoice"
        id="container"
        style="padding: 0 !important; min-height: auto;"
      >
        <div class="header text-center pt-3">
          <template v-if="inv_template.show_logo">
            <p style="margin: 0">
              <template v-if="shop.logo">
                <img
                  id="logo"
                  alt="Logo"
                  :src="getSrc(shop.logo)"
                  style="max-width: 220px; max-height: 100px;"
                >
              </template>
              <template v-else>
                <img
                  src="/images/icons/icon-512x512.png"
                  alt="Logo"
                  style="max-width: 220px; max-height: 100px;"
                >
              </template>
            </p>
          </template>
          <table class="table table-hover auto-width">
            <template v-if="inv_template.show_shop_name">
              <tr>
                <td
                  class="no-border"
                  colspan="4"
                  style="text-align: center"
                >
                  <strong>{{ shop.name }}</strong>
                </td>
              </tr>
            </template>
            <tr
              v-if="order && inv_template && inv_template.show_order_number"
              class="below-calculate"
            >
              <td colspan="4">
                <p class="margin-bottom-none font-s-16">
                  <strong>{{ $t("table.order.orderNumber") }}: {{ order.order_number }}</strong>
                </p>
              </td>
            </tr>
            <template v-if="inv_template.show_contact">
              <tr
                v-if="shop.address || shop.tel"
                class="below-calculate"
              >
                <td
                  class="no-border tiny-text slash-bar"
                  colspan="4"
                >
                  <span v-if="shop.address">{{ shop.address }}</span>
                  <span v-if="shop.tel">{{ shop.tel }}</span>
                </td>
              </tr>
            </template>
            <template v-if="inv_template && inv_template['custom_header_' + $i18n.locale]">
              <tr class="below-calculate">
                <td
                  class="no-border tiny-text whitespace-pre"
                  colspan="5"
                >
                  {{ inv_template["custom_header_" + $i18n.locale] }}
                </td>
              </tr>
            </template>
            <template v-if="inv_template.show_customer_info">
              <tr
                v-if="order.customer"
                class="below-calculate tiny-text"
              >
                <td class="text-left">
                  {{ $t("label.customer") }}:
                </td>
                <td
                  class="text-left"
                  colspan="3"
                >
                  <strong v-if="order.customer.name">{{ order.customer.name }}</strong>
                  <span v-else>{{ $t("string.na") }}</span>
                </td>
              </tr>
              <tr
                v-if="order.customer && order.customer.tel"
                class="below-calculate tiny-text"
              >
                <td class="text-left">
                  {{ $t("label.telephone") }}:
                </td>
                <td class="text-left">
                  <strong>{{ order.customer.tel }}</strong>
                </td>
                <td class="text-left">
                  {{ $t("label.gender") }}:
                </td>
                <td class="text-left">
                  <strong>{{ order.customer.gender.name_en }}</strong>
                </td>
              </tr>
              <tr
                v-if="order.customer && order.customer.address"
                class="below-calculate tiny-text"
              >
                <td class="text-left">
                  {{ $t("label.address") }}:
                </td>
                <td
                  class="text-left"
                  colspan="3"
                >
                  <strong>{{ order.customer.address }}</strong>
                </td>
              </tr>
            </template>
            <tr class="below-calculate tiny-text">
              <td class="text-left">
                {{ $t("label.date") }}:
              </td>
              <td class="text-left">
                <strong>{{ $moment(invoice.created_at).format("DD/MM/YYYY") }}</strong>
              </td>
              <td class="text-left">
                {{ $t("string.time") }}:
              </td>
              <td class="text-left">
                <strong>{{ $moment(invoice.created_at).format("hh:mm:ss A") }}</strong>
              </td>
            </tr>
            <template v-if="inv_template.show_cashier">
              <tr class="below-calculate tiny-text">
                <td class="no-border text-left">
                  {{ $t("label.cashier") }}:
                </td>
                <td class="no-border text-left">
                  <strong>{{ invoice.cashier.full_name }}</strong>
                </td>
                <template v-if="inv_template.show_invoice_number">
                  <td class="no-border text-left">
                    {{ $t("label.inv") }}:
                  </td>
                  <td class="no-border text-left">
                    <strong>{{ invoice ? invoice.order_number : null }}</strong>
                  </td>
                </template>
              </tr>
            </template>
          </table>
        </div>
        <div class="body">
          <table class="table table-hover auto-width small-text margin-bottom-none">
            <thead>
              <tr>
                <th style="width: 5%">
                  #
                </th>
                <th
                  class="text-center"
                  style="width: 30%"
                >
                  {{ $t("label.item") }}
                </th>
                <th
                  class="text-center"
                  style="width: 10%"
                >
                  {{ $t("label.qty") }}
                </th>
                <th
                  class="text-center"
                  style="width: 25%"
                >
                  {{ $t("label.price") }}
                </th>
                <th
                  class="text-right"
                  style="width: 30%"
                >
                  {{ $t("label.subTotal") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(product, key) in products"
                :key="key"
              >
                <td class="text-center">
                  {{ key + 1 }}.
                </td>
                <td class="text-center">
                  <div>{{ product.name }}</div>
                  <div
                    v-if="product.parent_id !== null"
                    class="slash font-s-13"
                  >
                    <template
                      v-if="product.product_variants && Array.isArray(product.product_variants) && product.product_variants.length"
                    >
                      <template v-for="(variant, product_variants_key) in product.product_variants">
                        <span
                          v-if="product.product_variants.length > 0"
                          :key="product_variants_key"
                        >{{ variant.pivot.value }}</span>
                      </template>
                    </template>
                  </div>
                  <template v-if="product.promotion_value">
                    <div class="font-s-13">
                      <small>
                        Promo:
                        {{ product.promotion_value | numFormat(num_format) }}
                        {{ product.promotion_is_percentage ? "%" : currency ? currency.symbol : "$" }}
                      </small>
                    </div>
                  </template>
                </td>
                <td class="text-center">
                  {{ product.pivot.served_quantity }}
                </td>
                <td class="text-center">
                  <div>
                    <template v-if="product.currency && product.currency.code === 'KHR'">
                      {{ getProductSalePrice(product) | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ getProductSalePrice(product) | numFormat(num_format) }}
                    </template>
                    {{ product.currency.symbol }}
                  </div>
                  <div
                    v-if="parseInt(product.pivot.discount) > 0"
                    class="tiny-text"
                  >
                    Disc:
                    <template v-if="product.pivot.is_percentage">
                      {{ product.pivot.discount }}%
                    </template>
                    <template v-else>
                      <template v-if="shop_currency && shop_currency.code === 'KHR'">
                        {{ product.pivot.discount | numFormat("0,0") }}
                      </template>
                      <template v-else>
                        {{ product.pivot.discount | numFormat(num_format) }}
                      </template>
                      {{ shop_currency ? shop_currency.symbol : "$" }}
                    </template>
                  </div>
                </td>
                <td class="text-right">
                  <template v-if="product.currency && product.currency.code === 'KHR'">
                    {{ getSubtotal(product) | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ getSubtotal(product) | numFormat(num_format) }}
                  </template>
                  {{ product.currency.symbol }}
                </td>
              </tr>
              <tr class="no-border below-calculate">
                <td
                  class="text-right"
                  colspan="3"
                >
                  {{ $t("label.subTotal") }}
                </td>
                <td
                  class="text-right"
                  colspan="2"
                >
                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                    {{ parseFloat(getAmountSubtotal) | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ parseFloat(getAmountSubtotal) | numFormat(num_format) }}
                  </template>
                  {{ shop_currency.code === "USD" ? "$" : "៛" }}
                </td>
              </tr>
              <template v-if="invoice.data_info">
                <tr class="no-border below-calculate">
                  <td
                    class="text-right no-border"
                    colspan="3"
                  >
                    {{ $t("label.includeTax") }}
                    ({{ invoice.data_info.tax.amount_percentage }}%)
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    <template v-if="shop_currency && shop_currency.code === 'KHR'">
                      {{ calcAmountTax(invoice.data_info.tax.amount_percentage, getAmountSubtotal) | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{
                        calcAmountTax(invoice.data_info.tax.amount_percentage, getAmountSubtotal) | numFormat(num_format)
                      }}
                    </template>
                    {{ this.shop.currency.code === "USD" ? "$" : "៛" }}
                  </td>
                </tr>
                <tr
                  v-if="invoice.data_info.customer_membership && invoice.data_info.customer_membership.length"
                  class="no-border below-calculate"
                >
                  <td
                    class="text-right no-border"
                    colspan="3"
                  >
                    {{ $t("label.membershipDiscount") }}
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    {{ invoice.data_info.customer_membership[0].amount_percentage | numFormat(num_format) }} %
                  </td>
                </tr>
              </template>
              <tr class="no-border below-calculate">
                <td
                  class="text-right no-border"
                  colspan="3"
                >
                  {{ $t("label.discount") }}
                </td>
                <td
                  class="text-right no-border"
                  colspan="2"
                >
                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                    {{ invoice.amount_discount | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ invoice.amount_discount | numFormat(num_format) }}
                  </template>
                  {{ invoice.is_percentage ? "%" : shop_currency ? shop_currency.symbol : "$" }}
                </td>
              </tr>
              <tr
                v-if="invoice.amount_discount > 0 && invoice.discount_authorizer"
                class="no-border below-calculate"
              >
                <td
                  class="text-right no-border"
                  colspan="3"
                >
                  {{ $t("label.by") }}
                </td>
                <td
                  class="text-right no-border"
                  colspan="2"
                >
                  {{ invoice.discount_authorizer || $t("string.na") }}
                </td>
              </tr>
              <tr class="no-border below-calculate">
                <td
                  class="text-right no-border"
                  colspan="3"
                >
                  <strong>{{ $t("label.amountTotal") }}(USD)</strong>
                </td>
                <td
                  class="text-right no-border"
                  colspan="2"
                >
                  <strong>{{ invoice.amount_total_dollar_number_format | numFormat(num_format) }} $</strong>
                </td>
              </tr>
              <tr class="no-border below-calculate">
                <td
                  class="text-right no-border"
                  colspan="3"
                >
                  <strong>{{ $t("label.amountTotal") }}(KHR)</strong>
                </td>
                <td
                  class="text-right no-border"
                  colspan="2"
                >
                  <strong>{{ invoice.amount_total_riel_number_format | numFormat("0,0") }} ៛</strong>
                </td>
              </tr>

              <template v-if="inv_template.show_amount_paid">
                <tr class="no-border below-calculate">
                  <td
                    class="text-right"
                    colspan="3"
                  >
                    {{ $t("label.amountPaid") }}(USD)
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ invoice.amount_paid_dollar | numFormat(num_format) }} $
                  </td>
                </tr>
                <tr class="no-border below-calculate">
                  <td
                    class="text-right no-border"
                    colspan="3"
                  >
                    {{ $t("label.amountPaid") }}(KHR)
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    {{ invoice.amount_paid_riel | numFormat("0,0") }} ៛
                  </td>
                </tr>
              </template>

              <template v-if="inv_template.show_balance">
                <tr class="no-border below-calculate">
                  <td
                    class="text-right no-border"
                    colspan="3"
                  >
                    {{ $t("label.amountChange") }}(USD)
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    {{ invoice.amount_due_dollar | numFormat(num_format) }} $
                  </td>
                </tr>
                <tr class="no-border below-calculate">
                  <td
                    class="text-right no-border"
                    colspan="3"
                  >
                    {{ $t("label.amountChange") }}(KHR)
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    {{ invoice.amount_due_riel | numFormat("0,0") }} ៛
                  </td>
                </tr>
              </template>

              <template v-if="inv_template.show_exchange_rate">
                <tr
                  v-if="invoice.data_info && invoice.data_info.exchange_rate"
                  class="below-calculate"
                >
                  <td
                    class="tiny-text no-border"
                    colspan="5"
                  >
                    <span class="font-s-14">{{
                      $t("label.exchangeRate")
                    }} 1$ = {{ invoice.data_info.exchange_rate.value | numFormat("0,0") }}៛</span>
                  </td>
                </tr>
              </template>
              <template v-if="inv_template && inv_template['custom_footer_' + $i18n.locale]">
                <tr class="below-calculate">
                  <td
                    class="tiny-text no-border pt-3 pb-3 text-center whitespace-pre"
                    colspan="5"
                  >
                    {{ inv_template["custom_footer_" + $i18n.locale] }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="footer"
          style="border-top: 1px solid #ddd; text-align: center; min-height: 50px"
        >
          <div>{{ $t("label.poweredBy") }} Asorasoft.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "Invoice",
  metaInfo () {
    return {
      title: this.$t("string.printInvoice"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  beforeRouteEnter (to, from, next) {
    localStorage.setItem("from_route", from.name)
    next()
  },
  data () {
    return {
      order: null,
      invoice: null,
      products: null,
      inv_template: {
        custom_footer_en: "Thank you for coming",
        custom_footer_km: "សូមអរគុណសម្រាប់ការអញ្ជីញមក។",
        custom_header_en: null,
        custom_header_km: null,
        show_amount_paid: true,
        show_balance: true,
        show_cashier: true,
        show_contact: true,
        show_customer_info: true,
        show_exchange_rate: true,
        show_logo: true,
        show_shop_name: true,
        show_invoice_number: false,
        show_order_number: false
      }
    }
  },
  computed: {
    ...mapState({
      shop: state => state.shop.shop,
      num_format: state => state.shop.numFormat,
      shop_currency: state => state.shop.currency,
      auto_popup_print: state => state.shop.autoPopupPrint
    }),
    getAmountSubtotal () {
      if (this.invoice) {
        return this.invoice[this.shop.currency.code === "USD" ? "amount_subtotal_dollar" : "amount_subtotal_riel"]
      }
      return 0
    },
    backRoute () {
      return localStorage.getItem("from_route")
    }
  },
  created () {
    this.getInvoiceTemplateActive()
  },
  mounted () {
    this.getInvoiceInfo()
    localStorage.setItem("display_order_mode", "show_thank_you")
    this.emit("display_order_mode", {
      display_order_mode: "show_thank_you"
    })
  },
  methods: {
    onClickNewOrder () {
      if (!(this.$route.name === "order-show" && this.orderProducts.length === 0)) {
        this.$isLoading(true)
        this.$axios.post(this.$config.base_url + "/api/order/create-new-blank-order", {
          shop_uuid: this.shop.uuid
        }).then((response) => {
          this.$router.push({
            name: "order-show",
            params: {
              uuid: response.data.data.uuid,
              orderFrom: "order"
            }
          })
          this.$store.dispatch("order/setOrderProducts", [])
          this.$store.dispatch("order/setDisplayPo", this)
          this.$store.dispatch("order/setSelectCustomer", null)
          this.emit("display_sc", {
            customer: null
          })
          this.$isLoading(false)
        }).catch((error) => {
          this.getResponseError(error)
          this.$isLoading(false)
        })
      }
    },
    getProductSalePrice (product) {
      if (product.is_custom_product) {
        return product.custom_product_price
      } else {
        return product.sale_price
      }
    },
    calcAmountTax (tax, total) {
      tax = parseFloat(tax)
      total = parseFloat(total)
      return (tax * total) / 100
    },
    onClickPrint () {
      window.print()
    },
    getSubtotal (item) {
      let subtotal = 0
      if (item.pivot.is_percentage) {
        if (item.is_custom_product) {
          subtotal = item.pivot.served_quantity * item.custom_product_price * (100 - item.pivot.discount) / 100
        } else {
          subtotal = item.pivot.served_quantity * item.sale_price * (100 - item.pivot.discount) / 100
        }
      } else {
        if (item.is_custom_product) {
          let amount_discount = parseFloat(item.pivot.discount)
          if (this.shop_currency && item.currency && this.shop_currency.code !== item.currency.code) {
            amount_discount = this.exchangeMoney(this.shop_currency.code, item.currency.code, amount_discount)
          }
          subtotal = (item.pivot.served_quantity * item.custom_product_price) - amount_discount
        } else {
          let amount_discount = parseFloat(item.pivot.discount)
          if (this.shop_currency && item.currency && this.shop_currency.code !== item.currency.code) {
            amount_discount = this.exchangeMoney(this.shop_currency.code, item.currency.code, amount_discount)
          }
          subtotal = (item.pivot.served_quantity * item.sale_price) - amount_discount
        }
      }
      // return subtotal
      return subtotal
    },
    getSrc (src) {
      if (src.includes("http")) {
        return src
      } else {
        return this.$config.base_url + "/" + src
      }
    },
    getInvoiceInfo () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/invoice/show", {
        shop_uuid: this.shop.uuid,
        uuid: this.$route.params.uuid
      }).then((response) => {
        this.order = response.data.data.order
        this.invoice = response.data.data.invoice
        this.products = JSON.parse(this.invoice.items)
        this.products.map((product) => {
          const subtotal = product.pivot.served_quantity * product.sale_price * (100 - product.pivot.discount) / 100
          this.$set(product, "subTotal", subtotal)

          product = this.calcPromotion(product, product.promotions)
          return product
        })
        if (this.auto_popup_print) {
          setTimeout(() => {
            this.onClickPrint()
          }, 300)
        }
      }).catch((error) => {
        this.getResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getInvoiceTemplateActive () {
      this.$axios.post(this.$config.base_url + "/api/invoice-template/get-invoice-template-active", {
        shop_uuid: this.get_shop.uuid
      }).then((res) => {
        const r = res.data.data
        if (r) {
          this.inv_template = {
            custom_footer_en: r.custom_footer_en,
            custom_footer_km: r.custom_footer_km,
            custom_header_en: r.custom_header_en,
            custom_header_km: r.custom_header_km,
            show_amount_paid: r.show_amount_paid,
            show_balance: r.show_balance,
            show_cashier: r.show_cashier,
            show_contact: r.show_contact,
            show_customer_info: r.show_customer_info,
            show_exchange_rate: r.show_exchange_rate,
            show_logo: r.show_logo,
            show_shop_name: r.show_shop_name,
            show_invoice_number: r.show_invoice_number,
            show_order_number: r.show_order_number
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.pb-15 {
  padding-bottom: 15px;
}

.invoice-container {
  width: 100mm;
  background-color: white;
  margin: auto;
  min-height: 832px;
}

.auto-width {
  table-layout: auto;
  padding: 0 12px;
}

.auto-width td, .auto-width th {
  padding: 8px 0;
  vertical-align: middle;
}

.auto-width td:first-child, .auto-width th:first-child {
  padding-left: 8px;
}

.auto-width td:last-child, .auto-width th:last-child {
  padding-right: 8px;
}

.auto-width > thead > tr:first-child, .auto-width > tbody > tr:first-child {
  width: 10mm !important;
}

.below-calculate > td {
  height: 5mm;
  padding: 0 2px;
}

.small-text {
  font-size: 1.1em;
}

.tiny-text {
  font-size: 1em;
}

.item-list > td {
  padding: 3px 0;
  vertical-align: middle;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }

  @page {
    margin: 0;
  }
}
</style>
