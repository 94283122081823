import AdditionalApp from "@/views/settings/additionalApps/Index"
import List from "@/views/settings/additionalApps/List"

export const additionalAppsRouters = {
  path: "additional-apps",
  component: AdditionalApp,
  children: [
    {
      path: "",
      name: "list-additional-apps",
      component: List
    }
  ]
}
