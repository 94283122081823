import User from "@/views/settings/user/Index"
import List from "@/views/settings/user/List"
import Create from "@/views/settings/user/Create"
import Show from "@/views/settings/user/Show"
import Edit from "@/views/settings/user/Edit"
import ResetPassword from "@/views/settings/user/ResetPassword"

export const userRouters = {
  path: "user",
  component: User,
  name: "user",
  redirect: "user",
  children: [
    {
      path: "",
      name: "list-user",
      component: List,
      meta: {
        group: "user"
      }
    },
    {
      path: "create",
      name: "create-user",
      component: Create,
      meta: {
        group: "user"
      }
    },
    {
      path: "edit-user/:uuid",
      name: "edit-user",
      component: Edit,
      meta: {
        group: "user"
      }
    },
    {
      path: "show-user/:uuid",
      name: "show-user",
      component: Show,
      meta: {
        group: "user"
      }
    },
    {
      path: "user-reset-password/:uuid",
      name: "user-reset-password",
      component: ResetPassword,
      meta: {
        group: "user"
      }
    }
  ]
}
