<template>
  <div>
    <div class="container title no-print">
      <div class="col-md-12">
        <router-link
          :to="{name: 'amusement-park-order'}"
          class="btn btn-default"
          style="margin-right: 5px"
        >
          <i class="fas fa-arrow-circle-left" />
          {{ $t("button.back") }}
        </router-link>
        <button
          class="btn btn-secondary margin-left"
          @click="onClickPrint"
        >
          <i class="fas fa-print" />
          {{ $t("button.print") }}
        </button>
      </div>
    </div>
    <div
      class="row"
      style="margin: 30px 0"
    >
      <div
        v-if="tickets !== null && tickets.length > 0"
        class="col-md-12"
      >
        <div class="print-container">
          <div
            v-for="(ticket, key) in tickets"
            :key="key"
            class="col-md-12"
          >
            <div class="ticket">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="text-center">
                    {{ shop.name }}
                  </h3>
                  <p class="text-center small-text">
                    {{ shop.address }}
                  </p>
                  <p class="text-center big-text">
                    #000000{{ ticket.ticket_number }}
                  </p>
                  <p class="text-muted text-center">
                    {{ ticket.ticket_type.name }}
                  </p>
                  <div class="text-center">
                    <vue-qr-code
                      :options="{ width: 200 }"
                      :value="ticket.uuid"
                    />
                  </div>
                  <p class="text-muted text-center">
                    {{ ticket.created_at }}
                  </p>
                  <p
                    class="text-muted text-center"
                    style="margin-bottom: 20px"
                  >
                    Power by asorasoft.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Print",
  metaInfo () {
    return {
      title: "Print Ticket",
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      shop: null,
      tickets: null
    }
  },
  methods: {
    getOrder () {
      this.$axios.post(this.$config.base_url + "/api/amusement-park/order/get-tickets-by-order", {
        order_uuid: this.$route.params.order_uuid,
        shop_uuid: this.$config.shop_uuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.shop = response.data.data.shop
          this.tickets = response.data.data.tickets
        }
      })
    },
    onClickPrint () {
      window.print()
    }
  },
  mounted () {
    this.getOrder()
  }
}
</script>

<style scoped>
.print-container {
  margin: 0 auto;
  width: 105mm;
  height: auto;
  background: #2e2d47 !important;
}

.ticket {
  margin-bottom: 10px;
  position: relative;
  background: #FFFFFF;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

.big-text {
  font-size: 20px;
  font-weight: bold;
}

.small-text {
  font-size: 10px;
  line-height: normal;
}

.ticket p {
  line-height: 0.5;
}
</style>
