import Primary from "@/layouts/Primary"
import { dashboardRouter, shopCreateRouter } from "@/routers/singleRouters"
import { screenCustomerDisplayRouters } from "@/routers/screenCustomerDisplayRouters"
import { reportRouters } from "@/routers/reportRouters"
import { settingsRouters } from "@/routers/settingRouters"
import { inventoryRouters } from "@/routers/inventoryRouters"
import { offlineRouters } from "@/routers/offlineRouters"
import { orderRouters } from "@/routers/orderRouter"
import { customerRouters } from "@/routers/customerRouters"
import { cashierRouters } from "@/routers/cashierRouter"
import { profileRouters } from "@/routers/profileRouters"
import { invoiceRouters } from "@/routers/invoiceRouter"
import { amusementRouters } from "@/routers/amusementPark"
import { chefRouters } from "@/routers/chefRouters"
import { couponPrintRouters, couponRouters } from "@/routers/couponRouters"
import { error401Routers, error404Routers, error500Routers } from "@/routers/errorRouters"

export const primaryRouters = {
  path: "",
  component: Primary,
  children: [
    dashboardRouter,
    shopCreateRouter,
    dashboardRouter,
    screenCustomerDisplayRouters,
    reportRouters,
    settingsRouters,
    inventoryRouters,
    offlineRouters,
    orderRouters,
    customerRouters,
    cashierRouters,
    profileRouters,
    invoiceRouters,
    amusementRouters,
    chefRouters,
    couponRouters,
    couponPrintRouters,
    error401Routers,
    error500Routers,
    error404Routers
  ]
}
