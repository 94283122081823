<template>
  <div class="content-wrapper">
    <section class="content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t("report.strings.listingReportProductSaleDetail") }}
              </h3>
              <div class="box-tools">
                <a
                  class="btn btn-sm btn-default"
                  @click="$router.go(-1)"
                >
                  <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
                </a>
              </div>
            </div>
            <div class="box-body">
              <table
                id="report-product-sale-history-table"
                class="table table-bordered"
              >
                <thead>
                  <tr>
                    <th>{{ $t("report.table.no") }}</th>
                    <th>{{ $t("report.table.name") }}</th>
                    <th>{{ $t("product.label.variants") }}</th>
                    <th>{{ $t("report.table.amount") }}</th>
                    <th>{{ $t("report.table.unitPrice") }}</th>
                    <th>{{ $t("report.table.discount") }}</th>
                    <th>{{ $t("report.table.amountTotal") }}</th>
                  </tr>
                </thead>
                <tbody />
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "SaleHistory",
  metaInfo () {
    return {
      title: this.$t("report.strings.listingReportProductSaleDetail"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      shop_id: JSON.parse(localStorage.getItem("shop")).id,
      oTable: null
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw()
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $("#report-product-sale-history-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        ajax: {
          url: this.$config.base_url + "/api/report/get-product-sale-history-datatable",
          type: "POST",
          data: (d) => {
            d.shop_id = self.shop_id
            d.product_uuid = self.$route.params.uuid
            d.start_date = self.$route.params.start_date
            d.end_date = self.$route.params.end_date
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "name",
            name: "name",
            searchable: false,
            orderable: false,
            title: this.$t("report.table.name")
          },
          {
            data: "variants",
            name: "variants",
            searchable: false,
            orderable: false,
            title: this.$t("product.label.variants")
          },
          {
            data: "served_quantity",
            name: "served_quantity",
            title: this.$t("report.table.amount")
          },
          {
            data: "sale_price",
            name: "sale_price",
            title: this.$t("report.table.unitPrice")
          },
          {
            data: "discount",
            name: "discount",
            searchable: false,
            orderable: false,
            title: this.$t("report.table.discount")
          },
          {
            data: "amount_total",
            name: "amount_total",
            searchable: false,
            orderable: false,
            title: this.$t("report.table.amountTotal")
          },
          {
            data: "created_at",
            name: "created_at",
            title: this.$t("coupon.table.createdAt")
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
  }
}
</script>

<style scoped>

</style>
