<template>
  <div class="no-print pos-menubar-h">
    <ul class="pos-menubar scrollbar">
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': productTab}"
          :to="{name: 'list-product'}"
          class="closesidebar"
        >
          <i class="fas fa-cubes" />
          <p class="no-margin capitalize">
            {{ $t("menu.inventories.products.product") }}
          </p>
        </router-link>
      </li>
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': categoryTab}"
          :to="{name: 'list-product-category'}"
          class="closesidebar"
        >
          <i class="fas fa-book" />
          <p>{{ $t("menu.inventories.products.category") }}</p>
        </router-link>
      </li>
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': tagTab}"
          :to="{name: 'list-product-tag'}"
          class="closesidebar"
        >
          <i class="fas fa-tags" />
          <p>{{ $t("menu.inventories.products.tag") }}</p>
        </router-link>
      </li>
      <li class="pos-menubar-item">
        <router-link
          :class="{'pos-menubar_active': unitTab}"
          :to="{name: 'list-product-unit'}"
          class="closesidebar"
        >
          <i class="fas fa-cube" />
          <p>{{ $t("menu.inventories.products.unit") }}</p>
        </router-link>
      </li>
      <template v-if="config.VUE_APP_FEATURE_PROMOTION !== 'false'">
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': promotionTab}"
            :to="{name: 'list-promotion'}"
            class="closesidebar"
          >
            <i class="fa fa-bullhorn" />
            <p>{{ $t("menu.settings.promotion") }}</p>
          </router-link>
        </li>
      </template>
      <template v-if="config.VUE_APP_FEATURE_PROMOTION !== 'false'">
        <li class="pos-menubar-item">
          <router-link
            :class="{'pos-menubar_active': warehouseTab}"
            :to="{name: 'list-warehouse'}"
            class="closesidebar"
          >
            <i class="fas fa-warehouse" />
            <p>{{ $t("menu.inventories.products.warehouse") }}</p>
          </router-link>
        </li>
      </template>
      <li class="pos-menubar-item position-relative">
        <router-link
          :class="{'pos-menubar_active': stockAlertTab}"
          :to="{name: 'list-stock-alert-control'}"
          class="closesidebar"
        >
          <i class="fas fa-archive" />
          <p>{{ $t("menu.inventories.products.stockAlertControl") }}</p>
          <template v-if="has_alert_stock">
            <span
              class="badge bg-red badge-stock-alert"
              data-v-de889afa=""
            >
              <i class="fas fa-exclamation mr-0" />
            </span>
          </template>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "SidebarInventoryV2",
  data () {
    return {
      config: process.env
    }
  },
  computed: {
    ...mapGetters({
      has_alert_stock: "shop/has_alert_stock"
    }),
    productTab () {
      const route_paths = [
        "list-product",
        "create-product",
        "edit-product",
        "show-product",
        "print-barcode-all",
        "print-barcode",
        "order-product",
        "product-track-inventory"
      ]
      return route_paths.includes(this.$route.name)
    },
    categoryTab () {
      const route_name = [
        "list-product-category",
        "create-product-category",
        "show-product-category",
        "edit-product-category",
        "order-product-category"
      ]
      return route_name.includes(this.$route.name)
    },
    tagTab () {
      const route_name = [
        "list-product-tag",
        "create-product-tag",
        "show-product-tag",
        "edit-product-tag"
      ]
      return route_name.includes(this.$route.name)
    },
    unitTab () {
      const route_name = [
        "list-product-unit",
        "create-product-unit",
        "show-product-unit",
        "edit-product-unit"
      ]
      return route_name.includes(this.$route.name)
    },
    promotionTab () {
      const route_names = [
        "list-promotion",
        "create-promotion",
        "edit-promotion",
        "show-promotion"
      ]
      return route_names.includes(this.$route.name)
    },
    warehouseTab () {
      const route_name = [
        "list-warehouse",
        "create-warehouse",
        "show-warehouse",
        "edit-warehouse"
      ]
      return route_name.includes(this.$route.name)
    },
    stockAlertTab () {
      const route_name = [
        "list-stock-alert-control"
      ]
      return route_name.includes(this.$route.name)
    }
  },
  mounted () {
    this.alertStockProduct()
  }
}
</script>

<style scoped>
.badge-stock-alert {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  text-align: center;
  user-select: none;
}

.pos-menubar-item .badge-stock-alert i {
  font-size: 10px;
}

@media screen and (max-width: 767px) {
  .badge-stock-alert {
    right: 490px;
  }
}
</style>
