<template>
  <div class="content-wrapper h-100">
    <div
      :class="{'pb-3': isNotRouteList}"
      class="hidden-sm hidden-md hidden-lg no-print"
    >
      <div
        class="block-category d-flex rounded-tl rounded-tr"
        style="padding-top: 8px !important; padding-bottom: 8px"
      >
        <div class="d-flex w-100 align-items-center justify-content-between">
          <p class="title-label mt-0 font-s-20 text-capitalize">
            <strong>{{ $t(title) }}</strong>
          </p>
          <div>
            <template v-if="!hiddenButton">
              <template v-if="!isNotRouteList">
                <router-link
                  :title="$t(btnTitle)"
                  :to="{name: routeNameCreate}"
                  class="btn btn-success btn-lg mr-2"
                >
                  <i class="fas fa-plus" /> <span class="hidden-sm hidden-md ml-2">{{ $t("label.add") }}</span>
                </router-link>
              </template>
              <template v-else>
                <a
                  class="btn btn-default btn-lg no-margin"
                  @click="$router.back()"
                >
                  <i class="fas fa-arrow-left back" />
                </a>
              </template>
            </template>
            <template v-else>
              <slot name="header-button" />
            </template>
          </div>
        </div>
      </div>
    </div>

    <section class="content container-fluid content_p-xs scrollbar">
      <div class="row">
        <div class="col-md-12 p-xs">
          <router-view />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "IndexLayout",
  props: {
    routeNameCreate: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    btnTitle: {
      type: String,
      default: ""
    },
    isNotRouteList: {
      type: Boolean,
      default: true
    },
    hiddenButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
