<template>
  <ProductCategoryForm />
</template>

<script>
import ProductCategoryForm from "./Form"

export default {
  name: "CreateProductCategory",
  components: { ProductCategoryForm }
}
</script>

<style scoped>

</style>
