<template>
  <div class="register-container">
    <div class="register-form-content">
      <div class="restau-wrap rounded shadow">
        <div class="w-100 margin-bottom pt-3 text-center">
          <router-link :to="{name: 'Login'}">
            <div class="register-logo">
              <img
                alt="Logo"
                src="/img/logos/logo.png"
                class="tw-w-full"
              >
            </div>
          </router-link>
        </div>

        <div class="restau-block-form has-border-r">
          <div class="restau-form-creat">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group form-label-header">
                  <label class="font-s-22 text-capitalize">{{ $t("button.signIn") }}</label>
                </div>
              </div>
              <div class="col-md-12">
                <div
                  v-if="message_error"
                  class="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="alert"
                    type="button"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {{ message_error }}
                </div>
              </div>

              <div class="col-md-12">
                <div
                  :class="{'has-error' : validations.username}"
                  class="form-group"
                >
                  <label>{{ $t("fields.label.username") }}</label>
                  <input
                    v-model="username"
                    :placeholder="$t('fields.label.username')"
                    class="pos-input pos-input-lg"
                    type="email"
                  >
                  <span
                    v-if="validations.email"
                    class="label-error has-error"
                  >
                    {{ validations["username"][0] }}
                  </span>
                </div>
              </div>

              <div class="col-md-12">
                <div
                  :class="{'has-error' : validations.email}"
                  class="form-group"
                >
                  <label>{{ $t("fields.placeholder.password") }}</label>
                  <input
                    v-model="password"
                    :placeholder="$t('fields.placeholder.password')"
                    class="pos-input pos-input-lg"
                    type="password"
                  >
                  <span
                    v-if="validations.password"
                    class="label-error has-error"
                  >
                    {{ validations["password"][0] }}
                  </span>
                </div>
              </div>

              <div class="col-md-12">
                <router-link
                  :to="{name: 'forget-password'}"
                  class="text-link"
                >
                  {{ $t("loginPage.forgetYourPassword?") }}
                </router-link>
              </div>

              <div class="col-md-12 item-center">
                <button
                  class="btn btn-secondary capitalize btn-lg btn-block"
                  @click="login"
                >
                  {{ $t("button.signIn") }}
                </button>
                <router-link
                  :to="{name: 'Register'}"
                  class="btn btn-secondary-outline capitalize btn-lg btn-block no-margin"
                >
                  {{ $t("button.signUp") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="restau-block-form">
          <div class="restau-form-creat">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group form-label-header">
                  <label class="font-s-22 text-capitalize">
                    {{ $t("loginPage.singInOrRegisterWith") }}
                  </label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label
                    class="hidden-xs hidden-sm"
                    style="margin-bottom: 19px;"
                  />
                  <button
                    class="btn btn-facebook btn-lg btn-block"
                    @click="checkLoginState"
                  >
                    <i class="fab fa-facebook-f mr-2" />
                    Facebook
                  </button>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <button
                    class="btn btn-google btn-lg btn-block"
                    @click="loginWithGoogle"
                  >
                    <i class="fab fa-google mr-2" />
                    Google
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 register-footer">
          <p class="text-center">
            {{ $t("string.copyright") }} &copy; 2021 <a href="https://figos.app">Figos.app</a>
            <span class="d-none">Powered by <a href="https://asorasoft.com">Asorasoft.com</a></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shopMixin } from "@/mixins/shop"
import { googleSdkMixin } from "@/mixins/googleLogin"
import { facebookSdkMixin } from "@/mixins/facebookLogin"
import { offlineMixins } from "@/mixins/offline"

export default {
  name: "Login",
  mixins: [shopMixin, facebookSdkMixin, googleSdkMixin, offlineMixins],
  metaInfo () {
    return {
      title: this.$t("loginPage.login"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      username: "",
      password: "",
      rememberMeModel: false,
      validations: {},
      message_error: null
    }
  },
  methods: {
    login () {
      this.validations = {}
      this.message_error = null

      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/auth/login", {
        username: this.username,
        password: this.password
      }).then((response) => {
        const result = response.data.data
        const token = result.token_type + " " + result.access_token

        this.$axios.defaults.headers.common.Accept = "application/json"
        this.$axios.defaults.headers.common.Authorization = token

        $.ajaxSetup({
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })

        if (this.$bill_active === "true") {
          if (result.hasOwnProperty("expired")) {
            this.$store.dispatch("shop/setExpired", result.expired)
          }
          localStorage.setItem("plan", JSON.stringify(result.plan))
          this.$store.dispatch("shop/setPlan", result.plan)
        }

        localStorage.setItem("token", token)
        localStorage.setItem("user", JSON.stringify(result.user))
        this.$store.dispatch("user/updateUser", result.user)

        if (result.shop) {
          this.setShopProperty(result.shop)
          this.checkTokenDeviceForSell()
          this.getOfflineDataCache()
          this.$router.push({ name: "dashboard" })
        } else {
          this.$router.replace({ name: "create-shop" })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.message_error = error.response.data
          if (this.message_error.message) {
            this.message_error = this.message_error.message
          }
          if (this.message_error.message_en) {
            this.message_error = this.message_error["message_" + this.$i18n.locale]
          }
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    onPressEnter (e) {
      if (e.code === "Enter") {
        this.login()
      }
    }
  },
  mounted () {
    window.addEventListener("keypress", this.onPressEnter)
  },
  beforeDestroy () {
    window.removeEventListener("keypress", this.onPressEnter)
  }
}
</script>

<style scoped>
@import "./../assets/css/V2/front-page.css";
</style>
