import "./libraries/stylesheets"
import "./libraries/javascripts"

import Vue from "vue"
import App from "./App.vue"
import store from "./stores"
import axios from "./axios"
import helper from "./helper.js"
import { i18n } from "./i18n"
import Meta from "vue-meta"
import router from "./router"
import acl from "./plugins/acl"
import VueCroppie from "vue-croppie"
import VueMoment from "vue-moment"
import Paginate from "vuejs-paginate"
import VueBarcode from "@xkeshi/vue-barcode"
import VueSweetAlert from "vue-sweetalert"
import VueTheMask from "vue-the-mask"
import VueQrcode from "@chenfengyuan/vue-qrcode"
import VueOffline from "vue-offline"
import ToggleButton from "vue-js-toggle-button"
import Datepicker from "vuejs-datepicker"
import utilities from "./plugins/utilities"
import moment from "moment-timezone"
import VueToastr from "@deveodk/vue-toastr"
import Multiselect from "vue-multiselect"
import Chart from "vue2-frappe"
import VeeValidate from "vee-validate"
import VueLoadingScreen from "vuejs-loading-screen"
import InfiniteLoading from "vue-infinite-loading"
import Datetime from "vue-datetime"
import "./registerServiceWorker"
import numeral from "numeral"
import numFormat from "vue-filter-number-format"
import VueMask from "v-mask"
import GAuth from "vue-google-oauth2"
import VueSocketIO from "vue-socket.io"
import SocketIO from "socket.io-client"

import { vueLoadingScreen, vueMetaOption, vueToastrOption } from "./options"

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: process.env.VUE_APP_GOOGLE_SCOPE,
  prompt: process.env.VUE_APP_GOOGLE_PROMPT
}

Vue.use(VueOffline, { mixin: false })
Vue.use(VueTheMask)
Vue.use(Datetime)
Vue.use(VueSweetAlert)
Vue.use(acl)
Vue.use(Meta, vueMetaOption)
Vue.use(VueCroppie)
Vue.use(ToggleButton)
Vue.use(VueLoadingScreen, vueLoadingScreen)
Vue.use(VueToastr, vueToastrOption)
Vue.use(VueMoment, { moment })
Vue.use(VeeValidate)
Vue.use(Chart)
Vue.use(utilities)
Vue.use(InfiniteLoading)
Vue.use(helper)
Vue.use(VueMask)
Vue.use(GAuth, gauthOption)

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$bill_active = process.env.VUE_APP_BILL_SERVICE_ACTIVE
Vue.prototype.$config = {
  shop_uuid: null,
  shop_id: null,
  app_name: `${localStorage.hasOwnProperty("shop") ? JSON.parse(localStorage.getItem("shop")).name : process.env.VUE_APP_NAME}`,
  base_url: process.env.VUE_APP_API,
  roles: {
    administrator: "administrator",
    owner: "owner",
    waiter: "waiter",
    cashier: "cashier",
    reporter: "reporter"
  }
}

Vue.component("multiselect", Multiselect)
Vue.component("paginate", Paginate)
Vue.component("vue-barcode", VueBarcode)
Vue.component(VueQrcode.name, VueQrcode)
Vue.component("datepicker", Datepicker)
Vue.filter("numFormat", numFormat(numeral))

Vue.filter("nullable", (text) => {
  if (text === null) {
    return this.$t("string.na")
  } else {
    return text
  }
})

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketIO(process.env.VUE_APP_SOCKET_IO)
}))

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app")
