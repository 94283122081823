<template>
  <div class="row">
    <div class="col-sm-6">
      <OrderingProductList
        ref="orderingProductList"
        @getProductsOrdered="getProductsOrdered"
      />
    </div>
    <div class="col-sm-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("string.ordering_product") }} ({{ total_products }} {{ $t("string.items") }})
          </h3>
          <div class="box-tools hidden-xs">
            <button
              class="btn btn-primary btn-sm margin-r-5"
              @click="setOrderProducts"
            >
              {{ $t("button.update_order") }}
            </button>
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body">
          <template v-if="Array.isArray(products) && products.length > 0">
            <draggable
              v-model="products"
              group="people"
              @end="drag=false"
              @start="drag=true"
            >
              <transition-group
                :name="'flip-list'"
                class="tw-space-y-2"
                type="transition"
              >
                <div
                  v-for="element in products"
                  :key="element.id"
                >
                  <div class="tw-group tw-flex tw-bg-gray-100 hover:tw-bg-gray-200 tw-rounded-md tw-p-3 tw-cursor-move">
                    <div class="">
                      <template v-if="element.images && Array.isArray(element.images) && element.images.length>0">
                        <div class="tw-ring">
                          <img
                            :src="getSrc(element['images'][0]['src'])"
                            alt="product"
                            class="tw-w-16 tw-h-auto"
                          >
                        </div>
                      </template>
                      <template v-else>
                        <div class="tw-ring">
                          <img
                            alt="product"
                            class="tw-w-16 tw-h-auto"
                            src="/img/pngwing.png"
                          >
                        </div>
                      </template>
                    </div>
                    <div class="tw-flex tw-items-center tw-w-full tw-justify-content-between">
                      <div class="tw-w-full tw-pl-3">
                        <p class="tw-p-0 tw-m-0 tw-font-bold">
                          {{ element.name }}
                        </p>
                        <p class="text-muted tw-p-0 tw-m-0">
                          {{ element.category ? element.category["name_" + $i18n.locale] : $t("string.na") }}
                        </p>
                      </div>
                      <div class="tw-w-full tw-text-right">
                        <div class="tw-invisible group-hover:tw-visible">
                          <button
                            class="btn btn-danger btn-sm"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Remove"
                            @click="removeItem(element)"
                          >
                            <i class="fas fa-minus-square" />
                          </button>
                          <div class="btn-group">
                            <template v-if="products.indexOf(element) !== 0">
                              <button
                                class="btn btn-default btn-sm"
                                data-placement="top"
                                data-toggle="tooltip"
                                title="Move to Top"
                                @click="moveToTop(element)"
                              >
                                <i class="fas fa-angle-double-up" />
                              </button>
                            </template>
                            <template v-if="products.indexOf(element) !== parseInt((products.length - 1).toString())">
                              <button
                                class="btn btn-default btn-sm"
                                data-placement="top"
                                data-toggle="tooltip"
                                title="Move to Bottom"
                                @click="moveToBottom(element)"
                              >
                                <i class="fas fa-angle-double-down" />
                              </button>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </template>
          <template v-else>
            <EmptyCart />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import EmptyCart from "@/components/EmptyCart"
import OrderingProductList from "@/views/inventories/products/_components/OrderingProductList"

export default {
  name: "ProductCategoryOrder",
  metaInfo () {
    return {
      title: this.$t("label.product.category.orderCategory"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    OrderingProductList,
    EmptyCart,
    draggable
  },
  data () {
    return {
      config: this.$config,
      products: [],
      number_per_page: 2,
      page: 1,
      pageCount: 0
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      }
    },
    total_products () {
      if (this.products && Array.isArray(this.products) && this.products.length > 0) {
        return this.products.length
      }
      return 0
    }
  },
  methods: {
    getProductsOrdered (page) {
      this.page = page
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/product/get-products-ordered", {
        shop_id: this.get_shop.id,
        number_per_page: this.number_per_page,
        page: this.page
      }).then(({ data }) => {
        if (data.data) {
          this.products = data.data
          this.pageCount = data.total_pages
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    setOrderProducts () {
      this.onConfirm({
        title: this.$t("string.ordering_product"),
        text: this.$t("string.do_you_update_the_order")
      }).then(({ accept }) => {
        if (accept) {
          this.$isLoading(true)
          this.$axios.post(this.$config.base_url + "/api/product/set-ordering-products", {
            products: this.products,
            shop_id: this.get_shop.id
          }).then(() => {
            this.onResponseSuccess()
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
    moveToTop (product) {
      this.products.splice(this.products.indexOf(product), 1)
      this.products = [product, ...this.products]
    },
    moveToBottom (product) {
      this.products.splice(this.products.indexOf(product), 1)
      this.products = [...this.products, product]
    },
    removeItem (product) {
      this.onConfirm({
        title: this.$t("string.remove_it"),
        text: this.$t("string.do_you_want_remove_it_from_list")
      }).then(({ accept }) => {
        if (accept) {
          this.products.splice(this.products.indexOf(product), 1)

          this.$isLoading(true)
          this.$axios.post(this.$config.base_url + "/api/product/set-ordering-products", {
            products: this.products,
            shop_id: this.get_shop.id,
            unset_order_product_id: product.id
          }).then(() => {
            this.onResponseSuccess()
            this.$refs.orderingProductList.oTable.draw()
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    }
  },
  mounted () {
    this.getProductsOrdered()
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
