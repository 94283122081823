<template>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label
          class="required"
          for="plan"
        >{{ $t("string.plan") }}</label>
        <input
          id="plan"
          v-model="currentPlan.title"
          class="form-control"
          readonly
          type="text"
        >
      </div>

      <div class="form-group">
        <label
          class="required"
          :for="'monthly'"
        >{{ $t("string.monthly") }}</label>
        <select
          id="monthly"
          v-model="duration"
          class="form-control"
        >
          <option :value="6">
            6 {{ $t("string.months") }}
          </option>
          <option :value="12">
            12 {{ $t("string.months") }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label
          class="required"
          for="price"
        >{{ $t("string.price") }}</label>
        <input
          id="price"
          :value="price"
          class="form-control"
          readonly
          type="text"
        >
      </div>

      <div class="form-group">
        <button
          class="btn btn-default"
          @click="showInvoiceList"
        >
          {{ $t("button.back") }}
        </button>
        <button
          class="btn btn-primary"
          @click="submit"
        >
          {{ $t("button.submit") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "SubscriptionRenewalPlanFreeFrom",
  data () {
    return {
      duration: 6,
      validations: {}
    }
  },
  computed: {
    ...mapGetters("subscription", {
      currentPlan: "currentPlan"
    }),
    price () {
      const constant = process.env
      if (this.currentPlan) {
        if (this.currentPlan.code === constant.VUE_APP_SUBSCRIPTION_STANDARD_CODE) {
          if (this.duration === 6) {
            return this.currentPlan.price * 6
          }
          return constant.VUE_APP_SUBSCRIPTION_STANDARD_YEALY_PRICE
        } else if (this.currentPlan.code === constant.VUE_APP_SUBSCRIPTION_PREMIUM_CODE) {
          if (this.duration === 6) {
            return this.currentPlan.price * 6
          }
          return constant.VUE_APP_SUBSCRIPTION_PREMIUM_YEALY_PRICE
        } else if (this.currentPlan.code === constant.VUE_APP_SUBSCRIPTION_FREE_CODE) {
          return 0
        }
      }
      return 0
    }
  },
  methods: {
    ...mapActions("subscription", {
      showInvoiceList: "showInvoiceList"
    }),
    submit () {
      this.validations = {}
      this.$isLoading(true)

      this.$axios.post(this.$base_api + "/api/bill/renewal-free-plan", {
        duration: this.duration,
        price: this.price
      }).then(({ data }) => {
        if (data.data) {
          this.$toastr("success", this.$t("string.yourRequestHasBeenSubmitted"), this.$t("string.newPlan"))
          this.showInvoiceList()
          this.$emit("onSubmit")
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
