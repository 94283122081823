<template>
  <div :class="column">
    <div class="tw-w-full tw-bg-white tw-shadow-sm tw-rounded-lg">
      <div class="tw-bg-gray-50 tw-rounded-md tw-rounded-b-none tw-p-8">
        <slot name="header" />
      </div>
      <div class="tw-px-8 tw-py-8 tw-space-y-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    column: {
      type: String,
      default: "col-md-12"
    }
  }
}
</script>

<style scoped>

</style>
