import Login from "@/views/Login"
import Register from "@/views/Register"
import SendMail from "@/views/SendMail"
import ForgetPassword from "@/views/ForgetPassword"
import SecondCustomerDisplay from "@/views/customerDisplay/SecondCustomerDisplay"
import RenewPassword from "@/views/RenewPassword"
import ShopCreate from "@/views/settings/restaurant/Create"
import Dashboard from "@/views/Dashboard"

export const loginRouter = {
  path: "/",
  name: "Login",
  component: Login,
  meta: {
    auth: false
  }
}

export const registerRouter = {
  path: "/register",
  name: "Register",
  component: Register,
  meta: {
    auth: false
  }
}

export const sendMailRouter = {
  path: "/send-mail",
  name: "send-mail",
  component: SendMail,
  meta: {
    auth: false
  }
}

export const forgetPasswordRouter = {
  path: "/forget-password",
  name: "forget-password",
  component: ForgetPassword,
  meta: {
    auth: false
  }
}

export const secondCustomerDisplayRouter = {
  path: "/second-customer-display",
  name: "second-customer-display",
  component: SecondCustomerDisplay,
  meta: {
    auth: false
  }
}

export const renewPasswordRouter = {
  path: "/renew-password",
  name: "renew-password",
  component: RenewPassword,
  meta: {
    auth: false
  }
}

export const shopCreateRouter = {
  path: "/shop/create",
  name: "create-shop",
  component: ShopCreate
}

export const dashboardRouter = {
  path: "/dashboard",
  name: "dashboard",
  component: Dashboard
}
