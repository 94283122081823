<template>
  <div class="row">
    <template v-if="ticketTypes.length > 0">
      <div
        v-for="(ticketType, key) in ticketTypes"
        :key="key"
        class="col-xs-6 col-sm-6 col-md-4 col-lg-4"
      >
        <div
          :style="`border-left: .4rem solid ${ticketType.color} !important;`"
          class="ticket shadow margin-bottom courser-pointer"
          @click="addTicket(ticketType)"
        >
          <div class="ticket-body">
            <p class="text-primary ticket-name">
              {{ ticketType.name }}
            </p>
            <template v-if="ticketType.hasOwnProperty('price') && ticketType.price !== null">
              <p class="big-text">
                $ {{ parseFloat(ticketType.price).toFixed(2) }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="col-md-12">
        <no-any-records />
      </div>
    </template>
  </div>
</template>

<script>
import NoAnyRecords from "@/components/NoAnyRecords"

export default {
  name: "TicketSection",
  components: { NoAnyRecords },
  data () {
    return {
      ticketTypes: []
    }
  },
  methods: {
    getTicketTypes () {
      this.$axios.post(this.$config.base_url + "/api/amusement-park/ticket-type", {
        shop_uuid: this.$config.shop_uuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.ticketTypes = response.data.data
        }
      })
    },
    addTicket (ticketType) {
      this.$set(ticketType, "total_people", 1)
      this.$store.dispatch("amusementPark/addTicket", ticketType)
    }
  },
  mounted () {
    this.getTicketTypes()
  }
}
</script>

<style scoped>
.ticket-name {
  height: 18px;
  width: 140px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}
</style>
