const state = {
  sell_mode: "normal",
  display_date_format: "dd/MM/yyyy",
  placeholder_display_date_format: "DD/MM/YYYY",
  recent_sale_filter: {
    start_date: null,
    end_date: null,
    customer: null,
    cashier: null,
    status: null,
    payment_option: null
  },
  parked_sale_filter: {
    start_date: null,
    end_date: null
  }
}

const getters = {}

const actions = {
  setSellMode ({ commit }, mode) {
    commit("setSellMode", mode)
  },
  setRecentSaleFilter ({ commit }, data) {
    commit("setRecentSaleFilter", data)
  },
  setParkedSaleFilter ({ commit }, data) {
    commit("setParkedSaleFilter", data)
  }
}

const mutations = {
  setSellMode (state, mode) {
    state.sell_mode = mode
  },
  setRecentSaleFilter (state, data) {
    state.recent_sale_filter = data
  },
  setParkedSaleFilter (state, data) {
    state.parked_sale_filter = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
