<template>
  <div
    id="filterParkedSale"
    aria-labelledby="filterParkedSaleLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header-custom">
          <h4
            id="myModalLabel"
            class="modal-title capitalize"
          >
            {{ $t("label.filterParkedSale") }}
          </h4>
          <button
            ref="close"
            :class="class_btn_modal"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
          >
            <i
              aria-hidden="true"
              class="fas fa-times"
            />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <label class="font-s-16 user-select-none text-capitalize font-weight-normal">{{
                  $t("fields.label.startDate")
                }}</label>
                <datepicker
                  v-model="start"
                  :clear-button="true"
                  :clear-button-icon="'fas fa-times'"
                  :format="dateFormat"
                  :input-class="'pos-input pos-input-lg'"
                  :placeholder="placeholder_display_date_format"
                  name="membershipStartDate"
                />
              </div>
            </div>
            <div class="col-xs-12">
              <div class="form-group">
                <label class="font-s-16 user-select-none text-capitalize font-weight-normal">{{
                  $t("fields.label.endDate")
                }}</label>
                <datepicker
                  v-model="end"
                  :clear-button="true"
                  :clear-button-icon="'fas fa-times'"
                  :disabled-dates="disabledDates"
                  :format="dateFormat"
                  :input-class="'pos-input pos-input-lg'"
                  :placeholder="placeholder_display_date_format"
                  name="membershipStartDate"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default btn-lg"
            @click="onClickReset"
          >
            <i class="fas fa-history" /> {{ $t("button.reset") }}
          </button>
          <button
            class="btn btn-success btn-lg"
            @click="onClickApply"
          >
            <i class="fas fa-check-circle" /> {{ $t("button.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "ParkedSaleFilter",
  data () {
    return {
      start: null,
      end: null,
      disabled_dates: {
        to: null
      }
    }
  },
  computed: {
    ...mapState({
      parked_sale_filter: state => state.sell.parked_sale_filter,
      dateFormat: state => state.sell.display_date_format,
      placeholder_display_date_format: state => state.sell.placeholder_display_date_format
    }),
    disabledDates () {
      if (this.start) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.disabled_dates.to = this.start
        if (this.end) {
          const s = this.$moment(this.start).format("YYYY-MM-DD")
          const e = this.$moment(this.end).format("YYYY-MM-DD")
          if (this.$moment(e).isBefore(s)) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.end = null
          }
        }
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.disabled_dates.to = null
      }
      return this.disabled_dates
    }
  },
  methods: {
    clearData () {
      this.start = null
      this.end = null
    },
    onClickApply () {
      this.$store.dispatch("sell/setParkedSaleFilter", {
        start_date: this.start,
        end_date: this.end
      })
      this.$emit("refreshList")
      this.$refs.close.click()
    },

    onClickReset () {
      this.clearData()
      this.$store.dispatch("sell/setParkedSaleFilter", {
        start_date: null,
        end_date: null
      })
      this.$emit("refreshList")
      this.$refs.close.click()
    }
  }
}
</script>
