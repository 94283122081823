import Vue from "vue"
import Vuex from "vuex"
import user from "@/stores/modules/user"
import shop from "@/stores/modules/shop"
import sell from "@/stores/modules/sell"
import auth from "@/stores/modules/auth"
import chef from "@/stores/modules/chef"
import order from "@/stores/modules/order"
import customer from "@/stores/modules/customer"
import amusementPark from "@/stores/modules/amusementPark"
import wareHouseDropDown from "@/stores/modules/wareHouseDropDown"
import subscription from "@/stores/modules/subscription"
import totalSoldCount from "@/stores/modules/totalSoldCount"
import product from "@/stores/modules/product"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

export default new Vuex.Store({
  strict: debug,
  modules: {
    user,
    shop,
    sell,
    auth,
    chef,
    order,
    customer,
    amusementPark,
    wareHouseDropDown,
    subscription,
    totalSoldCount,
    product
  }
})
