<template>
  <ProductCategoryForm
    :is-edit="true"
    columns="col-md-12"
  />
</template>

<script>
import ProductCategoryForm from "./Form"

export default {
  components: { ProductCategoryForm }
}
</script>

<style scoped>

</style>
