<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="margin-bottom">
          <h3 class="font-weight-bold">
            {{ $t("string.nextRenewal") }}
          </h3>
        </div>
        <div class="row">
          <slot name="displayAlert" />
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{ $t("table.date") }}</th>
              <th>{{ $t("table.charge") }}</th>
              <th>{{ $t("table.actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="next_renewal_date">
              <tr>
                <td>{{ $moment(next_renewal_date).format("D MMM, YYYY") }}</td>
                <td>${{ parseFloat(next_renewal_price).toFixed(2) }}</td>
                <td>
                  <button
                    class="btn btn-primary btn-xs"
                    @click="showRenewForm"
                  >
                    {{ $t("button.renew") }}
                  </button>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td
                  class="text-center"
                  colspan="3"
                >
                  {{ $t("string.no_records") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="margin-bottom">
          <h3 class="font-weight-bold">
            {{ $t("string.pastInvoices") }}
          </h3>
        </div>
        <table class="table table-condensed table-bordered table-hover">
          <thead>
            <tr>
              <th>{{ $t("table.date") }}</th>
              <th>{{ $t("table.payment") }}</th>
              <th>{{ $t("table.status") }}</th>
              <th>{{ $t("table.invoice") }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="invoices && Array.isArray(invoices) && invoices.length">
              <tr
                v-for="(invoice, key) in invoices"
                :key="key"
              >
                <td>{{ $moment(invoice.issued_at).format("DD MMM, YYYY") }}</td>
                <td>${{ parseFloat(invoice.price).toFixed(2) }}</td>
                <td>
                  <span class="badge badge-primary">{{ $t("string.paid") }}</span>
                </td>
                <td>
                  <button
                    class="btn btn-primary btn-xs"
                    @click="download(invoice)"
                  >
                    <i class="fas fa-download" />
                  </button>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td
                  class="text-center"
                  colspan="5"
                >
                  {{ $t("string.no_invoices") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import exporter from "@/mixins/exporter"
import { mapActions } from "vuex"

export default {
  name: "SubscriptionInvoiceList",
  data () {
    return {
      invoices: [],
      next_renewal_date: null,
      next_renewal_price: null
    }
  },
  methods: {
    ...mapActions("subscription", {
      showRenewForm: "showRenewForm"
    }),
    download (invoice) {
      this.$isLoading(true)
      exporter({
        $axios: this.$axios,
        url: this.$base_api + "/api/bill/invoice/download",
        params: {
          invoice_uuid: invoice.uuid
        },
        filename: "invoice.pdf"
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    invoiceList () {
      this.$isLoading(true)
      this.$axios.post(this.$base_api + "/api/bill/invoice/list")
        .then(({ data }) => {
          if (data.data) {
            this.invoices = data.data
          }
        }).catch((error) => {
          this.onResponseError(error)
        }).finally(() => {
          this.$isLoading(false)
        })
    },
    nextRenewal () {
      this.$isLoading(true)
      this.$axios.post(this.$base_api + "/api/bill/invoice/next-renewal")
        .then(({ data }) => {
          if (data.data) {
            const response = data.data
            this.next_renewal_date = response.date
            this.next_renewal_price = response.price
          }
        }).catch((error) => {
          this.onResponseError(error)
        }).finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    this.invoiceList()
    this.nextRenewal()
  }
}
</script>

<style scoped>

</style>
