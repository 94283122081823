import InvoiceTemplate from "@/views/settings/invoice-template/Index"
import List from "@/views/settings/invoice-template/List"
import Create from "@/views/settings/invoice-template/Create"
import Edit from "@/views/settings/invoice-template/Edit"

export const invoiceTemplateRouters = {
  path: "invoice-template",
  component: InvoiceTemplate,
  children: [
    {
      path: "",
      name: "list-invoice-template",
      meta: {
        title: "Invoice Template"
      },
      component: List
    },
    {
      path: "create",
      name: "create-invoice-template",
      component: Create
    },
    {
      path: "edit-invoice-template/:uuid",
      name: "edit-invoice-template",
      component: Edit
    }
  ]
}
