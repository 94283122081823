<template>
  <span class="help-block">{{ message }}</span>
</template>

<script>
export default {
  name: "ValidationMessage",
  props: {
    message: {
      type: String,
      default () {
        return this.$t("validation.this_field_is_required")
      }
    }
  }
}
</script>

<style scoped>

</style>
