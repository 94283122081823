<template>
  <div>
    <template v-for="(item, key) in items">
      <template v-if="hasRoles(item.roles)">
        <div
          class="col-xs-6 col-md-4 padding-10"
          :key="key"
        >
          <div :class="{ 'not-allowed-click': is_expired && item.isCheckExpired }">
            <template v-if="item.callFunction">
              <a
                @click="createNewOrder(item.routeLink)"
                class="d-block"
              >
                <div
                  class="restau-box shadow"
                  :class="{'menu-disabled' : is_expired && item.isCheckExpired}"
                >
                  <div class="col-xs-12 col-md-8">
                    <label class="restau-box-label user-select-none">{{ $t(item.name) }}</label>
                  </div>
                  <div class="col-xs-12 col-md-4 restau-box-icon-container">
                    <img
                      :src="item.icon"
                      class="restau-box-icon"
                      alt="Menu Image"
                    >
                  </div>
                </div>
              </a>
            </template>
            <template v-else>
              <a
                @click="checkExpiredDemo(item.routeLink, item.isCheckExpired)"
                class="d-block"
                :class="{ 'not-allowed-click': is_expired && item.isCheckExpired }"
              >
                <div
                  class="restau-box shadow"
                  :class="{'menu-disabled' : is_expired && item.isCheckExpired}"
                >
                  <div class="col-xs-12 col-md-8">
                    <label class="restau-box-label user-select-none">{{ $t(item.name) }}</label>
                  </div>
                  <div class="col-xs-12 col-md-4 restau-box-icon-container">
                    <img
                      :src="item.icon"
                      class="restau-box-icon"
                      alt="Menu Image"
                    >
                  </div>
                </div>
              </a>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { VueOfflineMixin } from "vue-offline"
import { offlineMixins } from "@/mixins/offline"

export default {
  name: "OnlineDashboard",
  mixins: [VueOfflineMixin, offlineMixins],
  data () {
    return {
      config: this.$config,
      token_device: process.env.VUE_APP_KEYWORD_TOKEN_DEVICE,
      token_active_device: process.env.VUE_APP_KEYWORD_ACTIVE_TOKEN_DEVICE,
      gen_token: null,
      items: [
        {
          roles: ["waiter", "cashier", "owner"],
          name: "label.order",
          icon: require("./../../assets/icon/product.svg"),
          routeLink: "/order",
          callFunction: true,
          isCheckExpired: true
        },
        {
          roles: ["cashier", "owner"],
          name: "label.cashier",
          icon: require("./../../assets/icon/Cashier.svg"),
          routeLink: "/cashier/recent-sell",
          callFunction: true,
          isCheckExpired: true
        },
        {
          roles: ["owner"],
          name: "label.customer",
          icon: require("./../../assets/icon/customer.svg"),
          routeLink: "/customer",
          callFunction: false,
          isCheckExpired: true
        },
        {
          roles: ["owner", "stock-controller"],
          name: "label.inventory",
          icon: require("./../../assets/icon/inventory.svg"),
          routeLink: "/admin/inventories/product",
          callFunction: false,
          isCheckExpired: true
        },
        {
          roles: ["owner", "report-viewer"],
          name: "label.report",
          icon: require("./../../assets/icon/Report.svg"),
          routeLink: "/admin/reports",
          callFunction: false,
          isCheckExpired: true
        },
        {
          roles: ["owner"],
          name: "label.settings",
          icon: require("./../../assets/icon/Setting.svg"),
          routeLink: "/admin/settings",
          callFunction: false,
          isCheckExpired: false
        }
      ]
    }
  },
  computed: {
    ...mapState({
      shop: state => state.shop.shop,
      device_for_sell: state => state.shop.device_for_sell
    }),
    ...mapGetters("shop", {
      is_expired: "is_expired"
    })
  },
  methods: {
    createNewOrder (toRoute) {
      if (this.device_for_sell) {
        if (toRoute.includes("/cashier/recent-sell")) {
          this.$router.push({ path: toRoute })
          return 0
        }
        this.$axios.post(this.$config.base_url + "/api/order/create-new-blank-order", {
          shop_uuid: this.shop.uuid
        }).then(({ data }) => {
          if (data.data) {
            const {
              active_token,
              uuid
            } = data.data

            localStorage.setItem(this.token_active_device, active_token)
            this.checkTokenDeviceForSell()
            this.$router.push({
              name: "order-show",
              params: {
                uuid,
                orderFrom: "order"
              }
            })

            this.$store.dispatch("order/setOrderProducts", [])
            this.$store.dispatch("order/setDisplayPo", this)
            this.$store.dispatch("order/setSelectCustomer", null)
            this.emit("display_sc", {
              customer: null
            })
            this.$emit("refreshOrder")
          }
        }).catch((error) => {
          this.getResponseError(error)
        })
      } else {
        this.$router.push({ name: "cashier-list-v2" })
      }
    }
  },
  mounted () {
    const hasRole = this.onlyHasRole("waiter") || this.onlyHasRole("cashier")
    if (hasRole && this.$route.path !== "/order" && !this.isOffline) {
      this.createNewOrder()
    }
  }
}
</script>

<style scoped>
@import "./../../assets/css/dashboard.css";
</style>
