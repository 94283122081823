<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("strings.listingMenus") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'menu-create'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("button.newMenu") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-2 col-sm-3 col-xs-4 margin-bottom">
                <multiselect
                  v-model="selectedActiveOption"
                  :allow-empty="false"
                  :option-height="104"
                  :options="activeOptions"
                  :placeholder="$t('string.pleaseSelect')"
                  :show-labels="false"
                  label="label"
                  track-by="value"
                  @input="getMenu"
                />
              </div>
            </div>
            <table
              id="table-menu"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("table.menu.name") }}</th>
                  <th>{{ $t("table.menu.start") }}</th>
                  <th>{{ $t("table.menu.end") }}</th>
                  <th>{{ $t("table.menu.active") }}</th>
                  <th>{{ $t("table.menu.createdAt") }}</th>
                  <th style="width: 125px!important;">
                    {{ $t("table.menu.actions") }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MenuSetting",
  metaInfo () {
    return {
      title: this.$t("strings.listingMenus"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      food_id: [],
      menus: [],
      foods: [],
      config: this.$config,
      currentMenuModel: null,
      search_query: "",
      oTable: null,
      selectedActiveOption: {
        label: this.$t("report.label.all"),
        value: ""
      },
      activeOptions: [
        {
          label: this.$t("report.label.all"),
          value: ""
        },
        {
          label: this.$t("label.active"),
          value: true
        },
        {
          label: this.$t("label.inactive"),
          value: false
        }
      ]
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    "oTable" () {
      this.reloadButtonActions()
    }
  },
  computed: {
    selectAll: {
      get: function () {
        return this.foods ? this.food_id.length === this.foods.length : false
      },
      set: function (value) {
        const selected = []

        if (value) {
          this.foods.forEach(function (food) {
            selected.push(food.id)
          })
        }
        this.food_id = selected
      }
    },
    swalTitle () {
      return this.$t("swal.confirm_delete.title")
    },
    swalDesc () {
      return this.$t("swal.confirm_delete.desc")
    },
    swalYesButton () {
      return this.$t("swal.confirm_delete.button.yes")
    },
    swalNoButton () {
      return this.$t("swal.confirm_delete.button.no")
    }
  },
  methods: {
    deleteMenu (uuid) {
      const self = this
      self.$swal({
        title: this.$t("swal.confirm_delete.title"),
        text: this.$t("swal.confirm_delete.desc"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("swal.confirm_delete.button.yes"),
        cancelButtonText: this.$t("swal.confirm_delete.button.no")
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/menu/delete", {
          shop_uuid: self.config.shop_uuid,
          uuid: uuid
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.menu"))
            this.getMenu()
          }
        })
      })
    },
    getMenu () {
      this.oTable.draw(self)
    },
    dataTable () {
      const self = this
      return $("#table-menu").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: self.$config.base_url + "/api/menu/data",
          type: "POST",
          data: (d) => {
            d.shop_uuid = self.config.shop_uuid
            d.active = self.selectedActiveOption.value
            d.lang = self.$i18n.locale
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "name",
            name: "name",
            title: self.$t("table.menu.name")
          },
          {
            data: "start",
            name: "start",
            searchable: false,
            orderable: false
          },
          {
            data: "end",
            name: "end",
            searchable: false,
            orderable: false
          },
          {
            data: "active",
            name: "active",
            title: self.$t("table.menu.active"),
            searchable: false,
            orderable: false
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("table.menu.createdAt"),
            searchable: false,
            orderable: true
          },
          {
            data: "actions",
            name: "actions",
            title: self.$t("table.menu.actions"),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    }
  },
  mounted () {
    const self = this
    self.oTable = self.dataTable()

    $(document).off("click", ".btn-show")
    $(document).off("click", ".btn-edit")
    $(document).off("click", ".btn-delete")

    $(document).on("click", ".btn-show", function (e) {
      e.preventDefault()
      self.$router.push({
        name: "menu-show",
        params: { uuid: $(this).attr("data-uuid") }
      })
    })

    $(document).on("click", ".btn-edit", function (e) {
      e.preventDefault()
      self.$router.push({
        name: "menu-edit",
        params: { uuid: $(this).attr("data-uuid") }
      })
    })

    $(document).on("click", ".btn-delete", function (e) {
      e.preventDefault()
      self.deleteMenu($(this).attr("data-uuid"))
    })
  }
}
</script>

<style scoped>
.food-img {
  width: 50px;
  margin: 0 20px 0 0;
  border: 1px solid #CACACA;
}

.td-padding-top {
  padding-top: 25px;
}

.checkbox-btn {
  display: none;
}

.checkbox-label .checkbox-div {
  width: 23px;
  height: 23px;
  display: inline-block;
  border: 2px solid var(--gray-dark);
  text-align: center;
  line-height: 20px;
  margin-right: 6px;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox-label i {
  font-size: 16px;
  opacity: 0;
}

.checkbox-label:hover div {
  background: var(--blue-opacity);
}

.checkbox-btn:checked + .checkbox-label i {
  opacity: 1;
}

.checkbox-btn:checked + .checkbox-label .checkbox-div {
  background: var(--blue-opacity);
}
</style>
