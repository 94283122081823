export const vueToastrOption = {
  defaultPosition: "toast-top-right",
  defaultType: "info",
  defaultTimeout: 6000,
  clickClose: true
}

export const vueMetaOption = {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid"
}

export const vueLoadingScreen = {
  bg: "#43425dc4",
  slot: `
    <div class="d-flex align-items-center justify-content-center text-white">
      <h3><i class="fas fa-circle-notch fa-spin"></i> <span class="font-weight-bold">Loading...</span></h3>
    </div>
  `
}
