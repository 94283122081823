export default {
  async connectDB (db_name = "site-static", db_version = 1) {
    return new Promise(resolve => {
      const request = indexedDB.open(db_name, db_version)
      request.onupgradeneeded = ({ target }) => {
        const db = target.result
        // define all object stores
        db.createObjectStore("user", { keyPath: "uuid" })
        db.createObjectStore("shop", { keyPath: "uuid" })
        db.createObjectStore("payment_options", { keyPath: "id" })
        db.createObjectStore("genders", { keyPath: "id" })
        db.createObjectStore("exchange_rate", { keyPath: "uuid" })
        db.createObjectStore("tax", { keyPath: "uuid" })
        db.createObjectStore("warehouse", { keyPath: "uuid" })
        db.createObjectStore("product_categories", { keyPath: "uuid" })
        db.createObjectStore("products", { keyPath: "uuid" })
        db.createObjectStore("saved", { keyPath: "uuid" })
        db.createObjectStore("orders", { keyPath: "uuid" })
        db.createObjectStore("order_products", { keyPath: "uuid" })
        db.createObjectStore("invoices", { keyPath: "uuid" })
        db.createObjectStore("customers", { keyPath: "uuid" })
        db.createObjectStore("customer_groups", { keyPath: "uuid" })
        db.createObjectStore("customer_memberships", { keyPath: "uuid" })
      }
      return resolve(request)
    })
  },

  async save (node, data) {
    return new Promise((resolve, reject) => {
      this.connectDB()
        .then((request) => {
          request.onsuccess = ({ target }) => {
            const db = target.result
            const tx = db.transaction([node], "readwrite")
            const store = tx.objectStore(node)
            store.put(data)
            tx.oncomplete = () => {
              return resolve({ status: true })
            }
            tx.onerror = (e) => {
            }
            db.close()
          }
        })
    })
  },

  async saveArray (node, data) {
    return new Promise((resolve, reject) => {
      this.connectDB()
        .then((request) => {
          request.onsuccess = ({ target }) => {
            const db = target.result
            const tx = db.transaction([node], "readwrite")
            const store = tx.objectStore(node)
            data.forEach((item) => {
              store.put(item)
            })
            tx.oncomplete = () => {
              return resolve({ status: true })
            }
            tx.onerror = (e) => {
            }
            db.close()
          }
        })
    })
  },

  async find (node, id) {
    return new Promise((resolve, reject) => {
      this.connectDB()
        .then((requestDB) => {
          requestDB.onsuccess = ({ target }) => {
            const db = target.result
            const tx = db.transaction([node])
            const objectStore = tx.objectStore(node)
            const request = objectStore.get(id)

            request.onerror = (e) => {
            }
            request.onsuccess = ({ target }) => {
              return resolve(target.result)
            }

            db.close()
          }
        })
    })
  },

  async all (node) {
    return new Promise((resolve, reject) => {
      this.connectDB()
        .then((requestDB) => {
          requestDB.onsuccess = ({ target }) => {
            const db = target.result

            const tx = db.transaction([node])
            const objectStore = tx.objectStore(node)
            const request = objectStore.getAll()

            request.onerror = (e) => {
            }

            request.onsuccess = ({ target }) => {
              return resolve(target.result)
            }

            db.close()
          }
        })
        .catch((err) => {
          return reject(err)
        })
    })
  },

  async remove (node, id) {
    return new Promise((resolve, reject) => {
      this.connectDB()
        .then((requestDB) => {
          requestDB.onsuccess = ({ target }) => {
            const db = target.result
            const request = db.transaction([node], "readwrite").objectStore(node).delete(id)
            request.onerror = (e) => {
            }
            request.onsuccess = ({ target }) => {
              return resolve({ status: true })
            }
            db.close()
          }
        })
    })
  },

  async removeAll (node) {
    return new Promise((resolve, reject) => {
      this.connectDB()
        .then((requestDB) => {
          requestDB.onsuccess = ({ target }) => {
            const db = target.result
            const request = db.transaction([node], "readwrite").objectStore(node).clear()
            request.onerror = (e) => {
            }
            request.onsuccess = ({ target }) => {
              return resolve({ status: true })
            }
            db.close()
          }
        })
    })
  },

  removeDatabase () {
    indexedDB.deleteDatabase("site-static")
  }
}
