const state = {
  order_uuid: null
}

const getters = {}

const mutations = {
  setOrderUuid (state, orderUuid) {
    state.order_uuid = orderUuid
  }
}

const actions = {
  setOrderUuid ({
    commit,
    state
  }, orderUuid) {
    commit("setOrderUuid", orderUuid)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
