<template>
  <div class="h-100">
    <div class="block-content-h block-payment-order-info">
      <div class="row mx-0 user-select-none">
        <div class="col-xs-12 no-padding has-border-t">
          <div class="list-order-item">
            <div class="w-100 h-100">
              <div class="list-order-label d-flex align-items-center py-2 px-0">
                <span class="order-item_name oipp text-ellipsis text-bold">{{ $t("label.item") }}</span>
                <span class="order-item_qty oipp text-ellipsis">{{ $t("label.qty") }}</span>
                <span class="order-item_price oipp  text-ellipsis">{{ $t("label.price") }}</span>
                <span class="order-item_amount oipp  text-ellipsis">{{ $t("label.amount") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 listing-product scrollbar">
        <div
          v-if="products && products.length > 0"
          class="row mx-0 overflow-y-auto scrollbar user-select-none flex-1"
        >
          <template v-for="(orderItem, orderItemIndex) in products">
            <div
              :key="orderItemIndex"
              class="col-xs-12 no-padding has-border-t"
            >
              <div class="list-order-item">
                <div class="w-100 h-100">
                  <div class="list-order-label d-flex align-items-center py-2 px-0">
                    <div class="order-item_name oipp text-ellipsis">
                      <span class="font-s-14">{{ orderItem.name }}</span>
                      <template
                        v-if="orderItem && orderItem.product_variants && Array.isArray(orderItem.product_variants) && orderItem.product_variants.length > 0"
                      >
                        <small class="slash font-s-12">
                          <template v-for="(item, key) in orderItem.product_variants">
                            <span
                              v-if="item.pivot.value"
                              :key="key"
                            >
                              {{ item.pivot.value }}
                            </span>
                          </template>
                        </small>
                      </template>
                      <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                        <small class="font-s-12">{{ $t("label.discount") }}:
                          <template
                            v-if="!orderItem.pivot.is_percentage && currency && currency.code === 'KHR'"
                          >
                            {{ orderItem.pivot.discount | numFormat("0,0") }}
                          </template>
                          <template v-else>{{ orderItem.pivot.discount | numFormat(num_format) }}</template>
                          {{ orderItem.pivot.is_percentage ? "%" : currency ? currency.symbol : "$" }}
                        </small>
                      </template>
                      <template v-if="orderItem.promotion_value">
                        <small>{{ $t("label.promotion") }}:
                          <template
                            v-if="!orderItem.promotion_is_percentage && currency && currency.code === 'KHR'"
                          >
                            {{ orderItem.promotion_value | numFormat("0,0") }}
                          </template>
                          <template v-else>{{ orderItem.promotion_value | numFormat(num_format) }}</template>
                          {{ orderItem.promotion_is_percentage ? "%" : currency ? currency.symbol : "$" }}
                        </small>
                      </template>
                    </div>
                    <template v-if="orderItem.pivot">
                      <div class="order-item_qty oipp text-ellipsis font-weight-normal font-s-14">
                        {{ orderItem.pivot.quantity }}
                        {{ orderItem.unit ? orderItem.unit["name_" + $i18n.locale] : null }}
                      </div>
                    </template>

                    <div class="order-item_price oipp text-ellipsis font-weight-normal font-s-14">
                      <template v-if="orderItem.sale_price">
                        <div :class="{'text-red': calcDiscountPrice(orderItem) <= 0}">
                          {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                          <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                            {{ calcDiscountPrice(orderItem) | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ calcDiscountPrice(orderItem) | numFormat(num_format) }}
                          </template>
                        </div>
                        <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                          <small
                            class="font-s-12"
                            style="font-weight: normal"
                          >
                            <del class="text-red">
                              {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                              <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                {{ parseFloat(orderItem.sale_price) | numFormat("0,0") }}
                              </template>
                              <template v-else>{{ parseFloat(orderItem.sale_price) | numFormat(num_format) }}</template>
                            </del>
                          </small>
                        </template>
                      </template>
                      <template v-else>
                        0
                      </template>
                    </div>
                    <div class="order-item_amount oipp text-ellipsis font-weight-normal font-s-14">
                      <template v-if="orderItem.sale_price">
                        <div :class="{'text-red': calcDiscountPrice(orderItem) <= 0}">
                          {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                          <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                            {{
                              calculateAmountTotal(calcDiscountPrice(orderItem), orderItem.pivot.quantity) |
                                numFormat("0,0")
                            }}
                          </template>
                          <template v-else>
                            {{ calcDiscountPrice(orderItem) | numFormat(num_format) }}
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        0
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="row mx-0 has-border-t">
        <div class="col-xs-12 no-padding user-select-none pt-3">
          <div class="list-order-header no-border">
            <div class="w-100 h-100">
              <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                <div class="block-total-l text-capitalize">
                  <strong>{{ $t("label.subTotal") }}</strong>
                </div>
                <div class="block-total-r">
                  <div class="text-bold">
                    {{ currency ? currency.symbol : "" }}
                    <template v-if="currency && currency.code === 'KHR'">
                      {{ subTotal | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ subTotal | numFormat(num_format) }}
                    </template>
                  </div>
                  <div class="text-bold font-s-14 money-equivalent-color">
                    {{ currency.code === "USD" ? "៛" : "$" }}
                    <template v-if="currency && currency.code !== 'KHR'">
                      {{ moneyEquivalent(currency.code, subTotal) | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ moneyEquivalent(currency.code, subTotal) | numFormat(num_format) }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <slot />
        <div
          v-if="shopTax"
          class="col-xs-12 no-padding user-select-none"
        >
          <div class="list-order-header no-border">
            <div class="w-100 h-100">
              <div class="list-total-amount px-0 pt-0 pb-3 font-s-14">
                <span class="block-total-l"><strong>{{ shopTax["name_" + $i18n.locale] }} (+)</strong></span>
                <span class="block-total-r">{{ shopTax.amount_percentage | numFormat(num_format) }}%</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="discountValue && discountValue > 0"
          class="col-xs-12 no-padding user-select-none"
        >
          <div class="list-order-header no-border">
            <div class="w-100 h-100">
              <div class="list-total-amount px-0 pt-0 pb-3 font-s-14">
                <span class="block-total-l"><strong>{{ $t("label.discount") }} (-)</strong></span>
                <span class="block-total-r">
                  <template v-if="dicIsPercentage">
                    {{ discountValue }}%
                  </template>
                  <template v-else>
                    <template v-if="currency && currency.code === 'KHR'">
                      {{ currency ? currency.symbol : "" }} {{ discountValue | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ currency ? currency.symbol : "" }} {{ discountValue | numFormat("0,0.00") }}
                    </template>
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="membershipDiscount && membershipDiscount > 0"
          class="col-xs-12 no-padding user-select-none"
        >
          <div class="list-order-header no-border">
            <div class="w-100 h-100">
              <div class="list-total-amount px-0 pt-0 pb-3 font-s-14">
                <span class="block-total-l"><strong>{{ $t("label.membershipDiscount") }} (-)</strong></span>
                <span class="block-total-r">{{ membershipDiscount }}%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 no-padding user-select-none">
          <div class="list-order-header no-border">
            <div class="w-100 h-100">
              <div class="list-total-amount px-0 pt-0 pb-3 font-s-14">
                <span class="block-total-l text-capitalize"><strong>{{ $t("label.exchangeRate") }}</strong></span>
                <span
                  v-if="exchangeRate"
                  class="block-total-r"
                >
                  $1 = ៛ {{ exchangeRate.value | numFormat("0,0") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{'hidden-xs': hideInXs}"
          class="no-padding user-select-none"
        >
          <div class="list-order-header no-border">
            <div class="w-100 h-100">
              <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                <div class="block-total-l text-capitalize">
                  <div class="font-s-20">
                    <strong>{{ $t("label.amountTotal") }}</strong>
                  </div>
                  <div style="line-height: 0.8">
                    <small class="font-s-14">({{ $t("label.includeVat") }})</small>
                  </div>
                </div>
                <div class="block-total-r">
                  <div
                    :class="parseInt(amountTotal) <= 0 ? 'text-red' : 'pos-menubar_active'"
                    class="font-s-20"
                  >
                    {{ currency ? currency.symbol : "" }}
                    <template v-if="currency && currency.code === 'KHR'">
                      {{ amountTotal | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ amountTotal | numFormat(num_format) }}
                    </template>
                  </div>
                  <div
                    :class="parseInt(amountTotal) <= 0 ? 'text-red' : 'pos-menubar_active'"
                    class="font-s-18"
                  >
                    {{ currency.code === "USD" ? "៛" : "$" }}
                    <template v-if="currency && currency.code !== 'KHR'">
                      {{ moneyEquivalent(currency.code, amountTotal) | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ moneyEquivalent(currency.code, amountTotal) | numFormat(num_format) }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="balance && (parseFloat(balance.paid_reil) > 0 || parseFloat(balance.paid_dollar) > 0)"
          class="col-xs-12 no-padding user-select-none"
        >
          <div class="list-order-header no-border">
            <div class="w-100 h-100">
              <div class="list-total-amount amount align-items-top font-s-14 px-0 pt-0 pb-3">
                <div class="block-total-l user-select-none text-capitalize">
                  <strong>{{ $t("label.amountPaid") }}</strong>
                </div>
                <div class="block-total-r">
                  <div class="text-bold">
                    {{ currency.code === "KHR" ? "៛" : "$" }}
                    {{ currency && currency.code === "KHR" ? balance.paid_reil : balance.paid_dollar }}
                  </div>
                  <div class="text-bold">
                    {{ currency.code !== "KHR" ? "៛" : "$" }}
                    {{ currency && currency.code === "KHR" ? balance.paid_dollar : balance.paid_reil }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="parseFloat(amountChange) > 0"
          class="col-xs-12 no-padding user-select-none"
        >
          <div class="list-order-header no-border">
            <div class="w-100 h-100">
              <div class="list-total-amount amount align-items-top font-s-14 px-0 pt-0 pb-0">
                <div class="block-total-l user-select-none text-capitalize">
                  <strong>{{ $t("label.amountChange") }}</strong>
                </div>
                <div class="block-total-r">
                  <div class="text-bold">
                    {{ currency ? currency.symbol : "" }}
                    <template v-if="currency && currency.code === 'KHR'">
                      {{ amountChange | numFormat("0,0") }}
                    </template>
                    <template v-else>
                      {{ amountChange | numFormat(num_format) }}
                    </template>
                  </div>
                  <div class="text-bold font-s-14 money-equivalent-color">
                    {{ currency.code === "USD" ? "៛" : "$" }}
                    <template v-if="currency && currency.code !== 'KHR'">
                      {{ moneyEquivalent(currency.code, amountChange) | numFormat("0,0") }})
                    </template>
                    <template v-else>
                      {{ moneyEquivalent(currency.code, amountChange) | numFormat(num_format) }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "DisplayOrderInfo",
  props: {
    hideInXs: {
      type: Boolean,
      default: true
    },
    products: {
      type: Array,
      default: () => {
        return []
      }
    },
    amountTotal: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: 0
    },
    subTotal: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: 0
    },
    shopTax: {
      type: Object,
      default: () => {
        return null
      }
    },
    exchangeRate: {
      type: Object,
      default: () => {
        return null
      }
    },
    discountValue: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    },
    dicIsPercentage: {
      type: Boolean,
      default: false
    },
    membershipDiscount: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    },
    balance: {
      type: Object,
      default: () => {
        return null
      }
    },
    amountChange: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat
    })
  },
  methods: {
    calculateAmountTotal (price, quantity) {
      return parseFloat(price) * parseFloat(quantity)
    },
    calcDiscountPrice (product) {
      if (product.pivot && product.pivot.discount > 0) {
        if (product.pivot.is_percentage) {
          const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
          const result = parseFloat(product.sale_price) - amount_discount
          return parseFloat(result).toFixed(2)
        } else {
          // Convert price of product currency to shop currency.
          const val = this.exchangeMoney(product.currency.code, this.currency.code, parseFloat(product.sale_price))
          const amount_discount = parseFloat(product.pivot.discount)
          let result = parseFloat(val) - amount_discount
          // Convert price back to product currency.
          result = this.exchangeMoney(this.currency.code, product.currency.code, parseFloat(result))
          return parseFloat(result).toFixed(2)
        }
      }
      return parseFloat(product.sale_price).toFixed(2)
    }
  }
}
</script>

<style scoped>
@import "../../../../assets/css/displayToCustomer.css";
</style>
