export const state = () => {
  return {
    adjust_product_uuid: null,
    reduce_product_uuid: null,
    product_has_inventory: false,
    product_active: false
  }
}

export const getters = {
  adjust_product_uuid: state => state.adjust_product_uuid,
  reduce_product_uuid: state => state.reduce_product_uuid,
  product_has_inventory: state => state.product_has_inventory,
  product_active: state => state.product_active
}

export const mutations = {
  setAdjustProductUuid (state, adjust_product_uuid) {
    state.adjust_product_uuid = adjust_product_uuid
  },
  setReduceProductUuid (state, reduce_product_uuid) {
    state.reduce_product_uuid = reduce_product_uuid
  },
  setProductHasInventory (state, product_has_inventory) {
    state.product_has_inventory = product_has_inventory
  },
  setProductActive (state, product_active) {
    state.product_active = product_active
  }
}

export const actions = {
  setAdjustProductUuid ({ commit }, adjust_product_uuid) {
    commit("setAdjustProductUuid", adjust_product_uuid)
  },
  setReduceProductUuid ({ commit }, reduce_product_uuid) {
    commit("setReduceProductUuid", reduce_product_uuid)
  },
  setProductHasInventory ({ commit }, product_has_inventory) {
    commit("setProductHasInventory", product_has_inventory)
  },
  setProductActive ({ commit }, product_active) {
    commit("setProductActive", product_active)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
