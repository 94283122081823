<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div
          :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('value') ? 'has-error' : '')"
        >
          <label class="required">{{ $t("fields.label.valueRiel") }}</label>
          <input
            :placeholder="$t('fields.placeholder.valueRiel')"
            class="form-control"
            type="number"
            v-model="exchange.value"
          >
          <template v-if="message_validation !== null && message_validation.hasOwnProperty('value')">
            <small class="help-block">{{ message_validation["value"][0] }}</small>
          </template>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-6">
        <div class="form-group">
          <label>
            <input
              name="active"
              type="checkbox"
              v-model="exchange.active"
            > {{ $t("fields.label.active") }}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group text-right">
          <template v-if="this.$route.name !== 'show-exchange'">
            <button
              :class="`btn btn-${this.$route.name === 'edit-exchange' ? 'info' : 'primary'}`"
              @click="storeExchange"
            >
              <i class="fas fa-check-circle" />
              <template v-if="this.$route.name === 'edit-exchange'">
                {{ $t("button.update") }}
              </template>
              <template v-else>
                {{ $t("button.save") }}
              </template>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>export default {
  name: "ExchangeForm",
  props: {
    columns: {
      type: String,
      default: "col-md-12"
    }
  },
  data () {
    return {
      exchange: {
        shop_id: "",
        name_en: "",
        name_km: "",
        value: "",
        currency: "",
        active: false
      },
      config: this.$config,
      message_validation: null,
      currencies: []
    }
  },
  methods: {
    getExchange () {
      this.$axios.post(this.$config.base_url + "/api/exchange/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          this.exchange = response.data.data
        }
        if (response.data.code === 0) {
          this.$router.push({ name: "list-exchange" })
          this.$toastr("error", response.data.message, "Get exchange rate")
        }
      })
    },
    storeExchange () {
      this.$isLoading(true)
      this.exchange.shop_id = this.get_shop.id

      this.$axios.post(this.$config.base_url + "/api/exchange/store", this.exchange)
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1) {
            this.$router.push({ name: "list-exchange" })
            if (this.exchange.hasOwnProperty("id") && this.exchange.id !== null) {
              this.$anotify(this.$t("model.exchangeRate"), "update")
            } else {
              this.$anotify(this.$t("model.exchangeRate"))
            }
          } else {
            this.message_validation = response.data.message
          }
        })
    },
    getCurrencyOptions: function () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/exchange/get-currency-options")
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1 && response.data.data !== null) {
            this.currencies = response.data.data
            if (this.currencies.length > 0 && !this.$route.params.hasOwnProperty("uuid")) {
              this.currencies.forEach((item) => {
                if (item.code === "USD") {
                  this.exchange.currency = item
                }
              })
            }
          }
        })
    }
  },
  mounted () {
    this.getCurrencyOptions()
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getExchange()
    }
    if (this.$route.name === "show-exchange") {
      $("input, select > option").attr("disabled", true)
    }
  }
}
</script>

<style scoped>
button {
  margin-right: 5px;
}
</style>
