var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.product_uuid)?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-default btn-sm dropdown-toggle tw-rounded-md",attrs:{"id":"dropdownMenu1","aria-expanded":"true","aria-haspopup":"true","data-toggle":"dropdown","type":"button"}},[_vm._v(" "+_vm._s(_vm.$t("table.actions"))+" "),_c('span',{staticClass:"caret"})]),_c('ul',{staticClass:"dropdown-menu dropdown-menu-right tw-rounded-md",attrs:{"aria-labelledby":"dropdownMenu1"}},[_c('DropdownItem',{attrs:{"icon":_vm.product_active ? 'fas fa-stop' : 'fas fa-play',"label":_vm.product_active ? _vm.$t('string.inactiveProduct') :_vm.$t('string.activeProduct')},on:{"click":_vm.toggleProduct}}),_c('DropdownItem',{attrs:{"label":_vm.$t('button.editProduct'),"icon":"fa fa-edit"},on:{"click":function($event){return _vm.$router.push({
        name: 'edit-product',
        params: {
          uuid: _vm.product_uuid
        }
      })}}}),(_vm.product_has_inventory)?[_c('DropdownItem',{attrs:{"label":_vm.$t('button.adjust_stock'),"icon":"fa fa-plus-circle"},on:{"click":_vm.openAdjustStockModal}}),_c('DropdownItem',{attrs:{"label":_vm.$t('button.reduce_stock'),"icon":"fa fa-minus-circle"},on:{"click":_vm.openReduceStockModal}})]:[_c('DropdownItem',{attrs:{"label":_vm.$t('button.track_inventory'),"icon":"fas fa-warehouse"},on:{"click":_vm.trackInventory}})],_c('DropdownItem',{attrs:{"label":_vm.$t('button.print'),"icon":"fa fa-print"},on:{"click":function($event){return _vm.$router.push({
        name: 'print-barcode',
        params: {
          uuid: _vm.product_uuid
        }
      })}}}),_c('li',{staticClass:"divider tw-m-1",attrs:{"role":"separator"}}),_c('DropdownItem',{staticClass:"tw-bg-red-500 tw-text-white hover:tw-bg-red-600",attrs:{"label":_vm.$t('button.deleteProduct'),"icon":"fa fa-trash"},on:{"click":_vm.deleteProduct}})],2),_c('ProductAdjustStockModal',{on:{"fetchProductDetail":function($event){return _vm.$emit('fetchProductDetail')}}}),_c('ProductReduceStockModal',{on:{"fetchProductDetail":function($event){return _vm.$emit('fetchProductDetail')}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }