import Subscription from "@/views/settings/subscription/Index"
import List from "@/views/settings/subscription/List"

export const subscriptionRouters = {
  path: "subscription",
  component: Subscription,
  children: [
    {
      path: "",
      name: "list-subscription",
      component: List
    }
  ]
}
