<template>
  <div class="row">
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("user.resetPassword") }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <div
            :class="{'has-error': validations !== null && validations.hasOwnProperty('password')}"
            class="form-group row"
          >
            <label class="col-md-4 control-label required">{{ $t("user.field.label.password") }}</label>
            <div class="col-md-8">
              <input
                v-model="user.password"
                :placeholder="$t('user.field.placeholder.password')"
                class="form-control"
                name="password"
                type="password"
              >
              <span v-if="validations !== null && validations.hasOwnProperty('password')">{{
                validations.password[0]
              }}</span>
            </div>
          </div>
          <div
            :class="{'has-error': validations !== null && validations.hasOwnProperty('password_confirm')}"
            class="form-group row"
          >
            <label class="col-md-4 control-label required">{{ $t("user.field.label.passwordConfirmation") }}</label>
            <div class="col-md-8">
              <input
                v-model="user.password_confirmation"
                :placeholder="$t('user.field.placeholder.passwordConfirmation')"
                class="form-control"
                name="password_confirmation"
                type="password"
              >
              <span v-if="validations !== null && validations.hasOwnProperty('password_confirm')">{{
                validations.password_confirm[0]
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
              <button
                class="btn btn-info"
                @click="onChangePassword"
              >
                <i class="fas fa-sync-alt" /> {{ $t("button.resetRassword") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserResetPassword",
  metaInfo () {
    return {
      title: this.$t("user.resetPassword"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      user: {
        password: "",
        password_confirmation: ""
      },
      validations: null
    }
  },
  methods: {
    onChangePassword () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/user/reset-password", {
        user_uuid: this.$route.params.uuid,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation
      }).then((response) => {
        if (response.data.code === 1) {
          this.$router.push({ name: "list-user" })
        } else {
          this.validations = response.data.message
        }
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
