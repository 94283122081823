import { render, staticRenderFns } from "./AsoraCard.vue?vue&type=template&id=48854063&scoped=true&"
import script from "./AsoraCard.vue?vue&type=script&lang=js&"
export * from "./AsoraCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48854063",
  null
  
)

export default component.exports