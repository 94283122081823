<template>
  <div />
</template>

<script>

export default {
  name: "SwalDeleteAction",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    routeToDelete: {
      type: String
    },
    modelType: {
      type: String,
      default: "record"
    }
  },
  data () {
    return {
      config: this.$config
    }
  },
  methods: {
    deleteItem (params) {
      this.$axios.post(this.$config.base_url + this.routeToDelete, {
        ...params
      }).then((response) => {
        if (response.data.code === 1) {
          this.$aswaldelete(this.modelType)
          this.$emit("refreshListingTable")
        }

        if (response.data.code === 0) {
          if (typeof (response.data.message) === "string") {
            this.$toastr("error", response.data.message, "Notification")
          }
        }
      })
    },
    onClickDelete (uuid) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.deleteItem(uuid)
      })
    }
  }
}
</script>

<style scoped>

</style>
