<template>
  <div>
    <div class="asora-list-data-header">
      <div class="block-search-box">
        <div class="box-search box-search-mb w-100">
          <div class="box-search-icon margin-l-5">
            <i class="fas fa-search" />
          </div>
          <input
            v-model="search_query"
            :placeholder="$t('label.search')"
            class="pos-input-search pos-input-lg no-border"
            type="text"
          >
        </div>
      </div>
    </div>
    <div class="asora-list-data-body scrollbar">
      <template v-if="onLoading">
        <div
          class="d-flex align-items-center justify-content-center on-loading-layout"
          style="height: 50vh;"
        >
          <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
        </div>
      </template>
      <template v-else>
        <template v-if="list_data && Array.isArray(list_data) && list_data.length">
          <template v-for="(item, key) in list_data">
            <div
              :key="key"
              v-if="!item.is_custom_product && !(item.children && item.children.length > 0)"
              class="list-data-item rounded position-relative"
            >
              <div class="list-data-item-image">
                <template v-if="item.images.length > 0">
                  <div
                    :style="`background-image: url('${$config.base_url}/${replaceImgUrl(item.images[0].src, 'thumbnail')}')`"
                    class="block-image block-image-w-product"
                  />
                </template>
                <template v-else>
                  <div
                    :style="`background-image: url('${defaultImg}')`"
                    class="block-image block-image-w-product image_w-65"
                  />
                </template>
              </div>
              <div
                class="list-data-item-content user-select-none"
                style="padding-top: 8px"
              >
                <div class="text-ellipsis font-s-16">
                  {{ item.name }}
                </div>
                <div
                  v-if="item.product_variants && Array.isArray(item.product_variants) && item.product_variants.length"
                  class="text-ellipsis slash"
                >
                  <span
                    v-for="(variant, product_variants_key) in item.product_variants"
                    :key="product_variants_key"
                  >{{ variant.pivot.value }}</span>
                </div>
                <div
                  v-if="item.category"
                  class="text-ellipsis text-muted font-s-12"
                >
                  {{ item.category["name_" + $i18n.locale] }}
                </div>
                <div
                  v-if="item.barcode"
                  class="text-ellipsis"
                >
                  <i class="fas fa-barcode" /> {{ item.barcode }}
                </div>
                <div
                  v-if="item.has_inventory"
                  class="text-ellipsis"
                >
                  <span :class="{'text-red': item.amount_in_stock < item.stock_alert_level }">
                    <i class="fas fa-shopping-basket" />
                    {{ item.amount_in_stock }} {{ item.unit ? item.unit["name_" + $i18n.locale] : "" }}
                  </span>
                  <span class="text-warning">
                    ( <i class="fas fa-exclamation-circle" />
                    {{ item.stock_alert_level }} {{ item.unit ? item.unit["name_" + $i18n.locale] : "" }} )
                  </span>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="list-data-no_data">
            {{ $t("label.noData") }}
          </div>
        </template>
        <infinite-loading
          :identifier="infiniteId"
          spinner="spiral"
          @infinite="infiniteHandler"
        >
          <div slot="no-more" />
        </infinite-loading>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce"
import { mapState } from "vuex"

export default {
  name: "StockAlertControlDataTableSm",
  props: {
    windowWidth: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: null
    }
  },
  data () {
    return {
      api_get_data: "/api/product/get-list-data-inventory-orders",
      search_query: null,
      infiniteId: +new Date(),
      list_data: [],
      number_per_page: 50,
      onLoading: true
    }
  },
  watch: {
    search_query () {
      this.onLoading = true
      if (!this.awaitingSearch) {
        if (this.time_out) {
          clearTimeout(this.time_out)
        }
        this.time_out = setTimeout(() => {
          this.search()
          this.awaitingSearch = false
        }, 2000)
      }
      this.awaitingSearch = true
    },
    windowWidth: function () {
      if (this.windowWidth && this.windowWidth < 769 && this.list_data.length === 0) {
        this.getListData(1)
      }
    }
  },
  computed: {
    ...mapState({
      shop: state => state.shop.shop,
      number_format: state => state.shop.numFormat
    })
  },
  mounted () {
    if (this.windowWidth && this.windowWidth < 769) {
      this.getListData(1)
    }
  },
  methods: {
    search () {
      this.getListData(1)
    },
    getListData (page = null) {
      this.page = 1
      if (page) {
        this.page = page
      }
      this.list_data = []
      this.infiniteId += 1
      this.infiniteHandler()
    },
    infiniteHandler: debounce(function ($state) {
      this.$axios
        .post(this.$config.base_url + this.api_get_data, {
          page: this.page,
          shop_uuid: this.shop.uuid,
          search_query: this.search_query,
          number_per_page: this.number_per_page
        })
        .then(({ data }) => {
          if (!(this.page > data.total_pages)) {
            this.page += 1
            this.list_data.push(...data.data)
            if ($state) {
              $state.loaded()
            }
          } else {
            $state.loaded()
            $state.complete()
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.onLoading = false
        })
    }, 500)
  }
}
</script>

<style scoped>
@import "./../../../../assets/css/V2/list-data-table-sm.css";
</style>
