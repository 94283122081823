<template>
  <div
    :style="`height: ${height}vh;`"
    class="no-any-records"
  >
    <div class="d-block">
      <img
        v-if="image"
        :src="image"
        class="no_result-image"
        alt="No Result"
      >
      <p class="text-capitalize text-center no_result-title">
        <strong>{{ $t("label.no_result") }}</strong>
      </p>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "NoResult",
  props: {
    height: {
      type: Number,
      default: 80
    },
    image: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.no-any-records {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_result-image {
  width: 210px;
  margin-bottom: 20px;
}

.no_result-title {
  font-size: 20px;
}
</style>
