<template>
  <div class="d-flex flex-column w-100 bg-white">
    <div class="d-flex h-100">
      <div class="tabBlock-pane px-0 pb-0 w-100">
        <div class="d-flex flex-column h-100">
          <div class="shadow-bottom z-index-2">
            <div class="row mx-0">
              <div class="col-xs-12">
                <div class="form-group user-select-none">
                  <label class="title-section">
                    {{ $t("list.holdSale") }}
                  </label>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="tw-flex tw-pb-5">
                  <div class="input-group tw-w-full md:tw-w-2/5">
                    <div class="box-search">
                      <div class="box-search-icon margin-l-5">
                        <i class="fas fa-search" />
                      </div>
                      <input
                        v-model="search_query"
                        :placeholder="$t('label.search') + '...'"
                        class="pos-input-search pos-input-lg no-border"
                        type="text"
                        @keyup="getParkOrderList(1)"
                      >
                    </div>
                    <div class="input-group-btn btn-default rounded-br rounded-tr">
                      <button
                        :title="$t('label.filter')"
                        class="btn btn-default btn-lg no-margin"
                        data-placement="top"
                        data-target="#filterParkedSale"
                        data-toggle="modal"
                      >
                        <i class="fas fa-filter" />
                        <span class="hide-label-sm ml-2">{{ $t("label.filter") }}</span>
                      </button>
                    </div>
                  </div>
                  <div class="tw-hidden md:tw-block">
                    <div
                      class="btn-group btn-group-lg ml-2"
                      role="group"
                    >
                      <button
                        :class="{'btn-active': park_sale_view_mode === 'grid'}"
                        :title="$t('label.viewAsGrid')"
                        class="btn btn-default outline-none"
                        type="button"
                        @click="changeViewModeParkSale(grid_mode)"
                      >
                        <i class="fas fa-th" />
                      </button>
                      <button
                        :class="{'btn-active': park_sale_view_mode === 'list'}"
                        :title="$t('label.viewAsList')"
                        class="btn btn-default outline-none"
                        type="button"
                        @click="changeViewModeParkSale(list_mode)"
                      >
                        <i class="fas fa-th-list" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group no-margin font-s-18 tw-pb-3">
                  <div
                    v-if="parked_sale_filter.start_date || parked_sale_filter.end_date"
                    class="minus"
                  >
                    <label v-if="parked_sale_filter.start_date">
                      {{ $moment(parked_sale_filter.start_date).locale($i18n.locale).format(dateFormatLocaleNoTime) }}
                    </label>
                    <label v-if="parked_sale_filter.end_date">
                      {{ $moment(parked_sale_filter.end_date).locale($i18n.locale).format(dateFormatLocaleNoTime) }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="onLoading">
            <div class="d-flex align-items-center justify-content-center on-loading-layout">
              <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
            </div>
          </template>
          <template v-else>
            <div class="flex-1 d-flex h-100 overflow-hidden">
              <div class="list-park-order d-flex flex-column scrollbar user-select-none w-100">
                <div class="h-100">
                  <template v-if="!(park_order_list.length > 0)">
                    <no-any-records :height="50" />
                  </template>
                  <template v-else>
                    <template v-if="park_sale_view_mode === grid_mode && windowWidth > 767">
                      <div class="hidden-xs">
                        <div class="row mx-0">
                          <template v-for="(item, key) in park_order_list">
                            <div
                              :key="key"
                              class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-5"
                            >
                              <div class="order-card">
                                <div class="order-card-header pb-0">
                                  <div class="d-flex align-items-center justify-content-between has-border-b pb-3">
                                    <div>#{{ item.order_number }}</div>
                                    <div
                                      v-if="item.created_at"
                                      class="order-created-date"
                                    >
                                      <i class="fas fa-clock" />
                                      {{ $moment(item.created_at).format("MMM DD HH:mm") }}
                                    </div>
                                  </div>
                                </div>
                                <div class="order-card-body">
                                  <template v-for="(product, product_key) in item.products">
                                    <div
                                      class="row no-margin product-item"
                                      :key="product_key"
                                      v-if="product_key <= 3"
                                    >
                                      <div class="col-xs-6 pl-0 text-ellipsis">
                                        {{ product.name }}
                                      </div>
                                      <div class="col-xs-6 pr-0">
                                        <div class="d-flex justify-content-between">
                                          <div>X {{ product.pivot.quantity }}</div>
                                          <div>
                                            <span v-if="product.currency">{{ product.currency.symbol }}</span>
                                            <template v-if="product.is_custom_product">
                                              <span>
                                                <template v-if="product.currency && product.currency.code === 'KHR'">
                                                  {{ parseFloat(product.custom_product_price) | numFormat("0,0") }}
                                                </template>
                                                <template v-else>
                                                  {{ parseFloat(product.custom_product_price) | numFormat(num_format) }}
                                                </template>
                                              </span>
                                            </template>
                                            <template v-else>
                                              <span>
                                                <template v-if="product.currency && product.currency.code === 'KHR'">
                                                  {{ parseFloat(product.sale_price) | numFormat("0,0") }}
                                                </template>
                                                <template v-else>
                                                  {{ parseFloat(product.sale_price) | numFormat(num_format) }}
                                                </template>
                                              </span>
                                            </template>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-if="item.products.length > 4">
                                    <div class="row product-item pb-3">
                                      <div class="col-xs-6 col-lg-7 text-ellipsis">
                                        {{ $t("label.more") }}
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <div class="order-card-footer pt-1">
                                  <div class="has-border-t">
                                    <div class="d-flex align-items-center justify-content-between w-100 pt-3 pb-3">
                                      <div class="text-bold">
                                        {{ $t("recent_sell.label.customer") }}
                                      </div>
                                      <div>{{ item.customer ? item.customer.name : "-" }}</div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between w-100 pb-3">
                                      <div class="text-bold">
                                        {{ $t("label.soldBy") }}
                                      </div>
                                      <strong>
                                        {{ item.user && item.user.length ? item.user[0].full_name : "-" }}
                                      </strong>
                                    </div>
                                  </div>
                                  <div
                                    class="has-border-t text-right"
                                    style="padding-top: 10px"
                                  >
                                    <div class="d-flex align-items-center justify-content-end">
                                      <div class="btn-card-order mr-2">
                                        <button
                                          class="btn btn-lg btn-default"
                                          @click="deleteOrder(item.uuid)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </button>
                                      </div>
                                      <div class="btn-card-order">
                                        <button
                                          class="btn btn-lg btn-primary mr-0"
                                          @click="openToCart(item.uuid)"
                                        >
                                          <i class="fas fa-cart-plus mr-2" />
                                          <span class="ml-2">{{ $t("label.continueSell") }}</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                        <infinite-loading
                          :identifier="infiniteParkOrderListId"
                          spinner="spiral"
                          @infinite="infiniteHandlerParkOrderList"
                        >
                          <div slot="no-more" />
                          <div slot="no-results">
                            {{ $t("label.no_results_message") }}
                          </div>
                          <div
                            slot="error"
                            slot-scope="{ trigger }"
                          >
                            Error message, click <a
                              href="javascript:void(0)"
                              @click="trigger"
                            >here</a> to retry
                          </div>
                        </infinite-loading>
                      </div>
                    </template>
                    <template v-else>
                      <div class="park-sale-list">
                        <div class="tabBlock-pane pt-0 d-flex flex-column">
                          <div class="flex-1 overflow-y-auto h-100 scrollbar">
                            <div class="user-select-none sugg-customer-each layout-content-recent-sell-list px-3 py-3">
                              <div class="layout-content-recent-sell-list font-s-16">
                                <div class="order-label-number text-bold d-flex align-items-center">
                                  <div class="w-100 text-ellipsis text-capitalize">
                                    {{ $t("label.orderNo") }}
                                  </div>
                                </div>
                                <div class="order-customer-name text-bold w-25 text-center text-capitalize">
                                  {{ $t("label.customer") }}
                                </div>
                                <div class="order-cashier-name text-bold w-30 text-center text-capitalize">
                                  {{ $t("label.soldBy") }}
                                </div>
                                <div class="has-border-t text-bold w-40 text-center">
                                  {{ $t("coupon.table.actions") }}
                                </div>
                              </div>
                            </div>
                            <template v-for="(item, key) in park_order_list">
                              <div
                                :key="key"
                                :title="'Order Date: ' + $moment(item.created_at).locale($i18n.locale).format(dateFormatLocale)"
                                class="user-select-none"
                              >
                                <div class="sugg-customer-each px-3 py-3">
                                  <div class="layout-content-recent-sell-list font-s-16">
                                    <div class="order-label-number d-block">
                                      <div class="w-100 text-ellipsis">
                                        #{{ item.order_number }}
                                      </div>
                                    </div>
                                    <div class="order-customer-name d-block text-center w-25">
                                      <template v-if="item.customer">
                                        <div>{{ item.customer.name }}</div>
                                        <div
                                          v-if="item.customer.tel"
                                          class="font-s-14"
                                        >
                                          {{ item.customer.tel }}
                                        </div>
                                      </template>
                                      <template v-else>
                                        -
                                      </template>
                                    </div>
                                    <div class="order-cashier-name d-block text-center w-30">
                                      <div>{{ item.user && item.user.length ? item.user[0].full_name : "-" }}</div>
                                      <div class="w-100 text-ellipsis font-s-14">
                                        {{ $moment(item.created_at).locale($i18n.locale).format("MMM DD HH:mm") }}
                                      </div>
                                    </div>
                                    <div class="has-border-t d-block w-40 text-right">
                                      <button
                                        class="btn btn-lg btn-default mb-2"
                                        @click="deleteOrder(item.uuid)"
                                      >
                                        <i class="fas fa-trash-alt" />
                                      </button>
                                      <button
                                        :title="$t('label.continueSell')"
                                        class="btn btn-lg btn-primary mr-0 mb-2"
                                        @click="openToCart(item.uuid)"
                                      >
                                        <i class="fas fa-cart-plus mr-2" />
                                        <span class="ml-2">{{ $t("label.continueSell") }}</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="layout-content-recent-sell-card">
                                    <div class="row no-margin">
                                      <div class="col-sm-8 no-padding">
                                        <div class="row no-margin">
                                          <div class="col-xs-6 col-md-4 no-padding">
                                            <div class="list-order-header no-border font-s-16">
                                              <div class="w-100 h-100">
                                                <div class="list-total-amount px-0 py-1">
                                                  <div class="text-capitalize">
                                                    {{ $t("recent_sell.label.orderNumber") }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-xs-6 col-md-8 no-padding">
                                            <div class="list-order-header no-border font-s-16">
                                              <div class="w-100 h-100">
                                                <div class="list-total-amount px-0 py-1">
                                                  <div class="text-capitalize">
                                                    <strong>#{{ item.order_number }}</strong>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row no-margin">
                                          <div class="col-xs-6 col-md-4 no-padding">
                                            <div class="list-order-header no-border font-s-16">
                                              <div class="w-100 h-100">
                                                <div class="list-total-amount px-0 py-1">
                                                  <div class="text-capitalize">
                                                    {{ $t("recent_sell.label.orderDate") }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-xs-6 col-md-8 no-padding">
                                            <div class="list-order-header no-border font-s-16">
                                              <div class="w-100 h-100">
                                                <div class="list-total-amount px-0 py-1">
                                                  <div class="text-capitalize">
                                                    {{
                                                      $moment(item.created_at).locale($i18n.locale).format("MMM DD HH:mm")
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row no-margin">
                                          <div class="col-xs-6 col-md-4 no-padding">
                                            <div class="list-order-header no-border font-s-16">
                                              <div class="w-100 h-100">
                                                <div class="list-total-amount px-0 py-1">
                                                  <div class="text-capitalize">
                                                    {{ $t("label.cashier") }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-xs-6 col-md-8 no-padding">
                                            <div class="list-order-header no-border font-s-16">
                                              <div class="w-100 h-100">
                                                <div class="list-total-amount px-0 py-1">
                                                  <div class="text-capitalize">
                                                    <strong>{{
                                                      item.user && item.user.length ? item.user[0].full_name : "-"
                                                    }}</strong>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <template v-if="item.customer">
                                          <div class="row no-margin">
                                            <div class="col-xs-6 col-md-4 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      {{ $t("recent_sell.label.customer") }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-xs-6 col-md-8 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      <strong>{{ item.customer.name }}</strong>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row no-margin">
                                            <div class="col-xs-6 col-md-4 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      {{ $t("label.phoneNumber") }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-xs-6 col-md-8 no-padding">
                                              <div class="list-order-header no-border font-s-16">
                                                <div class="w-100 h-100">
                                                  <div class="list-total-amount px-0 py-1">
                                                    <div class="text-capitalize">
                                                      <strong>{{ item.customer.tel }}</strong>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </div>
                                      <div class="col-sm-4 no-padding">
                                        <div class="row no-margin">
                                          <div class="col-xs-12 no-padding">
                                            <div class="has-border-t">
                                              <div class="d-flex align-items-center justify-content-end">
                                                <div class="btn-card-order mr-2">
                                                  <button
                                                    class="btn btn-lg btn-default"
                                                    @click="deleteOrder(item.uuid)"
                                                  >
                                                    <i class="fas fa-trash-alt" />
                                                  </button>
                                                </div>
                                                <div class="btn-card-order">
                                                  <button
                                                    class="btn btn-lg btn-primary mr-0"
                                                    @click="openToCart(item.uuid)"
                                                  >
                                                    <i class="fas fa-cart-plus mr-2" />
                                                    <span class="ml-2">{{ $t("label.continueSell") }}</span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                        <infinite-loading
                          :identifier="infiniteParkOrderListId"
                          spinner="spiral"
                          @infinite="infiniteHandlerParkOrderList"
                        >
                          <div slot="no-more" />
                          <div slot="no-results">
                            {{ $t("label.no_results_message") }}
                          </div>
                          <div
                            slot="error"
                            slot-scope="{ trigger }"
                          >
                            Error message, click <a
                              href="javascript:void(0)"
                              @click="trigger"
                            >here</a> to retry
                          </div>
                        </infinite-loading>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <ParkedSaleFilter @refreshList="getParkOrderList(1)" />
  </div>
</template>

<script>
import { debounce } from "debounce"
import NoAnyRecords from "@/components/NoAnyRecords"
import { mapState } from "vuex"
import ParkedSaleFilter from "@/views/order/V2/_components/ParkedSaleFilter"

export default {
  name: "ParkOrderListV2",
  metaInfo () {
    return {
      title: this.$t("list.holdSale"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    ParkedSaleFilter,
    NoAnyRecords
  },
  data () {
    return {
      search_query: null,
      park_order_list: [],
      userRoles: null,
      page: 1,
      infiniteParkOrderListId: +new Date(),
      number_per_page: 30,
      onLoading: true,
      park_sale_view_mode: "grid",
      grid_mode: "grid",
      list_mode: "list",
      windowWidth: null
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      shop: state => state.shop.shop,
      parked_sale_filter: state => state.sell.parked_sale_filter,
      shop_currency: state => state.shop.currency
    })
  },
  mounted () {
    this.userRoles = [
      { name: "owner" },
      { name: "waiter" },
      { name: "cashier" },
      { name: "reporter" }
    ]
    if (!localStorage.hasOwnProperty("park_sale_view_mode")) {
      localStorage.setItem("park_sale_view_mode", this.grid_mode)
      this.park_sale_view_mode = this.grid_mode
    } else {
      this.park_sale_view_mode = localStorage.getItem("park_sale_view_mode")
    }
    this.$store.dispatch("sell/setParkedSaleFilter", {
      start_date: null,
      end_date: null
    })
    this.getParkOrderList(1)
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
    })
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    getParkOrderList (page = null) {
      this.onLoading = true
      this.page = 1
      if (page) {
        this.page = page
      }
      this.park_order_list = []
      this.infiniteParkOrderListId += 1
      this.infiniteHandlerParkOrderList()
    },
    infiniteHandlerParkOrderList: debounce(function ($state) {
      let startDate = null
      let endDate = null
      if (this.parked_sale_filter.start_date) {
        startDate = this.$moment(this.parked_sale_filter.start_date).locale("en").format("YYYY-MM-DD")
      }
      if (this.parked_sale_filter.end_date) {
        endDate = this.$moment(this.parked_sale_filter.end_date).locale("en").format("YYYY-MM-DD")
      }
      this.$axios
        .post(this.$config.base_url + "/api/order/get-park-order-list-pos", {
          page: this.page,
          shop_uuid: this.$config.shop_uuid,
          search_query: this.search_query,
          start_date: startDate,
          endDate: endDate,
          number_per_page: this.number_per_page
        })
        .then(({ data }) => {
          if (!(this.page > data.total_pages)) {
            this.page += 1
            this.park_order_list.push(...data.data)
            if ($state) {
              $state.loaded()
            }
          } else {
            if (data.data) {
              $state.loaded()
              $state.complete()
            }
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.onLoading = false
        })
    }, 500),

    openToCart (uuid) {
      this.$router.push({
        name: "order-show",
        params: {
          uuid: uuid,
          orderFrom: "order"
        }
      })
    },
    showOrder (uuid) {
      this.$router.push({
        name: "show-order",
        params: { uuid: uuid }
      })
    },
    deleteOrder (order_uuid) {
      const self = this
      self.$swal({
        title: this.$t("list.holdSale"),
        text: this.$t("message.confirm_delete_order"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.$config.base_url + "/api/v2/order/delete", {
          shop_uuid: self.shop.uuid,
          order_uuid: order_uuid
        }).then(() => {
          self.$aswaldelete(self.$t("model.order"))
          this.park_order_list.splice(this.park_order_list.findIndex(item => item.uuid === order_uuid), 1)
        }).catch((error) => {
          this.getResponseError(error)
        })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },
    changeViewModeParkSale (mode) {
      this.park_sale_view_mode = mode
      localStorage.setItem("park_sale_view_mode", mode)
    }
  }
}
</script>

<style scoped>
@import '../../../assets/css/V2/tab-style.css';

.park-sale-list {
  width: 45%;
}

/*==========================================================================*/
.order-card {
  border-radius: 6px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
}

.order-card-header,
.order-card-body,
.order-card-footer {
  padding: 12px;
}

.order-card-header {
  font-weight: 600;
}

.order-card-body {
  height: 160px;
}

.order-card-body .product-item {
  padding-bottom: 8px;
}

.order-card-body .product-item:last-child {
  padding-bottom: 0 !important;
}

.order-created-date i {
  font-size: 22px;
  color: #757575;
}

.list-park-order {
  overflow-y: auto;
  padding: 16px 0 0;
}

.order-card-footer .has-border-t .btn-card-order:last-child .btn {
  margin-right: 0;
}

.layout-search {
  padding-right: 0;
  width: calc(100% - 108px)
}

.layout-view-mode {
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 1230px) {
  .hide-label-btn-show {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .park-sale-list {
    width: 100%;
  }

  .layout-view-mode {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 991.98px) {
  .layout-search {
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .order-card-header,
  .order-card-body,
  .order-card-footer {
    padding: 8px;
  }

  .layout-search {
    padding-right: 0;
    width: 100%;
  }
}
</style>
