const _roles = {
  admin: "Administrator",
  owner: "owner",
  cashier: "cashier",
  stock_controller: "stock-controller",
  report_viewer: "report-viewer"
}

export const allowAccessOrder = (roles, to) => {
  const hasRole = roles.some(role => [_roles.admin, _roles.owner, _roles.cashier].includes(role))
  const matchedRoute = ["/invoice", "/order", "/cashier", "/parked-sale", "/offline"].some(r => to.path.startsWith(r))

  return hasRole && matchedRoute
}

export const allowAccessCashier = (roles, to) => {
  const hasRole = roles.some(role => [_roles.admin, _roles.owner, _roles.cashier].includes(role))
  const matchedRoute = ["/invoice", "/order", "/cashier", "/parked-sale", "/offline"].some(r => to.path.startsWith(r))

  return hasRole && matchedRoute
}

export const allowAccessCustomer = (roles, to) => {
  const hasRole = roles.some(role => [_roles.admin, _roles.owner, _roles.cashier].includes(role))
  const matchedRoute = ["/customer"].some(r => to.path.startsWith(r))

  return hasRole && matchedRoute
}

export const allowAccessInventory = (roles, to) => {
  const hasRole = roles.some(role => [_roles.admin, _roles.owner, _roles.stock_controller].includes(role))
  const matchedRoute = ["/admin/inventories"].some(r => to.path.startsWith(r))

  return hasRole && matchedRoute
}

export const allowAccessReport = (roles, to) => {
  const hasRole = roles.some(role => [_roles.admin, _roles.owner, _roles.report_viewer].includes(role))
  const matchedRoute = ["/admin/reports"].some(r => to.path.startsWith(r))

  return hasRole && matchedRoute
}

export const allowAccessSettings = (roles, to) => {
  const hasRole = roles.some(role => [_roles.admin, _roles.owner].includes(role))
  const matchedRoute = ["/admin/settings", "/inventory-transaction-history"].some(r => to.path.startsWith(r))

  return hasRole && matchedRoute
}

export const isOwner = (roles) => {
  return roles.some(role => role === _roles.owner) || roles.some(role => role === _roles.admin)
}

export const allowAccess = (roles, to) => {
  return allowAccessOrder(roles, to) ||
    allowAccessCashier(roles, to) ||
    allowAccessCustomer(roles, to) ||
    allowAccessInventory(roles, to) ||
    allowAccessReport(roles, to) ||
    allowAccessSettings(roles, to)
}
