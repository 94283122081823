<template>
  <div
    :style="`border-left: .25rem solid ${borderLeftColor} !important;`"
    class="asora-order-item shadow h-100 py-2"
    data-target="#chefOrderModal"
    data-toggle="modal"
    @click="getOrderDetail"
  >
    <div class="asora-order-item-body">
      <div>
        <p class="text-bold">
          #{{ order.order_number }}
        </p>
        <p class="text-muted">
          {{ order.updated_at }}
        </p>
        <template v-if="order.chef.email === 'unknown@gmail.com'">
          <p class="text-muted">
            {{ order.chef.first_name }}
          </p>
        </template>
        <template v-else>
          <p class="text-bold">
            {{ order.chef.first_name }} {{ order.chef.last_name }}
          </p>
        </template>
      </div>
      <div>
        <div class="asora-order-item-avatar">
          <img
            :src="`${checkUrlAvatar(order.chef.avatar_location)}`"
            class="img-responsive"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsoraChefOrderItem",
  props: {
    borderLeftColor: {
      type: String,
      default: "#4e73df"
    },
    order: {
      type: Object,
      required: true
    }
  },
  methods: {
    getOrderDetail () {
      this.$store.dispatch("chef/setOrderUuid", this.order.uuid)
    }
  }
}
</script>

<style scoped>
.py-2 {
  padding-bottom: .5rem !important;
}

.h-100 {
  height: 100% !important;
}

.shadow {
  -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
}

.asora-order-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: .35rem;
  cursor: pointer;
}

.asora-order-item-body {
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
}

.asora-order-item-avatar {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  border: 1px solid #dddddd;
  overflow: hidden;
}
</style>
