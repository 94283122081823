<template>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            <span>{{ $t("label.product.unit.editUnit") }}</span>
          </h3>
          <div class="box-tools hidden-xs">
            <a
              @click="$router.back()"
              class="btn btn-default btn-sm"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <product-unit-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductUnitForm from "./Form"

export default {
  components: { ProductUnitForm },
  name: "ProductUnitList",
  metaInfo () {
    return {
      title: this.$t("label.product.unit.editUnit"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  }
}
</script>

<style scoped>

</style>
