<template>
  <div class="register-container">
    <div class="register-form-content">
      <div class="register-form-content-wrapper shadow">
        <div class="margin-bottom pt-3 text-center">
          <router-link :to="{name: 'Login'}">
            <div class="register-logo">
              <img
                src="/img/logos/logo.png"
                alt="image"
                class="tw-w-full"
              >
            </div>
          </router-link>
        </div>
        <template v-if="step === 2">
          <div class="register-form-content_title">
            {{ $t("label.form_step_1_title") }}
          </div>
          <div class="register-form-content_sub_title text-muted">
            {{ $t("label.form_step_1_sub_title") }}
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3">
              <div
                class="form-group"
                :class="{'has-error': hasErrorValidate('first_name')}"
              >
                <label class="required text-capitalize">
                  {{ $t("fields.label.firstName") }}
                </label>
                <input
                  :placeholder="$t('fields.placeholder.firstName')"
                  class="form-control input-lg"
                  type="text"
                  v-model="user.first_name"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('first_name')"
                >
                  {{ validations["first_name"][0] }}
                </span>
              </div>
            </div>
            <div class="col-md-3 col-lg-3">
              <div
                class="form-group"
                :class="{'has-error': hasErrorValidate('last_name')}"
              >
                <label class="required text-capitalize">
                  {{ $t("fields.label.lastName") }}
                </label>
                <input
                  :placeholder="$t('fields.label.lastName')"
                  class="form-control input-lg"
                  type="text"
                  v-model="user.last_name"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('last_name')"
                >
                  {{ validations["last_name"][0] }}
                </span>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div
                class="form-group"
                :class="{'has-error': hasErrorValidate('username') || exist_username}"
              >
                <label class="text-capitalize d-flex align-items-center justify-content-between">
                  <span class="required">
                    {{ $t("fields.placeholder.username") }}
                  </span>
                  <button
                    class="btn btn-default btn-xs ml-3"
                    type="button"
                    data-toggle="modal"
                    data-target="#usernameTrip"
                    aria-expanded="true"
                    aria-controls="usernameTrip"
                  >
                    <i class="fas fa-question-circle" />
                  </button>
                </label>
                <input
                  :placeholder="$t('fields.placeholder.username')"
                  class="form-control input-lg"
                  type="text"
                  v-model="user.username"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('username')"
                >
                  {{ validations["username"][0] }}
                </span>
                <div v-if="exist_username">
                  <div class="form-group">
                    <span class="has-error">This username is already token. Please try another username.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div
                class="form-group"
                :class="{'has-error': hasErrorValidate('email') || exist_email}"
              >
                <label class="required text-capitalize">
                  {{ $t("fields.placeholder.email") }}
                </label>
                <input
                  :placeholder="$t('fields.placeholder.email')"
                  class="form-control input-lg"
                  type="email"
                  v-model="user.email"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('email')"
                >
                  {{ validations["email"][0] }}
                </span>
                <div v-if="exist_email">
                  <div class="form-group">
                    <span class="has-error">This email is already registered. </span>
                    <router-link :to="{name: 'Login'}">
                      Log In
                    </router-link>
                    <span class="has-error"> or try another email</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div
                class="form-group"
                :class="{'has-error': hasErrorValidate('phone')}"
              >
                <label class="required text-capitalize">
                  {{ $t("fields.placeholder.phone") }}
                </label>
                <input
                  :placeholder="$t('fields.placeholder.phone')"
                  class="form-control input-lg"
                  type="text"
                  v-mask="'### ### ###'"
                  v-model="user.phone"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('phone')"
                >
                  {{ validations["phone"][0] }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between w-100">
            <router-link
              class="btn btn-secondary-outline btn-lg"
              :to="{name: 'Login'}"
            >
              {{ $t("button.back") }}
            </router-link>
            <button
              class="btn btn-secondary btn-lg"
              :class="{'disabled': onloading_check_email}"
              :disabled="onloading_check_email"
              @click="checkInputUserContact"
            >
              <template v-if="onloading_check_email">
                <i class="fas fa-circle-notch fa-spin" />
              </template>
              <template v-else>
                {{ $t("button.next") }}
              </template>
            </button>
          </div>
        </template>
        <template v-if="step === 3">
          <div class="register-form-content_title">
            {{ $t("label.form_step_2_title") }}
          </div>
          <div class="register-form-content_sub_title text-muted">
            {{ $t("label.form_step_2_sub_title") }}
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  class="required text-capitalize"
                  :class="{'has-error': hasErrorValidate('shop_name')}"
                >
                  {{ $t("fields.label.shopName") }}
                </label>
                <input
                  :placeholder="$t('fields.label.shopName')"
                  class="form-control input-lg"
                  type="text"
                  v-model="shop.name"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('shop_name')"
                >
                  {{ validations["shop_name"][0] }}
                </span>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  class="required text-capitalize"
                  :class="{'has-error': hasErrorValidate('shop_tel')}"
                >
                  {{ $t("fields.label.shopPhone") }}
                </label>
                <input
                  :placeholder="$t('fields.label.shopPhone')"
                  class="form-control input-lg"
                  type="text"
                  v-mask="'### ### ###'"
                  v-model="shop.tel"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('shop_tel')"
                >
                  {{ validations["shop_tel"][0] }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  class="required text-capitalize"
                  :class="{'has-error': hasErrorValidate('shop_type_id')}"
                >
                  {{ $t("fields.label.shopType") }}
                </label>
                <select
                  name="shop_type"
                  id="shopType"
                  class="form-control input-lg"
                  v-model="shop.type_id"
                >
                  <option
                    :value="null"
                    class="text-muted"
                  >
                    {{ $t("label.selectAShopType") }}
                  </option>
                  <template v-if="shop_types && shop_types.length">
                    <option
                      v-for="(item, key) in shop_types"
                      :key="key"
                      :value="item.id"
                    >
                      {{ item["name_" + $i18n.locale] }}
                    </option>
                  </template>
                </select>
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('shop_type_id')"
                >
                  {{ validations["shop_type_id"][0] }}
                </span>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  class="required text-capitalize"
                  :class="{'has-error': hasErrorValidate('currency_id')}"
                >
                  {{ $t("fields.label.currency") }}
                </label>
                <select
                  name="shop_type"
                  id="currency"
                  class="form-control input-lg"
                  v-model="shop.currency_id"
                >
                  <option
                    :value="null"
                    class="text-muted"
                  >
                    {{ $t("label.selectDefaultCurrency") }}
                  </option>
                  <template v-if="currencies && currencies.length">
                    <option
                      v-for="(item, key) in currencies"
                      :key="key"
                      :value="item.id"
                    >
                      {{ item["name_" + $i18n.locale] }} ({{ item.code }})
                    </option>
                  </template>
                </select>
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('currency_id')"
                >
                  {{ validations["currency_id"][0] }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between w-100">
            <button
              class="btn btn-secondary-outline btn-lg"
              @click="backStep"
            >
              {{ $t("button.back") }}
            </button>
            <button
              class="btn btn-secondary btn-lg"
              @click="checkInputShopInfo"
            >
              {{ $t("button.next") }}
            </button>
          </div>
        </template>
        <template v-if="step === 4">
          <div class="register-form-content_title">
            {{ $t("label.form_step_3_title") }}
          </div>
          <div class="register-form-content_sub_title text-muted">
            {{ $t("label.form_step_3_sub_title") }}
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="form-group">
                <label
                  class="required text-capitalize"
                  :class="{'has-error': hasErrorValidate('address')}"
                >
                  {{ $t("fields.label.address") }}
                </label>
                <input
                  :placeholder="$t('fields.label.address')"
                  class="form-control input-lg"
                  type="text"
                  v-model="shop.address"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('address')"
                >
                  {{ validations["address"][0] }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  class="required text-capitalize"
                  :class="{'has-error': hasErrorValidate('city')}"
                >
                  {{ $t("fields.label.city") }}
                </label>
                <input
                  :placeholder="$t('fields.label.city')"
                  class="form-control input-lg"
                  type="text"
                  v-model="shop.city"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('city')"
                >
                  {{ validations["city"][0] }}
                </span>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  class="required text-capitalize"
                  :class="{'has-error': hasErrorValidate('country')}"
                >
                  {{ $t("fields.label.country") }}
                </label>
                <select
                  v-model="shop.country_id"
                  class="form-control input-lg"
                >
                  <option
                    :value="null"
                    selected
                    hidden
                  >
                    Select a country
                  </option>
                  <template v-for="(item, each_country_key) in countries">
                    <option
                      :value="item.id"
                      :key="each_country_key"
                    >
                      {{ item["name_" + $i18n.locale] }}
                    </option>
                  </template>
                </select>
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('country')"
                >
                  {{ validations["district"][0] }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between w-100">
            <button
              class="btn btn-secondary-outline btn-lg"
              @click="backStep"
            >
              {{ $t("button.back") }}
            </button>
            <button
              class="btn btn-secondary btn-lg"
              @click="checkInputShopAddress"
            >
              {{ $t("button.next") }}
            </button>
          </div>
        </template>
        <template v-if="step === 5">
          <div class="register-form-content_title">
            {{ $t("label.form_step_4_title") }}
          </div>
          <div class="register-form-content_sub_title text-muted">
            {{ $t("label.form_step_4_sub_title") }}
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  class="required"
                  :class="{'has-error': hasErrorValidate('password')}"
                >
                  {{ $t("fields.placeholder.password") }}
                </label>
                <password
                  :badge="false"
                  class="max-w-100"
                  :placeholder="$t('fields.placeholder.password')"
                  :secure-length="8"
                  :toggle="true"
                  default-class="form-control input-lg"
                  v-model="user.password"
                />
                <span
                  class="label-error has-error"
                  v-if="hasErrorValidate('password')"
                >
                  {{ validations["password"][0] }}
                </span>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  class="required"
                  :class="{'has-error': hasErrorValidate('password_confirmation')}"
                >
                  {{ $t("fields.placeholder.confirmPassword") }}
                </label>
                <input
                  :placeholder="$t('fields.placeholder.confirmPassword')"
                  class="form-control input-lg"
                  type="password"
                  v-model="user.password_confirmation"
                >
                <span
                  class="label-error has-error help-block"
                  v-if="hasErrorValidate('password_confirmation')"
                >
                  {{ validations["password_confirmation"][0] }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between w-100">
            <button
              class="btn btn-secondary-outline btn-lg"
              @click="backStep"
            >
              {{ $t("button.back") }}
            </button>
            <button
              class="btn btn-secondary btn-lg"
              @click="signUp"
            >
              {{ $t("button.signUp") }}
            </button>
          </div>
        </template>
        <div class="w-100 register-footer">
          <RegistrationAgreement v-if="step === 2" />
          <p class="text-center">
            {{ $t("string.copyright") }} &copy; 2021 <a href="https://figos.app">Figos.app</a>
            <span class="d-none">Powered by <a href="https://asorasoft.com">Asorasoft.com</a></span>
          </p>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="usernameTrip"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <UsernameTrip />
            <div class="margin-top w-100 text-right">
              <button
                type="button"
                class="btn btn-sm btn-default"
                data-dismiss="modal"
                aria-label="Close"
              >
                {{ $t("button.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter"
import { facebookSdkMixin } from "@/mixins/facebookLogin"
import { googleSdkMixin } from "@/mixins/googleLogin"
import UsernameTrip from "@/views/settings/_components/UsernameTrip"
import RegistrationAgreement from "@/components/RegistrationAgreement"

export default {
  name: "Register",
  metaInfo () {
    return {
      title: this.$t("loginPage.register"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  mixins: [facebookSdkMixin, googleSdkMixin],
  components: {
    Password,
    UsernameTrip,
    RegistrationAgreement
  },
  data () {
    return {
      regex_1: /^[a-z0-9]([a-z0-9_.]*[a-z0-9])?$/g,
      regex_2: /[_.]{2,}/g,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        phone: null,
        password: "",
        password_confirmation: ""
      },
      shop: {
        name: null,
        type_id: null,
        currency_id: null,
        tel: null,
        country_id: null,
        address: null,
        city: null,
        token_device_for_sell: this.generate_token(32)
      },
      shop_types: [],
      currencies: [],
      countries: [],
      onloading_check_email: false,
      exist_email: false,
      exist_username: false,
      validations: null
    }
  },
  mounted () {
    this.getDataOptions()
  },
  methods: {
    getDataOptions () {
      this.$axios
        .get(this.$config.base_url + "/api/get-data-options-register")
        .then((res) => {
          const result = res.data.data
          this.currencies = result.currencies
          this.shop_types = result.shop_types
          this.countries = result.countries
          if (this.countries.length) {
            const c = this.countries.find((item) => {
              if (item.name_en.toLowerCase() === "cambodia") {
                return item
              } else {
                return null
              }
            })
            if (c) {
              this.shop.country_id = c.id
            }
          }
        })
    },
    signUp () {
      this.validations = null
      this.$isLoading(true)
      localStorage.setItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE, this.shop.token_device_for_sell)
      this.$axios
        .post(this.$config.base_url + "/api/auth/register", {
          address: this.shop.address,
          city: this.shop.city,
          country_id: this.shop.country_id,
          currency_id: this.shop.currency_id,
          email: this.user.email,
          username: this.user.username,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          password: this.user.password,
          password_confirmation: this.user.password_confirmation,
          phone: this.user.phone,
          shop_name: this.shop.name,
          shop_tel: this.shop.tel,
          shop_type_id: this.shop.type_id,
          token_device_for_sell: this.shop.token_device_for_sell
        })
        .then((res) => {
          const result = res.data.data
          localStorage.setItem("send_mail", result.email)
          this.$router.replace({ name: "send-mail" })
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            this.validations = errors.response.data.errors
          } else {
            this.getResponseError(errors)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },

    loginWithPhone () {
      this.validations = null
      this.login_type = "phone"
      this.step = 2
      this.exist_email = false
    },
    backToStepOne () {
      this.validations = null
      this.login_type = null
      this.step = 2
    },
    backStep () {
      this.validations = null
      this.step -= 1
    },
    onClickNext () {
      this.step += 1
    },
    checkInputUserContact () {
      this.exist_email = false
      this.exist_username = false
      this.validations = {}
      if (!this.user.first_name) {
        this.validations.first_name = ["Enter your first name."]
      }
      if (!this.user.last_name) {
        this.validations.last_name = ["Enter your last name."]
      }
      if (!this.user.email) {
        this.validations.email = ["Enter your email address."]
      } else {
        if (!this.validateEmail(this.user.email)) {
          this.validations.email = ["Enter a valid email address."]
        }
      }
      if (!this.user.username) {
        this.validations.username = ["Enter your username."]
      } else {
        const username = this.user.username
        if (!(username.match(this.regex_1) != null && username.match(this.regex_2) === null)) {
          this.validations.username = ["The username format is invalid."]
        }
      }
      if (!this.user.phone) {
        this.validations.phone = ["Enter your phone number."]
      }

      if (!this.notEmptyObject(this.validations)) {
        this.onloading_check_email = true
        setTimeout(() => {
          this.$axios.post(this.$config.base_url + "/api/auth/check-email", {
            email: this.user.email,
            username: this.user.username
          }).then((res) => {
            this.exist_email = res.data.data.exist_email
            this.exist_username = res.data.data.exist_username
            if (!this.exist_email || !this.exist_username) {
              this.onClickNext()
            }
          }).catch((error) => {
            if (error.response.status === 422) {
              this.validations = error.response.data.errors
            } else {
              this.getResponseError(error)
            }
          }).finally(() => {
            this.onloading_check_email = false
          })
        }, 1000)
      }
    },
    checkInputShopInfo () {
      this.exist_email = false
      this.exist_username = false
      this.validations = {}
      if (!this.shop.name) {
        this.validations.shop_name = ["Please enter your shop name."]
      }
      if (!this.shop.tel) {
        this.validations.shop_tel = ["Please enter your shop phone number."]
      }
      if (!this.shop.type_id) {
        this.validations.shop_type_id = ["Please select your shop type."]
      }
      if (!this.shop.currency_id) {
        this.validations.currency_id = ["Please select your default currency."]
      }
      if (!this.notEmptyObject(this.validations)) {
        this.onClickNext()
      }
    },
    checkInputShopAddress () {
      this.exist_email = false
      this.exist_username = false
      this.validations = {}
      if (!this.shop.address) {
        this.validations.address = ["Please enter address."]
      }
      if (!this.shop.city) {
        this.validations.city = ["Please enter city."]
      }
      if (!this.shop.country_id) {
        this.validations.country_id = ["Please select a country."]
      }
      if (!this.notEmptyObject(this.validations)) {
        this.onClickNext()
      }
    },
    notEmptyObject (someObject) {
      return Object.keys(someObject).length > 0
    },
    hasErrorValidate (field) {
      return this.validations && this.validations.hasOwnProperty(field)
    }
  }
}
</script>

<style scoped>
@import "./../assets/css/V2/front-page.css";
</style>
