import Unauthorized from "@/views/errors/403"
import PageNotFound from "@/views/errors/404"
import PageServerError from "@/views/errors/500"

export const error401Routers = {
  path: "/403",
  name: "403",
  component: Unauthorized
}

export const error500Routers = {
  path: "/500",
  name: "500",
  component: PageServerError
}

export const error404Routers = {
  path: "*",
  name: "404",
  component: PageNotFound
}
