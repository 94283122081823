<template>
  <div>
    <div class="container title">
      <div class="col-md-12 no-padding">
        <router-link
          :to="{path: '/cashier/recent-sell'}"
          class="btn btn-default pull-left margin-top"
          style="margin-right: 5px"
        >
          <i class="fas fa-times-circle" />
          {{ $t("button.close") }}
        </router-link>
        <button
          class="btn btn-default pull-right margin-left margin-top"
          @click="goToPrintPage"
        >
          <i class="fas fa-print" />
          {{ $t("button.print") }}
        </button>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="wrapper-header">
            <div class="row">
              <div class="col-md-6">
                <h3 class="title-label">
                  {{ $t("recent_sell.showRecentSell") }}
                </h3>
              </div>
            </div>
            <div class="clearfix" />
          </div>
        </div>
      </div>
      <div class="row wrapper">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="text-label">
                  <label>{{ $t("recent_sell.label.customer") }} : </label>
                  <template
                    v-if="recentSellDetail.hasOwnProperty('order') && recentSellDetail.order.hasOwnProperty('customer')"
                  >
                    <label class="label-data">
                      {{ recentSellDetail.order.customer ? recentSellDetail.order.customer.name : "Unknown" }}
                    </label>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <div class="text-label">
                  <label>{{ $t("recent_sell.label.tel") }} : </label>
                  <template
                    v-if="recentSellDetail.hasOwnProperty('order') && recentSellDetail.order.hasOwnProperty('customer')"
                  >
                    <label class="label-data">
                      {{
                        recentSellDetail.order.customer ? recentSellDetail.order.customer.tel : $t("string.na")
                      }}
                    </label>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="text-label">
                  <label>{{ $t("recent_sell.label.orderNumber") }} : </label>
                  <label class="label-data">{{ recentSellDetail.order_number }}</label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="text-label">
                  <label>{{ $t("recent_sell.label.date") }} : </label>
                  <label
                    class="label-data"
                  >{{
                    $moment(recentSellDetail.created_at).format("ddd, MMM Do YYYY, h:mm a")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="text-label">
                  <label>{{ $t("recent_sell.label.cashier") }} : </label>
                  <template v-if="recentSellDetail.hasOwnProperty('cashier')">
                    <label class="label-data">{{ recentSellDetail.cashier.full_name }}</label>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("recent_sell.table.no") }}</th>
                <th>{{ $t("recent_sell.table.productName") }}</th>
                <th>{{ $t("recent_sell.table.salePrice") }}</th>
                <th>{{ $t("recent_sell.table.servedQuantity") }}</th>
                <th>{{ $t("recent_sell.table.discount") }}</th>
                <th>{{ $t("recent_sell.table.amount") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(product, key) in products"
                :key="key"
              >
                <td>{{ key + 1 }}</td>
                <td>{{ product.name }}</td>
                <td>
                  {{ parseFloat(product.sale_price).toFixed(2) }} {{ product.currency.code }}
                </td>
                <td>{{ product.pivot.served_quantity }}</td>
                <td>
                  <template v-if="product.pivot.is_percentage">
                    {{ product.pivot.discount }}%
                  </template>
                  <template v-else>
                    {{ parseFloat(product.pivot.discount) }}{{ product.currency.symbol }}
                  </template>
                </td>
                <td>
                  {{ parseFloat(product.amountTotal).toFixed(2) }} {{ product.currency.code }}
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.label.subTotal") }}</label></td>
                <td>
                  {{ parseInt(recentSellDetail.amount_subtotal_riel) }}
                  <template
                    v-if="recentSellDetail.hasOwnProperty('shop') && recentSellDetail.shop.hasOwnProperty('currency')"
                  >
                    {{ recentSellDetail.shop.currency.code }}
                  </template>
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.label.tax") }}</label></td>
                <td>
                  <template v-if="recentSellDetail.hasOwnProperty('tax')">
                    {{ recentSellDetail.tax.name ? recentSellDetail.tax.amount_percentage : 0 }} %
                  </template>
                </td>
              </tr>
              <tr>
                <template v-if="recentSellDetail.discount_authorizer">
                  <td colspan="2" />
                  <td>{{ $t("recent_sell.label.authorizer") }}</td>
                  <td>{{ recentSellDetail.discount_authorizer }}</td>
                </template>
                <template v-else>
                  <td colspan="4" />
                </template>
                <td><label>{{ $t("recent_sell.label.discount") }}</label></td>
                <td>{{ recentSellDetail.amount_discount }} %</td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.label.amountTotalRiel") }}</label></td>
                <td>
                  <strong>{{ parseInt(recentSellDetail.amount_total_riel) }}</strong> KHR
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.label.amountTotalDollar") }}</label></td>
                <td>
                  <strong>{{ parseFloat(recentSellDetail.amount_total_dollar).toFixed(2) }}</strong> USD
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.label.amountPaidRiel") }}</label></td>
                <td>
                  <strong>{{ parseInt(recentSellDetail.amount_paid_riel) }}</strong> KHR
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.label.amountPaidDollar") }}</label></td>
                <td>
                  <strong>{{ parseFloat(recentSellDetail.amount_paid_dollar).toFixed(2) }}</strong> USD
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.label.amountDueRiel") }}</label></td>
                <td>
                  {{ parseInt(recentSellDetail.amount_due_riel) }} KHR
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.label.amountDueDollar") }}</label></td>
                <td>
                  {{ parseFloat(recentSellDetail.amount_due_dollar).toFixed(2) }} USD
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.owe.label.amountOweRiel") }}</label></td>
                <td>
                  {{ parseInt(recentSellDetail.amount_owe_riel) }} KHR
                </td>
              </tr>
              <tr>
                <td colspan="4" />
                <td><label>{{ $t("recent_sell.owe.label.amountOweDollar") }}</label></td>
                <td>
                  {{ parseFloat(recentSellDetail.amount_owe_dollar).toFixed(2) }} USD
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo () {
    return {
      title: this.$t("recent_sell.showRecentSell"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      recentSellDetail: {},
      products: []
    }
  },
  methods: {
    getRecentSellDetail () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/recent-sell/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.recentSellDetail = response.data.data
          this.products = JSON.parse(response.data.data.items)
          this.products.forEach((product) => {
            let amountTotal = 0
            if (product.pivot.is_percentage) {
              amountTotal = product.pivot.served_quantity * product.sale_price * (100 - product.pivot.discount) / 100
            } else {
              amountTotal = (product.pivot.served_quantity * product.sale_price) - product.pivot.discount
            }

            this.$set(product, "amountTotal", amountTotal)
          })
        } else {
          this.$toastr("error", this.$t("string.canNotGetData"), this.$t("string.error"))
        }
      })
    },
    goToPrintPage () {
      this.$router.push({
        name: "print-invoice",
        params: { uuid: this.$route.params.uuid }
      })
    }
  },
  mounted () {
    this.getRecentSellDetail()
  }
}
</script>

<style scoped>
.wrapper {
  background: var(--white);
  margin: 1% auto 0 auto;
  padding: 15px;
}

.wrapper-header {
  margin-bottom: 15px;
}

.label-data {
  margin-left: 15px;
}

.badge-label {
  display: inline-block;
  background-color: #00c0ef;
  color: #ffffff;
  border-radius: 12px;
  padding: 3px 10px;
  margin-bottom: 10px;
  margin-left: 3px;
}
</style>
