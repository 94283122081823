/* eslint-disable no-console */
import { register } from "register-service-worker"

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
      )
    },
    registered (ref) {
      console.log("Service worker has been registered.")
    },
    cached () {
      console.log("Content has been cached for offline use.")
    },
    updatefound () {
      console.log("New content is downloading.")
    },
    updated (registration) {
      console.log("New content is available; please refresh.")

      document.dispatchEvent(new CustomEvent("swUpdated", {
        detail: registration
      }))
    },
    offline () {
      console.log("No internet connection found. App is running in offline mode.")
    },
    error (error) {
      console.error("Error during service worker registration:", error)
    }
  })
}

// Self-destroying service worker
// Checkout docs: https://github.com/NekR/self-destroying-sw

self.addEventListener("install", function (e) {
  self.skipWaiting()
})

self.addEventListener("activate", function (e) {
  self.registration.unregister()
    .then(function () {
      return self.clients.matchAll()
    })
    .then(function (clients) {
      clients.forEach(client => client.navigate(client.url))
    })
})

// Handling service worker
// https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip

self.addEventListener("message", (event) => {
  if (event.data && event.data.type && event.data.type === "SKIP_WAITING") {
    self.skipWaiting()
  }
})
