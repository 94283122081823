<template>
  <div>
    <div class="non-footer-container">
      <NavbarV2 class="no-print" />
      <LeftSideBarMobileScreen class="no-print" />
      <router-view />
    </div>
    <div class="no-print">
      <dashboard-footer />
    </div>
  </div>
</template>

<script>
import DashboardFooter from "../../includes/footer/Dashboard"
import NavbarV2 from "@/includes/NavbarV2"
import LeftSideBarMobileScreen from "@/components/LeftSideBarMobileScreen"

export default {
  name: "InvoiceLayout",
  components: {
    LeftSideBarMobileScreen,
    NavbarV2,
    DashboardFooter
  }
}
</script>

<style scoped>
@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
</style>
