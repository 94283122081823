<template>
  <div class="form-horizontal">
    <div
      :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('name') ? 'has-error' : '')"
    >
      <label class="col-sm-2 control-label required">{{ $t("label.name") }}</label>
      <div class="col-sm-10">
        <input
          v-model="ticketType.name"
          :placeholder="$t('label.name')"
          class="form-control"
          type="text"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('name')">
          <span class="help-block">{{ message_validation["name"][0] }}</span>
        </template>
      </div>
    </div>
    <div
      :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('code') ? 'has-error' : '')"
    >
      <label class="col-sm-2 control-label required">{{ $t("label.code") }}</label>
      <div class="col-sm-10">
        <input
          v-model="ticketType.code"
          :placeholder="$t('label.code')"
          class="form-control"
          type="text"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('code')">
          <span class="help-block">{{ message_validation["code"][0] }}</span>
        </template>
      </div>
    </div>
    <template v-if="$route.name === 'ticket-type-edit'">
      <div class="form-group">
        <label class="col-sm-2 control-label">{{ $t("label.price") }}</label>
        <label class="col-sm-10 ">$ {{ parseFloat(ticketType.price).toFixed(2) }}</label>
      </div>
    </template>
    <template v-else>
      <div
        :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('price') ? 'has-error' : '')"
      >
        <label class="col-sm-2 control-label required">{{ $t("label.price") }}</label>
        <div class="col-sm-10">
          <div class="input-group">
            <input
              v-model="ticketType.price"
              :placeholder="$t('label.price')"
              class="form-control"
              min="0"
              type="number"
            >
            <span class="input-group-addon">$</span>
          </div>

          <template v-if="message_validation !== null && message_validation.hasOwnProperty('price')">
            <span class="help-block">{{ message_validation["price"][0] }}</span>
          </template>
        </div>
      </div>
    </template>

    <div
      :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('start_age') ? 'has-error' : '')"
    >
      <label class="col-sm-2 control-label required">{{ $t("label.startAge") }}</label>
      <div class="col-sm-10">
        <input
          v-model="ticketType.start_age"
          :placeholder="$t('label.startAge')"
          class="form-control"
          min="1"
          type="number"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('start_age')">
          <span class="help-block">{{ message_validation["start_age"][0] }}</span>
        </template>
      </div>
    </div>
    <div
      :class="'form-group ' + (message_validation !== null && message_validation.hasOwnProperty('end_age') ? 'has-error' : '')"
    >
      <label class="col-sm-2 control-label required">{{ $t("label.endAge") }}</label>
      <div class="col-sm-10">
        <input
          v-model="ticketType.end_age"
          :placeholder="$t('label.endAge')"
          class="form-control"
          min="1"
          type="number"
        >
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('end_age')">
          <span class="help-block">{{ message_validation["end_age"][0] }}</span>
        </template>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <div class="checkbox">
          <label>
            <input
              v-model="ticketType.show"
              type="checkbox"
            >
            <template v-if="ticketType.show">
              {{ $t("label.showAndUseThisTicket") }}
            </template>
            <template v-else>
              {{ $t("label.hideThisTicket") }}
            </template>
          </label>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <button
          class="btn btn-primary"
          @click="store"
        >
          <i class="fas fa-check-circle" /> {{ $t("button.save") }}
        </button>
        <button
          class="btn btn-default"
          @click="$router.push({name: 'ticket-type-list'})"
        >
          <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketTypeForm",
  data () {
    return {
      message_validation: null,
      colors: {
        r: 255,
        g: 0,
        b: 0
      },
      ticketType: {
        shop_uuid: this.$config.shop_uuid,
        name: "",
        code: "",
        price: 0,
        start_age: 0,
        end_age: 0,
        show: false,
        color: "#606073"
      }
    }
  },
  watch: {
    "ticketType.name" () {
      this.ticketType.code = this.ticketType.name
    }
  },
  methods: {
    store () {
      this.ticketType.shop_uuid = this.$config.shop_uuid
      if (this.ticketType.hasOwnProperty("shop_id")) {
        delete this.ticketType.shop_id
      }
      this.$axios.post(this.$config.base_url + "/api/amusement-park/ticket-type/store", this.ticketType)
        .then((response) => {
          if (response.data.code === 1) {
            this.$router.push({ name: "ticket-type-list" })
            this.$toastr("info", this.$t("string.theRequestHasBeenProcessed"), this.$t("string.ticketType"))
          }
          if (response.data.code === 0) {
            this.message_validation = response.data.message
          }
        })
    },
    getTicketType () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/amusement-park/ticket-type/show", {
        shop_uuid: this.$config.shop_uuid,
        uuid: this.$route.params.ticket_type_uuid
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          this.ticketType = response.data.data
          this.colors.hex = response.data.data.color
        }
      })
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty("ticket_type_uuid") &&
      this.$route.params.ticket_type_uuid !== undefined) {
      this.getTicketType()
    }
  }
}
</script>

<style scoped>

</style>
