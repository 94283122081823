import indexedb from "../indexedb"
import { VueOfflineMixin } from "vue-offline/src"

export const offlineMixins = {
  mixins: [VueOfflineMixin],
  methods: {
    getOfflineDataCache () {
      if (this.isOffline) return 0
      indexedb.removeAll("customer_groups")
      indexedb.removeAll("customer_memberships")
      indexedb.removeAll("customers")
      indexedb.removeAll("exchange_rate")
      indexedb.removeAll("genders")
      indexedb.removeAll("payment_options")
      indexedb.removeAll("product_categories")
      indexedb.removeAll("products")
      indexedb.removeAll("shop")
      indexedb.removeAll("tax")
      indexedb.removeAll("user")
      indexedb.removeAll("warehouse")

      if (localStorage.hasOwnProperty("exchange")) {
        indexedb.save("exchange_rate", JSON.parse(localStorage.getItem("exchange")))
      }
      if (localStorage.hasOwnProperty("shop")) {
        indexedb.save("shop", JSON.parse(localStorage.getItem("shop")))
      }
      if (localStorage.hasOwnProperty("user")) {
        indexedb.save("user", JSON.parse(localStorage.getItem("user")))
      }
      this.$axios.post(`${this.$config.base_url}/api/offline/get-data-cache`, {
        shop_uuid: this.get_shop.uuid
      }).then((res) => {
        const genders = res.data.data.genders
        const tax = res.data.data.tax
        const products = res.data.data.products
        const product_categories = res.data.data.product_categories
        const warehouse = res.data.data.warehouse
        const payment_options = res.data.data.payment_options
        const customers = res.data.data.customers
        const customer_groups = res.data.data.customer_groups
        const customer_memberships = res.data.data.customer_memberships
        localStorage.setItem("shops", JSON.stringify(res.data.data.shops))
        if (customer_groups !== null && customer_groups.length > 0) {
          indexedb.saveArray("customer_groups", customer_groups)
        }
        if (customer_memberships !== null && customer_memberships.length > 0) {
          indexedb.saveArray("customer_memberships", customer_memberships)
        }
        if (customers !== null && customers.length > 0) {
          indexedb.saveArray("customers", customers)
        }
        if (genders !== null && genders.length > 0) {
          indexedb.saveArray("genders", genders)
        }
        if (payment_options !== null && payment_options.length > 0) {
          indexedb.saveArray("payment_options", payment_options)
        }
        if (product_categories !== null && product_categories.length > 0) {
          indexedb.saveArray("product_categories", product_categories)
        }
        if (products !== null && products.length > 0) {
          products.map((product) => {
            if (product.children && product.children.length > 0) {
              let amount = 0
              product.children.forEach((item) => {
                amount += parseFloat(item.amount_in_stock)
              })
              this.$set(product, "initAmountInStock", amount)
            } else {
              this.$set(product, "initAmountInStock", product.amount_in_stock)
            }
            if (!product.hasOwnProperty("pivot")) {
              this.$set(product, "pivot", {
                quantity: 0,
                served_quantity: 0,
                discount: 0,
                is_percentage: true,
                note: null
              })
            }
            product = this.calcPromotion(product, product.promotions)
            if (product.children && product.children.length) {
              product.children.map((pro_child) => {
                this.$set(pro_child, "initAmountInStock", pro_child.amount_in_stock)
                if (!pro_child.hasOwnProperty("pivot")) {
                  this.$set(pro_child, "pivot", {
                    quantity: 0,
                    served_quantity: 0,
                    discount: 0,
                    is_percentage: true,
                    note: null
                  })
                }
                pro_child = this.calcPromotion(pro_child, product.promotions)
                return pro_child
              })
              product = this.addVariantType(product)
            }
            return product
          })
          indexedb.saveArray("products", products)
        }
        indexedb.save("tax", tax)
        if (tax && !(localStorage.hasOwnProperty("tax"))) {
          localStorage.setItem("tax", JSON.stringify(tax))
        }
        indexedb.save("warehouse", warehouse)
      })
      this.getInvoiceTemplateActive()
    },
    syncOfflineData () {
      let orders = []
      let invoices = []
      let order_products = []

      indexedb.all("orders")
        .then((data) => {
          if (data !== undefined && data.length > 0) {
            orders = data
          }
        })
        .then(() => {
          indexedb.all("order_products")
            .then((data) => {
              if (data !== undefined && data.length > 0) {
                order_products = data
              }
            })
            .then(() => {
              indexedb.all("invoices")
                .then((data) => {
                  if (data !== undefined && data.length > 0) {
                    invoices = data
                  }
                })
                .then(() => {
                  if (orders.length > 0 && order_products.length > 0) {
                    this.confirmSyncOfflineData(orders, order_products, invoices)
                  }
                })
            })
        })
    },
    confirmSyncOfflineData (orders, order_products, invoices) {
      this.$swal({
        title: this.$t("string.youAreOnline"),
        text: this.$t("string.youNeedToSyncDataOffline"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$t("string.process"),
        cancelButtonText: this.$t("string.thankSkip")
      }).then(() => {
        this.$isLoading(true)
        this.$axios.post(`${this.$config.base_url}/api/offline/sync-data`, {
          orders: orders,
          order_products: order_products,
          invoices: invoices,
          shop_uuid: this.get_shop.uuid
        }).then((response) => {
          this.onResponseSuccess()
          orders.forEach((order) => {
            try {
              indexedb.remove("orders", order.uuid)
              indexedb.remove("order_products", order.uuid)
              indexedb.remove("invoices", order.uuid)
              // eslint-disable-next-line no-empty
            } catch (e) {
            }
          })
        }).catch((error) => {
          this.onResponseError(error)
        }).finally(() => {
          this.$isLoading(false)
        })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
      this.getOfflineDataCache()
    },
    getInvoiceTemplateActive () {
      this.$axios.post(this.$config.base_url + "/api/invoice-template/get-invoice-template-active", {
        shop_uuid: this.get_shop.uuid
      }).then((res) => {
        const r = res.data.data
        if (r) {
          const invoice_template = {
            custom_footer_en: r.custom_footer_en,
            custom_footer_km: r.custom_footer_km,
            custom_header_en: r.custom_header_en,
            custom_header_km: r.custom_header_km,
            show_amount_paid: r.show_amount_paid,
            show_balance: r.show_balance,
            show_cashier: r.show_cashier,
            show_contact: r.show_contact,
            show_customer_info: r.show_customer_info,
            show_exchange_rate: r.show_exchange_rate,
            show_logo: r.show_logo,
            show_shop_name: r.show_shop_name,
            show_invoice_number: r.show_invoice_number,
            show_order_number: r.show_order_number
          }
          localStorage.setItem("invoice_template", JSON.stringify(invoice_template))
        }
      })
    }
  }
}
