<template>
  <li
    v-if="user"
    :class="{'active': $route.name === 'overview', 'not-allowed-click': is_expired}"
    class="dropdown user user-menu"
  >
    <template v-if="displayDropdown">
      <a
        class="dropdown-toggle nav-icon-size nav-name-color"
        data-toggle="dropdown"
        href="#"
      >
        <template v-if="is_offline">
          <img
            :src="defaultAvatar"
            alt="User Image"
            class="user-image"
          >
        </template>
        <template v-else>
          <template v-if="user.avatar_location">
            <img
              :src="`${checkUrlAvatar(user.avatar_location)}`"
              alt="User Image"
              class="user-image"
            >
          </template>
          <template v-else>
            <img
              :src="defaultAvatar"
              alt="User Image"
              class="user-image"
            >
          </template>
        </template>
        <span
          :class="usernameClass"
          class="hidden-xs username"
        >
          {{ user ? user.full_name : "Default User" }}
        </span>
      </a>
      <ul class="dropdown-menu">
        <li class="user-header">
          <p>
            {{ user.full_name || "Default User" }}
          </p>
          <template v-if="user.avatar_location">
            <img
              :src="`${checkUrlAvatar(user.avatar_location)}`"
              alt="User Avatar"
            >
          </template>
          <template v-else>
            <img
              :src="defaultAvatar"
              alt="Default Image"
            >
          </template>
        </li>
        <li class="user-footer">
          <div class="pull-left">
            <router-link
              :to="{name: 'overview'}"
              class="btn btn-default btn-flat"
            >
              {{ $t("label.profile") }}
            </router-link>
          </div>
          <div class="pull-right">
            <button
              class="btn btn-default btn-flat"
              @click="logout"
            >
              {{ $t("label.logout") }}
            </button>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <a
        class="nav-icon-size nav-name-color"
        @click="checkExpiredDemo('/profile/overview')"
      >
        <template v-if="is_offline">
          <img
            :src="defaultAvatar"
            alt="User Image"
            class="user-image"
          >
        </template>
        <template v-else>
          <template v-if="user.avatar_location">
            <img
              :src="`${checkUrlAvatar(user.avatar_location)}`"
              alt="User Image"
              class="user-image"
            >
          </template>
          <template v-else>
            <img
              :src="defaultAvatar"
              alt="User Image"
              class="user-image"
            >
          </template>
        </template>
        <span
          :class="usernameClass"
          class="hidden-xs username"
        >
          {{ user.full_name || "Default User" }}
        </span>
      </a>
    </template>
  </li>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "DropdownUserProfile",
  data () {
    return {}
  },
  props: {
    usernameClass: {
      type: String,
      default: ""
    },
    displayDropdown: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      is_offline: "shop/is_offline",
      is_expired: "shop/is_expired"
    })
  }
}
</script>

<style scoped>
.username {
  color: #333333;
}

.user-menu {
  cursor: pointer;
}

.user-menu.not-allowed-click {
  cursor: not-allowed;
}
</style>
