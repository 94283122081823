<template>
  <div class="tw-flex tw-justify-center tw-items-center tw-bg-gray-800 tw-p-2 sm:tw-pt-24 sm:tw-pb-24 tw-rounded-lg">
    <div class="tw-w-full md:tw-w-10/12">
      <div class="tw-flex tw-w-full tw-items-center tw-bg-yellow-400 tw-space-x-2 tw-p-3 tw-rounded-lg tw-mb-3">
        <i class="fas fa-exclamation-circle" />
        <p class="tw-p-0 tw-m-0">
          {{ $t("string.recommended_size") }}: 720x960
        </p>
      </div>

      <div>
        <cropper
          ref="cropper"
          :src="image.src"
          :stencil-props="{aspectRatio: 3/4}"
          :stencil-size="{ width: 720, height: 960 }"
          check-orientation
        />
      </div>
      <div class="tw-mt-2">
        <button
          class="btn btn-primary btn-sm tw-mb-1"
          @click="$refs.file.click()"
        >
          <input
            ref="file"
            accept="image/*"
            style="display: none;"
            type="file"
            @change="loadImage($event)"
          >
          <i class="fas fa-image" /> {{ $t("string.browse_image") }}
        </button>
        <button
          v-if="image.src"
          class="btn btn-primary btn-sm tw-mb-1"
          @click="crop"
        >
          <i class="fas fa-upload" /> {{ $t("button.upload") }}
        </button>
        <button
          class="btn btn-default btn-sm tw-mb-1"
          @click="reset"
        >
          {{ $t("button.reset") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"

const getMimeType = (file, fallback = null) => {
  const byteArray = new Uint8Array(file).subarray(0, 4)
  let header = ""
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
  case "89504e47":
    return "image/png"
  case "47494638":
    return "image/gif"
  case "ffd8ffe0":
  case "ffd8ffe1":
  case "ffd8ffe2":
  case "ffd8ffe3":
  case "ffd8ffe8":
    return "image/jpeg"
  default:
    return fallback
  }
}

export default {
  name: "CarouselImageUploader",
  components: {
    Cropper
  },
  data () {
    return {
      image: {
        src: `${window.location.origin}/img/slide_image_default.png`,
        type: "image/jpeg"
      }
    }
  },
  methods: {
    loadImage (event) {
      const { files } = event.target
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        const blob = URL.createObjectURL(files[0])
        const reader = new FileReader()
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type)
          }
        }
        reader.readAsArrayBuffer(files[0])
      }
    },
    crop () {
      const { canvas } = this.$refs.cropper.getResult()
      const self = this
      canvas.toBlob((blob) => {
        const params = new FormData()
        params.set("uuid", this.get_shop.uuid)
        params.set("cover", blob)
        this.$isLoading(true)
        self.$axios.post(`${self.$config.base_url}/api/shop/upload-multiple-covers`, params)
          .then(() => {
            this.$emit("fetchImages")
            this.reset()
          })
          .finally(() => {
            this.$isLoading(false)
          })
      }, this.image.type)
    },
    reset () {
      this.$refs.file.value = null
      this.image = {
        src: `${window.location.origin}/img/slide_image_default.png`,
        type: "image/jpeg"
      }
    }
  }
}
</script>
