export const state = () => {
  return {
    dateRange: null
  }
}

export const getters = {
  dateRange: state => state.dateRange
}

export const mutations = {
  setDateRange (state, dateRange) {
    state.dateRange = dateRange
  }
}

export const actions = {
  setDateRange ({ commit }, dateRange) {
    commit("setDateRange", dateRange)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
