<template>
  <div class="row">
    <div class="col-xs-12 col-md-3">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ showProduct ? showProduct.name : $t("label.productNotFound") }}
          </h3>
          <div
            v-if="showProduct"
            class="box-tools"
          >
            <ProductActions
              :product-name="showProduct.name"
              @fetchProductDetail="() => {
                getProduct();
                oInventoryTransaction.draw();
              }"
            />
          </div>
        </div>
        <div class="box-body">
          <template v-if="showProduct">
            <template v-if="productChild.length > 0">
              <div class="list-group">
                <a
                  :class="'list-group-item ' + [isParent === true ? 'active' : '']"
                  :href="`javascript:void(0)`"
                  @click="selectedParent"
                >
                  {{ showProduct.name }}
                </a>
              </div>
              <div class="list-group">
                <a
                  v-for="(child, pKey) in productChild"
                  :key="pKey"
                  :class="'list-group-item  ' + child.is_active"
                  href="javascript:void(0)"
                  @click="selectedVariant(child)"
                >
                  <i class="fas fa-list-ul mr-2" />
                  <label
                    v-for="(variant, ckey) in child.product_variants"
                    :key="ckey"
                    class="font-weight-normal margin-bottom-none"
                  >
                    {{ variant.pivot.value }}
                    <span v-if="ckey < child.product_variants.length-1">/</span>
                  </label>
                </a>
              </div>
            </template>
            <template v-else>
              <div class="block">
                <p class="text-center">
                  {{ $t("string.noProductVariants") }}
                </p>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-9">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            <span>{{ $t("product.label.show") }}</span>
          </h3>
          <div class="box-tools">
            <button
              class="btn btn-default btn-sm hidden-xs"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </button>
          </div>
        </div>
        <div class="box-body">
          <template v-if="showProduct">
            <product-form-show
              :is-parent="isParent"
              :parent-images="parentImages"
              :product="showProduct"
              columns="col-md-12"
              @refreshProduct="getProduct"
            />
          </template>
          <template v-else>
            <div class="d-flex align-items-center justify-content-center">
              {{ $t("label.productNotFound") }}
            </div>
          </template>
        </div>
      </div>
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            <span>{{ $t("inventory.label.inventoryHistory") }}</span>
          </h3>
        </div>
        <div class="box-body">
          <div>
            <table
              id="table-inventory-transaction"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("inventory.table.adjustedBy") }}</th>
                  <th>{{ $t("inventory.table.name") }}</th>
                  <th>{{ $t("inventory.table.amountInStock") }}</th>
                  <th>{{ $t("inventory.table.amount") }}</th>
                  <th>{{ $t("inventory.table.price") }}</th>
                  <th>{{ $t("inventory.table.action") }}</th>
                  <th>{{ $t("product.fields.expired_date.label") }}</th>
                  <th>{{ $t("inventory.table.date") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <swal-delete-action
      ref="swalDeleteAction"
      route-to-delete="/api/product/delete"
      @refreshListingTable="getProduct"
    />
  </div>
</template>

<script>

import { mapActions } from "vuex"
import ProductFormShow from "./FormShow"
import SwalDeleteAction from "@/components/SwalDeleteAction"
import ProductActions from "@/views/inventories/products/_components/ProductActions"

export default {
  name: "ShowProduct",
  metaInfo () {
    return {
      title: this.$t("page.productDetail"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    ProductActions,
    ProductFormShow,
    SwalDeleteAction
  },
  data () {
    return {
      config: this.$config,
      showProduct: null,
      productChild: [],
      parentProduct: {},
      isParent: true,
      productUuid: null,
      oInventoryTransaction: null,
      parentImages: []
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oInventoryTransaction.clear()
      this.oInventoryTransaction.destroy()
      this.oInventoryTransaction = this.oInventoryTransactionDatatable()
      this.oInventoryTransaction.draw()
    }
  },
  methods: {
    ...mapActions("product", {
      setProductHasInventory: "setProductHasInventory",
      setProductActive: "setProductActive"
    }),
    resetData () {
      this.showProduct = null
      this.productChild = []
      this.parentProduct = {}
      this.isParent = true
      this.oInventoryTransaction = null
      this.parentImages = []
    },
    getProduct () {
      this.$isLoading(true)
      this.resetData()
      this.$axios.post(this.$config.base_url + "/api/product/get-product-by-id", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        this.$isLoading(false)
        if (response.data.code === 1) {
          const result = response.data.data.product
          result.is_active = "active"
          this.parentProduct = result
          if (this.parentProduct && this.parentProduct.images) {
            this.parentImages = this.parentProduct.images
          }
          this.showProduct = result

          if (result) {
            this.setProductActive(result.active)
            this.setProductHasInventory(result.has_inventory)
          }
          if (result.children !== null && result.children.length > 0) {
            const children = result.children
            children.forEach((item) => {
              item.is_active = ""
              if (item.warehouses && item.warehouses.length > 0) {
                item.child_warehouse = 0
                item.warehouses.forEach((warehouse) => {
                  item.child_warehouse += parseFloat(warehouse.pivot.amount)
                })
              }
            })
            this.showProduct.children = children
            this.productChild = children
          }
        }
        if (response.data.code === 0) {
          if (typeof (response.data.message) === "object") {
            this.message_validation = response.data.message
          } else {
            this.$toastr("error", response.data.message, "Product")
          }
        }
      })
    },
    selectedVariant (product) {
      this.productUuid = product.uuid
      this.isParent = false
      this.showProduct = product
      this.productChild.forEach((item) => {
        if (item.id === product.id) {
          item.is_active = "active"
        } else {
          item.is_active = ""
        }
      })
      this.oInventoryTransaction.draw(true)
    },
    selectedParent () {
      this.isParent = true
      this.productChild.forEach((item) => {
        item.is_active = ""
      })
      this.showProduct = this.parentProduct
      this.productUuid = this.showProduct.uuid
      this.oInventoryTransaction.draw(true)
    },
    deleteProduct () {
      this.$refs.swalDeleteAction.onClickDelete({
        id: this.showProduct.id,
        shop_id: this.get_shop.id
      })
    },
    oInventoryTransactionDatatable () {
      const self = this
      return $("#table-inventory-transaction").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: this.$config.base_url + "/api/inventory-transaction/data",
          type: "POST",
          data: (d) => {
            d.lang = this.$i18n.locale
            d.shop_id = this.get_shop.id
            d.uuid = this.productUuid
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "user",
            name: "user",
            searchable: false,
            title: this.$t("inventory.table.adjustedBy")
          },
          {
            data: "product_name",
            name: "product_name",
            searchable: false,
            orderable: false,
            title: this.$t("inventory.table.name")
          },
          {
            data: "amount_in_stock",
            name: "amount_in_stock",
            searchable: false,
            title: this.$t("inventory.table.amountInStock")
          },
          {
            data: "amount",
            name: "amount",
            searchable: false,
            title: this.$t("inventory.table.amount")
          },
          {
            data: "price",
            name: "price",
            searchable: false,
            orderable: false,
            title: this.$t("inventory.table.price")
          },
          {
            data: "type",
            name: "type",
            searchable: false,
            title: this.$t("inventory.table.action")
          },
          {
            data: "expired_date",
            name: "expired_date",
            searchable: false,
            title: this.$t("product.fields.expired_date.label")
          },
          {
            data: "import_date",
            name: "import_date",
            searchable: false,
            title: this.$t("inventory.table.date")
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        },
        order: [[4, "desc"]]
      })
    }
  },
  created () {
    this.productUuid = this.$route.params.uuid
    this.getProduct()
  },
  mounted () {
    this.$nextTick(() => {
      this.oInventoryTransaction = this.oInventoryTransactionDatatable()
    })
  },
  beforeDestroy () {
    this.setProductHasInventory(false)
    this.setProductActive(false)
  }
}
</script>

<style scoped>

</style>
