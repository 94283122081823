<template>
  <div class="h-100">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("inventories.strings.category.listingCategory") }}
            </h3>
            <div class="box-tools">
              <router-link
                :to="{name: 'order-product-category'}"
                class="btn btn-default btn-sm margin-r-5"
              >
                <i class="fas fa-sort-numeric-asc" /> {{ $t("inventories.button.category.orderCategory") }}
              </router-link>
              <router-link
                :to="{name: 'create-product-category'}"
                class="btn btn-primary btn-sm"
              >
                <i class="fas fa-plus-circle" /> {{ $t("inventories.button.category.newCategory") }}
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <table
              id="product-tag-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("food.table.image") }}</th>
                  <th>{{ $t("label.nameEn") }}</th>
                  <th>{{ $t("label.nameKm") }}</th>
                  <th>{{ $t("inventories.category.table.order") }}</th>
                  <th>{{ $t("inventories.category.table.createdAt") }}</th>
                  <th>{{ $t("inventories.category.table.action") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 bg-white rounded hidden-sm hidden-md hidden-lg scrollbar">
      <CategoryDataTableSm :window-width="windowWidth" />
    </div>
  </div>
</template>

<script>
import CategoryDataTableSm from "./_components/CategoryDataTableSm"

export default {
  name: "ListProductCategory",
  metaInfo () {
    return {
      title: this.$t("inventories.strings.category.listingCategory"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: { CategoryDataTableSm },
  data () {
    return {
      config: this.$config,
      oTable: null,
      windowWidth: null
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    windowWidth: function () {
      if (this.windowWidth > 768) {
        if (!this.oTable) {
          this.oTable = this.dataTable()
        }
      }
    },
    oTable: function () {
      this.reloadButtonAction()
    }
  },
  created () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  },
  mounted () {
    if (this.windowWidth > 768) {
      this.oTable = this.dataTable()
      this.reloadButtonAction()
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    deleteCategory (params) {
      const self = this
      self.$swal({
        title: this.swalTitle,
        text: this.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.swalYesButton,
        cancelButtonText: this.swalNoButton
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/product-category/delete", {
          ...params
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.category"))
            self.oTable.draw(true)
          }
        })
      })
    },
    dataTable () {
      const self = this
      return $("#product-tag-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        pageLength: 100,
        ajax: {
          url: self.config.base_url + "/api/product-category/data",
          type: "POST",
          data: {
            shop_id: self.get_shop.id,
            labelEdit: self.labelEdit,
            labelDelete: self.labelDelete,
            labelShow: self.labelShowTrans,
            labelViewStock: self.labelShowStock
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "image",
            name: "image",
            title: self.$t("food.table.image"),
            searchable: false,
            orderable: false
          },
          {
            data: "name_en",
            name: "product_categories.name_en",
            title: self.$t("label.nameEn")
          },
          {
            data: "name_km",
            name: "product_categories.name_km",
            title: self.$t("label.nameKm")
          },
          // {
          //   data: 'product_type.name_' + this.$i18n.locale,
          //   name: 'productType.name_' + this.$i18n.locale,
          //   title: self.$t('inventories.category.table.productType')
          // },
          {
            data: "order",
            name: "order",
            title: self.$t("inventories.category.table.order")
          },
          {
            data: "created_at",
            name: "product_categories.created_at",
            title: self.$t("inventories.category.table.createdAt"),
            searchable: false,
            orderable: true
          },
          {
            data: "actions",
            name: "actions",
            title: self.$t("inventories.category.table.action"),
            searchable: false,
            orderable: false
          }
        ],
        order: [
          [3, "desc"]
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    reloadButtonAction () {
      const self = this
      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-edit-product-category")
      $(document).off("click", ".btn-delete-product-category")

      $(document).on("click", ".btn-show", function () {
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "show-product-category",
          params: { uuid: uuid }
        })
      })

      $(document).on("click", ".btn-edit-product-category", function () {
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "edit-product-category",
          params: { uuid: uuid }
        })
      })

      $(document).on("click", ".btn-delete-product-category", function () {
        const uuid = $(this).attr("data-uuid")
        const params = {
          shop_id: self.get_shop.id,
          uuid: uuid
        }
        self.deleteCategory(params)
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>

</style>
