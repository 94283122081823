<template>
  <div class="row">
    <div class="col-xs-12">
      <card>
        <template slot="header">
          <div>
            <h3 class="m-0 font-weight-bold text-primary payment-subtitle">
              {{ $t("coupon.strings.couponOrderList") }}
            </h3>
          </div>
          <div class="box-tools">
            <router-link
              :to="{name: 'order-coupon'}"
              class="btn btn-primary btn-sm"
            >
              <i class="fas fa-plus-circle" /> {{ $t("coupon.button.orderCoupon") }}
            </router-link>
          </div>
        </template>
        <div class="row">
          <div class="col-md-12">
            <table
              id="coupon-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("coupon.table.orderNumber") }}</th>
                  <th>{{ $t("coupon.table.creator") }}</th>
                  <th>{{ $t("coupon.table.status") }}</th>
                  <th>{{ $t("coupon.table.createdAt") }}</th>
                  <th>{{ $t("coupon.table.action") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card"

export default {
  name: "CouponList",
  components: { Card },
  metaInfo () {
    return {
      title: this.$t("coupon.strings.couponOrderList"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      config: this.$config,
      oTable: null
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    oTable: function () {
      this.reloadButtonAction()
    }
  },
  methods: {
    deleteCouponOrder (uuid) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/coupon/invoice/delete", {
          shop_id: self.get_shop.id,
          uuid: uuid
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.coupon"))
            self.oTable.draw(true)
          }
        })
      })
    },
    dataTable () {
      const self = this
      return $("#coupon-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        ajax: {
          url: self.config.base_url + "/api/coupon/coupon-order-data-table",
          type: "POST",
          data: {
            shop_id: self.get_shop.id,
            labelEdit: self.labelEdit,
            labelDelete: self.labelDelete,
            labelShow: self.labelShowTrans
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "order_number",
            name: "order_number",
            title: self.$t("coupon.table.orderNumber")
          },
          {
            data: "creator",
            name: "creator",
            searchable: false,
            orderable: false,
            title: self.$t("coupon.table.creator")
          },
          {
            data: "status",
            name: "status",
            searchable: false,
            orderable: false,
            title: self.$t("coupon.table.status")
          },
          {
            data: "created_at",
            name: "created_at",
            searchable: false,
            orderable: true,
            title: self.$t("coupon.table.createdAt")
          },
          {
            data: "action",
            name: "action",
            searchable: false,
            orderable: false,
            title: self.$t("coupon.table.actions")
          }
        ],
        order: [[3, "desc"]],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    reloadButtonAction () {
      const self = this
      $(document).off("click", ".btn-coupon-order-show")
      $(document).off("click", ".btn-coupon-order-edit")
      $(document).off("click", ".btn-coupon-order-print")
      $(document).off("click", ".btn-coupon-order-delete")
      $(document).off("click", ".btn-coupon-order-payment")

      $(document).on("click", ".btn-coupon-order-show", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "show-coupon",
          params: {
            coupon_order_uuid: $(this).attr("data-uuid")
          }
        })
      })

      $(document).on("click", ".btn-coupon-order-edit", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "order-edit-coupon",
          params: {
            coupon_order_uuid: $(this).attr("data-uuid")
          }
        })
      })

      $(document).on("click", ".btn-coupon-order-print", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "print-coupon-cards",
          params: {
            coupon_order_uuid: $(this).attr("data-uuid")
          }
        })
      })

      $(document).on("click", ".btn-coupon-order-payment", function (e) {
        e.preventDefault()
        self.$router.push({
          name: "payment-coupon",
          params: {
            coupon_order_uuid: $(this).attr("data-uuid")
          }
        })
      })

      $(document).on("click", ".btn-coupon-order-delete", function (e) {
        e.preventDefault()
        self.deleteCouponOrder($(this).attr("data-uuid"))
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.reloadButtonAction()
  }
}
</script>

<style scoped>
.coupon {
  background: #F9F9F9;
  border: 0.225rem solid #43bf5f;
  border-radius: 0.5rem;
}
</style>
