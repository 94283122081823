<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4">
          <div
            :class="message_validation !== null ? 'has-error' : ''"
            class="form-group row"
          >
            <label class="col-md-3 required">{{ $t("fields.label.name") }}</label>
            <div class="col-md-9">
              <input
                v-model="menu.name"
                :placeholder="$t('fields.placeholder.name')"
                class="form-control"
                type="text"
              >
              <template v-if="message_validation !== null && message_validation.hasOwnProperty('name')">
                <span class="help-block">{{ message_validation["name"][0] }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group row">
            <label class="col-md-4 control-label">{{ $t("fields.label.startTime") }}</label>
            <div class="col-md-8">
              <datetime
                v-model="menu.start"
                :phrases="{ok: $t('button.ok'), cancel: $t('button.cancel')}"
                input-class="form-control"
                type="time"
                value-zone="Asia/Phnom_Penh"
                zone="Asia/Phnom_Penh"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group row">
            <label class="col-md-4 control-label">{{ $t("fields.label.endTime") }}</label>
            <div class="col-md-8">
              <datetime
                v-model="menu.end"
                :phrases="{ok: $t('button.ok'), cancel: $t('button.cancel')}"
                format="HH:mm"
                input-class="form-control"
                type="time"
                value-zone="Asia/Phnom_Penh"
                zone="Asia/Phnom_Penh"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div v-if="this.$route.name === 'menu-show'">
          <label class="col-sm-3">{{ $t("fields.label.active") }}</label>
          <div class="col-md-9">
            <label
              v-if="menu.active === true"
              class="control-label"
            >
              {{ $t("fields.label.active") }}
            </label>
            <label
              v-else
              class="control-label"
            >
              {{ $t("fields.label.inactive") }}
            </label>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="checkbox">
            <label>
              <input
                v-model="menu.active"
                type="checkbox"
              >
              {{ $t("fields.label.active") }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div
        :class="(message_validation !== null && message_validation.hasOwnProperty('products') ? 'has-error' : '')"
        class="form-group row"
        style="border-bottom: 1px solid #f4f4f4; margin-bottom: 10px"
      >
        <label class="col-sm-3">
          {{ $t("fields.product") }}
        </label>
        <template v-if="message_validation !== null && message_validation.hasOwnProperty('products')">
          <span class="help-block">{{ message_validation["products"][0] }}</span>
        </template>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <input
            v-model="search_query"
            :placeholder="$t('fields.placeholder.search')"
            class="form-control input-border-radius"
            type="text"
            @keyup="getProducts"
          >
        </div>
        <div class="col-md-12 product-list">
          <div class="product-list_item">
            <div
              v-for="(item, key) in products"
              :key="key"
              style="display: grid;"
            >
              <div class="product-item">
                <div style="width: 75px">
                  <img
                    v-if="item.images.length > 0 "
                    :src="$config.base_url + '/' + item.images[0].src"
                    alt="hehe"
                    width="50px"
                  >

                  <img
                    v-else
                    :src="defaultImg"
                    alt="hehe"
                    width="50px"
                  >
                </div>
                <div
                  class=""
                  style="width: calc(100% - 60px); display:flex; justify-content: space-between"
                >
                  <div>
                    <div>{{ item.name }}</div>
                    <div>
                      {{ item.currency ? item.currency.symbol : "" }}
                      {{ parseFloat(item.sale_price).toFixed(2) }}
                    </div>
                  </div>
                  <button
                    class="btn btn-success"
                    @click="addProduct(item)"
                  >
                    <i class="fas fa-plus" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4 style="margin-bottom: 20px">
        {{ $t("label.productAdded") }}
      </h4>
      <div class="form-group product-list_item_added">
        <div
          v-if="menu.products.length === 0"
          class="col-md-12 text-center"
          style="background-color: #f4f4f4; padding: 10px"
        >
          {{ $t("strings.noProduct") }}
        </div>
        <div v-else>
          <div
            v-for="(item, key) in menu.products"
            :key="key"
            style="display: grid;"
          >
            <div class="product-item">
              <div
                class=""
                style="width: 60px"
              >
                <img
                  v-if="item.images.length > 0"
                  :src="$config.base_url + '/' + item.images[0].src"
                  alt="hehe"
                  width="50px"
                >
                <img
                  v-else
                  :src="defaultImg"
                  alt="hehe"
                  width="50px"
                >
              </div>
              <div
                class=""
                style="width: calc(100% - 60px); display:flex; justify-content: space-between"
              >
                <div class="">
                  <div>{{ item.name }}</div>
                  <div>
                    {{ item.currency ? item.currency.symbol : "" }}
                    {{ parseFloat(item.sale_price).toFixed(2) }}
                  </div>
                </div>
                <button
                  class="btn btn-danger"
                  @click="removeProduct(item.id)"
                >
                  <i class="fas fa-trash-alt" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 margin-top">
      <template v-if="$route.name === 'menu-create' || $route.name === 'menu-list'">
        <button
          class="btn btn-primary btn-sm"
          @click="storeMenus"
        >
          <i class="fas fa-check-circle" />
          {{ $t("button.save") }}
        </button>
      </template>
      <template v-if="$route.name === 'menu-edit'">
        <button
          class="btn btn-info btn-sm"
          @click="storeMenus"
        >
          <i class="fas fa-check-circle" />
          {{ $t("button.update") }}
        </button>
      </template>
      <a
        class="btn btn-default btn-sm"
        @click="$router.back()"
      >
        <i class="fas fa-arrow-circle-left" />
        {{ $t("button.back") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuForm",
  props: {
    isUsedByModal: {
      type: Boolean,
      default: false
    },
    modalId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      menu: {
        uuid: "",
        name: "",
        icon: "",
        active: true,
        images: "",
        photo: "",
        products: []
      },
      cropped: null,
      message_validation: null,
      products: [],
      config: this.$config,
      search_query: ""
    }
  },
  methods: {
    clickToBrowse () {
      $("#menu-image").click()
    },
    crop () {
      const options = {
        format: "png",
        circle: false
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output
      })
    },
    result (output) {
      this.menu.photo = output
    },
    rotate (rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle)
    },

    getSrc (src) {
      if (src.includes("http")) {
        return src
      } else {
        return config.base_url + "/" + src
      }
    },
    getMenu () {
      this.$isLoading(true)
      const self = this
      this.message_validation = null
      this.$axios
        .post(this.$config.base_url + "/api/menu/show", {
          shop_uuid: this.$config.shop_uuid,
          uuid: this.$route.params.uuid
        })
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1 && response.data.data !== null) {
            self.menu = response.data.data
          }
        })
    },

    storeMenus () {
      this.$isLoading(true)

      this.menu.shop_uuid = config.shop_uuid
      this.$axios.post(this.$config.base_url + "/api/menu/store", this.menu)
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1) {
            this.goBack("menu-list")
            if (this.$route.params.hasOwnProperty("uuid")) {
              this.$anotify(this.$t("model.menu"), "update")
            } else {
              this.$anotify(this.$t("model.menu"))
            }
          } else {
            this.message_validation = response.data.message
          }
        })
    },
    goBack (nameRoute) {
      if (this.isUsedByModal) {
        $("#" + this.modalId).modal("toggle")
        this.$emit("refreshMenu")
      } else {
        this.$router.push({ name: nameRoute })
      }
    },

    getProducts () {
      this.$axios.post(this.$config.base_url + "/api/product", {
        shop_id: this.get_shop.id,
        id: this.$route.params.id,
        search_query: this.search_query
      }).then((response) => {
        if (response.data.code === 1) {
          this.products = response.data.data
        }
      }).catch((error) => {
        this.$toastr("error", error, "Get Product")
      })
    },
    addProduct (product) {
      if (this.menu.products.length > 0) {
        for (let i = 0; i < this.menu.products.length; i++) {
          if (this.menu.products[i].id === product.id) {
            return this.$toastr("warning", this.$t("string.thisProductIsAlreadyAdded"), this.$t("string.addedProduct"))
          }
        }
        this.menu.products.push(product)
      } else {
        this.menu.products.push(product)
      }
    },
    removeProduct (id) {
      for (let i = 0; i < this.menu.products.length; i++) {
        if (this.menu.products[i].id === id) {
          this.menu.products.splice(i, 1)
        }
      }
    }
  },
  mounted () {
    this.getProducts()
    if (this.$route.params.hasOwnProperty("uuid")) {
      this.getMenu()
    }
  }
}
</script>

<style scoped>
.help-block {
  color: var(--danger);
}

.input-border-radius {
  display: block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: rgba(255, 255, 255, 1);
  background-image: none;
  border: 1px solid #ccc;
}

.product-list {
  margin-top: 15px;
}

.img-block {
  width: 60px;
  margin: 5px 15px 5px 5px;
}

.info-block {
  padding: 10px;
}

.product-content {
  min-height: 95px;
}

.product-detail,
.product-promotion,
.product-list_item_added {
  margin: 15px 0;
}

.product-list_item,
.product-list_item_added {
  max-height: 530px;
}

.product-promotion {
  max-height: 655px;
}

.product-promotion,
.product-list_item,
.product-list_item_added {
  overflow-y: auto;
  border: 1px solid #f4f4f4;
}

.product-item {
  display: flex;
  border: 1px solid rgb(232, 232, 232);
  padding: 7px;
  align-items: center;
  min-height: 75px;
}

.product-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
