import Currency from "@/views/settings/currency/Index"
import List from "@/views/settings/currency/List"
import Create from "@/views/settings/currency/Create"
import Show from "@/views/settings/currency/Show"
import Edit from "@/views/settings/currency/Edit"

export const currencyRouters = {
  path: "currency",
  component: Currency,
  children: [
    {
      path: "",
      name: "list-currency",
      component: List
    },
    {
      path: "create",
      name: "create-currency",
      component: Create
    },
    {
      path: "edit-currency/:uuid",
      name: "edit-currency",
      component: Edit
    },
    {
      path: "show-currency/:uuid",
      name: "show-currency",
      component: Show
    }
  ]
}
