<template>
  <div>
    <h3>{{ $t("label.displayCustomOrdered") }}</h3>
    <ul style="list-style: none; padding: 0; margin: 0;">
      <SecondDisplayDeepLink />

      <li class="">
        <a
          class="user-select-none"
          href="/display-order"
          target="_blank"
        >
          <i class="fas fa-desktop mr-2" /> {{ $t("label.displayCustomOrdered") }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import SecondDisplayDeepLink from "@/components/SecondDisplayDeepLink"

export default {
  name: "CustomerDisplayOption",
  components: { SecondDisplayDeepLink }
}
</script>

<style scoped>
ul > li {
  padding: 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 6px;
}
</style>
