<template>
  <div
    class="row"
    v-if="restaurant"
  >
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("restaurant.label.info") }}
          </h3>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="required">{{ $t("fields.label.name") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.name')"
                  class="form-control"
                  type="text"
                  v-model="restaurant.name"
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="required">{{ $t("fields.label.currency") }}</label>
                <template v-if="currencies.length > 0">
                  <select
                    :disabled="true"
                    :placeholder="$t('fields.placeholder.currency')"
                    class="form-control"
                    v-model="restaurant.currency_id"
                  >
                    <option
                      :key="key"
                      :value="currency.id"
                      v-for="(currency, key) in currencies"
                    >
                      {{ currency.code }}
                    </option>
                  </select>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.streetNumber") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.streetNumber')"
                  class="form-control"
                  type="text"
                  v-model="restaurant.street_number"
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.homeNumber") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.homeNumber')"
                  class="form-control"
                  type="text"
                  v-model="restaurant.home_number"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.village") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.village')"
                  class="form-control"
                  type="text"
                  v-model="restaurant.village"
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.commune") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.commune')"
                  class="form-control"
                  type="text"
                  v-model="restaurant.commune"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.district") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.district')"
                  class="form-control"
                  type="text"
                  v-model="restaurant.district"
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.province") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.province')"
                  class="form-control"
                  type="text"
                  v-model="restaurant.province"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.tel") }}</label>
                <the-mask
                  :mask="['###-###-###', '###-###-####']"
                  :placeholder="$t('fields.placeholder.tel')"
                  class="form-control"
                  v-model="restaurant.tel"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.fax") }}</label>
                <the-mask
                  :mask="['###-###-###', '###-###-####']"
                  :placeholder="$t('fields.placeholder.fax')"
                  class="form-control"
                  v-model="restaurant.fax"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.url") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.url')"
                  class="form-control"
                  type="url"
                  v-model="restaurant.website"
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.address") }}</label>
                <input
                  :placeholder="$t('fields.placeholder.address')"
                  class="form-control"
                  type="text"
                  v-model="restaurant.address"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>{{ $t("fields.label.description") }}</label>
                <textarea
                  :placeholder="$t('fields.placeholder.description')"
                  class="form-control"
                  rows="5"
                  v-model="restaurant.description"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <button
                  @click="onSubmitRestaurant"
                  class="btn btn-primary"
                >
                  <i class="fas fa-save" /> {{ $t("button.update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 p-xs">
      <restaurant-upload />

      <div class="row">
        <div class="col-sm-12">
          <CustomerDisplayOption />
        </div>
        <div class="col-sm-12">
          <div>
            <h3>{{ $t("label.configuration") }}</h3>
            <ul style="list-style: none; padding: 0; margin: 0;">
              <li class="d-flex align-items-center justify-content-between">
                <p class="margin-bottom-none">
                  <i class="fas fa-print mr-2" />
                  {{ $t("label.autoPopupPrintInvoice") }}
                </p>
                <toggle-button
                  v-model="restaurant.popup_print_invoice"
                  color="#32c851"
                  class="margin-bottom-none"
                  :sync="true"
                  :width="110"
                  :height="25"
                  :font-size="14"
                  @change="updateConfiguration"
                  :labels="{checked: $t('label.enable'), unchecked: $t('label.disable')}"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 hidden-xs">
      <ShopHistoryDataTable ref="historyDataTable" />
    </div>
    <div class="col-md-12 hidden-sm hidden-md hidden-lg">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("restaurant.history.history") }}
          </h3>
        </div>
        <div class="box-body no-padding">
          <div class="h-100 bg-white scrollbar in-box">
            <ShopHistoryDataTableSm :window-width="windowWidth" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestaurantUpload from "./Upload"
import ShopHistoryDataTable from "@/views/settings/restaurant/_components/ShopHistoryDataTable"
import ShopHistoryDataTableSm from "@/views/settings/restaurant/_components/ShopHistoryDataTableSm"
import CustomerDisplayOption from "@/components/CustomerDisplayOption"
import { debounce } from "debounce"

export default {
  name: "RestaurantDetail",
  components: {
    CustomerDisplayOption,
    ShopHistoryDataTableSm,
    RestaurantUpload,
    ShopHistoryDataTable
  },
  metaInfo () {
    return {
      title: this.$t("page.aboutShop"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      restaurant: {
        name: null,
        currency_id: null,
        street_number: null,
        home_number: null,
        village: null,
        commune: null,
        district: null,
        province: null,
        tel: null,
        fax: null,
        website: null,
        address: null,
        description: null
      },
      currencies: [],
      config: this.$config,
      windowWidth: null
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    getCurrencyOptions () {
      this.$axios.post(this.$config.base_url + "/api/restaurant/get-currency-options")
        .then((response) => {
          if (response.data.code === 1 && response.data.data !== null) {
            this.currencies = response.data.data
          } else {
            this.$router.push({ name: "Login" })
          }
        })
    },
    getRestaurant () {
      const shop = JSON.parse(localStorage.getItem("shop"))

      if (!shop) {
        this.$toastr(
          "error",
          this.$t("string.pleaseFollowTheThrowExceptionError"),
          this.$t("string.throwExceptionError")
        )
        return 0
      }

      this.$axios.post(this.$config.base_url + "/api/restaurant/show", {
        shop_uuid: shop.uuid
      }).then(({ data }) => {
        if (data.data) {
          this.restaurant = data.data
          localStorage.setItem("shop", JSON.stringify(this.restaurant))
          const shop = Object.assign({}, data.data)
          this.setShopProperty(shop)
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    onSubmitRestaurant () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/restaurant/store", this.restaurant)
        .then(({ data }) => {
          if (data.data) {
            const shop = data.data
            this.setShopProperty(shop)
            localStorage.setItem("shop", JSON.stringify(shop))
            this.onResponseSuccess()
            this.$refs.historyDataTable.oTable.draw(true)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    updateConfiguration: debounce(function () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/restaurant/update-configuration", {
        uuid: this.restaurant.uuid,
        popup_print_invoice: this.restaurant.popup_print_invoice
      }).then(({ data }) => {
        if (data.data) {
          const shop = data.data
          localStorage.setItem("shop", JSON.stringify(shop))
          this.setShopProperty(shop)
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }, 300)
  },
  mounted () {
    window.addEventListener("resize", this.onResize)
    this.onResize()
    this.getCurrencyOptions()
    this.getRestaurant()
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>
ul > li {
  padding: 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 6px;
}
</style>
