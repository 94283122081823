<template>
  <div class="content-wrapper">
    <section class="content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <router-view />
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "MenuIndex"
}
</script>
