<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t("restaurant.history.history") }}
      </h3>
    </div>
    <div class="box-body">
      <table
        class="table table-bordered table-hover"
        id="restaurant-history-table"
      >
        <thead>
          <tr>
            <th>{{ $t("restaurant.history.table.user") }}</th>
            <th>{{ $t("restaurant.history.table.createdAt") }}</th>
            <th>{{ $t("restaurant.history.table.action") }}</th>
          </tr>
        </thead>
        <tbody />
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopHistoryDataTable",
  data () {
    return {
      oTable: null,
      config: this.$config
    }
  },
  watch: {
    "$i18n.locale" () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $("#restaurant-history-table").DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.config.base_url + "/api/restaurant-history/data",
          type: "POST",
          data: {
            shop_id: self.get_shop && self.get_shop.id ? self.get_shop.id : null,
            labelShow: self.labelShowTrans
          },
          error: function (jqXHR, ajaxOptions, thrownError) {
            self.onResponseErrorDatatable(jqXHR, ajaxOptions, thrownError)
          }
        },
        columns: [
          {
            data: "user",
            name: "user",
            title: self.$t("restaurant.history.table.user")
          },
          {
            data: "created_at",
            name: "created_at",
            title: self.$t("restaurant.history.table.createdAt")
          },
          {
            data: "actions",
            name: "actions",
            searchable: false,
            orderable: false,
            title: self.$t("restaurant.history.table.action")
          }
        ],
        language: {
          url: `/locale/${localStorage.getItem("locale")}.json`
        }
      })
    },
    deleteRestaurantHistory (uuid) {
      const self = this
      self.$swal({
        title: self.swalTitle,
        text: self.swalDesc,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: self.swalYesButton,
        cancelButtonText: self.swalNoButton
      }).then(() => {
        self.$axios.post(self.config.base_url + "/api/restaurant-history/delete", {
          shop_id: self.get_shop.id,
          uuid: uuid
        }).then((response) => {
          if (response.data.code === 1) {
            self.$aswaldelete(self.$t("model.restaurant"))
            self.oTable.draw(self)
          }
        })
      })
    },
    reloadButtonAction () {
      const self = this

      $(document).off("click", ".btn-show")
      $(document).off("click", ".btn-delete")

      $(document).on("click", ".btn-show", function (e) {
        e.preventDefault()
        const uuid = $(this).attr("data-uuid")
        self.$router.push({
          name: "restaurant-history-detail",
          params: { uuid: uuid }
        })
      })

      $(document).on("click", ".btn-delete", function (e) {
        e.preventDefault()
        const uuid = $(this).attr("data-uuid")
        self.deleteRestaurantHistory(uuid)
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.reloadButtonAction()
  }
}
</script>

<style scoped>

</style>
