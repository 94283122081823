<template>
  <div class="register-container">
    <div class="register-form-content">
      <div class="register-form-content-wrapper shadow">
        <div class="margin-bottom pt-3 text-center">
          <router-link :to="{name: 'Login'}">
            <div class="register-logo">
              <img
                src="/img/logos/logo.png"
                class="tw-w-full"
                alt="Logo"
              >
            </div>
          </router-link>
        </div>
        <template>
          <div class="register-form-content_title">
            {{ $t("string.resetYourPassword") }}
          </div>
          <div class="register-form-content_sub_title text-muted">
            {{ $t("string.pleaseSetYourPasswordAtLeast8Digits") }}
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  :class="{'has-error': hasErrorValidate('password')}"
                  class="required"
                >
                  {{ $t("fields.placeholder.password") }}
                </label>
                <password
                  v-model="password"
                  :badge="false"
                  :placeholder="$t('fields.placeholder.password')"
                  :secure-length="8"
                  :toggle="true"
                  class="max-w-100"
                  default-class="pos-input pos-input-lg"
                />
                <span
                  v-if="hasErrorValidate('password')"
                  class="label-error has-error"
                >
                  {{ validations["password"][0] }}
                </span>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label
                  :class="{'has-error': hasErrorValidate('password_confirmation')}"
                  class="required"
                >
                  {{ $t("fields.placeholder.confirmPassword") }}
                </label>
                <input
                  v-model="password_confirmation"
                  :placeholder="$t('fields.placeholder.confirmPassword')"
                  class="pos-input pos-input-lg"
                  type="password"
                >
                <span
                  v-if="hasErrorValidate('password_confirmation')"
                  class="label-error has-error help-block"
                >
                  {{ validations["password_confirmation"][0] }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between w-100">
            <div />
            <button
              :class="{'disabled': onloading_check_email}"
              :disabled="onloading_check_email"
              class="btn btn-secondary btn-lg"
              @click="clickRenewPassword"
            >
              <template v-if="onloading_check_email">
                <i class="fas fa-circle-notch fa-spin" />
              </template>
              <template v-else>
                {{ $t("button.submit") }}
              </template>
            </button>
          </div>
        </template>
        <div class="w-100 register-footer">
          <p class="text-center">
            {{ $t("string.copyright") }} &copy; 2021 <a href="https://figos.app">Figos.app</a>
            <span class="d-none">Powered by <a href="https://asorasoft.com">Asorasoft.com</a></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter"
import { mapState } from "vuex"

export default {
  name: "RenewPassword",
  components: {
    Password
  },
  metaInfo () {
    return {
      title: this.$t("string.resetPassword"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  data () {
    return {
      password: null,
      password_confirmation: null,
      validations: null,
      onloading_check_email: false
    }
  },
  mounted () {
    if (!this.user) {
      this.$router.replace({ name: "Login" })
    }
  },
  methods: {
    hasErrorValidate (field) {
      return this.validations && this.validations.hasOwnProperty(field)
    },
    clickRenewPassword () {
      this.validations = {}
      this.onloading_check_email = true
      setTimeout(() => {
        this.renewPassword()
      }, 800)
    },
    renewPassword () {
      this.$axios.post(this.$config.base_url + "/api/auth/renew-password", {
        uuid: this.user.uuid,
        password: this.password,
        password_confirmation: this.password_confirmation
      }).then(() => {
        this.$router.push({
          name: "Login"
        })

        this.$toastr(
          "success",
          this.$t("string.thePasswordHasBeenUpdatedSuccessfully"),
          this.$t("label.resetPassword")
        )

        this.$store.dispatch("user/updateUser", null)
      }).catch((errors) => {
        if (errors.response && errors.response.status && errors.response.status === 422) {
          this.validations = errors.response.data.errors
        } else {
          this.onResponseError(errors)
        }
      }).finally(() => {
        this.onloading_check_email = false
      })
    }
  }
}
</script>

<style scoped>
@import "./../assets/css/V2/front-page.css";
</style>
