<template>
  <div
    v-if="aba_account"
    class="row"
  >
    <div class="col-sm-12 text-center">
      <h3>{{ $t("string.accountPaymentInfo") }}</h3>
    </div>
    <div class="col-sm-12 text-center">
      <qrcode
        :options="{ width: 200 }"
        :value="aba_account"
        class="qrcode-border margin-bottom"
      />
      <div>
        <p class="font-s-16">
          {{ $t("label.account") }} ABA : {{ aba_account }}
        </p>
        <p class="font-s-16">
          {{ $t("label.name") }} : {{ aba_name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountPaymentInfo",
  data () {
    return {
      aba_account: process.env.VUE_APP_ABA_ACCOUNT,
      aba_name: process.env.VUE_APP_ABA_NAME
    }
  }
}
</script>

<style scoped>
.qrcode-border {
  border: 1px solid #edeeee;
  border-radius: 0.25rem;
}
</style>
