<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <div class="row">
            <div class="col-md-6">
              <div
                :class="(message_validation !== null && message_validation.hasOwnProperty('name') ? 'has-error' : '')"
                class="form-group"
              >
                <label class="required">{{ $t("fields.label.name") }}</label>
                <input
                  v-model="promotion.name"
                  :placeholder="$t('fields.placeholder.name')"
                  class="form-control input-lg"
                  type="text"
                >
                <template
                  v-if="message_validation !== null && message_validation.hasOwnProperty('name')"
                >
                  <span class="help-block">{{ message_validation["name"][0] }}</span>
                </template>
              </div>
            </div>
            <div class="col-md-6">
              <div
                :class="message_validation && message_validation.hasOwnProperty('amount_percentage') ? 'has-error' : ''"
                class="form-group"
              >
                <label class="required">
                  {{
                    promotion.is_percentage ? $t("promotion.label.amountPercentage") : $t("promotion.label.amountCash")
                  }}
                </label>
                <div class="input-group input-group-lg">
                  <input
                    v-model="promotion.amount_percentage"
                    :placeholder="$t('fields.placeholder.amountPercentage')"
                    class="form-control"
                    type="number"
                  >
                  <div class="input-group-btn">
                    <button
                      :class="promotion.is_percentage ? 'btn-primary' : 'btn-default'"
                      class="btn"
                      @click="promotion.is_percentage = true"
                    >
                      <i class="fas fa-percent" />
                    </button>
                    <button
                      :class="!promotion.is_percentage ? 'btn-primary' : 'btn-default'"
                      class="btn"
                      @click="promotion.is_percentage = false"
                    >
                      {{ currency_code }}
                    </button>
                  </div>
                </div>
                <template
                  v-if="message_validation !== null && message_validation.hasOwnProperty('amount_percentage')"
                >
                  <span class="help-block">{{ message_validation["amount_percentage"][0] }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div
                :class="message_validation !== null && message_validation.hasOwnProperty('start') ? 'has-error' : ''"
                class="form-group"
              >
                <label class="required">{{ $t("fields.label.promotion_date") }}</label>
                <date-picker
                  v-model="promotion.date"
                  :input-class="'form-control input-lg'"
                  :not-before="new Date()"
                  :shortcuts="false"
                  :time-picker-options="time_picker_options"
                  format="YYYY-MM-DD HH:mm:ss A"
                  lang="en"
                  range
                  style="width: 100%"
                  type="datetime"
                />
                <template v-if="message_validation && message_validation.hasOwnProperty('start')">
                  <span class="help-block">The promotion date field is required.</span>
                </template>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("fields.label.active") }}</label>
                <div style="margin: 8px 0">
                  <toggle-button
                    :font-size="16"
                    :height="30"
                    :labels="{checked: $t('label.active'), unchecked: $t('label.inactive')}"
                    :sync="true"
                    :value="promotion.active"
                    :width="100"
                    class="no-margin"
                    color="#1876f2"
                    @change="promotion.active = !promotion.active"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                :class="{ 'has-error': message_validation && message_validation.hasOwnProperty('description') }"
                class="form-group"
              >
                <label>{{ $t("fields.label.description") }}</label>
                <textarea
                  v-model="promotion.description"
                  :placeholder="$t('fields.placeholder.description')"
                  class="form-control"
                  rows="5"
                  type="text"
                />
                <template v-if="message_validation && message_validation.hasOwnProperty('description')">
                  <span class="help-block">{{ message_validation["description"][0] }}</span>
                </template>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div
                :class="(message_validation !== null && message_validation.hasOwnProperty('products') ? 'has-error' : '')"
                class="form-group"
                style="border-bottom: 1px solid #f4f4f4; margin-bottom: 10px"
              >
                <label class="required">
                  {{ $t("fields.productName") }}
                </label>
                <template
                  v-if="message_validation !== null && message_validation.hasOwnProperty('products')"
                >
                  <span class="help-block">{{ message_validation["products"][0] }}</span>
                </template>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  v-model="search_query"
                  :placeholder="$t('fields.placeholder.search')"
                  class="form-control input-border-radius"
                  type="text"
                  @keyup="getProducts"
                >
              </div>
              <div class="form-group product-list">
                <div class="product-list_item scrollbar">
                  <template v-if="products && Array.isArray(products) && products.length > 0">
                    <template v-for="(item, key) in products">
                      <div
                        v-if="products.length > 0"
                        :key="key"
                        style="display: grid;"
                      >
                        <div class="product-item">
                          <div style="width: 75px">
                            <img
                              v-if="item.images.length > 0 "
                              :src="$config.base_url + '/' + item.images[0].src"
                              alt="product_image"
                              width="50px"
                            >
                            <img
                              v-else
                              :src="defaultImg"
                              alt="product_image"
                              width="50px"
                            >
                          </div>
                          <div style="width: calc(100% - 60px); display:flex; justify-content: space-between">
                            <div>
                              <div>{{ item.name }}</div>
                              <div>
                                {{ item.currency ? item.currency.code : "USD" }}
                                <template v-if="item.currency && item.currency.code === 'KHR'">
                                  {{ parseFloat(item.sale_price)| numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ parseFloat(item.sale_price) | numFormat("0,0.00") }}
                                </template>
                              </div>
                            </div>
                            <button
                              class="btn btn-success"
                              @click="addProduct(item)"
                            >
                              <i class="fas fa-plus" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <div
                    v-else
                    class="col-md-12 text-center"
                    style="background-color: #f4f4f4; padding: 10px"
                  >
                    {{ $t("strings.noProduct") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4 style="margin-bottom: 20px">
                {{ $t("label.productAdded") }}
              </h4>
              <div class="form-group product-list_item_added scrollbar">
                <div v-if="promotion && Array.isArray(promotion.products) && promotion.products.length > 0">
                  <div
                    v-for="(item, key) in promotion.products"
                    :key="key"
                    style="display: grid;"
                  >
                    <div class="product-item">
                      <div style="width: 75px">
                        <img
                          v-if="item.images.length > 0"
                          :src="$config.base_url + '/' + item.images[0].src"
                          alt="product_image"
                          width="50px"
                        >
                        <img
                          v-else
                          :src="defaultImg"
                          alt="product_image"
                          width="50px"
                        >
                      </div>
                      <div style="width: calc(100% - 60px); display:flex; justify-content: space-between">
                        <div>
                          <div>{{ item.name }}</div>
                          <div>
                            {{ item.currency ? item.currency.code : "USD" }}
                            <template v-if="item.currency && item.currency.code === 'KHR'">
                              {{ parseFloat(item.sale_price)| numFormat("0,0") }}
                            </template>
                            <template v-else>
                              {{ parseFloat(item.sale_price) | numFormat("0,0.00") }}
                            </template>
                          </div>
                        </div>
                        <button
                          class="btn btn-danger"
                          @click="removeProduct(item)"
                        >
                          <i class="fas fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="col-md-12 text-center"
                  style="background-color: #f4f4f4; padding: 10px"
                >
                  {{ $t("strings.noProduct") }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 margin-top">
              <div class="form-group text-right">
                <template v-if="this.$route.name === 'create-promotion'">
                  <button
                    class="btn btn-primary"
                    @click="storePromotion"
                  >
                    <i class="fas fa-check-circle" /> {{ $t("button.save") }}
                  </button>
                </template>
                <template v-else>
                  <button
                    class="btn btn-primary"
                    @click="storePromotion"
                  >
                    <i class="fas fa-check-circle" /> {{ $t("button.update") }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from "vue2-datepicker"

export default {
  name: "PromotionForm",
  components: { DatePicker },
  props: {
    title: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      promotion: {
        uuid: null,
        shop_uuid: null,
        name: "",
        description: "",
        amount_percentage: 0,
        start: null,
        end: null,
        active: false,
        is_percentage: true,
        products: [],
        date: []
      },
      changeLayout: "list",
      products: [],
      config: this.$config,
      message_validation: null,
      show_promotion: false,
      search_query: "",
      time_picker_options: {
        start: "00:00",
        step: "00:30",
        end: "23:30"
      }
    }
  },
  computed: {
    checkPromotionStart () {
      return this.promotion.start ? new Date() : this.promotion.start
    }
  },
  watch: {
    "promotion.amount_percentage": function () {
      this.checkPromotionAmount()
    },
    "promotion.is_percentage": function () {
      this.checkPromotionAmount()
    }
  },
  methods: {
    checkPromotionAmount () {
      if (this.promotion.is_percentage) {
        if (this.promotion.amount_percentage < 0) {
          this.promotion.amount_percentage = 0
        } else if (this.promotion.amount_percentage > 100) {
          this.promotion.amount_percentage = 100
        }
      }
    },
    getPromotion () {
      this.$axios.post(this.$config.base_url + "/api/promotion/show", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        const result = response.data.data
        if (response.data.code === 1) {
          this.promotion = {
            uuid: result.uuid,
            shop_id: result.shop_id,
            name: result.name,
            is_percentage: result.is_percentage,
            description: result.description,
            amount_percentage: result.amount_percentage,
            start: result.start,
            end: result.end,
            active: result.active,
            date: [result.start, result.end],
            products: result.products
          }
        }
      }).catch((error) => {
        this.$toastr("error", error, "Get Promotion")
      })
    },
    getProducts () {
      this.$axios.post(this.$config.base_url + "/api/product", {
        shop_id: this.get_shop.id,
        search_query: this.search_query
      }).then((response) => {
        if (response.data.code === 1) {
          this.products = response.data.data
        }
      }).catch((error) => {
        this.$toastr("error", error, "Get Product")
      })
    },
    addProduct (product) {
      if (this.promotion.products.length > 0) {
        for (let i = 0; i < this.promotion.products.length; i++) {
          if (this.promotion.products[i].id === product.id) {
            return this.$toastr("warning", this.$t("string.theProductIsAlreadyAdded"), this.$t("string.warning"))
          }
        }

        this.promotion.products.push(product)
      } else {
        this.promotion.products.push(product)
      }
      this.products.splice(this.products.indexOf(product), 1)
    },
    removeProduct (product) {
      for (let i = 0; i < this.promotion.products.length; i++) {
        if (this.promotion.products[i].id === product.id) {
          this.promotion.products.splice(i, 1)
        }
      }
      this.products.push(product)
    },
    storePromotion () {
      this.$isLoading(true)
      this.promotion.shop_uuid = this.get_shop.uuid
      if (this.promotion.date.length > 0) {
        this.promotion.start = this.promotion.date[0] ? this.promotion.date[0].toLocaleString() : null
        this.promotion.end = this.promotion.date[1] ? this.promotion.date[1].toLocaleString() : null
      }
      this.$axios
        .post(this.$config.base_url + "/api/promotion/store", this.promotion)
        .then(() => {
          this.$router.push({ name: "list-promotion" })
          if (this.$route.params.uuid) {
            this.$toastr("success", this.$t("string.thePromotionWasUpdatedSuccessfully"), this.$t("string.success"))
          } else {
            this.$toastr("success", this.$t("string.thePromotionWasCreatedSuccessfully"), this.$t("string.success"))
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.message_validation = error.response.data.message
          } else {
            this.$toastr("error", error.response.data.message["message_" + this.$i18n.locale], error.response.status)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    this.getProducts()
    if (this.$route.params.uuid) {
      this.promotion.uuid = this.$route.params.uuid
      this.getPromotion()
    }
  }
}
</script>

<style scoped>
.input-border-radius {
  display: block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: rgba(255, 255, 255, 1);
  background-image: none;
  border: 1px solid #ccc;
}

.product-list {
  margin-top: 15px;
}

.img-block {
  width: 60px;
  margin: 5px 15px 5px 5px;
}

.info-block {
  padding: 10px;
}

.product-content {
  min-height: 95px;
}

.product-detail,
.product-promotion,
.product-list_item_added {
  margin: 15px 0;
}

.product-list_item,
.product-list_item_added {
  max-height: 350px;
}

.product-promotion {
  max-height: 655px;
}

.product-promotion,
.product-list_item,
.product-list_item_added {
  overflow-y: auto;
  border: 1px solid #f4f4f4;
}

.product-item {
  display: flex;
  border: 1px solid rgb(232, 232, 232);
  padding: 7px;
  align-items: center;
  min-height: 75px;
}

.product-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

</style>
