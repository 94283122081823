<template>
  <div class="order-page-container w-100">
    <template v-if="!on_check_order">
      <template v-if="allowed">
        <div class="block-product-list flex-column">
          <div>
            <div
              :class="{ 'block-category-hx2 shadow-bottom': sell_mode !== normal_mode}"
              class="block-search-with-category"
            >
              <template v-if=" sell_mode === quick_mode">
                <div class="d-flex justify-content-between w-100 h-100 align-items-center">
                  <div class="row no-margin w-100">
                    <div class="pl-0 col-xs-10 col-lg-11">
                      <div class="box-search box-search-mb w-100 search-sh">
                        <div class="box-search-icon margin-l-5">
                          <svg
                            aria-hidden="true"
                            class="svg-inline--fa fa-barcode-read fa-w-20 fa-9x"
                            data-icon="barcode-read"
                            data-prefix="fas"
                            focusable="false"
                            role="img"
                            viewBox="0 0 640 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              class=""
                              d="M184 128h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-40 320H64v-80c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16v128c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm104-320h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zM16 160h32c8.8 0 16-7.2 16-16V64h80c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H16C7.2 0 0 7.2 0 16v128c0 8.8 7.2 16 16 16zm392-32h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-96 0h-16c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zM624 0H496c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v80c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zm0 352h-32c-8.8 0-16 7.2-16 16v80h-80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16zm-112 24V136c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v240c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <input
                          v-model="search_query"
                          :placeholder="$t('label.scan') + '...'"
                          class="pos-input-search pos-input-lg no-border"
                          type="text"
                        >
                      </div>
                    </div>
                    <div class="px-0 text-right no-margin col-xs-2 col-lg-1">
                      <div>
                        <button
                          :title="$t('label.quickSell')"
                          class="btn btn-primary btn-lg no-margin btn-block search-sh"
                          @click="passValueToChangeSellMode"
                        >
                          <svg
                            aria-hidden="true"
                            class="svg-inline--fa fa-desktop-alt fa-w-18 fa-9x"
                            data-icon="desktop-alt"
                            data-prefix="fal"
                            focusable="false"
                            role="img"
                            viewBox="0 0 576 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              class=""
                              d="M528 0H48C21.5 0 0 21.5 0 48v288c0 26.5 21.5 48 48 48h192l-24 96h-72c-8.8 0-16 7.2-16 16s7.2 16 16 16h288c8.8 0 16-7.2 16-16s-7.2-16-16-16h-72l-24-96h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM249 480l16-64h46l16 64h-78zm295-144c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-48h512v48zm0-80H32V48c0-8.8 7.2-16 16-16h480c8.8 0 16 7.2 16 16v208z"
                              fill="currentColor"
                            />
                          </svg>
                          <span class="hide-label-sm" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="block-search-box">
                  <div class="box-search box-search-mb w-100">
                    <div class="box-search-icon margin-l-5">
                      <i class="fas fa-search" />
                    </div>
                    <input
                      v-model="search_query"
                      :placeholder="$t('label.search')"
                      class="pos-input-search pos-input-lg no-border"
                      type="text"
                    >
                  </div>
                </div>
                <div class="block-btn-actions bba-2">
                  <div
                    class="btn-filter-sm mr-2 hidden-lg"
                    style=" vertical-align: bottom"
                  >
                    <div
                      :title="$t('label.filter')"
                      class="user-select-none list-category-item on-page-order filter-category-main-item"
                      data-placement="top"
                      data-target="#categoryModal"
                      data-toggle="modal"
                      style="padding-top: 8px !important;"
                    >
                      <h4 class="list-category-label">
                        <i class="fas fa-filter" />
                        <span
                          v-if="countFilter > 0"
                          class="ml-2"
                        >({{ countFilter }})</span>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="btn-group btn-group-lg switch-view"
                    role="group"
                  >
                    <button
                      :class="{'btn-active': view_mode === 'grid'}"
                      :title="$t('label.viewAsGrid')"
                      class="btn btn-default outline-none"
                      type="button"
                      @click="changeViewModeProductOrder('grid')"
                    >
                      <i class="fas fa-th" />
                    </button>
                    <button
                      :class="{'btn-active': view_mode === 'list'}"
                      :title="$t('label.viewAsList')"
                      class="btn btn-default outline-none"
                      type="button"
                      @click="changeViewModeProductOrder('list')"
                    >
                      <i class="fas fa-th-list" />
                    </button>
                  </div>
                  <div class="align-top btn-quick-mode">
                    <button
                      :title="$t('label.normalSell')"
                      class="btn btn-primary btn-lg no-margin"
                      @click="passValueToChangeSellMode"
                    >
                      <i class="fas fa-bolt" />
                      <span class="hide-label-sm ml-2">
                        {{ $t("label.quickSell") }}
                      </span>
                    </button>
                  </div>
                </div>
                <div class="block-btn-actions bba-1 hidden-sm hidden-md hidden-lg">
                  <button
                    aria-controls="collapseExample"
                    aria-expanded="false"
                    class="btn btn-lg btn-default bg-white no-margin has-shadow"
                    data-target="#collapseExample"
                    data-toggle="collapse"
                    type="button"
                  >
                    <i class="fas fa-ellipsis-h text-muted" />
                  </button>
                </div>
                <div
                  id="collapseExample"
                  class="collapse hidden-sm hidden-md hidden-lg"
                >
                  <div class="py-3 text-right">
                    <div
                      class="btn-filter-sm mr-2 hidden-lg"
                      style=" vertical-align: bottom"
                    >
                      <div
                        :title="$t('label.filter')"
                        class="user-select-none list-category-item on-page-order filter-category-main-item"
                        data-placement="top"
                        data-target="#categoryModal"
                        data-toggle="modal"
                        style="padding-top: 8px !important;"
                      >
                        <h4 class="list-category-label">
                          <i class="fas fa-filter" />
                          <span
                            v-if="countFilter > 0"
                            class="ml-2"
                          >({{ countFilter }})</span>
                        </h4>
                      </div>
                    </div>
                    <div
                      class="btn-group btn-group-lg switch-view"
                      role="group"
                    >
                      <button
                        :class="{'btn-active': view_mode === 'grid'}"
                        :title="$t('label.viewAsGrid')"
                        class="btn btn-default outline-none"
                        type="button"
                        @click="changeViewModeProductOrder('grid')"
                      >
                        <i class="fas fa-th" />
                      </button>
                      <button
                        :class="{'btn-active': view_mode === 'list'}"
                        :title="$t('label.viewAsList')"
                        class="btn btn-default outline-none"
                        type="button"
                        @click="changeViewModeProductOrder('list')"
                      >
                        <i class="fas fa-th-list" />
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="sell_mode === normal_mode"
              class="block-category hide-block-category shadow-bottom d-flex align-items-center"
            >
              <div
                v-if="categories.length > 0"
                class="order-list-category overflow-x-auto scrollbar-x"
              >
                <div
                  :title="$t('label.filter')"
                  class="user-select-none list-category-item on-page-order filter-category-main-item"
                  data-placement="top"
                  data-target="#categoryModal"
                  data-toggle="modal"
                >
                  <h4 class="list-category-label">
                    <i class="fas fa-filter" />
                  </h4>
                </div>
                <div class="separate" />
                <template v-for="(category, key) in categories">
                  <div
                    :key="key"
                    v-if="key < 6"
                    :class="`${category.active ? 'active' : ''}`"
                    :title="category['name_' + $i18n.locale]"
                    class="user-select-none list-category-item on-page-order item-category-sugg"
                    style="width: auto; max-width: unset"
                    @click="onClickCategory(category)"
                  >
                    <template v-if="category.image">
                      <img
                        alt="Category image"
                        :src="`${$config.base_url}${replaceImgUrl(category.image, 'small')}`"
                        class="img-category-sugg"
                      >
                    </template>
                    <template v-else-if="category.id === -1">
                      <img
                        :src="categoryAllImg"
                        alt="Category image"
                        class="img-category-sugg"
                      >
                    </template>
                    <template v-else>
                      <img
                        alt="Category image"
                        :src="defaultImg"
                        class="img-category-sugg"
                      >
                    </template>
                    <h4 class="list-category-label">
                      {{ category["name_" + $i18n.locale] }}
                    </h4>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <template v-if="onLoading">
            <div class="d-flex align-items-center justify-content-center on-loading-layout">
              <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
            </div>
          </template>
          <template v-else>
            <template v-if="!(list_products.length > 0 || customProducts.length > 0)">
              <NoAnyRecords>
                <template v-if="hasRoles(['owner'])">
                  <div class="text-center padding-top margin-top">
                    <router-link
                      :to="{name: 'create-product'}"
                      class="btn btn-primary"
                    >
                      <i class="fas fa-cube mr-2" />
                      {{ $t("button.create_product") }}
                    </router-link>
                  </div>
                </template>
              </NoAnyRecords>
            </template>
            <template v-else>
              <div
                v-if="searching || sell_mode === normal_mode"
                class="flex-1 overflow-y-auto scrollbar plc"
              >
                <div class="products-listing">
                  <div
                    :class="countItems > 0 ? order_discount && order_discount > 0 ? 'products-listing-content_mb-sm-2' : 'products-listing-content_mb-sm-1': ''"
                    class="products-listing-content"
                  >
                    <template v-if="customProducts !== null && customProducts.length > 0 && !searching">
                      <template v-for="(customProduct, key) in customProducts">
                        <template v-if="view_mode === 'list'">
                          <AsoraCustomProductItemList
                            :key="key"
                            :custom-product="customProduct"
                          />
                        </template>
                        <template v-else>
                          <AsoraCustomProductItemGrid
                            :key="key"
                            :custom-product="customProduct"
                          />
                        </template>
                      </template>
                    </template>
                    <template v-for="(product_item, key) in list_products">
                      <template v-if="view_mode === 'list'">
                        <AsoraProductItemList
                          :key="key"
                          :product="product_item"
                        />
                      </template>
                      <template v-else>
                        <AsoraProductItemGrid
                          :key="key"
                          :product="product_item"
                        />
                      </template>
                    </template>
                  </div>
                </div>
                <infinite-loading
                  :identifier="infiniteId"
                  spinner="spiral"
                  @infinite="infiniteHandler"
                >
                  <div slot="no-more" />
                </infinite-loading>
              </div>
            </template>
          </template>
        </div>
        <div class="block-order-list flex-column show-order-pro-sm">
          <div class="block-category op-block-category-btn-action">
            <div class="d-flex align-items-center h-100 justify-content-between">
              <div class="hidden-sm hidden-md hidden-lg">
                <button
                  class="btn btn-lg btn-default dismiss-order-pro-sm"
                  @click="closeDrawerCustomer"
                >
                  <i class="fas fa-arrow-left" />
                </button>
              </div>
              <div>
                <label
                  v-if="order"
                  class="font-s-18 no-margin"
                >#{{ order.order_number }}</label>
              </div>
              <div class="d-flex align-items-center">
                <div class="position-relative mr-2">
                  <button
                    v-if="$route.params.orderFrom === 'order'"
                    :title="$t('button.park_sale')"
                    class="btn btn-default btn-lg dismiss-order-pro-sm"
                    @click="onClickParkSale"
                  >
                    <i class="fas fa-history" />
                    <span class="hide-label-sm ml-2">{{ $t("button.parkSale") }}</span>
                  </button>
                  <span
                    v-if="parseInt(numberParkOrder) > 0"
                    class="badge bg-red badge-count-hold-sale"
                  >{{ numberParkOrder }}</span>
                </div>
                <div>
                  <button
                    :class="!(orderProducts.length > 0) ? 'disabled' : ''"
                    :disabled="!(orderProducts.length > 0)"
                    :title="$t('label.newOrder')"
                    class="btn btn-lg btn-success mr-0 dismiss-order-pro-sm"
                    @click="onClickNewOrder"
                  >
                    <i class="fas fa-cart-plus" />
                    <span class="ml-2 hide-label-sm">{{ $t("order.label.newOrder") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <OrderProduct
            ref="listOrderProduct"
            :order_from="this.$route.params.orderFrom"
            @pay="storeOrderItems('payment')"
            @refreshProducts="getProducts(1)"
          />
        </div>
        <div
          :class="{'show-btn-sopl-xs': countItems > 0}"
          class="btn-sopl-xs hidden-sm hidden-md hidden-lg"
        >
          <div class="no-margin text-left d-block">
            <strong>{{ $t("button.pay") }}</strong>
            <small class="d-block font-s-14">{{ countItems }} {{ $t("label.items") }}</small>
          </div>
          <div class="no-margin text-right d-block">
            <p class="d-block margin-bottom-none">
              <label
                v-if="order_discount && order_discount > 0"
                class="font-s-14 mr-2 badge badge-danger badge-del-price text-white hidden-md hidden-sm hidden-xs margin-bottom-none"
              >
                <del>
                  {{ shop_currency ? shop_currency.code : "USD" }}
                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                    {{ origin_amount_total | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ origin_amount_total | numFormat(num_format) }}
                  </template>
                </del>
              </label>
              <label class="font-s-22 margin-bottom-none">
                {{ shop_currency ? shop_currency.code : "USD" }}
                <template v-if="shop_currency && shop_currency.code === 'KHR'">
                  {{ subtotal | numFormat("0,0") }}
                </template>
                <template v-else>
                  {{ subtotal | numFormat(num_format) }}
                </template>
              </label>
              <small
                v-if="order_discount && order_discount > 0"
                class="d-block text-white font-s-14"
              >
                <del class="font-s-14 badge badge-danger badge-del-price text-white hidden-lg">
                  {{ shop_currency ? shop_currency.code : "USD" }}
                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                    {{ origin_amount_total | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ origin_amount_total | numFormat(num_format) }}
                  </template>
                </del>
              </small>
            </p>
            <p class="d-block margin-bottom-none">
              <label
                v-if="order_discount && order_discount > 0"
                class="font-s-14 mr-2 badge badge-danger badge-del-price text-white hidden-md hidden-sm hidden-xs margin-bottom-none"
              >
                <del>
                  {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                  <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                    {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat(num_format) }}
                  </template>
                </del>
              </label>
              <label class="font-s-18 margin-bottom-none">
                {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                  {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat("0,0") }}
                </template>
                <template v-else>
                  {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat(num_format) }}
                </template>
              </label>
              <small
                v-if="order_discount && order_discount > 0"
                class="d-block text-white font-s-14"
              >
                <del class="font-s-14 badge badge-danger badge-del-price text-white hidden-lg">
                  {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                  <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                    {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat(num_format) }}
                  </template>
                </del>
              </small>
            </p>
          </div>
        </div>
        <EditProductOrder :product="edit_product_order_info" />
        <CategoryModal @refreshProducts="getProducts(1)" />
        <SelectProductVariant
          ref="productVariantModal"
          :check-variant="checkVariant"
          :product="product"
          modal-id="order-list"
        />
        <div
          id="addDiscount"
          aria-labelledby="myModalLabel"
          class="modal fade"
          data-backdrop="static"
          role="dialog"
          tabindex="-1"
        >
          <div
            class="modal-dialog"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header-custom">
                <h4
                  id="myModalLabel"
                  class="modal-title"
                >
                  {{ $t("label.discount") }}
                </h4>
                <button
                  ref="close"
                  :class="class_btn_modal"
                  aria-label="Close"
                  data-dismiss="modal"
                  type="button"
                  @click="resetDiscountModal"
                >
                  <i
                    aria-hidden="true"
                    class="fas fa-times"
                  />
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-xs-12">
                    <div class="row form-group">
                      <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                        {{ $t("label.amountTotal") }}
                      </div>
                      <div class="col-md-8">
                        <p class="control-label user-select-none font-s-18 mt-3 font-weight-bold">
                          {{ shop_currency ? shop_currency.code : "USD" }}
                          <template v-if="shop_currency && shop_currency.code === 'KHR'">
                            {{ subtotal | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ subtotal | numFormat(num_format) }}
                          </template>
                        </p>
                        <small class="control-label user-select-none font-s-16 mt-3 font-weight-bold">
                          {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                          <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                            {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat(num_format) }}
                          </template>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12">
                    <div class="row form-group">
                      <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                        {{ $t("label.discount") }}
                      </div>
                      <div class="col-md-8">
                        <div class="input-group input-group-lg">
                          <input
                            v-model="discount"
                            aria-describedby="discount"
                            class="form-control"
                            min="0"
                            type="number"
                          >
                          <div class="input-group-btn">
                            <button
                              :class="is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                              class="btn no-margin bl-unset"
                              @click="is_percentage = true"
                            >
                              <i class="fas fa-percent" />
                            </button>
                            <button
                              :class="!is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                              class="btn"
                              @click="is_percentage = false"
                            >
                              {{ currency ? currency.code : "USD" }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12">
                    <div class="row form-group">
                      <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                        {{ $t("label.authorizedBy") }}
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="discountAuthorizer"
                          :placeholder="$t('label.authorizesTheDiscount')"
                          class="form-control input-lg"
                          style="border-radius: 6px"
                          type="text"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-default btn-lg"
                  @click="resetDiscountModal"
                >
                  <i class="fas fa-history" /> {{ $t("button.reset") }}
                </button>
                <button
                  aria-label="Close"
                  class="btn btn-lg btn-primary"
                  data-dismiss="modal"
                  type="button"
                  @click="updateDiscountModal"
                >
                  <i class="fas fa-edit" />
                  {{ $t("button.update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <PlanMessage
          :height="'100%'"
          :message="message_allowed"
        />
      </template>
    </template>
    <template v-else>
      <div class="d-flex align-items-center justify-content-center on-loading-layout w-100">
        <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex"
import payment from "../../mixins/payment"
import CategoryModal from "./includes/CategoryModal"
import AsoraProductItemGrid from "../../components/product/AsoraProductItemGrid"
import AsoraCustomProductItemGrid from "../../components/product/AsoraCustomProductItemGrid"
import NoAnyRecords from "../../components/NoAnyRecords"
import OrderProduct from "./includes/OrderProduct"
import { debounce } from "debounce"
import EditProductOrder from "@/views/order/includes/EditProductOrder"
import AsoraProductItemList from "@/components/product/AsoraProductItemList"
import AsoraCustomProductItemList from "@/components/product/AsoraCustomProductItemList"
import SelectProductVariant from "@/components/product/SelectProductVariant"
import PlanMessage from "@/components/PlanMessage"

export default {
  name: "Order",
  metaInfo () {
    return {
      title: this.$t("menu.sale"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    SelectProductVariant,
    AsoraCustomProductItemList,
    AsoraProductItemList,
    EditProductOrder,
    OrderProduct,
    NoAnyRecords,
    AsoraCustomProductItemGrid,
    AsoraProductItemGrid,
    CategoryModal,
    PlanMessage
  },
  mixins: [payment],
  data () {
    return {
      timeout: null,
      previousCode: "",
      barcode: "",
      page: 1,
      infiniteId: +new Date(),
      list_products: [],
      numberParkOrder: 0,
      onLoading: false,
      order: null,
      variant_types: [],
      product: null,
      search_query: null,
      awaitingSearch: false,
      normal_mode: "normal",
      quick_mode: "quick_sell",
      time_out: null,

      discount: 0,
      is_percentage: true,
      discountAuthorizer: null,
      origin_amount_total: 0,
      token_device: null,
      shop_uuid: null,
      allowed: true,
      message_allowed: null,
      on_check_order: true
    }
  },
  watch: {
    search_query () {
      this.search()
    },
    orderProducts () {
      if (this.orderProducts && this.orderProducts.length > 0) {
        localStorage.setItem("display_order_mode", "show_list")
        this.emit("display_order_mode", {
          display_order_mode: "show_list"
        })
      } else {
        localStorage.setItem("display_order_mode", "show_welcome")
        this.emit("display_order_mode", {
          display_order_mode: "show_welcome"
        })
      }
    },
    discount () {
      this.checkDiscountValue()
    },
    is_percentage () {
      this.checkDiscountValue()
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      shop: state => state.shop.shop,
      shop_currency: state => state.shop.currency,
      customProducts: state => state.order.customProducts,
      filterProducts: state => state.order.filterProducts,
      categories: state => state.order.categories,
      orderProducts: state => state.order.orderProducts,
      selectedCustomer: state => state.order.selectedCustomer,
      view_mode: state => state.order.view_mode,
      sell_mode: state => state.sell.sell_mode,
      order_discount: state => state.order.order_discount,
      dis_as_percentage: state => state.order.is_percentage,
      authorizer: state => state.order.discount_authorizer,
      edit_product_order_info: state => state.order.editProductOrder
    }),
    subtotal () {
      let total = 0
      let valKhr = 0
      let valUsd = 0
      this.orderProducts.forEach((product) => {
        let result = 0
        // calculate product discount
        if (product.pivot && product.pivot.discount > 0) {
          if (product.pivot.is_percentage) {
            const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
            result = parseFloat(product.sale_price) - amount_discount
          } else {
            let amount_discount = parseFloat(product.pivot.discount)
            if (this.shop_currency && product.currency && this.shop_currency.code !== product.currency.code) {
              amount_discount = this.exchangeMoney(this.shop_currency.code, product.currency.code, amount_discount)
            }
            result = parseFloat(product.sale_price) - amount_discount
          }
        } else {
          result = parseFloat(product.sale_price)
        }
        if (product.currency && product.currency.code === "USD") {
          valUsd = parseFloat(valUsd) + (parseFloat(product.pivot.quantity) * result)
        } else {
          valKhr = parseFloat(valKhr) + (parseFloat(product.pivot.quantity) * result)
        }
        const usd = this.exchangeMoney("USD", this.shop_currency.code, valUsd)
        const khr = parseFloat(this.exchangeMoney("KHR", this.shop_currency.code, valKhr))
        total = khr + usd
      })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.origin_amount_total = total
      if (this.order_discount) {
        if (this.dis_as_percentage) {
          total = total - ((total * parseFloat(this.order_discount)) / 100)
        } else {
          total = total - parseFloat(this.order_discount)
        }
      }
      return parseFloat(total).toFixed(2)
    },
    countItems () {
      let total = 0
      this.orderProducts.forEach((product) => {
        total += parseFloat(product.pivot.quantity)
      })
      return total
    },
    searching () {
      return this.search_query !== "" && this.search_query !== null
    },
    checkVariant () {
      return this.product && this.product.children && this.product.children.length
    },
    countFilter () {
      let count = 0
      if (this.categories.length) {
        let c = []
        c = this.categories.filter((item) => {
          if (item.active) {
            return item
          }
        })
        if (c.length === 1 && c[0].id < 0) {
          return 0
        }
        count = c.length
      }
      return count
    }
  },
  created () {
    if (this.$route.name === "order-show") {
      window.addEventListener("keypress", this.onWindowKeyPress)
    } else {
      window.removeEventListener("keypress", this.onWindowKeyPress)
    }
    window.addEventListener("storage", (event) => {
      if (event.storageArea !== localStorage) return
      if (event.key === this.t_d_s) {
        this.token_device = localStorage.getItem(this.t_d_s)
      }
    })
  },
  beforeMount () {
    if (this.get_shop) {
      this.shop_uuid = this.get_shop.uuid
    }
    if (this.$bill_active === "true") {
      this.checkCreateOrderLimited()
    }
  },
  mounted () {
    if (localStorage.hasOwnProperty(this.t_d_s)) {
      this.token_device = localStorage.getItem(this.t_d_s)
    }
    this.$nextTick(() => {
      this.$store.dispatch("order/setFilterProductionShopUuid", this.shop.uuid)
      this.$store.dispatch("order/setOrderProducts", [])
      this.$store.dispatch("order/setDisplayPo", this)
      this.$store.dispatch("order/setCategories", [])
      this.$store.dispatch("order/setCustomProducts", [])
      this.$store.dispatch("order/setTables", [])
      this.$store.dispatch("order/changeAwayValue", "takeAway")
      this.$store.dispatch("order/setFilterProductCategoryIds", [])
      this.$store.dispatch("order/setSelectCustomer", null)
      this.emit("display_sc", { customer: null })
      this.$store.dispatch("order/setOrderDiscountStatus", true)
      this.emit("display_dis_percent", { display_dis_percent: true })
      this.$store.dispatch("order/setOrderDiscount", parseFloat(0))
      this.emit("display_dis", { discount: parseFloat(0) })
      this.$store.dispatch("order/setDiscountAuthorizer", null)
      this.emit("display_balance", { display_balance: null })
      this.emit("display_change", { display_change: null })
      this.getDataOptions()
      if (this.sell_mode === "quick_sell") {
        this.onLoading = false
      } else {
        this.getProducts(1)
      }
      // Keep this function call the last in mounted
      this.getOrderProducts()
    })
  },
  methods: {
    checkCreateOrderLimited () {
      this.on_check_order = true
      this.$axios.post(this.$config.base_url + "/api/bill/check-create-order-limited", {
        suuid: this.shop_uuid
      }).then((res) => {
        this.allowed = res.data.data.allowed
        this.message_allowed = res.data.data.message
      })
    },
    checkDiscountValue () {
      if (this.is_percentage) {
        if (this.discount < 0) {
          this.discount = 0
        } else if (this.discount > 100) {
          this.discount = 100
        }
      }
    },
    getDataOptions () {
      const categories = []
      this.$axios.post(this.$config.base_url + "/api/data-option/get-data-option-page-order", {
        shop_uuid: this.shop.uuid
      }).then((res) => {
        const data_currencies = res.data.data.currencies
        const data_category = res.data.data.product_categories
        const data_groups = res.data.data.customer_groups
        const data_memberships = res.data.data.customer_memberships
        const data_genders = res.data.data.genders
        const data_variant_tpyes = res.data.data.variant_types
        const data_exchange = res.data.data.exchange
        const all = {
          name_en: "All",
          name_km: "ទាំងអស់",
          id: -1,
          active: true
        }
        categories.push(all)
        data_category.forEach((item) => {
          this.$set(item, "active", false)
          categories.push(item)
        })
        this.variant_types = data_variant_tpyes
        this.$store.dispatch("shop/setExchangeRate", data_exchange)
        this.$store.dispatch("shop/setCurrencies", data_currencies)
        this.$store.dispatch("order/setCategories", categories)
        this.$store.dispatch("customer/setListGroups", data_groups)
        this.$store.dispatch("customer/setListMemberships", data_memberships)
        this.$store.dispatch("customer/setListGenders", data_genders)
      })
    },
    getProducts: debounce(function (page = null) {
      this.onLoading = true
      this.page = 1
      if (page) {
        this.page = page
      }
      this.list_products = []
      this.$store.dispatch("order/setProducts", [])
      this.infiniteId += 1
      this.infiniteHandler()
    }, 400),
    infiniteHandler ($state) {
      this.$axios
        .post(this.$config.base_url + "/api/product/product-by-warehouse", {
          page: this.page,
          shop_uuid: this.filterProducts.shop_uuid,
          number_per_page: this.filterProducts.number_per_page,
          search_query: this.search_query,
          categories: this.filterProducts.categories
        })
        .then(({ data }) => {
          if (!(this.page > data.total_pages)) {
            this.page += 1
            data.data.products.map((product) => {
              if (product.children && product.children.length > 0) {
                let amount = 0
                product.children.forEach((item) => {
                  amount += parseFloat(item.amount_in_stock)
                })
                this.$set(product, "initAmountInStock", amount)
              } else {
                this.$set(product, "initAmountInStock", product.amount_in_stock)
              }
              if (!product.hasOwnProperty("pivot")) {
                this.$set(product, "pivot", {
                  quantity: 0,
                  served_quantity: 0,
                  discount: 0,
                  is_percentage: true,
                  note: null
                })
              }
              product = this.calcPromotion(product, product.promotions)
              if (product.children && product.children.length) {
                let total_amount_child = 0
                product.children.forEach((pro_child) => {
                  this.$set(pro_child, "initAmountInStock", pro_child.amount_in_stock)
                  total_amount_child += parseFloat(pro_child.amount_in_stock)
                  if (!pro_child.hasOwnProperty("pivot")) {
                    this.$set(pro_child, "pivot", {
                      quantity: 0,
                      served_quantity: 0,
                      discount: 0,
                      is_percentage: true,
                      note: null
                    })
                  }
                  pro_child = this.calcPromotion(pro_child, product.promotions)
                })
                product = this.addVariantType(product)
                this.$set(product, "amount_in_stock", total_amount_child)
                this.$set(product, "initAmountInStock", total_amount_child)
              }
              return product
            })

            this.list_products.push(...data.data.products)
            if ($state) {
              $state.loaded()
            }
          } else {
            $state.loaded()
            $state.complete()
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.onLoading = false
        })
    },
    onClickCategory (category) {
      this.$store.dispatch("order/onChangeCategories", category)
      const categories = []
      this.categories.forEach((category) => {
        if (category.active === true) {
          categories.push(category.id)
        }
      })
      this.$store.dispatch("order/setFilterProductCategoryIds", categories)
      this.onLoading = true
      this.getProducts(1)
    },
    search () {
      this.$store.dispatch("order/updateSearchQuery", this.search_query)
      this.getProducts(1)
    },
    countParkedOrder () {
      this.$axios.post(this.$config.base_url + "/api/order/count-park-order", {
        shop_uuid: this.$config.shop_uuid
      }).then((res) => {
        this.numberParkOrder = res.data.data
      })
    },
    onClickNewOrder () {
      this.$swal({
        html: `<p class="text-bold font-s-18">${this.$t("string.confirmCreateNewOrder")}</p>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("string.yesIAmSure"),
        cancelButtonText: this.swalNoButton
      }).then(() => {
        this.storeAndCreateNewOrder("saved")
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    },
    onClickParkSale () {
      if (this.orderProducts !== null && this.orderProducts.length > 0) {
        this.$swal({
          html: `<p class="text-bold font-s-18">${this.$t("string.confirmParkSale")}</p>`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("string.yesIAmSure"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          this.storeAndCreateNewOrder("saved", "cashier-list-v2")
        }, (dismiss) => {
          if (dismiss === "cancel") {
            this.$router.push({ name: "cashier-list-v2" })
          }
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          throw err
        })
      } else {
        this.$router.push({ name: "cashier-list-v2" })
      }
    },
    getOrderProducts () {
      let orderProducts = []
      this.$axios.post(this.$config.base_url + "/api/order/get-order-products", {
        uuid: this.$route.params.uuid,
        shop_uuid: this.shop.uuid,
        order_from: this.order_from
      }).then((response) => {
        const result = response.data.data
        this.order = result.order
        if (this.order.discount) {
          this.discount = parseFloat(this.order.discount)
          this.is_percentage = this.order.is_percentage
          this.$store.dispatch("order/setOrderDiscountStatus", this.order.is_percentage)
          this.emit("display_dis_percent", {
            display_dis_percent: this.order.is_percentage
          })
          this.$store.dispatch("order/setOrderDiscount", parseFloat(this.order.discount))
          this.emit("display_dis", {
            discount: parseFloat(this.order.discount)
          })
        }

        this.discountAuthorizer = this.order.discount_authorizer
        this.$store.dispatch("order/setDiscountAuthorizer", this.order.discount_authorizer)
        orderProducts = result.order.products
        orderProducts.map((product) => {
          if (product.is_custom_product) {
            this.$set(product, "sale_price", product.custom_product_price)
          }
          product = this.calcPromotion(product, product.promotions)
          return product
        })
        this.$store.dispatch("order/setOrderProducts", orderProducts)
        this.$store.dispatch("order/setDisplayPo", this)
        if (result.order.customer) {
          this.$store.dispatch("order/setSelectCustomer", result.order.customer)
          this.emit("display_sc", {
            customer: result.order.customer
          })
        }
        this.numberParkOrder = result.count_park_order
      }).catch((error) => {
        this.getResponseError(error, true)
      }).finally(() => {
        this.$isLoading(false)
        this.on_check_order = false
      })
    },
    onBarcodeScanned (barcode) {
      if (this.product && this.$refs.productVariantModal) {
        this.$refs.productVariantModal.hideModal()
      }
      this.$axios.post(this.$config.base_url + "/api/product/search-product-barcode", {
        shop_uuid: this.$config.shop_uuid,
        barcode: barcode
      }).then((response) => {
        if (response.data.data !== null) {
          this.product = response.data.data
          this.$set(this.product, "initAmountInStock", this.product.amount_in_stock)
          if (!this.product.hasOwnProperty("pivot")) {
            this.$set(this.product, "pivot", {
              quantity: 0,
              served_quantity: 0,
              discount: 0,
              is_percentage: true,
              note: null
            })
          }
          this.product = this.calcPromotion(this.product, this.product.promotions)
          if (this.product.children && this.product.children.length) {
            this.product.children.map((pro_child) => {
              this.$set(pro_child, "initAmountInStock", pro_child.amount_in_stock)
              if (!pro_child.hasOwnProperty("pivot")) {
                this.$set(pro_child, "pivot", {
                  quantity: 0,
                  served_quantity: 0,
                  discount: 0,
                  is_percentage: true,
                  note: null
                })
              }
              pro_child = this.calcPromotion(pro_child, this.product.promotions)
              return pro_child
            })
            this.product = this.addVariantType(this.product)
          }
          if (this.product && this.product.children.length) {
            this.$refs.productVariantModal.showModal()
          } else {
            this.$store.dispatch("order/addProductToCart", this.product)
            this.$store.dispatch("order/setDisplayPo", this)
          }
        }
        if (this.product === null) {
          this.$toastr("success", this.$t("string.theProductCanNotFound"), this.$t("string.warning"))
        }
      })
    },
    onWindowKeyPress (e) {
      if (e.key === "Enter") {
        this.previousCode = this.barcode
        if (this.previousCode.length > 3) {
          e.preventDefault()
          this.onBarcodeScanned(this.previousCode)
        }
      } else {
        this.barcode += e.key
      }
      setTimeout(() => {
        this.barcode = ""
      }, 200)
    },
    storeOrderItems (type) {
      const formData = {
        shop_uuid: this.$config.shop_uuid,
        uuid: this.$route.params.uuid,
        type: type,
        products: this.orderProducts,
        customer_uuid: this.selectedCustomer ? this.selectedCustomer.uuid : null,
        discount: this.order_discount,
        is_percentage: this.dis_as_percentage,
        authorizedBy: this.authorizer,
        token_device: this.token_device
      }
      if (!(this.orderProducts !== null && this.orderProducts.length > 0)) {
        this.$toastr(
          "info",
          this.$t("string.pleaseAddProductToCart"),
          this.$t("string.order")
        )
        return
      }

      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/v2/order/store", formData)
        .then(() => {
          if (type === "saved") {
            this.$toastr(this.$t("message.success"), this.$t("message.parkOrderSuccess"), "success")
            this.$store.dispatch("order/setSelectCustomer", null)
            this.emit("display_sc", {
              customer: null
            })
            this.$store.dispatch("order/setOrderProducts", [])
            this.$store.dispatch("order/setDisplayPo", this)
            this.$router.push({ name: "cashier-list-v2" })
          } else {
            this.$router.push({
              name: "payment-show",
              params: {
                uuid: this.$route.params.uuid,
                orderFrom: this.$route.params.orderFrom
              }
            })
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
          this.closeSidebarOrderProductSm()
        })
    },
    storeAndCreateNewOrder (type, route = "order-show") {
      this.$isLoading(true)
      const formData = {
        shop_uuid: this.$config.shop_uuid,
        uuid: this.$route.params.uuid,
        type: type,
        products: this.orderProducts,
        customer_uuid: this.selectedCustomer ? this.selectedCustomer.uuid : null,
        discount: this.order_discount,
        is_percentage: this.dis_as_percentage,
        authorizedBy: this.authorizer,
        token_device: this.token_device
      }
      this.$axios.post(this.$config.base_url + "/api/v2/order/store-and-create-new-order", formData)
        .then((res) => {
          this.order = res.data.data
          this.$store.dispatch("order/setOrderDiscountStatus", true)
          this.emit("display_dis_percent", {
            display_dis_percent: true
          })
          this.$store.dispatch("order/setOrderDiscount", 0)
          this.emit("display_dis", {
            discount: 0
          })
          this.$store.dispatch("order/setDiscountAuthorizer", null)
          this.$store.dispatch("order/setSelectCustomer", null)
          this.emit("display_sc", {
            customer: null
          })
          this.$store.dispatch("order/setOrderProducts", [])
          this.$store.dispatch("order/setDisplayPo", this)
          this.countParkedOrder()
          if (route) {
            if (route === "cashier-list-v2") {
              this.$router.push({ name: "cashier-list-v2" })
            } else {
              this.$router.push({
                name: "order-show",
                params: {
                  uuid: res.data.data.uuid,
                  orderFrom: "order"
                }
              })
            }
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
          this.closeSidebarOrderProductSm()
        })
    },
    passValueToChangeSellMode () {
      let mode = this.normal_mode
      if (this.sell_mode) {
        if (this.sell_mode === this.normal_mode) {
          mode = this.quick_mode
        }
      }
      this.changeSellMode(mode)
      if (this.sell_mode === this.normal_mode && !this.list_products.length) {
        this.refreshData(true)
      }
    },
    refreshData (only_product = false) {
      this.$isLoading(true)
      setTimeout(() => {
        this.onLoading = true
        this.getProducts(1)
        if (!only_product) {
          this.getDataOptions()
          this.getOrderProducts()
        }
        this.$isLoading(false)
      }, 400)
    },

    resetDiscountModal () {
      this.discount = 0
      this.discountAuthorizer = null
      this.is_percentage = true
      this.$store.dispatch("order/setOrderDiscountStatus", this.is_percentage)
      this.emit("display_dis_percent", {
        display_dis_percent: this.is_percentage
      })
      this.$store.dispatch("order/setOrderDiscount", this.discount)
      this.emit("display_dis", {
        discount: this.discount
      })
      this.$store.dispatch("order/setDiscountAuthorizer", this.discountAuthorizer)
    },
    updateDiscountModal () {
      if (this.discount === null || this.discount === "") {
        this.discount = 0
      }
      this.$store.dispatch("order/setOrderDiscountStatus", this.is_percentage)
      this.emit("display_dis_percent", {
        display_dis_percent: this.is_percentage
      })
      this.$store.dispatch("order/setOrderDiscount", this.discount)
      this.emit("display_dis", {
        discount: this.discount
      })
      this.$store.dispatch("order/setDiscountAuthorizer", this.discountAuthorizer)
    },
    closeDrawerCustomer () {
      if (this.$refs.listOrderProduct) {
        this.$refs.listOrderProduct.closeDrawerCustomer()
      }
    }
  },
  destroyed () {
    window.removeEventListener("storage", (event) => {
      if (event.storageArea !== localStorage) return
      if (event.key === this.t_d_s) {
        this.token_device = localStorage.getItem(this.t_d_s)
      }
    })
  }
}
</script>

<style scoped>
.plc {
  background-color: #f9f9f9;
}

.switch-view {
  margin-right: 5px;
}

@media screen and (max-width: 1200px) {
  .btn-refresh, .btn-quick-mode {
    display: none;
  }

  .block-search-with-category {
    padding: 7px 8px;
    height: auto;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .08);
    z-index: 2;
  }

  .block-search-box {
    width: calc(100% - 195px);
  }

  .block-btn-actions.bba-1 {
    display: none;
  }

  .block-btn-actions.bba-2 {
    display: inline-block;
    width: 185px;
  }

  .btn-filter-sm {
    display: inline-block !important;
    height: 46px !important;
    max-width: 75px;
    min-width: 75px;
  }

  .block-category.hide-block-category {
    display: none !important;
  }

  .switch-view {
    margin-right: 0;
  }
}

@media screen and (max-width: 991.98px) {
  .align-top,
  .switch-view {
    vertical-align: top;
  }
}

@media screen and (max-width: 767px) {
  .block-search-with-category {
    display: block;
    height: auto;
  }

  .block-search-box {
    width: calc(100% - 65px);
  }

  .block-search-box,
  .block-btn-actions.bba-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .block-btn-actions.bba-1 .btn-filter-sm.mr-2 {
    margin-right: 0 !important;
  }

  .block-btn-actions.bba-1 {
    display: inline-block;
    width: 55px;
  }

  .block-btn-actions.bba-2 {
    display: none;
    width: 100%;
  }
}
</style>
