<template>
  <div class="recent-sale-page-container w-100 bg-white h-100">
    <div class="block-invoice-list h-100">
      <div class="tabBlock-pane d-flex flex-column">
        <div class="form-group user-select-none">
          <label class="title-section">
            {{ $t("recent_sell.listingRecentSell") }}
          </label>
        </div>
        <div class="form-group">
          <div class="input-group">
            <div class="box-search">
              <div class="box-search-icon margin-l-5">
                <i class="fas fa-search" />
              </div>
              <input
                v-model="search_query"
                :placeholder="$t('label.search') + '...'"
                class="pos-input-search pos-input-lg no-border"
                type="text"
                @keyup="getInvoice(1)"
              >
            </div>
            <div class="input-group-btn">
              <button
                :title="$t('label.filter')"
                class="btn btn-default btn-lg"
                data-placement="top"
                data-target="#filterRecentSale"
                data-toggle="modal"
              >
                <i class="fas fa-filter" />
                <span class="hide-label-sm ml-2">{{ $t("label.filter") }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group font-s-18 d-flex align-items-center justify-content-between">
          <div class="minus">
            <template v-if="recent_sale_filter.start_date || recent_sale_filter.end_date">
              <label v-if="recent_sale_filter.start_date">
                {{ $moment(recent_sale_filter.start_date).locale($i18n.locale).format(dateFormatLocaleNoTime) }}
              </label>
              <label v-if="recent_sale_filter.end_date">
                {{ $moment(recent_sale_filter.end_date).locale($i18n.locale).format(dateFormatLocaleNoTime) }}
              </label>
            </template>
            <template v-else>
              <label>{{ $moment().locale($i18n.locale).format(dateFormatLocaleNoTime) }}</label>
            </template>
          </div>
          <div v-if="showResetButton">
            <button
              class="btn btn-default btn-lg"
              @click="onClickReset"
            >
              <i class="fas fa-history" />
            </button>
          </div>
        </div>
        <template v-if="onLoading">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <template v-if="list_invoices && list_invoices.length">
            <div class="flex-1 overflow-y-auto h-100 scrollbar">
              <div class="sugg-customer-each sugg-customer-header layout-content-recent-sell-list font-s-16">
                <div
                  :title="$t('label.in_no')"
                  class="order-label-number text-bold text-ellipsis text-capitalize"
                >
                  {{ $t("label.in_no") }}
                </div>
                <div
                  :title="$t('label.soldBy')"
                  class="order-label-date text-bold d-flex align-items-center justify-content-center w-30"
                >
                  <div class="d-block text-capitalize">
                    {{ $t("label.soldBy") }}
                  </div>
                </div>
                <div
                  :title="$t('label.customer')"
                  class="order-customer-name text-bold w-30 text-center text-capitalize"
                >
                  {{ $t("label.customer") }}
                </div>
                <div
                  :title="$t('label.status')"
                  class="order-status text-bold text-center text-capitalize"
                >
                  {{ $t("label.status") }}
                </div>
                <div
                  :title="$t('label.saleTotal')"
                  class="total-amount text-bold text-capitalize"
                >
                  {{ $t("label.saleTotal") }}
                </div>
              </div>
              <template v-for="(item, key) in list_invoices">
                <a
                  :key="key"
                  :title="'Order Date: ' + $moment(item.created_at).locale($i18n.locale).format(dateFormatLocale)"
                  class="user-select-none show-customer-sidebar-detail"
                  @click="onClickSelectRecentSell(item.uuid)"
                >
                  <div
                    :class="{ 'active': recentSellDetail && recentSellDetail.uuid === item.uuid }"
                    class="sugg-customer-each"
                  >
                    <div class="layout-content-recent-sell-list">
                      <div class="order-label-number d-flex align-items-center">
                        <div class="w-100 text-ellipsis">#{{ item.order_number }}</div>
                      </div>
                      <div class="order-label-date d-flex align-items-center justify-content-center w-30">
                        <div class="d-block text-center">
                          <div class="w-100 text-ellipsis text-bold">
                            {{ item.cashier ? item.cashier.full_name : "-" }}
                          </div>
                          <div class="w-100 text-ellipsis">
                            {{ $moment(item.created_at).locale($i18n.locale).format("hh:mm A") }}
                          </div>
                        </div>
                      </div>
                      <div class="order-customer-name w-30 text-center">
                        <template v-if="item.order && item.order.customer">
                          <div v-if="item.order.customer.name">
                            <strong>{{ item.order.customer.name }}</strong>
                          </div>
                          <div v-if="item.order.customer.tel">
                            {{ item.order.customer.tel }}
                          </div>
                        </template>
                        <template v-else> -</template>
                      </div>
                      <div class="order-status text-center">
                        <span
                          :class="item.is_owe ? 'label-danger' : 'label-success'"
                          class="label-status text-white rounded text-center font-s-13"
                        >
                          {{ item.is_owe ? $t("label.unpaid") : $t("label.fullyPaid") }}
                        </span>
                      </div>
                      <div class="total-amount">
                        <strong>៛ {{ item.amount_total_riel_number_format }}</strong><br>
                        <strong>$ {{ item.amount_total_dollar_number_format }}</strong>
                      </div>
                    </div>
                    <div class="layout-content-recent-sell-card">
                      <div class="row no-margin">
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border pt-0">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("label.orderNo") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  <strong>#{{ item.order_number }}</strong>
                                </div>
                                <div class="col-status">
                                  <p
                                    :class="item.is_owe ? 'label-danger' : 'label-success'"
                                    class="margin-bottom-none label-status text-white rounded text-center font-s-13"
                                  >
                                    {{ item.is_owe ? $t("label.unpaid") : $t("label.fullyPaid") }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="item.cashier"
                        class="row no-margin"
                      >
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border pt-0">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("label.soldBy") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border pt-0">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ item.cashier.full_name }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row no-margin">
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border pt-0">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("label.time") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border pt-0">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $moment(item.created_at).locale($i18n.locale).format("hh:mm A") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="item.order && item.order.customer">
                        <div class="row no-margin">
                          <div class="col-xs-6 col-md-4 no-padding">
                            <div class="list-order-header no-border pt-0">
                              <div class="w-100 h-100">
                                <div class="list-total-amount px-0 py-1">
                                  <div class="text-capitalize">
                                    {{ $t("recent_sell.label.customer") }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-6 col-md-8 no-padding">
                            <div class="list-order-header no-border pt-0">
                              <div class="w-100 h-100">
                                <div class="list-total-amount px-0 py-1">
                                  <div class="text-capitalize">
                                    <strong>{{ item.order.customer.name }}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="item.order.customer.tel"
                          class="row no-margin"
                        >
                          <div class="col-xs-6 col-md-4 no-padding">
                            <div class="list-order-header no-border pt-0">
                              <div class="w-100 h-100">
                                <div class="list-total-amount px-0 py-1">
                                  <div class="text-capitalize">
                                    {{ $t("label.phoneNumber") }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-6 col-md-8 no-padding">
                            <div class="list-order-header no-border pt-0">
                              <div class="w-100 h-100">
                                <div class="list-total-amount px-0 py-1">
                                  <div class="text-capitalize">
                                    <strong>{{ item.order.customer.tel }}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <div class="row no-margin">
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border pt-0">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("label.saleTotal") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border pt-0">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  <strong
                                    v-if="item.amount_total_riel_number_format"
                                  >
                                    ៛ {{ item.amount_total_riel_number_format }}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="list-order-header no-border pt-0">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  <strong
                                    v-if="item.amount_total_dollar_number_format"
                                  >
                                    $ {{ item.amount_total_dollar_number_format }}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </template>
              <infinite-loading
                :identifier="infiniteRecentSaleId"
                spinner="spiral"
                @infinite="infiniteHandlerRecentSaleList"
              >
                <div slot="no-more" />
              </infinite-loading>
            </div>
          </template>
          <template v-else>
            <no-any-records />
          </template>
        </template>
      </div>
    </div>
    <div class="block-invoice-detail cus-detail h-100">
      <div class="tabBlock-pane d-flex flex-column overflow-y-auto scrollbar">
        <template v-if="onLoadingDetail">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <div
            v-if="recentSellDetail"
            class="order-info show-recent-sell w-100 user-select-none"
          >
            <div class="form-group d-flex align-items-center justify-content-between w-100 text-capitalize">
              <div>
                <button
                  class="btn btn-default btn-lg no-margin btn-close-cus-detail-sm"
                  @click="closeCustomerDetailSm"
                >
                  <i class="fas fa-arrow-left" />
                </button>
              </div>
              <div>
                <button
                  :class="{'no-margin': !recentSellDetail.is_owe}"
                  class="btn btn-default btn-lg"
                  @click="goToPrintPage(recentSellDetail.uuid)"
                >
                  <i class="fas fa-print" />
                  {{ $t("string.printInvoice") }}
                </button>
                <button
                  v-if="recentSellDetail.is_owe"
                  class="btn btn-success btn-lg no-margin"
                  @click="payRecentSell(recentSellDetail.uuid)"
                >
                  <i class="fas fa-money-check-alt mr-2" />
                  {{ $t("button.pay") }}
                </button>
              </div>
            </div>
            <div class="block-invoice-detail_box scrollbar user-select-none">
              <div class="row">
                <div class="col-xs-12 form-group">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-lg-4">
                      <div class="text-capitalize">
                        {{ $t("recent_sell.label.invoiceNumber") }}
                      </div>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                      <div class="text-capitalize order-number">
                        <strong>#{{ recentSellDetail.order_number }}</strong>
                        <span
                          v-if="recentSellDetail.invoice_edit_history_count > 0"
                          class="badge badge-primary"
                        >{{ $t("label.edited") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 form-group">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-lg-4">
                      <div class="text-capitalize">
                        {{ $t("recent_sell.label.date") }}
                      </div>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                      <div class="text-capitalize">
                        {{ $moment(recentSellDetail.created_at).locale($i18n.locale).format(dateFormatLocale) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="recentSellDetail.cashier"
                  class="col-xs-12 form-group"
                >
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-lg-4">
                      <div class="text-capitalize">
                        {{ $t("recent_sell.label.cashier") }}
                      </div>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                      <div class="text-capitalize">
                        {{ recentSellDetail.cashier.full_name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="recentSellDetail.data_info && recentSellDetail.data_info.payment_option"
                  class="col-xs-12 form-group"
                >
                  <div class="row">
                    <template>
                      <div class="col-xs-5 col-sm-5 col-lg-4">
                        <div class="text-capitalize">
                          {{ $t("label.paymentOption") }}
                        </div>
                      </div>
                      <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                        <div class="text-capitalize">
                          {{ recentSellDetail.data_info.payment_option["name_" + $i18n.locale] }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <template v-if="recentSellDetail.order && recentSellDetail.order.customer">
                  <template v-if="recentSellDetail.order.customer.name">
                    <div class="col-xs-12 form-group">
                      <div class="row">
                        <div class="col-xs-5 col-sm-5 col-lg-4">
                          <div class="text-capitalize">
                            {{ $t("recent_sell.label.customer") }}
                          </div>
                        </div>
                        <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                          <div class="text-capitalize">
                            {{ recentSellDetail.order.customer.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="recentSellDetail.order.customer.tel">
                    <div class="col-xs-12 form-group">
                      <div class="row">
                        <div class="col-xs-5 col-sm-5 col-lg-4">
                          <div class="text-capitalize">
                            {{ $t("label.telephone") }}
                          </div>
                        </div>
                        <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                          <div class="text-capitalize">
                            {{ recentSellDetail.order.customer.tel }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="recentSellDetail.order.customer.address">
                    <div class="col-xs-12 form-group">
                      <div class="row">
                        <div class="col-xs-5 col-sm-5 col-lg-4">
                          <div class="text-capitalize">
                            {{ $t("label.address") }}
                          </div>
                        </div>
                        <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                          <div class="text-capitalize">
                            {{ recentSellDetail.order.customer.address }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="
                      recentSellDetail.order &&
                        recentSellDetail.order.customer &&
                        recentSellDetail.order.customer.customer_group &&
                        Array.isArray(recentSellDetail.order.customer.customer_group) &&
                        recentSellDetail.order.customer.customer_group.length"
                  >
                    <div class="col-xs-12 form-group">
                      <div class="row">
                        <div class="col-xs-5 col-sm-5 col-lg-4">
                          <div class="text-capitalize">
                            {{ $t("label.customerGroup") }}
                          </div>
                        </div>
                        <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                          <div class="text-capitalize slash">
                            <span
                              v-for="(item, key) in recentSellDetail.order.customer.customer_group"
                              :key="key"
                            >
                              {{ item["name_" + $i18n.locale] }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="recentSellDetail.data_info && recentSellDetail.data_info.customer_membership && Array.isArray(recentSellDetail.data_info.customer_membership) && recentSellDetail.data_info.customer_membership.length"
                  >
                    <div class="col-xs-12 form-group">
                      <div class="row">
                        <div class="col-xs-5 col-sm-5 col-lg-4">
                          <div class="text-capitalize">
                            {{ $t("label.membership") }}
                          </div>
                        </div>
                        <div class="col-xs-7 col-sm-7 col-lg-8 pl-0">
                          <div class="text-capitalize slash">
                            <span
                              v-for="(item, each_recent_sell_detail_key) in recentSellDetail.data_info.customer_membership"
                              :key="each_recent_sell_detail_key"
                            >
                              {{ item["name_" + $i18n.locale] }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <div class="col-xs-12 form-group margin-top">
                  <div class="list-order-header no-border">
                    <div class="w-100 h-100">
                      <div class="list-order-label align-items-top  px-0 py-3">
                        <div class="text-capitalize">
                          <span class="order-item_name text-bold">{{ $t("recent_sell.label.orderSummary") }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 form-group">
                  <div
                    v-if="products.length"
                    class="row mx-0"
                  >
                    <div class="col-xs-12 no-padding has-border-t no-border-last-item">
                      <div class="list-order-header">
                        <div class="w-100 h-100">
                          <div class="list-order-label d-block px-0 py-3">
                            <div class="d-flex align-items-center">
                              <span class="text-bold order-item_number text-ellipsis">#</span>
                              <span class="text-bold order-item_name text-ellipsis">{{
                                $t("label.item")
                              }}</span>
                              <span class="text-bold order-item_qty text-ellipsis">{{
                                $t("label.qty")
                              }}</span>
                              <span class="text-bold order-item_price text-ellipsis mr-2">{{
                                $t("label.subTotal")
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-for="(orderItem, orderItemIndex) in products">
                      <div
                        :key="orderItemIndex"
                        class="col-xs-12 no-padding no-border-last-item table-striped"
                      >
                        <div class="list-order-header">
                          <div class="w-100 h-100">
                            <div class="list-order-label d-block px-0 py-3">
                              <div class="d-flex align-items-center">
                                <span class="order-item_number text-ellipsis">
                                  {{ parseInt(orderItemIndex) + 1 }}
                                </span>
                                <span class="order-item_name text-ellipsis">
                                  {{ orderItem.name }}
                                  <template
                                    v-if="orderItem.product_variants && Array.isArray(orderItem.product_variants) && orderItem.product_variants.length > 0"
                                  >
                                    <small class="slash">
                                      <template
                                        v-for="(item, each_order_item_product_variant_key) in orderItem.product_variants"
                                      >
                                        <span
                                          v-if="item.pivot.value"
                                          :key="each_order_item_product_variant_key"
                                        >{{ item.pivot.value }}</span>
                                      </template>
                                    </small>
                                  </template>
                                  <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                                    <small>
                                      {{ $t("label.discount") }}:
                                      <template v-if="orderItem.pivot.is_percentage">
                                        {{ orderItem.pivot.discount }}
                                      </template>
                                      <template v-else>
                                        <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                          {{ orderItem.pivot.discount | numFormat("0,0") }}
                                        </template>
                                        <template v-else>
                                          {{ orderItem.pivot.discount | numFormat(num_format) }}
                                        </template>
                                      </template>
                                      {{ orderItem.pivot.is_percentage ? "%" : currency ? currency.symbol : "$" }}
                                    </small>
                                  </template>
                                  <template v-if="orderItem.promotion_value">
                                    <small>
                                      {{ $t("label.promotion") }}:
                                      {{ orderItem.promotion_value | numFormat(num_format) }}
                                      {{ orderItem.promotion_is_percentage ? "%" : currency ? currency.symbol : "$" }}
                                    </small>
                                  </template>
                                </span>
                                <span class="order-item_qty text-ellipsis">
                                  {{ orderItem.pivot.quantity }}
                                </span>
                                <span
                                  v-if="orderItem.sale_price"
                                  class="order-item_price text-ellipsis"
                                >
                                  {{ orderItem.currency ? orderItem.currency.code : "" }}
                                  <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                    {{ calcDiscountPrice(orderItem) | numFormat("0,0") }}
                                  </template>
                                  <template v-else>
                                    {{ calcDiscountPrice(orderItem) | numFormat(num_format) }}
                                  </template>
                                  <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                                    <br>
                                    <small style="font-weight: normal">
                                      <del>
                                        {{ orderItem.currency ? orderItem.currency.code : "" }}
                                        <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                          {{ parseFloat(orderItem.sale_price) | numFormat("0,0") }}
                                        </template>
                                        <template v-else>
                                          {{ parseFloat(orderItem.sale_price) | numFormat(num_format) }}
                                        </template>
                                      </del>
                                    </small>
                                  </template>
                                </span>
                                <span
                                  v-else
                                  class="order-item_price text-ellipsis text-danger"
                                >{{
                                  parseFloat(0).toFixed(2)
                                }}</span>
                              </div>
                              <small
                                v-if="orderItem.pivot.note"
                                class="font-s-14"
                              >{{ $t("note") }}:
                                {{ orderItem.pivot.note }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="col-xs-12">
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                            <div class="order-item_name text-ellipsis text-capitalize">
                              {{ $t("label.subTotal") }}
                            </div>
                            <div class="order-item_price text-ellipsis">
                              <div class="text-bold">
                                <div class="d-block text-ellipsis w-100">
                                  KHR {{ recentSellDetail.amount_subtotal_riel | numFormat("0,0") }}
                                </div>
                                <div class="d-block text-ellipsis w-100">
                                  USD {{ recentSellDetail.amount_subtotal_dollar | numFormat(num_format) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-if="recentSellDetail.data_info">
                    <template
                      v-if="recentSellDetail.data_info.customer_membership && recentSellDetail.data_info.customer_membership.length"
                    >
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-order-label border-bottom justify-content-between px-0 pt-3 pb-3">
                                <div class="order-item_name text-ellipsis text-capitalize">
                                  {{ $t("table.customer.membership") }} (-)
                                  (- {{ recentSellDetail.data_info.customer_membership[0].amount_percentage }}%)
                                </div>
                                <div class="order-item_price text-ellipsis">
                                  {{ shop_currency && shop_currency.code === "KHR" ? "KHR" : "USD" }}
                                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                    {{
                                      calcAmountPercentage(recentSellDetail.data_info.customer_membership[0].amount_percentage,
                                                           getAmountSubtotal) | numFormat("0,0")
                                    }}
                                  </template>
                                  <template v-else>
                                    {{
                                      calcAmountPercentage(recentSellDetail.data_info.customer_membership[0].amount_percentage,
                                                           getAmountSubtotal) | numFormat(num_format)
                                    }}
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="recentSellDetail.data_info.tax">
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-order-label border-bottom justify-content-between px-0 pt-3 pb-3">
                                <div class="order-item_name text-ellipsis text-capitalize">
                                  {{ $t("recent_sell.label.tax") }}
                                  ( + {{ recentSellDetail.data_info.tax.amount_percentage | numFormat(num_format) }}% )
                                </div>
                                <div class="order-item_price text-ellipsis">
                                  {{ this.shop_currency && this.shop_currency.code === "KHR" ? "KHR" : "USD" }}
                                  <template v-if="this.shop_currency && this.shop_currency.code === 'KHR'">
                                    {{
                                      calcAmountPercentage(recentSellDetail.data_info.tax.amount_percentage,
                                                           getAmountSubtotal) | numFormat("0,0")
                                    }}
                                  </template>
                                  <template v-else>
                                    {{
                                      calcAmountPercentage(recentSellDetail.data_info.tax.amount_percentage,
                                                           getAmountSubtotal) | numFormat(num_format)
                                    }}
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 pt-3 pb-3">
                            <div class="order-item_name text-ellipsis text-capitalize">
                              {{ $t("recent_sell.label.discount") }} (-)
                            </div>
                            <div class="order-item_price text-ellipsis">
                              <template v-if="recentSellDetail.order">
                                <template v-if="recentSellDetail.order.is_percentage">
                                  {{ recentSellDetail.amount_discount }}%
                                </template>
                                <template v-else>
                                  {{ shop_currency ? shop_currency.code : "" }}
                                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                    {{ recentSellDetail.amount_discount | numFormat("0,0") }}
                                  </template>
                                  <template v-else>
                                    {{ recentSellDetail.amount_discount | numFormat(num_format) }}
                                  </template>
                                </template>
                              </template>
                              <template v-else>
                                {{ recentSellDetail.amount_discount }}%
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="recentSellDetail.discount_authorizer"
                    class="row"
                  >
                    <div class="col-xs-12">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 pt-3 pb-3">
                            <span class="order-item_name text-ellipsis text-capitalize">
                              {{ $t("recent_sell.label.authorizer") }}
                            </span>
                            <span class="order-item_price text-ellipsis">
                              {{ recentSellDetail.discount_authorizer }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 pt-3 pb-3">
                            <div class="order-item_name text-ellipsis text-capitalize w-45">
                              {{ $t("label.amountTotal") }}
                            </div>
                            <div class="order-item_price text-ellipsis w-55">
                              <div class="d-block text-ellipsis w-100">
                                KHR {{ recentSellDetail.amount_total_riel | numFormat("0,0") }}
                              </div>
                              <div class="d-block text-ellipsis w-100">
                                USD {{ recentSellDetail.amount_total_dollar | numFormat(num_format) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 pt-3 pb-3">
                            <div class="order-item_name text-ellipsis text-capitalize w-45">
                              {{ $t("recent_sell.label.amountPaid") }}
                            </div>
                            <div class="order-item_price w-55">
                              <div class="d-block text-ellipsis w-100">
                                KHR {{ recentSellDetail.amount_paid_riel | numFormat("0,0") }}
                              </div>
                              <div class="d-block text-ellipsis w-100">
                                USD {{ recentSellDetail.amount_paid_dollar | numFormat(num_format) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 pt-3 pb-3">
                            <div class="order-item_name text-ellipsis text-capitalize w-45">
                              {{ $t("recent_sell.label.amountDue") }}
                            </div>
                            <div class="order-item_price w-55">
                              <div class="d-block text-ellipsis w-100">
                                KHR {{ recentSellDetail.amount_due_riel | numFormat("0,0") }}
                              </div>
                              <div class="d-block text-ellipsis w-100">
                                USD {{ recentSellDetail.amount_due_dollar | numFormat(num_format) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-if="recentSellDetail.is_owe">
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label border-bottom justify-content-between px-0 pt-3 pb-3">
                              <div class="order-item_name text-ellipsis text-capitalize w-45">
                                {{ $t("recent_sell.owe.label.amountOwe") }}
                              </div>
                              <div
                                :class="{'text-red': parseInt(recentSellDetail.amount_owe_riel) > 0}"
                                class="order-item_price w-55"
                              >
                                <div class="d-block text-ellipsis w-100">
                                  KHR {{ recentSellDetail.amount_owe_riel | numFormat("0,0") }}
                                </div>
                                <div class="d-block text-ellipsis w-100">
                                  USD {{ recentSellDetail.amount_owe_dollar | numFormat(num_format) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center justify-content-center w-100 h-100"
          >
            <p class="font-s-18 capitalize">
              {{ $t("string.noRecentSaleSelected") }}
            </p>
          </div>
        </template>
      </div>
    </div>
    <RecentSaleFilter
      ref="recentSaleFilter"
      :cashiers-data="cashiers"
      :payment-options-data="payment_options"
      @refreshList="getInvoice(1)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import { debounce } from "debounce"
import NoAnyRecords from "@/components/NoAnyRecords"
import RecentSaleFilter from "@/views/cashiers/recentSell/V2/_components/RecentSaleFilter"

export default {
  name: "RecentSaleListV2",
  metaInfo () {
    return {
      title: this.$t("recent_sell.listingRecentSell"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    RecentSaleFilter,
    NoAnyRecords
  },
  data () {
    return {
      search_query: null,
      list_invoices: [],
      recentSellDetail: null,
      onLoadingDetail: false,
      onLoading: true,
      page: 1,
      products: [],
      infiniteRecentSaleId: +new Date(),
      payment_options: [],
      cashiers: []
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      recent_sale_filter: state => state.sell.recent_sale_filter,
      shop: state => state.shop.shop,
      shop_currency: state => state.shop.currency
    }),
    showResetButton () {
      return this.recent_sale_filter.start_date ||
        this.recent_sale_filter.end_date ||
        this.recent_sale_filter.customer ||
        this.recent_sale_filter.cashier ||
        (this.recent_sale_filter.status === true || this.recent_sale_filter.status === false) ||
        this.recent_sale_filter.payment_option
    },
    getAmountSubtotal () {
      if (this.recentSellDetail) {
        if (this.shop_currency && this.shop_currency.code === "KHR") {
          return this.recentSellDetail.amount_subtotal_riel
        }
        return this.recentSellDetail.amount_subtotal_dollar
      }
      return 0
    }
  },
  mounted () {
    this.$store.dispatch("sell/setRecentSaleFilter", {
      start_date: null,
      end_date: null,
      customer: null,
      cashier: null,
      status: null,
      payment_option: null
    })
    this.getOptions()
    this.getInvoice(1)
    if (this.$route.params.uuid) {
      this.getRecentSellDetail(this.$route.params.uuid)
    }
  },
  methods: {
    calcAmountPercentage (tax, total) {
      tax = parseFloat(tax)
      total = parseFloat(total)
      return (tax * total) / 100
    },
    onClickReset () {
      if (this.$refs.recentSaleFilter) {
        this.$refs.recentSaleFilter.clearData()
      }
      this.$store.dispatch("sell/setRecentSaleFilter", {
        start_date: null,
        end_date: null,
        customer: null,
        cashier: null,
        status: null,
        payment_option: null
      })
      this.getInvoice(1)
    },
    getOptions () {
      this.$axios.post(this.$config.base_url + "/api/data-option/get-data-option-page-recent-sell ", {
        shop_uuid: this.shop.uuid
      }).then((res) => {
        this.payment_options = res.data.data.payment_options
        this.cashiers = res.data.data.cashiers
      })
    },
    getInvoice (page = null) {
      this.onLoading = true
      this.page = 1
      if (page) {
        this.page = page
      }
      this.list_invoices = []
      this.recentSellDetail = null
      this.infiniteRecentSaleId += 1
      this.infiniteHandlerRecentSaleList()
    },
    infiniteHandlerRecentSaleList: debounce(function ($state) {
      let startDate = null
      let endDate = null

      if (!this.recent_sale_filter) {
        console.log("undefined recent_sale_filter")
        return 0
      }

      if (this.recent_sale_filter.start_date) {
        startDate = this.$moment(this.recent_sale_filter.start_date).locale("en").format("YYYY-MM-DD")
      }
      if (this.recent_sale_filter.end_date) {
        endDate = this.$moment(this.recent_sale_filter.end_date).locale("en").format("YYYY-MM-DD")
      }
      this.$axios.post(this.$config.base_url + "/api/recent-sell/list-recent-sell", {
        shop_uuid: this.$config.shop_uuid,
        search_query: this.search_query,
        start_date: startDate,
        end_date: endDate,
        customer_name: this.recent_sale_filter.customer,
        cashier_id: this.recent_sale_filter.cashier ? this.recent_sale_filter.cashier.id : null,
        payment_option_id: this.recent_sale_filter.payment_option ? this.recent_sale_filter.payment_option.id : null,
        status: this.recent_sale_filter.status,
        page: this.page
      }).then(({ data }) => {
        if (!(this.page > data.total_pages)) {
          this.page += 1
          this.list_invoices.push(...data.data)
          if ($state) {
            $state.loaded()
          }
        } else {
          if (data.data && $state) {
            $state.loaded()
            $state.complete()
          }
        }
      }).finally(() => {
        this.onLoading = false
      })
    }, 500),
    onClickSelectRecentSell (item) {
      this.onLoadingDetail = true
      this.getRecentSellDetail(item)
    },
    getRecentSellDetail: debounce(function (uuid) {
      this.$axios
        .post(this.$config.base_url + "/api/recent-sell/show", {
          shop_uuid: this.$config.shop_uuid,
          uuid: uuid
        })
        .then((response) => {
          this.recentSellDetail = response.data.data
          this.products = JSON.parse(response.data.data.items)
          this.products.map((product) => {
            if (product.is_custom_product && product.custom_product_price && product.custom_product_price.length) {
              this.$set(product, "sale_price", product.custom_product_price[0].price)
            }
            let amountTotal = 0
            if (product.pivot.is_percentage) {
              amountTotal = product.pivot.served_quantity * product.sale_price * (100 - product.pivot.discount) / 100
            } else {
              let amount_discount = parseFloat(product.pivot.discount)
              if (this.shop_currency && product.currency && this.shop_currency.code !== product.currency.code) {
                amount_discount = this.exchangeMoney(this.shop_currency.code, product.currency.code, amount_discount)
              }
              amountTotal = (product.pivot.served_quantity * product.sale_price) - amount_discount
            }
            this.$set(product, "amountTotal", amountTotal)
            product = this.calcPromotion(product, product.promotions)
            return product
          })
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.onLoadingDetail = false
        })
    }, 800),
    goToPrintPage (uuid) {
      this.$router.push({
        name: "print-invoice",
        params: { uuid: uuid }
      })
    },
    calcDiscountPrice (product) {
      if (product.pivot && product.pivot.discount > 0) {
        if (product.pivot.is_percentage) {
          const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
          const result = parseFloat(product.sale_price) - amount_discount
          return parseFloat(result).toFixed(2)
        } else {
          let amount_discount = parseFloat(product.pivot.discount)
          if (this.shop_currency && product.currency && this.shop_currency.code !== product.currency.code) {
            amount_discount = this.exchangeMoney(this.shop_currency.code, product.currency.code, amount_discount)
          }
          const result = parseFloat(product.sale_price) - amount_discount
          return parseFloat(result).toFixed(2)
        }
      }
      return parseFloat(product.sale_price).toFixed(2)
    },
    payRecentSell (uuid) {
      this.$swal({
        title: this.$t("string.editRecentSellTitle"),
        text: this.$t("string.editRecentSellText"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f63547",
        confirmButtonText: this.$t("string.yesIAmSure"),
        cancelButtonText: this.swalNoButton
      }).then(() => {
        this.$router.push({
          name: "payment-show",
          params: {
            uuid: uuid,
            orderFrom: "recent-sell"
          }
        })
      }, (dismiss) => {
        if (!(dismiss === "cancel")) {
          throw dismiss
        }
      }).catch(function (err) {
        throw err
      })
    }
  }
}
</script>

<style scoped>
@import "../../../../assets/css/V2/recent-sale.css";
</style>
