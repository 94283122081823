import Index from "@/views/inventories/tags/Index"
import List from "@/views/inventories/tags/List"
import Create from "@/views/inventories/tags/Create"
import Edit from "@/views/inventories/tags/Edit"
import Show from "@/views/inventories/tags/Show"

export const tagRouters = {
  path: "tag",
  component: Index,
  name: "tag",
  redirect: "/admin/inventories/tag/",
  children: [
    {
      path: "",
      name: "list-product-tag",
      component: List
    },
    {
      path: "create",
      name: "create-product-tag",
      component: Create
    },
    {
      path: ":uuid/show",
      name: "show-product-tag",
      component: Show
    },
    {
      path: ":uuid/edit",
      name: "edit-product-tag",
      component: Edit
    }
  ]
}
