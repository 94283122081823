const state = {
  shop: null,
  currency: null,
  exchange: null,
  tax: null,
  numFormat: "0,0.00",
  currencies: [],
  device_for_sell: false,
  has_alert_stock: false,
  is_offline: false,
  is_expired: false,
  plan: null,
  autoPopupPrint: false
}

const getters = {
  getShop: (state) => {
    if (state.shop) {
      return state.shop
    } else {
      return {
        id: null
      }
    }
  },
  currencyCode: (state) => {
    if (state.currency) {
      return state.currency.code
    } else {
      return "USD"
    }
  },
  currencySymbol: (state) => {
    if (state.currency) {
      return state.currency.symbol
    } else {
      return "$"
    }
  },
  has_alert_stock: state => state.has_alert_stock,
  is_offline: state => state.is_offline,
  is_expired: state => state.is_expired,
  plan: state => state.plan,
  shop: state => state.shop,
  device_for_sell: state => state.device_for_sell
}

const mutations = {
  setExpired (state, data) {
    state.is_expired = data
  },
  setOffline (state, data) {
    state.is_offline = data
  },
  setHasAlertStock (state, data) {
    state.has_alert_stock = data
  },
  setDeviceForSell (state, is_device_for_sell) {
    state.device_for_sell = is_device_for_sell
  },
  setShop (state, { shop }) {
    state.shop = shop
    if (shop && shop.popup_print_invoice) {
      state.autoPopupPrint = shop.popup_print_invoice
    }
  },
  setDisplayBannerImage (state, vm) {
    if (state.shop) {
      const shop = state.shop
      const data = {}
      if (shop.media) {
        data.media = shop.media
      }
      if (shop.carousel_direction) {
        data.carousel_direction = shop.carousel_direction
      }
      if (shop.carousel_effect) {
        data.carousel_effect = shop.carousel_effect
      }
      if (shop.carousel_timeout) {
        data.carousel_timeout = shop.carousel_timeout
      }
      if (shop.hasOwnProperty("carousel_loop")) {
        data.carousel_loop = shop.carousel_loop
      }
      if (shop.hasOwnProperty("carousel_pagination")) {
        data.carousel_pagination = shop.carousel_pagination
      }
      vm.emit("display_banner_image", { display_banner_image: data })
    }
  },
  setPlan (state, { plan }) {
    state.plan = plan
  },
  setCurrencies (state, currencies) {
    state.currencies = currencies
  },
  setCurrency (state, currency) {
    state.currency = currency
    localStorage.setItem("currency", JSON.stringify(currency))
  },
  setExchangeRate (state, exchange_rate) {
    state.exchange = exchange_rate
    localStorage.setItem("exchange", JSON.stringify(exchange_rate))
  },
  setTax (state, tax) {
    state.tax = tax
    localStorage.setItem("exchange", JSON.stringify(tax))
  },
  setMedia (state, media) {
    if (state.shop && media && Array.isArray(media) && media.length > 0) {
      state.shop.media = media
    }
  }
}

const actions = {
  setExpired ({
    commit,
    state
  }, data) {
    commit("setExpired", data)
  },
  setOffline ({
    commit,
    state
  }, data) {
    commit("setOffline", data)
  },
  setHasAlertStock ({
    commit,
    state
  }, data) {
    commit("setHasAlertStock", data)
  },
  setDeviceForSell ({
    commit,
    state
  }, is_device_for_sell) {
    commit("setDeviceForSell", is_device_for_sell)
  },
  updateShop ({ commit }, shop) {
    commit("setShop", { shop: shop })
  },
  setPlan ({ commit }, plan) {
    commit("setPlan", { shop: plan })
  },
  setCurrencies ({
    commit,
    state
  }, currencies) {
    commit("setCurrencies", currencies)
  },
  setCurrency ({
    commit,
    state
  }, currency) {
    commit("setCurrency", currency)
  },
  setExchangeRate ({
    commit,
    state
  }, exchange_rate) {
    commit("setExchangeRate", exchange_rate)
  },
  setTax ({
    commit,
    state
  }, tax) {
    commit("setTax", tax)
  },
  setShop ({ commit }, shop) {
    commit("setShop", { shop })
  },
  setDisplayBannerImage ({ commit }, vm) {
    commit("setDisplayBannerImage", vm)
  },
  setMedia ({ commit }, media) {
    commit("setMedia", media)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
