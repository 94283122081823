<template>
  <div :class="column">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h3 class="m-0 font-weight-bold text-primary payment-subtitle">
          {{ title }}
        </h3>
      </div>
      <div class="card-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsoraCard",
  props: {
    column: {
      type: String,
      default: "col-md-4 col-xs-4 col-sm-4"
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
