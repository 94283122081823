<template>
  <div class="register-container">
    <div class="register-form-content">
      <div class="register-form-content-wrapper shadow">
        <div class="margin-bottom pt-3 text-center">
          <router-link :to="{name: 'Login'}">
            <div class="register-logo">
              <img
                src="/img/logos/logo.png"
                width="100%"
                alt="Logo"
              >
            </div>
          </router-link>
        </div>
        <template v-if="show_verify_code">
          <div class="register-form-content_title">
            {{ $t("loginPage.verificationCode") }}
          </div>
          <div class="register-form-content_sub_title text-muted">
            {{ $t("loginPage.pleaseEnterTheVerificationCodeSentTo") }} <strong class="text-black">{{ email }}</strong>.
          </div>
          <div class="row">
            <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
              <div
                :class="{'has-error': hasErrorValidate('code')}"
                class="form-group"
              >
                <label class="required text-capitalize">
                  {{ $t("label.verifyCode") }}
                </label>
                <input
                  v-model="verify_code"
                  :placeholder="$t('strings.verifyCodeEnter6Digits')"
                  class="pos-input pos-input-lg"
                  type="email"
                >
                <span
                  v-if="hasErrorValidate('code')"
                  class="label-error has-error help-block"
                >
                  {{ validations["code"][0] }}
                </span>
              </div>
              <div class="form-group">
                <a
                  class="text-link"
                  @click="clickSubmitEmail"
                >
                  {{ $t("loginPage.resendCode") }}
                </a>
              </div>
              <div class="d-flex justify-content-between w-100">
                <button
                  class="btn btn-secondary-outline btn-lg"
                  @click="show_verify_code = false"
                >
                  {{ $t("button.back") }}
                </button>
                <button
                  :class="{'disabled': onloading_check_email}"
                  :disabled="onloading_check_email"
                  class="btn btn-secondary btn-lg"
                  @click="clickVerifyCode"
                >
                  <template v-if="onloading_check_email">
                    <i class="fas fa-circle-notch fa-spin" />
                  </template>
                  <template v-else>
                    {{ $t("button.submit") }}
                  </template>
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="register-form-content_title">
            {{ $t("loginPage.forgetYouPassword?") }}
          </div>
          <div class="register-form-content_sub_title text-muted">
            {{ $t("loginPage.pleaseEnterYourEmailForResetYourPassword") }}
          </div>
          <div class="row">
            <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
              <div
                :class="{'has-error': hasErrorValidate('email')}"
                class="form-group"
              >
                <label class="required text-capitalize">
                  {{ $t("fields.placeholder.email") }}
                </label>
                <input
                  v-model="email"
                  :placeholder="$t('fields.placeholder.email')"
                  class="pos-input pos-input-lg"
                  type="email"
                >
                <span
                  v-if="hasErrorValidate('email')"
                  class="label-error has-error help-block"
                >
                  {{ validations["email"][0] }}
                </span>
              </div>
              <div class="d-flex justify-content-between w-100">
                <router-link
                  :to="{name: 'Login'}"
                  class="btn btn-secondary-outline btn-lg"
                >
                  {{ $t("button.back") }}
                </router-link>
                <button
                  :class="{'disabled': onloading_check_email}"
                  :disabled="onloading_check_email"
                  class="btn btn-secondary btn-lg"
                  @click="clickSubmitEmail"
                >
                  <template v-if="onloading_check_email">
                    <i class="fas fa-circle-notch fa-spin" />
                  </template>
                  <template v-else>
                    {{ $t("button.send") }}
                  </template>
                </button>
              </div>
            </div>
          </div>
        </template>
        <div class="w-100 register-footer">
          <p class="text-center">
            {{ $t("string.copyright") }} &copy; 2021 <a href="https://figos.app">Figos.app</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgetPassword",
  metaInfo () {
    return {
      title: () => {
        if (this.show_verify_code) {
          return this.$t("loginPage.verificationCode")
        }
        return this.$t("loginPage.forgetYouPassword?")
      },
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      email: null,
      verify_code: null,
      validations: null,
      onloading_check_email: false,
      show_verify_code: false
    }
  },
  methods: {
    hasErrorValidate (field) {
      return this.validations && this.validations.hasOwnProperty(field)
    },
    clickSubmitEmail () {
      this.validations = {}
      this.onloading_check_email = true
      setTimeout(() => {
        this.submitEmail()
      }, 800)
    },
    submitEmail () {
      this.$axios.post(this.$config.base_url + "/api/auth/forget-password", {
        email: this.email
      }).then((res) => {
        this.$toastr("success", res.data.message["message_" + this.$i18n.locale], this.$t("label.verifyCode"))
        this.show_verify_code = true
        this.email = res.data.data
      }).catch((errors) => {
        if (errors.response.status === 422) {
          this.validations = errors.response.data.errors
        } else if (errors.response.status === 404) {
          this.validations = {
            email: [errors.response.data.message["message_" + this.$i18n.locale]]
          }
        } else {
          this.getResponseError(errors)
        }
      }).finally(() => {
        this.onloading_check_email = false
      })
    },

    clickVerifyCode () {
      this.validations = {}
      this.onloading_check_email = true
      setTimeout(() => {
        this.verifyCode()
      }, 800)
    },
    verifyCode () {
      this.validations = {}
      this.$axios.post(this.$config.base_url + "/api/auth/check-code-verify", {
        email: this.email,
        code: this.verify_code,
        time: this.$moment().format("YYYY-MM-DD HH:mm:ss")
      }).then((res) => {
        this.$store.dispatch("user/updateUser", res.data.data)
        this.$router.push({ name: "renew-password" })
      }).catch((errors) => {
        if (errors.response.status === 422) {
          this.validations = errors.response.data.errors
        } else {
          this.getResponseError(errors)
        }
      }).finally(() => {
        this.onloading_check_email = false
      })
    }
  }
}
</script>

<style scoped>
@import "./../assets/css/V2/front-page.css";
</style>
