import Layout from "@/views/profile/Layout"
import Index from "@/views/profile/Index"
import AccountSettings from "@/views/profile/AccountSettings"
import Tasks from "@/views/profile/Tasks"
import Help from "@/views/profile/Help"

export const profileRouters = {
  path: "/profile",
  component: Layout,
  redirect: "/admin/profile/overview",
  name: "profile",
  children: [
    {
      path: "overview",
      name: "overview",
      component: Index
    },
    {
      path: "account-settings",
      name: "account-settings",
      component: AccountSettings
    },
    {
      path: "tasks",
      name: "tasks",
      component: Tasks
    },
    {
      path: "help",
      name: "help",
      component: Help
    }
  ]
}
