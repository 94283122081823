<template>
  <div class="row">
    <div class="col-md-12 margin-top">
      <h3 class="title-label">
        {{ $t("label.chefBoard") }}
      </h3>
    </div>
    <div class="col-md-12 margin-top">
      <div class="row">
        <asora-card :title="$t('label.pending')">
          <draggable
            :list="pending"
            group="order"
            v-bind="dragOptions"
            @change="updateOrdersByStatus"
            @end="drag = false"
            @start="drag = true"
            @to="test"
          >
            <template v-if="pending.length > 0">
              <asora-chef-order-item
                v-for="(order, key) in pending"
                :key="key"
                :order="order"
              />
            </template>
            <template v-else>
              <no-any-records :height="50" />
            </template>
          </draggable>
        </asora-card>
        <asora-card :title="$t('label.inProgress')">
          <draggable
            :list="inProgress"
            group="order"
            v-bind="dragOptions"
            @change="updateOrdersByStatus"
            @end="drag = false"
            @start="drag = true"
          >
            <template v-if="inProgress.length > 0">
              <asora-chef-order-item
                v-for="(order, key) in inProgress"
                :key="key"
                :order="order"
                border-left-color="#5bb85b"
              />
            </template>
            <template v-else>
              <no-any-records :height="50" />
            </template>
          </draggable>
        </asora-card>
        <asora-card :title="$t('label.done')">
          <draggable
            :list="done"
            group="order"
            v-bind="dragOptions"
            @change="updateOrdersByStatus"
            @end="drag = false"
            @start="drag = true"
          >
            <template v-if="done.length > 0">
              <asora-chef-order-item
                v-for="(order, key) in done"
                :key="key"
                :order="order"
                border-left-color="#ff00a5"
              />
            </template>
            <template v-else>
              <no-any-records :height="50" />
            </template>
          </draggable>
        </asora-card>
      </div>
    </div>
    <asora-chef-view-order-modal />
  </div>
</template>

<script>
import draggable from "vuedraggable"
import AsoraCard from "@/components/AsoraCard"
import AsoraChefOrderItem from "@/components/AsoraChefOrderItem"
import NoAnyRecords from "@/components/NoAnyRecords"
import AsoraChefViewOrderModal from "@/components/AsoraChefViewOrderModal"

export default {
  name: "ChefBoard",
  components: {
    AsoraChefViewOrderModal,
    NoAnyRecords,
    AsoraChefOrderItem,
    AsoraCard,
    draggable
  },
  metaInfo () {
    return {
      title: this.$t("label.chefBoard"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      pending: [],
      inProgress: [],
      done: [],
      drag: false
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 300,
        group: "order",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  methods: {
    getOrders () {
      this.$axios.post(this.$config.base_url + "/api/chef/order/get-orders", {
        shop_id: this.get_shop.id
      }).then((response) => {
        if (response.data.code === 1) {
          const result = response.data.data
          if (result.pending.length > 0) {
            this.pending = result.pending
          }
          if (result.inProgress.length > 0) {
            this.inProgress = result.inProgress
          }
          if (result.done.length > 0) {
            this.done = result.done
          }
        }
      })
    },
    updateOrdersByStatus (el) {
      let uuid = null
      if (el.hasOwnProperty("added") &&
        el.added.hasOwnProperty("element")) {
        uuid = el.added.element.uuid
      }
      if (el.hasOwnProperty("removed") &&
        el.removed.hasOwnProperty("element")) {
        uuid = el.removed.element.uuid
      }
      this.store(uuid)
    },
    store (uuid) {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/chef/order/update-orders", {
        shop_id: this.get_shop.id,
        pending: this.pending,
        inProgress: this.inProgress,
        done: this.done,
        uuid: uuid
      }).then(() => {
        this.pending = []
        this.inProgress = []
        this.done = []
        this.getOrders()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.getOrders()
  }
}
</script>

<style scoped>

</style>
