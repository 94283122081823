<template>
  <div class="recent-sale-page-container w-100 bg-white h-100">
    <div class="block-invoice-list h-100">
      <div class="tabBlock-pane d-flex flex-column">
        <div class="form-group user-select-none">
          <label class="title-section">
            {{ $t("recent_sell.listingRecentSell") }}
          </label>
        </div>
        <div class="form-group">
          <div class="input-group">
            <div class="box-search">
              <div class="box-search-icon margin-l-5">
                <i class="fas fa-search" />
              </div>
              <input
                v-model="search_query"
                :placeholder="$t('label.searchOrderNumber')"
                class="pos-input-search pos-input-lg no-border"
                type="text"
              >
            </div>
            <div class="input-group-btn">
              <button
                :title="$t('label.filter')"
                class="btn btn-default btn-lg"
                data-placement="top"
                data-target="#filterRecentSaleOffline"
                data-toggle="modal"
              >
                <i class="fas fa-filter" />
                <span class="hide-label-sm ml-2">{{ $t("label.filter") }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group font-s-18 d-flex align-items-center justify-content-between">
          <div class="minus">
            <template v-if="recent_sale_filter.start_date || recent_sale_filter.end_date">
              <label v-if="recent_sale_filter.start_date">
                {{ $moment(recent_sale_filter.start_date).locale($i18n.locale).format(dateFormatLocaleNoTime) }}
              </label>
              <label v-if="recent_sale_filter.end_date">
                {{ $moment(recent_sale_filter.end_date).locale($i18n.locale).format(dateFormatLocaleNoTime) }}
              </label>
            </template>
            <template v-else>
              <label>{{ $moment().locale($i18n.locale).format(dateFormatLocaleNoTime) }}</label>
            </template>
          </div>
          <div v-if="showResetButton">
            <button
              class="btn btn-default btn-lg"
              @click="onClickReset"
            >
              <i class="fas fa-history" />
            </button>
          </div>
        </div>
        <template v-if="onLoading">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <template v-if="getListInvoices.length > 0">
            <div class="flex-1 overflow-y-auto h-100 scrollbar">
              <div class="sugg-customer-each sugg-customer-header layout-content-recent-sell-list font-s-16">
                <div class="order-label-number text-bold text-ellipsis text-capitalize">
                  {{ $t("label.orderNo") }}
                </div>
                <div class="order-label-date text-bold d-flex align-items-center justify-content-center w-30">
                  <div class="d-block text-capitalize">
                    {{ $t("label.soldBy") }}
                  </div>
                </div>
                <div class="order-customer-name text-bold w-30 text-center text-capitalize">
                  {{ $t("label.customer") }}
                </div>
                <div class="order-status text-bold text-center text-capitalize">
                  {{ $t("label.status") }}
                </div>
                <div class="total-amount text-bold text-capitalize">
                  {{ $t("label.saleTotal") }}
                </div>
              </div>
              <template v-for="(item, key) in getListInvoices">
                <a
                  :key="key"
                  :title="'Order Date: ' + $moment(item.created_at).locale($i18n.locale).format(dateFormatLocale)"
                  class="user-select-none show-customer-sidebar-detail"
                  @click="getRecentSellDetail(item.order_uuid)"
                >
                  <div
                    :class="{ 'active': recentSellDetail && recentSellDetail.uuid === item.order_uuid }"
                    class="sugg-customer-each"
                  >
                    <div class="layout-content-recent-sell-list font-s-16">
                      <div class="order-label-number d-flex align-items-center">
                        <div class="w-100 text-ellipsis">#{{ item.order_number }}</div>
                      </div>
                      <div class="order-label-date d-flex w-30">
                        <div class="d-block">
                          <div class="w-100 text-ellipsis text-bold">
                            {{ item.cashier ? item.cashier.full_name : "-" }}
                          </div>
                          <div class="w-100 text-ellipsis">
                            {{ $moment(item.created_at).locale($i18n.locale).format("hh:mm A") }}
                          </div>
                        </div>
                      </div>
                      <div class="order-customer-name w-30 text-center">
                        {{ item.customer_name ? item.customer_name : "-" }}
                      </div>
                      <div class="order-status text-center">
                        <span
                          :class="item.is_owe ? 'label-danger' : 'label-success'"
                          class="label-status text-white rounded text-center font-s-13"
                        >
                          {{ item.is_owe ? $t("label.unpaid") : $t("label.fullyPaid") }}
                        </span>
                      </div>
                      <div class="total-amount">
                        <strong>៛ {{ item.amount_total_riel | numFormat("0,0") }}</strong><br>
                        <strong>$ {{ item.amount_total_dollar | numFormat("0,0.00") }}</strong>
                      </div>
                    </div>
                    <div class="layout-content-recent-sell-card">
                      <div class="row no-margin">
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("label.invoiceNumber") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  <strong>#{{ item.order_number }}</strong>
                                </div>
                                <div class="col-status">
                                  <span
                                    :class="item.is_owe ? 'label-danger' : 'label-success'"
                                    class="label-status text-white rounded text-center font-s-13"
                                  >
                                    {{ item.is_owe ? $t("label.unpaid") : $t("label.fullyPaid") }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row no-margin">
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("label.invoiceDate") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $moment(item.created_at).locale($i18n.locale).format("hh:mm A") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="item.cashier"
                        class="row no-margin"
                      >
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("label.cashier") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  <strong>{{ item.cashier.full_name }}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="item.customer_name"
                        class="row no-margin"
                      >
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("recent_sell.label.customer") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  <strong>{{ item.customer_name }}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row no-margin">
                        <div class="col-xs-6 col-md-4 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  {{ $t("label.amountTotal") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-8 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  <strong
                                    v-if="item.amount_total_riel_number_format"
                                  >
                                    ៛ {{ item.amount_total_riel_number_format }}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-total-amount px-0 py-1">
                                <div class="text-capitalize">
                                  <strong
                                    v-if="item.amount_total_dollar_number_format"
                                  >
                                    $ {{ item.amount_total_dollar_number_format }}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </template>
            </div>
          </template>
          <template v-else>
            <no-any-records />
          </template>
        </template>
      </div>
    </div>
    <div class="block-invoice-detail cus-detail h-100">
      <div class="tabBlock-pane d-flex flex-column overflow-y-auto scrollbar">
        <template v-if="onLoadingDetail">
          <div class="d-flex align-items-center justify-content-center on-loading-layout">
            <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
          </div>
        </template>
        <template v-else>
          <div class="order-info show-recent-sell w-100 user-select-none">
            <div class="form-group d-flex align-items-center justify-content-between w-100 text-capitalize">
              <div>
                <button
                  class="btn btn-default btn-lg no-margin btn-close-cus-detail-sm"
                  @click="closeCustomerDetailSm"
                >
                  <i class="fas fa-arrow-left" />
                </button>
              </div>
              <div v-if="recentSellDetail">
                <button
                  :class="{'no-margin': !recentSellDetail.is_owe}"
                  class="btn btn-default btn-lg"
                  @click="goToPrintPage(recentSellDetail.uuid)"
                >
                  <i class="fas fa-print" />
                  {{ $t("string.printInvoice") }}
                </button>
              </div>
            </div>
            <div
              v-if="recentSellDetail"
              class="block-invoice-detail_box scrollbar user-select-none"
            >
              <div class="row no-margin">
                <div class="col-xs-12 no-padding">
                  <div class="row mx-0">
                    <div class="col-xs-12 col-sm-5 col-lg-4 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label align-items-top px-0 pt-0 pb-3">
                            <div class="text-capitalize">
                              <div class="order-item_name w-100 mr-2">
                                {{ $t("recent_sell.label.orderNumber") }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-7 col-lg-8 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                            <div class="text-capitalize order-number">
                              <strong class="order-item_name w-100">
                                #{{ recentSellDetail.order_number }}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 no-padding">
                  <div class="row mx-0">
                    <div class="col-xs-12 col-md-5 col-lg-4 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-total-amount align-items-top px-0 pt-0 pb-4">
                            <div class="text-capitalize">
                              <p class="order-item_name w-100 no-margin">
                                {{ $t("recent_sell.label.date") }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-7 col-lg-8 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-total-amount align-items-top px-0 pt-0 pb-4">
                            <div class="text-capitalize">
                              <p class="order-item_name w-100 no-margin">
                                {{ $moment(recentSellDetail.created_at).locale($i18n.locale).format(date_format) }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 no-padding">
                  <div class="row no-margin">
                    <template v-if="cashier">
                      <div class="col-xs-12 col-md-5 col-lg-4 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin">
                                  {{ $t("recent_sell.label.cashier") }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-7 col-lg-8 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin">
                                  {{ cashier.full_name }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="col-xs-12 no-padding">
                  <div class="row no-margin">
                    <template v-if="recentSellDetail.payment_option">
                      <div class="col-xs-12 col-md-5 col-lg-4 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin">
                                  {{ $t("label.paymentOption") }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-7 col-lg-8 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin">
                                  {{ recentSellDetail.payment_option["name_" + $i18n.locale] }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <template v-if="recentSellDetail.customer_name">
                  <div class="col-xs-12 no-padding">
                    <div class="row mx-0">
                      <div class="col-xs-12 col-sm-5 col-lg-4 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100  no-margin">
                                  {{ $t("recent_sell.label.customer") }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-7 col-lg-8 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-total-amount align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin">
                                  {{ recentSellDetail.customer_name }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="recentSellDetail.customer_telephone">
                  <div class="col-xs-12 no-padding">
                    <div class="row no-margin">
                      <div class="col-xs-12 col-sm-5 col-lg-4 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100  no-margin">
                                  {{ $t("label.telephone") }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-7 col-lg-8 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin">
                                  {{ recentSellDetail.customer_telephone }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="recentSellDetail.customer_address">
                  <div class="col-xs-12 no-padding">
                    <div class="row no-margin">
                      <div class="col-xs-12 col-sm-5 col-lg-4 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100  no-margin">
                                  {{ $t("label.address") }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-7 col-lg-8 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin">
                                  {{ recentSellDetail.customer_address }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="recentSellDetail.customer_group && recentSellDetail.customer_group.length">
                  <div class="col-xs-12 no-padding">
                    <div class="row no-margin">
                      <div class="col-xs-12 col-sm-5 col-lg-4 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100  no-margin">
                                  {{ $t("label.customerGroup") }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-7 col-lg-8 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label align-items-top px-0 pt-0 pb-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin slash">
                                  <template
                                    v-if="recentSellDetail.customer_group && Array.isArray(recentSellDetail.customer_group)"
                                  >
                                    <template v-for="(item, customer_group_key) in recentSellDetail.customer_group">
                                      <span :key="customer_group_key">
                                        {{ item["name_" + $i18n.locale] }}
                                      </span>
                                    </template>
                                  </template>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template
                  v-if="recentSellDetail.info.customer_membership && recentSellDetail.info.customer_membership.length"
                >
                  <div class="col-xs-12 no-padding">
                    <div class="row no-margin">
                      <div class="col-xs-12 col-sm-5 col-lg-4 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label align-items-top px-0 pb-0">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin">
                                  {{ $t("fields.label.membership") }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-7 col-lg-8 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label align-items-top px-0 py-3">
                              <div class="text-capitalize">
                                <p class="order-item_name w-100 no-margin slash">
                                  <template
                                    v-if="recentSellDetail.info.customer_membership && Array.isArray(recentSellDetail.info.customer_membership)"
                                  >
                                    <template
                                      v-for="(item, customer_membership_index) in recentSellDetail.info.customer_membership"
                                    >
                                      <span :key="customer_membership_index">
                                        {{ item["name_" + $i18n.locale] }}
                                      </span>
                                    </template>
                                  </template>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="col-xs-12 no-padding margin-top">
                  <div class="list-order-header no-border">
                    <div class="w-100 h-100">
                      <div class="list-order-label align-items-top  px-0 py-3">
                        <div class="text-capitalize">
                          <span class="order-item_name text-bold">
                            {{ $t("recent_sell.label.orderSummary") }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="recentSellDetailItem.length"
                  class="col-xs-12 no-padding"
                >
                  <div class="row mx-0">
                    <div class="col-xs-12 no-padding has-border-t no-border-last-item">
                      <div class="list-order-header">
                        <div class="w-100 h-100">
                          <div class="list-order-label d-block px-0 py-3">
                            <div class="d-flex align-items-center">
                              <span class="text-bold order-item_number text-ellipsis">#</span>
                              <span class="text-bold order-item_name text-ellipsis">{{ $t("label.item") }}</span>
                              <span class="text-bold order-item_qty text-ellipsis">{{ $t("label.qty") }}</span>
                              <span class="text-bold order-item_price text-ellipsis mr-2">{{
                                $t("label.subTotal")
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-for="(orderItem, orderItemIndex) in recentSellDetailItem">
                      <div
                        :key="orderItemIndex"
                        class="col-xs-12 no-padding no-border-last-item table-striped"
                      >
                        <div class="list-order-header">
                          <div class="w-100 h-100">
                            <div class="list-order-label d-block px-0 py-3">
                              <div class="d-flex align-items-center">
                                <span class="order-item_number text-ellipsis">
                                  {{ parseInt(orderItemIndex) + 1 }}
                                </span>
                                <span class="order-item_name text-ellipsis">
                                  {{ orderItem.name }}
                                  <template
                                    v-if="orderItem.product_variants && Array.isArray(orderItem.product_variants) && orderItem.product_variants.length > 0"
                                  >
                                    <small class="slash">
                                      <template
                                        v-for="(item, order_item_product_variant_key) in orderItem.product_variants"
                                      >
                                        <span
                                          v-if="item.pivot.value"
                                          :key="order_item_product_variant_key"
                                        >{{ item.pivot.value }}</span>
                                      </template>
                                    </small>
                                  </template>
                                  <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                                    <small>
                                      {{ $t("label.discount") }}:
                                      <template v-if="orderItem.pivot.is_percentage">{{
                                        orderItem.pivot.discount
                                      }}</template>
                                      <template v-else>
                                        <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                          {{ orderItem.pivot.discount | numFormat("0,0") }}
                                        </template>
                                        <template v-else>
                                          {{ orderItem.pivot.discount | numFormat(num_format) }}
                                        </template>
                                      </template>
                                      {{
                                        orderItem.pivot.is_percentage ? "%" : currency ? currency.symbol : "$"
                                      }}
                                    </small>
                                  </template>
                                  <template v-if="orderItem.promotion_value">
                                    <small>
                                      {{ $t("label.promotion") }}:
                                      <template v-if="orderItem.promotion_is_percentage">
                                        {{ orderItem.promotion_value }}
                                      </template>
                                      <template v-else>
                                        <template v-if="currency && currency.code === 'KHR'">
                                          {{ orderItem.promotion_value | numFormat("0,0") }}
                                        </template>
                                        <template v-else>
                                          {{ orderItem.promotion_value | numFormat("0,0.00") }}
                                        </template>
                                      </template>
                                      {{
                                        orderItem.promotion_is_percentage ? "%" : currency ? currency.symbol : "$"
                                      }}
                                    </small>
                                  </template>
                                </span>
                                <span class="order-item_qty text-ellipsis">
                                  {{ orderItem.pivot.quantity }}
                                </span>
                                <span
                                  v-if="orderItem.sale_price"
                                  class="order-item_price text-ellipsis"
                                >
                                  <template v-if="parseInt(orderItem.sale_price) > 0">
                                    {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                                    <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                      {{ calcDiscountPrice(orderItem) | numFormat("0,0") }}
                                    </template>
                                    <template v-else>
                                      {{ calcDiscountPrice(orderItem) | numFormat(num_format) }}
                                    </template>
                                  </template>
                                  <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                                    <br>
                                    <small style="font-weight: normal">
                                      <del>
                                        {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                                        <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                          {{ parseFloat(orderItem.sale_price) | numFormat("0,0") }}
                                        </template>
                                        <template v-else>
                                          {{ parseFloat(orderItem.sale_price) | numFormat(num_format) }}
                                        </template>
                                      </del>
                                    </small>
                                  </template>
                                </span>
                                <span
                                  v-else
                                  class="order-item_price text-ellipsis text-danger"
                                >{{
                                  parseFloat(0).toFixed(2)
                                }}</span>
                              </div>
                              <small
                                v-if="orderItem.pivot.note"
                                class="font-s-14 pl-3 d-block"
                              >{{ $t("note") }}:
                                {{ orderItem.pivot.note }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="col-xs-12 no-padding">
                  <div class="row mx-0">
                    <div class="col-xs-12 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                            <div class="order-item_name text-ellipsis text-capitalize">
                              {{ $t("label.subTotal") }}
                            </div>
                            <div class="order-item_price text-ellipsis">
                              <div class="text-bold">
                                <div class="d-block text-ellipsis w-100">
                                  KHR {{ recentSellDetail.amount_subtotal_riel | numFormat("0,0") }}
                                </div>
                                <div class="d-block text-ellipsis w-100">
                                  USD {{ recentSellDetail.amount_subtotal_dollar | numFormat(num_format) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-if="recentSellDetail.info">
                    <template
                      v-if="recentSellDetail.info.customer_membership && recentSellDetail.info.customer_membership.length"
                    >
                      <div class="row mx-0">
                        <div class="col-xs-12 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                                <div class="order-item_name text-ellipsis text-capitalize">
                                  {{ $t("table.customer.membership") }} (-)
                                  (- {{ recentSellDetail.info.customer_membership[0].amount_percentage }}%)
                                </div>
                                <div class="order-item_price text-ellipsis">
                                  {{ shop_currency && shop_currency.code === "KHR" ? "KHR" : "USD" }}
                                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                    {{
                                      calcAmountPercentage(recentSellDetail.info.customer_membership[0].amount_percentage,
                                                           getAmountSubtotal) | numFormat("0,0")
                                    }}
                                  </template>
                                  <template v-else>
                                    {{
                                      calcAmountPercentage(recentSellDetail.info.customer_membership[0].amount_percentage,
                                                           getAmountSubtotal) | numFormat(num_format)
                                    }}
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="recentSellDetail.info.tax">
                      <div class="row mx-0">
                        <div class="col-xs-12 no-padding">
                          <div class="list-order-header no-border">
                            <div class="w-100 h-100">
                              <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                                <div class="order-item_name text-ellipsis text-capitalize">
                                  {{ $t("recent_sell.label.tax") }}
                                  ( + {{ recentSellDetail.info.tax.amount_percentage }}% )
                                </div>
                                <div class="order-item_price text-ellipsis">
                                  {{ this.shop_currency && this.shop_currency.code === "KHR" ? "KHR" : "USD" }}
                                  <template v-if="this.shop_currency && this.shop_currency.code === 'KHR'">
                                    {{
                                      calcAmountPercentage(recentSellDetail.info.tax.amount_percentage, getAmountSubtotal)
                                        | numFormat("0,0")
                                    }}
                                  </template>
                                  <template v-else>
                                    {{
                                      calcAmountPercentage(recentSellDetail.info.tax.amount_percentage, getAmountSubtotal)
                                        | numFormat(num_format)
                                    }}
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <div class="row mx-0">
                    <div class="col-xs-12 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                            <div class="order-item_name text-ellipsis text-capitalize">
                              {{ $t("recent_sell.label.discount") }} (-)
                            </div>
                            <div class="order-item_price text-ellipsis">
                              <template v-if="recentSellDetail.is_percentage">
                                {{ recentSellDetail.amount_discount }}%
                              </template>
                              <template v-else>
                                {{ shop_currency ? shop_currency.code : "" }}
                                <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                  {{ recentSellDetail.amount_discount | numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ recentSellDetail.amount_discount | numFormat(num_format) }}
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="recentSellDetail.discount_authorizer"
                    class="row mx-0"
                  >
                    <div class="col-xs-12 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                            <div class="order-item_name text-ellipsis text-capitalize">
                              {{ $t("recent_sell.label.authorizer") }}
                            </div>
                            <div class="order-item_price text-ellipsis">
                              {{ recentSellDetail.discount_authorizer }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-0">
                    <div class="col-xs-12 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                            <div class="order-item_name text-ellipsis text-capitalize w-45">
                              {{ $t("label.amountTotal") }}
                            </div>
                            <div class="order-item_price text-ellipsis w-55">
                              <div class="d-block text-ellipsis w-100">
                                KHR {{ recentSellDetail.amount_total_riel | numFormat("0,0") }}
                              </div>
                              <div class="d-block text-ellipsis w-100">
                                USD {{ recentSellDetail.amount_total_dollar | numFormat(num_format) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-0">
                    <div class="col-xs-12 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                            <div class="order-item_name text-ellipsis text-capitalize w-45">
                              {{ $t("recent_sell.label.amountPaid") }}
                            </div>
                            <div class="order-item_price w-55">
                              <div class="d-block text-ellipsis w-100">
                                KHR {{ recentSellDetail.amount_paid_riel | numFormat("0,0") }}
                              </div>
                              <div class="d-block text-ellipsis w-100">
                                USD {{ recentSellDetail.amount_paid_dollar | numFormat(num_format) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-0">
                    <div class="col-xs-12 no-padding">
                      <div class="list-order-header no-border">
                        <div class="w-100 h-100">
                          <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                            <div class="order-item_name text-ellipsis text-capitalize w-45">
                              {{ $t("recent_sell.label.amountDue") }}
                            </div>
                            <div class="order-item_price w-55">
                              <div class="d-block text-ellipsis w-100">
                                KHR {{ recentSellDetail.amount_due_riel | numFormat("0,0") }}
                              </div>
                              <div class="d-block text-ellipsis w-100">
                                USD {{ recentSellDetail.amount_due_dollar | numFormat(num_format) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-if="recentSellDetail.is_owe">
                    <div class="row mx-0">
                      <div class="col-xs-12 no-padding">
                        <div class="list-order-header no-border">
                          <div class="w-100 h-100">
                            <div class="list-order-label border-bottom justify-content-between px-0 py-3">
                              <div class="order-item_name text-ellipsis text-capitalize w-45">
                                {{ $t("recent_sell.owe.label.amountOwe") }}
                              </div>
                              <div
                                :class="{'text-red': parseInt(recentSellDetail.amount_owe_riel) > 0}"
                                class="order-item_price w-55"
                              >
                                <div class="d-block text-ellipsis w-100">
                                  KHR {{ recentSellDetail.amount_owe_riel | numFormat("0,0") }}
                                </div>
                                <div class="d-block text-ellipsis w-100">
                                  USD {{ recentSellDetail.amount_owe_dollar | numFormat(num_format) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center w-100 h-100"
            >
              <p class="font-s-18 capitalize">
                {{ $t("string.noRecentSaleSelected") }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      id="filterRecentSaleOffline"
      aria-labelledby="filterRecentSaleLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header-custom">
            <h4
              id="myModalLabel"
              class="modal-title capitalize"
            >
              {{ $t("label.filterRecentSale") }}
            </h4>
            <button
              ref="close"
              :class="class_btn_modal"
              aria-label="Close"
              data-dismiss="modal"
              type="button"
            >
              <i
                aria-hidden="true"
                class="fas fa-times"
              />
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <div class="form-group">
                  <label class="font-s-16 user-select-none">{{ $t("fields.label.startDate") }}</label>
                  <datepicker
                    v-model="recent_sale_filter.start_date"
                    :clear-button="true"
                    :clear-button-icon="'fas fa-times'"
                    :format="dateFormat"
                    :input-class="'pos-input pos-input-lg'"
                    :placeholder="placeholder_display_date_format"
                    name="membershipStartDate"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <div class="form-group">
                  <label class="font-s-16 user-select-none">{{ $t("fields.label.endDate") }}</label>
                  <datepicker
                    v-model="recent_sale_filter.end_date"
                    :clear-button="true"
                    :clear-button-icon="'fas fa-times'"
                    :disabled-dates="disabledDates"
                    :format="dateFormat"
                    :input-class="'pos-input pos-input-lg'"
                    :placeholder="placeholder_display_date_format"
                    name="membershipStartDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default btn-lg"
              @click="onClickReset"
            >
              <i class="fas fa-history" /> {{ $t("button.reset") }}
            </button>
            <button
              class="btn btn-success btn-lg"
              @click="onClickApply"
            >
              <i class="fas fa-check-circle" /> {{ $t("button.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexedb from "@/indexedb"
import NoAnyRecords from "@/components/NoAnyRecords"
import { mapState } from "vuex"

export default {
  name: "RecentSaleList",
  components: {
    NoAnyRecords
  },
  metaInfo () {
    return {
      title: this.$t("string.orderList"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      cd_format: "YYYY-MM-DD",
      list_invoices: [],
      tmp: [],
      search_query: "",
      recentSellDetail: null,
      recentSellDetailItem: [],
      onLoadingDetail: false,
      onLoading: true,
      date_format: "ddd, MMMM Do YYYY, h:mm a",
      cashier: null,
      paymentOptionsData: [],
      cashiersData: [],
      recent_sale_filter: {
        start_date: null,
        end_date: null,
        customer: null,
        cashier: null,
        status: null,
        payment_option: null
      },
      disabled_dates: {
        to: null
      }
    }
  },
  watch: {
    search_query () {
      if (this.search_query === "") {
        this.list_invoices = this.tmp
      }
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      dateFormat: state => state.sell.display_date_format,
      placeholder_display_date_format: state => state.sell.placeholder_display_date_format,
      shop_currency: state => state.shop.currency
    }),
    showResetButton () {
      return this.recent_sale_filter.start_date ||
        this.recent_sale_filter.end_date ||
        this.recent_sale_filter.customer ||
        this.recent_sale_filter.cashier ||
        (this.recent_sale_filter.status === true || this.recent_sale_filter.status === false) ||
        this.recent_sale_filter.payment_option
    },
    getListInvoices () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.list_invoices = this.tmp
      if (this.search_query !== "") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.list_invoices = this.list_invoices.filter((item) => {
          const order_number = item.order_number.toString().toLowerCase()
          const search_query = this.search_query.toString().toLowerCase()
          if (order_number.includes(search_query)) {
            return item
          }
        })
      }
      this.filterInvoice()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.list_invoices.sort((first, second) => {
        const a = first.order_number
        const b = second.order_number
        if (a > b) {
          return 1
        } else if (a < b) {
          return -1
        } else {
          return 0
        }
      })
    },
    disabledDates () {
      if (this.recent_sale_filter.start_date) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.disabled_dates.to = this.recent_sale_filter.start_date
        if (this.recent_sale_filter.end_date) {
          const s = this.$moment(this.recent_sale_filter.start_date).format("YYYY-MM-DD")
          const e = this.$moment(this.recent_sale_filter.end_date).format("YYYY-MM-DD")
          if (this.$moment(e).isBefore(s)) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.recent_sale_filter.end_date = null
          }
        }
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.disabled_dates.to = null
      }
      return this.disabled_dates
    },
    getAmountSubtotal () {
      if (this.recentSellDetail) {
        if (this.shop_currency && this.shop_currency.code === "KHR") {
          return this.recentSellDetail.amount_subtotal_riel
        }
        return this.recentSellDetail.amount_subtotal_dollar
      }
      return 0
    }
  },
  mounted () {
    this.getInvoices()
    this.getUsers()
    this.getPaymentOptions()
  },
  methods: {
    calcAmountPercentage (tax, total) {
      tax = parseFloat(tax)
      total = parseFloat(total)
      return (tax * total) / 100
    },
    addClassShowRecentSellDetail () {
      $(".cus-detail").addClass("customer-show-sidebar")
    },
    clearData () {
      this.recent_sale_filter = {
        start_date: null,
        end_date: null
      }
    },
    onClickApply () {
      this.getListInvoices()
      if (this.recentSellDetail) {
        this.recentSellDetail = null
        this.recentSellDetailItem = []
        this.cashier = null
      }
      this.$refs.close.click()
    },
    onClickReset () {
      this.clearData()
      this.getListInvoices()
      if (this.recentSellDetail) {
        this.recentSellDetail = null
        this.recentSellDetailItem = []
        this.cashier = null
      }
      this.$refs.close.click()
    },
    getInvoices () {
      indexedb.all("invoices")
        .then((res) => {
          this.tmp = res
          this.list_invoices = this.tmp
        })
        .finally(() => {
          this.onLoading = false
        })
    },
    getPaymentOptions () {
      indexedb.all("payment_options")
        .then((res) => {
          this.paymentOptionsData = res
        })
        .catch((err) => {
          this.onResponseError(err)
        })
        .finally(() => {
          this.onLoading = false
        })
    },
    getUsers () {
      indexedb.all("user")
        .then((res) => {
          this.cashiersData = res
        })
        .catch((err) => {
          this.onResponseError(err)
        })
        .finally(() => {
          this.onLoading = false
        })
    },
    filterInvoice () {
      this.list_invoices = this.list_invoices.filter((item) => {
        let result = null
        const od = this.$moment(item.created_at).format(this.cd_format)
        if (this.recent_sale_filter.start_date && this.recent_sale_filter.end_date) {
          const sd = this.$moment(this.recent_sale_filter.start_date).format(this.cd_format)
          const ed = this.$moment(this.recent_sale_filter.end_date).format(this.cd_format)
          if ((this.$moment(od).isSame(sd) || this.$moment(od).isAfter(sd)) && (this.$moment(od).isSame(ed) || this.$moment(od).isBefore(ed))) {
            result = item
          }
        } else if (this.recent_sale_filter.start_date && !this.recent_sale_filter.end_date) {
          const sd = this.$moment(this.recent_sale_filter.start_date).format(this.cd_format)
          if (this.$moment(od).isSame(sd) || this.$moment(od).isAfter(sd)) {
            result = item
          }
        } else if (!this.recent_sale_filter.start_date && this.recent_sale_filter.end_date) {
          const ed = this.$moment(this.recent_sale_filter.end_date).format(this.cd_format)
          if (this.$moment(od).isSame(ed) || this.$moment(od).isBefore(ed)) {
            result = item
          }
        } else {
          result = item
        }
        return result
      })
    },
    getRecentSellDetail (item_uuid) {
      this.addClassShowRecentSellDetail()
      indexedb.find("invoices", item_uuid)
        .then((res) => {
          this.recentSellDetail = res
        }).then(() => {
          if (this.recentSellDetail) {
            indexedb.find("orders", this.recentSellDetail.order_uuid)
              .then((order) => {
                this.recentSellDetailItem = order.product
                this.recentSellDetailItem.map((product) => {
                  if (product.is_custom_product && product.custom_product_price && product.custom_product_price.length) {
                    this.$set(product, "sale_price", product.custom_product_price[0].price)
                  }
                  return product
                })
                this.cashier = order.cashier
              })
          }
        })
    },
    calcDiscountPrice (product) {
      if (product.pivot && product.pivot.discount > 0) {
        if (product.pivot.is_percentage) {
          const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
          const result = parseFloat(product.sale_price) - amount_discount
          return parseFloat(result).toFixed(2)
        } else {
          let amount_discount = parseFloat(product.pivot.discount)
          if (this.shop_currency && product.currency && this.shop_currency.code !== product.currency.code) {
            amount_discount = this.exchangeMoney(this.shop_currency.code, product.currency.code, amount_discount)
          }
          const result = parseFloat(product.sale_price) - parseFloat(amount_discount)
          return parseFloat(result)
        }
      }
      return parseFloat(product.sale_price).toFixed(2)
    },
    goToPrintPage (order_uuid) {
      this.$router.push({
        name: "offline-print-invoice",
        params: { order_uuid: order_uuid }
      })
    }
  }
}
</script>

<style scoped>
@import "../../../../assets/css/V2/recent-sale.css";
</style>
