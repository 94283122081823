<template>
  <SubscriptionCard :title="title">
    <SubscriptionInvoiceList v-if="showInvoiceList">
      <template v-slot:displayAlert>
        <SubscriptionRenewalPlanStatus ref="renewalPlanStatus" />
      </template>
    </SubscriptionInvoiceList>
    <SubscriptionRenewPlan
      v-else
      @onSubmit="$refs.renewalPlanStatus.getRenewalPlanStatus"
    />
  </SubscriptionCard>
</template>

<script>
import { mapGetters } from "vuex"
import SubscriptionCard from "@/views/settings/subscription/components/SubscriptionCard"
import SubscriptionRenewPlan from "@/views/settings/subscription/components/SubscriptionRenewPlan"
import SubscriptionInvoiceList from "@/views/settings/subscription/components/SubscriptionInvoiceList"
import SubscriptionRenewalPlanStatus from "@/views/settings/subscription/components/SubscriptionRenewalPlanStatus"

export default {
  name: "SubscriptionInvoices",
  components: {
    SubscriptionRenewalPlanStatus,
    SubscriptionInvoiceList,
    SubscriptionRenewPlan,
    SubscriptionCard
  },
  computed: {
    ...mapGetters("subscription", {
      showInvoiceList: "showInvoiceList"
    }),
    title () {
      if (this.showInvoiceList) {
        return this.$t("string.invoices")
      }
      return this.$t("string.renewPlan")
    }
  }
}
</script>

<style scoped>

</style>
