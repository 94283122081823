<template>
  <div
    v-if="status && (status.toLowerCase() !== 'approved' && status.toLowerCase() !== 'approve') && showAlertChangePlan"
    class="col-sm-12"
  >
    <div
      :class="checkStatus(status)"
      class="alert alert-dismissible"
      role="alert"
    >
      <button
        aria-label="Close"
        class="close"
        data-dismiss="alert"
        type="button"
        @click="hiddenAlertChangePlan"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <template v-if="status.toLowerCase() === 'approved' || status.toLowerCase() === 'approve'">
        <i class="fas fa-check-circle mr-2" />
        <strong>{{ $t("string.requestChangePlanApproved") }}</strong>
      </template>
      <template v-else-if="status.toLowerCase() === 'rejected' || status.toLowerCase() === 'reject'">
        <i class="fas fa-times-circle mr-2" />
        <template v-if="from_plan && to_plan">
          <template v-if="$i18n.locale === 'km'">
            សំណើរបស់អ្នកដើម្បីផ្លាស់ប្តូរគម្រោងពី <strong>{{ from_plan.title }}</strong> ទៅ
            <strong>{{ to_plan.title }}</strong> ត្រូវបានបដិសេធ!
          </template>
          <template v-else>
            Your request to change the <strong>{{ from_plan.title }}</strong> to
            <strong>{{ to_plan.title }}</strong> plan is rejected!
          </template>
        </template>
        <template v-else>
          <strong>{{ $t("string.requestChangePlanRejected") }}</strong>
        </template>
      </template>
      <template v-else>
        <i class="fas fa-info-circle mr-2" />
        <template v-if="from_plan && to_plan">
          <template v-if="$i18n.locale === 'km'">
            សំណើរបស់អ្នកដើម្បីផ្លាស់ប្តូរគម្រោងពី <strong>{{ from_plan.title }}</strong> ទៅ
            <strong>{{ to_plan.title }}</strong> កំពុងពិនិត្យមើល!
          </template>
          <template v-else>
            Your request to change the <strong>{{ from_plan.title }}</strong> to
            <strong>{{ to_plan.title }}</strong> plan is on processing!
          </template>
        </template>
        <template v-else>
          <strong>{{ $t("string.requestChangePlanPending") }}</strong>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "SubscriptionChangePlanStatus",
  data () {
    return {
      status: null,
      reason: null,
      from_plan: null,
      to_plan: null
    }
  },
  computed: {
    ...mapGetters("subscription", {
      showAlertChangePlan: "showAlertChangePlan"
    })
  },
  methods: {
    checkStatus (status) {
      switch (status) {
      case "Approved" || "Approve":
        return "alert-success"
      case "Rejected" || "Reject":
        return "alert-danger"
      default:
        return "alert-default"
      }
    },
    getPlanChangeStatus () {
      this.$axios.post(this.$config.base_url + "/api/bill/get-plan-change-status", {
        suuid: this.get_shop.uuid
      }).then(({ data }) => {
        if (data.data) {
          const {
            status,
            reason_reject,
            from_plan,
            to_plan
          } = data.data

          this.status = status
          this.reason = reason_reject
          this.from_plan = from_plan
          this.to_plan = to_plan
        }
      })
    },
    hiddenAlertChangePlan () {
      this.$store.dispatch("subscription/setShowAlertChangePlan", false)
    }
  },
  mounted () {
    this.getPlanChangeStatus()
  }
}
</script>

<style scoped>

</style>
