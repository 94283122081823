<template>
  <div class="register-shop-wrapper">
    <div class="register-shop-content">
      <div class="register-shop-banner">
        <div class="text-center">
          <img
            alt="main-laptop"
            src="/img/main-laptop.png"
          >
        </div>
        <div class="text-center">
          <h1 class="text-white">
            Grow your business with a<br>
            <span>POS</span> system built for your needs
          </h1>
          <p class="text-gray">
            Figos is a Fast Integrated Cloud-Based POS built for retailers and restaurateurs.
          </p>
        </div>
      </div>
      <div class="tw-py-14 tw-px-16 tw-space-y-12">
        <div class="row">
          <div class="col-sm-12 tw-space-y-12">
            <h2 class="restau-form-title tw-p-0 tw-m-0">
              {{ $t("string.createNewStore") }}
            </h2>
            <p class="restau-form-sub-title">
              {{ $t("strings.pleaseFillOutInformationToCreateRestaurant") }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div
              :class="{'has-error' : validations.name}"
              class="form-group"
            >
              <label
                for="shop_name"
                class="required"
              >{{ $t("fields.label.shopName") }}</label>
              <input
                id="shop_name"
                v-model="restau.name"
                :placeholder="$t('fields.placeholder.restaurantName')"
                class="form-control"
                autofocus
                type="text"
              >
              <ValidationMessage v-if="validations.name" />
            </div>

            <div class="row">
              <div class="col-md-6">
                <div
                  :class="{'has-error' : validations.shop_type_id}"
                  class="form-group"
                >
                  <label
                    class="required"
                    :for="'shop_type'"
                  >
                    {{ $t("fields.label.shopType") }}
                  </label>
                  <select
                    v-model="restau.shop_type_id"
                    id="shop_type"
                    class="form-control"
                  >
                    <option
                      :value="null"
                      disabled
                      selected
                    >
                      {{ $t("fields.label.choose_shop_type") }}
                    </option>
                    <option
                      v-for="(shopType, key) in shopTypes"
                      :key="key"
                      :value="shopType.id"
                    >
                      {{ shopType.code }}
                    </option>
                  </select>
                  <ValidationMessage v-if="validations.shop_type_id" />
                </div>
              </div>
              <div class="col-md-6">
                <div
                  :class="{'has-error' : validations.currency_id}"
                  class="form-group"
                >
                  <label
                    class="required"
                    :for="'currency'"
                  >
                    {{ $t("fields.label.currency") }}
                  </label>
                  <select
                    id="currency"
                    v-model="restau.currency_id"
                    class="form-control"
                  >
                    <option
                      :value="null"
                      disabled
                      selected
                    >
                      {{ $t("fields.label.choose_currency") }}
                    </option>
                    <option
                      v-for="(currency, key) in currencies"
                      :key="key"
                      :value="currency.id"
                    >
                      {{ currency.code }}
                    </option>
                  </select>
                  <ValidationMessage v-if="validations.currency_id" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="village">{{ $t("fields.label.village") }}</label>
                  <input
                    id="village"
                    v-model="restau.village"
                    :placeholder="$t('fields.placeholder.village')"
                    class="form-control"
                    type="text"
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="commune">{{ $t("fields.label.commune") }}</label>
                  <input
                    id="commune"
                    v-model="restau.commune"
                    :placeholder="$t('fields.placeholder.commune')"
                    class="form-control"
                    type="text"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="district">{{ $t("fields.label.district") }}</label>
                  <input
                    id="district"
                    v-model="restau.district"
                    :placeholder="$t('fields.placeholder.district')"
                    class="form-control"
                    type="text"
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="province">{{ $t("fields.label.province") }}</label>
                  <input
                    id="province"
                    v-model="restau.province"
                    :placeholder="$t('fields.placeholder.province')"
                    class="form-control"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="'phone'">{{ $t("fields.label.tel") }}</label>
                  <the-mask
                    id="phone"
                    v-model="restau.tel"
                    :mask="['###-###-###', '###-###-####']"
                    :placeholder="$t('fields.placeholder.tel')"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="website">{{ $t("fields.label.website") }}</label>
                  <input
                    id="website"
                    v-model="restau.website"
                    :placeholder="$t('fields.placeholder.website')"
                    class="form-control"
                    type="url"
                  >
                </div>
              </div>
            </div>

            <div class="form-group">
              <label :for="'address'">{{ $t("fields.label.address") }}</label>
              <textarea
                id="address"
                rows="3"
                v-model="restau.address"
                :placeholder="$t('fields.placeholder.address')"
                class="form-control"
                type="text"
              />
            </div>

            <div class="form-group">
              <label :for="'description'">{{ $t("fields.label.description") }}</label>
              <textarea
                id="description"
                rows="3"
                v-model="restau.description"
                :placeholder="$t('fields.placeholder.description')"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="tw-flex tw-justify-center tw-items-center">
              <button
                class="btn btn-default capitalize"
                @click="logout"
              >
                {{ $t("button.cancel") }}
              </button>
              <button
                class="btn btn-secondary capitalize"
                @click="onSubmitRestaurant"
              >
                {{ $t("button.submit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shopMixin } from "@/mixins/shop"
import ValidationMessage from "@/components/ValidationMessage"

export default {
  name: "RestaurantCreate",
  components: { ValidationMessage },
  mixins: [shopMixin],
  metaInfo () {
    return {
      title: this.$t("loginPage.createANewStore"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      restau: {
        name: null,
        currency_id: null,
        shop_type_id: null,
        address: null,
        tel: null,
        website: null,
        description: null,
        village: null,
        commune: null,
        district: null,
        province: null,
        token_device_for_sell: null
      },
      currencies: [],
      shopTypes: [],
      validations: {}
    }
  },
  mounted () {
    if (localStorage.hasOwnProperty("shop")) {
      this.$router.replace({ name: "dashboard" })
    } else {
      this.getCurrencyOptions()
      this.getShopType()
    }
  },
  methods: {
    getCurrencyOptions () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/restaurant/get-currency-options")
        .then((response) => {
          if (response.data.code === 1 && response.data.data !== null) {
            this.currencies = response.data.data
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getShopType () {
      this.$axios
        .get(this.$config.base_url + "/api/shop-type/get-options")
        .then((response) => {
          this.shopTypes = response.data.data
        })
    },
    onSubmitRestaurant () {
      this.validations = {}
      this.$isLoading(true)
      this.restau.token_device_for_sell = localStorage.getItem(process.env.VUE_APP_KEYWORD_TOKEN_DEVICE)
      const self = this
      this.$axios.post(this.$config.base_url + "/api/restaurant/store", this.restau)
        .then((response) => {
          const shop = response.data.data.shop
          localStorage.setItem("shop", JSON.stringify(shop))
          self.setShopProperty(shop)
          self.$store.dispatch("shop/setDeviceForSell", true)
          self.$router.push({ name: "dashboard" })
          this.$toastr(
            "success",
            this.$t("string.theRequestHasBeenProcessedSuccessfully"),
            this.$t("string.system")
          )
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getUser () {
      this.$axios
        .get(this.$config.base_url + "/api/check-user/get-data")
        .then((res) => {
          const result = res.data.data
          if (result.shop) {
            this.$router.replace({ name: "dashboard" })
            this.$toastr("warning", this.$t("string.youAlreadyHasShop"), this.$t("string.warning"))
          }
        })
        .catch((error) => {
          this.getResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>
@import "./../../../assets/css/login.css";
@import "./../../../assets/css/input.css";

.btn {
  width: 40%;
  font-size: 17px;
  line-height: 30px;
}

.register-shop-wrapper {
  margin: 0 auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.register-shop-content {
  background: white;
  width: 70vw;
}

.register-shop-banner {
  background-image: linear-gradient(218deg, #00a5e5, #658cff 50%, #00a5e5);
  text-align: center;
  display: none;
}

.register-shop-banner img {
  width: 90%;
}

@media (min-width: 1025px) {
  .register-shop-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .register-shop-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .register-shop-content {
    width: 95vw;
  }
}
</style>
