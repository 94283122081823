import customerMembershipIndex from "@/views/cashiers/memberships/MembershipLayout"
import customerMembershipList from "@/views/customers/memberships/V2/List"
import customerMembershipCreate from "@/views/cashiers/memberships/Create"
import customerMembershipShow from "@/views/cashiers/memberships/Show"
import customerMembershipEdit from "@/views/cashiers/memberships/Edit"

export default {
  path: "customer-membership",
  name: "customer-membership",
  component: customerMembershipIndex,
  redirect: "/customer/customer-membership",
  children: [
    {
      path: "",
      name: "customer-membership-list-v2",
      component: customerMembershipList
    },
    // for old version
    {
      path: "create",
      name: "create-customer-membership",
      component: customerMembershipCreate
    },
    {
      path: "edit-customer-membership/:uuid",
      name: "edit-customer-membership",
      component: customerMembershipEdit
    },
    {
      path: "show-customer-membership/:uuid",
      name: "show-customer-membership",
      component: customerMembershipShow
    }
  ]
}
