<template>
  <div
    :id="'modalVariant' + modalId"
    ref="offlineProductVariantModalContainer"
    class="modal fade"
    data-backdrop="static"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-scroll modal-lg"
      role="document"
    >
      <div
        v-if="product"
        class="modal-content"
      >
        <div class="modal-header-custom">
          <h4 class="modal-title">
            {{ product.name }}
          </h4>
          <button
            ref="close"
            :class="class_btn_modal"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
          >
            <i
              aria-hidden="true"
              class="fas fa-times"
            />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-7">
              <div class="form-group row">
                <div class="col-xs-5 col-sm-4">
                  <label class="font-s-16 text-capitalize font-weight-normal">{{ $t("label.price") }}</label>
                </div>
                <div class="col-xs-7 col-sm-8">
                  <template v-if="product_child_data">
                    <label class="font-s-16">
                      {{ product_child_data.currency ? product_child_data.currency.symbol : "$" }}
                      <template v-if="product_child_data.currency && product_child_data.currency.code === 'KHR'">
                        {{ product_child_data.sale_price | numFormat("0,0") }}
                      </template>
                      <template v-else>
                        {{ product_child_data.sale_price | numFormat(num_format) }}
                      </template>
                    </label><br>
                    <template v-if="product.has_promotion">
                      <del class="badge badge-danger badge-del-price">
                        <em>
                          {{ product_child_data.currency ? product_child_data.currency.symbol : "$" }}
                          <template v-if="product_child_data.currency && product_child_data.currency.code === 'KHR'">
                            {{ product_child_data.origin_price | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ product_child_data.origin_price | numFormat(num_format) }}
                          </template>
                        </em>
                      </del>
                    </template>
                  </template>
                  <template v-else>
                    <label class="font-s-16 minus">
                      <span v-if="showChildrenSalePrice.min">
                        {{ product.currency ? product.currency.symbol : "$" }}
                        <template v-if="product.currency && product.currency.code === 'KHR'">
                          {{ showChildrenSalePrice.min | numFormat("0,0") }}
                        </template>
                        <template v-else>
                          {{ showChildrenSalePrice.min | numFormat(num_format) }}
                        </template>
                      </span>
                      <span v-if="showChildrenSalePrice.max">
                        {{ product.currency ? product.currency.symbol : "$" }}
                        <template v-if="product.currency && product.currency.code === 'KHR'">
                          {{ showChildrenSalePrice.max | numFormat("0,0") }}
                        </template>
                        <template v-else>
                          {{ showChildrenSalePrice.max | numFormat(num_format) }}
                        </template>
                      </span>
                    </label>
                    <br>
                    <template v-if="product.has_promotion">
                      <del class="font-s-16 badge badge-danger badge-del-price">
                        <em class="minus no-margin">
                          <span v-if="showChildrenOriginPrice.min">
                            {{ product.currency ? product.currency.symbol : "$" }}
                            <template v-if="product.currency && product.currency.code === 'KHR'">
                              {{ showChildrenOriginPrice.min | numFormat("0,0") }}
                            </template>
                            <template v-else>
                              {{ showChildrenOriginPrice.min | numFormat(num_format) }}
                            </template>
                          </span>
                          <span v-if="showChildrenOriginPrice.max">
                            {{ product.currency ? product.currency.symbol : "$" }}
                            <template v-if="product.currency && product.currency.code === 'KHR'">
                              {{ showChildrenOriginPrice.max | numFormat("0,0") }}
                            </template>
                            <template v-else>
                              {{ showChildrenOriginPrice.max | numFormat(num_format) }}
                            </template>
                          </span>
                        </em>
                      </del>
                    </template>
                  </template>
                </div>
              </div>
              <div
                v-if="product_child_data"
                class="form-group row"
              >
                <div class="col-xs-5 col-sm-4">
                  <label class="font-s-16 text-capitalize font-weight-normal">{{ $t("label.amountInStock") }}</label>
                </div>
                <div class="col-xs-7 col-sm-8">
                  <template>
                    <label
                      v-if="product_child_data.has_inventory"
                      :class="{'text-red': product_child_data.amount_in_stock <= 0}"
                      class="font-s-16"
                    >
                      {{
                        product_child_data.amount_in_stock |
                          numFormat(isFloat(parseFloat(product_child_data.amount_in_stock)))
                      }}
                      <span class="font-weight-normal">
                        {{ product_child_data.unit ? product_child_data.unit["name_" + $i18n.locale] : null }}
                      </span>
                    </label>
                    <label
                      v-else
                      class="font-s-16"
                    >{{ $t("label.unlimited") }}</label>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div
                v-if="product.images.length || (product_child_data && product_child_data.images.length)"
                class="form-group row no-margin"
              >
                <div class="col-xs-5 col-sm-4 no-padding">
                  <label class="font-s-16 text-capitalize font-weight-normal">{{ $t("label.images") }}</label>
                </div>
                <template v-if="product_child_data">
                  <template
                    v-if="product_child_data.images && Array.isArray(product_child_data.images) && product_child_data.images.length"
                  >
                    <template v-for="(image, key) in product_child_data.images">
                      <div
                        v-if="key === 0"
                        :key="key"
                        class="col-xs-4 col-sm-5"
                      >
                        <div class="thumbnail no-margin">
                          <img
                            :src="`${$config.base_url}/${replaceImgUrl(image.src, 'thumbnail')}`"
                            alt=""
                            width="100%"
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-for="(image, key) in product.images">
                      <div
                        v-if="key === 0"
                        :key="key"
                        class="col-xs-4 col-sm-5"
                      >
                        <div class="thumbnail no-margin">
                          <img
                            :src="`${$config.base_url}/${replaceImgUrl(image.src, 'thumbnail')}`"
                            alt=""
                            width="100%"
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <template v-if="product.images && Array.isArray(product.images) && product.images.length > 0">
                    <template v-for="(image, key) in product.images">
                      <div
                        v-if="key === 0"
                        :key="key"
                        class="col-xs-4 col-sm-5"
                      >
                        <div class="thumbnail no-margin">
                          <img
                            :src="`${$config.base_url}/${replaceImgUrl(image.src, 'thumbnail')}`"
                            alt=""
                            width="100%"
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                v-if="alert_message"
                class="alert alert-danger alert-dismissible"
                role="alert"
              >
                <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="alert"
                  type="button"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <label>{{ alert_message["message_" + $i18n.locale] }}</label>
              </div>
            </div>
          </div>
          <div
            v-if="product && product.variants && Array.isArray(product.variants) && product.variants.length"
            class="row"
          >
            <template v-for="(item, key) in product.variants">
              <div
                class="col-sm-12"
                :key="key"
              >
                <div class="row">
                  <div class="col-md-12">
                    <label class="font-s-16 text-capitalize font-weight-normal">
                      {{ item.name["name_" + $i18n.locale] }}
                    </label>
                  </div>
                  <div class="col-md-12 margin-b-15">
                    <div class="list-category list-product-variant scrollbar-x h-auto no-padding">
                      <div
                        v-for="(sub_item, index) in item.values"
                        :key="index"
                        :class="{'active': variantTypeActive(sub_item)}"
                        class="list-category-item popup-category-item-variant rounded on-page-order"
                        @click="onClickVariant(sub_item)"
                      >
                        <div
                          class="position-relative d-flex align-items-center justify-content-center flex-column h-100"
                        >
                          <div class="position-relative text-center popup-category-item-content__label">
                            <label
                              class="list-category-label no-margin font-s-15 text-ellipsis-2-line"
                              style="padding:0 10px;"
                            >
                              {{ sub_item.pivot.value }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default btn-lg"
            type="button"
            @click="onClickReset"
          >
            <i class="fas fa-history" /> {{ $t("button.reset") }}
          </button>
          <button
            class="btn btn-success btn-lg"
            type="button"
            @click="checkSelectedVariant"
          >
            <i class="fas fa-plus" /> {{ $t("button.addToCart") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "OfflineProductVariant",
  props: {
    modalId: {
      type: String,
      default: ""
    },
    product: {
      type: Object,
      default: null
    },
    checkVariant: {
      type: Boolean,
      default: false
    },
    offlineOrderProducts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      product_child_data: null,
      alert_message: null
    }
  },
  computed: {
    ...mapState({
      num_format: state => state.shop.numFormat,
      selectedVariant: state => state.order.selected_variants
    }),
    showChildrenSalePrice () {
      if (this.product.children_sale_price && this.product.children_sale_price.length > 0) {
        const max = Math.max(...this.product.children_sale_price)
        const min = Math.min(...this.product.children_sale_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.sale_price,
            min: null
          }
        }
      }
      return {
        max: this.product.sale_price,
        min: null
      }
    },
    showChildrenOriginPrice () {
      if (this.product.children_origin_price && this.product.children_origin_price.length > 0) {
        const max = Math.max(...this.product.children_origin_price)
        const min = Math.min(...this.product.children_origin_price)
        if (max === min) {
          return {
            max: max,
            min: null
          }
        } else if (max !== min) {
          return {
            max: max,
            min: min
          }
        } else {
          return {
            max: this.product.origin_price,
            min: null
          }
        }
      }
      return {
        max: this.product.origin_price,
        min: null
      }
    }
  },
  methods: {
    onClickReset () {
      this.alert_message = null
      this.$store.dispatch("order/clearSelectedVariant")
      this.product_child_data = null
    },
    showModal () {
      this.alert_message = null
      $(this.$refs.offlineProductVariantModalContainer).modal("show")
    },
    hideModal () {
      this.alert_message = null
      $(this.$refs.offlineProductVariantModalContainer, ".close").click()
    },
    toggleModal () {
      this.alert_message = null
      $(this.$refs.offlineProductVariantModalContainer).modal("toggle")
    },
    variantTypeActive (variant) {
      if (this.selectedVariant.length) {
        return this.selectedVariant.includes(variant)
      }
      return false
    },
    addItem (product) {
      if (this.offlineOrderProducts.some((productAdded) => productAdded.uuid === product.uuid)) {
        const productAdded = this.offlineOrderProducts.find((productAdded) => productAdded.uuid === product.uuid)
        if (productAdded && productAdded.pivot) {
          productAdded.pivot.quantity = parseFloat(productAdded.pivot.quantity) + parseFloat(1)
        }
      } else {
        this.$set(product, "pivot", {
          quantity: 1,
          served_quantity: 1,
          discount: 0,
          is_percentage: true,
          note: null
        })
        this.offlineOrderProducts.push(product)
      }
    },
    checkSelectedVariant () {
      this.alert_message = null
      if (this.selectedVariant.length === Object.entries(this.product.variants).length) {
        if (this.product_child_data) {
          this.addItem(this.product_child_data)
          this.$store.dispatch("order/clearSelectedVariant")
          $(this.$refs.offlineProductVariantModalContainer).modal("toggle")
        }
      } else {
        this.alert_message = {
          message_en: "Please select all options before adding to the cart.",
          message_km: "សូមជ្រើសរើសជំរើសទាំងអស់មុននឹងដាក់ចូលកន្ត្រក។"
        }
      }
    },
    onClickVariant (variant_item) {
      this.$store.dispatch("order/setSelectedVariant", variant_item)
      if (this.selectedVariant.length === Object.entries(this.product.variants).length) {
        this.product_child_data = null
        const a = []
        this.selectedVariant.forEach((item) => {
          a.push(item.pivot.value)
        })
        this.product_child_data = this.product.children.find((item) => {
          const pro_variants = item.product_variants
          switch (pro_variants.length) {
          case 1:
            if (a.includes(pro_variants[0].pivot.value)) {
              return item
            }
            break
          case 2:
            if (a.includes(pro_variants[0].pivot.value) && a.includes(pro_variants[1].pivot.value)) {
              return item
            }
            break
          case 3:
            if (a.includes(pro_variants[0].pivot.value) && a.includes(pro_variants[1].pivot.value) && a.includes(pro_variants[2].pivot.value)) {
              return item
            }
            break
          default:
            return null
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.product-has-variant .icon {
  font-size: 20px;
}
</style>
