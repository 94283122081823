<template>
  <div class="row">
    <card>
      <template slot="header">
        <div class="pull-left">
          <h3 class="card-header-title">
            {{ $t("offline.string.preBill") }}
          </h3>
        </div>
        <div class="pull-right">
          <button
            class="btn btn-default margin-r-5"
            @click="skipProcess()"
          >
            <i class="fas fa-arrow-circle-left" />
            {{ $t("button.back") }}
          </button>
          <button
            class="btn btn-info"
            @click="nextProcess()"
          >
            {{ $t("offline.string.next") }}
          </button>
        </div>
      </template>
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>{{ $t("offline.string.item") }}</th>
            <th>{{ $t("offline.string.quantity") }}</th>
            <th style="width: 80px;">
              {{ $t("offline.string.reserved") }}
            </th>
            <th style="width: 180px;">
              {{ $t("offline.string.discount") }}
            </th>
            <th>{{ $t("offline.string.price") }}</th>
            <th>{{ $t("offline.string.total") }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="products.length>0 && exchangeRate!==null">
            <tr
              v-for="(product, key) in products"
              :key="key"
            >
              <td>{{ product.name }}</td>
              <td>{{ product.quantity }}</td>
              <td>
                <input
                  v-model="product.reserved"
                  :placeholder="$t('offline.string.reserved')"
                  class="form-control"
                  type="number"
                >
              </td>
              <td>
                <div class="input-group">
                  <input
                    v-model="product.discount"
                    :placeholder="$t('offline.string.discount')"
                    class="form-control"
                    type="number"
                  >
                  <span class="input-group-addon">%</span>
                </div>
              </td>
              <td>
                <template v-if="product.unit!==null">
                  {{ product.sale_price }} {{ product.currency.code }}/({{ product.unit.name }})
                </template>
                <template v-else>
                  {{ product.sale_price }} {{ product.currency.code }}
                </template>
              </td>
              <td>{{ getSubTotal(product) }} {{ exchangeRate.currency.code }}</td>
            </tr>
            <tr>
              <td
                class="text-right"
                colspan="5"
              >
                Total:
              </td>
              <td>
                <strong>{{ totalPrice }} {{ exchangeRate.currency.code }}</strong>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </card>
  </div>
</template>

<script>
import Card from "../../../components/Card"
import indexedb from "../../../indexedb"
import payment from "./../../../mixins/payment"

export default {
  name: "PreBill",
  metaInfo () {
    return {
      title: this.$t("string.preBill"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  components: {
    Card
  },
  mixins: [payment],
  data () {
    return {
      products: [],
      exchangeRate: null,
      next: true
    }
  },
  computed: {
    totalPrice () {
      const items = this.products.map((product) => {
        return this.getSubTotal(product)
      })
      return items.reduce((result, next) => parseFloat(result) + parseFloat(next), 0).toFixed(2)
    }
  },
  methods: {
    skipProcess () {
      this.next = false
      this.nextProcess()
      this.$router.back()
    },
    nextProcess () {
      const data = this.products.map((product) => {
        return {
          order_uuid: this.$route.params.order_uuid,
          product_id: product.id,
          name: product.name,
          sale_price: product.sale_price,
          currency: product.currency,
          quantity: product.quantity,
          served_quantity: product.reserved,
          discount: product.discount,
          exchange: this.exchangeRate,
          tax: 0,
          is_percentage: true,
          created_at: new Date(),
          updated_at: new Date(),
          subTotal: this.getSubTotal(product)
        }
      })
      const params = {
        uuid: this.$route.params.order_uuid,
        subTotal: this.totalPrice,
        exchangeRate: this.exchangeRate,
        currency: this.exchangeRate.currency,
        data: data
      }
      this.$isLoading(true)
      indexedb.remove("order_products", params.uuid)
        .then(() => {
          indexedb.save("order_products", params)
            .then((response) => {
              if (response.status) {
                if (this.next) {
                  this.$router.push({
                    name: "offline-bill",
                    params: {
                      order_uuid: this.$route.params.order_uuid
                    }
                  })
                }
              }
            })
            .catch(() => {
              this.$isLoading(false)
            })
            .finally(() => {
              this.$isLoading(false)
            })
        })
    },
    getSubTotal (product) {
      const subTotal = this.exchangeMoney(product.currency.code, this.exchangeRate.currency.code, product.sale_price)
      product.subTotal = parseFloat(subTotal).toFixed(2)
      let total = (product.subTotal * product.reserved)
      if (product.discount >= 0 && product.discount <= 100) {
        total = this.precise(total - (total * (product.discount / 100)))
      } else {
        product.discount = 0
      }
      return total.toFixed(2)
    },
    getOrder () {
      this.$isLoading(true)
      indexedb.find("orders", this.$route.params.order_uuid)
        .then((order) => {
          if (order.is_paid) {
            this.$router.push({
              name: "offline-sale"
            })
            this.onResponseSuccess("This order have been billed")
          }
          this.products = order.product.map((product) => {
            this.$set(product, "reserved", product.quantity)
            this.$set(product, "discount", null)
            this.$set(product, "subTotal", 0)
            return product
          })
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getExchangeRate () {
      indexedb.all("exchange_rate")
        .then((exchangeRates) => {
          this.exchangeRate = exchangeRates[0]
        })
    }
  },
  mounted () {
    this.getOrder()
    this.getExchangeRate()
  }
}
</script>

<style scoped>

</style>
