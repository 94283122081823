<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.product.label.newWarehouse") }}
          </h3>
          <div class="box-tools hidden-xs">
            <a
              class="btn btn-default btn-sm"
              @click="$router.back()"
            >
              <i class="fas fa-arrow-circle-left" /> {{ $t("button.back") }}
            </a>
          </div>
        </div>
        <div class="box-body p-xs">
          <div class="row">
            <div class="col-md-12">
              <div
                :class="{'has-error' : errorValidations !== null && errorValidations.hasOwnProperty('name_en') }"
                class="form-group"
              >
                <label class="required">{{ $t("label.nameEn") }}</label>
                <input
                  v-model="warehouse.name_en"
                  :placeholder="$t('placeholder.enterNameAsEn')"
                  class="form-control"
                  type="text"
                >
                <template v-if="errorValidations !== null && errorValidations.hasOwnProperty('name_en')">
                  <span class="help-block">{{ errorValidations["name_en"][0] }}</span>
                </template>
              </div>
            </div>
            <div class="col-md-12">
              <div
                :class="{'has-error' : errorValidations !== null && errorValidations.hasOwnProperty('name_km') }"
                class="form-group"
              >
                <label class="required">{{ $t("label.nameKm") }}</label>
                <input
                  v-model="warehouse.name_km"
                  :placeholder="$t('placeholder.enterNameAsKm')"
                  class="form-control"
                  type="text"
                >
                <template v-if="errorValidations !== null && errorValidations.hasOwnProperty('name_km')">
                  <span class="help-block">{{ errorValidations["name_km"][0] }}</span>
                </template>
              </div>
            </div>
            <div class="col-md-12">
              <div
                :class="'form-group ' + (errorValidations !== null && errorValidations.hasOwnProperty('description') ? 'has-error' : '')"
              >
                <label class="">{{ $t("fields.label.description") }}</label>
                <textarea
                  v-model="warehouse.description"
                  :placeholder="$t('fields.placeholder.description')"
                  class="form-control"
                  rows="6"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group text-right">
                <template v-if="this.$route.name !== 'show-warehouse'">
                  <button
                    :class="`btn btn-${$route.name === 'edit-warehouse' ? 'info' : 'primary'}`"
                    @click="storeProductCategory"
                  >
                    <i class="fas fa-check-circle" />
                    <template v-if="$route.name === 'edit-warehouse'">
                      {{ $t("button.update") }}
                    </template>
                    <template v-else>
                      {{ $t("button.save") }}
                    </template>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "WarehouseForm",
  props: {
    columns: {
      type: String,
      default: "col-md-8 col-md-offset-2"
    }
  },
  data () {
    return {
      warehouse: {
        shop_id: null,
        name_en: "",
        name_km: "",
        description: ""
      },
      config: this.$config,
      errorValidations: null
    }
  },
  methods: {
    getWarehouse () {
      const self = this
      self.$isLoading(true)
      self.$axios.post(self.config.base_url + "/api/warehouse/show", {
        shop_id: self.get_shop.id,
        uuid: self.$route.params.uuid
      }).then((response) => {
        self.$isLoading(false)
        if (response.data.code === 1) {
          self.warehouse = response.data.data
        }
      })
    },
    storeProductCategory () {
      this.$isLoading(true)
      this.warehouse.shop_id = this.get_shop.id
      this.$axios.post(this.$config.base_url + "/api/warehouse/store", this.warehouse)
        .then((response) => {
          this.$isLoading(false)
          if (response.data.code === 1) {
            this.$router.push({ name: "list-warehouse" })
            if (this.warehouse.hasOwnProperty("id") && this.warehouse.id !== null) {
              this.$anotify(this.$t("model.warehouse"), "update")
            } else {
              this.$anotify(this.$t("model.warehouse"))
            }
          } else {
            this.errorValidations = response.data.message
          }
        })
    }
  },
  mounted () {
    const self = this
    if (self.$route.params.hasOwnProperty("uuid")) {
      self.getWarehouse()
    }
    if (self.$route.name === "show-warehouse") {
      $("input, textarea").attr("disabled", true)
    }
  }
}
</script>

<style scoped>

</style>
