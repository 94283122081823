<template>
  <div
    id="mySidebar"
    class="side-bar no-print h-100 user-select-none"
    v-if="shop"
  >
    <div class="hidden-sm hidden-md hidden-lg has-border-b">
      <div class="dismiss">
        <i class="fas fa-times" />
      </div>
      <div class="btn-set-device-sell">
        <template v-if="!device_for_sell && displayBtnSetDeviceToSell">
          <a
            class="w-100 d-block"
            @click="updateDeviceForSell"
          >
            <span
              class="fa-stack fa-lg"
              style="font-size: 16px;"
            >
              <i class="fas fa-shopping-cart fa-stack-1x text-black" />
              <i class="fas fa-ban fa-stack-2x text-danger" />
            </span>
          </a>
        </template>
      </div>
      <div class="logo">
        <router-link
          :to="{name: 'dashboard'}"
          class="app_show_name"
        >
          <i class="fas fa-home mr-3" /> {{ shop.name }}
        </router-link>
      </div>
      <router-link :to="{name: 'overview'}">
        <div class="w-100 xs-user-info has-border-t">
          <div
            class="xs-user-info_image"
            @click="$router.push({name: 'overview'})"
          >
            <template v-if="user && user.avatar_location">
              <img
                :src="`${checkUrlAvatar(user.avatar_location)}`"
                alt="User Image"
                class="user-image"
              >
            </template>
            <template v-else>
              <img
                :src="defaultAvatar"
                alt="User Image"
                class="user-image"
              >
            </template>
          </div>
          <div class="xs-user-info_name">
            <div class="text-bold">
              {{ user ? user.full_name : "Default User" }}
            </div>
            <small>{{ show_date }}</small>
          </div>
        </div>
      </router-link>
    </div>
    <slot />
    <ul class="pos-menubar hidden-sm hidden-md hidden-lg">
      <li class="pos-menubar-item">
        <a
          class="closesidebar"
          @click="changeLocale(locale === 'km' ? 'en' : 'km')"
        >
          <img
            alt="Flag"
            :src="`/flag/${locale === 'km' ? 'en' : 'kh'}.png`"
            style="height: 16px; width: 25px; margin-right: 5px"
          >
          <p>{{ locale === "km" ? "English" : "ភាសាខ្មែរ" }}</p>
        </a>
      </li>
      <li class="pos-menubar-item pos-menubar-item_logout">
        <a @click="logout">
          <i class="fas fa-sign-out-alt" />
          <p>{{ $t("label.logout") }}</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "LeftSideBarMobileScreen",
  data () {
    return {
      locale: localStorage.getItem("locale")
    }
  },
  computed: {
    ...mapGetters("shop", {
      shop: "shop",
      device_for_sell: "device_for_sell"
    }),
    ...mapGetters("user", {
      user: "getUser"
    }),
    displayBtnSetDeviceToSell () {
      const route_names = [
        "order-show",
        "payment-show",
        "cashier-list-v2",
        "list-recent-sell",
        "cashier-list-customer"
      ]
      return route_names.includes(this.$route.name)
    }
  },

  methods: {
    changeLocale (locale) {
      this.locale = locale
      this.$i18n.locale = locale
      localStorage.setItem("locale", locale)
    }
  }
}
</script>

<style scoped>
.btn-set-device-sell {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 10px;
  right: 65px;
  -webkit-transition: all .3s;
  transition: all .3s;
}
</style>
