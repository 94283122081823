<template>
  <div class="order-page-container w-100">
    <template v-if="!on_check_order">
      <template v-if="allowed">
        <div class="block-product-list flex-column">
          <div>
            <div class="block-search-with-category">
              <div class="block-search-box">
                <div class="box-search box-search-mb w-100">
                  <div class="box-search-icon margin-l-5">
                    <i class="fas fa-search" />
                  </div>
                  <input
                    v-model="filter_text"
                    :placeholder="$t('label.search')"
                    class="pos-input-search pos-input-lg no-border"
                    type="text"
                  >
                </div>
              </div>
              <div class="block-btn-actions bba-2">
                <div
                  class="btn-filter-sm mr-2 hidden-lg"
                  style=" vertical-align: bottom"
                >
                  <div
                    :title="$t('label.filter')"
                    class="user-select-none list-category-item on-page-order filter-category-main-item"
                    data-placement="top"
                    data-target="#categoryOfflineModal"
                    data-toggle="modal"
                    style="padding-top: 8px !important;"
                  >
                    <h4 class="list-category-label">
                      <i class="fas fa-filter" />
                      <span
                        v-if="countFilter > 0"
                        class="ml-2"
                      >({{ countFilter }})</span>
                    </h4>
                  </div>
                </div>
                <div
                  class="btn-group btn-group-lg switch-view"
                  role="group"
                >
                  <button
                    :class="{'btn-active': view_mode === 'grid'}"
                    :title="$t('label.viewAsGrid')"
                    class="btn btn-default outline-none"
                    type="button"
                    @click="changeViewModeProductOrder('grid')"
                  >
                    <i class="fas fa-th" />
                  </button>
                  <button
                    :class="{'btn-active': view_mode === 'list'}"
                    :title="$t('label.viewAsList')"
                    class="btn btn-default outline-none"
                    type="button"
                    @click="changeViewModeProductOrder('list')"
                  >
                    <i class="fas fa-th-list" />
                  </button>
                </div>
              </div>
              <div class="block-btn-actions bba-1 hidden-sm hidden-md hidden-lg">
                <button
                  aria-controls="collapseExample"
                  aria-expanded="false"
                  class="btn btn-lg btn-default bg-white no-margin has-shadow"
                  data-target="#collapseExample"
                  data-toggle="collapse"
                  type="button"
                >
                  <i class="fas fa-ellipsis-h text-muted" />
                </button>
              </div>
              <div
                id="collapseExample"
                class="collapse hidden-sm hidden-md hidden-lg"
              >
                <div class="py-3 text-right">
                  <div
                    class="btn-filter-sm mr-2 hidden-lg"
                    style=" vertical-align: bottom"
                  >
                    <div
                      :title="$t('label.filter')"
                      class="user-select-none list-category-item on-page-order filter-category-main-item"
                      data-placement="top"
                      data-target="#categoryOfflineModal"
                      data-toggle="modal"
                      style="padding-top: 8px !important;"
                    >
                      <h4 class="list-category-label">
                        <i class="fas fa-filter" />
                        <span
                          v-if="countFilter > 0"
                          class="ml-2"
                        >({{ countFilter }})</span>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="btn-group btn-group-lg switch-view"
                    role="group"
                  >
                    <button
                      :class="{'btn-active': view_mode === 'grid'}"
                      :title="$t('label.viewAsGrid')"
                      class="btn btn-default outline-none"
                      type="button"
                      @click="changeViewModeProductOrder('grid')"
                    >
                      <i class="fas fa-th" />
                    </button>
                    <button
                      :class="{'btn-active': view_mode === 'list'}"
                      :title="$t('label.viewAsList')"
                      class="btn btn-default outline-none"
                      type="button"
                      @click="changeViewModeProductOrder('list')"
                    >
                      <i class="fas fa-th-list" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-category hide-block-category shadow-bottom d-flex align-items-center">
              <div
                v-if="categories.length > 0"
                class="order-list-category overflow-x-auto scrollbar-x"
              >
                <div
                  :title="$t('label.filter')"
                  class="user-select-none list-category-item on-page-order filter-category-main-item"
                  data-placement="top"
                  data-target="#categoryOfflineModal"
                  data-toggle="modal"
                >
                  <h4 class="list-category-label">
                    <i class="fas fa-filter" />
                  </h4>
                </div>
                <div class="separate" />
                <template v-for="(category, key) in categories">
                  <div
                    :key="key"
                    v-if="key < 6"
                    :class="`${category.active ? 'active' : ''}`"
                    class="user-select-none list-category-item on-page-order"
                    @click="onSelectedCategories(category)"
                  >
                    <template v-if="category.image">
                      <img
                        alt="Category image"
                        :src="`${$config.base_url}${replaceImgUrl(category.image, 'small')}`"
                        class="img-category-sugg"
                      >
                    </template>
                    <template v-else>
                      <img
                        alt="Category image"
                        :src="defaultImg"
                        class="img-category-sugg"
                      >
                    </template>
                    <h4 class="list-category-label">
                      {{ category["name_" + $i18n.locale] }}
                    </h4>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <template v-if="onLoading">
            <div class="d-flex align-items-center justify-content-center on-loading-layout">
              <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
            </div>
          </template>
          <template v-else>
            <template v-if="!(productData.length > 0)">
              <no-any-records />
            </template>
            <template v-else>
              <div class="flex-1 overflow-y-auto scrollbar plc">
                <div class="products-listing">
                  <div
                    :class="countItems > 0 ? order_discount && order_discount > 0 ? 'products-listing-content_mb-sm-2' : 'products-listing-content_mb-sm-1' : ''"
                    class="products-listing-content"
                  >
                    <template v-for="(productItem, key) in productData">
                      <template v-if="view_mode === 'list'">
                        <OfflineProductList
                          :key="key"
                          :offline-order-products="offlineOrderProducts"
                          :product="productItem"
                          @onClick="addItem(productItem)"
                        />
                      </template>
                      <template v-else>
                        <OfflineProductCard
                          :key="key"
                          :offline-order-products="offlineOrderProducts"
                          :product="productItem"
                          @onClick="addItem(productItem)"
                        />
                      </template>
                    </template>
                    <div
                      v-if="Math.ceil(products.length/number_per_page) > 1"
                      class="w-100 mx-3 text-center"
                    >
                      <paginate
                        v-if="productData.length"
                        v-model="page"
                        :click-handler="onClickPaginationButton"
                        :container-class="'pagination pagination-lg'"
                        :margin-pages="2"
                        :next-text="$t('string.next')"
                        :page-class="'page-item'"
                        :page-count="Math.ceil(products.length/number_per_page)"
                        :page-range="3"
                        :prev-text="$t('string.prev')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <div class="block-order-list flex-column show-order-pro-sm">
          <div class="block-category op-block-category-btn-action">
            <div class="d-flex align-items-center h-100 justify-content-between">
              <div class="hidden-sm hidden-md hidden-lg">
                <button
                  class="btn btn-lg btn-default dismiss-order-pro-sm"
                  @click="closeDrawerCustomer"
                >
                  <i class="fas fa-arrow-left" />
                </button>
              </div>
              <div>
                <label
                  v-if="re_order"
                  class="font-s-18 no-margin"
                >#{{ re_order.order_number }}</label>
              </div>
              <div class="d-flex align-items-center">
                <div class="position-relative mr-2">
                  <button
                    :title="$t('list.holdSale')"
                    class="btn btn-default btn-lg dismiss-order-pro-sm"
                    @click="gotoParkSaleList"
                  >
                    <i class="fas fa-history" />
                    <span class="hide-label-sm ml-2">{{ $t("button.parkSale") }}</span>
                  </button>
                  <span
                    v-if="parseInt(numberParkOrder) > 0"
                    class="badge bg-red badge-count-hold-sale"
                  >{{ numberParkOrder }}</span>
                </div>
                <div>
                  <button
                    :class="!(offlineOrderProducts.length > 0) ? 'disabled' : ''"
                    :disabled="!(offlineOrderProducts.length > 0)"
                    class="btn btn-lg btn-primary mr-0 dismiss-order-pro-sm"
                    @click="confirmedOrder('park_sale')"
                  >
                    <i class="fas fa-cart-plus" />
                    <span class="ml-2 hide-label-sm">{{ $t("order.label.newOrder") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="list-order flex-1 h-100 overflow-hidden position-relative">
            <template>
              <div class="d-flex flex-column h-100">
                <div class="block-category order-product-head no-padding shadow-bottom">
                  <div class="list-order-header no-border">
                    <div class="list-order-header-w h-100">
                      <div class="list-order-label d-flex">
                        <span class="order-item_number"><strong>#</strong></span>
                        <span class="order-item_name"><strong>{{ $t("order.product.name") }}</strong></span>
                        <span class="order-item_qty"><strong>{{ $t("label.qty") }}</strong></span>
                        <span class="order-item_price"><strong>{{ $t("label.price") }}</strong></span>
                      </div>
                    </div>
                    <div
                      v-if="offlineOrderProducts.length"
                      class="list-order-btn"
                    >
                      <button
                        class="btn btn-danger mr-0"
                        @click="offlineOrderProducts = []"
                      >
                        <i class="fas fa-trash-alt" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex-1 overflow-y-auto h-100 scrollbar">
                  <div
                    v-if="offlineOrderProducts"
                    class="row mx-0 list-order-height scrollbar overflow-y-auto user-select-none"
                  >
                    <template v-for="(orderItem, orderItemIndex) in offlineOrderProducts">
                      <div
                        :key="orderItemIndex"
                        class="col-xs-12 no-padding list-order-item has-border-b"
                      >
                        <div class="d-flex align-items-center w-100 position-relative">
                          <a
                            class="list-order-item-w h-100"
                            data-placement="top"
                            data-target="#editProductOrderOfflineModal"
                            data-toggle="modal"
                            @click="editProductOrder(orderItem)"
                          >
                            <div class="list-order-label d-block">
                              <div class="has-greater-than-sign d-flex align-items-center position-relative">
                                <span class="order-item_number text-ellipsis">{{ parseInt(orderItemIndex) + 1 }}</span>
                                <span class="order-item_name text-ellipsis">
                                  {{ orderItem.name }}
                                  <template
                                    v-if="orderItem.product_variants && Array.isArray(orderItem.product_variants) && orderItem.product_variants.length > 0"
                                  >
                                    <small class="slash">
                                      <template v-for="(item, product_variant_key) in orderItem.product_variants">
                                        <span
                                          v-if="item.pivot.value"
                                          :key="product_variant_key"
                                        >{{
                                          item.pivot.value
                                        }}</span>
                                      </template>
                                    </small>
                                  </template>
                                  <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                                    <small>
                                      {{ $t("label.discount") }}:
                                      <template
                                        v-if="!orderItem.pivot.is_percentage && shop_currency && shop_currency.code === 'KHR'"
                                      >
                                        {{ orderItem.pivot.discount | numFormat("0,0") }}
                                      </template>
                                      <template v-else>
                                        {{ orderItem.pivot.discount | numFormat(num_format) }}
                                      </template>
                                      {{
                                        orderItem.pivot.is_percentage ? "%" : shop_currency ? shop_currency.symbol : "$"
                                      }}
                                    </small>
                                  </template>
                                  <template v-if="orderItem.promotion_value">
                                    <small>
                                      {{ $t("label.promotion") }}:
                                      <template
                                        v-if="!orderItem.promotion_is_percentage && shop_currency && shop_currency.code === 'KHR'"
                                      >
                                        {{ orderItem.promotion_value | numFormat("0,0") }}
                                      </template>
                                      <template v-else>
                                        {{ orderItem.promotion_value | numFormat(num_format) }}
                                      </template>
                                      {{
                                        orderItem.promotion_is_percentage ? "%" : shop_currency ? shop_currency.symbol : "$"
                                      }}
                                    </small>
                                  </template>
                                </span>
                                <span class="order-item_qty text-ellipsis">{{ orderItem.pivot.quantity }}</span>
                                <span
                                  v-if="orderItem.sale_price"
                                  class="order-item_price text-ellipsis"
                                >
                                  <template v-if="parseInt(orderItem.sale_price) > 0">
                                    {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                                    <span :class="{'text-red': calcDiscountPrice(orderItem) <= 0}">
                                      <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                        {{ calcDiscountPrice(orderItem) | numFormat("0,0") }}
                                      </template>
                                      <template v-else>
                                        {{ calcDiscountPrice(orderItem) | numFormat(num_format) }}
                                      </template>
                                    </span>
                                  </template>
                                  <template v-if="orderItem.pivot && orderItem.pivot.discount > 0">
                                    <br>
                                    <small style="font-weight: normal">
                                      <del>
                                        {{ orderItem.currency ? orderItem.currency.symbol : "" }}
                                        <template v-if="orderItem.currency && orderItem.currency.code === 'KHR'">
                                          {{ orderItem.sale_price | numFormat("0,0") }}
                                        </template>
                                        <template v-else>
                                          {{ orderItem.sale_price | numFormat(num_format) }}
                                        </template>
                                      </del>
                                    </small>
                                  </template>
                                </span>
                                <span
                                  v-else
                                  class="order-item_price text-ellipsis text-danger"
                                >
                                  {{ parseFloat(0) | numFormat(num_format) }}
                                </span>
                              </div>
                            </div>
                          </a>
                          <div class="list-order-btn">
                            <button
                              class="btn btn-danger mr-0"
                              @click="removeAnItemFromCart(orderItem)"
                            >
                              <i class="fas fa-times" />
                            </button>
                          </div>
                        </div>
                        <div
                          v-if="orderItem.pivot.note"
                          class="list-order-label d-block py-0 pb-3 text"
                        >
                          <small class="font-s-14 help-block mt-0 text-black">{{ $t("note") }}:
                            {{ orderItem.pivot.note }}</small>
                        </div>
                      </div>
                    </template>
                  </div>
                  <template v-else>
                    <div class="row mx-0 list-order-height h-100">
                      <no-any-records
                        :height="50"
                        image="/img/undraw_empty_cart_co35.svg"
                      />
                    </div>
                  </template>
                </div>
                <div class="row mx-0 block-amount scrollbar">
                  <div class="col-xs-12 no-padding">
                    <div class="row no-margin has-border-b">
                      <div
                        :class="order_discount && order_discount > 0 ? 'col-lg-6' : 'col-lg-5'"
                        class="col-xs-12 no-padding"
                      >
                        <div class="list-total-amount btn-discount-order">
                          <button
                            :class="{'disabled': !hasProductOrder}"
                            :disabled="!hasProductOrder"
                            class="btn btn-default btn-lg btn-block no-margin"
                            data-target="#addDiscount"
                            data-toggle="modal"
                            type="button"
                            @click="openDiscountModal"
                          >
                            <div class="fa-stack fa-discount mr-2">
                              <i class="fas fa-tag fa-rotate-90 fa-stack-2x" />
                              <i class="fas fa-percent fa-stack-1x fa-inverse" />
                            </div>
                            {{ $t("label.discount") }}
                            <template v-if="order_discount && order_discount > 0">
                              <span class="ml-2 font-s-18 text-bold text-red">
                                <template v-if="dis_as_percentage">
                                  {{ order_discount | numFormat(num_format) }} %
                                </template>
                                <template v-else>
                                  <template v-if="shop_currency">
                                    {{ shop_currency.code }}
                                    <template v-if="shop_currency.code === 'KHR'">
                                      {{ order_discount | numFormat("0,0") }}
                                    </template>
                                    <template v-else>
                                      {{ order_discount | numFormat(num_format) }}
                                    </template>
                                  </template>
                                  <template v-else>
                                    USD {{ order_discount | numFormat(num_format) }}
                                  </template>
                                </template>
                              </span>
                            </template>
                          </button>
                        </div>
                      </div>
                      <div
                        :class="order_discount && order_discount > 0 ? 'col-lg-6' : 'col-lg-7'"
                        class="col-xs-12 no-padding"
                      >
                        <div class="list-total-amount btn-select-customer">
                          <template v-if="selectedCustomer">
                            <div
                              class="selected-customer"
                              style="align-items: start; flex-direction: column"
                            >
                              <div class="sugg-cus-name">
                                <strong>{{ selectedCustomer.name }}</strong>
                              </div>
                            </div>
                            <div>
                              <button
                                :class="{'disabled': !hasProductOrder}"
                                :disabled="!hasProductOrder"
                                class="btn btn-lg btn-danger no-margin"
                                @click="clearSelectCustomer"
                              >
                                <i class="fas fa-trash-alt" />
                              </button>
                            </div>
                          </template>
                          <template v-else>
                            <button
                              :class="{'disabled': !hasProductOrder}"
                              :disabled="!hasProductOrder"
                              class="btn btn-default btn-lg btn-block mx-0"
                              @click="showSelectCustomer = !showSelectCustomer"
                            >
                              <i class="fas fa-user mr-2" />
                              {{ $t("button.select_customer") }}
                            </button>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 no-padding">
                    <div class="w-100 h-100">
                      <div class="list-total-amount block-btn-pay">
                        <button
                          :class="{'disabled': !offlineOrderProducts.length}"
                          :disabled="!offlineOrderProducts.length"
                          class="btn-success btn-pay justify-content-between"
                          @click="confirmedOrder('payment')"
                        >
                          <div class="no-margin text-left d-block">
                            <strong>{{ $t("button.pay") }}</strong>
                            <small class="d-block font-s-14">{{ countItems }} {{ $t("label.items") }}</small>
                          </div>
                          <div class="no-margin text-right d-block">
                            <div class="d-block">
                              <span
                                v-if="order_discount && order_discount > 0"
                                class="font-s-14 mr-2 badge badge-danger badge-del-price text-white hidden-md hidden-sm hidden-xs"
                              >
                                <del>
                                  {{ shop_currency ? shop_currency.code : "USD" }}
                                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                    {{ origin_amount_total | numFormat("0,0") }}
                                  </template>
                                  <template v-else>
                                    {{ origin_amount_total | numFormat(num_format) }}
                                  </template>
                                </del>
                              </span>
                              <span class="font-s-22">
                                {{ shop_currency ? shop_currency.code : "USD" }}
                                <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                  {{ subtotal | numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ subtotal | numFormat(num_format) }}
                                </template>
                              </span>
                              <small
                                v-if="order_discount && order_discount > 0"
                                class="d-block text-white font-s-14"
                              >
                                <del class="font-s-14 badge badge-danger badge-del-price text-white hidden-lg">
                                  {{ shop_currency ? shop_currency.code : "USD" }}
                                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                                    {{ origin_amount_total | numFormat("0,0") }}
                                  </template>
                                  <template v-else>
                                    {{ origin_amount_total | numFormat(num_format) }}
                                  </template>
                                </del>
                              </small>
                            </div>
                            <div class="d-block">
                              <span
                                v-if="order_discount && order_discount > 0"
                                class="font-s-14 mr-2 badge badge-danger badge-del-price text-white hidden-md hidden-sm hidden-xs"
                              >
                                <del>
                                  {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                                  <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                                    {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat("0,0") }}
                                  </template>
                                  <template v-else>
                                    {{
                                      moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat(num_format)
                                    }}
                                  </template>
                                </del>
                              </span>
                              <span class="font-s-18">
                                {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                                <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                                  {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat("0,0") }}
                                </template>
                                <template v-else>
                                  {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat(num_format) }}
                                </template>
                              </span>
                              <small
                                v-if="order_discount && order_discount > 0"
                                class="d-block text-white font-s-14"
                              >
                                <del class="font-s-14 badge badge-danger badge-del-price text-white hidden-lg">
                                  {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                                  <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                                    {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat("0,0") }}
                                  </template>
                                  <template v-else>
                                    {{
                                      moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat(num_format)
                                    }}
                                  </template>
                                </del>
                              </small>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :class="{'show-alert': show_alert}"
                  class="alert alert-dismissible hide-alert"
                  role="alert"
                >
                  <div class="font-s-15 d-flex align-items-center alcf">
                    <span class="mr-2">{{ $t("string.removeProduct") }} <strong>({{
                      remove_items.length
                    }})</strong></span>
                    <div class="button-undo ml-3">
                      <a
                        class="alert-link"
                        href="#"
                        @click="undo"
                      >{{ $t("button.undo") }}</a>
                    </div>
                  </div>
                  <div class="button-close">
                    <a
                      type="button"
                      @click="removeItem"
                    >
                      <span aria-hidden="true">&times;</span>
                    </a>
                  </div>
                </div>
              </div>
              <DrawerCustomer
                ref="addOfflineCustomer"
                :shop_id="shop ? shop.id : null"
                :show-select-customer="showSelectCustomer"
                class-list-customer="list-sugg-customer-payment"
                @closeDrawer="closeDrawerCustomer"
                @getChildData="getCustomer"
              />
            </template>
          </div>
        </div>
        <div
          :class="{'show-btn-sopl-xs': countItems > 0}"
          class="btn-sopl-xs hidden-sm hidden-md hidden-lg"
        >
          <p class="no-margin text-left d-block">
            <strong>{{ $t("button.pay") }}</strong>
            <small class="d-block font-s-14">{{ countItems }} {{ $t("label.items") }}</small>
          </p>
          <p class="no-margin text-right d-block">
            <span class="d-block">
              <span class="font-s-22">
                {{ shop_currency ? shop_currency.code : "USD" }}
                <template v-if="shop_currency && shop_currency.code === 'KHR'">
                  {{ subtotal | numFormat("0,0") }}
                </template>
                <template v-else>
                  {{ subtotal | numFormat(num_format) }}
                </template>
              </span>
              <small
                v-if="order_discount && order_discount > 0"
                class="d-block text-white font-s-14"
              >
                <del class="font-s-14 badge badge-danger badge-del-price text-white hidden-lg">
                  {{ shop_currency ? shop_currency.code : "USD" }}
                  <template v-if="shop_currency && shop_currency.code === 'KHR'">
                    {{ origin_amount_total | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ origin_amount_total | numFormat(num_format) }}
                  </template>
                </del>
              </small>
            </span>
            <span class="d-block">
              <span class="font-s-18">
                {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                  {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat("0,0") }}
                </template>
                <template v-else>
                  {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat(num_format) }}
                </template>
              </span>
              <small
                v-if="order_discount && order_discount > 0"
                class="d-block text-white font-s-14"
              >
                <del class="font-s-14 badge badge-danger badge-del-price text-white hidden-lg">
                  {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                  <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                    {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat("0,0") }}
                  </template>
                  <template v-else>
                    {{ moneyEquivalent(shop_currency.code, origin_amount_total) | numFormat(num_format) }}
                  </template>
                </del>
              </small>
            </span>
          </p>
        </div>
        <div
          id="categoryOfflineModal"
          aria-labelledby="categoryModalLabel"
          class="modal fade"
          role="dialog"
          tabindex="-1"
        >
          <div
            class="modal-dialog modal-scroll modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header-custom">
                <h4
                  id="categoryModalLabel"
                  class="modal-title"
                >
                  {{ $t("string.filterProductByCategory") }}
                </h4>
                <button
                  ref="closeCategoryModal"
                  :class="class_btn_modal"
                  aria-label="Close"
                  data-dismiss="modal"
                  type="button"
                >
                  <i
                    aria-hidden="true"
                    class="fas fa-times"
                  />
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      v-if="categories.length > 0"
                      class="list-category h-auto no-padding"
                    >
                      <div
                        v-for="(category, key) in categories"
                        :key="key"
                        :class="`${category.active ? 'active' : ''}`"
                        class="popup-category-item shadow rounded"
                        @click="onSelectedCategories(category)"
                      >
                        <span class="check-icon"><i class="fas fa-check-circle" /></span>
                        <div
                          class="position-relative d-flex align-items-center justify-content-center flex-column h-100"
                        >
                          <div class="popup-category-item-content__image rounded">
                            <img
                              v-if="category.image"
                              :src="`${$config.base_url}${replaceImgUrl(category.image, 'small')}`"
                              class="w-100"
                            >
                            <img
                              v-else
                              :src="defaultImg"
                              class="w-100"
                            >
                          </div>
                          <div class="position-relative text-center popup-category-item-content__label">
                            <label
                              class="list-category-label no-margin font-s-15 text-ellipsis-2-line"
                              style="padding:0 10px;"
                            >{{ category["name_" + $i18n.locale] }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-default btn-lg"
                  @click="onClickReset"
                >
                  <i class="fas fa-history" /> {{ $t("button.reset") }}
                </button>
                <button
                  class="btn btn-success btn-lg"
                  @click="$refs.closeCategoryModal.click()"
                >
                  <i class="fas fa-check-circle" /> {{ $t("button.apply") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--    Edit Product-->
        <div
          id="editProductOrderOfflineModal"
          aria-labelledby="editProductOrderOffline"
          class="modal fade"
          role="dialog"
          tabindex="-1"
        >
          <div
            class="modal-dialog"
            role="document"
          >
            <template v-if="editProduct">
              <div class="modal-content">
                <div class="modal-header-custom">
                  <h4 class="modal-title">
                    <strong>{{ editProduct.name }}</strong>
                  </h4>
                  <button
                    ref="closeEditProductOrder"
                    :class="class_btn_modal"
                    aria-label="Close"
                    data-dismiss="modal"
                    type="button"
                  >
                    <i
                      aria-hidden="true"
                      class="fas fa-times"
                    />
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                    <div class="form-group row">
                      <div class="col-sm-4">
                        <label class="font-s-18">{{ $t("label.amountInStock") }}</label>
                      </div>
                      <div class="col-sm-8">
                        <label
                          v-if="editProduct.has_inventory"
                          :class="{'text-red': calcAmountProduct <= 0}"
                          class="font-s-18"
                        >
                          {{ calcAmountProduct | numFormat("0,0") }}
                          {{ editProduct.unit ? editProduct.unit["name_" + $i18n.locale] : null }}
                        </label>
                        <label
                          v-else
                          class="font-s-18"
                        >{{ $t("label.unlimited") }}</label>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>{{ $t("label.qty") }}</label>
                      <div class="input-group input-group-lg">
                        <input
                          v-model="editProduct.pivot.quantity"
                          class="form-control"
                          type="number"
                        >
                        <template v-if="editProduct.unit">
                          <span class="input-group-addon">
                            {{ editProduct.unit["name_" + $i18n.locale] }}</span>
                        </template>
                        <span class="input-group-btn">
                          <button
                            class="btn btn-default no-margin"
                            @click="minusOne"
                          >
                            <i class="fas fa-minus" />
                          </button>
                          <button
                            class="btn btn-default no-margin"
                            @click="addOne"
                          >
                            <i class="fas fa-plus" />
                          </button>
                        </span>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="font-s-18">{{ $t("label.discount") }}</label>
                      <div class="input-group input-group-lg">
                        <input
                          v-model="editProduct.pivot.discount"
                          class="form-control input-lg"
                          type="number"
                        >
                        <div class="input-group-btn">
                          <button
                            :class="editProduct.pivot.is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                            class="btn no-margin bl-unset"
                            type="button"
                            @click="editProduct.pivot.is_percentage = true"
                          >
                            <i class="fas fa-percent" />
                          </button>
                          <button
                            :class="!editProduct.pivot.is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                            class="btn btn-default"
                            type="button"
                            @click="editProduct.pivot.is_percentage = false"
                          >
                            {{ shop_currency ? shop_currency.code : "USD" }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="font-s-18">{{ $t("note") }}</label>
                      <textarea
                        id="product_note"
                        v-model="editProduct.pivot.note"
                        :placeholder="$t('placeholder.enterNote')"
                        class="form-control rounded max-w-100 scrollbar"
                        name="note"
                        rows="10"
                        style="resize: none; height: 120px !important;"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    class="btn btn-lg btn-success"
                    @click="closeModalEditProduct(editProduct)"
                  >
                    {{ $t("btn.okay") }}
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!--    Discount-->
        <div
          id="addDiscount"
          aria-labelledby="myModalLabel"
          class="modal fade"
          data-backdrop="static"
          role="dialog"
          tabindex="-1"
        >
          <div
            class="modal-dialog"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header-custom">
                <h4
                  id="myModalLabel"
                  class="modal-title"
                >
                  {{ $t("label.discount") }}
                </h4>
                <button
                  :class="class_btn_modal"
                  aria-label="Close"
                  data-dismiss="modal"
                  type="button"
                >
                  <i
                    aria-hidden="true"
                    class="fas fa-times"
                  />
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-xs-12">
                    <div class="row form-group">
                      <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                        {{ $t("label.amountTotal") }}
                      </div>
                      <div class="col-md-8">
                        <p class="control-label user-select-none font-s-18 mt-3 font-weight-bold">
                          {{ shop_currency ? shop_currency.code : "USD" }}
                          <template v-if="shop_currency && shop_currency.code === 'KHR'">
                            {{ subtotal | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ subtotal | numFormat(num_format) }}
                          </template>
                        </p>
                        <small class="control-label user-select-none font-s-16 mt-3 font-weight-bold">
                          {{ shop_currency.code === "USD" ? "KHR" : "USD" }}
                          <template v-if="shop_currency && shop_currency.code !== 'KHR'">
                            {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat("0,0") }}
                          </template>
                          <template v-else>
                            {{ moneyEquivalent(shop_currency.code, subtotal) | numFormat(num_format) }}
                          </template>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12">
                    <div class="row form-group">
                      <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                        {{ $t("label.discount") }}
                      </div>
                      <div class="col-md-8">
                        <div class="input-group input-group-lg">
                          <input
                            v-model="discount"
                            aria-describedby="discount"
                            class="form-control"
                            min="0"
                            type="number"
                          >
                          <div class="input-group-btn">
                            <button
                              :class="is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                              class="btn no-margin bl-unset"
                              @click="is_percentage = true"
                            >
                              <i class="fas fa-percent" />
                            </button>
                            <button
                              :class="!is_percentage ? 'btn-primary text-white' : 'btn-default background-white'"
                              class="btn"
                              @click="is_percentage = false"
                            >
                              {{ currency ? currency.code : "USD" }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12">
                    <div class="row form-group">
                      <div class="col-md-4 control-label user-select-none font-s-18 mt-3">
                        {{ $t("label.authorizedBy") }}
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="discountAuthorizer"
                          :placeholder="$t('label.authorizesTheDiscount')"
                          class="form-control input-lg"
                          style="border-radius: 6px"
                          type="text"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-default btn-lg"
                  @click="resetDiscountModal"
                >
                  <i class="fas fa-history" /> {{ $t("button.reset") }}
                </button>
                <button
                  aria-label="Close"
                  class="btn btn-lg btn-primary"
                  data-dismiss="modal"
                  type="button"
                  @click="updateDiscountModal"
                >
                  <i class="fas fa-edit" />
                  {{ $t("button.update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <OfflineProductVariant
          ref="offlineProductVariantModal"
          :check-variant="checkVariant"
          :order-products="offlineOrderProducts"
          :product="product"
          modal-id="grid"
        />
      </template>
      <template v-else>
        <PlanMessage
          :height="'100%'"
          :message="message_allowed"
        />
      </template>
    </template>
    <template v-else>
      <div class="d-flex align-items-center justify-content-center on-loading-layout w-100">
        <i class="fas fa-circle-notch fa-spin mr-2" /> {{ $t("label.loading") }}...
      </div>
    </template>
  </div>
</template>

<script>
import indexedb from "../../../../indexedb"
import OfflineProductCard from "./../../order/includes/OfflineProductCard"
import NoAnyRecords from "../../../../components/NoAnyRecords"
import { mapState } from "vuex"
import OfflineProductList from "@/views/offline/order/includes/OfflineProductList"
import OfflineProductVariant from "@/views/offline/order/includes/OfflineProductVariant"
import DrawerCustomer from "@/components/DrawerCustomer"
import PlanMessage from "@/components/PlanMessage"

export default {
  name: "SaleVersion2",
  components: {
    PlanMessage,
    DrawerCustomer,
    OfflineProductVariant,
    OfflineProductList,
    NoAnyRecords,
    OfflineProductCard
  },
  metaInfo () {
    return {
      title: this.$t("menu.sale"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  data () {
    return {
      previousCode: "",
      barcode: "",
      products: [],
      categories: [],
      cashier: null,
      offlineOrderProducts: [],
      page: 1,
      number_per_page: 54,
      filter_text: "",
      selectedCategories: [],
      showSelectCustomer: false,
      selectedCustomer: null,
      shop: null,

      tmpProducts: [],

      // Add New For Version 2
      editProduct: null,
      exchangeRate: null,
      order_list: 0,
      re_order: null,

      numberParkOrder: 0,
      onLoading: true,
      show_alert: false,
      remove_items: [],

      product: null,
      origin_amount_total: 0,
      discount: 0,
      is_percentage: true,
      discountAuthorizer: null,

      plan: null,
      on_check_order: true,
      allowed: true,
      message_allowed: null
    }
  },
  computed: {
    ...mapState({
      view_mode: state => state.order.view_mode,
      num_format: state => state.shop.numFormat,
      shop_currency: state => state.shop.currency,
      order_discount: state => state.order.order_discount,
      dis_as_percentage: state => state.order.is_percentage,
      authorizer: state => state.order.discount_authorizer
    }),
    hasProductOrder () {
      if (this.offlineOrderProducts.length > 0) {
        localStorage.setItem("display_order_mode", "show_list")
        this.emit("display_order_mode", {
          display_order_mode: "show_list"
        })
      } else {
        localStorage.setItem("display_order_mode", "show_welcome")
        this.emit("display_order_mode", {
          display_order_mode: "show_welcome"
        })
      }
      return this.offlineOrderProducts.length > 0
    },
    checkVariant () {
      return false
    },
    countFilter () {
      return this.selectedCategories.length
    },
    productData () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.products = this.tmpProducts
      if (this.selectedCategories.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.products = this.products.filter((product) => {
          if (product.category === null) {
            return product
          } else {
            if (this.selectedCategories.find((category_id) => category_id === product.category.id)) {
              return product
            }
          }
        })
      }
      if (this.filter_text !== "") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.products = this.products.filter((product) => {
          const productName = product.name.toLowerCase()
          const filter_text = this.filter_text.toLowerCase()
          if (productName.includes(filter_text)) {
            return product
          }
        })
      }
      return this.paginate(this.products, this.number_per_page, this.page)
    },
    // Add New For Version 2
    subtotal () {
      let total = 0
      let valKhr = 0
      let valUsd = 0
      this.offlineOrderProducts.forEach((product) => {
        let result = 0
        if (product.pivot && product.pivot.discount > 0) {
          if (product.pivot.is_percentage) {
            const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
            result = parseFloat(product.sale_price) - amount_discount
          } else {
            let amount_discount = parseFloat(product.pivot.discount)
            if (this.shop_currency && product.currency && this.shop_currency.code !== product.currency.code) {
              amount_discount = this.exchangeMoney(this.shop_currency.code, product.currency.code, amount_discount)
            }
            result = parseFloat(product.sale_price) - parseFloat(amount_discount)
          }
        } else {
          result = parseFloat(product.sale_price)
        }
        if (product.currency && product.currency.code === "USD") {
          valUsd = parseFloat(valUsd) + (parseFloat(product.pivot.quantity) * result)
        } else {
          valKhr = parseFloat(valKhr) + (parseFloat(product.pivot.quantity) * result)
        }
        const usd = this.exchangeMoney("USD", this.shop_currency.code, valUsd)
        const khr = parseFloat(this.exchangeMoney("KHR", this.shop_currency.code, valKhr))
        total = khr + usd
      })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.origin_amount_total = total
      if (this.order_discount) {
        if (this.is_percentage) {
          total = total - ((total * parseFloat(this.order_discount)) / 100)
        } else {
          total = parseFloat(this.precise(total - this.order_discount))
        }
      }
      return parseFloat(total).toFixed(2)
    },
    countItems () {
      let total = 0
      this.offlineOrderProducts.forEach((product) => {
        total += parseFloat(product.pivot.quantity)
      })
      return total
    },
    calcAmountProduct () {
      if (this.editProduct && this.editProduct.has_inventory) {
        if (!this.editProduct.pivot.quantity) {
          return parseFloat(this.editProduct.amount_in_stock)
        }
        return parseFloat(this.editProduct.amount_in_stock) - parseFloat(this.editProduct.pivot.quantity)
      }
      return 0
    }
  },
  watch: {
    filter_text () {
      if (this.filter_text === "") {
        this.products = this.tmpProducts
      }
    },
    discount () {
      this.checkDiscountValue()
    },
    is_percentage () {
      this.checkDiscountValue()
    },
    "editProduct.pivot.discount" () {
      this.checkProductDiscountValue()
    },
    "editProduct.pivot.is_percentage" () {
      this.checkProductDiscountValue()
    }
  },
  created () {
    window.addEventListener("keypress", this.onWindowKeyPress)
  },
  mounted () {
    setTimeout(() => {
      if (this.$bill_active === "true") {
        if (localStorage.hasOwnProperty("plan") && localStorage.getItem("plan")) {
          this.plan = JSON.parse(localStorage.getItem("plan"))
          if (this.plan) {
            const sell = this.plan.sell
            let getOrders = []
            indexedb.all("orders").then((orders) => {
              getOrders = orders
            })
            if (sell.type && sell.type === "limited") {
              if (sell.number) {
                if (sell.number > getOrders.length) {
                  this.allowed = true
                } else {
                  this.allowed = false
                  let label_sell = "order"
                  if (sell.number > 1) {
                    label_sell = "orders"
                  }
                  this.message_allowed = {
                    message_en: "Your shop can create only " + sell.number + " " + label_sell,
                    message_km: "ហាងរបស់អ្នកអាចបង្កើតការបញ្ជាទិញបានតែ " + sell.number + " ប៉ុណ្ណោះ"
                  }
                }
              }
            } else {
              this.allowed = true
            }
          }
        }
      }
      this.getPreviousOrder()
      this.getCategories()
      this.getProducts()
      this.getExchangeRate()
      this.countParkOrder()
      this.getShop()
      this.$store.dispatch("order/setOrderDiscount", parseFloat(0))
      this.emit("display_dis", {
        discount: parseFloat(0)
      })
      this.$store.dispatch("order/setDiscountAuthorizer", null)
      if (this.$route.params.hasOwnProperty("order_uuid") && this.$route.params.order_uuid !== undefined) {
        this.getOrder()
      }
      this.on_check_order = false
    }, 500)
  },
  methods: {
    checkDiscountValue () {
      if (this.is_percentage) {
        if (this.discount < 0) {
          this.discount = 0
        } else if (this.discount > 100) {
          this.discount = 100
        }
      }
    },
    checkProductDiscountValue () {
      if (this.editProduct && this.editProduct.pivot) {
        if (this.editProduct.pivot.is_percentage) {
          if (this.editProduct.pivot.discount < 0) {
            this.editProduct.pivot.discount = 0
          } else if (this.editProduct.pivot.discount > 100) {
            this.editProduct.pivot.discount = 100
          }
        }
      }
    },
    minusOne () {
      if (this.editProduct && this.editProduct.pivot) {
        if (this.editProduct.pivot.quantity > 0) {
          this.editProduct.pivot.quantity -= 1
        } else {
          this.editProduct.pivot.quantity = 0
        }
      }
    },
    addOne () {
      if (this.editProduct && this.editProduct.pivot) {
        this.editProduct.pivot.quantity = parseFloat(this.editProduct.pivot.quantity) + 1
      }
    },
    clearSelectCustomer () {
      this.selectedCustomer = null
      this.$store.dispatch("order/setSelectCustomer", this.selectedCustomer)
      this.emit("display_sc", {
        customer: this.selectedCustomer
      })
    },
    closeDrawerCustomer () {
      this.showSelectCustomer = false
    },
    getCustomer () {
      if (this.$refs.addOfflineCustomer) {
        this.selectedCustomer = this.$refs.addOfflineCustomer.customer
      }
    },
    getShop () {
      indexedb.all("shop")
        .then((shop) => {
          this.shop = shop[0]
        })
    },
    // Add New For Version 2
    closeModalEditProduct (product) {
      if (product && product.pivot) {
        if (!product.pivot.quantity) product.pivot.quantity = 0
        if (!product.pivot.discount) product.pivot.discount = 0
      }
      this.$refs.closeEditProductOrder.click()
    },
    createAlert (item) {
      this.show_alert = true
      this.remove_items.push(item)
      if (this.time) {
        clearTimeout(this.time)
      }
      this.setDuration()
    },
    setDuration () {
      this.time = setTimeout(() => {
        this.removeItem()
      }, 10000)
    },
    removeItem () {
      this.remove_items = []
      this.show_alert = false
    },
    undo () {
      this.show_alert = false
      if (this.remove_items.length) {
        this.remove_items.forEach((item) => {
          this.offlineOrderProducts.push(item)
        })
      }
      localStorage.setItem("display_po", JSON.stringify(this.offlineOrderProducts))
      this.emit("display_po", {
        products: JSON.stringify(this.offlineOrderProducts)
      })
      this.remove_items = []
    },
    removeAnItemFromCart (item) {
      this.createAlert(item)
      this.offlineOrderProducts.splice(this.offlineOrderProducts.indexOf(item), 1)
      localStorage.setItem("display_po", JSON.stringify(this.offlineOrderProducts))
      this.emit("display_po", {
        products: JSON.stringify(this.offlineOrderProducts)
      })
    },
    calcDiscountPrice (product) {
      if (product.pivot && product.pivot.discount > 0) {
        if (product.pivot.is_percentage) {
          const amount_discount = parseFloat(product.sale_price) * parseFloat(product.pivot.discount) / 100
          const result = parseFloat(product.sale_price) - amount_discount
          return parseFloat(result).toFixed(2)
        } else {
          let amount_discount = parseFloat(product.pivot.discount)
          if (this.shop_currency && product.currency && this.shop_currency.code !== product.currency.code) {
            amount_discount = this.exchangeMoney(this.shop_currency.code, product.currency.code, amount_discount)
          }
          const result = parseFloat(product.sale_price) - parseFloat(amount_discount)
          return parseFloat(result)
        }
      }
      // call computed subtotal
      // eslint-disable-next-line no-unused-expressions
      this.subtotal
      return parseFloat(product.sale_price).toFixed(2)
    },
    editProductOrder (item) {
      this.editProduct = item
    },
    getExchangeRate () {
      indexedb.all("exchange_rate")
        .then((exchangeRates) => {
          this.exchangeRate = exchangeRates[0]
        })
    },
    getPreviousOrder () {
      indexedb.all("orders")
        .then((orders) => {
          this.order_list = orders.length
        })
        .catch((err) => {
          this.onResponseError(err)
        })
    },
    // End here
    onClickReset () {
      const all = {
        name_en: "All",
        name_km: "ទាំងអស់",
        id: -1,
        active: true
      }
      this.onSelectedCategories(all)
      this.$refs.closeCategoryModal.click()
    },
    onSelectedCategories (category) {
      if (category.id === -1) {
        this.categories = this.categories.map((item) => {
          item.active = item.id === category.id
          return item
        })
        this.selectedCategories = []
      } else {
        this.categories = this.categories.map((item) => {
          if (item.id === -1) {
            item.active = false
          }
          return item
        })
        if (!category.active) {
          this.selectedCategories.push(category.id)
          this.categories = this.categories.map((item) => {
            if (item.id === category.id) {
              item.active = true
            }
            return item
          })
        }
      }
    },
    paginate (data, number_per_page, page) {
      return data.slice((page - 1) * number_per_page, page * number_per_page)
    },
    onClickPaginationButton (page) {
      this.page = page
    },
    addItem (product) {
      if (this.offlineOrderProducts.some((productAdded) => productAdded.uuid === product.uuid)) {
        const productAdded = this.offlineOrderProducts.find((productAdded) => productAdded.uuid === product.uuid)
        if (productAdded && productAdded.pivot) {
          productAdded.pivot.quantity = parseFloat(productAdded.pivot.quantity) + parseFloat(1)
        }
      } else {
        this.$set(product, "pivot", {
          quantity: 1,
          served_quantity: 1,
          discount: 0,
          is_percentage: true,
          note: null
        })
        this.offlineOrderProducts.push(product)
      }
      localStorage.setItem("display_po", JSON.stringify(this.offlineOrderProducts))
      this.emit("display_po", {
        products: JSON.stringify(this.offlineOrderProducts)
      })
    },
    gotoParkSaleList () {
      if (this.offlineOrderProducts.length) {
        this.$swal({
          html: `<p class="text-bold font-s-18">${this.$t("string.confirmParkSale")}</p>`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: this.$t("string.yesIAmSure"),
          cancelButtonText: this.swalNoButton
        }).then(() => {
          if (this.offlineOrderProducts.length > 0) {
            this.confirmedOrder("park_sale")
          } else {
            this.$router.push({ name: "offline-cashier-order-list" })
          }
        }, (dismiss) => {
          if (dismiss === "cancel") {
            this.$router.push({ name: "offline-cashier-order-list" })
          }
          if (!(dismiss === "cancel")) {
            throw dismiss
          }
        }).catch(function (err) {
          throw err
        })
      } else {
        this.$router.push({ name: "offline-cashier-order-list" })
      }
    },
    confirmedOrder (type) {
      const self = this
      if (type === "new_order") {
        this.offlineOrderProducts = []
        this.re_order = null
        this.$router.push({ name: "offline-sale" })
      } else {
        if (localStorage.hasOwnProperty("user")) {
          this.cashier = JSON.parse(localStorage.getItem("user"))
        }
        const order = {
          product: this.offlineOrderProducts,
          cashier: this.cashier,
          created_at: new Date(),
          uuid: this.$route.params.order_uuid || this.random_uuidv4(),
          discount: this.order_discount,
          is_percentage: this.dis_as_percentage,
          authorized: this.authorizer,
          customer: this.selectedCustomer
        }
        if (!(this.$route.params.hasOwnProperty("order_uuid") && this.$route.params.order_uuid !== undefined)) {
          this.order_list = parseInt(this.order_list) + 1
          order.order_number = this.order_list
        } else if (this.re_order) {
          order.order_number = this.re_order.order_number
        }
        this.$isLoading(true)
        indexedb.save("orders", order)
          .then((response) => {
            if (response.status) {
              const data = this.offlineOrderProducts.map((product) => {
                this.$set(product, "order_uuid", this.$route.params.order_uuid)
                this.$set(product, "product_id", product.id)
                this.$set(product, "created_at", new Date())
                this.$set(product, "updated_at", new Date())
                this.$set(product, "subTotal", this.subtotal)
                return product
              })
              const params = {
                uuid: order.uuid,
                subTotal: this.subtotal,
                exchangeRate: this.exchangeRate,
                currency: this.shop_currency,
                data: data
              }
              indexedb
                .remove("order_products", params.uuid)
                .then(() => {
                  indexedb.save("order_products", params)
                    .then((response) => {
                      if (response.status) {
                        if (type === "park_sale") {
                          this.$toastr(this.$t("message.success"), this.$t("message.parkOrderSuccess"), "success")
                          this.offlineOrderProducts = []
                          this.re_order = null
                          this.countParkOrder()
                          this.$router.push({ name: "offline-cashier-order-list" })
                        } else if (type === "payment") {
                          this.$router.push({
                            name: "offline-bill",
                            params: { order_uuid: order.uuid }
                          })
                        }
                      }
                    })
                    .catch((error) => {
                      self.onResponseError(error)
                    })
                    .finally(() => {
                      this.$isLoading(false)
                    })
                })
                .catch((error) => {
                  this.onResponseError(error)
                })
            }
          })
          .catch((error) => {
            self.onResponseError(error)
          })
          .finally(() => {
            this.$isLoading(false)
          })
      }
    },
    getProductSalePrice (product) {
      if (product.is_custom_product) {
        return product.custom_product_price
      } else {
        return product.sale_price
      }
    },
    getProducts () {
      indexedb.all("products")
        .then((products) => {
          this.products = products
          this.products.forEach((item) => {
            item.sale_price = this.getProductSalePrice(item)
          })
          this.products.sort(function (a, b) {
            const keyA = new Date(a.created_at)
            const keyB = new Date(b.created_at)
            // Compare the 2 dates
            if (keyA > keyB) return -1
            if (keyA < keyB) return 1
            return 0
          })
          this.tmpProducts = products
        })
        .finally(() => {
          this.onLoading = false
        })
    },
    countParkOrder () {
      indexedb.all("orders")
        .then((orders) => {
          this.tmp = orders.filter((item) => {
            if (!(item.hasOwnProperty("is_paid"))) {
              return item
            }
          })
          this.numberParkOrder = this.tmp.length
        })
    },
    getOrder () {
      indexedb.find("orders", this.$route.params.order_uuid)
        .then((order) => {
          this.re_order = order
          if (this.re_order.hasOwnProperty("is_percentage")) {
            this.is_percentage = this.re_order.is_percentage
            this.$store.dispatch("order/setOrderDiscountStatus", this.re_order.is_percentage)
            this.emit("display_dis_percent", {
              display_dis_percent: this.re_order.is_percentage
            })
          }
          if (this.re_order.discount) {
            this.$store.dispatch("order/setOrderDiscount", parseFloat(this.re_order.discount))
            this.emit("display_dis", {
              discount: parseFloat(this.re_order.discount)
            })
          }
          this.discountAuthorizer = this.re_order.authorized
          this.$store.dispatch("order/setDiscountAuthorizer", this.re_order.authorized)
          if (this.re_order.customer) {
            this.selectedCustomer = this.re_order.customer
          }
          order.product.forEach((item) => {
            if (!item.hasOwnProperty("pivot")) {
              this.$set(item, "pivot", {
                quantity: 1,
                discount: 0,
                is_percentage: true,
                note: null
              })
            }
          })
          this.offlineOrderProducts = order.product
          localStorage.setItem("display_po", JSON.stringify(this.offlineOrderProducts))
          this.emit("display_po", {
            products: JSON.stringify(this.offlineOrderProducts)
          })
        })
        .catch(() => {
          this.re_order = null
          this.offlineOrderProducts = []
          this.$router.push({ name: "offline-sale" })
        })
    },
    getCategories () {
      indexedb.all("product_categories")
        .then((data) => {
          const all = {
            name_en: "All",
            name_km: "ទាំងអស់",
            id: -1,
            active: true
          }
          this.categories.push(all)
          data.forEach((category) => {
            category.active = false
            this.categories.push(category)
          })
        })
    },
    onWindowKeyPress (e) {
      if (e.key === "Enter") {
        this.previousCode = this.barcode
        if (this.previousCode.length > 3) {
          e.preventDefault()
          this.onBarcodeScanned(this.previousCode)
        }
      } else {
        this.barcode += e.key
      }
      setTimeout(() => {
        this.barcode = ""
      }, 200)
    },
    onBarcodeScanned (barcode) {
      if (this.product && this.$refs.offlineProductVariantModal) {
        this.$refs.offlineProductVariantModal.hideModal()
      }
      indexedb.all("products")
        .then((products) => {
          this.products = products
          this.product = this.products.find((item) => {
            return item.barcode === barcode
          })
          if (!this.product) {
            this.$toastr("success", this.$t("string.theProductCanNotFound"), this.$t("string.warning"))
          } else {
            if (this.product && this.product.children.length) {
              this.$refs.offlineProductVariantModal.showModal()
            } else {
              this.addItem(this.product)
            }
          }
        })
        .catch((err) => {
          this.onResponseError(err)
        })
    },
    resetDiscountModal () {
      this.discount = 0
      this.is_percentage = true
      this.discountAuthorizer = null
      this.$store.dispatch("order/setOrderDiscountStatus", this.is_percentage)
      this.emit("display_dis_percent", {
        display_dis_percent: this.is_percentage
      })
      this.$store.dispatch("order/setOrderDiscount", this.discount)
      this.emit("display_dis", {
        discount: this.discount
      })
      this.$store.dispatch("order/setDiscountAuthorizer", this.discountAuthorizer)
    },
    updateDiscountModal () {
      this.$store.dispatch("order/setOrderDiscountStatus", this.is_percentage)
      this.emit("display_dis_percent", {
        display_dis_percent: this.is_percentage
      })
      this.$store.dispatch("order/setOrderDiscount", this.discount)
      this.emit("display_dis", {
        discount: this.discount
      })
      this.$store.dispatch("order/setDiscountAuthorizer", this.discountAuthorizer)
    },
    openDiscountModal () {
      this.discount = parseFloat(this.order_discount)
      this.is_percentage = this.dis_as_percentage
      this.discountAuthorizer = this.authorizer
    }
  }
}
</script>

<style scoped>
.plc {
  background-color: #f9f9f9;
}

.separate {
  border-right: 2px solid #ddd;
  margin-right: 8px;
}

.block-search-with-category {
  width: 100%;
  height: 60px;
  padding: 5px 15px;
  display: block;
  position: relative;
}

.block-category.hide-block-category {
  padding: 0 15px;
}

.block-search-box {
  width: calc(100% - 120px);
  margin-right: 8px;
}

.block-btn-actions {
  width: 110px;
  text-align: right;
}

.block-search-box, .block-btn-actions {
  display: inline-block;
  vertical-align: middle;
}

.btn-filter-sm {
  display: none;
}

.separate {
  border-right: 2px solid #ddd;
  margin-right: 8px;
}

.search-sh i {
  font-size: 24px;
}

.list-category-item.filter-category-main-item {
  max-width: 75px;
  min-width: 75px;
}

.btn-select-customer {
  padding: 14px 16px 14px 8px;
}

.btn-discount-order {
  padding: 14px 16px;
  border-right: 1px solid #f3f2f2;
}

.fa-discount {
  font-size: 10px !important;
  line-height: 1.8rem !important;
}

.fa-discount .fa-stack-2x {
  font-size: 20px !important;
}

.fa-discount .fa-stack-1x {
  font-size: 8px !important;
  transform: rotate(135deg);
}

.has-greater-than-sign:before {
  content: none;
  font-family: "FontAwesome", serif;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: -6px;
  color: #878787;
}

.block-btn-pay {
  padding: 14px 16px !important;
}

.switch-view {
  margin-right: 5px;
}

@media screen and (max-width: 1200px) {
  .block-search-with-category {
    padding: 7px 8px;
    height: auto;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .08);
    z-index: 2;
  }

  .block-search-box {
    width: calc(100% - 195px);
  }

  .block-btn-actions.bba-1 {
    display: none;
  }

  .block-btn-actions.bba-2 {
    display: inline-block;
    width: 185px;
  }

  .btn-discount-order {
    padding: 8px 16px;
    border-right: unset;
  }

  .btn-filter-sm {
    display: inline-block !important;
    height: 46px !important;
    max-width: 75px;
    min-width: 75px;
  }

  .block-category.hide-block-category {
    display: none !important;
  }

  .block-search-with-category {
    box-shadow: 0 5px 8px rgba(0, 0, 0, .08);
  }

  .switch-view {
    margin-right: 0;
  }
}

/*________________________________________________________________________________________________*/

@media screen and (max-width: 991.98px) {
  .switch-view {
    vertical-align: top;
  }
}

@media screen and (max-width: 767px) {
  .box-search-mb {
    margin-bottom: 0;
  }

  .block-search-with-category {
    display: block;
    height: auto;
  }

  .block-search-box {
    width: calc(100% - 65px);
  }

  .block-search-box,
  .block-btn-actions.bba-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .block-btn-actions.bba-1 .btn-filter-sm.mr-2 {
    margin-right: 0 !important;
  }

  .block-btn-actions.bba-1 {
    display: inline-block;
    width: 55px;
  }

  .block-btn-actions.bba-2 {
    display: none;
    width: 100%;
  }

  .block-btn-pay {
    padding: 14px 8px !important;
  }
}
</style>
