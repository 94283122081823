<template>
  <div class="content-wrapper">
    <section class="content container-fluid content_p-xs">
      <div class="row">
        <div class="col-md-12 p-xs">
          <router-view />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ProductTypeLayout"
}
</script>

<style scoped>

</style>
