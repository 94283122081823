<template>
  <div class="h-100">
    <div class="non-footer-container">
      <NavbarV2 />
      <div class="container title">
        <div class="col-md-12">
          <h3>{{ $t("profile.label.account") }}</h3>
        </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <dashboard-footer />
    </div>
  </div>
</template>

<script>
import DashboardFooter from "../../includes/footer/Dashboard"
import NavbarV2 from "@/includes/NavbarV2"

export default {
  name: "Index",
  components: {
    NavbarV2,
    DashboardFooter
  }
}
</script>

<style scoped>
.non-footer-container {
  min-height: calc(100vh - 36px);
}
</style>
