import Index from "@/views/inventories/warehouses/Index"
import List from "@/views/inventories/warehouses/List"
import Create from "@/views/inventories/warehouses/Create"
import Edit from "@/views/inventories/warehouses/Edit"

export const warehouseRouters = {
  path: "warehouse",
  component: Index,
  name: "warehouse",
  redirect: "/admin/inventories/warehouse/",
  children: [
    {
      path: "",
      name: "list-warehouse",
      component: List
    },
    {
      path: "create",
      name: "create-warehouse",
      component: Create
    },
    {
      path: ":uuid/show",
      name: "show-warehouse",
      component: Edit
    },
    {
      path: ":uuid/edit",
      name: "edit-warehouse",
      component: Edit
    }
  ]
}
