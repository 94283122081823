<template>
  <div>
    <div class="container title no-print">
      <div class="col-md-12">
        <button
          class="btn btn-default"
          style="margin-right: 5px"
          @click="$router.back()"
        >
          <i class="fas fa-arrow-circle-left" />
          {{ $t("button.back") }}
        </button>
        <button
          class="btn btn-secondary margin-left"
          @click="onClickPrint"
        >
          <i class="fas fa-print" />
          {{ $t("button.print") }}
        </button>
      </div>
    </div>
    <div class="overflow-y-auto h-100 scrollbar">
      <div class="invoice-container">
        <div
          v-if="restaurant"
          id="container"
          style="padding: 0 !important; min-height: auto;"
        >
          <div class="header text-center">
            <p style="margin: 0">
              <img
                id="logo"
                :src="getSrc(restaurant.logo)"
                style="max-width: 220px; max-height: 100px;"
                alt="Shop Logo"
              >
            </p>
            <table class="table table-hover auto-width">
              <tr>
                <td
                  class="no-border"
                  colspan="4"
                  style="text-align: center"
                >
                  <strong>{{ restaurant.name }}</strong>
                </td>
              </tr>
              <tr>
                <td
                  class="no-border"
                  colspan="4"
                  style="text-align: center"
                >
                  <strong>Pre-Receipt</strong>
                </td>
              </tr>
              <tr class="below-calculate">
                <td
                  class="no-border tiny-text"
                  colspan="4"
                >
                  {{ restaurant.address }} | {{ restaurant.tel }}
                </td>
              </tr>
              <tr class="below-calculate tiny-text">
                <td>{{ $t("label.date") }}:</td>
                <td>
                  <strong>{{ $moment(order.created_at).locale("en").format("DD/MM/YYYY") }}</strong>
                </td>
                <td>Time:</td>
                <td><strong>{{ $moment(order.created_at).locale("en").format("hh:mm:ss A") }}</strong></td>
              </tr>
              <tr
                v-if="cashier!==null"
                class="below-calculate tiny-text"
              >
                <td class="no-border">
                  {{ $t("label.cashier") }}:
                </td>
                <td class="no-border">
                  <strong>{{ cashier[0].full_name }}</strong>
                </td>
              </tr>
              <tr
                v-if="order.customer"
                class="below-calculate tiny-text"
              >
                <td>{{ $t("label.customer") }}:</td>
                <td>
                  <strong v-if="order.customer.name">{{ order.customer.name }}</strong>
                  <span v-else>{{ $t("string.na") }}</span>
                </td>
                <td>{{ $t("label.gender") }}:</td>
                <td><strong>{{ order.customer.gender.name_en }}</strong></td>
              </tr>
              <tr v-if="order.customer && order.customer.tel">
                <td>{{ $t("label.telephone") }}:</td>
                <td>
                  <strong>{{ order.customer.tel }}</strong>
                </td>
              </tr>
              <tr class="below-calculate tiny-text">
                <td class="no-border">
                  Tables:
                </td>
                <td class="no-border">
                  <template v-if="order.tables !== null && order.tables.length>0">
                    <template v-for="(table, key) in order.tables">
                      <strong :key="key">
                        {{ table.name }}
                        <template v-if="key !== order.tables.length - 1">,</template>
                      </strong>
                    </template>
                  </template>
                  <template v-else>
                    <strong>Take away</strong>
                  </template>
                </td>
              </tr>
            </table>
          </div>
          <div class="body">
            <table class="table table-hover auto-width small-text">
              <thead>
                <tr>
                  <th>N<sup>o</sup></th>
                  <th>{{ $t("label.item") }}</th>
                  <th class="text-center">
                    {{ $t("label.qty") }}
                  </th>
                  <th class="text-center">
                    {{ $t("label.price") }}
                  </th>
                  <th class="text-center">
                    Dis
                  </th>
                  <th
                    class="text-right"
                    style="width: 25%;"
                  >
                    {{ $t("label.subTotal") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(product_order, key) in products"
                  :key="key"
                >
                  <td>{{ key + 1 }}</td>
                  <td>
                    <div>
                      {{ product_order.product.name }}
                    </div>
                    <div v-if="product_order.product && product_order.product.parent_id !== null">
                      <template
                        v-if="product_order.product && product_order.product.product_variants && Array.isArray(product_order.product.product_variants) && product_order.product.product_variants.length > 0"
                      >
                        <template v-for="(variant, index) in product_order.product.product_variants">
                          ({{ variant.pivot.value }})
                          <span
                            v-if="index !== product_order.product.product_variants.length-1"
                            :key="index"
                          >/</span>
                        </template>
                      </template>
                    </div>
                  </td>
                  <td>{{ product_order.served_quantity }}</td>
                  <td>{{ product_order.product.sale_price }}{{ product_order.product.currency.symbol }}</td>
                  <td>
                    <span />
                    <template v-if="product_order.is_percentage">
                      {{ product_order.discount }}%
                    </template>
                    <template v-else>
                      {{ product_order.discount }}{{ product_order.product.currency.symbol }}
                    </template>
                  </td>
                  <td class="text-right">
                    {{ parseFloat(getSubtotal(product_order)).toFixed(2) }}{{
                      restaurant.currency.code ===
                        "USD" ? "$" : "៛"
                    }}
                  </td>
                </tr>
                <tr class="no-border below-calculate">
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ $t("label.subTotal") }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{
                      restaurant.currency.code === "USD" ?
                        (parseFloat(subTotal).toFixed(2) + "$") :
                        (parseFloat(subTotal).toFixed(2) + "៛")
                    }}
                  </td>
                </tr>
                <tr class="no-border below-calculate">
                  <td
                    class="text-right no-border"
                    colspan="4"
                  >
                    {{ $t("label.discount") }}
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    {{ order.discount || 0 }}%
                  </td>
                </tr>
                <tr class="no-border below-calculate">
                  <td
                    class="text-right no-border"
                    colspan="4"
                  >
                    {{ $t("label.by") }}
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    {{ order.discount_authorizer || $t("string.na") }}
                  </td>
                </tr>
                <tr class="no-border below-calculate">
                  <td
                    class="text-right no-border"
                    colspan="4"
                  >
                    {{ $t("label.amountTotal") }} (USD)
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    <strong>{{ parseFloat(amountTotal / exchangeRate.value).toFixed(2) }}$</strong>
                  </td>
                </tr>
                <tr class="no-border below-calculate">
                  <td
                    class="text-right no-border"
                    colspan="4"
                  >
                    {{ $t("label.amountTotal") }} (KHR)
                  </td>
                  <td
                    class="text-right no-border"
                    colspan="2"
                  >
                    <strong>{{ parseFloat(amountTotal).toFixed(2) }}៛</strong>
                  </td>
                </tr>
                <tr class="below-calculate">
                  <td
                    class="tiny-text no-border"
                    colspan="4"
                  >
                    {{ $t("label.exchangeRate") }} 1$ = {{ exchangeRate.value }}៛
                  </td>
                </tr>
                <tr class="below-calculate">
                  <td
                    class="tiny-text no-border"
                    colspan="6"
                    style="text-align: center; padding-top: 15px;"
                  >
                    {{ $t("strings.thankYouForComing") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="footer"
            style="border-top: 1px solid #ddd; text-align: center; min-height: 50px"
          >
            <div>{{ $t("label.poweredBy") }} Asorasoft.com</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import payment from "../../mixins/payment"

export default {
  name: "PreviewOrder",
  metaInfo () {
    return {
      title: this.$t("label.previewOrder"),
      titleTemplate: "%s | " + this.$config.app_name
    }
  },
  mixins: [payment],
  data () {
    return {
      restaurant: null,
      order: null,
      products: null,
      exchangeRate: null,
      cashier: null
    }
  },
  computed: {
    amountTotal () {
      return this.precise(((100 - this.order.discount) / 100) * this.subTotal)
    },
    subTotal () {
      let total = 0
      this.products.forEach((item) => {
        total += parseFloat(this.getSubtotal(item))
      })
      return total
    }
  },
  methods: {
    onClickPrint () {
      window.print()
    },
    getSubtotal (item) {
      let subtotal = 0
      if (item.is_percentage) {
        subtotal = item.served_quantity * item.product.sale_price * (100 - item.discount) / 100
      } else {
        subtotal = (item.served_quantity * item.product.sale_price) - item.discount
      }
      return this.exchangeMoney(item.product.currency.code, this.restaurant.currency.code, subtotal)
    },
    getSrc (src) {
      if (src.includes("http")) {
        return src
      } else {
        return this.$config.base_url + "/" + src
      }
    },
    getOrder () {
      this.$isLoading(true)
      this.$axios.post(this.$config.base_url + "/api/order/get-order", {
        shop_id: this.get_shop.id,
        uuid: this.$route.params.uuid
      }).then((response) => {
        if (response.data.code === 1) {
          const result = response.data.data
          this.cashier = result.user
          this.restaurant = result.shop
          this.order = result
          this.products = result.order_products
          this.exchangeRate = result.exchangeRate
          this.products.forEach((order) => {
            const subtotal = order.served_quantity * order.product.sale_price * (100 - order.discount) / 100
            this.$set(order, "subTotal", subtotal)
          })
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.getOrder()
  }
}
</script>

<style scoped>
.margin-left {
  margin-left: 6px;
}

.qr-container {
  transform: scale(0.35);
  transform-origin: 50% 0;
}

.invoice-container {
  width: 100mm;
  background-color: white;
  margin: auto;
  min-height: 832px;
}

.auto-width {
  table-layout: auto;
  padding: 0 12px;
}

.auto-width td, .auto-width th {
  padding: 12px 0;
}

.auto-width td:first-child, .auto-width th:first-child {
  padding-left: 12px;
}

.auto-width td:last-child, .auto-width th:last-child {
  padding-right: 12px;
}

.auto-width > thead > tr:first-child, .auto-width > tbody > tr:first-child {
  width: 10mm !important;
}

.below-calculate > td {
  height: 5mm;
  padding: 0 2px;
}

.small-text {
  font-size: 1.1em;
}

.tiny-text {
  font-size: 1em;
}

.item-list > td {
  padding: 3px 0;
  vertical-align: middle;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
</style>
