<template>
  <div
    id="adjustStockModal"
    aria-labelledby="myModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header-custom">
          <h4
            id="myModalLabel"
            class="modal-title"
          >
            {{ $t("label.adjust_stock") }}
          </h4>
          <button
            ref="close"
            :class="class_btn_modal"
            aria-label="Close"
            data-dismiss="modal"
            type="button"
          >
            <i
              aria-hidden="true"
              class="fas fa-times"
            />
          </button>
        </div>
        <div class="modal-body">
          <template v-if="isLoading">
            <div class="tw-flex tw-w-full tw-items-center tw-justify-center tw-h-[300px]">
              <h3 class="tw-text-center tw-text-gray-400">
                {{ $t("string.loading") }}...
              </h3>
            </div>
          </template>
          <template v-else>
            <template v-if="product">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t("fields.name.label") }}</label>
                    <input
                      v-model="product.name"
                      :placeholder="$t('fields.name.placeholder')"
                      class="form-control input-lg"
                      readonly
                      type="text"
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t("product.fields.amount_in_stock.label") }}</label>
                    <input
                      v-model="product.amount_in_stock"
                      :placeholder="$t('product.fields.amount_in_stock.placeholder')"
                      class="form-control input-lg"
                      readonly
                      type="text"
                    >
                  </div>
                </div>
                <template v-if="product.children && Array.isArray(product.children) && product.children.length > 0">
                  <div class="col-md-12">
                    <div
                      :class="'form-group ' + (validations !== null && validations.hasOwnProperty('product_child') ? 'has-error' : '')"
                    >
                      <label class="required">{{ $t("product.fields.variant.label") }}</label>
                      <multiselect
                        v-model="product.product_child"
                        :option-height="104"
                        :options="productChildOptions"
                        :placeholder="$t('label.chooseProductVariant')"
                        label="label"
                        track-by="id"
                        @input="changeProductChild"
                      />
                      <template
                        v-if="validations !== null && validations.hasOwnProperty('product_child')"
                      >
                        <span class="help-block">{{ validations["product_child"][0] }}</span>
                      </template>
                    </div>
                  </div>
                </template>
                <div class="col-md-12">
                  <div
                    :class="{'has-error' : validations.amount}"
                    class="form-group"
                  >
                    <label class="required">{{ $t("product.fields.amount.label") }}</label>
                    <input
                      v-model="product.amount"
                      :placeholder="$t('product.fields.amount.placeholder')"
                      class="form-control input-lg"
                      type="number"
                    >
                    <template v-if="validations.amount">
                      <span class="help-block">{{ validations["amount"][0] }}</span>
                    </template>
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    :class="{'has-error' : validations.warehouse}"
                    class="form-group"
                  >
                    <label class="required">{{ $t("product.fields.warehouse.label") }}</label>
                    <multiselect
                      v-model="warehouse"
                      :allow-empty="false"
                      :deselect-label="$t('label.can_not_remove_value')"
                      :label="'name_' + $i18n.locale"
                      :option-height="104"
                      :options="warehouseOptions"
                      :placeholder="$t('product.fields.warehouse.choose_warehouse')"
                      :select-label="$t('label.select_label')"
                      :selected-label="$t('label.selected')"
                      track-by="uuid"
                      @input="changeWarehouse"
                    />
                    <template v-if="validations.warehouse">
                      <span class="help-block">{{ validations["warehouse"][0] }}</span>
                    </template>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t("product.fields.imported_date.label") }}</label>
                    <date-picker
                      v-model="imported_date"
                      :disabled-date="notAfterToday"
                      :input-class="'form-control input-lg'"
                      :placeholder="$t('label.select_date')"
                      :time-picker-options="timesPickerOptions"
                      format="YYYY-MM-DD hh:mm A"
                      lang="en"
                      style="width: 100%;"
                      type="datetime"
                      value-type="format"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t("product.fields.expired_date.label") }}</label>
                    <date-picker
                      v-model="product.expired_date"
                      :input-class="'form-control input-lg'"
                      :placeholder="$t('label.select_date')"
                      format="YYYY-MM-DD"
                      lang="en"
                      style="width: 100%;"
                      type="date"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="tw-flex tw-w-full tw-items-center tw-justify-center tw-h-[300px]">
                <h3 class="tw-text-center tw-text-gray-400">
                  {{ $t("string.item_was_not_found") }}
                </h3>
              </div>
            </template>
          </template>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
          >
            <i class="fas fa-times-circle" /> {{ $t("button.close") }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="store"
          >
            <i class="fas fa-plus-circle" /> {{ $t("button.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "ProductAdjustStockModal",
  components: { DatePicker },
  data () {
    return {
      warehouse: null,
      product: {},
      warehouseOptions: [],
      imported_date: this.$moment().format("YYYY-MM-DD hh:mm A"),
      timesPickerOptions: {
        start: "00:00",
        step: "00:30",
        end: "23:30"
      },
      productChildOptions: [],
      validations: {},
      isLoading: false
    }
  },
  computed: {
    ...mapGetters("product", {
      adjust_product_uuid: "adjust_product_uuid"
    })
  },
  watch: {
    adjust_product_uuid () {
      if (this.adjust_product_uuid) {
        this.getProduct(this.adjust_product_uuid)
      }
    }
  },
  methods: {
    ...mapActions("product", {
      setAdjustProductUuid: "setAdjustProductUuid"
    }),
    changeProductChild (item) {
      if (item && this.warehouse) {
        if (item.warehouses.length > 0) {
          let selectWarehouse = null
          item.warehouses.forEach((warehouse_item) => {
            if (warehouse_item.id === this.warehouse.id) {
              selectWarehouse = warehouse_item
            }
          })
          if (selectWarehouse && selectWarehouse.pivot) {
            this.product.amount_in_stock = parseFloat(selectWarehouse.pivot.amount)
          } else {
            this.product.amount_in_stock = 0
          }
        } else {
          this.product.amount_in_stock = 0
        }
      } else {
        this.product.amount_in_stock = 0
      }
    },
    notAfterToday (date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0))
    },
    changeWarehouse (item) {
      if (item && this.product.product_child) {
        const child = this.product.product_child
        if (child.warehouses.length > 0) {
          let selectWarehouse = null
          child.warehouses.forEach((warehouse_item) => {
            if (warehouse_item.id === item.id) {
              selectWarehouse = warehouse_item
            }
          })
          if (selectWarehouse && selectWarehouse.pivot) {
            this.product.amount_in_stock = parseFloat(selectWarehouse.pivot.amount)
          } else {
            this.product.amount_in_stock = 0
          }
        } else {
          this.product.amount_in_stock = 0
        }
      } else {
        this.product.amount_in_stock = 0
      }
    },
    store () {
      this.validations = {}
      if (this.warehouse) {
        this.product.warehouse = this.warehouse
      }
      if (this.imported_date) {
        this.product.imported_date = this.imported_date
      }
      this.$axios.post(this.$config.base_url + "/api/product/adjust-stock", this.product)
        .then((response) => {
          if (response.data.code === 1) {
            this.$toastr(
              "success",
              this.$t("string.theStockHasBeenAddedSuccessfully"),
              this.$t("string.adjustStock")
            )
            $("#adjustStockModal").modal("hide")
            this.setAdjustProductUuid(null)
            this.$emit("fetchProductDetail")
          } else {
            this.validations = response.data.message
            if (typeof (response.data.message) === "string") {
              this.$toastr("error", response.data.message, "Adjust Stock")
            }
          }
        })
        .finally(() => {
          this.alertStockProduct()
        })
    },
    getProduct (product_uuid) {
      this.isLoading = true
      const self = this
      self.$axios.post(self.$config.base_url + "/api/product/get-product-by-id", {
        shop_id: self.get_shop.id,
        uuid: product_uuid
      }).then((response) => {
        if (response.data.code === 1) {
          self.validations = {}
          self.productChildOptions = []
          self.warehouseOptions = response.data.data.warehouses
          self.product = response.data.data.product
          if (self.product) {
            this.$set(self.product, "amount", 0)
          }
          self.warehouse = response.data.data.warehouses[0]

          if (response.data.data.product.warehouses.length > 0) {
            response.data.data.product.warehouses.forEach((eachWarehouse) => {
              if (eachWarehouse.id === response.data.data.warehouses[0].id) {
                self.product.amount_in_stock = eachWarehouse.pivot.amount
              }
            })
          }
          if (response.data.data.product.children.length > 0) {
            response.data.data.product.children.forEach(eachChild => {
              let childLabel = ""
              eachChild.product_variants.forEach((eachVariant, index) => {
                childLabel += eachVariant.pivot.value
                if (index < (eachChild.product_variants.length - 1)) {
                  childLabel += "/"
                }
              })
              if (eachChild.amount_in_stock) {
                self.productChildOptions.push({
                  label: childLabel,
                  id: eachChild.id,
                  amount_in_stock: eachChild.amount_in_stock,
                  warehouses: eachChild.warehouses
                })
              } else {
                self.productChildOptions.push({
                  label: childLabel,
                  id: eachChild.id,
                  amount_in_stock: 0,
                  warehouses: eachChild.warehouses
                })
              }
            })
          }
        }
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
